import React from 'react';
import { useTranslation } from 'react-i18next';

const RGPBanner = ({rgpData}) => {
    const { t } = useTranslation();

    const NewlineText = () => {
        let i = 0;
        let newText = [
            ...rgpData.text1.value.split('\n').map((str) => <div key={`footer-play-responsibly-content-${i++}`} dangerouslySetInnerHTML={{ __html: str }}></div>),
            ...rgpData.text2.value.split('\n').map((str) => <div key={`footer-play-responsibly-content-${i++}`} dangerouslySetInnerHTML={{ __html: str }}></div>)
            ];
        return newText;
    }

    return <div className="footer-banner-rgp-content">
        <div className="footer-play-responsibly">{rgpData.header.value}</div>
        <div className="footer-play-responsibly-content">
            <NewlineText />
        </div>
    </div>;
};
export default RGPBanner;
