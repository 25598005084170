import React, { useContext, useState } from 'react';
import { onClickCheckBox } from './AddBet';
import { useTranslation } from 'react-i18next';
import { lblBr } from '../../Common/GlobalFunc';
import { FootballContext } from '../../Home/FootballPage';
import { Coupon, AcceptInplayOnlyLink, isInplayMatch } from './TournamentTable';
import { TournImage, getOddsArrowCss } from './MatchBase';
import { formatOdds, toDateFormat, isEliminated, isRefund, isSellingStarted, isMatchResultPage } from './Common';
import CheckBox from '../../Common/ui-components/CheckBox';

const TQLTable = ({ betType, isMatch, match, tourn, isSingleBetPage, isCloseLine }) => {
    const { t, i18n } = useTranslation();
    const context = useContext(FootballContext);
    const [expand, setExpand] = useState(true);
    const [checkboxState, setCheckboxState] = useState([]);
    const foPools = (isMatch ? match : tourn).foPools.filter(x=> x.oddsType===betType);
    const matchId = (isMatch ? match : tourn).id
    const isLastOddsPage = isMatchResultPage(context.contentRef.current.page);
    foPools.sort((x, y) => { return x.instNo - y.instNo });

    const toggle = () => {
        setExpand(!expand)
    }



    let title = `${t('LB_FB_TITLE_'+betType)} ${!isLastOddsPage ? tourn.frontEndId : ""}`;
    let showSuspend = false;
    if ( isMatch && !isLastOddsPage) {
        let foPool = match.foPools.filter(x=>x.oddsType===betType)[0];
        let name = foPool['name_'+i18n.language]!=null ? ` ( ${foPool['name_'+i18n.language]} )` : '';
        title = `${t('LB_FB_TITLE_'+ betType)} ( ${t('LB_FB_TQL_TQL')} ${foPool.instNo} )${name}`;
        showSuspend = !isSellingStarted(tourn, foPool);
    }
    if (isLastOddsPage && !isCloseLine) {
        title += '- ' + t('LB_FB_RESULT_LAST_ODDS_INPLAY');
    }

    const tqlProps = {
        foPools, betType, tourn, context, isMatch, match, setCheckboxState, showSuspend, isLastOddsPage, isCloseLine
    }

    return (
        <section className='TQL couponTQL'>
            {isSingleBetPage ?
                <>
                    <Coupon title={`${tourn.frontEndId} ${tourn["name_"+i18n.language]}`} handleOnClick={toggle} isChecked={expand} showHelpBtn={false} betType={betType} frontEndId={tourn.frontEndId} />
                    {expand ? <>
                        <GeneralSingleTournTable {...tqlProps}/>
                    </> : null}
                </> :
                <>
                    <Coupon title={title} handleOnClick={toggle} isChecked={expand} showHelpBtn={true} betType={betType} isShowSupended={false/* 放在了tablebody里面 */}  frontEndId={tourn.frontEndId}/>
                    { expand ? <>
                        <GeneralSingleTournTable {...tqlProps}/>
                    </> : null }
                </>
            }
        </section>
    )
}
export default TQLTable;

const GeneralSingleTournTable = (props) => {
    const {isMatch, context} = props
    return isMatch ? <TQLTableMatchAllOdds {...props}/> :
        (context.state.windowSize.isMobileSize ?
            <TQLTableMobile {...props}/> :
            <TQLTableDesktop {...props}/>)
}

const TQLTableDesktop = ({foPools, betType, tourn, context, isMatch, match, setCheckboxState}) => {
    const {t, i18n} = useTranslation()
    return <div className='tournTqlTable'>
        <div className='tournTqlTableHeader'>
            <div className="dfCol padl12">{lblBr(t('LB_FB_TQL_TABLE_DATE'))}</div>
            <div className="dfjc">{t('LB_FB_TQL_CODE')}</div>
            <div className="dfc padl16">{t('LB_FB_TQL_STAGE')}</div>
            <div className="padl16">{t('LB_FB_CHP_TEAM')}</div>
            <div className="padl16">{t('LB_ODDS')}</div>
            <div className="padl16">{t('LB_FB_CHP_TEAM')}</div>
            <div className="padl16">{t('LB_ODDS')}</div>
        </div>
        {foPools.map((foPool, index) => {
            const combs = foPool.lines[0].combinations;
            combs.sort((x, y) => {
                return parseInt(x.selections[0].str) - parseInt(y.selections[0].str);
            });
            let cssStr = index % 2 == 0 ? 'odd' : 'even';
            let isInplay = foPool.inplay && foPool.match && isInplayMatch(foPool.match.status);
            return (<div className={`tournTqlTableBody ${cssStr}`} key={foPool.id} id={`tournTQL_${tourn.frontEndId}_${foPool.instNo}`}>
                <div className="dfc right-border padl12">{toDateFormat(foPool.expectedSuspendDateTime, true, true)}</div>
                <div className="dfcc right-border">{foPool.instNo}</div>
                <div className="dfc right-border padl16 padtb6">{foPool['name_' + i18n.language]}</div>
                {isInplay ?
                    <AcceptInplayOnlyLink match={foPool.match} betType={betType} />
                : combs.map((comb, eq) => {
                    let odds = comb.currentOdds;
                    let isDisabled = isEliminated(comb) || isRefund(comb) || !isSellingStarted(tourn, foPool, foPool.lines[0]) || comb.status!="AVAILABLE";
                    let id = 'TQL_' + foPool.lines[0].lineId + "_" + comb.combId  + "_"  + foPool.id + "_" + comb.str;
                    let curState = context.content.oddsSelection.filter(x => x.id == id)[0];
                    curState = {...curState,
                        id: id,
                        checked: curState?.checked || false,
                        oddsVal: odds,
                        matchId: isMatch ? match.id : tourn.id
                    }
                    let arrowCss = getOddsArrowCss(curState, isMatch && isDisabled )
                    return (<>
                        <div className="dfc padl16 padtb6">{comb.selections[0].str} {comb.selections[0]["name_"+i18n.language]}</div>
                        <div className="df">
                            <div className={`dfc `}>
                                <CheckBox product='football' id={`fb_${id}`} classNameBox={`${id} ${isDisabled && 'dim'}`} onClick={() => onClickCheckBox(curState, isDisabled, context, setCheckboxState)} defaultChecked={curState.checked} disabled={isDisabled} />
                            </div>
                            <div className={`dfc padl4 oddsColor ${arrowCss}`}>
                                {isMatch && isDisabled ? "---" : formatOdds(t, odds, comb.status)}
                                <div className={`oddsArrow ${arrowCss}`}></div>
                            </div>
                        </div>
                    </>)
                })}
            </div>)
        })}
    </div>
}

const TQLTableMobile = ({foPools, betType, tourn, context, isMatch, match, setCheckboxState}) => {
    const {t, i18n} = useTranslation()
    return (<>
        {foPools.map((foPool) => {
            const combs = foPool.lines[0].combinations;
            combs.sort((x, y) => {
                return parseInt(x.selections[0].str) - parseInt(y.selections[0].str);
            });
            let isInplay = foPool.inplay && foPool.match && isInplayMatch(foPool.match.status);
            return (
                <div className="tournTqlTableM" key={foPool.id} id={`tournTQL_${tourn.frontEndId}_${foPool.instNo}`}>
                    <div className="tournHeader">
                        <div>
                            <div className='font11'>{t('LB_FB_TQL_CODE')}</div>
                            <div>{foPool.instNo}</div>
                        </div>
                        <div className="tournStage">
                            <div className='font11'>{t('LB_FB_TQL_STAGE')}</div>
                            <div>{foPool["name_"+i18n.language]}</div>
                        </div>
                    </div>
                    <div className='tournEsst'>
                        <div className="tournEsstLbl">{t('LB_FB_CHP_TABLE_DATE')}</div>
                        <div>{toDateFormat(foPool.expectedSuspendDateTime, true, true)}</div>
                    </div>
                    <div className="tournSelections">
                        <div className="tournSelHeader">{t('LB_FB_CHP_TEAM')}</div>
                        <div className="tournSelHeaderOdds">{t('LB_ODDS')}</div>
                        <div className="tournSelHeader"></div>
                        {isInplay ?
                            <AcceptInplayOnlyLink match={foPool.match} betType={betType} />
                        : combs.map((comb, index) => {
                            let odds = comb.currentOdds;
                            let isDisabled = isEliminated(comb) || isRefund(comb) || !isSellingStarted(tourn, foPool, foPool.lines[0]) || comb.status!="AVAILABLE";
                            let id = 'TQL_' + foPool.lines[0].lineId + "_" + comb.combId  + "_"  + foPool.id + "_" + comb.str;
                            let cssStr = index%2==0 ? 'odd': 'even';
                            let curState = context.content.oddsSelection.filter(x => x.id == id)[0];
                            curState = {...curState,
                                id: id,
                                checked: curState?.checked || false,
                                oddsVal: odds,
                                matchId: isMatch ? match.id : tourn.id
                            }
                            let arrowCss = getOddsArrowCss(curState, isMatch && isDisabled)
                            let oddsVal = formatOdds(t, odds, comb.status)
                            let isNaNVal = isNaN(oddsVal)
                            return <>
                                <div className={`tournSelName ${cssStr}`}>{comb.selections[0].str} {comb.selections[0]['name_'+i18n.language]}</div>
                                <div className={`tournSelOdds ${cssStr} oddsColor ${arrowCss}`}>
                                    {isMatch && isDisabled ? isNaNVal? oddsVal : "---" : oddsVal}
                                    <div className={`oddsArrow ${arrowCss}`}></div>
                                </div>
                                <div className={`tournSelChkbox ${cssStr}`}>
                                    <CheckBox  product='football' id={`fb_${id}`} classNameBox={`${id} ${isDisabled && 'dim'}`} onClick={() => onClickCheckBox(curState, isDisabled, context, setCheckboxState)} defaultChecked={curState.checked} disabled={isDisabled} />
                                </div>
                            </>;
                        })}
                    </div>
                </div>
            )
        })}
    </>)
}

const TQLTableMatchAllOdds = (props) => {
    const {t, i18n} = useTranslation()
    const {tourn, context, foPools, match, showSuspend, isLastOddsPage, isCloseLine} = props
    let isMatchNotPrev= match?.status != 'PREEVENT';
    const code = tourn.code;
    const name = tourn['name_'+i18n.language];
    const title = <>{tourn.frontEndId} { !context.state.windowSize.isMobileSize ? <TournImage code={code} name={name} /> : null } {name}</>
    const foPool = foPools[0];
    const lines = isCloseLine ? foPool.closedLines : foPool?.lines;
    let homeComb = lines?.[0].combinations.filter(x => x.selections[0].selId===match.homeTeam.id || x.selections[0].str==="H")[0];
    let awayComb = lines?.[0].combinations.filter(x => x.selections[0].selId===match.awayTeam.id || x.selections[0].str==="A")[0];

    return <div className='tqlAllOdds'>
        { showSuspend && isMatchNotPrev && <div className='lb-suspended'>{t('LB_FB_SUSPENDED')}</div> }
        {!isLastOddsPage && <div className='tqlAllOddsHeader'>
            <div className="tqlAllOddsTitle">{title}</div>
            <div className='tournEsst'>
                <div className="tournEsstLbl">{t('LB_FB_CHP_TABLE_DATE')}</div>
                <div className="">{toDateFormat(foPool.expectedSuspendDateTime, true, true)}</div>
            </div>
        </div>}
        <div className={`tqlAllOddsTable`} key={foPool.id}>
            <div className="tqlAllOddsSel">{!isLastOddsPage ? <TQLOdds  {...props} foPool={foPool} comb={homeComb} /> : <TQLOldOdds comb={homeComb} {...props} />}</div>
            <div className="tqlAllOddsSel">{!isLastOddsPage ? <TQLOdds  {...props} foPool={foPool} comb={awayComb} /> : <TQLOldOdds comb={awayComb} {...props} />}</div>
        </div>
    </div>
}

const TQLOdds = ({foPool, comb, context, isMatch, match, tourn, setCheckboxState}) => {
    const {t, i18n} = useTranslation()
    let odds = comb.currentOdds;
    let isDisabled = isEliminated(comb) || isRefund(comb) || !isSellingStarted(tourn, foPool, foPool.lines[0]) || comb.status!="AVAILABLE";
    let id = 'TQL_' + foPool.lines[0].lineId + "_" + comb.combId  + "_"  + foPool.id + "_" + comb.str;
    let curState = context.content.oddsSelection.filter(x => x.id == id)[0];
    let isMatchNotPrev= match?.status != 'PREEVENT';
    curState = {...curState,
        id: id,
        checked: curState?.checked || false,
        oddsVal: odds,
        matchId: isMatch ? match.id : tourn.id
    }
    let arrowCss = getOddsArrowCss(curState, isMatch && isDisabled && isMatchNotPrev)
    let oddsVal = formatOdds(t, odds, comb.status)
    let isNaNVal = isNaN(oddsVal)
    return (<>
        <div className="tqlSel">{comb.selections[0].str} {comb.selections[0]["name_"+i18n.language]}</div>
        <div className={`tqlCheckbox `}>
            <CheckBox  product='football' id={`fb_${id}`} classNameBox={`${id} ${isDisabled && 'dim'}`} onClick={() => onClickCheckBox(curState, isDisabled, context, setCheckboxState)} defaultChecked={curState.checked} disabled={isDisabled} />

        </div>
        <div className={`tqlOdds oddsColor ${arrowCss}`}>
            {isMatch && isDisabled && isMatchNotPrev ? isNaNVal? oddsVal : "---" : oddsVal}
            <div className={`oddsArrow ${arrowCss}`}></div>
        </div>
    </>)
}

const TQLOldOdds = ({comb, match}) => {
    const {t, i18n} = useTranslation()
    let odds = comb.currentOdds;
    let team = comb.selections[0].str==="H" ? match.homeTeam : match.awayTeam;
    return (<>
        <div className="tqlSel">{team["name_"+i18n.language]}</div>
        <div className={`tqlOdds`}>
            {formatOdds(t, odds, '')}
        </div>
    </>)
}