
import { ClearBetSels,ClearBetByBetTypeAndRacesAndRunner,ClearBetRaces } from "../../Common/RacingBetline";
import { initAlupData, getNextDefinedRace } from '../../Common/Common';
import { getAlupBetTypeList } from '../../Common/AlupPageBase';

export const parseRacSta = (content, message, topicInfo) => {
    let races = content.meeting.races.filter(x => x.no==topicInfo.raceNo)[0];
    if(races != null){
        if(message != null){
            if(message.finPosUpdAt != null){  // race result status
                return;
            }
            if(message.st != null){
                races.status = message.st.toUpperCase() ;
                if(["CLOSED", "VOIDED", "RESULT", "ABANDONED"].includes(message.st?.toUpperCase())){
                    ClearBetRaces(topicInfo.raceNo);
                    if(content.alupData.length>0){
                        const lastRaceNo = Math.max(...content.meeting.races.map(x=>x.no));
                        if(races.no != lastRaceNo){
                            content.alupData = content.alupData?.filter(x => x.raceNo != races.no);
                            if ( content.alupData.length <=6 ) {
                                let fSet =  content.config['ALUP_FORMULA'][content.alupData.length];
                                content.alupFormula = fSet[0];
                            }
                        }

                        if(content.alupData.length == 0){
                            const curRaceNo = getNextDefinedRace(content.page, content.raceNo, content.meeting)
                            content.alupData.push(initAlupData({current: content}, curRaceNo, getAlupBetTypeList( content.page)))
                        }
                    }

                }
                //races.postTime = message.stUpdAt;
            }
            if(message.ru != null){
                let runner = races.runners.filter(x => x.no == message.ru.no)[0];
                if(runner != null){
                    runner.status = message.ru.st;
                    if(["SCRATCHED", "SCRATCHEDRESERVED", "STANDBY"].includes(message.ru.st?.toUpperCase())){
                        ClearBetByBetTypeAndRacesAndRunner(topicInfo.raceNo,message.ru.no);
                    }
                }

            }
        }
    }

}