import React, { useEffect, useContext, useState } from 'react';
import { Button, Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NewWindow, postMsgToPeNote } from '../Common/home-common';
import { RacingContext } from '../Home/RacingPage';
import { GlobalContext } from '../../StandardPage';
import { isMatchResultPage, isTournResultPage, isPmPoolResultPage, sortSeqIgnoreCase, isEarlySettlementEanbled } from '../Football/Common/Common';
import { handleClickMenuItem, MenuIcon } from './MenuCommon';
import { useProductMenuQuery } from '../Home/Data/SitecoreDataHooks';
import { isLogonHigh } from '../BetSlip/LoginHooks';

const TopMenu = (props) => {
    const { t, i18n } = useTranslation();
    const racingContext = useContext(RacingContext);
    const globalContext = useContext(GlobalContext);
    const fbMenuGqlVars = globalContext.fbMenuGqlVars;
    const [pMenuLoading, pMenuData] = useProductMenuQuery(props.Product);
    const cashoutData = globalContext.esHook.getEsData() || [];
    const [tournIdList, setTournIdList] = useState({ day: 60, list: "" });

    useEffect(()=> {
        if ( tournIdList!="" ) {
            globalContext.loadFootballMenuQuery(tournIdList.day, tournIdList.list.split(','));
        }
    }, [tournIdList.list])

    const highLightItem = (item) => {
        let page = globalContext.globalState.betType;
        let isResultPage = false;
        switch (props.Product) {
            case 'racing':
                isResultPage = page.indexOf("RESULTS")>=0;
                if ( item===window.sitecoreVars.MENU_RESULTS && isResultPage ) {
                    return true;
                }
                else if ( item=="HOME" && ! isResultPage ) {
                    return true;
                }
                break;
            case 'football':
                isResultPage = isMatchResultPage(page) || isTournResultPage(page) || isPmPoolResultPage(page);
                if ( item==window.sitecoreVars.MENU_RESULTS && isResultPage ) {
                    return true;
                }
                else if ( item==window.sitecoreVars.MENU_EARLY_SETTLEMENT && page==="CASHOUT") {
                    return true;
                }
                else if ( item=="HOME" && ! isResultPage && page!=="CASHOUT") {
                    return true;
                }
                break;
            case 'marksix':
                if ( item==window.sitecoreVars.MENU_CHECK_DRAW_RESULT && page=="CHECKDRAWRESULT" ) {
                    return true;
                }
                else if ( item==window.sitecoreVars.MENU_RESULTS && page=="RESULTS" ) {
                    return true;
                }
                else if ( item==window.sitecoreVars.MENU_FIXTURES && page=="FIXTURES" ) {
                    return true;
                }
                else if ( item==window.sitecoreVars.MENU_STATISTICS && page=="STATISTICS" ) {
                    return true;
                }
                else if ( item==window.sitecoreVars.MENU_M6GUIDE && page=="GUIDE" ) {
                   return true;
                }
                else if ( item=="HOME" && !["CHECKDRAWRESULT", "RESULTS", "FIXTURES", "STATISTICS"].includes(page) ) {
                    return true;
                }
                break;
            default:
                break;
        }
        return false;
    }

    const menuItem = (menu) => {
        return menu.children.map((_cItem, _cIndex) => {
            let predefinedColor = _cItem.predefinedColor?.targetItem?.optionValue?.value || "";
            let hlStyle = predefinedColor!="" ? { color: predefinedColor } : {};
            let id = _cItem.key.split(' ').join("_");
            if ( _cItem.itemEnable.value!=="1" ) {
                return null;
            }
            else if ( _cItem.key===window.sitecoreVars.MENU_AUTO_RACING_NOTES) {
                if( window.globalConfig.ENABLE_PE_NOTE && globalContext.racingNotesData.globalEnable && globalContext.racingNotesData.settingEnable ){
                    return <Dropdown.Item eventKey={_cIndex} id={id} onClick={(e)=>handleClickRacingNotes()} value={_cItem.itemName.value} key={`${_cItem.key}`} style={hlStyle}>
                        {_cItem.itemName.value}
                        <MenuIcon menu={_cItem} />
                    </Dropdown.Item>;
                }else return

            }
            else if ( _cItem.key===window.sitecoreVars.MENU_AUTO_TOURN_RESULTS ) {
                if ( _cItem.frontEndIds.value!="" && tournIdList.list=="" ) {
                    let d = _cItem.noOfDays.value!="" ? parseInt(_cItem.noOfDays.value) : 60;
                    setTournIdList({day: d, list: _cItem.frontEndIds.value});
                }
                let menuList = [];
                fbMenuGqlVars?.data?.tournamentResult?.sort(sortSeqIgnoreCase);
                fbMenuGqlVars?.data?.tournamentResult?.forEach(item=> {
                    let tournUrl = 'tournres/' + item.id;
                    let cTemp = item["name_"+i18n.language];
                    menuList.push(<Dropdown.Item eventKey={_cIndex}id={id} itemID={_cItem.itemName.value} onClick={() => handleOnPageChange(tournUrl)} value={cTemp} key={`${cTemp}_${item.id}`} style={hlStyle}>
                        {cTemp}
                        <MenuIcon menu={_cItem} />
                    </Dropdown.Item>);
                });
                return menuList
            }
            return <Dropdown.Item eventKey={_cIndex} id={id}  onClick={(e)=>handleClickMenuItem(e,_cItem, handleOnPageChange)} value={_cItem.itemName.value} key={`${_cItem.key}`} style={hlStyle}>
                {_cItem.itemName.value}
                <MenuIcon menu={_cItem} />
            </Dropdown.Item>;
        });
    }

    const handleOnPageChange = (page) => {
        let product = props.Product;
        let url = "";

        switch (product) {
            case 'racing':
                url = "/" + i18n.language + "/" + product + "/" + page + "/" + racingContext.content.date + "/" + racingContext.content.venue + "/" + racingContext.content.raceNo;
                break;
            default:
                url = "/" + i18n.language + "/" + product + "/" + page;
                break;
        }

        props.onPageChange(url, page);
    }

    const handleClickRacingNotes = () => {
        if ( !isLogonHigh() ) {
            alert(t('LB_RC_NOTES_MSG'));
        }
        else {
            let peNoteLang = i18n.language === 'en' ? 'english' : 'chinese';
		    let trCodeLang = i18n.language === 'en' ? 'E' : 'C';
            let pop = NewWindow(window.globalConfig.PENOTES_URL + window.globalConfig.PENOTE_ENDPOINT.menu.replace('{0}', peNoteLang).replace('{2}', trCodeLang), 'racingNotes', 780, 640, 1, 1);
            postMsgToPeNote(pop);
        }
    }

    const gotoHomePage = () => {
        handleOnPageChange('home');
    }

    const menuProps = {
        pMenuData, highLightItem, handleOnPageChange, menuItem, cashoutData
    }    

    if (pMenuLoading) {
        return (<div className="topMenu">

        </div>);
    } else {
        let isActive = highLightItem('HOME');
        return (
            <div className="topMenu">
                <ul key="topMenuUl">
                    <li key={`topMenu-home`}><Button className={isActive && 'active'} id={'topMenu-home'} onClick={(e)=> gotoHomePage()} value={t('LB_HOME')} variant="">{t('LB_HOME')}</Button></li>
                    <Menu {...menuProps}/>
                </ul>
            </div>
        );
    }
}
export default TopMenu;

const Menu = ({pMenuData, highLightItem, handleOnPageChange, menuItem, cashoutData}) => {
    const globalContext = useContext(GlobalContext);
    let i = 0;
    const isESDisabled = globalContext.globalState.commonCtrl.enableEarlySettlementUIMessage

    return pMenuData.map((menu) => {
        let _name = menu.itemName.value;
        _name = _name.replace(/(\()/g,'<wbr />$1')
        _name = <span dangerouslySetInnerHTML={{ __html: _name }}></span>
        let showMenu = menu.key!=window.sitecoreVars.MENU_EARLY_SETTLEMENT || isEarlySettlementEanbled(globalContext);
        let predefinedColor = menu.predefinedColor?.targetItem?.optionValue?.value || "";
        let hlStyle = predefinedColor!="" && !highLightItem(menu.key) ? { color: predefinedColor } : {};
        let key = menu.key.split(' ').join('_')
        if ( menu.itemEnable.value!=="1" || !showMenu ) {
            return null;
        }
        else if (menu.children.filter(x=> x.itemEnable.value==="1").length > 0 ) {
            return <li key={`topMenu-${i++}`}>
                <DropdownButton
                    as={ButtonGroup}
                    key={`DropdownButton-${i++}`}
                    id={key}
                    variant={menu.key}
                    title={<div style={hlStyle}>{_name}<MenuIcon menu={menu} /></div>}
                    align="end"
                    className={`${highLightItem(menu.key) && 'active'}`}
                    onClick={(e)=>{
                        if ( e.target.ariaExpanded==="true" ) {
                            handleClickMenuItem(e, menu, handleOnPageChange);
                        }
                    }}
                >
                    {menuItem(menu)}
                </DropdownButton>
            </li>
        }
        else {
            return <li key={`topMenu-${i++}`}>
                <div id={key} className={`${highLightItem(menu.key) && 'active'} dropdown btn-group`} style={hlStyle}>
                    <Button onClick={(e)=>handleClickMenuItem(e, menu, handleOnPageChange)}  value={_name} style={hlStyle} variant="">
                        {_name}
                        <MenuIcon 
                        menu={menu}
                        cashOutNo={
                            menu.key===window.sitecoreVars.MENU_EARLY_SETTLEMENT && isLogonHigh() && cashoutData?.length>0 && !isESDisabled ? 
                            <span className={`cashoutMenuNo${cashoutData.length>99?' moreThan99':''}`}>{cashoutData.length>99?'99+':cashoutData.length}</span>
                            : null
                        }
                        />
                    </Button>
                </div>
            </li>
        }

    });
}