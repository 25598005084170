import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetBetslipConfig } from '../Common/ConfigHelper';
import { NewWindow } from '../Common/home-common';
import { GetError } from '../Common/bs_str_table';


export const maxLengthLimit = (val, maxLength = 50) => {
    let pass = true;
    if (val.length > maxLength/2) {
        const cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/;
        let length = 0;
        val.split('').forEach((str) => {
            if (pass) {
                if (cnReg.test(str)) {
                    length += 2;
                } else {
                    length++;
                }
            }
            if (length > maxLength) {
                pass = false;
            }
        });
    }
    return pass
};

const LoginEKBAAnswer = ({ onClickConfirm, onClickCancel, onClickLogout, isSsoLogin, loginEKBAQuestion = '', showAnswerErrorMsg = '', APILoading}) => {
    const [answer, setAnswer] = useState('');
    const { t, i18n } = useTranslation();

    const handleClickConfirm = () => {
        onClickConfirm(answer);
    };

    const handleClickCancel = () => {
        onClickCancel();
    };

    const handleClickLogout = () => {
        onClickLogout();
    }

    const handleClickForgotLoginAnswer = () => {
        const flag = window.confirm(t('LB_BS_LOGIN_FORGOT_ANSWER_MSG'));
        if (flag) {
            const url = t('LB_BS_LOGIN_FORGOT_ANSWER_URL');
            window.location = url;
        }
    };

    const handleClickFindLoginAnswer = () => {
        const url = t('LB_BS_LOGIN_ANSWER_ERROR_URL');
        NewWindow(url, '', 750, 500, 1, 1);
    };

    const forgotEkbaUrl = window.globalConfig.WCIP_URL + GetBetslipConfig()?.urls?.wcip?.forgotEKBA[i18n.language];

    return (
        <div className="login-answer-container">
            <div className="login-answer-title">{t('LB_BS_LOGIN_QUESTION')}</div>
            <div className="login-answer-content">
                <div className="login-question">{loginEKBAQuestion}</div>
                <div className="answer-input">
                    <input
                        autoFocus
                        type="text"
                        maxLength={50}
                        value={answer}
                        onChange={(e) => {maxLengthLimit(e.target.value) && setAnswer(e.target.value)}}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter' && !showAnswerErrorMsg?.disableEkbaConfirm) {
                                handleClickConfirm();
                            }
                        }}
                        readOnly={APILoading}
                    />
                </div>
                <div className="forgot-answer" onClick={handleClickForgotLoginAnswer}>
                    {t('LB_BS_LOGIN_FORGOT_ANSWER')}
                </div>
                {showAnswerErrorMsg ? (
                    <div className="error-answer">
                        {showAnswerErrorMsg?.retry && [0,1,2,3].includes(showAnswerErrorMsg?.retryCount) ? // retry count
                        <>
                            <span dangerouslySetInnerHTML={{ __html: t('LB_BS_LOGIN_ANSWER_ERROR1').replace('{FORGOT_EKBA_URL}', forgotEkbaUrl)}} />
                            <span className="helpLink" onClick={handleClickFindLoginAnswer}>
                                {t('LB_BS_LOGIN_ANSWER_ERROR2')}
                            </span>
                            {t('LB_BS_LOGIN_ANSWER_ERROR3').replace('X', showAnswerErrorMsg.retryCount)}
                        </>
                        :
                        (GetError(showAnswerErrorMsg?.login_status, i18n.language) ? // map with bs_str_table error list
                        <span dangerouslySetInnerHTML={{ __html: GetError(showAnswerErrorMsg?.login_status, i18n.language)}} />
                        :
                        showAnswerErrorMsg?.login_error ? // show error message from txn api
                        <span dangerouslySetInnerHTML={{ __html: showAnswerErrorMsg?.login_error}} />
                        :
                        <span>{t('LB_BS_LOGIN_ERROR_OTHER')}</span>
                        )}
                        
                    </div>
                ) : null}
                <div className={`button-box ${isSsoLogin?'ekbaBtnArea':''}`}>
                    {isSsoLogin ?
                        <div className='leftArea'>
                            <div onClick={handleClickCancel}>{t('LB_BS_LOGIN_CANCEL')}</div>
                            <div className='ssoLogoutBtn' onClick={handleClickLogout}>{t('LB_LOGOUT')}</div>
                        </div>
                    :
                        <div className="bsBgColor cta_brand" onClick={handleClickCancel}>{t('LB_BS_LOGIN_CANCEL')}</div>
                    }
                    <div className={`cta_yellow ${showAnswerErrorMsg?.disableEkbaConfirm?'disabled':''} ${APILoading ? 'disabled' : ''}`} onClick={()=> !APILoading && handleClickConfirm()}>{t('LB_BS_LOGIN_CONFIRM')}</div>
                </div>
            </div>
        </div>
    );
};

export default LoginEKBAAnswer;
