import { useCallback, useEffect, useMemo } from 'react';
import { BroadcastChannel } from 'broadcast-channel';

const useBroadcastChannel = (name = 'test', onMessage, open = true) => {
    const callback = useCallback(
        (res) => {
            onMessage && onMessage(res);
        },
        [onMessage]
    );

    const broadcastChannel = useMemo(() => {
        if (open) {
            const broadcastChannel = new BroadcastChannel(name);
            return broadcastChannel;
        }
        return () => {
            broadcastChannel?.close();
        };
    }, [open]);

    useEffect(() => {
        broadcastChannel?.addEventListener('message', callback);
        return () => {
            broadcastChannel?.removeEventListener('message', callback);
        };
    }, [broadcastChannel, callback]);

    return broadcastChannel;
};

export default useBroadcastChannel;
