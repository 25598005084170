export const parseMeetingSta = ( content, message, topicInfo) => {
    let meeting = content.meeting;
    if(message != null){
        if(message.st != null){
            meeting.status = message.st.toUpperCase() ;
            meeting.currentNumberOfRace = message.curRaNo;
        }
        if(message.chgHist != null){
            let chgHistLen = meeting.changeHistories.length;
            for(let i=0 ; i<chgHistLen; i++){
                meeting.changeHistories.pop();
            }
            meeting.changeHistories = message.chgHist ;
        }
    }
}