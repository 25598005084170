import { useRef, useContext } from 'react';
import useLoadPmPool from './useLoadPmPool';
import useLoadPoolInv from './useLoadPoollInv';
import useLoadFoPool from './useLoadFoPool';
import { GlobalContext } from '../../../StandardPage';
import { parseMessage } from './useRacingPush/parseMessage';
import { apiErrorCallback } from '../../Common/graphqlFetch';
import useLoadMeeting from './useLoadMeeting';

export const useDataRefresh = (contentRef, setContent) => {
    const { loadPmPool } = useLoadPmPool(contentRef, setContent);
    const { loadPoolInv } = useLoadPoolInv(contentRef, setContent);
    const { loadFoPool }  = useLoadFoPool(contentRef, setContent);
    const { reloadMeetingStatus } = useLoadMeeting(contentRef, setContent);
    const globalContext = useContext(GlobalContext);
    const { replayMsgQueue } = globalContext.mqttClient;
    const allowReload = useRef(true);

    const manualReload = () => {
        if(!document.querySelector('#refreshButton')?.classList.contains('rotate')) {
            document.querySelector('#refreshButton')?.classList?.add('rotate')
            setTimeout(() => {
                document.querySelector('#refreshButton')?.classList?.remove('rotate')
            }, 1200)
        }
        if ( !allowReload.current )
            return;
        reloadRacingPools();
        allowReload.current = false;
        setTimeout(()=> {
            allowReload.current = true;
        }, 30000)
    }

    const reloadRacingPools = () => {
        let arr = [];
        let suf = contentRef.current.isPresales ? "Pre" : "";
        let refresh = false;
        switch (contentRef.current.page) {
            case "WP":
                loadQueries([
                    reloadMeetingStatus(),
                    loadPmPool(["WIN" + suf, "PLA" + suf], false),
                    loadPoolInv(["WIN","PLA","QIN","QPL","CWA","CWB","CWC",
                        "IWN","FCT","TCE","TRI","FF","QTT","DBL","TBL","DT","TT","SixUP"])
                ]);
                break;
            case "JTCOMBO":
                loadQueries([
                    reloadMeetingStatus(),
                    loadPmPool(["WIN", "PLA"], true),
                    loadPoolInv(["WIN","PLA"], true)
                ]);
                break;
            case "PWIN":
                loadQueries([
                    reloadMeetingStatus(),
                    loadPmPool(["WIN", "PLA", "PWIN0", "PWIN1"]),
                    loadPoolInv(["WIN","PLA"])
                ]);
                break;
            case "WPQ":
                arr = ["WIN" + suf, "PLA" + suf];
                if ( contentRef.current.displayOpt==="top" )
                    arr.push("QINTop" + suf);
                else if ( contentRef.current.displayOpt==="banker" )
                    arr.push("QINBanker" + suf);
                else
                    arr.push("QIN" + suf);
                if ( contentRef.current.displayOpt2==="top" )
                    arr.push("QPLTop" + suf);
                else if ( contentRef.current.displayOpt2==="banker" )
                    arr.push("QPLBanker" + suf);
                else
                    arr.push("QPL" + suf);
                loadQueries([
                    reloadMeetingStatus(),
                    loadPmPool(arr, false),
                    loadPoolInv(["WIN","PLA","QIN","QPL"])
                ]);
                break;
            case "CWA":
            case "CWB":
            case "CWC":
                loadQueries([
                    reloadMeetingStatus(),
                    loadPmPool(["WIN", contentRef.current.page], false)
                ]);
                break;
            case "IWN":
                loadQueries([
                    reloadMeetingStatus(),
                    loadPmPool(["WIN" + suf, contentRef.current.page + suf], false)
                ]);
                break;
            case "FCT":
                loadQueries([
                    reloadMeetingStatus(),
                    loadPmPool(["WIN", contentRef.current.page], false),
                    loadPoolInv([contentRef.current.page, "WIN"])
                ]);
                break;
            case "TCE":
            case "QTT":
                arr = ["WIN"];
                if ( contentRef.current.displayOpt==="top" )
                    arr.push(contentRef.current.page + "Top");
                else if ( contentRef.current.displayOpt==="banker" )
                    arr.push(contentRef.current.page + "Banker");
                else if ( contentRef.current.displayOpt==="inv" )
                    arr.push(contentRef.current.page + "Invest");
                loadQueries([
                    reloadMeetingStatus(),
                    loadPmPool(arr, false),
                    loadPoolInv([contentRef.current.page, "WIN"])
                ]);
                break;
            case "TRI":
            case "FF":
                if ( contentRef.current.isPresales ) {
                    loadQueries([
                        reloadMeetingStatus(),
                        loadPmPool(["WINPre", contentRef.current.page + "Pre"], false),
                        loadPoolInv([contentRef.current.page, "WIN"])
                    ]);
                }
                else {
                    let arr = ["WIN"];
                    if ( contentRef.current.displayOpt==="top" )
                        arr.push(contentRef.current.page + "Top");
                    else if ( contentRef.current.displayOpt==="banker" )
                        arr.push(contentRef.current.page + "Banker");
                    else
                        arr.push(contentRef.current.page + suf);
                    loadQueries([
                        reloadMeetingStatus(),
                        loadPmPool(arr, false),
                        loadPoolInv([contentRef.current.page, "WIN"])
                    ]);
                }
                break;
            case "DBL":
            case "TBL":
            case "DT":
            case "TT":
                if ( contentRef.current.isPresales ) {
                    loadQueries([
                        reloadMeetingStatus(),
                        loadPmPool([contentRef.current.page + "Pre", contentRef.current.page], false),
                        loadPmPool(["WINPre", "WIN"], true),
                        loadPoolInv([contentRef.current.page, "WIN"], true)
                    ]);
                }
                else {
                    loadQueries([
                        reloadMeetingStatus(),
                        loadPmPool([contentRef.current.page], false),
                        loadPmPool(["WIN"], true),
                        loadPoolInv([contentRef.current.page, "WIN"], true)
                    ]);
                }
                break;
            case "6UP":
                loadQueries([
                    reloadMeetingStatus(),
                    loadPmPool(["SixUP"], false),
                    loadPmPool(["WIN"], true),
                    loadPoolInv(["SixUP", "WIN"], true)
                ]);
                break;
            case "CWAALUP":
            case "CWAALLALUP":
            case "CROSS_ALUP":
                loadQueries([
                    reloadMeetingStatus(),
                    loadPmPool(["WIN", "CWA"], true),
                    loadPoolInv(["WIN","PLA","QIN","QPL","CWA","CWB","CWC","FCT","TRI"], true)
                ]);
                break;
            case "WPALUP":
            case "WPQALUP":
            case "FCTALUP":
            case "TRIALUP":
                loadQueries([
                    reloadMeetingStatus(),
                    loadPmPool(["WIN" + suf], true),
                    loadPoolInv(["WIN","PLA","QIN","QPL","CWA","CWB","CWC","FCT","TRI"], true)
                ]);
                break;
            case "JKC":
            case "TNC":
                loadQueries([
                    reloadMeetingStatus(),
                    loadFoPool([contentRef.current.page])
                ]);
                break;
            case "TURNOVER":
                loadQueries([
                    reloadMeetingStatus(),
                    loadPoolInv(["WIN","PLA","QIN","QPL","CWA","CWB","CWC",
                        "IWN","FCT","TCE","TRI","FF","QTT","DBL","TBL","DT","TT","SixUP"])
                ]);
                break;
            default:
                refresh = true;
        }

        //replayMsgCache();

        return refresh;
    }

    const loadQueries = (arr) => {
        clearTimeout(window.RCPoolsFetchTimer)
        Promise.all(arr).then((res) => {
            replayMsgCache();
        }).catch(error =>{
            apiErrorCallback(error, reloadRacingPools, {timer: window.RCPoolsFetchTimer})
        });
    }

    const replayMsgCache = () => {
        replayMsgQueue.current.forEach(({topic, message}) => {
            parseMessage(topic, message, contentRef);
        });
    }

    return {
        manualReload,
        reloadRacingPools
    };
}