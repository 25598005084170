import React, { useRef, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

const NoPoolMsg = () => {

    const { t, i18n } = useTranslation();
    const noPoolDiv = useRef();
    const navigate = useNavigate();

    return <>
        <div className='nopool'>
            <div className='nopoolmsg' ref={noPoolDiv} >
                {i18n.language=="en" ?
                <>
                    {t('LB_FB_NO_POOL_MSG1')}
                    <a href="#" onClick={()=> { navigate(`/${i18n.language}/football/home`) }} >{t('LB_FB_NO_POOL_MSG2')}</a>
                    {t('LB_FB_NO_POOL_MSG3')}
                </>
                :
                <>
                    {t('LB_FB_NO_POOL_MSG1')}<br/><br/>{t('LB_FB_NO_POOL_MSG2')}
                    <a href="#" onClick={()=> { navigate(`/${i18n.language}/football/home`) }} >{t('LB_FB_NO_POOL_MSG3')}</a>
                </>
                }
            </div>
        </div>
    </>

}
export default NoPoolMsg;