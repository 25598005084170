import moment from 'moment';
import { matchCompare, featureMatchCompare } from '../useFootballPush/common';
import { isFeaturedMatch } from '../../Common/Common';
export const getDisplayMatches = (matches, page, offset) => {
    const sortFnc = page != 'OFM' ? matchCompare : featureMatchCompare
    return matches?.filter(x=> isDisplayMatch(x, page, offset) && isDisplayPool(x, page)).sort(sortFnc) || [];
}

const INPLAY_MATCH_VALID_STATUS = ["PREEVENT",
    "FIRSTHALF",
    "FIRSTHALFCOMPLETED",
    "SECONDHALF",
    "FULLTIMECOMPLETED",
    "FIRSTHALFET",
    "FIRSTHALFETCOMPLETED",
    "SECONDHALFET",
    "SECONDHALFETCOMPLETED",
    "PENALTYKICK",
    "PENALTYKICKCOMPLETED"
];
const MIXALLUP_INPLAY_MATCH_VALID_STATUS = ["PREEVENT",
    "FIRSTHALF",
    "FIRSTHALFCOMPLETED",
    "SECONDHALF",
    "FULLTIMECOMPLETED",
    "FIRSTHALFET",
    "FIRSTHALFETCOMPLETED",
    "SECONDHALFET",
    "PENALTYKICKCOMPLETED"
];
const NON_INPLAY_MATCH_VALID_STATUS = ["PREEVENT"];

export const isDisplayMatch = (match, page, offset) => {
    if(!match) return false
    if ( page==="CASHOUT" ) {
        return true;
    }
    else if ( page==="OFM" ) {
        let showFeaturedMatch = match?.featureStartTime && moment(match.featureStartTime) <= moment().add(offset, 'seconds');
        return showFeaturedMatch && INPLAY_MATCH_VALID_STATUS.includes(match.status.toUpperCase());
    }
    else if ( page==="MIXALLUP" ) {
        return  MIXALLUP_INPLAY_MATCH_VALID_STATUS.includes(match.status.toUpperCase());
    }
    else if ( window.fbPageConfig[page]?.showInplayMatch) {
        return INPLAY_MATCH_VALID_STATUS.includes(match.status.toUpperCase());
    }
    return NON_INPLAY_MATCH_VALID_STATUS.includes(match.status.toUpperCase());
}

const isDisplayPool = (match, page) => {
    const isPreEvent = NON_INPLAY_MATCH_VALID_STATUS.includes(match.status.toUpperCase())
    switch ( page ) {
        case "CASHOUT":
            return true;
        case "INPLAY":
        case "INPLAY_ALL":
            return match.poolInfo.inplayPools.length>0;
        case "OFM":
        case "ALLODDS":
            return match.poolInfo.normalPools.length>0;
        case "MIXALLUP":
        case "MIXALLUP_DETAIL":
            return match.poolInfo.normalPools.some(i => [...window.fbConfig?.MixAllupPools, ...window.fbConfig.ExtraTimePools].includes(i))
        case "HOME":
        case "HAD":
            return match.poolInfo?.[isPreEvent ? 'normalPools' : 'inplayPools']?.some(x=> ["HAD", "EHA"].includes(x));
        case "HDC":
            return match.poolInfo?.[isPreEvent ? 'normalPools' : 'inplayPools']?.some(x=> ["HDC", "EDC"].includes(x));
        case "HIL":
            return match.poolInfo?.[isPreEvent ? 'normalPools' : 'inplayPools']?.some(x=> ["HIL", "EHL"].includes(x));
        case "CHL":
            return match.poolInfo?.[isPreEvent ? 'normalPools' : 'inplayPools']?.some(x=> ["CHL", "ECH"].includes(x));
        case "CRS":
            return match.poolInfo?.[isPreEvent ? 'normalPools' : 'inplayPools']?.some(x=> ["CRS", "ECS"].includes(x));
        case "TTG":
            return match.poolInfo?.[isPreEvent ? 'normalPools' : 'inplayPools']?.some(x=> ["TTG", "ETG"].includes(x));
        case "SGA":
            return match.poolInfo?.[isPreEvent ? 'normalPools' : 'inplayPools']?.some(x=> x.indexOf("SGA")>=0);
        case "MSP":
            return match.poolInfo?.[isPreEvent ? 'normalPools' : 'inplayPools']?.some(x=> x.indexOf("MSP")>=0);
        default:
            return match.poolInfo?.[isPreEvent ? 'normalPools' : 'inplayPools']?.includes(page);
    }
}

export const updateMixAllUpOFMMatches = ( contentRef, setContent) =>{
    contentRef.current.selection.type = 'OFM';
    contentRef.current.selection.value = [];
    contentRef.current.pageNum = 1;
    let offset = contentRef.current.offset;
    let filteredMatches = contentRef.current.listData.matches.filter(x=> isFeaturedMatch(x, offset));
    filteredMatches.sort((x,y) => {
        return x.featureMatchSequence > y.featureMatchSequence ? 1 : -1;
    });
    contentRef.current.matchData = { matches: filteredMatches };
    contentRef.current.page = 'MIXALLUP';
    contentRef.current.pageConfig = window.fbPageConfig[contentRef.current.page];
    contentRef.current.isAllUpDtls = false;
    contentRef.current.isLoading = false;
    setTimeout(()=>{
        setContent({ ...contentRef.current });
    })
}