import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate  } from 'react-router-dom';
import { EmptyTable } from '../../Common/EwinHomeCommon';
import { formatDateTime } from '../../Common/GlobalFunc';
import { getBallImage } from './Common';
import { LandingContext } from '../../Home/LangingPage';
import { useBetSlip, AddM6SlipFunc } from '../../BetSlip/BetSlipHooks'
import {updateOriginLocation} from '../../Common/ui-components/AddToSlipAnimation';
import { useWindowSize } from '../../Common/CommonHooks';
import { glassboxCustomEvent } from '../../Common/utils/glassbox';
import { isStartSell } from './Common';
import { isPmuEoD, eodMessage, scrollTo } from '../../Common/GlobalFunc';

const EwinHomeMarksixQuickPick = () => {
    const {t, i18n} = useTranslation()
    const navigate = useNavigate()
    const { isMobileSize } = useWindowSize();
    const context = useContext(LandingContext);
    const ballSize = 's';
    const [ randomBalls, setRandomBalls ] = useState([]);
    const [ betAdded, setBetAdded ] = useState(0);
    const { addToBetSlip } = useBetSlip()
    const { data, loading } = context.marksixData;

    useEffect(()=> {
        randomBall();
    }, []);


    const randomBall = () => {
        let nums = [];
        while(nums.length < 6){
            const rand = Math.ceil(Math.random()*49)
            if(!nums.includes(rand)) nums.push(rand)
        }
        nums.sort((x,y) => {return x-y;});
        if ( betAdded ) {
            setBetAdded(0);
        }
        setRandomBalls(nums);
    }

    const getMarksixAddbetData = () => {
        return [{
            numList:randomBalls,
            isFav:false,
            chance:1,
            multidraw:1,
            random:true,
            unitBet:10
        }]
    }

    const addRandomMarksixBet = (nextDraw) => {
        if(betAdded) return false
        const isSell = isStartSell(nextDraw);
        if (!isSell) {
            if (isPmuEoD() && eodMessage()?.length > 0) {
              return window.alert(eodMessage());
            } else {
              return window.alert(t('LB_M6_MSG_NOT_DIFINED'));
            }
        }
        if ( randomBalls.length===6 ) {
            const betList = AddM6SlipFunc(getMarksixAddbetData(), { pageName: 'EWINHOME', nextDraw: nextDraw })
            if ( addToBetSlip(betList) ) {
                if(isMobileSize){
                    setBetAdded(1);
                    updateOriginLocation(`.LYQuickPick .blockAddToSlip`,'MSCBall');
                    setTimeout(() => {
                        setBetAdded(2);
                    }, 700);
                }else{
                    setBetAdded(2);
                }
            }
        }
    }

    const ClickHereLink = () => {
        return <span className="blockMarksixLink" onClick={()=>{ scrollTo(0, 0); navigate(`/${i18n.language}/marksix`) }}>{t('LB_CLICK_HERE')}</span>
    }

    const QuickPickTable = () => {
        let lastDraw = data.lotteryDraws.length > 0 ? data.lotteryDraws[0] : {};
        let nextDraw = data.lotteryDraws.length > 1 ? data.lotteryDraws[1] : {};

        if ( !lastDraw || Object.keys(lastDraw).length===0 ){
            return <div className="blockBody M6NoInfo">{t('LB_M6_INFO_BLOCK_NOINFO')}</div>
        }
        let drawId = '-';
        let drawDt = ['-',''];
        if ( nextDraw && Object.keys(nextDraw).length>0 ){
            drawId = `${nextDraw.year.substring(2,4)}/${String(nextDraw.no).padStart(3, '0')}`;
            drawDt = formatDateTime(nextDraw.drawDate.substring(0, 10));
        }
        return <div className="blockBody">
            <div className="blockDrawResultHead bottom-border" id="bdrHead">
                <div>{t('LB_M6_BLOCK_RANDOM')}</div>
                <div className='df-date'>
                <div className="right-border">{drawId}</div>
                <div>{drawDt[0]}</div>
            </div>
            </div>
            <div className="blockQuickPickBody">
                {betAdded === 2 ? <div className="blockMsg">
                    <div>{t('LB_M6_BLOCK_RETRY_MSG1')}</div>
                    <div>{t('LB_M6_BLOCK_RETRY_MSG2')}<ClickHereLink />{t('LB_M6_BLOCK_RETRY_MSG3')}</div>
                    <div>{t('LB_M6_BLOCK_RETRY_MSG4')}</div>
                </div>
                :
                randomBalls.map(x=> {
                    return <div className="blockDrawBall">{getBallImage(x, ballSize)}</div>
                })}
            </div>
            <div className="blockButton">
                <div className="blockMarksixReset btn cta_m6" onClick={()=> { randomBall(); }}>{t('LB_RETRY')}</div>
                {betAdded !== 2 && <div className="blockAddToSlip cta_yellow btn" onClick={()=> { addRandomMarksixBet(nextDraw); glassboxCustomEvent("Add_to_Slip") }}>{t('LB_ADDTOSLIP')}</div>}
            </div>
        </div>
    }

    return (
        <section className="LYQuickPick">

            {loading ? <EmptyTable/> : <QuickPickTable />}

        </section>
    );
}
export default EwinHomeMarksixQuickPick;