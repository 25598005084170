import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { createFoSelection, createFoOtherSelection } from "./createFoSelection";

export const parseFoDtls = ( foPool, message, content,t) => {

    if ( message.lang==="zh-cn" ) {
        return;
    }
    let lang = message.lang==="zh-hk" ? "ch" : "en";
    if(message.dtl) {
        let mtg = content.meeting;
        let maxRace = Math.max(...mtg?.races.map(x=>x.no));
        foPool.otherSelNo = parseInt(message.dtl.othCmbStr);
        foPool.inplayUpTo = parseInt(message.dtl.inplayUptoRaNo);
        foPool.includeRaces = message.dtl.raComposition;
        foPool.lastUpdateTime = moment().format("YYYY-MM-DDTHH:mm:ss+08:00");
        let excludeRaces = [];
        for ( let i=1; i<=maxRace; i++ ) {
            if ( !message.dtl.raComposition.includes(i) )
                excludeRaces.push(i);
        }
        foPool.excludeRaces = excludeRaces;

        message.dtl.cmb?.forEach(cmb => {
            if ( cmb.str == foPool.otherSelNo ) {
                parseFoResOtherSheet(foPool, cmb, message, lang);
            }
            parseFoResSheet( foPool, cmb, message, lang);
        });    
    }
}

const parseFoResSheet = ( foPool, cmb, message, lang,t) => {
    let foSel = foPool.selections.filter(x=> x.combId===cmb.id)[0];
    if( !foSel ) {
        foSel = createFoSelection();
        foSel.combId = cmb.id;
        foSel.number = parseInt(cmb.str);
        foPool.selections.push(foSel);
    }
    if ( cmb.str === foPool.otherSelNo ) {
        foSel.code = "OTHER";
        foSel["name_" + lang] = t('LB_RC_' + foPool.oddsType + '_OTHERS_' + lang.toUpperCase());
    }
    else {
        let ref = cmb.sel?.[0]?.ref?.[0];
        if ( ref ) {
            foSel.code = ref.code;
            foSel["name_" + lang] = ref.sNm;
        }
    }
    let res = message.dtl.resSheet?.res?.filter(x=> x.id===cmb.id)[0];
    if ( res ) {
        foSel.remainingRides = parseInt(res.rmRides);
        foSel.scheduleRides = parseInt(res.schRides); 
        foSel.points = res.accPt;
        foSel.order = res.selOrdNo;
        let raRes = res.raRes;
        
        if(raRes){
            foSel.results = createSheetResult(raRes);
        }
    }
}

const parseFoResOtherSheet = (foPool, cmb, message, lang) => {
    cmb.sel?.[0]?.ref?.forEach(ref=> {
        let foSel = foPool.otherSelections.filter(x=> x.code===ref.code)[0];
        if( !foSel ) {
            foSel = createFoOtherSelection();
            foSel.code = ref.code;
            foPool.otherSelections.push(foSel);
        }
        foSel["name_" + lang] = ref.sNm;
        let res = message.dtl.othResSheet?.res?.filter(x=> x.refId===ref.code)[0];
        if ( res ) {
            foSel.remainingRides = parseInt(res.rmRides);
            foSel.scheduleRides = parseInt(res.schRides);
            foSel.points = res.accPt;
            foSel.order = res.ordNo;
            let raRes = res.raRes;

            if(raRes){
                foSel.results = createSheetResult(raRes);
            }
        }
    });
}


const createSheetResult = (raRes) => {
    let allResult = [];
    for(let j = 0 ; j < raRes.length; j++){
        let dHRemarks = raRes[j].dHRemarks;
        let result ={
            raceNo : raRes[j].raNo,
            points : raRes[j].accPt,
            count1st : raRes[j].numFirst,
            dhRmk1st : [1,3,5,7].includes(dHRemarks),
            point1st : raRes[j].accPt1st,
            count2nd : raRes[j].numSecond,
            dhRmk2nd : [2,3,6,7].includes(dHRemarks),
            point2nd : raRes[j].accPt2nd,
            count3rd : raRes[j].numThird,
            dhRmk3rd : [4,5,6,7].includes(dHRemarks),
            point3rd : raRes[j].accPt3rd,
            count4th :  Math.floor(raRes[j].numFourthDmtr)/raRes[j].numFourthDmtr ,
            numerator4th : raRes[j].numFourthNmtr %raRes[j].numFourthDmtr,
            denominator4th : raRes[j].numFourthDmtr
     
        }
        allResult.push(result);
    }
    return allResult;
}