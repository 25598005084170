import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { PrintNow, getDayOfWeek2 } from '../../Common/home-common';
import { useWindowSize } from '../../Common/CommonHooks';

// import '../../../App.css';
import '../../../css/football/sellinghours.css';


const SellingHours = () => {
    const { t, i18n } = useTranslation();
    const [content, setContent] = useState({})
    const { isMobileSize } = useWindowSize();
    const today = moment();

    useEffect(()=>{
        //
        fetch('/dev/football/sellinghours.json', { method: "GET" })
        .then(res => res.json())
        .then(
          (result) => {
            setContent({...result});
          },
          (error) => {
          }
        )

    }, []);

    const SellingHoursTable = () => {
        return <><div className="shHeader">
            <div className="shRow">
                <div>{t('LB_FB_SELLINGHOURS_DATE')}</div>
                <div>{t('LB_FB_SELLINGHOURS_MATCHDAY')}</div>
                <div>
                    <a href={content.ocblink[i18n.language]}>{t('LB_FB_SELLINGHOURS_OCB')}</a>
                    <div className="shNote">1</div>
                </div>
                <div>
                    <div><a href={content.onlinelink[i18n.language]}>{t('LB_FB_SELLINGHOURS_ONLINE1')}</a></div>
                    <div><a href={content.onlinelink[i18n.language]}>{t('LB_FB_SELLINGHOURS_ONLINE2')}</a></div>
                </div>
                <div>
                <a href={content.telebetlink[i18n.language]}>{t('LB_FB_SELLINGHOURS_TELEBET')}</a>
                    <div className="shNote">2</div>
                </div>
                <div>
                    <div>{t('LB_FB_SELLINGHOURS_RACECOURSES1')}</div>
                    <div>{t('LB_FB_SELLINGHOURS_RACECOURSES2')}</div>
                </div>
            </div>
        </div>

        <div className="shTable">
            {content.data.map(item=> {
                let dt = moment(item.date).format('D/M');
                let isToday = today.isSame(item.date, 'day');
                return <div className={`shRow ${isToday ? "hl" : ""}`}>
                    <div className="right-border dtStickyCol">
                        <div>{dt}</div>
                        <div className="shWeekDay">{getDayOfWeek2(item.date, t)}</div>
                    </div>
                    <div className="right-border">
                        {item.isMatchDay && <div className="matchdayIcon"></div>}
                    </div>
                    <div className="right-border">
                        {item.ocb[i18n.language].map(msg=> {
                            return <div>{msg}</div>
                        })}
                    </div>
                    <div className="right-border">
                        {item.online[i18n.language].map(msg=> {
                            return <div>{msg}</div>
                        })}
                    </div>
                    <div className="right-border">
                        {item.telebet[i18n.language].map(msg=> {
                            return <div>{msg}</div>
                        })}
                    </div>
                    <div>
                        {item.raceCourse[i18n.language].map(msg=> {
                            return <div>{msg}</div>
                        })}
                    </div>
                </div>
            })}
        </div>
        </>
    }

    const SellingHoursTableMobile = () => {
        return <div className="shTableMobile">

            <div className="dtStickyCol">{t('LB_FB_SELLINGHOURS_DATE')}</div>
            <div>{t('LB_FB_SELLINGHOURS_MATCHDAY')}</div>
            <div className="shTitleOCB">
                <a href={content.ocblink[i18n.language]}>{t('LB_FB_SELLINGHOURS_OCB')}</a>
                <div className="shNote">1</div>
            </div>
            <div className="shTitleOnline">
                <div><a href={content.onlinelink[i18n.language]}>{t('LB_FB_SELLINGHOURS_ONLINE1')}</a></div>
                <div><a href={content.onlinelink[i18n.language]}>{t('LB_FB_SELLINGHOURS_ONLINE2')}</a></div>
            </div>
            <div className="shTitleTelebet">
                <a href={content.telebetlink[i18n.language]}>{t('LB_FB_SELLINGHOURS_TELEBET')}</a>
                <div className="shNote">2</div>
            </div>
            <div className="shTitleRaceCourse">
                <div>{t('LB_FB_SELLINGHOURS_RACECOURSES1')}</div>
                <div>{t('LB_FB_SELLINGHOURS_RACECOURSES2')}</div>
            </div>

            {content.data.map((item, idx)=> {
                let dt = moment(item.date).format('D/M');
                let isToday = today.isSame(item.date, 'day');
                let bgCss = idx%2==0 ? 'shOdd': 'shEven';
                let hlCss = isToday ? 'shHL': bgCss;
                return <>
                    <div className={`shRowMobile dtStickyCol ${hlCss}`}>
                        <div>{dt}</div>
                        <div className="shWeekDay">{getDayOfWeek2(item.date, t)}</div>
                    </div>
                    <div className={`shRowMobile right-border ${hlCss}`}>
                        {item.isMatchDay && <div className="matchdayIcon"></div>}
                    </div>
                    <div className={`shRowMobile right-border ${hlCss}`}>
                        {item.ocb[i18n.language].map(msg=> {
                            return <div>{msg}</div>
                        })}
                    </div>
                    <div className={`shRowMobile right-border ${hlCss}`}>
                        {item.online[i18n.language].map(msg=> {
                            return <div>{msg}</div>
                        })}
                    </div>
                    <div className={`shRowMobile right-border ${hlCss}`}>
                        {item.telebet[i18n.language].map(msg=> {
                            return <div>{msg}</div>
                        })}
                    </div>
                    <div className={`shRowMobile ${hlCss}`}>
                        {item.raceCourse[i18n.language].map(msg=> {
                            return <div>{msg}</div>
                        })}
                    </div>
                </>
            })}
        </div>
    }

    return content.data ? <div className={`sellingHours`}>
        <div className="shTitle">
            <div>{t('LB_FB_SELLINGHOURS_TITLE')}</div>
            {!isMobileSize ? <div className='printBtn-container' onClick={PrintNow} >
                <div className='printBtn-image' />
                <a href="#">{t('LB_PRINT')}</a>
            </div> : null}
        </div>

        {isMobileSize ? <SellingHoursTableMobile /> : <SellingHoursTable />}

        <div className="shNotes">
            <div className="shNote">1</div>
            <div>
                {content.notes1[i18n.language].map(msg=> {
                    return <div dangerouslySetInnerHTML={{  __html: msg }}></div>
                })}
            </div>
        </div>

        <div className="shNotes">
            <div className="shNote">2</div>
            <div>
                {content.notes2[i18n.language].map(msg=> {
                    return <div dangerouslySetInnerHTML={{  __html: msg }}></div>
                })}
            </div>
        </div>
    </div> : null
}
export default SellingHours;