export const RGPBannerData = {
    ch: {
        "data": {
            "item": {
                "key": "RGPBanner",
                "enable": {
                    "value": "1"
                },
                "header": {
                    "value": "博彩不要有節制"
                },
                "text1": {
                    "value": "<p>testing未滿十八歲人士不得投注或進入可投注的地方。</p>"
                },
                "text2": {
                    "value": "<p>Testing向非法或海外莊家下注，最高可被判監禁九個月及罰款三萬港元。</p>\n<p>切勿沉迷賭博，如需尋求輔導協助，可致電平和基金熱線1834 633。</p>"
                },
                "icon": {
                    "src": "/-/media/Sites/JCBW/rgp_text_chi_970.gif?rev=431de0410a1d4f1982b8c4c782c43cfc",
                    "width": 70,
                    "height": 50
                },
                "itemRedirection": {
                    "value": "Other link"
                },
                "itemLink": {
                    "value": "https://www.hkjc.com/responsible-gambling/ch/index.aspx"
                },
                "itemPage": {
                    "value": ""
                },
                "redirectMethod": {
                    "value": "Popup"
                },
                "pH": {
                    "value": "600"
                },
                "pW": {
                    "value": "900"
                },
                "pX": {
                    "value": "20"
                },
                "pY": {
                    "value": "20"
                }
            }
        }
    },
    en: {
        "data": {
            "item": {
                "key": "RGPBanner",
                "enable": {
                    "value": "1"
                },
                "header": {
                    "value": "No Play Responsibly"
                },
                "text1": {
                    "value": "<p>testing No person under 18 is allowed to bet or enter premises where bets are accepted.</p>"
                },
                "text2": {
                    "value": "<p>Testing The maximum penalty for betting with illegal or overseas bookmakers is 9 months' imprisonment and a HK$30,000 fine.</p>\n<p>Don't gamble your life away. Call Ping Wo Fund hotline 1834 633 if you need help or counselling.</p>"
                },
                "icon": {
                    "src": "/-/media/Sites/JCBW/rgp_text_eng_970.gif?rev=2b742e675a8f4e1a801daa5fc54f50a8",
                    "width": 72,
                    "height": 50
                },
                "itemRedirection": {
                    "value": "Other link"
                },
                "itemLink": {
                    "value": "https://www.hkjc.com/responsible-gambling/en/index.aspx"
                },
                "itemPage": {
                    "value": ""
                },
                "redirectMethod": {
                    "value": "Popup"
                },
                "pH": {
                    "value": "600"
                },
                "pW": {
                    "value": "900"
                },
                "pX": {
                    "value": "20"
                },
                "pY": {
                    "value": "20"
                }
            }
        }
    }
}