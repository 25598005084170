import React, { useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';

const MobileTabSet = (props) => {
  const { t } = useTranslation();
  const cssName = props.className ? "." + props.className : "";

  // 如果返回的数据带有checked，下面代码可以不要
  const setLineStyle = (e) => {
    let totalWidth = document.querySelector(`.mobile-tab-set${cssName}`).clientWidth;
    let line = document.querySelector(`.mobile-tab-set${cssName} > .line`);
    let idx = props.items.findIndex(x=> x.type===e.currentTarget.getAttribute('value'));
    line.style.left = (idx * totalWidth / props.items.length) + "px";
  }
  let idx = false
  return (
    <div className={`${props.className || ""} mobile-tab-set`}>
        {props.items.map((_cItem, _cIndex) => {
            if(_cItem.checked) idx = _cIndex
          return <div id={`subTypeMobile${_cItem.type}`} className={`subtype-${_cItem.type}`} value={_cItem.type} onClick={(e) => { if ( !_cItem.disabled ) { setLineStyle(e); props.handleRadioClick(_cItem.type); } }}>
            <div value={_cItem.type}>{t(_cItem.name1)}</div>
            {_cItem.name2!=null ? <div>{t(_cItem.name2)}</div> : null}
          </div>;
        })}
        <div className="line" style={{left: props.items.length && idx !== false ? `${idx/props.items.length*100}%` : ''}}></div>
    </div>);
}
export default MobileTabSet