import React from 'react';
import MatchTable from '../Common/MatchTable';
import { Remark } from '../Common/MatchBase';

const FCS = () => {
    return <section className='FCS'>
        <MatchTable betType='FCS'/>
        <Remark />
    </section>
}
export default FCS;