import React from 'react';
import MatchTable from '../Common/MatchTable';
import { Remark } from '../Common/MatchBase';

const HHA = () => {
    return (
        <section className='HHA'>
            <MatchTable betType='HHA' />
            <Remark />
        </section>
    )
}
export default HHA;