import React from 'react';
import { useTranslation } from 'react-i18next';
import { pad } from './Common';

const NthColsTable = ({foPool, combStrs, lblFunc, isMobile, getOddsVal, expand, isCloseLine }) => {
    const { t } = useTranslation();
    let line = isCloseLine ? foPool?.closedLines[0] : foPool?.lines[0];

    return combStrs.map((str, i) => {
        return !isMobile || expand || i<3 ? <div>
            <div className='goals-number'>{lblFunc(str)}</div>
            {getOddsVal(line, str, isMobile)}
        </div> : null
    })
}
export default NthColsTable