import React, { useContext, useMemo,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../../../StandardPage';
import { getRacingTopics } from './common';
import { parseTopic } from './parseTopic';

const useRacingPush = (contentRef) => { 
    const { t } = useTranslation();
    const globalContext = useContext(GlobalContext);
    const { pushState, isPushing, resubscribeTopic, pubCacheTopic, clearMessage } = globalContext.mqttClient;

    const resubscribe = () => {
        if ( window.globalConfig.RC_ODDS_PUSH ) {
            const topics = getRacingTopics(contentRef.current)
            clearMessage()
            pubHRCacheTipic(topics)
            resubscribeTopic(topics);
        }
    }
    const pubHRCacheTipic = (topics) =>{
        topics.forEach(topic =>{
            let cacheTopicInfo = parseTopic(topic);
            if(cacheTopicInfo.raceStatus || cacheTopicInfo.displayType == "detail"){
                pubCacheTopic(topic, 1)
            }
            else{
                pubCacheTopic(topic, 0)
            }    
        })
    }

    useEffect(()=> {
        if ( isPushing && window.globalConfig.RC_ODDS_PUSH ) {
            resubscribe();
        }
    }, [isPushing,contentRef.current.venue])


    const topicInfo = useMemo(()=> {parseTopic(pushState.current.topic)}, [pushState.current.topic])

    return {
        topic: pushState.current.topic,
        message: pushState.current.message,
        topicInfo,
        isPushing,
        resubscribe
    };
    
}

export default useRacingPush