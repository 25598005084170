import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RacingContext } from '../../Home/RacingPage';

import OddsTable from '../Common/RCOddsTable';
import OddsDropLegend from '../Common/OddsDropLegend';
import RefreshTime from '../../Common/ui-components/RefreshTime';
import Loading from '../../Common/ui-components/Loading';

const PWin = (props) => {
    const { t } = useTranslation();
    const context = useContext(RacingContext);
    const mtg = context.content.meeting;



    const Content = useCallback(({context}) => {
        const RCColumns = {
            alw: true,
            no: true,
            colour: true,
            horseName: true,
            rcNote: context.content.isPeNoteEnable,
            draw: true,
            wt: true,
            jocky: true,
            trainer: true,
            bodyWT: false,
            rtg: false,
            gear: false,
            last6: false,
            pwin0: true,
            pwin1: true,
            win: true,
            place: true,
            wp: false
        };
        return (
            <section className={context.content.page}>
                <RefreshTime product="racing" />
                <OddsTable columns={RCColumns} />
                <OddsDropLegend />
            </section>
        );
    },[]);


    if (mtg == null) {
        return <Loading/>
    } else if (!mtg.currentNumberOfRace) {
        return <div style={{marginTop: "10px", marginLeft: "8px"}} id='PWIN_MSG'>{t('LB_RC_PWIN_MSG')}</div>
    } else {
        return (
            <Content context={context}/>
        );
    }
}
export default PWin;
