import { useState, useEffect, useRef } from 'react';
import { getPersonalSettings } from '../../Home/PersonalSettings/config' 

export const useRacingNotesQuery = () => {
    const [noteData, setNoteData] = useState(null);
    const [settingEnable, setSettingEnable] = useState(getPersonalSettings('racingNotes'));
    const [globalEnable, setGlobalEnable] = useState(false);
    const controller = new AbortController();
    const signal = controller.signal;

    const uuidv4 = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    const para = { 
        variables: {},
        userSession: {
            channelId: 4,
            sessionId: uuidv4()
        }
    }

    const loadNotesData = () => {
        if ( !window.globalConfig.ENABLE_PE_NOTE || !globalEnable || !settingEnable )
            return;

        let url = window.globalConfig.PENOTES_URL + window.globalConfig.PENOTE_ENDPOINT.query;
        let mth = 'POST';
        if ( window.globalConfig.PENOTE_ENDPOINT.dummy ) {
            url = window.globalConfig.PENOTE_ENDPOINT.dummyquery;
            mth = 'GET';
        }
        fetch(url, {
            method: mth,
            headers: {
                'Content-Type': 'application/json'
            },
            body: mth==='POST' ? JSON.stringify(para) : null,
            credentials: 'include',
            signal: signal
        }).then(res => res.json())
        .then((result) => {
            setNoteData({...result});
            },
            (error) => {
        });
    }

    useEffect(()=> {
        const autoRefreshInterval = setInterval(() => {
            let s = getPersonalSettings('racingNotes')!==0;
            if ( s!==settingEnable ) {
                setSettingEnable(s);
            }
        }, 1000);
        return () => clearInterval(autoRefreshInterval);
    }, []);

    return {
        loadNotesData,
        noteData,
        globalEnable,
        setGlobalEnable,
        settingEnable,
        setSettingEnable        
    };
}