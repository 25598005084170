import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../../Common/ConfigHelper';
import { getSessionId } from '../../BetSlipAPI';
import { getFPSErrorMsg, maskPhoneNumber } from '../utils';
import { textFormatAmountFn } from '../../../Common/GlobalFunc';
import { postUpdateFpsInfo_IB } from '../FundsTransferIBAPI';

const LinkResult = ({ props }) => {
    const {
        nbaData,
        FPSErrorList,
        setNbaData,
        curStep,
        setCurStep,
        selectedItem,
        setSelectedItem,
        setLoadingStatus,
        linkResult,
        setResultShowStatus,
        isCheckStatus,
        bankList
    } = props;
    const { t, i18n } = useTranslation();
    const eDDAStatus = linkResult.errCode ? linkResult.errCode : linkResult.eDDAStatus;

    useEffect(async () => {
        if (curStep === 3) {
            switch (eDDAStatus) {
                case 'AP':
                    setResultShowStatus('SUCCESSFUL');
                    break;
                case 'RJ':
                    setResultShowStatus('REJECTED');
                    await postUpdateFpsInfo_IB({
                        language: i18n.language === 'en' ? '0' : '1',
                        params: {
                            bankAccountType: linkResult.bettingAccount.bankAccount[0].bankAccountType,
                            bankCode: linkResult.bettingAccount.bankAccount[0].bankCode,
                            bankAccountNo: linkResult.bettingAccount.bankAccount[0].bankAccountNo,
                            eDDAStatus: 'NO'
                        }
                    });
                    break;
                case 'IP':
                    setResultShowStatus('SUBMITTED');
                    break;
                case 'TIMEOUT':
                    setResultShowStatus('TIMEOUT');
                    break;
                default:
                    setResultShowStatus('REJECTED');
                    break;
            }
            updateLinkStatus();
        }
    }, []);

    const updateLinkStatus = () => {
        if (['AP', 'RJ', 'IP', 'NO'].includes(eDDAStatus)) {
            const nba = selectedItem.nba; // 'nba' + linkResult.bettingAccount.bankAccount[0].bankAccountType;

            let tempNbaData;
            if (linkResult.isLinkSingleLeg) {
                tempNbaData = JSON.parse(JSON.stringify(nbaData));
                if (eDDAStatus == 'AP') {
                    tempNbaData.link[nba].FPS.EDDASingleLegStatus = 0;
                    tempNbaData.link[nba].FPS.isSingleLeg = false;
                    tempNbaData.deposit[nba].FPS.EDDASingleLegStatus = 0;
                    tempNbaData.deposit[nba].FPS.isSingleLeg = false;
                    if (nba == 'nba1') {
                        tempNbaData.withdrawal[nba].FPS.isSingleLeg = false;
                        tempNbaData.withdrawal[nba].FPS.EDDASingleLegStatus = 0;
                    }
                }

                tempNbaData.deposit[nba].FPS.disabled =
                    linkResult.bettingAccount.bankAccount[0].FPSPStatus == '1' ? false : true;
                tempNbaData.link[nba].FPS.disabled =
                    linkResult.bettingAccount.bankAccount[0].FPSPStatus == '1' ? false : true;
                if (nba == 'nba1') {
                    tempNbaData.withdrawal[nba].FPS.disabled =
                        linkResult.bettingAccount.bankAccount[0].FPSPStatus == '1' ? false : true;
                }

                tempNbaData.link[nba].FPS.notifyEDDASingleLegErrorType = linkResult.errorType;
                tempNbaData.link[nba].FPS.notifyEDDASingleLegErrorCode = linkResult.errorCode;

                setNbaData(tempNbaData);
            } else {
                if (eDDAStatus == 'AP' || eDDAStatus == 'IP') {
                    tempNbaData = JSON.parse(JSON.stringify(nbaData));
                    tempNbaData.link[nba].FPS.EDDASingleLegStatus = parseInt(
                        linkResult.bettingAccount.bankAccount[0].EDDASingleLegStatus
                    );
                    tempNbaData.link[nba].FPS.isSingleLeg =
                        linkResult.bettingAccount.bankAccount[0].EDDASingleLegStatus == '1';

                    tempNbaData.deposit[nba].FPS.EDDASingleLegStatus = parseInt(
                        linkResult.bettingAccount.bankAccount[0].EDDASingleLegStatus
                    );
                    tempNbaData.deposit[nba].FPS.isSingleLeg =
                        linkResult.bettingAccount.bankAccount[0].EDDASingleLegStatus == '1';

                    if (nba == 'nba1') {
                        tempNbaData.withdrawal[nba].FPS.isSingleLeg =
                            linkResult.bettingAccount.bankAccount[0].EDDASingleLegStatus == '1';
                        tempNbaData.withdrawal[nba].FPS.EDDASingleLegStatus = parseInt(
                            linkResult.bettingAccount.bankAccount[0].EDDASingleLegStatus
                        );
                    }

                    if (eDDAStatus == 'AP') {
                        tempNbaData.deposit[nba].FPS.show = true;
                        if (nba == 'nba1') {
                            tempNbaData.withdrawal[nba].FPS.show = true;
                            tempNbaData.withdrawal[nba].FPS.disabled =
                                linkResult.bettingAccount.bankAccount[0].FPSPStatus == '1' ? false : true;
                        }
                    }
                }

                let newNbaData;
                if (tempNbaData != null) {
                    newNbaData = {
                        ...tempNbaData,
                        link: {
                            ...tempNbaData.link,
                            [selectedItem.nba]: {
                                ...tempNbaData.link[selectedItem.nba],
                                FPS: {
                                    ...tempNbaData.link[selectedItem.nba].FPS,
                                    linkStatus: eDDAStatus,
                                    linkErrorType: linkResult.errorType,
                                    linkErrorCode: linkResult.errorCode
                                }
                            }
                        },
                        deposit: {
                            ...tempNbaData.deposit,
                            [selectedItem.nba]: {
                                ...tempNbaData.deposit[selectedItem.nba],
                                FPS: {
                                    ...tempNbaData.deposit[selectedItem.nba].FPS,
                                    linkStatus: eDDAStatus,
                                    disabled: linkResult.bettingAccount.bankAccount[0].FPSPStatus == '1' ? false : true
                                }
                            }
                        }
                    };

                    if (selectedItem.nba == 'nba1') {
                        newNbaData = {
                            ...newNbaData,
                            withdrawal: {
                                ...tempNbaData.withdrawal,
                                [selectedItem.nba]: {
                                    ...tempNbaData.withdrawal[selectedItem.nba],
                                    FPS: {
                                        ...tempNbaData.withdrawal[selectedItem.nba].FPS,
                                        linkStatus: eDDAStatus,
                                        disabled:
                                            linkResult.bettingAccount.bankAccount[0].FPSPStatus == '1' ? false : true
                                    }
                                }
                            }
                        };
                    }
                } else {
                    newNbaData = {
                        ...nbaData,
                        link: {
                            ...nbaData.link,
                            [selectedItem.nba]: {
                                ...nbaData.link[selectedItem.nba],
                                FPS: {
                                    ...nbaData.link[selectedItem.nba].FPS,
                                    linkStatus: eDDAStatus,
                                    linkErrorType: linkResult.errorType,
                                    linkErrorCode: linkResult.errorCode
                                }
                            }
                        }
                    };
                }
                setNbaData(newNbaData);
            }
        }
    };

    const getSummary = () => {
        switch (eDDAStatus) {
            case 'AP':
                if (isCheckStatus) {
                    return 'LB_BS_FT_LINK_RESULT_AP_CHK_' + selectedItem.nba;
                }
                return 'LB_BS_FT_LINK_RESULT_AP_' + selectedItem.nba;
            case 'RJ':
                return 'LB_BS_FT_LINK_RESULT_RJ';
            case 'IP':
                if(curStep === 5){
                    return 'LB_BS_FT_LINK_RESULT_IP_SUM1';
                }
                return 'LB_BS_FT_LINK_RESULT_IP_SUM';
            default:
                return '';
        }
    };

    const fullName = () => {
        //always display english name as requeted by PO (PRJ1027-4717)
        return window.globalConfig.IS_IB
            ? sessionStorage.getItem('acc_name')
            : sessionStorage.getItem('login_firstNameEn') + ' ' + sessionStorage.getItem('login_lastNameEn');
    };

    const bankName = () => {
        if (i18n.language === 'en') {
            return nbaData.link[selectedItem.nba].bankInfo.name_en;
        } else {
            return nbaData.link[selectedItem.nba].bankInfo.name_ch;
        }
    };

    const bankAcc =
        eDDAStatus == 'RJ'
            ? nbaData.link[selectedItem.nba].bankAccountNo
            : nbaData.link[selectedItem.nba].bankAccountNoMasked;
    const mobileNo = window.globalConfig.IS_IB
        ? sessionStorage.getItem('mobile_country')
        ? `+${sessionStorage.getItem('mobile_country')}-${
            eDDAStatus == 'RJ'
                ? sessionStorage.getItem('mobile_num')
                : maskPhoneNumber(sessionStorage.getItem('mobile_num'))
        }`
        : t('LB_BS_FT_LINK_NO_MOBILE')
        : sessionStorage.getItem('login_maskedMobilePhoneNumber')
        ? sessionStorage.getItem('login_maskedMobilePhoneNumber')
        : t('LB_BS_FT_LINK_NO_MOBILE');

    const rejectedReason = getFPSErrorMsg(linkResult, FPSErrorList, i18n.language);
    const dailyDepMaxAmt = window.globalConfig.IS_IB
        ? textFormatAmountFn(linkResult.dailyDepMaxAmt, 2)
        : textFormatAmountFn(linkResult.dailyDepMaxAmt / 100, 2);

    const onClickDone = () => {
        if (curStep === 3) {
            setSelectedItem({});
            setResultShowStatus('');
        }
        setCurStep(0);
    };

    return (
        <div>
            {curStep === 3 && <div className="link-summary">{t('LB_BS_FT_SUMMARY')}</div>}
            <div className="link-summary">{t(getSummary())}</div>
            {eDDAStatus == 'TIMEOUT' ? (
                <>
                    <div className="FT-form-content">
                        <div className="link-row noline">
                            <div>{t('LB_BS_FT_LINK_RESULT_TO')}</div>
                        </div>
                    </div>
                    <div className="deposit-btnArea link-bottom">
                        <div className="deposit-btn doneBtn link-btn-active" onClick={onClickDone}>
                            {t('LB_BETSLIP_DONE')}
                        </div>
                    </div>
                </>
            ) : (
                <>
                    {' '}
                    <div className="FT-form-content">
                        <div className="link-row">
                            <div className="link-l">{t('LB_BS_FT_LINK_FULLNAME')}:</div>
                            <div className="link-r">{fullName()}</div>
                        </div>
                        <div className="link-row">
                            <div className="link-l">{t('LB_BS_FT_LINK_BANKNAME')}:</div>
                            <div className="link-r">{bankName()}</div>
                        </div>
                        <div className="link-row">
                            <div className="link-l">{t('LB_BS_FT_LINK_BANKACC')}:</div>
                            <div className="link-r">{bankAcc}</div>
                        </div>
                        <div className="link-row">
                            <div className="link-l">{t('LB_BS_FT_LINK_MOBILE')}:</div>
                            <div className="link-r">{mobileNo}</div>
                        </div>
                        {(eDDAStatus == 'AP' || eDDAStatus == 'IP') && curStep === 3 && (
                            <div className="link-row">
                                <div className="link-l">{t('LB_BS_FT_LINK_LIMIT')}:</div>
                                <div className="link-r">${dailyDepMaxAmt}</div>
                            </div>
                        )}
                        {eDDAStatus == 'AP' && (
                            <div className="link-row noline">
                                <div>{t('LB_BS_FT_LINK_LIMIT_INC')}</div>
                            </div>
                        )}
                        {eDDAStatus == 'IP' && (
                            <div className="link-row noline">
                                <div
                                    className=""
                                    dangerouslySetInnerHTML={{ __html: t(curStep === 5?'LB_BS_FT_LINK_RESULT_IP_MSG1':'LB_BS_FT_LINK_RESULT_IP_MSG') }}
                                ></div>
                            </div>
                        )}
                    </div>
                    {eDDAStatus == 'RJ' && (
                        <div className="link-summary">
                            <div>{t('LB_BS_FT_LINK_RESP_REJECT')}</div>
                        </div>
                    )}
                    <div className="deposit-btnArea">
                        <div className="deposit-btn doneBtn link-btn-active" onClick={onClickDone}>
                            {t('LB_BETSLIP_DONE')}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default LinkResult;
