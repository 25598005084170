import React, { useContext } from 'react';
import NextDrawInfoTable from '../Common/NextDrawInfoTable';
import BankerSelectedNumTable from '../Common/BankerSelectedNumTable';
import MyFavourites from '../Common/MyFavourites';
import { MarksixContext } from '../../Home/MarksixPage';


const Banker = () => {
    const context = useContext(MarksixContext);
 
    return (
        <section className={context.pageName}>
            <NextDrawInfoTable />
            <BankerSelectedNumTable />
            <MyFavourites />
        </section>
    );
}
export default Banker;
