import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { isLocalMeeting } from '../../Common/home-common';
import { RacingContext } from '../../Home/RacingPage';
import { GlobalContext } from '../../../StandardPage';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import AllUpFormulaDropdown from './AllUpFormulaDropdown';
import { useJockeyTrainerRankingQuery } from '../Data/RacingStatHooks';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';

const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
        borderRadius: "3px",
        backgroundColor: "#D8D8D8"
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
};

const CustomScrollbars = props => (
    <Scrollbars
        renderThumbHorizontal={renderThumb}
        renderThumbVertical={renderThumb}
        autoHide
        // style={{ height: 'calc(100% - 30px)' }}
        autoHeight
        autoHeightMin={0}
        autoHeightMax={270}
        {...props}
    />
);

const JTCDropdownBtn=(props)=>{
    const { t } = useTranslation();
    const {title,items,onClickFunc,curSel}=props;
    const curVal=items.filter(x => x.key == curSel)[0].val;
    return <>
        <DropdownButton
            as={ButtonGroup}
            key={`jkcAllup`}
            id={`jkcAllup`}
            variant="Formulas"
            className="dropdown-allup-formulas jkcAllup"
            title={<><div title={curVal}>{curVal}</div><ArrowIcon direction='down' size={24}/></>}
            align="end"
            disabled={items.length <= 0}
        >
            <Dropdown.Item className='rc-jtc-dropdown-header' eventKey="jkcAllupsub-header" value={t(props.title)} key="jkcAllupsub-header" >{props.title}</Dropdown.Item>
                <CustomScrollbars >
                    <div className="rc-jtc-dropdown-body">
                        {items.map((_cItem, _cIndex) => {
                            return <Dropdown.Item value={_cItem.key} className="dropdown-item rc-jtc-dropdown-item" onClick={onClickFunc}>{_cItem.val}</Dropdown.Item>
                        })}
                    </div>
                </CustomScrollbars>
            </DropdownButton>
        </>
}

const JTComboSearch = (props) => {
    const { t, i18n } = useTranslation();
    const context = useContext(RacingContext);
    const globalContext = useContext(GlobalContext);
    const [ jtLoading, jockeyData, trainerData ] = useJockeyTrainerRankingQuery();
    const mtg = context.content.meeting;
    const [jSel, setJSel] = useState('ALL');
    const [tSel, setTSel] = useState('ALL');
    const [wSel, setWSel] = useState('ALL');

    let output = null;

    let jMenuItem = [{ key: "ALL", val: t('LB_RC_JTC_All') }];
    let tMenuItem = [{ key: "ALL", val: t('LB_RC_JTC_All') }];
    let wMenuItem = [{ key: "ALL", val: t('LB_RC_JTC_All') },
    { key: "0", val: t('LB_RC_JTC_1_TO_5') },
    { key: "1", val: t('LB_RC_JTC_5_TO_15') },
    { key: "2", val: t('LB_RC_JTC_ABOVE_15') }];

    const getAllJockeysFromMeeting = () => {
        let list = [];
        if ( isLocalMeeting(mtg.venueCode) ) {
            mtg.races.forEach(race => {
                race.runners.forEach(runner => {
                    if ( runner.status.toUpperCase()!="STANDBY" && list.filter(x => x.key==runner.jockey.code).length==0 )
                        list.push({ key: runner.jockey.code, val: runner.jockey['name_'+i18n.language]});
                });
            });
            list.sort((a,b)=> { return a.key.localeCompare(b.key); });
            list = [{ key: "TOP5", val: t('LB_RC_JTC_TOP5_JOCKEYS') }, ...list];
        }
        return list;
    }

    const getAllTrainersFromMeeting = () => {
        let list = [];
        if ( isLocalMeeting(mtg.venueCode) ) {
            mtg.races.forEach(race => {
                race.runners.forEach(runner => {
                    if ( runner.status.toUpperCase()!="STANDBY" && list.filter(x => x.key==runner.trainer.code).length==0 )
                        list.push({ key: runner.trainer.code, val: runner.trainer['name_'+i18n.language]});
                });
            });
            list.sort((a,b)=> { return a.key.localeCompare(b.key); });
            list = [{ key: "TOP5", val: t('LB_RC_JTC_TOP5_TRAINERS') }, ...list];
        }
        return list;
    }

    jMenuItem = jMenuItem.concat(getAllJockeysFromMeeting());
    tMenuItem = tMenuItem.concat(getAllTrainersFromMeeting());

    const toggleJTCMenu = (val, func) => {
        func(!val);
    }

    const handleClickJTCMenu = (event, func) => {
        let value = event.target.getAttribute("value");
        func(value);
    }

    const processJTCSearch = () => {
        props.processJTCSearch([jSel, tSel, wSel,
            jockeyData.map(x=> x.code).slice(0,5), trainerData.map(x=> x.code).slice(0,5)]);
    }

    useEffect(() => {
        globalContext.resetGlobalState();
    }, [])

    useEffect(() => {
        setJSel('ALL');
        setTSel('ALL');
        setWSel('ALL');
    }, [context.content.venue])

    const JTCSearchMenuProps = {jMenuItem, handleClickJTCMenu, setJSel, jSel, tMenuItem, setTSel, tSel, wMenuItem, setWSel, wSel}

    return (<div className="rc-jtc-search-box">
        <div className="rc-jtc-search-item">
            <JTCSearchMenu {...JTCSearchMenuProps}/>
            <div className="rc-jtc-btn">
                <button className="race-btn cta_hr" onClick={processJTCSearch} id={`jtc_confrim`}>{t('LB_RC_JTC_CONFIRM')}</button>
            </div>
        </div>
        <div className="rc-jtc-allup-dropdown">
            <AllUpFormulaDropdown />
        </div>
        {/* <div id="block-ui-wrapper" className={jMenu || tMenu || wMenu ? 'active' : ''} onClick={closeWrapper}></div> */}
    </div>);
}
export default JTComboSearch;

function JTCSearchMenu({jMenuItem, handleClickJTCMenu, setJSel, jSel, tMenuItem, setTSel, tSel, wMenuItem, setWSel, wSel}) {
    const {t} = useTranslation()
    return <ul key="rc-jtc-ul">
        <li id={`dropdown_jockey`}>
            <span className='rc-jtc-dropdown-label'>{t('LB_RC_ODDS_TABLE_JOCKEY')} :</span>
            <JTCDropdownBtn title={t('LB_RC_ODDS_TABLE_JOCKEY')} items={jMenuItem} onClickFunc={(e) => { handleClickJTCMenu(e, setJSel, ) }} curSel={jSel} />
        </li>
        <li id={`dropdown_trainer`}>
            <span className='rc-jtc-dropdown-label'>{t('LB_RC_ODDS_TABLE_TRAINER')} :</span>
            <JTCDropdownBtn title={t('LB_RC_ODDS_TABLE_TRAINER')} items={tMenuItem} onClickFunc={(e) => { handleClickJTCMenu(e, setTSel) }} curSel={tSel} />
        </li>
        <li id={`dropdown_winOdds`}>
            <span className='rc-jtc-dropdown-label'>{t('LB_RC_JTC_WIN_ODDS')} :</span>
            <JTCDropdownBtn title={t('LB_RC_JTC_WIN_ODDS')} items={wMenuItem} onClickFunc={(e) => { handleClickJTCMenu(e, setWSel) }} curSel={wSel} />
        </li>
    </ul>;
}


