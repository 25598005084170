import { useApolloClient } from '@apollo/client';
import { getAllTournListQuery } from '../DataQuery';
import { Query } from '../../../Common/graphqlFetch'

const useGetTournList = (contentRef, setContent) => {
    // const { query } = useApolloClient();

    const loadTournListQuery = () => {
        Promise.all([Query({
            query : getAllTournListQuery(),
            fetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: true
        })])
        .then(([results]) => {
            contentRef.current.rawTournList = results?.data?.tournamentList || [];
            setContent({ ...contentRef.current })
        });
    }

    return {
        loadTournListQuery
    }
}

export default useGetTournList