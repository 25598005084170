import React from 'react';
import MatchTable from '../Common/MatchTable';
import { Remark } from '../Common/MatchBase';

const CHL = () => {
    return (
        <section className='CHL'>
            <MatchTable betType='CHL' etBetType='ECH' />
            <Remark />
        </section>
    )
}
export default CHL;