import React, { useState, useEffect, useContext } from 'react';
import { ConfigContext } from '../../Common/ConfigHelper';
import { useTranslation } from 'react-i18next';
import { RacingContext } from '../../Home/RacingPage';

const formatConv = (number) => {
  if(Number(number) != number) return '-'
  const numList = parseFloat(number).toFixed(2).toString().split('.')
  numList[0] = Number(numList[0]).toLocaleString()
  return numList.join('.')
}

const CwinCombItem = ({pool, item}) => {
  const [showDetail, setShowDetail] = useState(false)
  const {t, i18n} = useTranslation()
  const sel = pool.cWinSelections.filter(x => x.composite==item.winComb)[0] || {}

  const handleClickShowDetail = () => {
    setShowDetail(!showDetail)
  }

  return <div className='df table-cell-r divi-cwin-item'>
    <div>
      <div className='df divi-cwin-item-l pr'>
          <div>{sel?.composite} ({sel['name_'+i18n.language]})</div>
          <div onClick={handleClickShowDetail} className={`switch-btn-icon ${showDetail? 'close-btn-icon' : 'open-btn-icon'}`}></div>
      </div>
      {showDetail && <div className='divi-cwin-item-b'>{sel?.starters?.join(', ')}</div>}
    </div>
    <div className="text-bold">{
    ['NOTWIN','UNBACK'].includes(item.div.toUpperCase()) ? t(`LB_RC_RESULT_${item.div.toUpperCase()}`)
    :
    formatConv(item.div)
    }</div>
  </div>
}

const IWNCombItem = ({pool, isRefund, spoiler}) => {
  const [showAll, setShowAll] = useState(false)
  const {t} = useTranslation();
  let divs = pool.dividends.filter((item, index) => {
    let flag = spoiler ? item.winComb.indexOf('F#')>=0 : item.winComb.indexOf('F#')<0;
    if ( !spoiler && !showAll )
      flag &= index < 2;
    return flag;
  });
  const handleClickShowAll = () => {
    setShowAll(!showAll)
  }
return <div>
  {divs.map((item,index) => {
    return <div className='df table-cell-r' key={item.winComb}>
      {
      isRefund ? <>
      <div >-</div>
      <div className="text-bold">{t(`LB_RC_RESULT_REFUNDED`)}</div>
      </>
      :
      <>
      {
        !spoiler ? 
        <>
            <div className={`df ${index === divs.length-1 ? 'divi-table-iwn-ctrl' : ''}`}>
                <div >{item.winComb}</div>
                {index === divs.length-1 && <div style={{display: "flex"}}>
                    {!showAll ? <div>{t('LB_BMENU_MORE')}</div> : null}
                    <div onClick={handleClickShowAll} className={`switch-btn-icon ${showAll ? 'close-btn-icon' : 'open-btn-icon'}`}></div>
                  </div>}
            </div>
            <div className="text-bold">{
            ['NOTWIN','UNBACK'].includes(item.div?.toUpperCase()) ? t(`LB_RC_RESULT_${item.div.toUpperCase()}`)
            : 
            item.div 
            }</div>
        </> 
        :
        <>
            <div>{item.winComb.replace('F','')}</div>
            <div className='text-bold divi-table-iwn-guarantee'>{
            ['NOTWIN','UNBACK'].includes(item.div?.toUpperCase()) ? t(`LB_RC_RESULT_${item.div.toUpperCase()}`)
            :
            t('LB_RC_RESULT_GUARANTEE').replace('{value}', item.guarantee)
            }</div>
        </>
      }
      </>
      }
      </div>
  })
  }
</div>
}

const DividendTable = ({type='singleRacePool'}) => {

  const { t, i18n } = useTranslation();
  const context = useContext(RacingContext);
  const divBetType = context.content.config[type].split(";");

  const mtg = context.content.meeting;
  const dividendInfo = mtg.resPools.filter(x=> x.leg.races[x.leg.races.length-1]==context.content.raceNo
    && (x.status=="PAYOUT" || x.status.indexOf("REFUND")>=0) && divBetType.includes(x.oddsType));

  const hasMultiInstances = (betType) => {
      return ["DBL", "DT"].includes(betType) || mtg.resPools.filter(x=> betType==x.oddsType).length>1;
  }

  const TableHeader = () => {
    return <div className='dividend-table-header table-row'>
      <div className='table-cell'>{t('LB_RACE_INFO_BLOCK_POOL')}</div>
      <div className='table-cell'><div className='df table-cell-r'><div>{t('LB_RC_RESULT_WINCOMB')}</div><div>{t('LB_RC_RESULT_DIVIDEND')}</div></div></div>
    </div>
  }

  const Row = ({className, betType}) => {
    const pool =  dividendInfo.filter(x=> betType==x.oddsType)[0];
    if (pool==null) {
      return null;
    }
    const isRefund = pool.status.indexOf("REFUND")>=0;
    const dividends = pool.dividends?.sort((x,y) => x.seq-y.seq);
    const isAllNonWin = dividends.length > 0 && dividends.length === dividends.filter(x=>x.div==="NotWin").length;
    const showNonWinCmb = context.content.config["showNonWinCmbPool"].includes(betType);

    if ( (!isRefund && dividends.length==0) || (!showNonWinCmb && isAllNonWin) ) {
      return null;
    }

    return<><div className={className}>
      <div className='table-cell '>{
        ['CWA','CWB','CWC'].includes(betType) ? 
        <div>{`${pool['name_'+i18n.language]}`}<br/>({t('LB_RC_RESULT_CWIN')})</div>
        :
        <div>
            <div>{`${['DBL', 'TBL', 'DT','TT','SixUP'].includes(betType) && hasMultiInstances(betType) ? t(`LB_RC_POOL_NO_${pool.leg.number}`) : ''}${t(`LB_${betType}`)}`}</div>
        </div>
      }</div>
      <div className='table-cell'>
          {
            // REFUNDED
            isRefund ? <div className='df table-cell-r'>
                <div >-</div>
                <div className="text-bold">{t(`LB_RC_RESULT_REFUNDED`)}</div>
            </div>
            :
            ['IWN'].includes(betType)  ?
              // IWN
              <IWNCombItem pool={pool} isRefund={isRefund} spoiler={false} />
              :
              dividends.map(item => {
                if ( !showNonWinCmb && item.div==="NotWin" ) {
                  return null;
                }

                // CWIN
                if(['CWA','CWB','CWC'].includes(betType)){
                return <CwinCombItem pool={pool} item={item} key={item.winComb}/>
                }
                // Others
                const comb = item.winComb.replace(/,/g,', ').replace(/F/g,t('LB_RC_RESULT_ANYCOMB'));
                const winComb = ['TT'].includes(betType) && item.type === 'Consolation' ? `${t('LB_RC_RESULT_CON')} ${comb}` : comb
                const partial = item.partial ? `/$${item.partialUnit}`: ''

                return <div className='df table-cell-r' key={item.winComb}>
                <div>{winComb}</div>
                <div className="text-bold">{
                    // NOTWIN & UNBACK
                    ['NOTWIN','UNBACK'].includes(item.div.toUpperCase())
                    ? t(`LB_RC_RESULT_${item.div.toUpperCase()}`)
                    :
                    // normal
                    `${formatConv(item.div)}${partial}`
                    }</div>
                </div>
            })
          }
      </div>
    </div>
    {betType=="IWN" && !isRefund ? <div className={className}>
      <div className='table-cell'>
        <div className="dividend-table-pool">
          <div style={{lineHeight: '32px'}}>{t('LB_RC_RESULT_SPOILER')}</div>
        </div>
      </div>
      <div className='table-cell'>
        <IWNCombItem pool={pool} isRefund={isRefund} spoiler={true} />
      </div>
    </div> : null}
    </>
  }

  const Table = () => {
    let idx = 0;
    const table = divBetType.map((item,index) => {
      const pool =  dividendInfo.filter(x=> item==x.oddsType)[0];
      if (pool==null) {
        return null;
      }
      return <Row className={`table-row ${idx++%2 ? '': 'sec'}`} key={item} betType={item}/>;
    })
    
    return <div className='dividend-table'>
      <TableHeader />
      {table}
    </div>
  }

  const isShowAnyDivRow = () => {
    for ( let idx in divBetType) {
      if (divBetType.hasOwnProperty(idx)) {
        const pool =  dividendInfo.filter(x=> divBetType[idx]==x.oddsType)[0];
        if (pool==null) {
          continue;
        }
        const isRefund = pool.status.indexOf("REFUND")>=0;
        const dividends = pool.dividends;
        const isAllNonWin = dividends.length > 0 && dividends.length === dividends.filter(x=>x.div==="NotWin").length;
        const showNonWinCmb = context.content.config["showNonWinCmbPool"].includes(divBetType[idx]);
    
        if ( (!isRefund && dividends.length==0) || (!showNonWinCmb && isAllNonWin) ) {
          continue;
        }
        return true;
      }
    }
    return false;
  }
  
  return <>
  {isShowAnyDivRow() ?<div className='dividend-table-container'>
    <header>{t('LB_RC_RESULT_DIV')}</header>
    <Table />
  </div>: <div></div>}
  </>
}

export default DividendTable