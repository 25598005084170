import React from 'react';
import { gql } from '@apollo/client';

const getLotteryDrawsFragment = () => {
  return gql`fragment lotteryDrawsFragment on LotteryDraw {
    id
    year
    no
    openDate
    closeDate
    drawDate
    status
    snowballCode
    snowballName_en
    snowballName_ch
    lotteryPool {
      sell
      status
      totalInvestment
      jackpot
      unitBet
      estimatedPrize
      lotteryPrizes {
        type
        winningUnit
        dividend
      }
    }
    drawResult {
      drawnNo
      xDrawnNo
    }
  }`
}

const getLotteryStatFragment = () => {
  return gql`fragment lotteryStatFragment on LotteryStat {
    year
    no
    drawDate
    drawnNumbers {
        lastDrawnIn
        totalNumber
        drawnNo
    }
  }`
}

export function getMarksixDrawQuery() {
    return gql`
        ${getLotteryDrawsFragment()}
        query marksixDraw {
            timeOffset {
                m6  
                ts  
            }
            lotteryDraws {
                ...lotteryDrawsFragment
            }
        }
    `;
}

export function getMarksixResultQuery() {
    return gql`
        ${getLotteryDrawsFragment()}
        query marksixResult($lastNDraw: Int, $startDate: String, $endDate: String, $drawType: LotteryDrawType) {
            lotteryDraws(lastNDraw: $lastNDraw, startDate: $startDate, endDate: $endDate, drawType: $drawType) {
              ...lotteryDrawsFragment
            }
        }
    `;
}

export function getMarksixStatQuery() {
    return gql`
        ${getLotteryStatFragment()}
        query marksixStat {
            lotteryStats {
              ...lotteryStatFragment
            }
        }
    `;
}

export function getEwinHomeMarksixBlockQuery() {
  return gql`
    ${getLotteryDrawsFragment()}
    ${getLotteryStatFragment()}
    query marksix {
        lotteryDraws {
          ...lotteryDrawsFragment
        }
        lotteryStats {
          ...lotteryStatFragment
        }
    }
  `;
}
