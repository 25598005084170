import React, { useEffect, useRef, useState, useContext } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Popup from 'reactjs-popup';
import { useUpdateRNotes } from '../Common/CommonHooks';
import { isLogonHigh } from './LoginHooks';
import { MaxOnePopupWindow, getSiteCoreImagePath } from '../Common/home-common';
import { GlobalContext } from '../../StandardPage';
import { WATrackerTrackClickEvent, callWAReq } from '../Common/utils/wa';
import { useLogoutBoxQuery } from '../Home/Data/SitecoreDataHooks';
import generalImg from '../../info/Include/images/betslip_default_card.svg';
import card_black_img from '../../info/Include/images/card_black.png';
import card_gold_img from '../../info/Include/images/card_gold.png';
import card_red_img from '../../info/Include/images/card_red.png';
import card_sliver_img from '../../info/Include/images/card_sliver.png';
import { Cookies } from '../Common/CookieHelper';
import { glassboxCustomEvent } from '../Common/utils/glassbox';

export const LoginAccountInfo = ({
    pwd,
    loginAccount,
    onInputPwd,
    onInputLoginAccount,
    onClickOverlay,
    handleClickSignIn,
    loginInputFocus,
    loginInputBlur,
    goToFAQFn,
    isReadOnly,
    APILoading,
    showLoginEKBAAnswer
}) => {
    const { t } = useTranslation();
    const disabledLogin = showLoginEKBAAnswer || APILoading;
    return (
        <>
            <div className="account-password-content">
                <input
                    type="text"
                    id="login-account-input"
                    className={`login-account-input ${isReadOnly?'readonly':''}`}
                    value={loginAccount}
                    onInput={onInputLoginAccount}
                    onFocus={(e) => {
                        loginInputFocus(e, 'login-account-input');
                    }}
                    onBlur={(e) => {
                        loginInputBlur('login-account-input');
                    }}
                    placeholder={t('LB_BS_LOGIN_ACCOUNT_PLACEHOLDER')}
                    maxLength={15}
                    readOnly={isReadOnly || disabledLogin}
                />
                <input
                    type="password"
                    id="login-password-input"
                    className={`${pwd?.length > 0 ? 'login-account-pwinput' : ''} ${isReadOnly?'readonly':''}`}
                    value={pwd}
                    onInput={onInputPwd}
                    onFocus={(e) => {
                        loginInputFocus(e, 'login-password-input');
                    }}
                    onBlur={() => {
                        loginInputBlur('login-password-input');
                    }}
                    placeholder={t('LB_BS_LOGIN_PASSWORD_PLACEHOLDER')}
                    maxLength={20}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            handleClickSignIn();
                        }
                    }}
                    readOnly={isReadOnly || disabledLogin}
                />
            </div>
            <div className={`signIn cta_yellow ${disabledLogin ? 'disabled' : ''}`} onClick={() => !disabledLogin && handleClickSignIn()}>
                {isReadOnly?t('LB_NEXT'):t('LB_SIGNIN')}
            </div>
        </>
    );
};

// export const LastLoginInfo = ({onClickOverlay,data,goToFAQFn}) => {
//     const {t} = useTranslation()
//     return <>
//     <div className='last-login-info'>
//         <div className='last-login-info-title'>{t('LB_BS_LOGIN_LAST_LOGIN_INFO_TITLE')}</div>
//         <div>{t('LB_BS_LOGIN_LAST_LOGIN_INFO_SUCC')}</div>
//         <div>{t('LB_BS_LOGIN_LAST_LOGIN_INFO_DATE')} : 2021-2-15</div>
//         <div>{t('LB_BS_LOGIN_LAST_LOGIN_INFO_TIME')} : 16:22:50 (HKT)</div>
//     </div>
//     <div>
//         <div className='loginCloseArea'><div className="switch-btn-icon open-btn-icon" onClick={onClickOverlay}></div><div className='help-icon login-help'  onClick={goToFAQFn}></div></div>
//         <div ></div>
//     </div>
// </>
// }

export const UserInfo = ({
    onClickOverlay,
    handleClickLogout,
    userInfo,
    balance,
    isPcardUser,
    refreshBalance
}) => {
    const { t, i18n } = useTranslation();
    const globalContext = useContext(GlobalContext);
    const lang = i18n.language;
    const [isShowEyeIcon, setIsShowEyeIcon] = useState(true);
    const [isActiveStateBtn,setIsActiveStateBtn]=useState(true);
    const updateNotes = useUpdateRNotes();

    // for racing notes render
    useEffect(() => {
        if ( isLogonHigh() ) {
            globalContext.racingNotesData.loadNotesData();
        }
    }, [isLogonHigh(), updateNotes, globalContext.racingNotesData.globalEnable, globalContext.racingNotesData.settingEnable]);

    useEffect(() => {
        if (Cookies.getData('isMaskAmount') != undefined || Cookies.getData('isMaskAmount') != null) {
            let isMaskAmount = Cookies.getData('isMaskAmount') === 'false' ? false : true;
            setIsShowEyeIcon(isMaskAmount);
        } else {
            Cookies.setData('isMaskAmount', true);
        }

        return () => {
            MaxOnePopupWindow(true);
        };
    }, []);

    const maxLengthName = (text) => {
        if (!text) return '';
        let newText = text;
        if (text.length > 14) {
            newText = text.slice(0, 12) + '...';
        }
        return newText;
    };

    const changeEyeIcon = () => {
        Cookies.setData('isMaskAmount', !isShowEyeIcon);
        setIsShowEyeIcon(!isShowEyeIcon);
    };

    const balanceAmount = (text) => {
        if (!text) return '---';
        let newText = text;
        if (!isShowEyeIcon) {
            newText = '*****';
        }
        if (text === '...') {
            newText = '---';
        }
        return newText;
    };

    const onClickRefreshBalance = () => {
        if(!isActiveStateBtn){
            return;
        }
        refreshBalance();
        setIsActiveStateBtn(false);
        setTimeout(()=> {
            setIsActiveStateBtn(true);
        }, 3000)
    };

    let betAccount = '';
    let betName = '';

    if (userInfo?.bettingProfile) {
        let langType = lang === 'en' ? 'En' : 'ZhHk';
        const { bettingProfile } = userInfo;
        betAccount = bettingProfile.personalInfo.bettingAccountNumber;

        if (lang === 'en') {
            betName = bettingProfile.personalInfo.firstNameEn + ' ' + bettingProfile.personalInfo.lastNameEn;
        } else {
            betName = bettingProfile.personalInfo.lastNameZhHk + bettingProfile.personalInfo.firstNameZhHk;
        }
    }

    const showUserAvatar = () => {
        let imgUrl = generalImg;
        const pCardTier =  window.globalConfig.IS_IB? sessionStorage.getItem('PCardTier') : sessionStorage.getItem('login_priorityCardTier');
        if (pCardTier) {
            switch (pCardTier) {
                case '1'://red
                    imgUrl = card_red_img;
                    break;
                case '2': //sliver
                    imgUrl = card_sliver_img;
                    break;
                case '3'://gold
                    imgUrl = card_gold_img;
                    break;
                case '4'://black
                    imgUrl = card_black_img;
                    break;
                default:
                    break;
            }
        }
        return imgUrl;
    };

    return (
        <>
            <div className="betslip-account df">
                <div className="betslip-pcard">
                    <img src={showUserAvatar()} alt="personal img" />
                </div>
                <div>
                    <div className="marginB2">
                        <div className="lineH13 marginB1">{t('LB_BS_LOGIN_ACCOUNT')}</div>
                        <div className="fontS15 lineH18">{betAccount}</div>
                    </div>
                    <div className="df lineH13 marginB1">
                        <div className="">{t('LB_BS_LOGIN_BALANCE')}</div>
                        <div
                            className={`${isShowEyeIcon ? 'eyeOn-icon' : 'eyeClose-icon'}`}
                            onClick={changeEyeIcon}
                        ></div>
                    </div>
                    <div className="df userAmount" style={{ alignItems: 'center' }}>
                        <div className="fontS15 lineH18">$ {balanceAmount(balance)}</div>
                        <div className={`refresh-icon ${isActiveStateBtn ? '':'rotate'}`} onClick={onClickRefreshBalance}></div>
                    </div>
                </div>
            </div>
            <div className={`loginCloseAreaCon ${isPcardUser ? 'pcardClose' : ''}`}>
                <div className="loginCloseArea">
                    <button className="switch-btn-icon open-btn-icon" onClick={onClickOverlay}></button>
                    {/* <div className="help-icon login-help" onClick={goToFAQFn}></div> */}
                </div>
                <div className="logout signIn" onClick={handleClickLogout}>
                    {t('LB_LOGOUT')}
                </div>
            </div>
        </>
    );
};

export const LogoutReminder = ({ showReminder, onCancel, onConfirm, isShowLogoutBetText }) => {
    const { t } = useTranslation();

    return (
        <Popup open={showReminder} className="logout-reminder" closeOnDocumentClick={false}>
            <div className="logout-reminder-container">
                <div className="title">bet.hkjc.com says</div>
                <div className="logout-reminder-message">
                    {t(isShowLogoutBetText ? 'LB_BS_LOGIN_FORGOT_ANSWER_MSG' : 'LB_BS_LOGOUT_REMINDER')}
                </div>
                <div className="button-box">
                    <div onClick={onCancel}>{t('LB_BS_LOGIN_CANCEL')}</div>
                    <div id="betlogoutbox-home-box" onClick={onConfirm}>
                        {t('LB_BS_LOGIN_OK')}
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export const LogoutSuccessMsg = ({ showMsg = false }) => {
    const { t, i18n } = useTranslation();
    const [msgOpen, setMsgOpen] = useState(true);
    const [logoutDateTime, setLogoutDateTime] = useState(null);
    const { msgLoading, loadLogoutBox, textEnable, text, imageEnable, image } = useLogoutBoxQuery();
    useEffect(() => {
        if (showMsg) {
            setLogoutDateTime(moment().format('HH:mm DD-MM-YYYY'));
            setMsgOpen(true);
            loadLogoutBox();
        }
    }, [showMsg]);
    const onClickClose = () => {
        WATrackerTrackClickEvent('betlogoutbox-home-button-close');
        glassboxCustomEvent('Logout_Close_button');
        setMsgOpen(false);
        sessionStorage.removeItem('customerSegment');
        sessionStorage.removeItem('hWebID');
        sessionStorage.removeItem('customerHash');
    };

    return !msgLoading ? (
        <Popup open={msgOpen && (textEnable || imageEnable)} closeOnDocumentClick={false} className="logout-succ-msg logout-succ-msg-m">
            <div className="logout-succ-msg-container">
                <div>{t('LB_BS_LOGOUT_SUCCESS_MSG')}</div>
                <div>
                    {t('LB_BS_LOGOUT_SUCCESS_TIME')} {logoutDateTime}
                </div>
                <div className="succ-msg-content">
                    <div className="message-box">{textEnable ? text : ''}</div>
                    {imageEnable && image && (
                        <div className="banner-box">
                            <div>
                                <img src={getSiteCoreImagePath(image.src, i18n.language)} title={image.alt} />
                            </div>
                        </div>
                    )}
                </div>
                <div className="close-btn-box">
                    <div id="betlogoutbox-home-button-close" className="close" onClick={onClickClose}>
                        {t('LB_CLOSE')}
                    </div>
                </div>
            </div>
        </Popup>
    ) : null;
};

export const LoginIncorrect = ({ messageCode, onClose, showClose = true }) => {
    const [message, setMessage] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        if (messageCode) {
            var errMsg;
            switch (messageCode) {
                case 415:
                case 416:
                    errMsg = t('LB_BS_LOGIN_ERROR_OTHER') + ` (${messageCode})`;
                    break;
                default:
                    errMsg = t('LB_BS_LOGIN_ERROR_' + messageCode);
                    break;
            }
            setMessage(errMsg);
        } else {
            setMessage('');
        }
    }, [messageCode, t]);

    return (
        <>
            {message && (
                <div className="login-incorrect-message-container">
                    {showClose && <div onClick={onClose} className="switch-btn-icon open-btn-icon"></div>}
                    <div dangerouslySetInnerHTML={{ __html: message }}></div>
                </div>
            )}
        </>
    );
};

export const ApiErrPopupUI = ({isApiErr, errorMessageCode})=>{
    const { t } = useTranslation();
    return (
        <Popup open={isApiErr} className="logout-reminder cashout-reminder" closeOnDocumentClick={false}>
                <div className="cashout-reminder-container">
                    <div className="cashout-reminder-message">
                        { errorMessageCode ? t('ACCOUNT_STATEMENT_ERROR_' + errorMessageCode) : t('LB_BS_LOGIN_ERROR_OTHER') }
                    </div>
                    <div className="button-box">
                        <div className='cancelBtn confirmBtn' onClick={()=>{ window.close(); }}>{t('LB_BS_FT_CONFIRM')}</div>
                    </div>
                </div>
            </Popup>
    )
}
