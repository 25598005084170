import React, { useContext, useEffect, useState } from 'react';
import { FootballContext } from '../../Home/FootballPage';
import FootballTitle from '../Common/FootballTitle';
import { useTranslation } from 'react-i18next';
import HaFuResultTable from '../Common/HaFuResultTable';
import ResultsSearchBox from '../Common/ResultsSearchBox';
import { enableDetailsButton, ResultHeader, ResultFooter } from '../Common/ResultsBase';
import { MatchCountLabel } from '../Common/ResultsBase';
import Pagination from '../Common/Pagination';
import moment from 'moment';
import { Loading } from '../../Common/ui-components';
import { GlobalContext } from '../../../StandardPage';

const Results = (props) => {
    const context = useContext(FootballContext)
    const { t, i18n } = useTranslation();
    const {sessionServerTimeStamp} = useContext(GlobalContext);
    // const [resultsData, setResultsData] = useState([]);
    const [openSearchBox, setOpenSearchBox] = useState(false)
    const offset = context?.content?.matchData?.timeOffset?.fb || 365;
    const searchEndDt = new Date(sessionServerTimeStamp + offset*1000);
    const isMobile = context?.state?.windowSize?.isMobileSize
    const pageLimit = context.content.config.MaxSearchResultPaginationLimit;

    const [pageState, setPageState] = useState({
        isSearch: false,
        startDate: new Date(searchEndDt.getTime() - (31 - 1)* 24 * 60 * 60 * 1000),
        endDate: new Date(searchEndDt),
        displayTeamId: "0",
        searchType: 'LAST10', // LAST10 / DATE
        displaySearchType: '',
        displaySearchRange: '',
        pageNum: 1
    })

    const handleClickSearchBtn = () => {
        context.state.onClickSearchResult(pageState.startDate, pageState.endDate, pageState.searchType);
        pageState.isSearch = true;
        pageState.pageNum = 1;
        pageState.displaySearchType = pageState.searchType;
        pageState.displaySearchRange = `${moment(pageState.startDate).format('DD/MM/YYYY')} - ${moment(pageState.endDate).format('DD/MM/YYYY')}`
        setPageState({...pageState});
    }

    const updateSearchParams = (newSearchParams) => {
        if ( newSearchParams.startDate )
            pageState.startDate = newSearchParams.startDate;
        if ( newSearchParams.endDate )
            pageState.endDate = newSearchParams.endDate;
        if ( newSearchParams.searchType)
            pageState.searchType = newSearchParams.searchType
        setPageState({...pageState});
    }

    const handlePageNumChange = (num) => {
        pageState.pageNum = num;
        setPageState({...pageState});
    }


    const handleClickOpenSearchBox = () => {
        setOpenSearchBox(!openSearchBox)
    }

    // const matchesInProgressData = availMatches.filter(match => !isMatchVoided(match) && !hasConfirmedResult(match) && match?.poolInfo?.payoutRefundPools?.length==0);
    const resultsData = context.content.matchData.matches || [];
    let curResultData = resultsData.slice((pageState.pageNum-1)* pageLimit, pageState.pageNum*pageLimit );
    let totalMatches = resultsData.length;
    const showPagination =  totalMatches > pageLimit && !isMobile;
    if ( !pageState.isSearch && curResultData.length > 0 ) {
        curResultData = curResultData.filter(x=> x.fbPmPools[0].leg[0].match.kickOffTime.substring(0,10)===curResultData[0].fbPmPools[0].leg[0].match.kickOffTime.substring(0,10));
    }

    return <>
        <ResultHeader  onClickSearch={handleClickOpenSearchBox} openSearchBox={openSearchBox}/>
        {<section className='HFMRES RESULTS'>
            <header className='DHCRES-MobileHeader'>
                <div  >{t('LB_FB_TITLE_HFMRES')}</div>
            </header>
            {/* <header className='DHCRES-Header'>
                <FootballTitle title={t('LB_FB_TITLE_HFMRES')} isPoolDefined={true} />
            </header> */}
            <ResultsSearchBox searchParams={pageState}
            windowSize={context?.state?.windowSize}
            onChangeSearchParams={updateSearchParams}
            handleClickSearchBtn={handleClickSearchBtn}
            isOpen={openSearchBox}
            setIsOpen={setOpenSearchBox}
            showRadioButton
            />
            {/* { pageState.isSearch && pageState.displaySearchType==="LAST10" && <div className="searchTitle">{t('LB_FB_DHCRES_LAST10')} :</div> }
            { pageState.isSearch && pageState.displaySearchType==="DATE" && <div className="searchTitle">{t('LB_FB_DHCRES_DATE')} : {pageState.displaySearchRange}</div> } */}
            {!context.content.isSearching && <div className='pagination'>
                {pageState.displaySearchType==="DATE" && resultsData.length + curResultData.length>0 && <MatchCountLabel total={totalMatches} pageNum={pageState.pageNum} maxPerPage={pageLimit} />}
                {showPagination && <Pagination total={Math.ceil(totalMatches / pageLimit)} onChange={(num) => handlePageNumChange(num)} pageNum={pageState.pageNum} />}
            </div>}
            {!context.content.isSearching && <HaFuResultTable matches={curResultData} type='HFMRES'/>}
            {!context.content.isSearching && resultsData.length + curResultData.length==0 && <div className="noMatchResult">{t('LB_FB_NO_RESULT')}</div> }
            {context.content.isSearching && <Loading/>}
            {!context.content.isSearching && <div className='pagination bottom'>
                {pageState.displaySearchType==="DATE" && resultsData.length + curResultData.length>0 && <MatchCountLabel total={totalMatches} pageNum={pageState.pageNum} maxPerPage={pageLimit} />}
                {showPagination && <Pagination total={Math.ceil(totalMatches / pageLimit)} onChange={(num) => handlePageNumChange(num)} pageNum={pageState.pageNum} />}
            </div>}
            <ResultFooter />
        </section>}
    </>
}

export default Results