import React from 'react';
import { StatesProps as _StatesProps, defaults as _defaults } from './equal-height';

const defaults = {
    ...{
        setTemporarySizes: (() => undefined),
        setOriginalChildrenCount: (() => undefined),
        setChildrenCount: (() => undefined),
        setForceUpdate: (() => undefined),
    },
    ..._defaults
};

const EqualHeightContext = React.createContext({
    sizes:                      defaults && defaults.sizes,
    temporarySizes:             defaults && defaults.temporarySizes,
    update:                     defaults && defaults.update,
    animationSpeed:             defaults && defaults.animationSpeed,
    forceUpdate:                defaults && defaults.forceUpdate,
    originalChildrenCount:      defaults && defaults.originalChildrenCount,
    childrenCount:              defaults && defaults.childrenCount,
    setTemporarySizes:          defaults && defaults.setTemporarySizes,
    setOriginalChildrenCount:   defaults && defaults.setOriginalChildrenCount,
    setChildrenCount:           defaults && defaults.setChildrenCount,
    setForceUpdate:             defaults && defaults.setForceUpdate,
    updateOnChange:             defaults && defaults.updateOnChange
});
const EqualHeightProvider = EqualHeightContext.Provider;
const EqualHeightConsumer = EqualHeightContext.Consumer;

export { EqualHeightContext, EqualHeightProvider, EqualHeightConsumer };
