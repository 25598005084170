import React, { useEffect, useState, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrentSessionRecords, checkPpsProcessStatus } from '../../Common/CurrentSessionRecords';
import { ConfigContext } from '../../Common/ConfigHelper';
import { isMobileSize } from '../../Common/CommonHooks';
import './current_session.scss';

const CurrentSession = ({ isShow, setShowExportPrint, currentTab, setTab }) => {
    const configs = useContext(ConfigContext);
    const accessToken = sessionStorage.getItem('access_token');
    const { t, i18n } = useTranslation();
    const [mounted, setMounted] = useState(false);
    useEffect(() => {
        if (isShow) {
            setMounted(true);
        }
    }, [isShow]);

    const [data, setData] = useState();
    useEffect(() => {
        if (configs.racing && !data) {
            getInfo()
        }
        const reSetdata = () => {
            getInfo()
        };
        window.addEventListener('storage', reSetdata);
        return () => window.removeEventListener('storage', reSetdata);
    }, [configs.racing]);

    useEffect(() => {
        if (data?.length > 0 && currentTab === 0) {
            setShowExportPrint(true);
        } else if (data?.length === 0 && currentTab === 0) {
            setShowExportPrint(false);
        }
    }, [data, currentTab, setShowExportPrint]);

    const getInfo = async () => {
        checkPpsProcessStatus(i18n);
        const getData=await getCurrentSessionRecords(t, i18n, configs)
        setData(getData);
    }

    const hasUnknow = useMemo(() => data?.some((i) => i.status == t('LB_UNKNOWN')), [data]);

    return (
        accessToken &&
        mounted && (
            <div className="current-session">
                <p className="explain">
                    <span>
                        {t('LB_BETSLIP_ACCOUNTRECORDS_CS_EXPLAIN')}
                        {/* {hasUnknow && t('LB_BETSLIP_ACCOUNTRECORDS_CS_UNKNOW_EXPLAIN')} */}
                        {hasUnknow &&
                            (i18n.language === 'en' ? (
                                <span>
                                    Please use
                                    <span className="cur" onClick={() => setTab(1)}>
                                        &nbsp;Transaction Records&nbsp;
                                    </span>
                                    function to confirm whether the transactions with status Unknown have been accepted.
                                </span>
                            ) : (
                                <span>
                                    *如 閣下發現狀況「不明」之交易紀錄，請按此查閱
                                    <span className="cur" onClick={() => setTab(1)}>
                                        交易紀錄
                                    </span>
                                    。
                                </span>
                            ))}
                    </span>
                </p>
                <Table data={data} />
            </div>
        )
    );
};

const Table = ({ data }) => {
    const { t } = useTranslation();
    return data?.length > 0 ? (
        <>
            <div className="table-header-shadow"></div>
            <table className="detail-table">
                <Header />
                <TableBody data={data} />
            </table>
            <div className="detail-tables-m">
                {data.map((item, index) => (
                    <TableM item={item} key={index} />
                ))}
            </div>
        </>
    ) : (
        <p className="no-records">{t('LB_BETSLIP_ACCOUNTRECORDS_CS_NO_RECORDS')}</p>
    );
};

const Header = () => {
    const { t } = useTranslation();
    return (
        <thead>
            <tr>
                <td>{t('LB_BETSLIP_ACCOUNTRECORDS_CS_TH_NO')}</td>
                <td>{t('LB_BETSLIP_ACCOUNTRECORDS_CS_TH_STATUS')}</td>
                <td>{t('LB_BETSLIP_ACCOUNTRECORDS_CS_TH_REF_NO')}</td>
                <td>{t('LB_BETSLIP_ACCOUNTRECORDS_CS_TH_BET_TYPE')}</td>
                <td>{t('LB_BETSLIP_ACCOUNTRECORDS_CS_TH_TRANSACTION_DETAILS')}</td>
                <td>{t('LB_BETSLIP_ACCOUNTRECORDS_CS_TH_AMOUNT')}</td>
            </tr>
        </thead>
    );
};

const TableBody = ({ data }) => {
    const { t, i18n } = useTranslation();
    return (
        <tbody>
            {data.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>
                            {isMobileSize() ? t('LB_M6_SINGLE_TABLE_NO') : ''} {item.No}
                        </td>
                        <td>{item.status}</td>
                        <td dangerouslySetInnerHTML={{ __html: item.txNo }}></td>
                        <td className="mw74" dangerouslySetInnerHTML={{ __html: item.betType }}></td>
                        <td className="width300">{item.betLineStr}</td>
                        <td>{item.amount}</td>
                    </tr>
                );
            })}
        </tbody>
    );
};

const TableM = ({ item }) => {
    const { t } = useTranslation();
    return (
        <div className="detail-table-m">
            <header>No. {item.No}</header>
            <section>
                <div>
                    <span className="title">{t('LB_BETSLIP_ACCOUNTRECORDS_CS_TH_STATUS')}</span>
                    <span className="content">{item.status}</span>
                </div>
                <div>
                    <span className="title">{t('LB_BETSLIP_ACCOUNTRECORDS_CS_TH_REF_NO')}</span>
                    <span className="content" dangerouslySetInnerHTML={{ __html: item.txNo }}></span>
                </div>
                <div>
                    <span className="title">{t('LB_BETSLIP_ACCOUNTRECORDS_CS_TH_BET_TYPE')}</span>
                    <span className="content" dangerouslySetInnerHTML={{ __html: item.betType }}></span>
                </div>
                <div>
                    <span className="title">{t('LB_BETSLIP_ACCOUNTRECORDS_CS_TH_TRANSACTION_DETAILS')}</span>
                    <span className="content">{item.betLineStr}</span>
                </div>
                <div>
                    <span className="title">{t('LB_BETSLIP_ACCOUNTRECORDS_CS_TH_AMOUNT')}</span>
                    <span className="content">{item.amount}</span>
                </div>
            </section>
        </div>
    );
};

export default CurrentSession;
