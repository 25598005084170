import React from 'react';
import MatchTable from '../Common/MatchTable';
import { Remark } from '../Common/MatchBase';

const TTG = () => {
    
    return (
        <section className='TTG'>
            <MatchTable betType='TTG' etBetType='ETG' />
            <Remark />
        </section>
    )
}
export default TTG;