import React from 'react';
import MatchTable from '../Common/MatchTable';
import { Remark } from '../Common/MatchBase';

const SGA = () => {
    return <section className='SGA'>
        <MatchTable betType='SGA'/>
        <Remark />
    </section>
}
export default SGA;