export const onClickCheckBox = (state, isStopSelling, context, setCheckboxState) => {
    if (state.checked && isStopSelling) {
        state.checked = false;
    }
    else if (isStopSelling) {
        return;
    }
    else {
        state.checked = !state.checked;
    }

    let curState = context.content.oddsSelection.filter(x => x.id == state.id)[0];
    let newState = []//[...context.content.oddsSelection];
    if (curState == null) {
        newState = [...context.content.oddsSelection];
        newState.push(state);
    } else {
        context.content.oddsSelection.map((item, index) => {
            if (item.id == state.id) {
                //return state;
                newState.push(state);
            }
            else {
                //return item;
                newState.push(item);
            }
        })
    }
    // newState=newState.filter(x=>!!x.checked);

    if(context.content.config.MixAllUpPages.includes(context.content.page)){
        newState.sort(function(a,b) { return (new Date(a.kickOffTime) - new Date(b.kickOffTime))})
    }
    setCheckboxState(newState);
    context.state.onClickOddsCheck(newState);
}

export const getAllUpCalEntries = () => {
    let data = window.sessionStorage.getItem("fbALlupData");
    if ( data )
        return JSON.parse(data);
    return [];
}

export const setAllUpCalEntries = (data) => {
    window.sessionStorage.setItem("fbALlupData", JSON.stringify(data));
}

export const addAllUpCalEntry = (item) => {
    let data = getAllUpCalEntries();
    data.push(item);
    setAllUpCalEntries(data);
}

export const removeAllUpCalEntry = (idx) => {
    let data = getAllUpCalEntries();
    data.splice(idx, 1)
    setAllUpCalEntries(data);
    return data;
}

function handicapType(i) {
    if (parseInt(i) != i) {
        if (parseFloat(i) >= 0)
            return '+F';
        else
            return '-F';
    }
    return '';
}

export const getHandicapResultItems = (data, t) => {
    let thomeHDC = data.hG.replace('[', '').replace(']', '');
    let tvalue = data.str === 'H' ? 'W' : '0';
    let opts = [];
    opts.push({value: t('LB_FB_CAL_HOME_S'), res: tvalue});
    if (thomeHDC.indexOf('/') < 0) { // no split line
        if (handicapType(thomeHDC) == '') // NO decimal pt
            opts.push({value: t('LB_FB_CAL_DRAW_S'), res: 1});
    } else {
        let hdcDataArr = thomeHDC.split('/');
        let tstr = '';
        // +.5/+ = A/Draw
        // -.5/- = H/Draw
        // +/+.5 = Draw/H
        // -/-.5 = Draw/A
        if (handicapType(hdcDataArr[0]) == '+F') {
            tvalue = data.str === 'H' ? '0/1' : 'W/1';
            tstr = 'LB_FB_CAL_AWAY_DRAW_S';
        }
        else if (handicapType(hdcDataArr[0]) == '-F') {
            tvalue = data.str === 'H' ? 'W/1' : '0/1';
            tstr = 'LB_FB_CAL_HOME_DRAW_S';
        }
        else if (handicapType(hdcDataArr[1]) == '+F') {
            tvalue = data.str === 'H' ? '1/W' : '1/0';
            tstr = 'LB_FB_CAL_DRAW_HOME_S';
        }
        else if (handicapType(hdcDataArr[1]) == '-F') {
            tvalue = data.str === 'H' ? '1/0' : '1/W';
            tstr = 'LB_FB_CAL_DRAW_AWAY_S';
        }
        if (tstr != '')
            opts.push({value: t(tstr), res: tvalue});
    }
    tvalue = data.str === 'H' ? '0' : 'W';
    opts.push({value: t('LB_FB_CAL_AWAY_S'), res: tvalue});
    return opts;
}

function getHILcond(tHILstr) {
    if (tHILstr.indexOf('/') >= 0) {
        let tArr = tHILstr.split('/')
        for (let i = 0; i <= 1; i++) {
            if (parseInt(tArr[i]) == tArr[i])
                return parseInt(tArr[i]);
        }
    }
    return parseInt(tHILstr);
}

export const getGoallineResultItems = (data, t) => {
    let tHILcond = data.condition;
    let absHILcond = getHILcond(tHILcond);
    let opts = [];
    let tstr = 'LB_FB_CAL_ABOVE';
    let tvalue = data.str === 'H' ? 'W' : '0';
    opts.push({value: t(tstr).replace('{0}', absHILcond), res: tvalue});
    if (tHILcond.indexOf('/') >= 0 || tHILcond.indexOf('.5') < 0) {
        tstr = absHILcond > 1 ? 'LB_FB_CAL_GOALS' : 'LB_FB_CAL_GOAL';
        if (tHILcond.indexOf('/') >= 0) {
            var hilDataArr = tHILcond.split('/');
            // H .5/.0 = W/1
            // H .0/.5 = 1/0
            // L .5/.0 = 0/1
            // L .0/.5 = 1/W
            if (handicapType(hilDataArr[0]) == '+F' && data.str === 'H')
                tvalue = 'W/1';
            if (handicapType(hilDataArr[1]) == '+F' && data.str === 'H')
                tvalue = '1/0';
            if (handicapType(hilDataArr[0]) == '+F' && data.str !== 'H')
                tvalue = '0/1';
            if (handicapType(hilDataArr[1]) == '+F' && data.str !== 'H')
                tvalue = '1/W';
        } else {
            tvalue = "1";
        }
        opts.push({value: t(tstr).replace('{0}', absHILcond), res: tvalue});

        tstr = 'LB_FB_CAL_BELOW';
    } else {
        tstr = 'LB_FB_CAL_OR_BELOW';
    }
    tvalue = data.str === 'H' ? '0' : 'W';
    opts.push({value: t(tstr).replace('{0}', absHILcond), res: tvalue});

    return opts;
}