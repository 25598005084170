import { useCallback, useState, useRef, useEffect, useLayoutEffect, useMemo } from 'react';

const useServerTime = () => {
    const sessionServerTimeRef = useRef(0);
    const timerRef = useRef(null);
    const timeDiffRef = useRef(0);
    
    useEffect(() => {
        sessionServerTimeRef.current = new Date().getTime();
        timeDiffRef.current = 0;
        let serverTime= sessionStorage.getItem('LastUpdate');
        if (serverTime) {
            sessionServerTimeRef.current = 0;
            let respDate = new Date();
            const stArr = serverTime.split(` `);
            let date=stArr[0].split('/')
            let time=stArr[1].split(':')
            respDate.setFullYear(Number(date[2]));
            respDate.setDate(Number(date[1]));
            respDate.setMonth(Number(date[0])-1);
            respDate.setHours(Number(time[0]));
            respDate.setMinutes(Number(time[1]));
            respDate.setSeconds(Number(time[2]));
            sessionServerTimeRef.current = respDate.getTime();
            timeDiffRef.current= (new Date().getTime()) - respDate.getTime();
        }
        clearInterval(timerRef.current);
        timerRef.current = null;
        timerRef.current = setInterval(() => {
            sessionServerTimeRef.current = (new Date().getTime()) - timeDiffRef.current;
            window.ssTime=sessionServerTimeRef.current;
        }, 3000);
        return () => {
            clearInterval(timerRef.current);
            timerRef.current = null;
        };
    }, [sessionStorage.getItem('LastUpdate')]);

    return {
        sessionServerTimeRef
    };
};

export default useServerTime;
