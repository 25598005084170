import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useProductInfoBlockQuery, useMarksixFixturesQuery } from '../../Home/Data/SitecoreDataHooks';
import { handleClickMenuItem, isProductInfoItemEnable, IconContent } from '../../Menu/MenuCommon'; 
import { Scrollbars } from 'react-custom-scrollbars-2';

const CustomScrollbars = props =>{
    return (
    <Scrollbars
        // autoHeight
        autoHeightMin={200}
        autoHeightMax={200}
        autoHide
        {...props}
    />
)};

const MarksixTopBlock = (props) => {
    const navigate = useNavigate();
    const [ menuLoading, menuData ] = useProductInfoBlockQuery('marksix');
    const { loading, getNext4Draw, getNextSnowballDraw } = useMarksixFixturesQuery();
    let snowballList = getNextSnowballDraw();

    const handleOnPageChange = (url) => {
        navigate(url);
    }

  

    return !loading && !menuLoading ? <div>
        <ul className="nav nav-tabs" id="myTab" role="tablist">
            {menuData.map((menu, idx) => {
                let isActive = snowballList?.length>0 ? menu.key===window.sitecoreVars.M6_SBDRAW : menu.key===window.sitecoreVars.M6_NEXTDRAW;
                return isProductInfoItemEnable(menu) ? <li className="nav-item" role="presentation">
                    <button className={`nav-link ${isActive ? 'active' : ''}`} id={`${menu.key}-tab`} data-bs-toggle="tab" data-bs-target={`#${menu.key}`} type="button" role="tab" aria-controls="home" aria-selected="true">
                        {menu.itemName.value}
                    </button>
                </li> : null
            })}
        </ul>
        <div className="tab-content" id="myTabContent">
            {menuData.map((menu, idx) => {
                let isActive = snowballList?.length>0 ? menu.key===window.sitecoreVars.M6_SBDRAW : menu.key===window.sitecoreVars.M6_NEXTDRAW;
                return isProductInfoItemEnable(menu) ? <div className={`tab-pane fade ${isActive ? 'show active': ''}`} id={menu.key} role="tabpanel" aria-labelledby={`${menu.key}-tab`}>
                    <BlockContent menu={menu} handleOnPageChange={handleOnPageChange} snowballList={snowballList} getNext4Draw={getNext4Draw}/>
                </div> : null
            })}
        </div>
    </div> : null;

}
export default MarksixTopBlock;

const BlockBody = ({menu, handleOnPageChange}) => {
    
    const { t, i18n } = useTranslation();

    const filterChildren = menu.children.filter(x=> x.itemEnable.value==="1");
    if ( filterChildren.length===0 ) {
        return <div className="m6-info-block-noinfo">{t('LB_RC_JTC_NOINFO')}</div>;
    }
    return <div>
        {filterChildren.map(cMenu => {
            let predefinedColor = cMenu.predefinedColor?.targetItem?.optionValue?.value || "";
            let hlStyle = predefinedColor!="" ? { color: predefinedColor } : {};
            return cMenu.itemEnable.value==="1" ? 
                <div className="top-block-item" style={hlStyle} onClick={(e)=>handleClickMenuItem(e, cMenu, handleOnPageChange)}>
                {cMenu.itemName.value}
                <span style={{ paddingLeft: "4px" }}>
                    <IconContent iconContent={cMenu.iconContent} />
                </span>
            </div>
            : null
        })}
    </div>
}

const DrawList = ({draws, handleOnPageChange}) => {
    
    const { t, i18n } = useTranslation();

    if ( draws?.length===0 ) {
        return <div className="m6-info-block-noinfo">{t('LB_M6_INFO_BLOCK_NOINFO')}</div>
    }
    let _url = `/${i18n.language}/marksix/fixtures`;
    return <div>
        {draws?.map(dt => {
            return <div className="top-block-item" onClick={() => { handleOnPageChange(_url) }}>
                {dt.format("DD/MM/YYYY")} ({t('LB_' + dt.format("ddd").toUpperCase())})
            </div>
        })}
    </div>
}

const BlockContent = ({menu, snowballList, getNext4Draw, handleOnPageChange}) => {
    

    switch (menu.key) {
        case window.sitecoreVars.M6_SBDRAW:
            return <DrawList draws={snowballList} handleOnPageChange={handleOnPageChange}/>
        case window.sitecoreVars.M6_NEXTDRAW:
            return <DrawList draws={getNext4Draw()} handleOnPageChange={handleOnPageChange}/>
        default:
            return <CustomScrollbars> <BlockBody menu={menu} handleOnPageChange={handleOnPageChange}/></CustomScrollbars>
    }
}
