import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';

import { TournImage } from '../../Football/Common/MatchBase';
import Footer from '../../Footer/Footer';

// import '../../../App.css';
import '../../../css/football/payouttime.css';

const PayoutTime = () => {
    const { t, i18n } = useTranslation();
    const [content, setContent] = useState({
        tourns: [],
        header: {},
        footer: {},
        remarks: {}
    })

    useEffect(()=>{
        //
        fetch('/dev/football/payoutTime.json', { method: "GET" })
        .then(res => res.json())
        .then(
          (result) => {
            content.header = result.header;
            content.footer = result.footer;
            content.remarks = result.remarks;
            setContent({...content});
          },
          (error) => {
          }
        )

        fetch('/dev/football/tournlist.json', { method: "GET" })
        .then(res => res.json())
        .then(
          (result) => {
            content.tourns = result;
            setContent({...content});
          },
          (error) => {
          }
        )

    }, []);

    return <div className={`payoutTime`}>
        <div className="ptTitle">
            {t('LB_FB_PAYOUTTIME_TITLE')}
        </div>
        <div className="ptHeader" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content.header[i18n.language])}}></div>

        <div className="ptTournTable">
            {content.tourns.map(tourn => {
                return <div className="ptTournItem">
                    <div className="ptTournFlag"><TournImage code={tourn.tournCode} name={i18n.language==="ch" ? tourn.tournNameC : tourn.tournNameE} /></div>
                    <div className="ptTournName">{i18n.language==="ch" ? tourn.tournNameC : tourn.tournNameE}</div>
                </div>
            })}
        </div>

        <div className="ptFooter" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content.footer[i18n.language])}}></div>
        <div className="ptRemarks">
            <div>{t('LB_FB_PAYOUTTIME_REMARKS')}</div>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content.remarks[i18n.language])}}></div>
        </div>
        <Footer className='ptRGP'/>
    </div>
}
export default PayoutTime;