import React, { useEffect, useState, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import OddsTable from '../Common/RCOddsTableCompact';
import { ClearBetRaces } from './RacingBetline';
import {getComingleMsgsAlup} from '../Common/Common';
import { RacingContext } from '../../Home/RacingPage';
import { SetBetSelectSingle, SetBetSelectBanker } from '../Common/RacingBetline';

const RCOddsTableAllUp = ({alupIdx, betTypeList, defTableSize = 14}) => {
    const { t, i18n } = useTranslation();
    const context = useContext(RacingContext);
    const mtg = context.content.meeting;

    const alupData = context.content.alupData[alupIdx];

    const getBanker1 = () => {
        return "QIN|QPL|QQP|TRI".indexOf(alupData.betType)>=0 || (alupData.betType=="FCT" && alupData.subType!="M");
    }

    const getLeg = () => {
        return alupData.betType!='CWA';
    }

    const hideField = () => {
        return alupData.betType=='CWA' || (alupData.betType=="FCT" && alupData.subType=="S");
    }

    const RCColumns = {
        no: true,
        banker1: getBanker1(),
        banker2: false,
        banker3: false,
        leg: getLeg(),
        horseName: true,
        rcNoteM: false,
        win: true,
        place: false,
        field: !hideField(),
        banker1F: alupData.betType=="FCT" && alupData.subType=="MB",
        banker2F: false,
        banker3F: false
    }

    function VerticalWord({ val }) {
        return <span className={i18n.language === 'ch' && 'verticalWord'}>{val}</span>
    }

    const bank1Name = () => {
        let bankerLbl = t('LB_RC_ODDS_BANKER');
        if ( alupData.betType=="FCT" ) {
            switch (alupData.subType) {
                case 'S':
                    bankerLbl = <VerticalWord val={t('LB_RC_ODDS_1ST')} />
                    break;
                case 'B':
                case 'BM':
                    bankerLbl = <VerticalWord val={t('LB_RC_OODS_1ST_B')} />
                    break;
                case 'MB':
                    bankerLbl = <VerticalWord val={t('LB_RC_ODDS_1ST_MB')} />
                    break;
            }
        }
        return bankerLbl;
    }

    const getLegName = () => {
        let bankerLbl = t('LB_RC_ODDS_SEL');
        switch (alupData.betType) {
            case 'TRI':
            case 'QQP':
            case "QIN":
            case "QPL":
                bankerLbl = t('LB_RC_ODDS_LEG')
                break;
            case "FCT":
                switch (alupData.subType) {
                    case 'S':
                        bankerLbl = <VerticalWord val={t('LB_RC_ODDS_2ND')} />
                        break;
                    case 'B':
                    case 'BM':
                        bankerLbl = <VerticalWord val={t('LB_RC_OODS_LEG_B')} />
                        break;
                    case 'MB':
                        bankerLbl = <VerticalWord val={t('LB_RC_ODDS_2ND_MB')} />
                }
                break;
        }
        return bankerLbl;
    }


    const RCColumnLbls = {
        banker1: bank1Name(),
        leg: getLegName()
    }

    const handleDropdownMenuChange = (betType, maxLeg) => {
        context.racingFuncs.resetAlupData({ idx: alupIdx, betType: betType, maxLeg: maxLeg });
        ClearBetRaces(alupData.raceNo);
    }

    const handleDropdownSubMenuChange = (subType) => {
        context.racingFuncs.resetAlupData({ idx: alupIdx, subType: subType });
        ClearBetRaces(alupData.raceNo);
    }

    const handleFCTOddsClick = (_type, _race, _col, _no, val) => {
        switch (alupData.subType) {
            case "S":
                SetBetSelectSingle(_type, _race, _col, _no, val);
                break;
            case "B":
            case "BM":
                SetBetSelectBanker(_type, _race, _col, _no, val);
                break;
        }
    }

    let tmpMsgs = getComingleMsgsAlup(mtg, context.content.page, alupData.betType, alupData.raceNo);

    let comingleMsg1 = [];
    if (tmpMsgs != null) {
        let comingleLang = i18n.language == "en" ? "E" : "C";
        if ( tmpMsgs['NOTE2_' + comingleLang]!= '' )
            comingleMsg1.push(<div className="rc-comingle-remarks"><span style={{ fontWeight: "bold" }}>{t('LB_RC_COMINGLE_NOTE')}1: </span>{tmpMsgs['NOTE2_' + comingleLang]}</div>);
        if ( tmpMsgs['NOTE3_' + comingleLang]!= '' )
            comingleMsg1.push(<div className="rc-comingle-remarks"><span style={{ fontWeight: "bold" }}>{t('LB_RC_COMINGLE_NOTE')}2: </span>{tmpMsgs['NOTE3_' + comingleLang]}</div>);
        if ( tmpMsgs['NOTE4_' + comingleLang]!= '' )
            comingleMsg1.push(<div className="rc-comingle-remarks"><span style={{ fontWeight: "bold" }}>{t('LB_RC_COMINGLE_NOTE')}3: </span>{tmpMsgs['NOTE4_' + comingleLang]}</div>);
    }

    return (<div>
        <OddsTable betType={alupData.betType} columns={RCColumns} lbl={RCColumnLbls}
            firstRace={alupData.raceNo} raceNo={alupData.raceNo}
            selectedBetType={alupData.betType} selectedSubBetType={alupData.subType} startCnt={0} showRaceHeader={true}
            showOddsDropdownMenu={true} betTypeList={betTypeList} handleDropdownMenuChange={handleDropdownMenuChange}
            handleDropdownSubMenuChange={handleDropdownSubMenuChange} defTableSize={defTableSize} showCWATable={true}
            oddsCheckboxClickCallback={alupData.betType==="FCT" ? handleFCTOddsClick : null} />
        {comingleMsg1}
    </div>
    )
}
export default RCOddsTableAllUp;