import { NewWindow } from '../../Common/home-common';

export function getDefaultPageFromURL() {
    let tmpPage = 'HOME';
    const pathname = window.location.pathname;
    let params = pathname.split("/").filter(Boolean);
    if ( params.length > 2 ) {
        if ( params[2]==null || params[2]=='' ) {
            tmpPage = 'HOME';
        }
        else {
            tmpPage = params[2].toUpperCase();
        }
    }

    return tmpPage;
}

export function isStartSell(draw) {
    if (draw?.lotteryPool?.status && ['StartSell'].includes(draw.lotteryPool.status) && ['Defined'].includes(draw.status)){
        return true;
    }
    return false;
}

export function isSnowballDraw(draw) {
    return draw !=null && draw.snowballCode!=null && draw.snowballCode!="";
}

export function isSnowballPage(pageName) {
    return pageName.indexOf("SNOWBALL")>=0;
}

export const getBallImage = (no, size, lang) => {
    let curLang = lang==="ch" ? "ch" : "en";
    return <img className={`ballsize-${size}`} src={require(`../../../info/marksix/info/images/marksix-${no}${no==="F" ? `_${curLang}` : ''}.svg`)} alt={no}/>
}

export const handleClickHelpLink = (lang) => {
    let pLang = lang==="en" ? "en-US" : "zh-HK";
    let url =  window.globalConfig.SPECIAL_URL + window.specialPages['M6_HELP']?.replace('{0}', pLang);
    NewWindow(url, '', 770, 500, 1, 1);
};

export const handleClickChanceTableLink = (lang) => {
    let pLang = lang==="en" ? "en-US" : "zh-HK";
    let url =  window.globalConfig.SPECIAL_URL + window.specialPages['M6_CHANCE_TABLE']?.replace('{0}', pLang);
    NewWindow(url, '', 770, 500, 1, 1);
};

export const getM6RespDate = (ts) => {
    let respDate = new Date();
    let date=ts.split(`${ts.indexOf('+')>-1?'+':'T'}`)[0].split('-')
    let time=ts.split(`${ts.indexOf('+')>-1?'+':'T'}`)[1].split(':')
    respDate.setFullYear(Number(date[0]));
    respDate.setMonth(Number(date[1])-1);
    respDate.setDate(Number(date[2]));
    respDate.setHours(Number(time[0]));
    respDate.setMinutes(Number(time[1]));
    return respDate;
};