import moment from 'moment';

const validateScriptCode = (scriptCode) => {
    return /^\w+$/.test(scriptCode);
}

export const loadBanner = function (scriptCode) {        
    let script = document.createElement('script');
    if (!validateScriptCode(scriptCode)) return script;
    script.id = 'js_' + scriptCode;
    script.type = "text/javascript";
    script.src = `${window.globalConfig.COMMON_URL}/bannerad/js/zone/sdceo_zone_${scriptCode}_banner.js?date=${moment().format("YYYYMMDDHHmm")}`;
    document.head.appendChild(script);
    return script;
};