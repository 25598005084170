import { featureMatchCompare, pushLiveEvtFun, isMatchVoided, matchCompare, filterMatch } from './common';
import { getDisplayMatches } from '../useGetMatchList/common';
import { trimTournament, getQueryTorunIds, isTournamentPage } from '../../Common/Common';
import { parseTopic } from './parseTopic';
import { updateTV } from './parseUpdMatchAdm';

export const parseUpdMatch =async (message, topicInfo, contentRef, loadMatchQuery, mQueue) => {
    
    let isOFM =  contentRef.current.page === 'OFM' || (contentRef.current.selection.type === 'OFM' && contentRef.current.page == 'MIXALLUP')
    let isTournPage = isTournamentPage(contentRef.current.page)
    const isAllOddsPage = contentRef.current.config.AllOddsPages.includes(contentRef.current.page)
    const compare = isOFM ? featureMatchCompare : matchCompare
    const isKickedOff = ["KICKEDOFF"].includes(message.st.toUpperCase())
    let rawMatch = null;
    rawMatch = contentRef.current.rawMatchList.find(x => x.tournament.id===topicInfo.tournamentid && x.id === topicInfo.matchid)
    if(rawMatch){
        updateMatchData(rawMatch, message,contentRef, isOFM ? mQueue : null);
    }
    if(contentRef.current.matchData != null) {
        let match = null;
        if(!contentRef.current.isESContent && ((isMatchVoided(message.st) || isKickedOff) && !isAllOddsPage) ){
            contentRef.current.matchData.matches = contentRef.current.matchData.matches.filter(x =>  x.id != topicInfo.matchid || x.tournament.id != topicInfo.tournamentid )
        }else{
            match = contentRef.current.matchData.matches.find(x => x.tournament.id===topicInfo.tournamentid && x.id === topicInfo.matchid);

            if(match && !isTournPage){
                updateMatchData(match, message,contentRef, isOFM ? mQueue : null);
                if(!filterMatch(match, contentRef)){
                    contentRef.current.matchData.matches = contentRef.current.matchData.matches.filter(i => i.id != match.id)
                }
            }else if(contentRef.current.page === 'OFM' ){
                const curMatchList = getDisplayMatches(contentRef.current.rawMatchList, contentRef.current.page, contentRef.current.offset);
                if(curMatchList.length > contentRef.current.matchData.matches.length ){
                    const ids = [...curMatchList].map(i => i.id)
                    const newQueue = [...mQueue]
                    await loadMatchQuery({ page: 'ALLODDS', matchIds: ids }, null, {isNewFeatureMatch: true, disabledMsgCache: true})
                    contentRef.current.matchData.matches.forEach(match => {
                        if(!match.featureMatchSequence){
                            
                         updateMatchData(match, message, contentRef,  newQueue);
                        }
                    })
                }
            }
        }
    }
    
    if(isTournPage){
        const tournament = contentRef.current?.tournData?.tournaments?.find(item => item.id === topicInfo.tournamentid)
        if(tournament){
            updateTagMatchPool(tournament, rawMatch)
        }
    }

    let matchList = getDisplayMatches(contentRef.current.rawMatchList, contentRef.current.page, contentRef.current.offset);
    contentRef.current.listData = { matches : matchList };
    
    if(isOFM){
        updateOFMMatchData(contentRef)
    }
    if(!isOFM && contentRef.current.page != 'MIXALLUP_DETAIL' ){
        contentRef.current.matchData?.matches?.sort(compare)
    }

    if(["TOURN"].includes(contentRef.current.selection.type) && !isTournPage) {
        contentRef.current.selection.tabs = trimTournament(contentRef.current.listData.matches);
        const isEmptyTourn = !contentRef.current?.listData?.matches.some(match => contentRef.current.selection.value.includes(match.tournament.id))
        const newTournIds = getQueryTorunIds(contentRef.current.listData.matches, contentRef.current, null)
        if(contentRef.current?.matchData?.matches?.length === 0 && loadMatchQuery){
            if(isEmptyTourn){
                loadMatchQuery({ page: contentRef.current.page, tournIds: newTournIds }, null, {disabledMsgCache: true})
            }
        }
    }
}

const updateMatchData = (match, message,contentRef,  mQueue) => {
    let featureMatch
    let tvChannels
    if(mQueue){
        let curMsg = mQueue?.find(x =>{
            let topicInfo = parseTopic(x.topic, contentRef)
            return topicInfo.matchid === match?.id && topicInfo.matchAdminUpd
        })
        featureMatch =  curMsg?.message?.admOpt?.featureMatch
        tvChannels =  curMsg?.message?.admOpt?.mediaChl?.tVChl?.prog
        match.featureMatchSequence = curMsg ?  featureMatch?.seq : match.featureMatchSequence;
        match.featureStartTime = curMsg ?  featureMatch?.startTm : match.featureStartTime;
    }
    match.awayTeam.id = message.aTeamId;
    match.frontEndId = message.frontEndId;
    match.homeTeam.id = message.hTeamId;
    match.inplayDelay = message.ref?.inplayDelay ;
    if(message.chlInfo){
        match.isInteractiveServiceAvailable =  message.chlInfo.find(x=> x.chl == "InteractiveService").st == "Available";
    }
    match.kickOffTime = message.kOTm;
    match.matchDate = message.bizDt.substring(0,10) + '+08:00';
    match.sequence = message.seq;
    match.status =  message.st.toUpperCase();
    match.esIndicatorEnabled = message.isESOffer;
    match.updateAt = message.updAt;
    match.venue = null;
    if(message.ven && message.neGd){
        match.venue = {
            code : message.ven.code,
            name_ch : message.ven.nm.find(x=>x.ln == "zh-hk").val,
            name_en : message.ven.nm.find(x=>x.ln == "en-us").val

        }
    }

    match.liveEvents = [pushLiveEvtFun(message)];

    if(tvChannels){
        match.tvChannels = [];
        for(let i=0 ; i< tvChannels.length; i++){
            match.tvChannels.push(updateTV(tvChannels[i]));
        }
    }
}

const updateOFMMatchData = (contentRef) =>{
        contentRef.current.matchData.matches = contentRef.current.matchData.matches.filter(m => m.featureStartTime && m.featureMatchSequence )
}

const updateTagMatchPool = (tournament, match) =>{
    tournament?.foPools?.forEach(pool => {
        if(pool?.match?.id  == match?.id){
            pool.match ={
                id: match?.id,
                status: match?.status,
                homeTeam: {name_en: match?.homeTeam?.name_en}
            }
        }
    })
}