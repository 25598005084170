import { createPool } from './createPool';
import { updatePoolInfo } from './parseUpdMatchPool'
import { checkChpType } from './common';
import {setTournamentPageTabs, trimTournamentByTourn, } from '../../Common/Common';

export const parseNewTournPool = (message, topicInfo, contentRef) => {
    let tourn = contentRef.current.tournData?.tournaments.find(x => x.id == topicInfo.tournamentid);
    let rawTourn = contentRef.current.rawTournList.find(x => x.id == topicInfo.tournamentid)
    const currentPool = createPool(contentRef, message)
    rawTourn.poolInfo ={
        normalPools: [],
        inplayPools: [],
        sellingPools: []
    }
    updatePoolInfo(rawTourn, message)
    if(tourn){
        updatePoolInfo(tourn, message)
        let idx = tourn.foPools.findIndex(x => x.id == (topicInfo.poolid ? topicInfo.poolid : message.id));
        if ( ["SellingStarted", "SellingStopped", "Hold"].includes(message.st) ) {
            if ( idx >= 0 ) {
                tourn.foPools[idx] = currentPool;
            }
            else {
                if ( contentRef.current.page=="WINCNTY" && message.bTyp=="CHP" && message.dtl.chpTyp==1 ) {
                    tourn.foPools.push(currentPool);
                }
                else if ( contentRef.current.page=="WINCNTT" && message.bTyp=="CHP" && message.dtl.chpTyp==2 ) {
                    tourn.foPools.push(currentPool);
                }
                else if ( contentRef.current.page=="FINALIST" && message.bTyp=="CHP" && message.dtl.chpTyp==3 ) {
                    tourn.foPools.push(currentPool);
                }
                else if ( contentRef.current.page=="CHP" && message.bTyp=="CHP" && !message.dtl.chpTyp ) {
                    tourn.foPools.push(currentPool);
                }
                else if ( contentRef.current.page=="TQL" && message.bTyp=="TQL" ) {
                    tourn.foPools.push(currentPool);
                }
                else if ( contentRef.current.page=="TOURNAMENT" ) {
                    tourn.foPools.push(currentPool);
                }
            }
        }
    }else{
        if ( ["SellingStarted", "SellingStopped", "Hold"].includes(message.st) ) {
            let foPools = []
            if ( contentRef.current.page=="WINCNTY" && message.bTyp=="CHP" && message.dtl.chpTyp==1 ) {
                foPools.push(currentPool);
            }
            else if ( contentRef.current.page=="WINCNTT" && message.bTyp=="CHP" && message.dtl.chpTyp==2 ) {
                foPools.push(currentPool);
            }
            else if ( contentRef.current.page=="FINALIST" && message.bTyp=="CHP" && message.dtl.chpTyp==3 ) {
                foPools.push(currentPool);
            }
            else if ( contentRef.current.page=="CHP" && message.bTyp=="CHP" && !message.dtl.chpTyp ) {
                foPools.push(currentPool);
            }
            else if ( contentRef.current.page=="TQL" && message.bTyp=="TQL" ) {
                foPools.push(currentPool);
            }
            else if ( contentRef.current.page=="TOURNAMENT" && rawTourn.nameProfileId == contentRef.current.tournNameProfileId) {
                foPools.push(currentPool);
            }
            if(foPools.length > 0 ){
                rawTourn.foPools = foPools
                contentRef.current.tournData.tournaments.push(rawTourn)
            }
        }
    }
    
    contentRef.current.selection.tabs = trimTournamentByTourn(contentRef.current.tournData.tournaments);
    setTournamentPageTabs(contentRef.current.tournData.tournaments, contentRef.current)
}