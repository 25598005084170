import React from 'react';
import MatchTable from '../Common/MatchTable';
import { Remark } from '../Common/MatchBase';

const OFM = () => {
    
    return (
        <section className='OFM'>
            <MatchTable betType='HAD' etBetType='EHA' />
            <Remark />
        </section>
    )
}
export default OFM;