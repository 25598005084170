import React,{ useEffect, useState, useContext } from 'react';
import { formatDateObj } from '../../Common/GlobalFunc';
import ResultsLuckyNumber from '../Common/ResultsLuckyNumber'
import ResultsSearchBox from '../Common/ResultsSearchBox'
import ResultsTable from '../Common/ResultsTable'
import ResultDetail from '../Common/ResultDetail'
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { getMarksixResultQuery } from '../Data/DataQuery';
import { MarksixContext } from '../../Home/MarksixPage';
import Loading from '../../Common/ui-components/Loading';
import remarkImg from '../../../info/Include/images/sms_failed.svg';
import { GlobalContext } from '../../../StandardPage';

const Results = (props) => {
    const {t, i18n} = useTranslation()
    const context = useContext(MarksixContext);
    const globalContext = useContext(GlobalContext);
    const sessionServerTimeStamp = globalContext?.sessionServerTimeStamp
    const offset = context?.gqlVars?.data?.timeOffset?.m6 || 0
    const offsetData = new Date(sessionServerTimeStamp + offset * 1000)
    let toDate = offsetData;
    let fromDate = offsetData;
    // fromDate.setMonth(fromDate.getMonth()-1);
    const [detailId, setDetailId] = useState(null)
    const [apiErr,setApiErr]=useState(false);
    const [pageNumber, setPageNumber] = useState(1)

    const [searchParams, setSearchParams] = useState({
        drawType: 'All',
        drawSearchParamsType: 'drawNumber',
        drawNumber: 10,
        dateFrom: fromDate,
        dateTo: toDate
    })

    const resGqlVars = useQuery(getMarksixResultQuery(), {
        variables: { "lastNDraw": 10 },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onCompleted: res=> { 
            setApiErr(false);
        },
        onError:err=>{
            setApiErr(true);
        }
    });

    const handleClickSearch = () => {
        setPageNumber(1);
        if( searchParams.drawSearchParamsType === 'drawDate'){
            const dateFrom = searchParams.dateFrom
            const dateTo = searchParams.dateTo
            let outOfRange = false
            const dateToMonth = dateTo.getMonth() + (dateTo.getFullYear() - dateFrom.getFullYear()) * 12
            if( dateToMonth - dateFrom.getMonth() >= 4 ){
                outOfRange = true
            }else if(dateToMonth - dateFrom.getMonth()  === 3 ){
                if(dateFrom.getDate() < dateTo.getDate()) outOfRange = true
            }
            if(outOfRange) return alert(t('LB_M6_RESULTS_DRAW_Date_ERRMSG'));

            resGqlVars.refetch({
                "startDate": formatDateObj(dateFrom)[0].replace(/-/g, ""),
                "endDate":  formatDateObj(dateTo)[0].replace(/-/g, ""),
                "drawType":  searchParams.drawType,
                "lastNDraw": null
            });
        }
        if ( searchParams.drawSearchParamsType === 'drawNumber') {
            resGqlVars.refetch({
                "lastNDraw": searchParams.drawNumber,
                "drawType":  searchParams.drawType,
                "startDate": null,
                "endDate": null
            });
        }
    }

    useEffect(() => {
        setDetailId(null);
    }, []);

    const handleResultDtls = (id) => {
        setDetailId(id)
    }

    if (!context.pageName) {
        return <Loading/>;
    } else {
        return <section className={context.pageName}>
            {!detailId ? <>
            <header className='result-header-m'>{t('LB_M6_RESULTS')}</header>
            <ResultsLuckyNumber />
            <ResultsSearchBox searchParams={searchParams} onChangeSearchParams={setSearchParams} handleClickSearch={handleClickSearch} offsetData={offsetData}/>
            <ResultsTable onclickResultDtls={handleResultDtls} draws={resGqlVars?.data?.lotteryDraws} apiErr={apiErr} apiLoading={resGqlVars.loading} setPageNumber={setPageNumber} pageNumber={pageNumber} /></>
            :
            <ResultDetail handleClickBack={() => setDetailId('')} draw={resGqlVars?.data?.lotteryDraws.find(item => item.id === detailId )}/>}

        </section >
    }
}

export default Results