import React, { useEffect, useState } from 'react';
import FPSChargeTips from '../components/FPSChargeTips';
import { postWithdrawalEPS } from '../FundsTransferAPI';
import { useTranslation } from 'react-i18next';
import { postWithdrawalEPS_IB } from '../FundsTransferIBAPI';
import useBroadcastChannel from '../../../Common/hooks/useBroadcastChannel';

const WithdrawalPreviewValidation = ({
    nbaData,
    setCurStep,
    postWithdrawalData,
    goToLinkBAPage,
    withdrawalParams,
    setPostWithdrawalData,
    setLoadingStatus,
    isReturnErrorCode
}) => {
    const { t, i18n } = useTranslation();
    const { amount } = withdrawalParams;
    const [title, setTitle] = useState('');
    const [topMsg, setTopMsg] = useState('');
    const nba1 = nbaData?.nba1 || {};
    const config = nbaData?.config || {};
    const AUTOPAYConfig = config?.AUTOPAY || {};
    const { method, responseData } = postWithdrawalData;
    const bankInfo = nba1.bankInfo;
    const [showAUTOPAYMsg, setShowAUTOPAYMsg] = useState(true);
    const broadcast_resetlogin = useBroadcastChannel('resetLoginTimeout');

    useEffect(() => {
        let msg;
        let newTitle;
        if (method === 'FPS') {
            msg = t(`LB_BS_FT_WITHDRAWAL_POSTERR_${responseData.errorCode}`);
            newTitle = t(`LB_BS_FT_WITHDRAWAL_POSTERR_TITLE_${responseData.errorCode}`);
            if ((responseData.errorCode == '62' || responseData.errorCode == '64') && getAUTOPAYErrMsg()) {
                msg = t(`LB_BS_FT_WITHDRAWAL_POSTERR_${responseData.errorCode}_BLOCKEDAW`);
                setShowAUTOPAYMsg(false);
            }
        } else if (method === 'EPS') {
            let code = responseData.code;
            if (![64, 62, 2003, 2004].includes(responseData.code)) {
                code = 99;
            }

            msg = t(`LB_BS_FT_WITHDRAWAL_POSTERR_${code}`);
            newTitle = t(`LB_BS_FT_WITHDRAWAL_POSTERR_TITLE_${code}`);
            if ([2003, 2004].includes(responseData.code)) {
                const FPSLinkMsg = (
                    <>
                        {t('LB_BS_FT_WITHDRAWAL_POSTERR_PINERR_1')}
                        <span className="goToLinkBank" onClick={goToLinkBank}>
                            {t('LB_BS_FT_WITHDRAWAL_POSTERR_PINERR_2')}
                        </span>
                        {t('LB_BS_FT_WITHDRAWAL_POSTERR_PINERR_3')}
                    </>
                );
                msg = (
                    <>
                        {t(`LB_BS_FT_WITHDRAWAL_POSTERR_${code}_1`)}
                        {getRegisterMsg() ? FPSLinkMsg : ''}
                        {t('LB_BS_FT_WITHDRAWAL_POSTERR_2003_2')}
                    </>
                );
            }
            if ([62, 64].includes(responseData.code) && getAUTOPAYErrMsg()) {
                msg = t(`LB_BS_FT_WITHDRAWAL_POSTERR_${responseData.code}_BLOCKEDAW`);
                setShowAUTOPAYMsg(false);
            }
        }

        setTitle(newTitle);
        setTopMsg(msg);
    }, []);
    const lang = i18n.language === 'en' ? 'en' : 'ch';
    const continueFn = async () => {
        if (getAUTOPAYErrMsg()) {
            //if (method === 'FPS' && (responseData.errorCode == '62' || responseData.errorCode == '64')) {
            //    window.close();
            //    return;
           // }
            backFn();
            return;
        }
        setLoadingStatus(true);
        const params = {
            pinBlock: '00000000',
            amount: amount,
            type: 1
        };
        const resData = window.globalConfig.IS_IB
            ? await postWithdrawalEPS_IB({ lang, params, timeout: AUTOPAYConfig.timeout })
            : await postWithdrawalEPS({ params, timeout: AUTOPAYConfig.timeout });
        broadcast_resetlogin?.postMessage(false);
        isReturnErrorCode(sessionStorage.getItem('eft_status'));
        let methodName = t(`LB_BS_FT_AUTOPAY`).replace('{0}', t('LB_BS_FT_WITHDRAWAL'));
        let errMsg = '';
        let errMsg_en;
        let errMsg_ch;
        let status = 'SUCCESSFUL';
        if (resData.code == 0) {
            if (resData.errCode === 'TIMEOUT') {
                status = 'TIMEOUT';
            }
        } else {
            status = 'REJECTED';
        }

        errMsg_en = resData?.errMsg;
        errMsg_ch = resData?.errMsgTC;
        errMsg = i18n.language === 'en' ? errMsg_en : errMsg_ch;

        setPostWithdrawalData({
            method: 'AUTOPAY',
            methodName,
            status,
            errMsg,
            errMsg_en,
            errMsg_ch,
            responseData: resData
        });

        setLoadingStatus(false);
        setCurStep(3);
    };

    const backFn = () => {
        if ([2003].includes(responseData.code)) {
            setCurStep(1);
            return;
        }
        setCurStep(0);
    };

    const goToLinkBank = () => {
        goToLinkBAPage(1);
    };

    const getRegisterMsg = () => {
        if (bankInfo.eDDADepositEnable != '1' || method !== 'EPS' || nba1.FPS.show || nba1.FPS.linkStatus != 'NO')
            return;

        if ([2003, 2004].includes(responseData.code))
            return <></>;
        
        let msg = (
            <>
                {t('LB_BS_FT_WITHDRAWAL_REGISTER_MSG1')}
                <span className="goToLinkBank" onClick={goToLinkBank}>
                    {' '}
                    {t('LB_BS_FT_WITHDRAWAL_REGISTER_LINKTEXT')}{' '}
                </span>
                {t('LB_BS_FT_WITHDRAWAL_REGISTER_MSG3')}
            </>
        );

        // if([62,64].includes(responseData.code)){
        //     msg = <>{t('LB_BS_FT_WITHDRAWAL_REGISTER_MSG2')}<span className='goToLinkBank' onClick={goToLinkBank}> {t('LB_BS_FT_WITHDRAWAL_REGISTER_LINKTEXT')} </span>{t('LB_BS_FT_WITHDRAWAL_REGISTER_MSG3')}</>
        // }
        return msg;
    };

    const getAUTOPAYErrMsg = () => {
        let msg;
        const nba1 = nbaData?.nba1;
        if (nba1.AUTOPAY.disabled) {
            if (nba1.FPS.show) {
                msg = t('LB_BS_FT_WITHDRAWAL_AUTOPAY_DISABLED1');
            } else {
                if (bankInfo.eDDADepositEnable != '1') {
                    msg = t('LB_BS_FT_WITHDRAWAL_AUTOPAY_DISABLED1');
                } else {
                    msg = t('LB_BS_FT_WITHDRAWAL_AUTOPAY_DISABLED2');
                }
            }
        }
        return msg;
    };

    const getContinueBtnText = () => {
        if (!getAUTOPAYErrMsg()) return null;
        //if (method === 'FPS' && (responseData.errorCode == '62' || responseData.errorCode == '64'))
        //    return t('LB_CLOSE');
        return t('LB_BS_FT_CANCEL');
    };

    const message = () => {
        return (
            <>
                <div className="mrB24 prewrap">{topMsg}</div>
                {showAUTOPAYMsg && (
                    <>
                        <div className={getAUTOPAYErrMsg() ? 'mrB24' : ''}>
                            {t('LB_BS_FT_WITHDRAWAL_FPSERR_AUTOPAY')}
                        </div>
                        {getAUTOPAYErrMsg() && <div className="dimColor">{getAUTOPAYErrMsg()}</div>}
                    </>
                )}
            </>
        );
    };

    const registerMsg = () => {
        return <>{getRegisterMsg() && <div>{getRegisterMsg()}</div>}</>;
    };

    return (
        <FPSChargeTips
            props={{
                backFn: getAUTOPAYErrMsg() ? null : backFn,
                continueFn,
                message,
                title,
                continueBtnText: getContinueBtnText(),
                grayAreaMessage: registerMsg
            }}
        />
    );
};

export default WithdrawalPreviewValidation;
