import React, { createContext } from 'react';

const configs = {
  global: null,

  racing: window.racingConfig,
  football: window.fbConfig,
  marksix: null,

  racingNaviationMenu: null,
  footballNaviationMenu: null,
  marksixNaviationMenu: null,

  racingPages: window.rcPageConfig,
  racingComingleMsgs: null,
  racingGearConfig: {},

  footballPages: window.fbPageConfig,
  betslipMenu: window.bsConfig,

  marksixPages: null,

  channelParas: null
};

export const ConfigContext = createContext(configs)

export function SetConfig(type, config){
  configs[type] = config;

  if ( type==="channelParas" && configs.channelParas.WebPara) {
    Object.keys(configs.channelParas.WebPara.onlinepara).forEach(idx => {
      window.sessionStorage.setItem(idx, configs.channelParas.WebPara.onlinepara[idx]);
    })
    Object.keys(configs.channelParas.WebPara.channelpara).forEach(idx => {
      window.sessionStorage.setItem(idx, configs.channelParas.WebPara.channelpara[idx]);
    })
    Object.keys(configs.channelParas.WebPara.eWinService).forEach(idx => {
      if ( typeof configs.channelParas.WebPara.eWinService[idx] === "object" )
        window.sessionStorage.setItem(idx, JSON.stringify(configs.channelParas.WebPara.eWinService[idx]));
      else {
        window.sessionStorage.setItem(idx, configs.channelParas.WebPara.eWinService[idx]);
      }
    })
    sessionStorage.setItem("eWinParaDone", "1");
  }
}

export function GetGlobalConfig() {
  return configs.global;
}

export function GetRaceConfig() {
  return window.racingConfig;
}

export function GetRacingPageConfigs() {
  return window.rcPageConfig;
}

export function GetRacingComingleMsgs() {
  return configs.racingComingleMsgs;
}

export function getRacingGearConfig(){
  return configs.racingGearConfig;
}

export function GetFootballConfig() {
  return window.fbConfig;
}


export function GetFootballPageConfigs() {
  return window.fbPageConfig;
}

export function GetMarksixConfig() {
  return configs.marksix;
}


export function GetMarksixPageConfigs() {
  return configs.marksixPages;
}

export function GetBetslipConfig() {
  return window.bsConfig;
}

export function GetChannelParas() {
  return configs.channelParas;
}

export function GetAllConfig() {
  return configs
}

export const QueryConfig = async (type, url) => {
  await fetch(url, { method: "GET" })
    .then(res => res.json())
    .then(
      (result) => {
        SetConfig(type, result);
      },
      (error) => {
      }
    )
}