import React from 'react';
import MatchTable from '../Common/MatchTable';
import { Remark } from '../Common/MatchBase';

const MSP = () => {
    return <section className='MSP'>
        <MatchTable betType='MSP'/>
        <Remark />
    </section>
}
export default MSP;