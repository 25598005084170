import React from 'react';

/**
 * 
 * @param {string} type football racing marksix 
 * 
 */
const Radio = ({ onChange, checked, disabled, className, label, value, type='racing' }) => {
    const handleClick = (e) => {
        if (disabled || !onChange) return;
        onChange(e);
    };

    const colorClassName = type?  ` ${type}-radio` : ''

    return (
        <div className="reactRadio">
            <label className={`radio-container${colorClassName} ${className ? className : ''}`} onClick={(e) => handleClick(e)}>
                {label}
                <input
                    type="radio"
                    onChange={(e) => handleClick(e)}
                    checked={checked}
                    disabled={disabled}
                    value={value}
                />
                <span className="radio-checkmark" />
            </label>
        </div>
    );
};

export default Radio;
