import eWinLogo from '../../../info/Include/images/eWinLogo.svg';
import eWinSpeedLogo from '../../../info/Include/images/HKJC_priority_logo_black.svg';
import Football from '../../../info/Include/images/FootballLogo.svg';
import horse from '../../../info/Include/images/horse.svg';
import MarkSix from '../../../info/Include/images/MarkSix.svg';
import moreIcon from '../../../info/Include/images/more_svg.svg';
import betslipIcon from '../../../info/Include/images/betslip_svg.svg';
import win_PlaceIcon from '../../../info/Include/images/win_Place.svg';
import result_horseIcon from '../../../info/Include/images/result_horse.svg';
import result_football from '../../../info/Include/images/result_football.svg';
import earlySettlement_svg from '../../../info/Include/images/earlySettlement_svg.svg';
import self_select_single from '../../../info/Include/images/self_select_single.svg';
import result_MarkSix from '../../../info/Include/images/result_MarkSix.svg';
import homeIcon from '../../../info/Include/images/homeIcon.svg';

const homeLogo = window.globalConfig.IS_SPEEDBET ? eWinSpeedLogo : eWinLogo;
export const mobileBottomMenuEn = {
	"data": {
		"item": {
			"children": [
				{
				  "sectionTitle": null,
				  "sectionEnabled": null,
				  "sectionIcon": null,
				  "key": "Racing Menu options",
				  "itemName": null,
				  "itemRedirection": null,
				  "itemLink": null,
				  "itemPage": null,
				  "itemIcon": null,
				  "jpFlag": null,
				  "altTxt": null,
				  "itemEnable": null,
				  "redirectMethod": null,
				  "pH": null,
				  "pW": null,
				  "pX": null,
				  "pY": null,
				  "specialtag": null,
				  "predefinedColor": null,
				  "frontendID": null,
				  "startDatetime": null,
				  "noOfDays": null,
				  "frontEndIds": null,
				  "sNoOfDays": null,
				  "moHead": null,
				  "moText": null,
				  "moImage": null,
				  "iconM": null,
				  "pCardEnable": null,
				  "GlassboxCustomEventName": null,
				  "GlassboxCustomEventValue": null,
				  "GlassboxTaggingEnabled": null,
				  "children": [
					{
					  "key": "Int_Racing",
					  "itemName": {
						"value": "Racing"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "/en/racing"
					  },
					  "itemIcon": {
						"src": homeIcon,
						"isLocalSrc":true,
						"width": null,
						"height": null
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_Win Place",
					  "itemName": {
						"value": "Win Place"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "/en/racing/wp"
					  },
					  "itemIcon": {
						"src": win_PlaceIcon,
						"isLocalSrc":true,
						"width": null,
						"height": null
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_Result",
					  "itemName": {
						"value": "Results/ Dividend"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "/en/racing/results"
					  },
					  "itemIcon": {
						"src": result_horseIcon,
						"isLocalSrc":true,
						"width": null,
						"height": null
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_Betslip",
					  "itemName": {
						"value": "Betslip"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": ""
					  },
					  "itemIcon": {
						"src": betslipIcon,
						"isLocalSrc":true,
						"width": 24,
						"height": 24
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_More",
					  "itemName": {
						"value": "More"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": ""
					  },
					  "itemIcon": {
						"src": moreIcon,
						"isLocalSrc":true,
						"width": 24,
						"height": 24
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_Football",
					  "itemName": {
						"value": "Football"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "/en/football/home"
					  },
					  "itemIcon": {
						"src": Football,
						"isLocalSrc":true,
						"width": 24,
						"height": 24
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": ""
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_Mark Six",
					  "itemName": {
						"value": "Mark Six"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "/en/marksix/home"
					  },
					  "itemIcon": {
						"src": MarkSix,
						"isLocalSrc":true,
						"width": 24,
						"height": 24
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": ""
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					}
				  ]
				},
				{
				  "sectionTitle": null,
				  "sectionEnabled": null,
				  "sectionIcon": null,
				  "key": "Football Menu options",
				  "itemName": null,
				  "itemRedirection": null,
				  "itemLink": null,
				  "itemPage": null,
				  "itemIcon": null,
				  "jpFlag": null,
				  "altTxt": null,
				  "itemEnable": null,
				  "redirectMethod": null,
				  "pH": null,
				  "pW": null,
				  "pX": null,
				  "pY": null,
				  "specialtag": null,
				  "predefinedColor": null,
				  "frontendID": null,
				  "startDatetime": null,
				  "noOfDays": null,
				  "frontEndIds": null,
				  "sNoOfDays": null,
				  "moHead": null,
				  "moText": null,
				  "moImage": null,
				  "iconM": null,
				  "pCardEnable": null,
				  "GlassboxCustomEventName": null,
				  "GlassboxCustomEventValue": null,
				  "GlassboxTaggingEnabled": null,
				  "children": [
					{
					  "key": "BottomNavigationMenuOption_BA",
					  "itemName": {
						"value": "Cash out"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "/en/football/cashout"
					  },
					  "itemIcon": {
						"src": earlySettlement_svg,
						"isLocalSrc":true,
						"width": 24,
						"height": 24
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": ""
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_Football",
					  "itemName": {
						"value": "Football"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "/en/football/home"
					  },
					  "itemIcon": {
						"src": homeIcon,
						"isLocalSrc":true,
						"width": null,
						"height": null
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_Results",
					  "itemName": {
						"value": "Results/ Dividend"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "/en/football/results"
					  },
					  "itemIcon": {
						"src": result_football,
						"isLocalSrc":true,
						"width": 24,
						"height": 24
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_Early Settlement",
					  "itemName": {
						"value": "Early Settlement"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "/en/football/cashout"
					  },
					  "itemIcon": {
						"src": earlySettlement_svg,
						"isLocalSrc":true,
						"width": 24,
						"height": 24
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_Betslip",
					  "itemName": {
						"value": "Betslip"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": ""
					  },
					  "itemIcon": {
						"src": betslipIcon,
						"isLocalSrc":true,
						"width": 24,
						"height": 24
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_More",
					  "itemName": {
						"value": "More"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": ""
					  },
					  "itemIcon": {
						"src": moreIcon,
						"isLocalSrc":true,
						"width": 24,
						"height": 24
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					}
				  ]
				},
				{
				  "sectionTitle": null,
				  "sectionEnabled": null,
				  "sectionIcon": null,
				  "key": "Mark Six Menu options",
				  "itemName": null,
				  "itemRedirection": null,
				  "itemLink": null,
				  "itemPage": null,
				  "itemIcon": null,
				  "jpFlag": null,
				  "altTxt": null,
				  "itemEnable": null,
				  "redirectMethod": null,
				  "pH": null,
				  "pW": null,
				  "pX": null,
				  "pY": null,
				  "specialtag": null,
				  "predefinedColor": null,
				  "frontendID": null,
				  "startDatetime": null,
				  "noOfDays": null,
				  "frontEndIds": null,
				  "sNoOfDays": null,
				  "moHead": null,
				  "moText": null,
				  "moImage": null,
				  "iconM": null,
				  "pCardEnable": null,
				  "GlassboxCustomEventName": null,
				  "GlassboxCustomEventValue": null,
				  "GlassboxTaggingEnabled": null,
				  "children": [
					{
					  "key": "Int_Mark Six",
					  "itemName": {
						"value": "Mark Six"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "/en/marksix"
					  },
					  "itemIcon": {
						"src": homeIcon,
						"isLocalSrc":true,
						"width": null,
						"height": null
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_Self Select Single",
					  "itemName": {
						"value": "Self select single"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "/en/marksix/Single"
					  },
					  "itemIcon": {
						"src": self_select_single,
						"isLocalSrc":true,
						"width": null,
						"height": null
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_Result",
					  "itemName": {
						"value": "Results"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "/en/marksix/results"
					  },
					  "itemIcon": {
						"src": result_MarkSix,
						"isLocalSrc":true,
						"width": null,
						"height": null
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_Betslip",
					  "itemName": {
						"value": "Betslip"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": ""
					  },
					  "itemIcon": {
						"src": betslipIcon,
						"isLocalSrc":true,
						"width": 24,
						"height": 24
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_More",
					  "itemName": {
						"value": "More"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": ""
					  },
					  "itemIcon": {
						"src": moreIcon,
						"isLocalSrc":true,
						"width": 24,
						"height": 24
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_Racing",
					  "itemName": {
						"value": "Racing"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "/en/racing/home/"
					  },
					  "itemIcon": {
						"src": horse,
						"isLocalSrc":true,
						"width": 24,
						"height": 24
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": ""
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_Football",
					  "itemName": {
						"value": "Football"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "/en/football/home"
					  },
					  "itemIcon": {
						"src": homeIcon,
						"isLocalSrc":true,
						"width": 24,
						"height": 24
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": ""
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					}
				  ]
				},
				{
				  "sectionTitle": null,
				  "sectionEnabled": null,
				  "sectionIcon": null,
				  "key": "eWin Menu options",
				  "itemName": null,
				  "itemRedirection": null,
				  "itemLink": null,
				  "itemPage": null,
				  "itemIcon": null,
				  "jpFlag": null,
				  "altTxt": null,
				  "itemEnable": null,
				  "redirectMethod": null,
				  "pH": null,
				  "pW": null,
				  "pX": null,
				  "pY": null,
				  "specialtag": null,
				  "predefinedColor": null,
				  "frontendID": null,
				  "startDatetime": null,
				  "noOfDays": null,
				  "frontEndIds": null,
				  "sNoOfDays": null,
				  "moHead": null,
				  "moText": null,
				  "moImage": null,
				  "iconM": null,
				  "pCardEnable": null,
				  "GlassboxCustomEventName": null,
				  "GlassboxCustomEventValue": null,
				  "GlassboxTaggingEnabled": null,
				  "children": [
					{
					  "key": "Int_Racing",
					  "itemName": {
						"value": "Racing"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "/en/racing"
					  },
					  "itemIcon": {
						"src": horse,
						"isLocalSrc":true,
						"width": null,
						"height": null
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_Football",
					  "itemName": {
						"value": "Football"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "/en/football/home"
					  },
					  "itemIcon": {
						"src": Football,
						"isLocalSrc":true,
						"width": 24,
						"height": 24
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_Mark Six",
					  "itemName": {
						"value": "Mark Six"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "/en/marksix"
					  },
					  "itemIcon": {
						"src": MarkSix,
						"isLocalSrc":true,
						"width": null,
						"height": null
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_Betslip",
					  "itemName": {
						"value": "Betslip"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "ewin/betslip"
					  },
					  "itemIcon": {
						"src": betslipIcon,
						"isLocalSrc":true,
						"width": 24,
						"height": 24
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_More",
					  "itemName": {
						"value": "More"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": ""
					  },
					  "itemIcon": {
						"src": moreIcon,
						"isLocalSrc":true,
						"width": 24,
						"height": 24
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					}
				  ]
				}
			  ]
		}
	}
}


export const mobileBottomMenuCh = {
	"data": {
		"item": {
			"children": [
				{
				  "sectionTitle": null,
				  "sectionEnabled": null,
				  "sectionIcon": null,
				  "key": "Racing Menu options",
				  "itemName": null,
				  "itemRedirection": null,
				  "itemLink": null,
				  "itemPage": null,
				  "itemIcon": null,
				  "jpFlag": null,
				  "altTxt": null,
				  "itemEnable": null,
				  "redirectMethod": null,
				  "pH": null,
				  "pW": null,
				  "pX": null,
				  "pY": null,
				  "specialtag": null,
				  "predefinedColor": null,
				  "frontendID": null,
				  "startDatetime": null,
				  "noOfDays": null,
				  "frontEndIds": null,
				  "sNoOfDays": null,
				  "moHead": null,
				  "moText": null,
				  "moImage": null,
				  "iconM": null,
				  "pCardEnable": null,
				  "GlassboxCustomEventName": null,
				  "GlassboxCustomEventValue": null,
				  "GlassboxTaggingEnabled": null,
				  "children": [
					{
					  "key": "Int_Racing",
					  "itemName": {
						"value": "賽馬"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "/ch/racing"
					  },
					  "itemIcon": {
						"src": homeIcon,
						"isLocalSrc":true,
						"width": null,
						"height": null
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_Win Place",
					  "itemName": {
						"value": "獨贏位置"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "/ch/racing/wp"
					  },
					  "itemIcon": {
						"src": win_PlaceIcon,
						"isLocalSrc":true,
						"width": null,
						"height": null
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_Result",
					  "itemName": {
						"value": "賽果/派彩"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "/ch/racing/results"
					  },
					  "itemIcon": {
						"src": result_horseIcon,
						"isLocalSrc":true,
						"width": null,
						"height": null
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_Betslip",
					  "itemName": {
						"value": "投注區"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": ""
					  },
					  "itemIcon": {
						"src": betslipIcon,
						"isLocalSrc":true,
						"width": 24,
						"height": 24
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_More",
					  "itemName": {
						"value": "更多"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": ""
					  },
					  "itemIcon": {
						"src": moreIcon,
						"isLocalSrc":true,
						"width": null,
						"height": null
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_Football",
					  "itemName": {
						"value": "足球"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "/ch/football/home"
					  },
					  "itemIcon": {
						"src": Football,
						"isLocalSrc":true,
						"width": 24,
						"height": 24
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": ""
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_Mark Six",
					  "itemName": {
						"value": "六合彩"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "/ch/marksix/home"
					  },
					  "itemIcon": {
						"src": MarkSix,
						"isLocalSrc":true,
						"width": 24,
						"height": 24
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": ""
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					}
				  ]
				},
				{
				  "sectionTitle": null,
				  "sectionEnabled": null,
				  "sectionIcon": null,
				  "key": "Football Menu options",
				  "itemName": null,
				  "itemRedirection": null,
				  "itemLink": null,
				  "itemPage": null,
				  "itemIcon": null,
				  "jpFlag": null,
				  "altTxt": null,
				  "itemEnable": null,
				  "redirectMethod": null,
				  "pH": null,
				  "pW": null,
				  "pX": null,
				  "pY": null,
				  "specialtag": null,
				  "predefinedColor": null,
				  "frontendID": null,
				  "startDatetime": null,
				  "noOfDays": null,
				  "frontEndIds": null,
				  "sNoOfDays": null,
				  "moHead": null,
				  "moText": null,
				  "moImage": null,
				  "iconM": null,
				  "pCardEnable": null,
				  "GlassboxCustomEventName": null,
				  "GlassboxCustomEventValue": null,
				  "GlassboxTaggingEnabled": null,
				  "children": [
					{
					  "key": "BottomNavigationMenuOption_BA",
					  "itemName": {
						"value": "Cash out"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "/ch/football/cashout"
					  },
					  "itemIcon": {
						"src": earlySettlement_svg,
						"isLocalSrc":true,
						"width": 24,
						"height": 24
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": ""
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_Football",
					  "itemName": {
						"value": "足球"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "/ch/football/home"
					  },
					  "itemIcon": {
						"src": homeIcon,
						"isLocalSrc":true,
						"width": null,
						"height": null
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_Results",
					  "itemName": {
						"value": "賽果/派彩"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "/ch/football/results"
					  },
					  "itemIcon": {
						"src": result_football,
						"isLocalSrc":true,
						"width": 24,
						"height": 24
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_Early Settlement",
					  "itemName": {
						"value": "派彩快"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "/ch/football/cashout"
					  },
					  "itemIcon": {
						"src": earlySettlement_svg,
						"isLocalSrc":true,
						"width": 24,
						"height": 24
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_Betslip",
					  "itemName": {
						"value": "投注區"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": ""
					  },
					  "itemIcon": {
						"src": betslipIcon,
						"isLocalSrc":true,
						"width": 24,
						"height": 24
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_More",
					  "itemName": {
						"value": "更多"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": ""
					  },
					  "itemIcon": {
						"src": moreIcon,
						"isLocalSrc":true,
						"width": 24,
						"height": 24
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					}
				  ]
				},
				{
				  "sectionTitle": null,
				  "sectionEnabled": null,
				  "sectionIcon": null,
				  "key": "Mark Six Menu options",
				  "itemName": null,
				  "itemRedirection": null,
				  "itemLink": null,
				  "itemPage": null,
				  "itemIcon": null,
				  "jpFlag": null,
				  "altTxt": null,
				  "itemEnable": null,
				  "redirectMethod": null,
				  "pH": null,
				  "pW": null,
				  "pX": null,
				  "pY": null,
				  "specialtag": null,
				  "predefinedColor": null,
				  "frontendID": null,
				  "startDatetime": null,
				  "noOfDays": null,
				  "frontEndIds": null,
				  "sNoOfDays": null,
				  "moHead": null,
				  "moText": null,
				  "moImage": null,
				  "iconM": null,
				  "pCardEnable": null,
				  "GlassboxCustomEventName": null,
				  "GlassboxCustomEventValue": null,
				  "GlassboxTaggingEnabled": null,
				  "children": [
					{
					  "key": "Int_Mark Six",
					  "itemName": {
						"value": "六合彩"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "/ch/marksix"
					  },
					  "itemIcon": {
						"src": homeIcon,
						"isLocalSrc":true,
						"width": null,
						"height": null
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_Self Select Single",
					  "itemName": {
						"value": "自選單式"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "/ch/marksix/Single"
					  },
					  "itemIcon": {
						"src": self_select_single,
						"isLocalSrc":true,
						"width": null,
						"height": null
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_Result",
					  "itemName": {
						"value": "攪珠結果"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "/ch/marksix/results"
					  },
					  "itemIcon": {
						"src": result_MarkSix,
						"isLocalSrc":true,
						"width": null,
						"height": null
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_Betslip",
					  "itemName": {
						"value": "投注區"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": ""
					  },
					  "itemIcon": {
						"src": betslipIcon,
						"isLocalSrc":true,
						"width": 24,
						"height": 24
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_More",
					  "itemName": {
						"value": "更多"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": ""
					  },
					  "itemIcon": {
						"src": moreIcon,
						"isLocalSrc":true,
						"width": 24,
						"height": 24
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_Racing",
					  "itemName": {
						"value": "賽馬"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "/ch/racing/home"
					  },
					  "itemIcon": {
						"src": horse,
						"isLocalSrc":true,
						"width": 24,
						"height": 24
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": ""
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_Football",
					  "itemName": {
						"value": "足球"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "/ch/football/home"
					  },
					  "itemIcon": {
						"src": Football,
						"isLocalSrc":true,
						"width": 24,
						"height": 24
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": ""
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					}
				  ]
				},
				{
				  "sectionTitle": null,
				  "sectionEnabled": null,
				  "sectionIcon": null,
				  "key": "eWin Menu options",
				  "itemName": null,
				  "itemRedirection": null,
				  "itemLink": null,
				  "itemPage": null,
				  "itemIcon": null,
				  "jpFlag": null,
				  "altTxt": null,
				  "itemEnable": null,
				  "redirectMethod": null,
				  "pH": null,
				  "pW": null,
				  "pX": null,
				  "pY": null,
				  "specialtag": null,
				  "predefinedColor": null,
				  "frontendID": null,
				  "startDatetime": null,
				  "noOfDays": null,
				  "frontEndIds": null,
				  "sNoOfDays": null,
				  "moHead": null,
				  "moText": null,
				  "moImage": null,
				  "iconM": null,
				  "pCardEnable": null,
				  "GlassboxCustomEventName": null,
				  "GlassboxCustomEventValue": null,
				  "GlassboxTaggingEnabled": null,
				  "children": [
					{
					  "key": "Int_Racing",
					  "itemName": {
						"value": "賽馬"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "/ch/racing"
					  },
					  "itemIcon": {
						"src": horse,
						"isLocalSrc":true,
						"width": null,
						"height": null
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_Football",
					  "itemName": {
						"value": "足球"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "/ch/football/home"
					  },
					  "itemIcon": {
						"src": Football,
						"isLocalSrc":true,
						"width": 24,
						"height": 24
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_Mark Six",
					  "itemName": {
						"value": "六合彩"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": "/ch/marksix"
					  },
					  "itemIcon": {
						"src": MarkSix,
						"isLocalSrc":true,
						"width": null,
						"height": null
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_Betslip",
					  "itemName": {
						"value": "投注區"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": ""
					  },
					  "itemIcon": {
						"src": betslipIcon,
						"isLocalSrc":true,
						"width": 24,
						"height": 24
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					},
					{
					  "key": "Int_More",
					  "itemName": {
						"value": "更多"
					  },
					  "itemRedirection": {
						"value": "JCBW page"
					  },
					  "itemLink": {
						"value": ""
					  },
					  "itemPage": {
						"value": ""
					  },
					  "itemIcon": {
						"src": moreIcon,
						"isLocalSrc":true,
						"width": 24,
						"height": 24
					  },
					  "jpFlag": null,
					  "altTxt": null,
					  "itemEnable": {
						"value": "1"
					  },
					  "redirectMethod": {
						"value": "Popup"
					  },
					  "pH": {
						"value": "600"
					  },
					  "pW": {
						"value": "900"
					  },
					  "pX": {
						"value": "20"
					  },
					  "pY": {
						"value": "20"
					  },
					  "specialtag": null,
					  "predefinedColor": null,
					  "frontendID": null,
					  "startDatetime": null,
					  "noOfDays": null,
					  "frontEndIds": null,
					  "sNoOfDays": null,
					  "moHead": null,
					  "moText": null,
					  "moImage": null,
					  "iconM": null,
					  "pCardEnable": null,
					  "GlassboxCustomEventName": null,
					  "GlassboxCustomEventValue": null,
					  "GlassboxTaggingEnabled": null
					}
				  ]
				}
			  ]
		}
	}
}


export const headerMenuDataCh = {
	"data": {
		"item": {
			"children": [{
				"sectionTitle": null,
				"sectionEnabled": null,
				"sectionIcon": null,
				"key": "Int_Online Channels",
				"itemName": {
					"value": "網上渠道"
				},
				"itemRedirection": {
					"value": ""
				},
				"itemLink": {
					"value": ""
				},
				"itemPage": {
					"value": ""
				},
				"itemIcon": {
					"src": null,
					"width": null,
					"height": null
				},
				"jpFlag": null,
				"altTxt": null,
				"itemEnable": {
					"value": "1"
				},
				"redirectMethod": {
					"value": "Popup"
				},
				"pH": {
					"value": "600"
				},
				"pW": {
					"value": "900"
				},
				"pX": {
					"value": "20"
				},
				"pY": {
					"value": "20"
				},
				"specialtag": null,
				"frontendID": null,
				"startDatetime": null,
				"noOfDays": null,
				"frontEndIds": null,
				"sNoOfDays": null,
				"moHead": null,
				"moText": null,
				"moImage": null,
				"iconM": {
					"src": null,
					"width": null,
					"height": null
				},
				"pCardEnable": {
					"value": ""
				},
				"GlassboxCustomEventName": null,
				"GlassboxCustomEventValue": null,
				"GlassboxTaggingEnabled": null,
				"children": [{
					"key": "Int_Smart Platform",
					"itemName": {
						"value": "新 • 平台"
					},
					"itemRedirection": {
						"value": "Other link"
					},
					"itemLink": {
						"value": "//m.hkjc.com/tc/index.html"
					},
					"itemPage": {
						"value": ""
					},
					"itemIcon": {
						"src": null,
						"width": null,
						"height": null
					},
					"jpFlag": null,
					"altTxt": null,
					"itemEnable": {
						"value": "1"
					},
					"redirectMethod": {
						"value": "Popup"
					},
					"pH": {
						"value": "600"
					},
					"pW": {
						"value": "900"
					},
					"pX": {
						"value": "20"
					},
					"pY": {
						"value": "20"
					},
					"specialtag": null,
					"frontendID": null,
					"startDatetime": null,
					"noOfDays": null,
					"frontEndIds": null,
					"sNoOfDays": null,
					"moHead": {
						"value": ""
					},
					"moText": {
						"value": ""
					},
					"moImage": {
						"src": null,
						"width": null,
						"height": null
					},
					"iconM": null,
					"pCardEnable": null,
					"GlassboxCustomEventName": null,
					"GlassboxCustomEventValue": null,
					"GlassboxTaggingEnabled": null
				}, {
					"key": "Int_Apply for Betting Account",
					"itemName": {
						"value": "申請投注戶口"
					},
					"itemRedirection": {
						"value": "Other link"
					},
					"itemLink": {
						"value": "/zh-HK/online-channels/apply-betting-account/"
					},
					"itemPage": {
						"value": ""
					},
					"itemIcon": {
						"src": null,
						"width": null,
						"height": null
					},
					"jpFlag": null,
					"altTxt": null,
					"itemEnable": {
						"value": "1"
					},
					"redirectMethod": {
						"value": "New tab in original browser"
					},
					"pH": {
						"value": "600"
					},
					"pW": {
						"value": "900"
					},
					"pX": {
						"value": "20"
					},
					"pY": {
						"value": "20"
					},
					"specialtag": null,
					"frontendID": null,
					"startDatetime": null,
					"noOfDays": null,
					"frontEndIds": null,
					"sNoOfDays": null,
					"moHead": {
						"value": ""
					},
					"moText": {
						"value": ""
					},
					"moImage": {
						"src": null,
						"width": null,
						"height": null
					},
					"iconM": null,
					"pCardEnable": null,
					"GlassboxCustomEventName": null,
					"GlassboxCustomEventValue": null,
					"GlassboxTaggingEnabled": null
				}, {
					"key": "Int_Funds Transfer",
					"itemName": {
						"value": "關於轉賬"
					},
					"itemRedirection": {
						"value": "Other link"
					},
					"itemLink": {
						"value": "/zh-HK/online-channels/fund-transfer/deposit/"
					},
					"itemPage": {
						"value": ""
					},
					"itemIcon": {
						"src": null,
						"width": null,
						"height": null
					},
					"jpFlag": null,
					"altTxt": null,
					"itemEnable": {
						"value": "1"
					},
					"redirectMethod": {
						"value": "New tab in original browser"
					},
					"pH": {
						"value": "600"
					},
					"pW": {
						"value": "900"
					},
					"pX": {
						"value": "20"
					},
					"pY": {
						"value": "20"
					},
					"specialtag": null,
					"frontendID": null,
					"startDatetime": null,
					"noOfDays": null,
					"frontEndIds": null,
					"sNoOfDays": null,
					"moHead": {
						"value": ""
					},
					"moText": {
						"value": ""
					},
					"moImage": {
						"src": null,
						"width": null,
						"height": null
					},
					"iconM": null,
					"pCardEnable": null,
					"GlassboxCustomEventName": null,
					"GlassboxCustomEventValue": null,
					"GlassboxTaggingEnabled": null
				}]
			}, {
				"sectionTitle": null,
				"sectionEnabled": null,
				"sectionIcon": null,
				"key": "Int_Retail And Telebet",
				"itemName": {
					"value": "投注處及電話投注"
				},
				"itemRedirection": {
					"value": ""
				},
				"itemLink": {
					"value": ""
				},
				"itemPage": {
					"value": ""
				},
				"itemIcon": {
					"src": null,
					"width": null,
					"height": null
				},
				"jpFlag": null,
				"altTxt": null,
				"itemEnable": {
					"value": "1"
				},
				"redirectMethod": {
					"value": "Popup"
				},
				"pH": {
					"value": "600"
				},
				"pW": {
					"value": "900"
				},
				"pX": {
					"value": "20"
				},
				"pY": {
					"value": "20"
				},
				"specialtag": null,
				"frontendID": null,
				"startDatetime": null,
				"noOfDays": null,
				"frontEndIds": null,
				"sNoOfDays": null,
				"moHead": null,
				"moText": null,
				"moImage": null,
				"iconM": {
					"src": null,
					"width": null,
					"height": null
				},
				"pCardEnable": {
					"value": ""
				},
				"GlassboxCustomEventName": null,
				"GlassboxCustomEventValue": null,
				"GlassboxTaggingEnabled": null,
				"children": [{
					"key": "Int_OffCourse",
					"itemName": {
						"value": "場外投注處"
					},
					"itemRedirection": {
						"value": "Other link"
					},
					"itemLink": {
						"value": "/zh-HK/retail-telebet/ocbb/"
					},
					"itemPage": {
						"value": ""
					},
					"itemIcon": {
						"src": null,
						"width": null,
						"height": null
					},
					"jpFlag": null,
					"altTxt": null,
					"itemEnable": {
						"value": "1"
					},
					"redirectMethod": {
						"value": "Popup"
					},
					"pH": {
						"value": "600"
					},
					"pW": {
						"value": "900"
					},
					"pX": {
						"value": "20"
					},
					"pY": {
						"value": "20"
					},
					"specialtag": null,
					"frontendID": null,
					"startDatetime": null,
					"noOfDays": null,
					"frontEndIds": null,
					"sNoOfDays": null,
					"moHead": {
						"value": "場外投注處"
					},
					"moText": {
						"value": "歡迎蒞臨嶄新設計的投注處，設施完善，包括面積寬敞的投注大堂、馬迷加油站及足球地帶，提供豐富賽馬及足球博彩資訊。"
					},
					"moImage": {
						"src": "/-/media/Sites/JCBW/pic_ocb.gif?rev=e9badbf14f14424095db3d31a2f277d8",
						"width": 90,
						"height": 90
					},
					"iconM": null,
					"pCardEnable": null,
					"GlassboxCustomEventName": null,
					"GlassboxCustomEventValue": null,
					"GlassboxTaggingEnabled": null
				}, {
					"key": "Int_Telebet",
					"itemName": {
						"value": "電話投注"
					},
					"itemRedirection": {
						"value": "Other link"
					},
					"itemLink": {
						"value": "/zh-HK/retail-telebet/telephone-betting/"
					},
					"itemPage": {
						"value": ""
					},
					"itemIcon": {
						"src": null,
						"width": null,
						"height": null
					},
					"jpFlag": null,
					"altTxt": null,
					"itemEnable": {
						"value": "1"
					},
					"redirectMethod": {
						"value": "Popup"
					},
					"pH": {
						"value": "600"
					},
					"pW": {
						"value": "900"
					},
					"pX": {
						"value": "20"
					},
					"pY": {
						"value": "20"
					},
					"specialtag": null,
					"frontendID": null,
					"startDatetime": null,
					"noOfDays": null,
					"frontEndIds": null,
					"sNoOfDays": null,
					"moHead": {
						"value": "電話投注"
					},
					"moText": {
						"value": "為你提供貼身服務！一個簡單指示，不論投注、轉賬或查詢，專業親切的服務員會為你效勞。讓你投注更安心！"
					},
					"moImage": {
						"src": "/-/media/Sites/JCBW/pic_telebet.gif?rev=209684a2d6944e4d8319ad0f88838569",
						"width": 90,
						"height": 90
					},
					"iconM": null,
					"pCardEnable": null,
					"GlassboxCustomEventName": null,
					"GlassboxCustomEventValue": null,
					"GlassboxTaggingEnabled": null
				}, {
					"key": "Int_Racecourse",
					"itemName": {
						"value": "馬場"
					},
					"itemRedirection": {
						"value": "Other link"
					},
					"itemLink": {
						"value": "/zh-HK/retail-telebet/racecourse/"
					},
					"itemPage": {
						"value": ""
					},
					"itemIcon": {
						"src": null,
						"width": null,
						"height": null
					},
					"jpFlag": null,
					"altTxt": null,
					"itemEnable": {
						"value": "1"
					},
					"redirectMethod": {
						"value": "Popup"
					},
					"pH": {
						"value": "600"
					},
					"pW": {
						"value": "900"
					},
					"pX": {
						"value": "20"
					},
					"pY": {
						"value": "20"
					},
					"specialtag": null,
					"frontendID": null,
					"startDatetime": null,
					"noOfDays": null,
					"frontEndIds": null,
					"sNoOfDays": null,
					"moHead": {
						"value": "馬場"
					},
					"moText": {
						"value": "沙田馬場擁有具世界最先進的賽馬設施，跑馬地馬場坐擁市區霓虹景致，歡迎你蒞臨馬場，一同體驗賽馬的歡樂刺激！"
					},
					"moImage": {
						"src": "/-/media/Sites/JCBW/pic_racecourse.gif?rev=fdf0b57ef7d440f18f7945c18c073708",
						"width": 90,
						"height": 90
					},
					"iconM": null,
					"pCardEnable": null,
					"GlassboxCustomEventName": null,
					"GlassboxCustomEventValue": null,
					"GlassboxTaggingEnabled": null
				}]
			}, {
				"sectionTitle": null,
				"sectionEnabled": null,
				"sectionIcon": null,
				"key": "Int_Betting Info",
				"itemName": {
					"value": "投注資料"
				},
				"itemRedirection": {
					"value": ""
				},
				"itemLink": {
					"value": ""
				},
				"itemPage": {
					"value": ""
				},
				"itemIcon": {
					"src": null,
					"width": null,
					"height": null
				},
				"jpFlag": null,
				"altTxt": null,
				"itemEnable": {
					"value": "1"
				},
				"redirectMethod": {
					"value": "Popup"
				},
				"pH": {
					"value": "600"
				},
				"pW": {
					"value": "900"
				},
				"pX": {
					"value": "20"
				},
				"pY": {
					"value": "20"
				},
				"specialtag": null,
				"frontendID": null,
				"startDatetime": null,
				"noOfDays": null,
				"frontEndIds": null,
				"sNoOfDays": null,
				"moHead": null,
				"moText": null,
				"moImage": null,
				"iconM": {
					"src": null,
					"width": null,
					"height": null
				},
				"pCardEnable": {
					"value": ""
				},
				"GlassboxCustomEventName": null,
				"GlassboxCustomEventValue": null,
				"GlassboxTaggingEnabled": null,
				"children": [{
					"key": "Int_Horse Racing",
					"itemName": {
						"value": "賽馬"
					},
					"itemRedirection": {
						"value": "Other link"
					},
					"itemLink": {
						"value": "https://member2.iuww.com/zh-HK/betting-info/racing/local-pools/"
					},
					"itemPage": {
						"value": ""
					},
					"itemIcon": {
						"src": null,
						"width": null,
						"height": null
					},
					"jpFlag": null,
					"altTxt": null,
					"itemEnable": {
						"value": "1"
					},
					"redirectMethod": {
						"value": "New tab in original browser"
					},
					"pH": {
						"value": ""
					},
					"pW": {
						"value": ""
					},
					"pX": {
						"value": ""
					},
					"pY": {
						"value": ""
					},
					"specialtag": null,
					"frontendID": null,
					"startDatetime": null,
					"noOfDays": null,
					"frontEndIds": null,
					"sNoOfDays": null,
					"moHead": {
						"value": ""
					},
					"moText": {
						"value": ""
					},
					"moImage": {
						"src": null,
						"width": null,
						"height": null
					},
					"iconM": null,
					"pCardEnable": null,
					"GlassboxCustomEventName": null,
					"GlassboxCustomEventValue": null,
					"GlassboxTaggingEnabled": null
				}, {
					"key": "Int_HKJC Football",
					"itemName": {
						"value": "足智彩"
					},
					"itemRedirection": {
						"value": "Other link"
					},
					"itemLink": {
						"value": "https://member2.iuww.com/zh-HK/betting-info/football/had/"
					},
					"itemPage": {
						"value": ""
					},
					"itemIcon": {
						"src": null,
						"width": null,
						"height": null
					},
					"jpFlag": null,
					"altTxt": null,
					"itemEnable": {
						"value": "1"
					},
					"redirectMethod": {
						"value": "New tab in original browser"
					},
					"pH": {
						"value": ""
					},
					"pW": {
						"value": ""
					},
					"pX": {
						"value": ""
					},
					"pY": {
						"value": ""
					},
					"specialtag": null,
					"frontendID": null,
					"startDatetime": null,
					"noOfDays": null,
					"frontEndIds": null,
					"sNoOfDays": null,
					"moHead": {
						"value": ""
					},
					"moText": {
						"value": ""
					},
					"moImage": {
						"src": null,
						"width": null,
						"height": null
					},
					"iconM": null,
					"pCardEnable": null,
					"GlassboxCustomEventName": null,
					"GlassboxCustomEventValue": null,
					"GlassboxTaggingEnabled": null
				}, {
					"key": "Int_Mark Six",
					"itemName": {
						"value": "六合彩"
					},
					"itemRedirection": {
						"value": "Other link"
					},
					"itemLink": {
						"value": "https://member2.iuww.com/special/zh-HK/betting-info/marksix/lottery/"
					},
					"itemPage": {
						"value": ""
					},
					"itemIcon": {
						"src": MarkSix,
						"isLocalSrc":true,
						"width": null,
						"height": null
					},
					"jpFlag": null,
					"altTxt": null,
					"itemEnable": {
						"value": "1"
					},
					"redirectMethod": {
						"value": "New tab in original browser"
					},
					"pH": {
						"value": ""
					},
					"pW": {
						"value": ""
					},
					"pX": {
						"value": ""
					},
					"pY": {
						"value": ""
					},
					"specialtag": null,
					"frontendID": null,
					"startDatetime": null,
					"noOfDays": null,
					"frontEndIds": null,
					"sNoOfDays": null,
					"moHead": {
						"value": ""
					},
					"moText": {
						"value": ""
					},
					"moImage": {
						"src": null,
						"width": null,
						"height": null
					},
					"iconM": null,
					"pCardEnable": null,
					"GlassboxCustomEventName": null,
					"GlassboxCustomEventValue": null,
					"GlassboxTaggingEnabled": null
				}]
			}, {
				"sectionTitle": null,
				"sectionEnabled": null,
				"sectionIcon": null,
				"key": "Int_Customer eCentre",
				"itemName": {
					"value": "客戶支援"
				},
				"itemRedirection": {
					"value": ""
				},
				"itemLink": {
					"value": ""
				},
				"itemPage": {
					"value": ""
				},
				"itemIcon": {
					"src": null,
					"width": null,
					"height": null
				},
				"jpFlag": null,
				"altTxt": null,
				"itemEnable": {
					"value": "1"
				},
				"redirectMethod": {
					"value": "Popup"
				},
				"pH": {
					"value": "600"
				},
				"pW": {
					"value": "900"
				},
				"pX": {
					"value": "20"
				},
				"pY": {
					"value": "20"
				},
				"specialtag": null,
				"frontendID": null,
				"startDatetime": null,
				"noOfDays": null,
				"frontEndIds": null,
				"sNoOfDays": null,
				"moHead": null,
				"moText": null,
				"moImage": null,
				"iconM": {
					"src": null,
					"width": null,
					"height": null
				},
				"pCardEnable": {
					"value": ""
				},
				"GlassboxCustomEventName": null,
				"GlassboxCustomEventValue": null,
				"GlassboxTaggingEnabled": null,
				"children": [{
					"key": "Int_Customer Service Chatbot",
					"itemName": {
						"value": "顧客服務智能助理"
					},
					"itemRedirection": {
						"value": "Other link"
					},
					"itemLink": {
						"value": "//ecbp.hkjc.com/?sys=d1kE&ch=6LvE&lang=tc"
					},
					"itemPage": {
						"value": ""
					},
					"itemIcon": {
						"src": "/-/media/Sites/JCBW/MenuIcons/new.gif?rev=0fd8875f391746ec9e85c70dc62a7381",
						"width": 11,
						"height": 11
					},
					"jpFlag": null,
					"altTxt": null,
					"itemEnable": {
						"value": "1"
					},
					"redirectMethod": {
						"value": "New tab in original browser"
					},
					"pH": {
						"value": ""
					},
					"pW": {
						"value": ""
					},
					"pX": {
						"value": ""
					},
					"pY": {
						"value": ""
					},
					"specialtag": null,
					"frontendID": null,
					"startDatetime": null,
					"noOfDays": null,
					"frontEndIds": null,
					"sNoOfDays": null,
					"moHead": {
						"value": ""
					},
					"moText": {
						"value": ""
					},
					"moImage": {
						"src": null,
						"width": null,
						"height": null
					},
					"iconM": null,
					"pCardEnable": null,
					"GlassboxCustomEventName": null,
					"GlassboxCustomEventValue": null,
					"GlassboxTaggingEnabled": null
				}, {
					"key": "Int_Customer eCentre",
					"itemName": {
						"value": "網上客戶中心"
					},
					"itemRedirection": {
						"value": "Other link"
					},
					"itemLink": {
						"value": "//wcip02.hkjc.com/loginformCSSP.aspx?language=zh-hk&link=%23%2fhome"
					},
					"itemPage": {
						"value": ""
					},
					"itemIcon": {
						"src": null,
						"width": null,
						"height": null
					},
					"jpFlag": null,
					"altTxt": null,
					"itemEnable": {
						"value": "1"
					},
					"redirectMethod": {
						"value": "New tab in original browser"
					},
					"pH": {
						"value": ""
					},
					"pW": {
						"value": ""
					},
					"pX": {
						"value": ""
					},
					"pY": {
						"value": ""
					},
					"specialtag": null,
					"frontendID": null,
					"startDatetime": null,
					"noOfDays": null,
					"frontEndIds": null,
					"sNoOfDays": null,
					"moHead": {
						"value": ""
					},
					"moText": {
						"value": ""
					},
					"moImage": {
						"src": null,
						"width": null,
						"height": null
					},
					"iconM": null,
					"pCardEnable": null,
					"GlassboxCustomEventName": null,
					"GlassboxCustomEventValue": null,
					"GlassboxTaggingEnabled": null
				}, {
					"key": "Int_Download Forms",
					"itemName": {
						"value": "下載表格"
					},
					"itemRedirection": {
						"value": "Other link"
					},
					"itemLink": {
						"value": "/zh-HK/online-channels/downloads/"
					},
					"itemPage": {
						"value": ""
					},
					"itemIcon": {
						"src": null,
						"width": null,
						"height": null
					},
					"jpFlag": null,
					"altTxt": null,
					"itemEnable": {
						"value": "1"
					},
					"redirectMethod": {
						"value": "New tab in original browser"
					},
					"pH": {
						"value": "600"
					},
					"pW": {
						"value": "900"
					},
					"pX": {
						"value": "20"
					},
					"pY": {
						"value": "20"
					},
					"specialtag": null,
					"frontendID": null,
					"startDatetime": null,
					"noOfDays": null,
					"frontEndIds": null,
					"sNoOfDays": null,
					"moHead": {
						"value": ""
					},
					"moText": {
						"value": ""
					},
					"moImage": {
						"src": null,
						"width": null,
						"height": null
					},
					"iconM": null,
					"pCardEnable": null,
					"GlassboxCustomEventName": null,
					"GlassboxCustomEventValue": null,
					"GlassboxTaggingEnabled": null
				}, {
					"key": "Int_Other Contact Channels",
					"itemName": {
						"value": "其他聯絡途徑"
					},
					"itemRedirection": {
						"value": "Other link"
					},
					"itemLink": {
						"value": "/zh-HK/online-channels/hotlines/"
					},
					"itemPage": {
						"value": ""
					},
					"itemIcon": {
						"src": null,
						"width": null,
						"height": null
					},
					"jpFlag": null,
					"altTxt": null,
					"itemEnable": {
						"value": "1"
					},
					"redirectMethod": {
						"value": "New tab in original browser"
					},
					"pH": {
						"value": ""
					},
					"pW": {
						"value": ""
					},
					"pX": {
						"value": ""
					},
					"pY": {
						"value": ""
					},
					"specialtag": null,
					"frontendID": null,
					"startDatetime": null,
					"noOfDays": null,
					"frontEndIds": null,
					"sNoOfDays": null,
					"moHead": {
						"value": ""
					},
					"moText": {
						"value": ""
					},
					"moImage": {
						"src": null,
						"width": null,
						"height": null
					},
					"iconM": null,
					"pCardEnable": null,
					"GlassboxCustomEventName": null,
					"GlassboxCustomEventValue": null,
					"GlassboxTaggingEnabled": null
				}]
			}, {
				"sectionTitle": null,
				"sectionEnabled": null,
				"sectionIcon": null,
				"key": "Int_Priority",
				"itemName": {
					"value": "「優越」客戶"
				},
				"itemRedirection": {
					"value": ""
				},
				"itemLink": {
					"value": ""
				},
				"itemPage": {
					"value": ""
				},
				"itemIcon": {
					"src": null,
					"width": null,
					"height": null
				},
				"jpFlag": null,
				"altTxt": null,
				"itemEnable": {
					"value": "1"
				},
				"redirectMethod": {
					"value": "Popup"
				},
				"pH": {
					"value": "600"
				},
				"pW": {
					"value": "900"
				},
				"pX": {
					"value": "20"
				},
				"pY": {
					"value": "20"
				},
				"specialtag": null,
				"frontendID": null,
				"startDatetime": null,
				"noOfDays": null,
				"frontEndIds": null,
				"sNoOfDays": null,
				"moHead": null,
				"moText": null,
				"moImage": null,
				"iconM": {
					"src": null,
					"width": null,
					"height": null
				},
				"pCardEnable": {
					"value": "1"
				},
				"GlassboxCustomEventName": null,
				"GlassboxCustomEventValue": null,
				"GlassboxTaggingEnabled": null,
				"children": [{
					"key": "Int_PriorityPoint",
					"itemName": {
						"value": "查閱「優越」積分"
					},
					"itemRedirection": {
						"value": "Other link"
					},
					"itemLink": {
						"value": "https://www.priority.hkjc.com"
					},
					"itemPage": {
						"value": ""
					},
					"itemIcon": {
						"src": null,
						"width": null,
						"height": null
					},
					"jpFlag": null,
					"altTxt": null,
					"itemEnable": {
						"value": "1"
					},
					"redirectMethod": {
						"value": "Popup"
					},
					"pH": {
						"value": "600"
					},
					"pW": {
						"value": "900"
					},
					"pX": {
						"value": "20"
					},
					"pY": {
						"value": "20"
					},
					"specialtag": null,
					"frontendID": null,
					"startDatetime": null,
					"noOfDays": null,
					"frontEndIds": null,
					"sNoOfDays": null,
					"moHead": {
						"value": ""
					},
					"moText": {
						"value": ""
					},
					"moImage": {
						"src": null,
						"width": null,
						"height": null
					},
					"iconM": null,
					"pCardEnable": null,
					"GlassboxCustomEventName": null,
					"GlassboxCustomEventValue": null,
					"GlassboxTaggingEnabled": null
				}]
			}]
		}
	}
}


export const headerMenuDataEn = {
	"data": {
		"item": {
			"children": [{
				"sectionTitle": null,
				"sectionEnabled": null,
				"sectionIcon": null,
				"key": "Int_Online Channels",
				"itemName": {
					"value": "Online Channels- Test"
				},
				"itemRedirection": {
					"value": ""
				},
				"itemLink": {
					"value": ""
				},
				"itemPage": {
					"value": ""
				},
				"itemIcon": {
					"src": null,
					"width": null,
					"height": null
				},
				"jpFlag": null,
				"altTxt": null,
				"itemEnable": {
					"value": "1"
				},
				"redirectMethod": {
					"value": "Popup"
				},
				"pH": {
					"value": "600"
				},
				"pW": {
					"value": "900"
				},
				"pX": {
					"value": "20"
				},
				"pY": {
					"value": "20"
				},
				"specialtag": null,
				"frontendID": null,
				"startDatetime": null,
				"noOfDays": null,
				"frontEndIds": null,
				"sNoOfDays": null,
				"moHead": null,
				"moText": null,
				"moImage": null,
				"iconM": {
					"src": null,
					"width": null,
					"height": null
				},
				"pCardEnable": {
					"value": ""
				},
				"GlassboxCustomEventName": null,
				"GlassboxCustomEventValue": null,
				"GlassboxTaggingEnabled": null,
				"children": [{
					"key": "Int_Smart Platform",
					"itemName": {
						"value": "Smart Platform-Test"
					},
					"itemRedirection": {
						"value": "Other link"
					},
					"itemLink": {
						"value": "//m.hkjc.com/en/index.html"
					},
					"itemPage": {
						"value": ""
					},
					"itemIcon": {
						"src": null,
						"width": null,
						"height": null
					},
					"jpFlag": null,
					"altTxt": null,
					"itemEnable": {
						"value": "1"
					},
					"redirectMethod": {
						"value": "Popup"
					},
					"pH": {
						"value": "600"
					},
					"pW": {
						"value": "900"
					},
					"pX": {
						"value": "20"
					},
					"pY": {
						"value": "20"
					},
					"specialtag": null,
					"frontendID": null,
					"startDatetime": null,
					"noOfDays": null,
					"frontEndIds": null,
					"sNoOfDays": null,
					"moHead": {
						"value": ""
					},
					"moText": {
						"value": ""
					},
					"moImage": {
						"src": null,
						"width": null,
						"height": null
					},
					"iconM": null,
					"pCardEnable": null,
					"GlassboxCustomEventName": null,
					"GlassboxCustomEventValue": null,
					"GlassboxTaggingEnabled": null
				}, {
					"key": "Int_Apply for Betting Account",
					"itemName": {
						"value": "Apply for Betting Account"
					},
					"itemRedirection": {
						"value": "Other link"
					},
					"itemLink": {
						"value": "/en-US/online-channels/apply-betting-account/"
					},
					"itemPage": {
						"value": ""
					},
					"itemIcon": {
						"src": null,
						"width": null,
						"height": null
					},
					"jpFlag": null,
					"altTxt": null,
					"itemEnable": {
						"value": "1"
					},
					"redirectMethod": {
						"value": "New tab in original browser"
					},
					"pH": {
						"value": "600"
					},
					"pW": {
						"value": "900"
					},
					"pX": {
						"value": "20"
					},
					"pY": {
						"value": "20"
					},
					"specialtag": null,
					"frontendID": null,
					"startDatetime": null,
					"noOfDays": null,
					"frontEndIds": null,
					"sNoOfDays": null,
					"moHead": {
						"value": ""
					},
					"moText": {
						"value": ""
					},
					"moImage": {
						"src": null,
						"width": null,
						"height": null
					},
					"iconM": null,
					"pCardEnable": null,
					"GlassboxCustomEventName": null,
					"GlassboxCustomEventValue": null,
					"GlassboxTaggingEnabled": null
				}, {
					"key": "Int_Funds Transfer",
					"itemName": {
						"value": "Funds Transfer"
					},
					"itemRedirection": {
						"value": "Other link"
					},
					"itemLink": {
						"value": "/en-US/online-channels/fund-transfer/deposit/"
					},
					"itemPage": {
						"value": ""
					},
					"itemIcon": {
						"src": null,
						"width": null,
						"height": null
					},
					"jpFlag": null,
					"altTxt": null,
					"itemEnable": {
						"value": "1"
					},
					"redirectMethod": {
						"value": "New tab in original browser"
					},
					"pH": {
						"value": "600"
					},
					"pW": {
						"value": "900"
					},
					"pX": {
						"value": "20"
					},
					"pY": {
						"value": "20"
					},
					"specialtag": null,
					"frontendID": null,
					"startDatetime": null,
					"noOfDays": null,
					"frontEndIds": null,
					"sNoOfDays": null,
					"moHead": {
						"value": ""
					},
					"moText": {
						"value": ""
					},
					"moImage": {
						"src": null,
						"width": null,
						"height": null
					},
					"iconM": null,
					"pCardEnable": null,
					"GlassboxCustomEventName": null,
					"GlassboxCustomEventValue": null,
					"GlassboxTaggingEnabled": null
				}]
			}, {
				"sectionTitle": null,
				"sectionEnabled": null,
				"sectionIcon": null,
				"key": "Int_Retail And Telebet",
				"itemName": {
					"value": "Retail & Telebet"
				},
				"itemRedirection": {
					"value": ""
				},
				"itemLink": {
					"value": ""
				},
				"itemPage": {
					"value": ""
				},
				"itemIcon": {
					"src": null,
					"width": null,
					"height": null
				},
				"jpFlag": null,
				"altTxt": null,
				"itemEnable": {
					"value": "1"
				},
				"redirectMethod": {
					"value": "Popup"
				},
				"pH": {
					"value": "600"
				},
				"pW": {
					"value": "900"
				},
				"pX": {
					"value": "20"
				},
				"pY": {
					"value": "20"
				},
				"specialtag": null,
				"frontendID": null,
				"startDatetime": null,
				"noOfDays": null,
				"frontEndIds": null,
				"sNoOfDays": null,
				"moHead": null,
				"moText": null,
				"moImage": null,
				"iconM": {
					"src": null,
					"width": null,
					"height": null
				},
				"pCardEnable": {
					"value": ""
				},
				"GlassboxCustomEventName": null,
				"GlassboxCustomEventValue": null,
				"GlassboxTaggingEnabled": null,
				"children": [{
					"key": "Int_OffCourse",
					"itemName": {
						"value": "Off-course Betting Branches"
					},
					"itemRedirection": {
						"value": "Other link"
					},
					"itemLink": {
						"value": "/en-US/retail-telebet/ocbb/"
					},
					"itemPage": {
						"value": ""
					},
					"itemIcon": {
						"src": null,
						"width": null,
						"height": null
					},
					"jpFlag": null,
					"altTxt": null,
					"itemEnable": {
						"value": "1"
					},
					"redirectMethod": {
						"value": "Popup"
					},
					"pH": {
						"value": "600"
					},
					"pW": {
						"value": "900"
					},
					"pX": {
						"value": "20"
					},
					"pY": {
						"value": "20"
					},
					"specialtag": null,
					"frontendID": null,
					"startDatetime": null,
					"noOfDays": null,
					"frontEndIds": null,
					"sNoOfDays": null,
					"moHead": {
						"value": "Off-course Betting Branches"
					},
					"moText": {
						"value": "Spacious and modern, Off-course Betting Branches offer state-of-the-art facilities and specialized corners for Horse Racing and Football Betting."
					},
					"moImage": {
						"src": "/-/media/Sites/JCBW/pic_ocb.gif?rev=c8b7bc31f195486c8118b29409da4c71",
						"width": 90,
						"height": 90
					},
					"iconM": null,
					"pCardEnable": null,
					"GlassboxCustomEventName": null,
					"GlassboxCustomEventValue": null,
					"GlassboxTaggingEnabled": null
				}, {
					"key": "Int_Telebet",
					"itemName": {
						"value": "Telebet"
					},
					"itemRedirection": {
						"value": "Other link"
					},
					"itemLink": {
						"value": "/en-US/retail-telebet/telephone-betting/"
					},
					"itemPage": {
						"value": ""
					},
					"itemIcon": {
						"src": null,
						"width": null,
						"height": null
					},
					"jpFlag": null,
					"altTxt": null,
					"itemEnable": {
						"value": "1"
					},
					"redirectMethod": {
						"value": "Popup"
					},
					"pH": {
						"value": "600"
					},
					"pW": {
						"value": "900"
					},
					"pX": {
						"value": "20"
					},
					"pY": {
						"value": "20"
					},
					"specialtag": null,
					"frontendID": null,
					"startDatetime": null,
					"noOfDays": null,
					"frontEndIds": null,
					"sNoOfDays": null,
					"moHead": {
						"value": "Telebet"
					},
					"moText": {
						"value": "Our professional service ambassadors help you to bet, transfer funds and make enquiries with confidence!"
					},
					"moImage": {
						"src": "/-/media/Sites/JCBW/pic_telebet.gif?rev=209684a2d6944e4d8319ad0f88838569",
						"width": 90,
						"height": 90
					},
					"iconM": null,
					"pCardEnable": null,
					"GlassboxCustomEventName": null,
					"GlassboxCustomEventValue": null,
					"GlassboxTaggingEnabled": null
				}, {
					"key": "Int_Racecourse",
					"itemName": {
						"value": "Racecourse"
					},
					"itemRedirection": {
						"value": "Other link"
					},
					"itemLink": {
						"value": "/zh-HK/retail-telebet/racecourse/"
					},
					"itemPage": {
						"value": ""
					},
					"itemIcon": {
						"src": null,
						"width": null,
						"height": null
					},
					"jpFlag": null,
					"altTxt": null,
					"itemEnable": {
						"value": "1"
					},
					"redirectMethod": {
						"value": "Popup"
					},
					"pH": {
						"value": "600"
					},
					"pW": {
						"value": "900"
					},
					"pX": {
						"value": "20"
					},
					"pY": {
						"value": "20"
					},
					"specialtag": null,
					"frontendID": null,
					"startDatetime": null,
					"noOfDays": null,
					"frontEndIds": null,
					"sNoOfDays": null,
					"moHead": {
						"value": "Racecourse"
					},
					"moText": {
						"value": "Sha Tin boasts the world's most sophisticated racing facilities and nothing beats the excitement of night racing amidst the stars and skyscrapers at Happy Valley."
					},
					"moImage": {
						"src": "/-/media/Sites/JCBW/pic_racecourse.gif?rev=adf08b4aa0be4717819c96b2dfd43869",
						"width": 90,
						"height": 90
					},
					"iconM": null,
					"pCardEnable": null,
					"GlassboxCustomEventName": null,
					"GlassboxCustomEventValue": null,
					"GlassboxTaggingEnabled": null
				}]
			}, {
				"sectionTitle": null,
				"sectionEnabled": null,
				"sectionIcon": null,
				"key": "Int_Betting Info",
				"itemName": {
					"value": "Betting Info"
				},
				"itemRedirection": {
					"value": ""
				},
				"itemLink": {
					"value": ""
				},
				"itemPage": {
					"value": ""
				},
				"itemIcon": {
					"src": "/-/media/Sites/JCBW/MenuIcons/new.gif?rev=b843ace7ec66483c829453dc1ede66dc",
					"width": 17,
					"height": 10
				},
				"jpFlag": null,
				"altTxt": null,
				"itemEnable": {
					"value": "1"
				},
				"redirectMethod": {
					"value": "Popup"
				},
				"pH": {
					"value": "600"
				},
				"pW": {
					"value": "900"
				},
				"pX": {
					"value": "20"
				},
				"pY": {
					"value": "20"
				},
				"specialtag": null,
				"frontendID": null,
				"startDatetime": null,
				"noOfDays": null,
				"frontEndIds": null,
				"sNoOfDays": null,
				"moHead": null,
				"moText": null,
				"moImage": null,
				"iconM": {
					"src": null,
					"width": null,
					"height": null
				},
				"pCardEnable": {
					"value": ""
				},
				"GlassboxCustomEventName": null,
				"GlassboxCustomEventValue": null,
				"GlassboxTaggingEnabled": null,
				"children": [{
					"key": "Int_Horse Racing",
					"itemName": {
						"value": "Horse Racing"
					},
					"itemRedirection": {
						"value": "Other link"
					},
					"itemLink": {
						"value": "https://member2.iuww.com/special/betting-info/Racing/commingling-pools/"
					},
					"itemPage": {
						"value": ""
					},
					"itemIcon": {
						"src": null,
						"width": null,
						"height": null
					},
					"jpFlag": null,
					"altTxt": null,
					"itemEnable": {
						"value": "1"
					},
					"redirectMethod": {
						"value": "New tab in original browser"
					},
					"pH": {
						"value": ""
					},
					"pW": {
						"value": ""
					},
					"pX": {
						"value": ""
					},
					"pY": {
						"value": ""
					},
					"specialtag": null,
					"frontendID": null,
					"startDatetime": null,
					"noOfDays": null,
					"frontEndIds": null,
					"sNoOfDays": null,
					"moHead": {
						"value": ""
					},
					"moText": {
						"value": ""
					},
					"moImage": {
						"src": null,
						"width": null,
						"height": null
					},
					"iconM": null,
					"pCardEnable": null,
					"GlassboxCustomEventName": null,
					"GlassboxCustomEventValue": null,
					"GlassboxTaggingEnabled": null
				}, {
					"key": "Int_HKJC Football",
					"itemName": {
						"value": "HKJC Football"
					},
					"itemRedirection": {
						"value": "Other link"
					},
					"itemLink": {
						"value": "https://member2.iuww.com/special/betting-info/Football/had/"
					},
					"itemPage": {
						"value": ""
					},
					"itemIcon": {
						"src": null,
						"width": null,
						"height": null
					},
					"jpFlag": null,
					"altTxt": null,
					"itemEnable": {
						"value": "1"
					},
					"redirectMethod": {
						"value": "New tab in original browser"
					},
					"pH": {
						"value": ""
					},
					"pW": {
						"value": ""
					},
					"pX": {
						"value": ""
					},
					"pY": {
						"value": ""
					},
					"specialtag": null,
					"frontendID": null,
					"startDatetime": null,
					"noOfDays": null,
					"frontEndIds": null,
					"sNoOfDays": null,
					"moHead": {
						"value": ""
					},
					"moText": {
						"value": ""
					},
					"moImage": {
						"src": null,
						"width": null,
						"height": null
					},
					"iconM": null,
					"pCardEnable": null,
					"GlassboxCustomEventName": null,
					"GlassboxCustomEventValue": null,
					"GlassboxTaggingEnabled": null
				}, {
					"key": "Int_Mark Six",
					"itemName": {
						"value": "Mark Six"
					},
					"itemRedirection": {
						"value": "Other link"
					},
					"itemLink": {
						"value": "https://member2.iuww.com/special/en-US/betting-info/marksix/lottery/"
					},
					"itemPage": {
						"value": ""
					},
					"itemIcon": {
						"src": MarkSix,
						"isLocalSrc":true,
						"width": null,
						"height": null
					},
					"jpFlag": null,
					"altTxt": null,
					"itemEnable": {
						"value": "1"
					},
					"redirectMethod": {
						"value": "New tab in original browser"
					},
					"pH": {
						"value": ""
					},
					"pW": {
						"value": ""
					},
					"pX": {
						"value": ""
					},
					"pY": {
						"value": ""
					},
					"specialtag": null,
					"frontendID": null,
					"startDatetime": null,
					"noOfDays": null,
					"frontEndIds": null,
					"sNoOfDays": null,
					"moHead": {
						"value": ""
					},
					"moText": {
						"value": ""
					},
					"moImage": {
						"src": null,
						"width": null,
						"height": null
					},
					"iconM": null,
					"pCardEnable": null,
					"GlassboxCustomEventName": null,
					"GlassboxCustomEventValue": null,
					"GlassboxTaggingEnabled": null
				}]
			}, {
				"sectionTitle": null,
				"sectionEnabled": null,
				"sectionIcon": null,
				"key": "Int_Customer eCentre",
				"itemName": {
					"value": "Customer Services"
				},
				"itemRedirection": {
					"value": ""
				},
				"itemLink": {
					"value": ""
				},
				"itemPage": {
					"value": ""
				},
				"itemIcon": {
					"src": null,
					"width": null,
					"height": null
				},
				"jpFlag": null,
				"altTxt": null,
				"itemEnable": {
					"value": "1"
				},
				"redirectMethod": {
					"value": "Popup"
				},
				"pH": {
					"value": "600"
				},
				"pW": {
					"value": "900"
				},
				"pX": {
					"value": "20"
				},
				"pY": {
					"value": "20"
				},
				"specialtag": null,
				"frontendID": null,
				"startDatetime": null,
				"noOfDays": null,
				"frontEndIds": null,
				"sNoOfDays": null,
				"moHead": null,
				"moText": null,
				"moImage": null,
				"iconM": {
					"src": null,
					"width": null,
					"height": null
				},
				"pCardEnable": {
					"value": ""
				},
				"GlassboxCustomEventName": null,
				"GlassboxCustomEventValue": null,
				"GlassboxTaggingEnabled": null,
				"children": [{
					"key": "Int_Customer Service Chatbot",
					"itemName": {
						"value": "Customer Service Chatbot"
					},
					"itemRedirection": {
						"value": "Other link"
					},
					"itemLink": {
						"value": "//ecbp.hkjc.com/?sys=d1kE&ch=6LvE&lang=en"
					},
					"itemPage": {
						"value": ""
					},
					"itemIcon": {
						"src": "/-/media/Sites/JCBW/MenuIcons/new.gif?rev=b843ace7ec66483c829453dc1ede66dc",
						"width": 17,
						"height": 10
					},
					"jpFlag": null,
					"altTxt": null,
					"itemEnable": {
						"value": "1"
					},
					"redirectMethod": {
						"value": "New tab in original browser"
					},
					"pH": {
						"value": ""
					},
					"pW": {
						"value": ""
					},
					"pX": {
						"value": ""
					},
					"pY": {
						"value": ""
					},
					"specialtag": null,
					"frontendID": null,
					"startDatetime": null,
					"noOfDays": null,
					"frontEndIds": null,
					"sNoOfDays": null,
					"moHead": {
						"value": ""
					},
					"moText": {
						"value": ""
					},
					"moImage": {
						"src": null,
						"width": null,
						"height": null
					},
					"iconM": null,
					"pCardEnable": null,
					"GlassboxCustomEventName": null,
					"GlassboxCustomEventValue": null,
					"GlassboxTaggingEnabled": null
				}, {
					"key": "Int_Customer eCentre",
					"itemName": {
						"value": "Customer eCentre"
					},
					"itemRedirection": {
						"value": "Other link"
					},
					"itemLink": {
						"value": "//wcip02.hkjc.com/loginformCSSP.aspx?language=en-us&link=%23%2Fhome"
					},
					"itemPage": {
						"value": ""
					},
					"itemIcon": {
						"src": null,
						"width": null,
						"height": null
					},
					"jpFlag": null,
					"altTxt": null,
					"itemEnable": {
						"value": "1"
					},
					"redirectMethod": {
						"value": "New tab in original browser"
					},
					"pH": {
						"value": ""
					},
					"pW": {
						"value": ""
					},
					"pX": {
						"value": ""
					},
					"pY": {
						"value": ""
					},
					"specialtag": null,
					"frontendID": null,
					"startDatetime": null,
					"noOfDays": null,
					"frontEndIds": null,
					"sNoOfDays": null,
					"moHead": {
						"value": ""
					},
					"moText": {
						"value": ""
					},
					"moImage": {
						"src": null,
						"width": null,
						"height": null
					},
					"iconM": null,
					"pCardEnable": null,
					"GlassboxCustomEventName": null,
					"GlassboxCustomEventValue": null,
					"GlassboxTaggingEnabled": null
				}, {
					"key": "Int_Download Forms",
					"itemName": {
						"value": "Download Forms"
					},
					"itemRedirection": {
						"value": "Other link"
					},
					"itemLink": {
						"value": "/en-US/online-channels/downloads/"
					},
					"itemPage": {
						"value": ""
					},
					"itemIcon": {
						"src": null,
						"width": null,
						"height": null
					},
					"jpFlag": null,
					"altTxt": null,
					"itemEnable": {
						"value": "1"
					},
					"redirectMethod": {
						"value": "New tab in original browser"
					},
					"pH": {
						"value": "600"
					},
					"pW": {
						"value": "900"
					},
					"pX": {
						"value": "20"
					},
					"pY": {
						"value": "20"
					},
					"specialtag": null,
					"frontendID": null,
					"startDatetime": null,
					"noOfDays": null,
					"frontEndIds": null,
					"sNoOfDays": null,
					"moHead": {
						"value": ""
					},
					"moText": {
						"value": ""
					},
					"moImage": {
						"src": null,
						"width": null,
						"height": null
					},
					"iconM": null,
					"pCardEnable": null,
					"GlassboxCustomEventName": null,
					"GlassboxCustomEventValue": null,
					"GlassboxTaggingEnabled": null
				}, {
					"key": "Int_Other Contact Channels",
					"itemName": {
						"value": "Other Contact Channels"
					},
					"itemRedirection": {
						"value": "Other link"
					},
					"itemLink": {
						"value": "/en-US/online-channels/hotlines/"
					},
					"itemPage": {
						"value": ""
					},
					"itemIcon": {
						"src": null,
						"width": null,
						"height": null
					},
					"jpFlag": null,
					"altTxt": null,
					"itemEnable": {
						"value": "1"
					},
					"redirectMethod": {
						"value": "New tab in original browser"
					},
					"pH": {
						"value": ""
					},
					"pW": {
						"value": ""
					},
					"pX": {
						"value": ""
					},
					"pY": {
						"value": ""
					},
					"specialtag": null,
					"frontendID": null,
					"startDatetime": null,
					"noOfDays": null,
					"frontEndIds": null,
					"sNoOfDays": null,
					"moHead": {
						"value": ""
					},
					"moText": {
						"value": ""
					},
					"moImage": {
						"src": null,
						"width": null,
						"height": null
					},
					"iconM": null,
					"pCardEnable": null,
					"GlassboxCustomEventName": null,
					"GlassboxCustomEventValue": null,
					"GlassboxTaggingEnabled": null
				}]
			}, {
				"sectionTitle": null,
				"sectionEnabled": null,
				"sectionIcon": null,
				"key": "Int_Priority",
				"itemName": {
					"value": "HKJC Priority(SA)"
				},
				"itemRedirection": {
					"value": ""
				},
				"itemLink": {
					"value": ""
				},
				"itemPage": {
					"value": ""
				},
				"itemIcon": {
					"src": null,
					"width": null,
					"height": null
				},
				"jpFlag": null,
				"altTxt": null,
				"itemEnable": {
					"value": "1"
				},
				"redirectMethod": {
					"value": "Popup"
				},
				"pH": {
					"value": "600"
				},
				"pW": {
					"value": "900"
				},
				"pX": {
					"value": "20"
				},
				"pY": {
					"value": "20"
				},
				"specialtag": null,
				"frontendID": null,
				"startDatetime": null,
				"noOfDays": null,
				"frontEndIds": null,
				"sNoOfDays": null,
				"moHead": null,
				"moText": null,
				"moImage": null,
				"iconM": {
					"src": null,
					"width": null,
					"height": null
				},
				"pCardEnable": {
					"value": "1"
				},
				"GlassboxCustomEventName": null,
				"GlassboxCustomEventValue": null,
				"GlassboxTaggingEnabled": null,
				"children": [{
					"key": "Int_PriorityPoint",
					"itemName": {
						"value": "Priority Point-test"
					},
					"itemRedirection": {
						"value": "Other link"
					},
					"itemLink": {
						"value": "https://www.priority.hkjc.com"
					},
					"itemPage": {
						"value": ""
					},
					"itemIcon": {
						"src": null,
						"width": null,
						"height": null
					},
					"jpFlag": null,
					"altTxt": null,
					"itemEnable": {
						"value": "1"
					},
					"redirectMethod": {
						"value": "New tab in original browser"
					},
					"pH": {
						"value": "600"
					},
					"pW": {
						"value": "900"
					},
					"pX": {
						"value": "20"
					},
					"pY": {
						"value": "20"
					},
					"specialtag": null,
					"frontendID": null,
					"startDatetime": null,
					"noOfDays": null,
					"frontEndIds": null,
					"sNoOfDays": null,
					"moHead": {
						"value": ""
					},
					"moText": {
						"value": ""
					},
					"moImage": {
						"src": null,
						"width": null,
						"height": null
					},
					"iconM": null,
					"pCardEnable": null,
					"GlassboxCustomEventName": null,
					"GlassboxCustomEventValue": null,
					"GlassboxTaggingEnabled": null
				}]
			}]
		}
	}
}

// dev data
export const defaultProductTabEn = {
	"item": {
	  "children": [
		{
		  "key": "Int_ewin",
		  "itemName": {
			"value": ""
		  },
		  "itemRedirection": {
			"value": "JCBW page"
		  },
		  "itemLink": {
			"value": ""
		  },
		  "itemPage": {
			"value": "/en/home"
		  },
		  "itemIcon": {
			"src": homeLogo,
			"isLocalSrc":true,
			"width": null,
			"height": null
		  },
		  "jpFlag": null,
		  "altTxt": null,
		  "itemEnable": {
			"value": "1"
		  },
		  "redirectMethod": {
			"value": "Replace original browser"
		  },
		  "pH": {
			"value": ""
		  },
		  "pW": {
			"value": ""
		  },
		  "pX": {
			"value": ""
		  },
		  "pY": {
			"value": ""
		  }
		},
		{
		  "key": "Int_Racing",
		  "itemName": {
			"value": "Racing"
		  },
		  "itemRedirection": {
			"value": "JCBW page"
		  },
		  "itemLink": {
			"value": ""
		  },
		  "itemPage": {
			"value": "/en/racing"
		  },
		  "itemIcon": {
			"src": horse,
			"isLocalSrc":true,
			"width": 20,
			"height": 20
		  },
		  "jpFlag": null,
		  "altTxt": null,
		  "itemEnable": {
			"value": "1"
		  },
		  "redirectMethod": {
			"value": "Replace original browser"
		  },
		  "pH": {
			"value": ""
		  },
		  "pW": {
			"value": ""
		  },
		  "pX": {
			"value": ""
		  },
		  "pY": {
			"value": ""
		  }
		},
		{
		  "key": "Int_Football",
		  "itemName": {
			"value": "HKJC Football"
		  },
		  "itemRedirection": {
			"value": "JCBW page"
		  },
		  "itemLink": {
			"value": ""
		  },
		  "itemPage": {
			"value": "/en/football"
		  },
		  "itemIcon": {
			"src": Football,
			"isLocalSrc":true,
			"width": 20,
			"height": 20
		  },
		  "jpFlag": null,
		  "altTxt": null,
		  "itemEnable": {
			"value": "1"
		  },
		  "redirectMethod": {
			"value": "Replace original browser"
		  },
		  "pH": {
			"value": ""
		  },
		  "pW": {
			"value": ""
		  },
		  "pX": {
			"value": ""
		  },
		  "pY": {
			"value": ""
		  }
		},
		{
		  "key": "Int_MarkSix",
		  "itemName": {
			"value": "Mark Six"
		  },
		  "itemRedirection": {
			"value": "JCBW page"
		  },
		  "itemLink": {
			"value": ""
		  },
		  "itemPage": {
			"value": "/en/marksix"
		  },
		  "itemIcon": {
			"src": MarkSix,
			"isLocalSrc":true,
			"width": 20,
			"height": 20
		  },
		  "jpFlag": null,
		  "altTxt": null,
		  "itemEnable": {
			"value": "1"
		  },
		  "redirectMethod": {
			"value": "Replace original browser"
		  },
		  "pH": {
			"value": ""
		  },
		  "pW": {
			"value": ""
		  },
		  "pX": {
			"value": ""
		  },
		  "pY": {
			"value": ""
		  },
		}
	  ]
	}
  }

export const defaultProductTabCh = {
"item": {
    "children": [
        {
            "key": "Int_ewin",
            "itemName": {
                "value": ""
            },
            "itemRedirection": {
                "value": "JCBW page"
            },
            "itemLink": {
                "value": ""
            },
            "itemPage": {
                "value": "/ch/home"
            },
            "itemIcon": {
                "src": homeLogo,
				"isLocalSrc":true,
				"width": null,
				"height": null
            },
            "jpFlag": null,
            "altTxt": null,
            "itemEnable": {
                "value": "1"
            },
            "redirectMethod": null,
            "pH": null,
            "pW": null,
            "pX": null,
            "pY": null,
            "specialtag": null,
            "predefinedColor": null,
            "frontendID": null,
            "startDatetime": null,
            "noOfDays": null,
            "frontEndIds": null,
            "sNoOfDays": null,
            "moHead": null,
            "moText": null,
            "moImage": null,
            "iconM": null,
            "pCardEnable": null,
            "GlassboxCustomEventName": null,
            "GlassboxCustomEventValue": null,
            "GlassboxTaggingEnabled": null
        },
        {
            "key": "Int_Racing",
            "itemName": {
                "value": "賽馬"
            },
            "itemRedirection": {
                "value": "JCBW page"
            },
            "itemLink": {
                "value": ""
            },
            "itemPage": {
                "value": "/ch/racing"
            },
            "itemIcon": {
                "src": horse,
				"isLocalSrc":true,
				"width": 20,
				"height": 20
            },
            "jpFlag": null,
            "altTxt": null,
            "itemEnable": {
                "value": "1"
            },
            "redirectMethod": null,
            "pH": null,
            "pW": null,
            "pX": null,
            "pY": null,
            "specialtag": null,
            "predefinedColor": null,
            "frontendID": null,
            "startDatetime": null,
            "noOfDays": null,
            "frontEndIds": null,
            "sNoOfDays": null,
            "moHead": null,
            "moText": null,
            "moImage": null,
            "iconM": null,
            "pCardEnable": null,
            "GlassboxCustomEventName": null,
            "GlassboxCustomEventValue": null,
            "GlassboxTaggingEnabled": null
        },
        {
            "key": "Int_Football",
            "itemName": {
                "value": "足智彩"
            },
            "itemRedirection": {
                "value": "JCBW page"
            },
            "itemLink": {
                "value": ""
            },
            "itemPage": {
                "value": "/ch/football"
            },
            "itemIcon": {
                "src": Football,
				"isLocalSrc":true,
				"width": 20,
				"height": 20
            },
            "jpFlag": null,
            "altTxt": null,
            "itemEnable": {
                "value": "1"
            },
            "redirectMethod": null,
            "pH": null,
            "pW": null,
            "pX": null,
            "pY": null,
            "specialtag": null,
            "predefinedColor": null,
            "frontendID": null,
            "startDatetime": null,
            "noOfDays": null,
            "frontEndIds": null,
            "sNoOfDays": null,
            "moHead": null,
            "moText": null,
            "moImage": null,
            "iconM": null,
            "pCardEnable": null,
            "GlassboxCustomEventName": null,
            "GlassboxCustomEventValue": null,
            "GlassboxTaggingEnabled": null
        },
        {
            "key": "Int_MarkSix",
            "itemName": {
                "value": "六合彩"
            },
            "itemRedirection": {
                "value": "JCBW page"
            },
            "itemLink": {
                "value": ""
            },
            "itemPage": {
                "value": "/ch/marksix"
            },
            "itemIcon": {
                "src": MarkSix,
				"isLocalSrc":true,
				"width": 20,
				"height": 20
            },
            "jpFlag": null,
            "altTxt": null,
            "itemEnable": {
                "value": "1"
            },
            "redirectMethod": null,
            "pH": null,
            "pW": null,
            "pX": null,
            "pY": null,
            "specialtag": null,
            "predefinedColor": null,
            "frontendID": null,
            "startDatetime": null,
            "noOfDays": null,
            "frontEndIds": null,
            "sNoOfDays": null,
            "moHead": null,
            "moText": null,
            "moImage": null,
            "iconM": null,
            "pCardEnable": null,
            "GlassboxCustomEventName": null,
            "GlassboxCustomEventValue": null,
            "GlassboxTaggingEnabled": null
        }
    ]
}
}