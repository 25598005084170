import React, { useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FootballContext } from '../../Home/FootballPage';
import useIntersectionObserver from '../../Common/hooks/useIntersectionObserver';


const MixAllupController = ({count, setMixAllupControllerIntersectionRatio}) => {

    const context = useContext(FootballContext);
    const { t } = useTranslation();

    let config = context.content.pageConfig;

    const allupRef = useRef();
    useIntersectionObserver(allupRef.current, setMixAllupControllerIntersectionRatio)

    return config?.showMixAllupControl ? <div ref={allupRef} className={`allup-next`}>
        <div>
            {t('LB_FB_ALLUP_SELECTED')} {context.content.oddsSelection.filter(x => x.checked).length}
        </div>
        <div>
            <div className='btnNext cta_fb' onClick={() => context.state.clearAllupSelection() }>{t('LB_FB_RESET')}</div>
        </div>
        <div>
            <div className='btnNext cta_fb' onClick={() => context.state.onAllupNext() }>{t('LB_FB_NEXT')}</div>
        </div>
    </div>
    : null;
}
export default MixAllupController;