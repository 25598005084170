import { useState, useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import Pagination from './Pagination';
import { RacingContext } from '../../Home/RacingPage';

export const WPQOddsSwitch = ({ betType, tableType, setTableType }) => {
    const { t } = useTranslation();
    const [lineLeft, setLeft] = useState(0);
    const context = useContext(RacingContext);
    const lineRef = useRef();
    const options = ['Top', 'Banker', ''];
    const onChangeTableType = (e, type, betType) => {
        if (e) {
            const left = e?.target?.offsetLeft || 0;
            setLeft(left);
        }
        let newTableType;
        if (betType === 'QIN') {
            newTableType = {
                ...tableType,
                QIN: type
            };
        } else {
            newTableType = {
                ...tableType,
                QPL: type
            };
        }
        setTableType(newTableType);
        context.contentRef.current.displayOpt = newTableType.QIN.toLowerCase();
        context.contentRef.current.displayOpt2 = newTableType.QPL.toLowerCase();
        context.setContent({ ...context.contentRef.current });
        context.racingFuncs.reloadData(context.contentRef.current);
    };

    return (
        <div>
            <div className="wpq-table-switch df">
                {options.map((item) => {
                    return (
                        <div>
                            <label className="radio-container">
                                {t(`LB_RC_WPQ_TABLE_${item.toUpperCase()}`)}
                                <input
                                    type="radio"
                                    checked={item === tableType[betType]}
                                    onClick={(e) => onChangeTableType(e, item, betType)}
                                />
                                <span className="radio-checkmark"></span>
                            </label>
                        </div>
                    );
                })}
            </div>
            <div className="wpq-table-switch-m df">
                {options.map((item) => {
                    return (
                        <div onClick={(e) => onChangeTableType(e, item, betType)}>
                            <div>{t(`LB_RC_WPQ_TABLE_${item.toUpperCase()}`)}</div>
                        </div>
                    );
                })}
                <div className="wpq-table-line" ref={lineRef} style={{ left: lineLeft }}></div>
            </div>
        </div>
    );
};

const WPQOddsTableTop20Row = ({ index, betType, data, pool, onClickOdds, enableQuickBet, isStartSell }) => {
    const handleClickOdds = (odds) => {
        onClickOdds(odds, betType);
    };
    const row = [];
    const isRefunded = pool?.status.indexOf('REFUND') >= 0;
    for (let i = 0; i < 4; i++) {
        const currentIndex = index + i * 5;
        const currentlabel = data[currentIndex]?.combString
            ?.split(',')
            ?.map((item) => Number(item))
            .join('-');
        let odds = '';
        if (data[currentIndex] != null) odds = isRefunded ? '---' : data[currentIndex].oddsValue;
        row.push(
            <div
                className="wpq-odds-table-cell"
                id={`${betType}_top20_${currentlabel}`}
                key={`table-row-${index}-top10-cell-${i}`}
            >
                <div>
                    <div className="cell-label">{currentlabel}</div>
                    <div className={`cell-value ${isStartSell && odds != '' ? '' : 'disabled'}`}>
                        <span
                            onClick={() => enableQuickBet && odds != '' && handleClickOdds(data[currentIndex])}
                            className={`${enableQuickBet ? 'cp table-odds' : ''}`}
                        >
                            {odds}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
    return <div className={`wpq-odds-table-row ${index % 2 ? 'sec' : ''}`}>{row}</div>;
};

export const WPQOddsTableTop20 = (props) => {
    const { t } = useTranslation();
    const Table = () => {
        const table = [];
        for (let i = 0; i < 5; i++) {
            table.push(<WPQOddsTableTop20Row index={i} key={`table-row-top20-${i}`} {...props} />);
        }
        return <div className="wpq-odds-table-top20">{table}</div>;
    };

    return (
        <div className="wpq-odds-table-top20-box">
            <header>
                {t(`LB_${props.betType}`)} {t(`LB_RC_WPQ_TABLE_TOP`)}
            </header>
            {<Table />}
        </div>
    );
};

const WPQOddsTableTop10TableHeader = ({ currentRaceData }) => {
    const { t, i18n } = useTranslation();
    const header = [];
    for (let i = 0; i < 4; i++) {
        const item = currentRaceData[i];
        const name = `${item?.no || ''} ${item?.['name_' + i18n.language] || ''}`;
        const scrStr = item?.status.toUpperCase().indexOf('SCRATCHED') >= 0 ? `(${t('LB_RC_SCR')})` : '';
        const resStr = ['RESERVED', 'STANDBY'].includes(item?.status.toUpperCase()) ? `(${t('LB_RC_RESERVE')})` : '';
        header.push(
            <div key={`${name}-${i}`} className="wpq-odds-table-cell">
                {name}
                {scrStr}
                {resStr}
            </div>
        );
    }

    return <div className="wpq-odds-table-top10-title wpq-odds-table-row">{header}</div>;
};

const WPQOddsTableTop10Row = ({
    index,
    currentRaceData,
    betType,
    data,
    pool,
    onClickOdds,
    enableQuickBet,
    isStartSell
}) => {
    const handleClickOdds = (odds) => {
        onClickOdds(odds, betType);
    };

    const row = [];
    const isRefunded = pool?.status.indexOf('REFUND') >= 0;
    for (let i = 0; i < 4; i++) {
        let no = '';
        let odds = '';
        const oddsData = data.filter((item) => item.combString == currentRaceData[i]?.no)[0];
        if (oddsData != null && oddsData.bankerOdds[index] != null) {
            no = oddsData.bankerOdds[index].combString
                    .split(',')
                    ?.map((item) => Number(item))
                    ?.join('-') || [];
            odds = isRefunded ? '---' : oddsData.bankerOdds[index].oddsValue;
        }
        row.push(
            <div
                className={`wpq-odds-table-cell`}
                key={`table-cell-${index}-top10-${i}`}
                id={`${betType}_bankerTop10_${currentRaceData[i]?.no}_${no}`}
            >
                <div>
                    <div className="cell-label">{no}</div>
                    <div className={`cell-value ${isStartSell && odds != '' ? '' : 'disabled'}`}>
                        <span
                            onClick={() => enableQuickBet && odds != '' && handleClickOdds(oddsData.bankerOdds[index])}
                            className={`${enableQuickBet ? 'cp table-odds' : ''}`}
                        >
                            {odds}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
    return <div className={`wpq-odds-table-row ${index % 2 ? '' : 'sec'}`}>{row}</div>;
};

export const WPQOddsTableTop10 = (props) => {
    const { t, i18n } = useTranslation();
    const [pageNum, setPageNum] = useState(1);
    const [currentRaceData, setCurrentRaceData] = useState([]);
    const { betType, race } = props;

    useEffect(() => {
        const tmpRunner = race.runners.filter((item) => item.no >= (pageNum - 1) * 4 + 1 && item.no <= pageNum * 4);
        setCurrentRaceData(tmpRunner);
    }, [pageNum, race.runners]);

    const Table = () => {
        const table = [];

        for (let i = 0; i < 10; i++) {
            table.push(
                <WPQOddsTableTop10Row
                    index={i}
                    currentRaceData={currentRaceData}
                    key={`table-row-top10-${i}`}
                    {...props}
                />
            );
        }

        return (
            <div className="wpq-odds-table-top10">
                <WPQOddsTableTop10TableHeader currentRaceData={currentRaceData} />
                {table}
            </div>
        );
    };

    return (
        <div className="wpq-odds-table-top10-box">
            <header>
                {t(`LB_${betType}`)} {t(`LB_RC_WPQ_TABLE_BANKER`)}
            </header>
            <Table />
            <Pagination total={Math.ceil(race.wageringFieldSize / 4)} onChange={setPageNum} pageNum={pageNum} />
        </div>
    );
};
