import moment from 'moment';
import { isSellingStarted, getCellId } from '../../Common/Common';
import { isMatchKickOff } from '../../Common/MatchBase';

export const setMatchDataOddsDiff = (contentRef, newMatches) => {
    newMatches?.forEach(rMatch =>{
        let match = contentRef.current.matchData?.matches?.find(x=> x.id===rMatch.id);
        if ( !match ) return false;

        rMatch.foPools?.forEach(rFoPool=> {
            setFoPoolOddsDiff(contentRef, match, rFoPool);
        });
    });
}

export const setTournDataOddsDiff = (contentRef, newTourns) => {
    newTourns?.forEach(rTourn =>{
        let tourn = contentRef.current.tournData?.tournaments?.find(x=> x.id===rTourn.id);
        if ( !tourn ) return false;

        rTourn.foPools?.forEach(rFoPool=> {
            setFoPoolOddsDiff(contentRef, tourn, rFoPool);
        });
    });
}

const setFoPoolOddsDiff = (contentRef, parent, rFoPool) => {
    let foPool = parent.foPools?.find(x=> x.id===rFoPool.id);
    if ( !foPool ) return false;
    rFoPool.lines?.forEach(rLine => {
        let line = foPool?.lines.find(x=> x.lineId===rLine.lineId);
        if ( !line ) return false;
        rLine.combinations?.forEach(rComb => {
            let comb = line.combinations.find(x=> x.combId===rComb.combId);
            if ( !comb ) return false;

            let id = getCellId(rFoPool, rLine, rComb);
            let isSelling = isSellingStarted(parent, rFoPool, rLine) && rComb.status == 'AVAILABLE';
            let oddsDiff = isMatchKickOff(parent.status) && !isSelling ? 0 : parseFloat(rComb.currentOdds) - parseFloat(comb.currentOdds);
            let idx = contentRef.current.oddsSelection.findIndex(x => x.id === id);
            let now = moment();
            if ( idx > -1 ) {
                if ( oddsDiff!=0 ) {
                    contentRef.current.oddsSelection[idx].oddsDiff = oddsDiff;
                    contentRef.current.oddsSelection[idx].oddUpdTs = now;
                }
                if( !isSelling ){
                    contentRef.current.oddsSelection[idx].checked = false
                }
            }
            else if(oddsDiff!=0){
                contentRef.current.oddsSelection.push({
                    id: id,
                    oddsDiff: oddsDiff,
                    oddUpdTs: now
                })
            }
           
        });
        if(['CRS','FCS'].includes(contentRef.current.page) && contentRef.current.config?.CorrectScorePools.includes(foPool.oddsType)){
            let comb = [...line.combinations].sort((a,b)=> a.currentOdds - b.currentOdds)
            comb.forEach((comb, index) => {
                let id = getCellId(foPool, line, comb)
                if(index > 3 ){
                    let idx = contentRef.current.oddsSelection.findIndex(i => i.id === id)
                    if(idx > -1)  contentRef.current.oddsSelection[idx].checked = false
                }
            })
        }
    });
}