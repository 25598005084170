import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import LangSelector from './LangSelector';
import Survey from './Survey';
import { NewWindow } from '../Common/home-common';
import { getProductFromURL, getOldUrl } from '../Common/GlobalFunc';
import { useWindowSize } from '../Common/CommonHooks';
import { useProductTabQuery } from '../Home/Data/SitecoreDataHooks';

import { slide as BurgerMenu } from 'react-burger-menu';
import LoginBtn from '../BetSlip/LoginBtn';
import SecondLvlMenu from '../Menu/SecondLvlMenu';
import MobileSecondLvlMenu from '../Menu/MobileSecondLvlMenu';
import { MenuIcon, handleClickMenuItem } from '../Menu/MenuCommon';
import { glassboxCustomEvent } from '../Common/utils/glassbox';
import oldVersionLogo from '../../info/Include/images/betslip_logo.svg';
import ewin1 from '../../info/Include/images/ewin1.svg';
import toDesktop from '../../info/Include/images/toDesktop.svg';
import SpeedBetLogo from './SpeedbetLogo';
import RelatedMenu from './RelatedMenu';

const Header = (props) => {
    const { t, i18n } = useTranslation();
    let navigate = useNavigate();
    let location = useLocation();
    const { isMobileSize } = useWindowSize();
    const [tabLoading, tabData] = useProductTabQuery();
    const isSpeedbet = props.isSpeedbet || window.globalConfig.IS_SPEEDBET;

    useEffect(() => {
        document.title = t('TITLE');
    }, [t]);

    const clickTopMenuLink = (idx) => {
        let url = t('URL_' + idx);
        NewWindow(url, '', 770, 500, 1, 1);
    };

    const closeMenu = () => {
        if (document.getElementsByClassName('bm-overlay')[0].style.opacity === '1') {
            document.getElementsByClassName('bm-overlay')[0].click();
        }
    };

    const highlightProductTab = (key) => {
        switch (key.toLowerCase()) {
            case 'int_racing':
                return getProductFromURL() === 'HR';
            case 'int_football':
                return getProductFromURL() === 'FB';
            case 'int_marksix':
                return getProductFromURL() === 'LY';
            case 'int_ewin':
                return getProductFromURL() === 'GL';
        }
        return false;
    };

    const isLoginPage = location.pathname.includes('login'); // <div className='hkjcpriority' />
    const hideSpBetOldPageForLogin = isLoginPage && isSpeedbet;

    const EwinIcon = useCallback(({ tabLoading, tabData }) => {
        if (tabLoading) {
            return null;
        }
        const ewinIcon = tabData.filter((x) => x.key === 'Int_ewin' && x.itemEnable.value === '1')[0];
        return ewinIcon ? (
            <div
                className="mobileEwinHome"
                onClick={(e) => {
                    handleClickMenuItem(e, ewinIcon, navigate);
                }}
            >
                <MenuIcon menu={ewinIcon} />
            </div>
        ) : null;
    }, []);

    const changeToDesktopVersion = () => {
        sessionStorage.setItem('isDeskTopVersion', 1);
        // window.location.reload();
        window.open(window.location.href);
        sessionStorage.setItem('isDeskTopVersion', null);
    };

    const prodTabProps = {
        tabLoading,
        tabData,
        navigate,
        highlightProductTab,
        isSpeedbet,
        isLoginPage,
        closeMenu,
        hideSpBetOldPageForLogin,
        oldUrl: getOldUrl(i18n.language)
    };

    return !isMobileSize ? (
        <>
            <div className={`fMenuTR`}>
                <RelatedMenu />
                <a
                    className={`old-version ${
                        !window.globalConfig.ENABLE_OLD_SITE_LINK ? 'vsbHide' : ''
                    }`}
                    href={getOldUrl(i18n.language)}
                >
                    <img src={oldVersionLogo} />
                    {t('LB_OLD_VERSION')}
                </a>
                <Survey />
                <LangSelector />
            </div>
            <div className="topInfoMenu">
                {isLoginPage ? (
                    <div className="hkjcpriority">
                        <div className="hkjcpriority-logo">香港賽馬會「優越」客戶</div>
                    </div>
                ) : (
                    <ProductTab {...prodTabProps} />
                )}
                <div className="topNav">
                    <div className="tContainer">
                        <SecondLvlMenu />
                    </div>
                </div>
            </div>
        </>
    ) : (
        <div className="fMenuTR-mobile-con">
            <div className={`fMenuTR-mobile`}>
                <BurgerMenu>
                    <div className="bm-top-header">
                        <div> </div>
                        <div className="mobile-survey-lang-wrap">
                            <LangSelector />
                            <Survey />
                        </div>
                    </div>
                    <div className="bm-content">
                        <ProductTabMobile {...prodTabProps} />
                        <MobileSecondLvlMenu {...props}/>
                        <div className="under-separator" />
                        <RelatedMenu />
                    </div>
                    <div className="bm-menu-bottom">
                        <a className="old-version" onClick={changeToDesktopVersion}>
                            <img src={toDesktop} />
                            {t('LB_DESKTOP_VERSION')}
                        </a>
                        {window.globalConfig.ENABLE_OLD_SITE_LINK && (
                            <a
                                className={`old-version`}
                                href={getOldUrl(i18n.language)}
                            >
                                <img src={oldVersionLogo} />
                                {t('LB_OLD_VERSION')}
                            </a>
                        )}
                    </div>
                </BurgerMenu>
                {!isLoginPage && <EwinIcon tabLoading={tabLoading} tabData={tabData} />}
                {isLoginPage ? <SpeedBetLogo /> : <LoginBtn />}
            </div>
        </div>
    );
};

const ProductTab = ({ tabLoading, tabData, navigate, highlightProductTab, isSpeedbet }) => {
    if (tabLoading) {
        return null;
    }

    return (
        <div className="secMenu">
            {tabData.map((menu) => {
                return menu.itemEnable.value === '1' ? (
                    <div
                        id={`secMenu${menu.key}`}
                        onClick={(e) => {
                            handleClickMenuItem(e, menu, navigate);
                        }}
                        className={`secMenuItem ${menu.key} ${highlightProductTab(menu.key) ? 'active' : ''}${
                            isSpeedbet ? ' isSpeedbet' : ''
                        }`}
                        key={menu.key}
                        aria-label={menu.itemName.value || null}
                    >
                        <div className={`${menu.key != 'Int_ewin' ? 'iconSize20' : ''}`}>
                            <MenuIcon menu={menu} />
                        </div>
                        {menu.itemName.value !== '' && menu.key != 'Int_ewin' && <div>{menu.itemName.value}</div>}
                    </div>
                ) : null;
            })}
        </div>
    );
};
const ProductTabMobile = ({
    tabLoading,
    tabData,
    navigate,
    highlightProductTab,
    isLoginPage,
    closeMenu,
    hideSpBetOldPageForLogin
}) => {
    const { i18n } = useTranslation();
    let results = null;
    if (tabLoading || isLoginPage) {
        results = null;
    } else {
        results = tabData.map((menu) => {
            return menu.itemEnable.value === '1' && menu.key !== 'Int_ewin' ? (
                <a
                    className={`product-tab ${menu.key}`}
                    onClick={(e) => {
                        handleClickMenuItem(e, menu, navigate);
                        closeMenu();
                    }}
                    key={menu.key}
                    aria-label={menu.itemName.value || null}
                    role='button'
                >
                    <div className={`${highlightProductTab(menu.key) ? 'active' : ''}`}>
                        <MenuIcon menu={menu} />
                        {menu.itemName.value !== '' && menu.itemName.value}
                    </div>
                </a>
            ) : null;
        });
    }

    return (
        <>
            {results}
            <a
                className={`product-tab ewin ${hideSpBetOldPageForLogin ? 'hide-d' : ''}`}
                onClick={() => {
                    navigate(`/${i18n.language}/home`);
                    closeMenu();
                }}
                aria-label="eWin"
                role='button'
            >
                <div>
                    <div className="leftMenuIcon">
                        <img src={ewin1} width={24} height={24}/>
                    </div>
                    eWin
                </div>
            </a>
        </>
    );
};


export default Header;
