import React from 'react';

import MediaBanner from './MediaBanner';
import RGPBanner from './RGPBanner';
import ResponsiblyImage from './ResponsiblyImage';

import Copyright from './Copyright';
import RGPLinks from './RGPLinks';

import { useWindowSize } from '../Common/CommonHooks';
import { useRGPBannerQuery } from '../Home/Data/SitecoreDataHooks';
import CheckBox from '../Common/ui-components/CheckBox';

const Footer = ({className= ''}) => {
    const [ rgpLoading, rgpData ] = useRGPBannerQuery();
    const { isMobileSize } = useWindowSize();

    let showBanner;
    if (window.globalConfig.IS_SPEEDBET) {
        showBanner = rgpData.speedbetEnable?.value === "1";
    } else if (window.globalConfig.IS_EWIN2) {
        showBanner = rgpData.chinaSiteEnable?.value === "1";
    } else {
        showBanner = rgpData.massSiteEnable?.value === "1";
    }
    return (
        <div className={`footer ${className}`}>
            {isMobileSize ?
            <div className="footer-top-m">
                <div className='footer-top-tips'>
                    { !window.globalConfig.IS_EWIN2 && <MediaBanner /> }
                    {!rgpLoading && rgpData.enable?.value==="1" && showBanner && <ResponsiblyImage rgpData={rgpData} />}
                </div>
                {!rgpLoading && rgpData.enable?.value==="1" && showBanner && <RGPBanner rgpData={rgpData} />}
            </div>
            :
            <div className="footer-top">
                {!window.globalConfig.IS_EWIN2 && <MediaBanner /> }
                {!rgpLoading && rgpData.enable?.value==="1" && showBanner  && <RGPBanner rgpData={rgpData} />}
                {!rgpLoading && rgpData.enable?.value==="1" && showBanner && <ResponsiblyImage rgpData={rgpData} />}
            </div>
            }
            <div className="footer-buttom">
                { !window.globalConfig.IS_EWIN2 && <RGPLinks /> }
                <Copyright />
            </div>
            {/* Preload components, Because some components have slow downloading of SVG images, SQ0248-2607 issue 1 */}
            <div className='Preload-components' style={{display: 'none'}}>
                <CheckBox checked={true}/>
            </div>
        </div>
    );
};
export default Footer;
