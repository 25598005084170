import React, { useEffect, useState, context, useContext } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { NewWindow, postMsgToPeNote } from '../../Common/home-common';
import { RacingContext } from '../../Home/RacingPage';
import { glassboxCustomEvent } from '../../Common/utils/glassbox';
import { useLocalSilkColorQuery } from '../../Home/Data/SitecoreDataHooks';
import spacerGif from '../../../info/Include/images/spacer.gif';
import { redirectJCRW } from './Common';

export const clickHorseLink = (t, code) => {
    let url = window.globalConfig.JCRW_URL + t('URL_RC_HORSE') + code;
    redirectJCRW(url);
};
export const clickJockyLink = (t, code) => {
    let url = window.globalConfig.JCRW_URL + t('URL_RC_JOCKY') + code;
    redirectJCRW(url);
};
export const clickJockyOtherLink = (t) => {
    let url = window.globalConfig.JCRW_URL + t('URL_RC_JOCKYOTHER');
    redirectJCRW(url);
};
export const clickTrainerLink = (t, code) => {
    let url = window.globalConfig.JCRW_URL + t('URL_RC_TRAINER') + code;
    redirectJCRW(url);
};
export const clickTrainerOtherLink = (t) => {
    let url = window.globalConfig.JCRW_URL + t('URL_RC_TRAINEROTHER');   
    redirectJCRW(url);
};

export function HorseName(props) {
    const context = useContext(RacingContext);
    if (props.isLocal) {
        return (
            <div id={`horseName_${props.raceNo}_${props.runnerNo}`} className="horseName">
                <a
                    onClick={() => {
                        glassboxCustomEvent('To_JCRW', 'Horse_Info_' + context.content.page);
                        clickHorseLink(props.t, `${props.racingColorFileName}`);
                    }}
                >
                    {props.name}
                </a>
            </div>
        );
    } else {
        return (
            <div id={`horseName_${props.raceNo}_${props.runnerNo}`} className="horseName simulcast">
                {props.name}
            </div>
        );
    }
}

export function MobileHorseName({ hyperlink, isLocal, columns, t, runner }) {
    const context = useContext(RacingContext);
    const isCanJump= hyperlink || ['WP','PWIN','HOME'].includes(context?.content?.page);
    return (
        <div className="horseName">
            <a onClick={() => {
                if ( isLocal ) {
                    glassboxCustomEvent('To_JCRW', 'Horse_Info_' + context.content.page);
                    isCanJump && clickHorseLink(t, `${runner.racingColorFileName}`);
                }
            }}>
                <div className={`font-size-15 ${isLocal ? 'font-color-blue bold' : ''}`}>{runner.name}</div>
                <div className="joc-trn-row sec">
                    <div>
                        <div className="subNameCon">
                            <label className="subName">{t('LB_RACE_JKC_SHORT')}</label>
                        </div>
                        <div className="name-str">
                            {runner.isScratch || columns.showStandby
                                ? '---'
                                : `${runner.jocky}${columns.alw ? runner.alw : ''}`}
                        </div>
                    </div>
                    <div>
                        <div className="subNameCon">
                            <label className="subName">{t('LB_RACE_TRN_SHORT')}</label>
                        </div>
                        <div className="name-str">{runner.isScratch ? '---' : runner.trn}</div>
                    </div>
                </div>
            </a>
        </div>
    );
}

export function Jockey(props) {
    const context = useContext(RacingContext);
    if (props.isLocal) {
        return (
            <div id={`jockey_${props.raceNo}_${props.runnerNo}`} className="jocky">
                <a
                    onClick={() => {
                        glassboxCustomEvent('To_JCRW', 'Jockey_Info_' + context.content.page);
                        clickJockyLink(props.t, `${props.jockyId}`);
                    }}
                >
                    {props.jocky}
                    {props.alw}
                </a>
            </div>
        );
    } else {
        return (
            <div id={`jockey_${props.raceNo}_${props.runnerNo}`} className="jocky">
                {props.jocky}
            </div>
        );
    }
}

export function Trainer(props) {
    const context = useContext(RacingContext);
    if (props.isLocal) {
        return (
            <div id={`trainer_${props.raceNo}_${props.runnerNo}`} className="trainer">
                <a onClick={() => {
                    glassboxCustomEvent('To_JCRW', 'Trainer_Info_' + context.content.page);
                    clickTrainerLink(props.t, `${props.trnId}`);
                }}>{props.trn}</a>
            </div>
        );
    } else {
        return (
            <div id={`trainer_${props.raceNo}_${props.runnerNo}`} className="trainer">
                {props.trn}
            </div>
        );
    }
}

export const handleClickNote = (i18n, url, horseId) => {
    let peNoteLang = i18n.language === 'en' ? 'english' : 'chinese';
    let trCodeLang = i18n.language === 'en' ? 'E' : 'C';
    let pop = NewWindow(window.globalConfig.PENOTES_URL + url.replace('{0}', peNoteLang).replace('{1}', horseId).replace('{2}', trCodeLang), 'racingNotes', 780, 640, 1, 1);
    postMsgToPeNote(pop);
};

export const RaceNote = ({ isLocal, raceNo, runnerNo, horseId }) => {
    const context = useContext(RacingContext);
    const { i18n } = useTranslation();
    if (!isLocal) {
        return null;
    }

    let iconCss = 'add';
    let notesObj = context.content.rNotesData?.index?.filter((x) => x.horseId === horseId)[0];
    let url = window.globalConfig.PENOTE_ENDPOINT.create;
    if (notesObj) {
        if (notesObj.isImportant) {
            url = window.globalConfig.PENOTE_ENDPOINT.view;
            iconCss = 'fav';
        }
        else if (notesObj.hasNote) {
            url = window.globalConfig.PENOTE_ENDPOINT.view;
            iconCss = 'update';
        }
    }

    return (
        <div id={`notes_${raceNo}_${runnerNo}`} className="cursor-pointer RCNOTE" onClick={()=>{handleClickNote(i18n, url, horseId)}}>
            <div className={`rc-note ${iconCss}`}></div>
        </div>
    );
};

const getColorfileName = (mtg, runner, raceNo) => {
    let dt = moment(mtg.date.substring(0, 10)).format('YYYYMMDD');
    let venue = mtg.venueCode.toLowerCase();
    return [dt, venue, String(raceNo).padStart(2, '0'), String(runner.no).padStart(2, '0')];
};

export const ColourImage = React.memo(({ isLocal, code }) => {
    const [loadColor, colorPath] = useLocalSilkColorQuery();
    const [requestImgNum,setRequestImgNum]=useState(0);
    useEffect(() => {
        if ( isLocal ) {
            loadColor(code);
        }
    }, []);

    let path = isLocal
        ? `${window.globalConfig.SITECORE_IMAGE_URL}/sitecore${colorPath}`
        : `${window.globalConfig.SITECORE_IMAGE_URL}${window.sitecorePath.SIM_RACECOLOR_PATH_PREFIX}`.replace(
              '{0}',
              code
          );
    return (
        <img
            src={path}
            title={code}
            onError={({ currentTarget }) => {
                if (location.host === 'localhost') {
                    return false;
                }
                if(requestImgNum>0){
                    return false;
                }else{
                    setRequestImgNum(prev=>prev+1)
                }
                currentTarget.onerror = null;
                currentTarget.src = `${spacerGif}`;
            }}
        />
    );
});

export function getRunnerData(columns, context, t, isLocal, mtg, runner, raceNo, pools, lang) {
    let runnerObj = {};
    runnerObj['no'] = runner.no || runner.standbyNo;
    let rStat = runner.status?.toUpperCase();
    let isStandbyOrReserved = ['STANDBY', 'RESERVED', 'SCRATCHEDRESERVED'].includes(rStat.toUpperCase());
    runnerObj['st'] = rStat;
    runnerObj['resTxt'] = !isLocal && isStandbyOrReserved ? `(${t('LB_RC_RESERVE')})` : '';
    let [d, v, ra, ru] = getColorfileName(mtg, runner, raceNo);
    runnerObj['racingColorFileName'] = isLocal ? runner.horse?.code : `${d}_${v}_r${ra}/${d}_${v}_r${ra}_h${ru}`;
    runnerObj['horseId'] = runner.horse?.id;
    runnerObj['colorTxt'] = [];
    if (!columns.showStandby && !isStandbyOrReserved)
        runnerObj['colorTxt'].push(
            <ColourImage
                key={`colour_${runnerObj['racingColorFileName']}`}
                isLocal={isLocal}
                code={runnerObj['racingColorFileName']}
            />
        );
    else if (!isLocal && isStandbyOrReserved) runnerObj['colorTxt'].push(`(${t('LB_RC_RESERVE')})`);
    runnerObj['tp'] = {
        pref: runner.trainerPreference,
        priority: runner.priority,
        trumpCard: runner.trumpCard
    };
    runnerObj['name'] = runner['name_' + lang];
    runnerObj['draw'] = runner.barrierDrawNumber;
    runnerObj['wt'] = runner.handicapWeight;
    runnerObj['alw'] = '';
    if (runner.allowance != null && runner.allowance.trim() != '') runnerObj['alw'] = ` (-${runner.allowance.trim()})`;
    if (runner.jockey != null) {
        runnerObj['jockyId'] = runner.jockey.code;
        runnerObj['jocky'] = runner.jockey['name_' + lang];
    }
    if (runner.trainer != null) {
        runnerObj['trnId'] = runner.trainer.code;
        runnerObj['trn'] = runner.trainer['name_' + lang];
    }
    runnerObj['isScratch'] = rStat.toUpperCase().indexOf('SCRATCHED')>=0;
    runnerObj['isReserve'] = !isLocal && rStat.toUpperCase() == 'STANDBY';
    runnerObj['selDisable'] = runnerObj['isScratch'] || runnerObj['isReserve'];
    runnerObj['bodyWT'] = runner.currentWeight;
    runnerObj['gear'] = runner.gearInfo;
    runnerObj['last6'] = runner.last6run;
    let rtg = runner.currentRating;
    runnerObj['rtg'] = rtg ? (parseInt(rtg) > 0 ? parseInt(rtg) : "") : "-";
    rtg = runner.internationalRating;
    runnerObj['intRtg'] = rtg ? (parseInt(rtg) > 0 ? parseInt(rtg) : "") : "-";

    runnerObj['plaOdds'] = {
        odds: '',
        od: 0,
        hf: false,
        className: ''
    };
    runnerObj['winOdds'] = {
        odds: '',
        od: 0,
        hf: false,
        className: ''
    };

    let preSuffix = context.content.isPresales ? 'Pre' : '';
    if (pools != null) {
        let tempWinOdds = [];
        let winPool = pools.filter((data) => data.leg.races[0] == raceNo && data.oddsType == 'WIN' + preSuffix)[0];
        let winInvPool = mtg.poolInvs.filter((data) => data.leg.races[0] == raceNo && data.oddsType == 'WIN')[0];
        if ( winInvPool?.status.toUpperCase().indexOf('REFUND') >= 0 ) {
            runnerObj['winOdds'].odds = '---';
        }
        else if (['RESERVED', 'STANDBY'].includes(rStat.toUpperCase())) {
            runnerObj['winOdds'].odds = '---';
        }
        else if ( rStat.toUpperCase().indexOf('SCRATCHED')>=0 ) {
            runnerObj['winOdds'].odds = t('LB_RC_SCR');
        }
        else if (winPool?.oddsNodes != null) {
            tempWinOdds = winPool.oddsNodes.filter((data) => parseInt(data.combString) == runnerObj['no']);
            if (columns.win && tempWinOdds.length > 0) {
                if (tempWinOdds[0].oddsValue == 'SCR') {
                    runnerObj['winOdds'].odds = t('LB_RC_SCR');
                } else {
                    runnerObj['winOdds'].odds = tempWinOdds[0].oddsValue;
                    runnerObj['winOdds'].hf = tempWinOdds[0].hotFavourite;
                    runnerObj['winOdds'].od = tempWinOdds[0].oddsDropValue;
                }
                if (runnerObj['winOdds'].hf) {
                    runnerObj['winOdds'].className = 'favourite';
                } else {

                    const isPresales = context?.content?.isPresales
                    runnerObj['winOdds'].className =
                        isPresales ? '' : runnerObj['winOdds'].od >= 50 ? 'od-50' : runnerObj['winOdds'].od >= 20 ? 'od-20' : '';
                }
            }
        }

        let plaPool = pools.filter((data) => data.leg.races[0] == raceNo && data.oddsType == 'PLA' + preSuffix)[0];
        let plaInvPool = mtg.poolInvs.filter((data) => data.leg.races[0] == raceNo && data.oddsType == 'PLA')[0];
        if (plaInvPool?.status.toUpperCase().indexOf('REFUND') >= 0) {
            runnerObj['plaOdds'].odds = '---';
        }
        else if (['RESERVED', 'STANDBY'].includes(rStat.toUpperCase())) {
            runnerObj['plaOdds'].odds = '---';
        }
        else if ( rStat.toUpperCase().indexOf('SCRATCHED')>=0 ) {
            runnerObj['plaOdds'].odds = t('LB_RC_SCR');
        }
        else if (plaPool?.oddsNodes != null) {
            let tempPlaOdds = plaPool.oddsNodes.filter((data) => parseInt(data.combString) == runnerObj['no']);
            if (columns.place && tempPlaOdds.length > 0) {
                if (tempPlaOdds[0].oddsValue == 'SCR') {
                    runnerObj['plaOdds'].odds = t('LB_RC_SCR');
                } else {
                    runnerObj['plaOdds'].odds = tempPlaOdds[0].oddsValue;
                    runnerObj['plaOdds'].hf = tempPlaOdds[0].hotFavourite;
                    runnerObj['plaOdds'].od = tempPlaOdds[0].oddsDropValue;
                }
                if (runnerObj['plaOdds'].hf) {
                    runnerObj['plaOdds'].className = 'favourite';
                } else {
                    const isPresales = context?.content?.isPresales
                    runnerObj['plaOdds'].className =
                        isPresales ? '' : runnerObj['plaOdds'].od >= 50 ? 'od-50' : runnerObj['plaOdds'].od >= 20 ? 'od-20' : '';
                }
            }
        }

        if (columns.pwin0) {
            let tempPwin0Odds = [];
            let pwin0Pool = pools.filter((data) => data.leg.races[0] == raceNo && data.oddsType == 'PWIN0')[0];
            if (pwin0Pool != null && pwin0Pool.oddsNodes != null) {
                tempPwin0Odds = pwin0Pool.oddsNodes.filter((data) => parseInt(data.combString) == runnerObj['no']);
            }
            if (pwin0Pool?.status.toUpperCase().indexOf('REFUND') >= 0) {
                runnerObj['pwinOdds0'] = '---';
            }
            else if (['RESERVED', 'STANDBY'].includes(rStat.toUpperCase())) {
                runnerObj['pwinOdds0'] = '---';
            } else if (rStat.toUpperCase().indexOf('SCRATCHED')>=0) {
                runnerObj['pwinOdds0'] = t('LB_RC_SCR');
            } else if ( tempPwin0Odds.length == 0 ) {
                runnerObj['pwinOdds0'] = '---';
            } else if (tempPwin0Odds?.length > 0) {
                if (tempPwin0Odds[0].oddsValue == 'SCR') {
                    runnerObj['pwinOdds0'] = t('LB_RC_SCR');
                } else {
                    runnerObj['pwinOdds0'] = tempPwin0Odds[0].oddsValue;
                }
            }
        }

        if (columns.pwin1) {
            let tempPwin1Odds = [];
            let pwin1Pool = pools.filter((data) => data.leg.races[0] == raceNo && data.oddsType == 'PWIN1')[0];
            if (pwin1Pool != null && pwin1Pool.oddsNodes != null) {
                tempPwin1Odds = pwin1Pool.oddsNodes.filter((data) => parseInt(data.combString) == runnerObj['no']);
            }
            if (pwin1Pool?.status.toUpperCase().indexOf('REFUND') >= 0) {
                runnerObj['pwinOdds1'] = '---';
            }
            else if (['RESERVED', 'STANDBY'].includes(rStat.toUpperCase())) {
                runnerObj['pwinOdds1'] = '---';
            } else if (rStat.toUpperCase().indexOf('SCRATCHED')>=0) {
                runnerObj['pwinOdds1'] = t('LB_RC_SCR');
            } else if ( tempPwin1Odds.length == 0 ) {
                runnerObj['pwinOdds1'] = '---';
            } else if (tempPwin1Odds.length > 0) {
                if (tempPwin1Odds[0].oddsValue == 'SCR') {
                    runnerObj['pwinOdds1'] = t('LB_RC_SCR');
                } else {
                    runnerObj['pwinOdds1'] = tempPwin1Odds[0].oddsValue;
                }
            }
        }
    }
    return runnerObj;
}

export function getPwinTimes(context) {
    let pTime0 = '';
    let pTime1 = '';
    let winTime = '';
    const mtg = context.content.meeting;
    if (mtg != null) {
        let winPool = mtg.pmPools.filter(
            (data) => data.leg.races[0] == context.content.raceNo && data.oddsType == 'WIN'
        )[0];
        let pwin0Pool = mtg.pmPools.filter(
            (data) => data.leg.races[0] == context.content.raceNo && data.oddsType == 'PWIN0'
        )[0];
        let pwin1Pool = mtg.pmPools.filter(
            (data) => data.leg.races[0] == context.content.raceNo && data.oddsType == 'PWIN1'
        )[0];

        if (winPool != null && winPool.lastUpdateTime != null && winPool.lastUpdateTime != '') {
            winTime = winPool.lastUpdateTime.substring(11, 16);
        }
        pTime0 = "---";
        if (pwin0Pool != null && pwin0Pool.lastUpdateTime != null && pwin0Pool.lastUpdateTime != '') {
            pTime0 = pwin0Pool.lastUpdateTime.substring(11, 16);
        }
        pTime1 = "---";
        if (pwin1Pool != null && pwin1Pool.lastUpdateTime != null && pwin1Pool.lastUpdateTime != '') {
            pTime1 = pwin1Pool.lastUpdateTime.substring(11, 16);
        }
    }
    return [pTime0, pTime1, winTime];
}
