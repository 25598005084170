import React from 'react';
import MatchTable from '../Common/MatchTable';
import { Remark } from '../Common/MatchBase';


const CRS = () => {
    return <section className='CRS'>
        <MatchTable betType='CRS' etBetType='ECS' />
        <Remark />
    </section>
}
export default CRS;