import React, { useEffect, useState, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';

import LoginEKBAAnswer from './LoginEKBAAnswer';
import { LoginMenu, TandCComp, TimeOutWarnDialog, ComingSoonLogoutDialog, FootballInplayMsgDialog } from './LoginMenu';
import { LoginAccountInfo, UserInfo, LogoutSuccessMsg, LoginIncorrect, LogoutReminder } from './LoginBase';
import { ConfirmationHeader, PanelAlupTemplate, BSPanelConfirmationTitle, ResendDoneDialog } from './BetSlipBase';
import { FBBetLineTemplate, HRBetLineTemplate, M6BetLineTemplate } from './BetLineTemplate';
import { useLoginLogout, getSessionLoginAccount, showEkbaQuestion } from './LoginHooks';
import { NewWindow, MaxOnePopupWindow, isEWinPage } from '../Common/home-common';
import bgMusic from '../../info/Include/Exclamation.wav';
import { useBetSlip, getBalanceValue } from './BetSlipHooks';
import { SetLoginBusyStatus, callLogoutApi } from './LoginHooks';
import { ConfigContext, QueryConfig, GetBetslipConfig } from '../Common/ConfigHelper';
import useBroadcastChannel from '../Common/hooks/useBroadcastChannel';
import { GlobalContext } from '../../StandardPage';
import { WATrackerTrackClickEvent, callWAReq, getWebIDHashed } from '../Common/utils/wa';
import { glassboxClearWebIDHashed, glassboxCustomEvent } from '../Common/utils/glassbox';
import { textFormatAmountFn, isEoD } from '../Common/GlobalFunc';
import { initSsoLogin, setSsoLogout } from './SsoHook';

import { Scrollbars } from 'react-custom-scrollbars-2';
import { GetOnlinePara } from '../Common/ChannelParaFunc';
import { initEkbaAPI, logout } from './BetSlipAPI';
import { callLogoutIbApi, useLoginLogout_IB, clearLoginSessionStorage } from './LoginIBHooks';
import { SurveyInBetSlip } from '../Header/Survey';
import { compressToUTF16, decompressFromUTF16 } from 'lz-string';
import { EarlySettlementShortcutNavigate } from '../Common/ui-components/EarlySettlementShortcutNavigate';
import { GetError } from '../Common/bs_str_table';

import { useWindowSize } from '../Common/CommonHooks';

import { secDialogFn } from '../Racing/Common/Common';

const CustomScrollbars = (props) => (
    <div style={{ flex: 1 }} className="betSlipScrollBars">
        <Scrollbars style={{ height: '100%' }} autoHeight={false} {...props} />
    </div>
);


const BetSlip = ({ product, useLoginLogout }) => {
    const { t, i18n } = useTranslation();
    const globalContext = useContext(GlobalContext);
    const configContext = useContext(ConfigContext);
    const bsConfig = GetBetslipConfig();
    const lang = i18n.language;
    const speedbetBasket = window.globalConfig.SPEEDBET_BASKET;
    const printRef = useRef();
    const pcardAccount = getSessionLoginAccount();
    const cashoutIP = globalContext.cashoutInProcess ? 'cashoutInProcess' : '';
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [isLoadTNCText, setIsLoadTNCText] = useState(false);
    const [FTInplayDialogDelay, setFTInplayDialogDelay] = useState(0);
    const [showOverlay, setShowOverlay] = useState(false);

    const { isMobileSize } = useWindowSize();

    const {
        clearBetSlip,
        betBaskets,
        setBetBaskets,
        panelAllupObj,
        isShowPreviewInfo,
        setIsShowPreviewInfo,
        resetPanelAllupFn,
        isShowFTInplayDialog,
        setIsShowFTInplayDialog,
        panelAllupCheckFn,
        updateBetTotal,
        getBetTotal,
        isShowError,
        setIsShowError,
        panelAllupData,
        setPanelAllupData,
        currentBasket,
        setCurrentBasket,
        clearCurrentBasket,
        sendBetRequest,
        isShowConfirmation,
        setIsShowConfirmation,
        betDoneFn,
        resendBetDoneFn,
        sendBetTime,
        confirmationStatus,
        balance,
        setBalance,
        disabledResendBtn,
        isSpeedbet,
        setIsSpeedbet,
        sendBetAPILoading,
        betBaskets_0,
        firstTimeFromJCRWRedirect
    } = useBetSlip();

    const {
        checkAccountPassword,
        showLoginEKBAAnswer,
        setShowLoginEKBAAnswer,
        loginEKBAQuestion,
        loginIncorrectMsg,
        checkEKBAAnswer,
        loginAccount,
        setLoginAccount,
        password,
        setPassword,
        resetLoginTimeout,
        accessToken,
        logoutStateFn,
        loginTimeoutRemind,
        isShowTODialog,
        setIsShowTODialog,
        isShowCSLogoutDialog,
        setIsShowCSLogoutDialog,
        isShowTandC,
        setIsShowTandC,
        userInfo,
        showUserInfo,
        setShowUserInfo,
        showLastLoginInfo,
        setShowLastLoginInfo,
        showAnswerErrorMsg,
        showLogoutSucMsg,
        setShowLogoutSucMsg,
        closeLoginIncorrectMsg,
        isShowLogoutBetText,
        setIsShowLogoutBetText,
        willOpenUrl,
        setWillOpenUrl,
        showLogoutReminder,
        setShowLogoutReminder,
        showEkbaQuestion,
        LoginAPILoading,
        setLoginAPILoading,
        showEwalletDialog,
        setShowEwalletDialog,
        sessionIdleTimeRef,
        warningIdleTimeRef,
        warningIdleTimeRef2,
        loginHigh
    } = useLoginLogout;

    const ssoInit = async () => {
        if (
            window.racingConfig['redirectIsReplaceWindow'] &&
            /* document.referrer.indexOf(window.globalConfig.JCRW_URL) > -1 && */
            firstTimeFromJCRWRedirect == 'true'
        ) {
            // JCRW redirect
            SetLoginBusyStatus(true);
            const islogon_b4redirect = sessionStorage.getItem('is_logon');
            const ssoGuid = sessionStorage.getItem('sso_guid');
            const data = await initSsoLogin();

            const ssoGuid2 = sessionStorage.getItem('sso_guid');
            const sso_sign_in_level = ssoGuid === ssoGuid2 ? sessionStorage.getItem('sso_sign_in_level') : 0;

            console.debug(islogon_b4redirect, sso_sign_in_level);
            if (islogon_b4redirect == 1 && sso_sign_in_level == 2) {
                // logged in b4 redirect and sso is still 2
                loginHigh();
                console.debug('ssoInit loginHigh')
            } else if (sso_sign_in_level > 0) {
                clearLoginSessionStorage();
                setLoginAccount(data);
                setPassword('********');
                setLoginAccount(data);
                setIsReadOnly(true);
                console.debug('ssoInit sso_sign_in_level > 0', data);
            } else {
                clearLoginSessionStorage();
                setIsReadOnly(false);
                console.debug('ssoInit sso_sign_in_level 0');
            }
            if (islogon_b4redirect == sessionStorage.getItem('is_logon')) {
                // set betbaskets
                const raw = betBaskets_0;
                console.debug('redirect from jcrw, setting betbasket...');
                if (raw) {
                    const betBasketsObj = JSON.parse(decompressFromUTF16(betBaskets_0));
                    setBetBaskets(betBasketsObj);
                    console.debug('betBasketsObj', betBasketsObj);
                }
            }
            sessionStorage.setItem('1stTimeFromJCRWRedirect', false);
            SetLoginBusyStatus(false);
        } else {
            console.debug('no JCRW redirect')
            // disabled JCRW redirect
            const data = await initSsoLogin();
            if (data != false) {
                setLoginAccount(data);
                setPassword('********');
                setIsReadOnly(true);
            } else {
                setIsReadOnly(false);
            }
        }
    };

    useEffect(() => {
        ssoInit();
        clearBetSlip(betBaskets);
    }, []);




    useEffect(() => {
        if (accessToken || showLoginEKBAAnswer || betBaskets[currentBasket]?.length > 0) {
            window.addEventListener('beforeunload', window.secDialogFn);
            window.addEventListener('unload', unloadEvent);
        } else {
            window.removeEventListener('beforeunload', window.secDialogFn);
        }
        return () => {
            window.removeEventListener('beforeunload', window.secDialogFn);
        };
    }, [accessToken, betBaskets, showLoginEKBAAnswer]);

    useEffect(() => {
        let state = globalContext.globalState.betslipState;
        if (showLoginEKBAAnswer) {
            state = bsConfig?.betslipState.EKBA || 0;
        } else if (isShowTandC) {
            state = bsConfig?.betslipState.NORMAL || 0;
            globalContext.globalRef.current.enableESButton = false;
        } else if (isShowTODialog) {
            state = bsConfig?.betslipState.IDLEALERT || 0;
        } else if (isShowPreviewInfo) {
            state = bsConfig?.betslipState.PREVIEWBET || 0;
        } else {
            state = bsConfig?.betslipState.NORMAL || 0;
        }
        globalContext.updateGlobalState({ betslipState: state });
    }, [showLoginEKBAAnswer, isShowTandC, isShowTODialog, isShowPreviewInfo]);

    useEffect(() => {}, [globalContext.globalState.betslipState]);

    const unloadEvent = (e) => {
        MaxOnePopupWindow(true);
    };

    useEffect(() => {
        updateBetTotal(betBaskets);
    }, [betBaskets, product, currentBasket]);

    useEffect(() => {}, [isShowError]);

    useEffect(() => {
        if (isShowPreviewInfo && betBaskets[currentBasket].length === 0) {
            backBetlines();
        }
    }, [betBaskets, isShowPreviewInfo]);

    useEffect(() => {
        if (window.racingConfig['redirectIsReplaceWindow']) {
            // filter out successful bet in betBaskets
            let filtered_betBaskets = betBaskets.map( (basket) => basket.filter((bet) => !(bet.resultCode === 0 && bet.isCounterOffer == false)) )
            sessionStorage.setItem('betBaskets', compressToUTF16(JSON.stringify(filtered_betBaskets)));
            console.debug('setting betBaskbets for jcrw redirect', filtered_betBaskets)
        }
    }, [betBaskets]);

    // useEffect(() => {
    //     console.debug('refreshBalance', (showUserInfo && balance == null), showUserInfo, balance)
    //     if (!window.globalConfig.IS_IB && isShowTandC && showUserInfo) {
    //         refreshBalance();
    //     } else if (showUserInfo && balance == null) {
    //         console.debug('refreshBalance')
    //         refreshBalance();
    //     }
    // }, [isShowTandC, showUserInfo]);

    useEffect(() => {
        if (sessionStorage.getItem('access_token') == '') {
            setLogoutUI(false, true);
        }
        if (sessionStorage.getItem('access_token') == null) {
            setLogoutUI(false, false);
        }
    }, [sessionStorage.getItem('access_token')]);

    useEffect(() => {
        checkIsEod();
    }, [sessionStorage.getItem('OOSMsgId')]);

    const onClickOverlay = () => {
        // if (window.screen.width > 559) { why ? cause SQ0248-1589
        // } else {
        document.getElementById('divBetSlipNormal').style.left = '105vw';
        document.getElementById('betslip-over-lay').style.left = '105vw';
        document.body.style.overflow = null;

        setTimeout(function () {
            document.getElementById('betslip-container').style.display = 'none';
        }, 300);
        // }
    };

    useEffect(() => {
        if(!isMobileSize && document.getElementById("divBetSlipNormal").style.left) {
            document.getElementById('divBetSlipNormal').style.left = null;
            document.getElementById('betslip-over-lay').style.left = null;
            document.body.style.overflow = null;
            document.getElementById('betslip-container').style.display = null;
        }
    }, [isMobileSize]);

    const onInputLoginAccount = (e) => {
        let val = e.target.value;
        if (isMobileSize && e.target.getAttribute('data-init') == 1) {
            if (e.nativeEvent.data != null) {
                val = e.nativeEvent.data;
            }
            e.target.setAttribute('data-init', 0);
        }
        setLoginAccount(val);
    };

    const onInputPassword = (e) => {
        let val = e.target.value;
        if (isMobileSize && e.target.getAttribute('data-init') == 1) {
            if (e.nativeEvent.data != null) {
                val = e.nativeEvent.data;
            }
            e.target.setAttribute('data-init', 0);
        }
        setPassword(val);
    };

    const loginInputFocus = (e, type) => {
        if (isMobileSize) {
            e.preventDefault();
            e.target.setAttribute('data-init', 1);
            e.target.setSelectionRange(10, 10);
            setTimeout(function () {
                e.target.setSelectionRange(10, 10);
            });
        }
        document.getElementById(type).setAttribute('placeholder', '');
    };
    const loginInputBlur = (type) => {
        document
            .getElementById(type)
            .setAttribute(
                'placeholder',
                type === 'login-account-input'
                    ? t('LB_BS_LOGIN_ACCOUNT_PLACEHOLDER')
                    : t('LB_BS_LOGIN_PASSWORD_PLACEHOLDER')
            );
    };
    const goToFAQFn = () => {
        let url = t('LB_BS_LOGIN_FAQ_URL');
        NewWindow(url, '', 800, 640, 1, 1);
    };

    const ssoExpired = (ssoLastExtendError = 'SSO_SIGN_OUT_PASSIVE', showAlert = true, clearSSO = true) => {
        console.debug('ssoExpired', ssoLastExtendError, showAlert, clearSSO);
        if (clearSSO) {
            setIsReadOnly(false);
            setPassword('');
            setLoginAccount('');
            setLogoutUI(false, false);
            setSsoLogout();
        } else {
            setLogoutUI(false, false);
            ssoInit();
        }

        clearLoginSessionStorage();

        if (showAlert) {
            const errorText = t('LB_BS_' + ssoLastExtendError);
            window.alert(errorText);
        }
    };

    const handleClickSignIn = async () => {
        setLoginAPILoading(true);
        if (!(await checkIsEodAsync())) {
            if (window.globalConfig.IS_IB) {
                if (sessionStorage.getItem('sso_web_id')?.length > 0) {
                    if ((await showEkbaQuestion(true)) == false) {
                        ssoExpired();
                    }
                } else {
                    await checkAccountPassword(loginAccount, password);
                }
            } else {
                if (sessionStorage.getItem('access_token') && sessionStorage.getItem('refresh_token')) {
                    if ((await showEkbaQuestion(sessionStorage.getItem('access_token'))) == false) {
                        ssoExpired();
                    }
                } else {
                    await checkAccountPassword(loginAccount, password);
                }
            }
        }
        setLoginAPILoading(false);
    };
    const handleClickLogout = () => {
        sessionStorage.setItem('setShowLogoutReminder', true)
        setShowLogoutReminder(true);
    };
    const calcTimeOutLogout = () => {
        setLogoutUI(true, true);
    };

    const handleClickLoginEKBAAnswerConfirm = async (answer) => {
        setLoginAPILoading(true);
        const isEod = await checkIsEod();
        if (!isEod) {
            await checkEKBAAnswer(answer, setLoginAPILoading);
            resetMyBetSlipLoginInfo();
            !window.globalConfig.IS_IB && setLoginAPILoading(false);
        } else {
            setLoginAPILoading(false);
        }
    };

    const handleClickLoginEKBAAnswerCancel = () => {
        setShowLoginEKBAAnswer(false);
        SetLoginBusyStatus(false);

        if (!isReadOnly) {
            const accessToken = sessionStorage.getItem('access_token');
            sessionStorage.removeItem('access_token');
            sessionStorage.removeItem('refresh_token');
            sessionStorage.removeItem('customerSegment');
            sessionStorage.removeItem('hWebID');
            sessionStorage.removeItem('customerHash');

            setLoginAccount('');
            setPassword('');
            setIsReadOnly(false);

            logout(accessToken);
        }
    };

    const handleClickLogoutEKBAAnswer = async () => {
        setShowLoginEKBAAnswer(false);
        SetLoginBusyStatus(false);

        const accessToken = sessionStorage.getItem('access_token');

        if (window.globalConfig.IS_IB) {
            await callLogoutIbApi();
            ssoExpired('', false, true);
            clearLoginSessionStorage();
        } else {
            callLogoutApi(accessToken);
        }

        setLoginAccount('');
        setPassword('');
        setIsReadOnly(false);

        sessionStorage.removeItem('access_token');
        sessionStorage.removeItem('refresh_token');
        sessionStorage.removeItem('customerSegment');
        sessionStorage.removeItem('hWebID');
        sessionStorage.removeItem('customerHash');
        globalContext.esHook.clearEsStateAll();
    };

    const handleClickResendBtn = () => {
        resetLoginTimeout(false);
        resendBetDoneFn();
    };

    const closeCandTFn = () => {
        setIsShowTandC(false);
        globalContext.globalRef.current.enableESButton = true;
        if (accessToken) {
            // setShowLastLoginInfo(true)
            // setShowUserInfo(false)
            // setTimeout(() => {
            //     setShowLastLoginInfo(false)
            //     setShowUserInfo(true)
            // }, 5000)
            setShowUserInfo(true);
            setPassword('');
            setLoginAccount('');
        }
    };

    const closeTOdialogFn = () => {
        setIsShowTODialog(false);
    };

    const closeCSLogoutdialogFn = () => {
        setIsShowCSLogoutDialog(false);
        if (loginTimeoutRemind === '3') {
            callWAReq();
            logoutStateFn();
            sessionStorage.removeItem('customerSegment');
            sessionStorage.removeItem('hWebID');
            sessionStorage.removeItem('customerHash');
        } else if ((loginTimeoutRemind === '1' || loginTimeoutRemind === '2') && isMobileSize) {
            document.getElementById('betslip-container').style.display = 'inherit';
            document.getElementById('divBetSlipNormal').style.left = 'calc(100vw - 90vw)';
            document.getElementById('betslip-over-lay').style.left = '0';
            // document.body.style.overflow = "hidden";
        }
    };

    const onCancelLogout = () => {
        setShowLogoutReminder(false);
        setIsShowLogoutBetText(false);
    };

    const onConfirmLogout = async () => {
        if (isShowLogoutBetText) {
            goToNewWindow();
        } else {
            setShowLogoutSucMsg(true);
        }
        await setLogoutUI(true, true);
        WATrackerTrackClickEvent('betlogoutbox-home-box');
        glassboxCustomEvent('Logout_OK_button');
    };

    const setLogoutUI = async (isCallLogoutApi, isShowLogoutReminder) => {
        clearBetSlip(betBaskets);
        setShowLogoutReminder(isShowLogoutReminder);
        closeCandTFn();
        setIsShowConfirmation(false);
        isShowTODialog && closeTOdialogFn();
        if (isCallLogoutApi) {
            if (window.globalConfig.IS_IB) {
                await callLogoutIbApi();
                clearLoginSessionStorage();
                ssoExpired('', false, true);
            } else {
                callLogoutApi(accessToken);
            }

            setIsReadOnly(false);
        }
        logoutStateFn();
        globalContext.esHook.clearEsStateAll();
        setIsSpeedbet(window.globalConfig.IS_SPEEDBET);
    };

    const handleClickMenuItem = async (data) => {
        let url = lang === 'en' ? data.uE : data.uC;
        if (data.secConfirm && data.secConfirm === '1') {
            const flag = window.confirm(t('LB_BS_LOGIN_FORGOT_ANSWER_MSG'));
            if (flag) {
                window.location.href = url;
            }
        } else {
            if (data.pu == '0') {
                window.location.href = url;
            } else {
                const w = data.w ? Math.min(data.w, window.innerWidth) : Math.min(800, window.innerWidth);
                const h = data.h ? data.h : window.innerWidth > 768 ? 600 : window.innerHeight;
                if (url.indexOf('acctstmt') > -1 || url.indexOf('eft') > -1) {
                        MaxOnePopupWindow(false, url, url.indexOf('acctstmt') > -1 ? 'acctstmt' : 'eft', w, h, 1, 1);
                } else {
                    NewWindow(url, 'betslip', w, h, 1, 1);
                }
            }
        }
    };

    const goToNewWindow = () => {
        NewWindow(willOpenUrl, '', 800, 640, 1, 1);
        setIsShowLogoutBetText(false);
    };

    const resetMyBetSlipLoginInfo = (obj) => {};

    const hasBetlines = () => {
        return betBaskets[currentBasket].length > 0;
    };

    const canSendBet = (betLines) => {
        if (accessToken !== null && accessToken?.length > 0) {
            return hasBetlines();
        }
        return false;
    };

    const gotoPreview = () => {
        resetLoginTimeout(false);
        WATrackerTrackClickEvent('betslip-sendbet-01');
        glassboxCustomEvent('Place_Bet');
        setIsShowPreviewInfo(true);
    };

    const backBetlines = () => {
        setIsShowPreviewInfo(false);
        clearCounterOfferData();
        resetPanelAllupFn();
    };

    const clearCounterOfferData = () => {
        betBaskets[currentBasket]?.map((item) => {
            item.counterOfferAmountChange = false;
            item.counterOfferOddsChange = false;
            if (item.counterOfferData) {
                delete item['counterOfferData'];
            }
        });
    };

    const betConfirm = async () => {
        resetLoginTimeout(false);
        if (!window.globalConfig.IS_IB) {
            const isHasDelayInBet = betBaskets[currentBasket].find((item) => {
                return item.betObj?.inplayDelay;
            });
            if (isHasDelayInBet) {
                setIsShowFTInplayDialog(true);
            }
            sendBetRequest(accessToken);
        } else {
            const sendBetResponse = await sendBetRequest(accessToken);
            if (sendBetResponse?.sendbet_status == '-2') {
                ssoExpired(sendBetResponse.sso_last_extend_error, true, false);
                return;
            }
            if (sendBetResponse?.delay_time_in_seconds) {
                // inplay delay
                setIsShowFTInplayDialog(true);
                setFTInplayDialogDelay(sendBetResponse?.delay_time_in_seconds);
                const seqNo = sendBetResponse.delay_request_seq_no;
                setTimeout(async () => {
                    const rsp = await sendBetRequest(accessToken, seqNo);
                    resetLoginTimeout(false);
                    const INPLAY_RETRY_SECOND = 4; // HARDCODE
                    if (rsp?.sendbet_error && rsp?.sendbet_error.indexOf('159') >= 0) {
                        setTimeout(async () => {
                            await sendBetRequest(accessToken, seqNo, true);
                            resetLoginTimeout(false);
                            setIsShowFTInplayDialog(false);
                        }, INPLAY_RETRY_SECOND * 1000);
                    } else {
                        setIsShowFTInplayDialog(false);
                    }
                }, sendBetResponse?.delay_time_in_seconds * 1000);
            }
        }

        document.getElementById('betslip-panel').scrollTop = 0;
        glassboxCustomEvent('Send_Bet_Confirm');
    };

    const onClickCloseError = () => {
        setIsShowError(false);
    };

    const onClickSpeedbetTab = (index, e) => {
        if (isShowPreviewInfo) return;
        resetPanelAllupFn('all');
        setCurrentBasket(index);
    };

    const handleClickDone = () => {
        if (confirmationStatus.status === 'unknown' && confirmationStatus.accepted === 0) {
            const w = Math.min(800, window.innerWidth);
            const h = window.innerWidth > 768 ? 640 : window.innerHeight;
            const url = `/${lang}/betslip/acctstmt/transactionRecords`;
            MaxOnePopupWindow(false, url, '', w, h, 1, 1);
        }
        resetLoginTimeout(false);
        betDoneFn();

        if (sessionStorage.getItem('_OOSEngContent')) {
            sessionStorage.setItem('OOSEngContent', sessionStorage.getItem('_OOSEngContent'));
            sessionStorage.removeItem('_OOSEngContent');
        }
        if (sessionStorage.getItem('_OOSChiContent')) {
            sessionStorage.setItem('OOSChiContent', sessionStorage.getItem('_OOSChiContent'));
            sessionStorage.removeItem('_OOSChiContent');
        }
        if (sessionStorage.getItem('_OOSMsgId')) {
            sessionStorage.setItem('OOSMsgId', sessionStorage.getItem('_OOSMsgId'));
            sessionStorage.removeItem('_OOSMsgId');
            setLogoutUI(true, true);
        }
    };

    const onBeforePrint = () => {
        const iframe = document.querySelector('#printWindow');
        iframe.contentWindow.document
            .querySelector('#divBetSlipNormal')
            .setAttribute('class', 'divBetSlipNormal-print');
    };

    const getConfirmationTotalAmount = () => {
        let total = 0;
        betBaskets[currentBasket].forEach((item, index) => {
            total += Number(item.resultAmount);
            /*
            if (item.resultCode === 0 && item.isCounterOffer == false) {
                if (item.prod === 'M6') {
                    const draw = item.betObj.multidraw || 1;
                    total += draw * item.unitBet * item.betObj.noOfBets;
                } else if (item.prod === 'FB') {
                    total += item.unitBet * (item.betObj.noOfBets || item.betObj.noOfComb);
                } else {
                    total += item.betObj.isFlexi
                        ? item.betObj.betTotal
                        : item.unitBet * (item.betObj.noOfBets || item.betObj.noOfComb);
                }
            }
            */
        });
        return total;
    };

    // const [balanceLoaded, setBalanceLoaded] = useState(false);
    const refreshBalance = async () => {
        if (sessionStorage.getItem('is_logon') == 1) {
            setBalance('...');
            const newValue = await getBalanceValue();
            if (newValue?.balanceStatus == '-2') {
                ssoExpired(newValue.sso_last_extend_error, true, false);
                return;
            }

            if (!isNaN(newValue?.balance) && newValue?.balanceStatus == '0') {
                setBalance(newValue.balance.toLocaleString(undefined, { minimumFractionDigits: 2 }));
            } else {
                setBalance('---');
            }
            resetLoginTimeout(false);
        }
        // setBalanceLoaded(true)
    };

    const [ssoLogoutNow, setSsoLogoutNow] = useState();

    useBroadcastChannel('refreshBalance', setBalance);
    useBroadcastChannel('ssoLogoutNow', setSsoLogoutNow);
    useBroadcastChannel('resetLoginTimeout', resetLoginTimeout);

    useEffect(() => {
        if (ssoLogoutNow == 'ssoLogoutNow') {
            ssoExpired('', false, true);
            setSsoLogoutNow('');
        }
    }, [ssoLogoutNow]);

    const hasHrOrFb = () => {
        return betBaskets[currentBasket].some((betLine) => {
            if (betLine.prod === 'FB' || betLine.prod === 'HR') {
                return true;
            }
        });
    };

    const checkIsEodAsync = async () => {
        await QueryConfig('channelParas', window.globalConfig.CHANNEL_PARA_URL);
        return setEodUi();
    };

    const checkIsEod = async () => {
        await QueryConfig('channelParas', window.globalConfig.CHANNEL_PARA_URL);
        return setEodUi();
    };

    const setEodUi = () => {
        if (isEoD()) {
            setLogoutUI(false, false);
            setShowLoginEKBAAnswer(false);
            setPassword('');
            setLoginAccount('');
            return true;
        } else {
            return false;
        }
    };

    const getProduckCTAClassName = () => {
        const product = globalContext?.globalState?.product;
        switch (product) {
            case 'racing':
                return 'cta_hr';
            case 'football':
                return 'cta_fb';
            case 'marksix':
                return 'cta_m6';
            default:
                return 'cta_brand';
        }
    };

    const isPcardUser = () => {
        if (window.globalConfig.IS_IB) {
            return (
                window.sessionStorage.getItem('PCardTier') != null &&
                window.sessionStorage.getItem('PCardTier') != '' &&
                window.sessionStorage.getItem('PCardTier') != 'NonPcard'
            );
        } else {
            return (
                sessionStorage.getItem('login_priorityCardTier') &&
                sessionStorage.getItem('login_priorityCardTier') !== 'NonPcard'
            );
        }
    };

    const onClickEwalletLater = () => {
        setShowEwalletDialog(false);
        setLoginAccount('');
        setPassword('');
    };

    const onClickEwalletUpgrade = () => {
        setShowEwalletDialog(false);
        window.open(t('LB_BS_EWALLET_UPGRADE_URL'), '_blank');
        setLoginAccount('');
        setPassword('');
    };

    const getEodMessage = () => {
        const { t } = useTranslation();
        let message =
            i18n.language === 'en' ? sessionStorage.getItem('OOSEngContent') : sessionStorage.getItem('OOSChiContent');
        if (message == null || message.length <= 0) {
            message = t('LB_BS_EOD');
        }
        return message;
    };

    const confirmDelSlip = () => {
        const flag = window.confirm(GetError('1304', i18n.language));
        if (flag) {
            clearCurrentBasket();
            glassboxCustomEvent('Clear_Slip');
        }
    };

    return (
        <>
            {showOverlay && <div className="overlay" />}
            <div className={`betslip-container ${isEWinPage() ? 'isEWinPage' : ''}`} id="betslip-container">
                <div role='button' tabIndex='0' className="betslip-over-lay" id="betslip-over-lay" onClick={onClickOverlay}></div>
                <div id="divBetSlipNormal" ref={printRef}>
                    <div className={`eod ${isEoD() ? 'show' : ''}`}>
                        <div
                            className="eod-msg"
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(getEodMessage())
                            }}
                        />
                    </div>
                    <div
                        className={`login bsBgColor ${accessToken ? 'logonState' : ''} ${
                            isPcardUser() || isSpeedbet ? 'pcard' : ''
                        }`}
                        id="login"
                    >
                        <div className="login-account">
                            {!accessToken && (
                                <LoginAccountInfo
                                    pwd={password}
                                    loginAccount={loginAccount}
                                    onInputPwd={onInputPassword}
                                    onInputLoginAccount={onInputLoginAccount}
                                    loginInputFocus={loginInputFocus}
                                    loginInputBlur={loginInputBlur}
                                    onClickOverlay={onClickOverlay}
                                    goToFAQFn={goToFAQFn}
                                    handleClickSignIn={handleClickSignIn}
                                    isReadOnly={isReadOnly}
                                    APILoading={LoginAPILoading}
                                    showLoginEKBAAnswer={showLoginEKBAAnswer}
                                />
                            )}
                            {/* {accessToken && showLastLoginInfo && <LastLoginInfo onClickOverlay={onClickOverlay} goToFAQFn={goToFAQFn} />} */}
                            {accessToken && showUserInfo && (
                                <UserInfo
                                    onClickOverlay={onClickOverlay}
                                    handleClickLogout={handleClickLogout}
                                    userInfo={userInfo}
                                    balance={balance}
                                    refreshBalance={refreshBalance}
                                    isPcardUser={isPcardUser}
                                />
                            )}
                            <LogoutReminder
                                showReminder={showLogoutReminder}
                                onCancel={onCancelLogout}
                                onConfirm={onConfirmLogout}
                                isShowLogoutBetText={isShowLogoutBetText}
                            />
                            <LogoutSuccessMsg showMsg={showLogoutSucMsg} />
                        </div>
                        <LoginMenu
                            loginKey={accessToken}
                            calcTimeOutLogout={calcTimeOutLogout}
                            handleClickMenuItem={handleClickMenuItem}
                            isReadOnly={isReadOnly}
                            isSpeedbet={isSpeedbet}
                        />
                    </div>
                    {isSpeedbet && !isShowPreviewInfo ? (
                        <div className="speedbet-tab">
                            {speedbetBasket.map((basket, index) => {
                                return [
                                    <div
                                        className={index == currentBasket ? 'active' : ''}
                                        onClick={(e) => onClickSpeedbetTab(index, e)}
                                    >
                                        {basket}{' '}
                                        {betBaskets[index]?.length > 0 ? '(' + betBaskets[index]?.length + ')' : ''}
                                    </div>
                                ];
                            })}
                        </div>
                    ) : null}
                    <ConfirmationHeader
                        isShow={isShowConfirmation}
                        sendBetTime={sendBetTime}
                        confirmationStatus={confirmationStatus}
                    />
                    <div
                        className={`dialogArea ${isShowTandC ? 'dialogAreaMBShow' : 'dialogAreaMBHide'} ${
                            isSpeedbet ? 'speedbetTandC' : ''
                        }`}
                    >
                        {isShowTandC && (
                            <TandCComp
                                setShowOverlay={setShowOverlay}
                                showOverlay={showOverlay}
                                loginKey={accessToken}
                                closeCandTFn={closeCandTFn}
                                isLoadTNCText={isLoadTNCText}
                                setIsLoadTNCText={setIsLoadTNCText}
                            />
                        )}
                        {isShowTODialog && (
                            <TimeOutWarnDialog
                                closeTOdialogFn={closeTOdialogFn}
                                resetLoginTimeout={resetLoginTimeout}
                                loginTimeoutRemind={loginTimeoutRemind}
                                calcTimeOutLogout={calcTimeOutLogout}
                                sessionIdleTimeRef={sessionIdleTimeRef}
                                warningIdleTimeRef={warningIdleTimeRef}
                                warningIdleTimeRef2={warningIdleTimeRef2}
                            />
                        )}
                    </div>
                    <CustomScrollbars>
                        <div
                            className={`panel ${isShowPreviewInfo ? 'previewPanel' : ''} ${
                                isShowConfirmation ? 'confirmationPanel' : ''
                            } ${panelAllupObj?.uniqueMatchIdList?.length > 0 ? 'betslipPanelalupModel' : ''} `}
                            id="betslip-panel"
                        >
                            {loginIncorrectMsg && (
                                <LoginIncorrect messageCode={loginIncorrectMsg} onClose={closeLoginIncorrectMsg} />
                            )}
                            {showEwalletDialog && (
                                <div className="login-answer-container">
                                    <div className="login-answer-title">{t('LB_BS_EWALLET_TITLE')}</div>
                                    <div className="login-answer-content">
                                        <div className="login-question">{t('LB_BS_EWALLET_DESC')}</div>
                                        <div className="button-box ">
                                            <div className="bsBgColor cta_brand" onClick={onClickEwalletLater}>
                                                {t('LB_BS_EWALLET_LATER')}
                                            </div>
                                            <div className="cta_yellow ewallet_upgrade" onClick={onClickEwalletUpgrade}>
                                                {t('LB_BS_EWALLET_UPGRADE')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {showLoginEKBAAnswer && (
                                <LoginEKBAAnswer
                                    loginEKBAQuestion={loginEKBAQuestion}
                                    showAnswerErrorMsg={showAnswerErrorMsg}
                                    onClickCancel={handleClickLoginEKBAAnswerCancel}
                                    onClickConfirm={handleClickLoginEKBAAnswerConfirm}
                                    onClickLogout={handleClickLogoutEKBAAnswer}
                                    isSsoLogin={isReadOnly}
                                    APILoading={LoginAPILoading}
                                />
                            )}
                            {isShowCSLogoutDialog && (<ComingSoonLogoutDialog
                                showMsg={isShowCSLogoutDialog}
                                loginTimeoutRemind={loginTimeoutRemind}
                                closeCSLogoutdialogFn={closeCSLogoutdialogFn}
                                logoutStateFn={logoutStateFn}
                                setPassword={setPassword}
                                setLoginAccount={setLoginAccount}
                                setIsReadOnly={setIsReadOnly}
                                sessionIdleTimeRef={sessionIdleTimeRef}
                                warningIdleTimeRef={warningIdleTimeRef}
                                warningIdleTimeRef2={warningIdleTimeRef2}
                            />)}
                            {isShowPreviewInfo && !isShowConfirmation && (
                                <div className="preview-title">{t('LB_BETSLIP_PREVIEWBET')}</div>
                            )}
                            <BSPanelConfirmationTitle
                                printContent={printRef.current}
                                onBeforePrint={onBeforePrint}
                                isShow={isShowConfirmation}
                                confirmationStatus={confirmationStatus}
                            />
                            {isShowError ? (
                                <div className="amount-error error-overlay" onClick={onClickCloseError}>
                                    <div>{t('LB_RBC_AMOUNTERROR')}</div>
                                    {/* <div className="switch-btn-icon open-btn-icon" onClick={onClickCloseError}></div> */}
                                </div>
                            ) : null}
                            {/* bet lines */}
                            {betBaskets[currentBasket].map((betLine) => {
                                if (betLine.prod === 'FB') {
                                    return (
                                        <FBBetLineTemplate
                                            betLineData={betLine}
                                            key={betLine.id}
                                            panelAllupObj={panelAllupObj}
                                            panelAllupCheckFn={panelAllupCheckFn}
                                            setIsShowError={setIsShowError}
                                            isShowPreviewInfo={isShowPreviewInfo}
                                            isShowConfirmation={isShowConfirmation}
                                        />
                                    );
                                }
                                if (betLine.prod === 'M6') {
                                    return (
                                        <M6BetLineTemplate
                                            betLineData={betLine}
                                            key={betLine.id}
                                            panelAllupObj={panelAllupObj}
                                            isShowPreviewInfo={isShowPreviewInfo}
                                            isShowConfirmation={isShowConfirmation}
                                        />
                                    );
                                }
                                if (betLine.prod === 'HR') {
                                    return (
                                        <HRBetLineTemplate
                                            betLineData={betLine}
                                            key={betLine.id}
                                            panelAllupObj={panelAllupObj}
                                            panelAllupCheckFn={panelAllupCheckFn}
                                            setIsShowError={setIsShowError}
                                            isShowPreviewInfo={isShowPreviewInfo}
                                            isShowConfirmation={isShowConfirmation}
                                        />
                                    );
                                }
                            })}
                            {isShowConfirmation && <SurveyInBetSlip />}
                        </div>
                    </CustomScrollbars>
                    <EarlySettlementShortcutNavigate
                        betData={betBaskets[currentBasket]}
                        isShowConfirmation={isShowConfirmation}
                    />
                    {!isShowPreviewInfo && (
                        <div className="control">
                            {
                                /* (!panelAllupObj || panelAllupObj?.uniqueMatchIdList?.length < 1) && */ <div className="add-slip">
                                    <div>
                                        <div>{t('LB_BS_TOTAL_NO_OF_BETS')}</div>
                                        <div className="money">
                                            {textFormatAmountFn(getBetTotal()?.totalBet, 0) || 0}
                                        </div>
                                    </div>
                                    <div>
                                        <div>{t('LB_BS_Total_AMOUNT')}</div>
                                        <div className="money">
                                            ${textFormatAmountFn(getBetTotal()?.totalAmount, 0) || 0}
                                        </div>
                                    </div>
                                    <div className={`betSlipBottomBtnArea ${cashoutIP}`}>
                                        <div>
                                            <button
                                                aria-label={t('LB_BS_AS_DETELE_ALL_BETS')}
                                                disabled={!hasBetlines()}
                                                className={`delete background2 ${getProduckCTAClassName()}`}
                                                onClick={() => {
                                                    confirmDelSlip();
                                                }}
                                            ></button>
                                        </div>
                                        <div>
                                            <button
                                                disabled={!canSendBet(betBaskets)}
                                                className="send background2 cta_yellow"
                                                onClick={gotoPreview}
                                            >
                                                {t('LB_SEND_BET')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }
                            {hasHrOrFb() && (
                                <PanelAlupTemplate
                                    panelAllupObj={panelAllupObj}
                                    panelAllupData={panelAllupData}
                                    setPanelAllupData={setPanelAllupData}
                                    resetPanelAllupFn={resetPanelAllupFn}
                                    cashoutIP={cashoutIP}
                                />
                            )}
                        </div>
                    )}
                    {isShowPreviewInfo && (
                        <div className="preview-bot-area">
                            <div className="preview-dfjs preview-bot">
                                <div className="font13">{t('LB_BS_Total_AMOUNT')}</div>
                                {isShowConfirmation ? (
                                    <div className="font15">
                                        ${textFormatAmountFn(getConfirmationTotalAmount() || 0, 0, false)}
                                    </div>
                                ) : (
                                    <div className="font15">
                                        ${textFormatAmountFn(getBetTotal()?.totalAmount || 0, 0, false)}
                                    </div>
                                )}
                            </div>
                            {isShowConfirmation ? (
                                <div
                                    className={`betslip-confirmation-done ${
                                        confirmationStatus.isResendBet ? 'resendBetBtn' : ''
                                    }`}
                                >
                                    {confirmationStatus.isResendBet ? (
                                        <>
                                            <ResendDoneDialog onClickConfirm={handleClickDone} />
                                            <button
                                                className={`confirm cta_yellow  ${disabledResendBtn ? 'disabled' : ''}`}
                                                onClick={handleClickResendBtn}
                                                disabled={disabledResendBtn}
                                            >
                                                {t('LB_BS_RESENDBET_SEND')}
                                            </button>
                                        </>
                                    ) : (
                                        <button
                                            className={`${
                                                confirmationStatus.status === 'unknown' &&
                                                confirmationStatus.accepted === 0
                                                    ? 'confirm_tr'
                                                    : 'confirm'
                                            } cta_yellow ${
                                                confirmationStatus.isResendBet && disabledResendBtn ? 'disabled' : ''
                                            }`}
                                            onClick={handleClickDone}
                                            disabled={confirmationStatus.isResendBet && disabledResendBtn}
                                        >
                                            {confirmationStatus.status === 'unknown' && confirmationStatus.accepted == 0
                                                ? t('LB_BETSLIP_TR')
                                                : t('LB_BETSLIP_DONE')}
                                        </button>
                                    )}
                                </div>
                            ) : (
                                <div className="preview-dfjs">
                                    <div>
                                        <button
                                            className={`back ${getProduckCTAClassName()} background2`}
                                            onClick={backBetlines}
                                            disabled={sendBetAPILoading}
                                        >
                                            {t('LB_BETSLIP_PREVIEWBACK')}
                                        </button>
                                    </div>
                                    <div>
                                        <button
                                            className="confirm cta_yellow"
                                            disabled={sendBetAPILoading}
                                            onClick={betConfirm}
                                        >
                                            {t('LB_BETSLIP_PREVIEWCONFIRM')}
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    {isShowFTInplayDialog && FTInplayDialogDelay && (
                        <FootballInplayMsgDialog
                            setIsShowFTInplayDialog={setIsShowFTInplayDialog}
                            FTInplayDialogDelay={FTInplayDialogDelay}
                        />
                    )}
                </div>
                <audio
                    style={{ width: 50 }}
                    className="bgMusic"
                    src={bgMusic}
                    controls="controls"
                    preload="true"
                    id="music1"
                    type="audio/wav"
                ></audio>
            </div>
        </>
    );
};
export default BetSlip;
