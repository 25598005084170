import moment from 'moment';
import { useApolloClient } from '@apollo/client';
import { getPoolInvQuery } from '../DataQuery';
import { isStartSellpmPool } from '../../Common/Common';
import { ClearBetRaces, ClearBetByBetTypeAndRaces } from '../../Common/RacingBetline';

const useLoadPoolInv = (contentRef, setContent) => {
    const { query } = useApolloClient();

    const loadPoolInv = (oddsTypes, allRaces) => {
        return Promise.all([query({
            query : getPoolInvQuery(),
            variables: {
                "date": contentRef.current.date,
                "venueCode": contentRef.current.venue,
                "raceNo": allRaces ? 0 : contentRef.current.raceNo,
                "oddsTypes": oddsTypes
            },
            fetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: true
        })])
        .then(([results]) => {
            if(results.errors){
                throw new Error(results.errors?.[0]?.message)
            }
            setPoollInvDataToContent(results);
        });
    }

    const setPoollInvDataToContent = (queryResult) => {
        if(!queryResult || !queryResult.data) return
        let invData = queryResult.data?.raceMeetings?.[0]?.poolInvs || [];
        invData.forEach(poolInv => {
            const idx = contentRef.current.meeting.poolInvs.findIndex(x=> x.id===poolInv.id);
            if ( idx > -1 ) {
            //    let oldTime = moment(contentRef.current.meeting.poolInvs[idx].lastUpdateTime);
            //    let newTime = moment(poolInv.lastUpdateTime);
            //    if ( oldTime < newTime ) {
                    contentRef.current.meeting.poolInvs[idx] = poolInv;
                    if(poolInv != null) {
                        if(!isStartSellpmPool(poolInv)) {
                            poolInv.leg.races.forEach(raceNo =>{
                                ClearBetByBetTypeAndRaces(poolInv.oddsType, raceNo);
                            })
                        }
                    }
            //    }
            }
            else {
                contentRef.current.meeting.poolInvs.push(poolInv);
            }
        });
        let totalInv = queryResult.data?.raceMeetings?.[0]?.totalInvestment;
        if ( totalInv && parseInt(contentRef.current.meeting.totalInvestment) < parseInt(totalInv) ) {
            contentRef.current.meeting.totalInvestment = totalInv;
        }
        setContent({ ...contentRef.current });
    }

    return {
        loadPoolInv
    }
}

export default useLoadPoolInv