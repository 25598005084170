import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { textFormatAmountFn } from '../../../../Common/GlobalFunc'

const FPSChargeTips = ({ props }) => {
    const { backFn, continueFn,depositPara, message , hideTitle,title, continueBtnText,grayAreaMessage, backBtnText, isConfirmBtn, isShowBtnArea = true, isHadPPS = false } = props;
    const { t } = useTranslation()
    const [chargeAmt,setChargeAmt]=useState(1)
    useEffect(() => {
        if(depositPara?.chargeAmtFps){
            setChargeAmt(depositPara.chargeAmtFps)
        }
    }, [depositPara])


    const defaultMsg = <>
            <div className='mrB24'>{t('LB_BS_FT_FPSCHARGETIPS1').replace('{0}', textFormatAmountFn(chargeAmt))}</div>
            <div>{t('LB_BS_FT_FPSCHARGETIPS2')}</div>
            </>

    const msgContent = (message && message()) || defaultMsg

    const continueText = continueBtnText || t('LB_BS_FT_CONTINUE')
    const backText = backBtnText || t('LB_BS_FT_CANCEL')

    return <div className="deposit-prev FT-valid-content">
        {!hideTitle &&<div className='deposit-prev-title'>
            <div>{title || t('LB_BS_FT_FPSCHARGETITLE')}</div>
        </div>}
        <div className={`fps-charge-tips `}>
            {msgContent}
        </div>
        {grayAreaMessage && <div className='gray-area-message'>
            {grayAreaMessage()}
        </div>}
        {isShowBtnArea &&
            <div className='deposit-btnArea'>
                {backFn && <div className='deposit-btn nextBtn msgBtn' onClick={backFn}>{backText}</div>}
                <div className={`deposit-btn nextBtn ${backFn && `msgBtn`} ${isConfirmBtn && `confirmBtn`}`} onClick={continueFn}>{continueText}</div>
            </div>
        }
    </div>
}

export default FPSChargeTips