import React, { useEffect } from 'react';
import { isMobileSize } from '../../../Common/CommonHooks';
import './index.scss';

export let originTagName = `.bottom-menu .AddToSlip`;
let ballBGColor = ''

export const updateOriginLocation = (str, gbColor = '') => {
    originTagName = str;
    ballBGColor = gbColor || '';

}

export const AddToSlipAnimationFn = (num = 1, onEnd) => {
    try {
        const originDom = document.querySelector(originTagName)
        const targetDom = document.querySelector(`.bottom-menu #bottom-menu-Int_Betslip`)
        if (!isMobileSize || !originDom || !targetDom) return;
        let AddToSlip = originDom.getBoundingClientRect()
        const x1 = Math.ceil(AddToSlip.left + AddToSlip.width * 0.5 - 10);
        const y1 = Math.ceil(AddToSlip.top + AddToSlip.height * 0.5 - 15);
        let betSlip = targetDom.getBoundingClientRect()
        const x2 = Math.ceil(betSlip.left + betSlip.width * 0.5 - 10);
        const y2 = Math.ceil(betSlip.top + betSlip.height * 0.5 - 10);
        const pathName = window.location.href;
        if (pathName.indexOf('marksix') > -1) {
            ballBGColor = 'MSCBall'
        } else if (pathName.indexOf('football') > -1) {
            ballBGColor = 'FTCBall'
        } else if (pathName.indexOf('racing') > -1) {
            ballBGColor = 'HRCBall'
        }
        const rootDom = document.getElementById('root');
        rootDom.insertAdjacentHTML('afterbegin', `<div class='parabolaBall ${ballBGColor}'>${num}</div>`);
        let ball = document.querySelector(`#root .parabolaBall`)
        ball.style.top = y1 + 'px';
        ball.style.left = x1 + 'px';
        ball.style.transition = 'left 0s, top 0s';
        ball.style.display = 'block';

        let speedTime = 0.8;
        let bezier = '.08,-0.35,.99,.33';
        const distanceY = Math.abs(y2 - y1);
        const rate = 0.4;
        if (distanceY > 0 && distanceY <= 100) {
            bezier = '0.11,-0.57, 0.99, 0.4';
        } else if (distanceY > 100 && distanceY <= 200) {
            speedTime = speedTime * (1 + 1 * rate);
        } else if (distanceY > 200 && distanceY <= 300) {
            speedTime = speedTime * (1 + 2 * rate);
        } else if (distanceY > 300 && distanceY <= 400) {
            speedTime = speedTime * (1 + 3 * rate);
        } else if (distanceY > 400 && distanceY <= 500) {
            speedTime = speedTime * (1 + 4 * rate);
        } else if (distanceY > 500 && distanceY <= 600) {
            speedTime = speedTime * (1 + 5 * rate);
        } else {
            speedTime = speedTime * (1 + 6 * rate);
        }
        speedTime = Math.round(speedTime * 100) / 100;
        setTimeout(() => {
            ball.style.willChange = 'transition';
            ball.style.transition = `left ${speedTime}s linear, top ${speedTime}s cubic-bezier(${bezier}), transform ${speedTime}s ease-out`;
            ball.style.top = y2 + 'px';
            ball.style.left = x2 + 'px';
            ball.style.transform = 'scale(0.7)';
            ball.style.willChange = 'auto';
        }, 20)
        setTimeout(() => {
            rootDom.removeChild(ball);
            setTimeout(() => {
                onEnd && onEnd()
            }, 100);
        }, speedTime * 1000 + 20);
    } catch (error) {
        console.error('animate func', error);
    }
};