import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import EpscoPin from '../components/EpscoPin';
import { numberDesensitizeFn } from '../../../Common/GlobalFunc';
import ReactRadio from '../../../Common/ui-components/ReactRadio';
import { postWithdrawalFPS, postWithdrawalEPS } from '../FundsTransferAPI';
import { postWithdrawalFPS_IB, postWithdrawalEPS_IB } from '../FundsTransferIBAPI';
import { textFormatAmountFn } from '../../../Common/GlobalFunc';
import { getFPSErrorMsg, formatServiceCharge, saveEpsNoticeCookie } from '../utils';
import { ConfigContext } from '../../../Common/ConfigHelper';
import useBroadcastChannel from '../../../Common/hooks/useBroadcastChannel';

const WithdrawalPreview = (props) => {
    const {
        setCurStep,
        withdrawalParams,
        loginAccount,
        nbaData,
        transactionMethodList,
        setPostWithdrawalData,
        FPSErrorList,
        setLoadingStatus,
        isReturnErrorCode,
        goToLinkBAPage,
        saveEpsNotice
    } = props;
    const { t, i18n } = useTranslation();
    const { betslipMenu } = useContext(ConfigContext);
    const betslipResultCode = betslipMenu.betslipResultCode;
    const [EPSCOPin, setEPSCOPin] = useState('');
    const [currentMethod, setCurrentMethod] = useState('');
    const { formParams, amount } = withdrawalParams;
    const nba1 = nbaData?.nba1 || {};
    const bankInfo = nba1?.bankInfo || {};
    const config = nbaData?.config || {};
    const FPSConfig = config?.FPS || {};
    const EPSConfig = config?.EPS || {};
    const AUTOPAYConfig = config?.AUTOPAY || {};
    const showEPS = formParams.method === 'EPS' && amount <= EPSConfig.TBMaxWithdrawal;
    const showFPS = formParams.method === 'FPS' && amount <= FPSConfig.perWdrMaxAmt;
    const broadcast_resetlogin = useBroadcastChannel('resetLoginTimeout');

    useEffect(() => {
        if (withdrawalParams) {
            let method;
            if (showEPS) {
                method = 'EPS';
            } else if (showFPS) {
                method = 'FPS';
            } else {
                method = 'AUTOPAY';
            }
            setCurrentMethod(method);
        }
    }, []);

    const confirmFn = () => {
        if (currentMethod === 'EPS') {
            if (!EPSCOPin) {
                setLoadingStatus(false);
                return alert(t('LB_BS_FT_PIN_ERR_MSG1'));
            }
            if (EPSCOPin.length < 8) {
                alert(t('LB_BS_FT_PIN_ERR_MSG2'));
                setLoadingStatus(false);
                return;
            }
        }

        commitWithdrawalApi();
    };

    const commitWithdrawalApi = async () => {
        setLoadingStatus(true);
        let responseData;
        let status = '';
        let goToValidationPage = false;
        let errMsg;
        let errMsg_en;
        let errMsg_ch;
        if (currentMethod === 'FPS') {
            const params = {
                fpsChannelID: 104,
                bankAccountType: '1',
                bankAccountNo: nba1.bankAccountNo,
                bankCode: nba1.bankInfo.code,
                transAmt: amount * 100,
                firstName: 'string',
                lastName: 'string'
            };
            responseData = window.globalConfig.IS_IB
                ? await postWithdrawalFPS_IB(i18n.language, params, FPSConfig.timeout)
                : await postWithdrawalFPS({ params, timeout: FPSConfig.timeout });
            broadcast_resetlogin?.postMessage(false);
            if (isReturnErrorCode(sessionStorage.getItem('result'))) {
                return;
            }

            if (
                responseData?.transStatus === 'RJ' &&
                (responseData?.errorCode == '64' || responseData?.errorCode == '62')
            ) {
                goToValidationPage = true;
                if (window.globalConfig.IS_IB) {
                    errMsg_en = responseData?.errMsg;
                    errMsg_ch = responseData?.errMsgTC;
                    errMsg = i18n.language === 'en' ? errMsg_en : errMsg_ch;
                } else {
                    errMsg = getFPSErrorMsg(responseData, FPSErrorList, i18n.language);
                    errMsg_en = getFPSErrorMsg(responseData, FPSErrorList, 'en');
                    errMsg_ch = getFPSErrorMsg(responseData, FPSErrorList, 'ch');
                }
            } else if (responseData?.transStatus === 'AP') {
                status = 'SUCCESSFUL';
            } else if (['PX', 'AA', 'MA', 'IP'].includes(responseData?.transStatus)) {
                status = 'PENDING';
                errMsg = getFPSErrorMsg(responseData, FPSErrorList, i18n.language);
                errMsg_en = getFPSErrorMsg(responseData, FPSErrorList, 'en');
                errMsg_ch = getFPSErrorMsg(responseData, FPSErrorList, 'ch');
            } else if (['AX', 'UX'].includes(responseData?.transStatus)) {
                status = 'TIMEOUT';
            } else {
                if (window.globalConfig.IS_IB) {
                    errMsg_en = responseData?.errMsg;
                    errMsg_ch = responseData?.errMsgTC;
                    errMsg = i18n.language === 'en' ? errMsg_en : errMsg_ch;
                } else {
                    errMsg = getFPSErrorMsg(responseData, FPSErrorList, i18n.language);
                    errMsg_en = getFPSErrorMsg(responseData, FPSErrorList, 'en');
                    errMsg_ch = getFPSErrorMsg(responseData, FPSErrorList, 'ch');
                }

                status = 'REJECTED';
            }
        }

        if (currentMethod === 'EPS') {
            const finalAmt = window.globalConfig.IS_IB ? amount : amount * 100;
            const params = {
                pinBlock: EPSCOPin,
                amount: finalAmt,
                type: 0,
                charge: textFormatAmountFn(nba1.EPS?.charge, 2)
            };
            if (saveEpsNotice) {
                saveEpsNoticeCookie(loginAccount);
            }
            responseData = window.globalConfig.IS_IB
                ? await postWithdrawalEPS_IB({ lang: i18n.language, params, timeout: EPSConfig.timeout })
                : await postWithdrawalEPS({ params, timeout: EPSConfig.timeout });
            broadcast_resetlogin?.postMessage(false);
            if (isReturnErrorCode(sessionStorage.getItem('eft_status'))) {
                return;
            }

            if (responseData.code === '0' || responseData.code === 0) {
                status = 'SUCCESSFUL';
            } else if ([504, 62, 99, 2003, 64, 2004].includes(Number(responseData.code))) {
                // go to step 3
                goToValidationPage = true;
            } else if (responseData.code == 2002) {
                // pending
                status = 'PENDING';
                errMsg = '';
            } else {
                status = 'REJECTED';
                if (window.globalConfig.IS_IB) {
                    switch (responseData.errorCode) {
                        case 'EPS BANK TIMEOUT':
                        case 'EFT BANK TIMEOUT WITHDRAWAL':
                        case 'EFT BANK TIME OUT WITHDRAWAL':
                        case 'BANK TIME OUT WITHDRAWAL':
                            status = 'PENDING';
                            break;
                        case 'AMOUNT EXCEEDS LIMIT':
                            responseData.code = 62;
                            goToValidationPage = true;
                            break;
                        case 'TOO MANY WITHDRAWALS':
                            responseData.code = 64;
                            goToValidationPage = true;
                            break;
                        case 'EFT SERVICE NOT AVAILABLE':
                        case 'BANK SYSTEM FAULT':
                            responseData.code = 99;
                            goToValidationPage = true;
                            break;
                        case 'EPE PIN RETRY EXCEEDED':
                            responseData.code = 2004;
                            goToValidationPage = true;
                            break;
                        case 'EPE PIN ERROR':
                            responseData.code = 2003;
                            goToValidationPage = true;
                            break;
                        default:
                    }

                    errMsg_en = responseData?.errMsg;
                    errMsg_ch = responseData?.errMsgTC;
                    errMsg = i18n.language === 'en' ? errMsg_en : errMsg_ch;
                } else {
                    const key = i18n.language === 'en' ? 'message_en' : 'message_cn';
                    errMsg = responseData[key];
                    errMsg_en = responseData['message_en'];
                    errMsg_ch = responseData['message_cn'];
                }
            }
        }
        if (currentMethod === 'AUTOPAY') {
            const finalAmt = window.globalConfig.IS_IB ? amount : amount * 100;
            const params = {
                pinBlock: '00000000',
                amount: finalAmt,
                type: 1
            };
            responseData = window.globalConfig.IS_IB
                ? await postWithdrawalEPS_IB({ lang: i18n.language, params, timeout: EPSConfig.timeout })
                : await postWithdrawalEPS({ params, timeout: EPSConfig.timeout });
            broadcast_resetlogin?.postMessage(false);
            if (isReturnErrorCode(sessionStorage.getItem('eft_status'))) {
                return;
            }

            if (responseData.code == 0) {
                status = 'SUCCESSFUL';
            } else {
                status = 'REJECTED';
                if (window.globalConfig.IS_IB) {
                    errMsg_en = responseData?.errMsg;
                    errMsg_ch = responseData?.errMsgTC;
                    errMsg = i18n.language === 'en' ? errMsg_en : errMsg_ch;
                } else {
                    const key = i18n.language === 'en' ? 'message_en' : 'message_cn';
                    errMsg = responseData[key];
                    errMsg_en = responseData['message_en'];
                    errMsg_ch = responseData['message_cn'];
                }
            }
            if (window.globalConfig.IS_IB) {
                switch (responseData.errorCode) {
                    case 'EPS BANK TIMEOUT':
                    case 'EFT BANK TIMEOUT WITHDRAWAL':
                    case 'EFT BANK TIME OUT WITHDRAWAL':
                    case 'BANK TIME OUT WITHDRAWAL':
                        status = 'PENDING';
                        break;
                }

                errMsg_en = responseData?.errMsg;
                errMsg_ch = responseData?.errMsgTC;
                errMsg = i18n.language === 'en' ? errMsg_en : errMsg_ch;
            } else {
                const key = i18n.language === 'en' ? 'message_en' : 'message_cn';
                errMsg = responseData[key];
                errMsg_en = responseData['message_en'];
                errMsg_ch = responseData['message_cn'];
            }
        }
        let methodName = t(`LB_BS_FT_${currentMethod}`).replace('{0}', t('LB_BS_FT_WITHDRAWAL'));

        if (responseData.errCode === 'TIMEOUT') {
            status = 'TIMEOUT';
        }

        setPostWithdrawalData({
            method: currentMethod,
            methodName,
            status,
            errMsg,
            errMsg_en,
            errMsg_ch,
            responseData
        });

        setLoadingStatus(false);
        if (goToValidationPage) {
            setCurStep(2);
        } else {
            setCurStep(3);
        }
    };

    const onInputPin = (val) => {
        setEPSCOPin(val);
    };

    const handleSelectedRadio = (value) => {
        setCurrentMethod(value);
    };

    const handleClickClose = () => {
        window.close();
    };

    const getAUTOPAYErrMsg = () => {
        let msg;
        const nba1 = nbaData?.nba1;
        if (nba1.AUTOPAY.disabled) {
            if (nba1.FPS.show) {
                msg = t('LB_BS_FT_WITHDRAWAL_AUTOPAY_DISABLED1');
            } else {
                if (bankInfo.eDDADepositEnable != '1') {
                    msg = t('LB_BS_FT_WITHDRAWAL_AUTOPAY_DISABLED1');
                } else {
                    msg = t('LB_BS_FT_WITHDRAWAL_AUTOPAY_DISABLED2');
                }
            }
        }
        return msg;
    };

    const getCharge = () => {
        if (currentMethod === 'AUTOPAY') return;
        if (currentMethod === 'FPS') {
            const perWdrChargeAmt = textFormatAmountFn(FPSConfig.perWdrChargeAmt, 2);
            return FPSConfig.perWdrChargeAmt ? perWdrChargeAmt : null;
        } else if (currentMethod === 'EPS') {
            var EPSChargeAmt = 0;
            if (window.globalConfig.IS_IB) {
                EPSChargeAmt = textFormatAmountFn(nba1.EPS?.charge, 2);
            } else {
                EPSChargeAmt = textFormatAmountFn(nba1.EPS?.charge / 100, 2);
            }

            return nba1.EPS?.hasWithdrawalCharge && EPSChargeAmt > 0 ? EPSChargeAmt : null;
        }
    };

    const showAUTOPAY =
        nba1.AUTOPAY.show &&
        ((showEPS && !withdrawalParams.otherMeansEnable && !nba1.AUTOPAY.disabled) || (!showFPS && !showEPS));
    const disabledAUTOPAY = nba1.AUTOPAY.disabled;
    const showMethodRadio = showEPS && showAUTOPAY;
    const lang = i18n.language === 'en' ? 'name_en' : 'name_ch';
    const AUTOPAYInfo = transactionMethodList.find((i) => i.method === 'AUTOPAY');

    const showCloseBtn = getAUTOPAYErrMsg() && !showEPS && showAUTOPAY;

    return (
        <>
            <div className="deposit-prev">
                <div className="deposit-prev-title">
                    <div>{t('LB_BS_FT_PREVIEW')}</div>
                    <div>{t('LB_BS_FT_PREVIEWDETAIL')}</div>
                </div>
                <div className="FT-form-content">
                    <div className="formItem">
                        <div className="formItemLabel">{t('LB_BS_FT_FROM')}:</div>
                        <div className="formItemContent">
                            <div>{t('LB_BS_FT_BETACCOUNT')}</div>
                            <div>{loginAccount}</div>
                        </div>
                    </div>
                    <div className="formItem">
                        <div className="formItemLabel">{t('LB_BS_FT_TO')}:</div>
                        <div className="formItemContent">
                            <div>{nba1?.bankInfo?.[lang]}</div>
                            <div>{numberDesensitizeFn(formParams.bankAccount)}</div>
                        </div>
                    </div>

                    <div className="formItem">
                        <div className="formItemLabel">{t('LB_BS_FT_TRANSACTIONMETHOD')}:</div>
                        <div className="formItemContent">
                            {showEPS && (
                                <div className={`itemMult `}>
                                    {showMethodRadio && (
                                        <ReactRadio
                                            checked={currentMethod === 'EPS'}
                                            onChange={() => handleSelectedRadio('EPS')}
                                        />
                                    )}
                                    <div className="bankInfo">
                                        <div className="contentTop">{formParams.methodName}</div>
                                        <div className="contentBottom">
                                            <EpscoPin onChange={onInputPin} />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {showFPS && (
                                <div className={`itemMult `}>
                                    <div> {formParams.methodName}</div>
                                </div>
                            )}
                            {showAUTOPAY && (
                                <div className={`itemMult ${getAUTOPAYErrMsg() ? 'dimColor' : ''} `}>
                                    {showMethodRadio && (
                                        <ReactRadio
                                            disabled={disabledAUTOPAY}
                                            checked={currentMethod === 'AUTOPAY'}
                                            onChange={() => handleSelectedRadio('AUTOPAY')}
                                        />
                                    )}
                                    <div className="bankInfo">
                                        <div className="contentTop">{AUTOPAYInfo?.methodName}</div>
                                        {getAUTOPAYErrMsg() ? (
                                            <div className="contentBottom ">{getAUTOPAYErrMsg()}</div>
                                        ) : (
                                            <div className="contentBottom">{t('LB_BS_FT_PREV_AUTOPAY_TIPS')}</div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="formItem">
                        <div className="formItemLabel">{t('LB_BS_FT_WITHDRAWALAMOUNT')}:</div>
                        <div className="formItemContent">
                            <div>${textFormatAmountFn(amount, 2)}</div>
                        </div>
                    </div>
                    {getCharge() && (
                        <div className="formItem">
                            <div className="formItemLabel">{t('LB_BS_FT_SERVICECHARGE')}:</div>
                            <div className={`formItemContent ${currentMethod === 'EPS' ? 'contentBlue' : ''}`}>
                                <div>${getCharge()}</div>
                            </div>
                        </div>
                    )}
                    {currentMethod !== 'EPS' && <div className="deposit-nopin">{t('LB_BS_FT_FPSTIPS')}</div>}
                </div>
                <div className={`deposit-btnArea${showCloseBtn?'':' prev-btnArea'}`}>
                    {showCloseBtn ? (
                        <>
                            <div className="deposit-btn nextBtn" onClick={() => handleClickClose()}>
                                {t('LB_CLOSE')}
                            </div>
                        </>
                    ) : (
                        <>
                            <div
                                className="deposit-btn backBtn"
                                onClick={() => {
                                    setCurStep(0);
                                }}
                            >
                                {t('LB_BETSLIP_PREVIEWBACK')}
                            </div>
                            <div className="deposit-btn confirmBtn" onClick={confirmFn}>
                                {t('LB_BS_FT_CONFIRM')}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default WithdrawalPreview;
