import React, { useState, useRef, useCallback, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toLocaleLang } from '../../Common/home-common';
import { TandCText } from '../../BetSlip/LoginMenu';
import { useLoginLogout, SetLoginBusyStatus } from '../../BetSlip/LoginHooks';
import { LoginIncorrect } from '../../BetSlip/LoginBase';
import LoginEKBAAnswer from '../../BetSlip/LoginEKBAAnswer';
import Header from '../../Header/Header';
import LangSelector from '../../Header/LangSelector';
import Footer from '../../Footer/Footer';
import Copyright from '../../Footer/Copyright';
import useClickAway from '../../Common/hooks/useClickAway';
import './index.scss';
import forgetpw from '../../../info/Include/images/forgetpw.svg';
import register from '../../../info/Include/images/register.svg';
import registerblue from '../../../info/Include/images/register-blue.svg';
import loginImg from '../../../info/Include/images/login-img-new.PNG';
import ewin from '../../../info/Include/images/eWin_new_logo.svg';
import logo_ch from '../../../info/Include/images/logo_hkjc_ch.png';
import logo_en from '../../../info/Include/images/logo_hkjc_en.png';
import logo_ch_white from '../../../info/Include/images/logo_hkjc_ch_white.svg';
import logo_en_white from '../../../info/Include/images/logo_hkjc_en_white.svg';
import { useBreakpoint } from '../../Common/CommonHooks';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useBetSlipMsgQuery } from '../../Home/Data/SitecoreDataHooks';
import { GetOnlinePara } from '../../Common/ChannelParaFunc';
import { QueryConfig } from '../../Common/ConfigHelper';
import { useLoginLogout_IB, clearLoginSessionStorage } from '../../BetSlip/LoginIBHooks';
import { isEoD, getOldUrl } from '../../Common/GlobalFunc';
import { initSsoLogin, setSsoLogout } from '../../BetSlip/SsoHook';
import { useWindowSize } from '../../Common/CommonHooks';
import oldVersionLogo from '../../../info/Include/images/betslip_logo.svg';

const CustomScrollbars = (props) => (
    <Scrollbars
        autoHeight
        autoHeightMin={440}
        autoHeightMax={440}
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        {...props}
    />
);

const Title = ({ isSpeedbet }) => {
    const { t } = useTranslation();
    return (
        <>
            {isSpeedbet ? (
                <>
                    <div className="title">{t('LB_LG_EPA')}</div>
                    <div className="title-hr" />
                </>
            ) : (
                <div className="ewin">
                    <img src={ewin} width={100} alt="" />
                </div>
            )}

            <div className="explanation">{t('LB_LG_EXP')}</div>
        </>
    );
};

const LoginInput = ({
    loginAccount,
    setLoginAccount,
    password,
    setPassword,
    checkAccountPassword,
    checkIsEod,
    APILoading,
    setLoginAPILoading,
    isReadOnly
}) => {
    const { t } = useTranslation();
    const usenameRef = useRef();
    const pwdRef = useRef();
    const { isMobileSize } = useWindowSize();

    return (
        <>
            <span className="login-t">{t('LB_LG_IN')}</span>
            <div className="input-c">
                <input
                    type="text"
                    id="login-account-input"
                    ref={usenameRef}
                    value={loginAccount}
                    onChange={(e) => {
                        let value = e.target.value;
                        if (isMobileSize && e.target.getAttribute('data-init') == 1) {
                            if (e.nativeEvent.data != null) {
                                value = e.nativeEvent.data;
                            }
                            e.target.setAttribute('data-init', 0);
                        }
                        setLoginAccount(value);
                    }}
                    onFocus={(e) => {
                        if (isMobileSize) {
                            e.preventDefault();
                            e.target.setAttribute('data-init', 1);
                            e.target.setSelectionRange(10, 10);
                            setTimeout(function () {
                                e.target.setSelectionRange(10, 10);
                            });
                        }
                        usenameRef.current.setAttribute('placeholder', '');
                    }}
                    onBlur={() => {
                        usenameRef.current.setAttribute('placeholder', t('LB_BS_LOGIN_ACCOUNT_PLACEHOLDER'));
                    }}
                    placeholder={t('LB_BS_LOGIN_ACCOUNT_PLACEHOLDER')}
                    maxLength={15}
                    className="login-input usename"
                    disabled={APILoading || isReadOnly}
                    readOnly={isReadOnly}
                />
            </div>
            <div className="input-c">
                <input
                    ref={pwdRef}
                    id="login-password-input"
                    type="password"
                    value={password}
                    onChange={(e) => {
                        let value = e.target.value;
                        if (isMobileSize && e.target.getAttribute('data-init') == 1) {
                            if (e.nativeEvent.data != null) {
                                value = e.nativeEvent.data;
                            }
                            e.target.setAttribute('data-init', 0);
                        }
                        setPassword(value);
                    }}
                    onFocus={(e) => {
                        if (isMobileSize) {
                            e.preventDefault();
                            e.target.setAttribute('data-init', 1);
                            e.target.setSelectionRange(10, 10);
                            setTimeout(function () {
                                e.target.setSelectionRange(10, 10);
                            });
                        }
                        pwdRef.current.setAttribute('placeholder', '');
                    }}
                    onBlur={(e) => {
                        pwdRef.current.setAttribute('placeholder', t('LB_BS_LOGIN_PASSWORD_PLACEHOLDER'));
                    }}
                    placeholder={t('LB_BS_LOGIN_PASSWORD_PLACEHOLDER')}
                    maxLength={20}
                    disabled={APILoading || isReadOnly}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            checkAccountPassword(loginAccount, password);
                        }
                    }}
                    className="login-input password"
                    readOnly={isReadOnly}
                />
            </div>
            <button
                className={`button login-button cta_yellow ${APILoading ? 'disabled' : ''}`}
                onClick={() => {
                    !APILoading && checkAccountPassword(loginAccount, password);
                }}
            >
                {t('LB_SIGNIN')}
            </button>
        </>
    );
};

const Link = () => {
    const { t, i18n } = useTranslation();
    const lang = toLocaleLang(i18n.language);
    const haveAcc = `${window.globalConfig.WCIP_URL}/registerbettingservice.aspx?UIC=${lang}&EP=BS`;
    const noAcc = `${window.globalConfig.SP_URL}/infomenu/${i18n.language}/register/applybet.asp`;
    const [show, setShow] = useState(false);
    const item1Ref = useRef(null);
    const close = useCallback(() => {
        setShow(false);
    }, []);
    useClickAway(item1Ref, close);

    return (
        <div className="link">
            <div
                ref={item1Ref}
                className={`item item1 cursor-pointer ${show ? 'show' : ''}`}
                onClick={() => setShow(!show)}
            >
                <img src={show ? registerblue : register} alt="" />
                <span className="pre">{t('LB_BS_REGISTER1')}</span>
                {show && (
                    <div className="tooltips">
                        <div
                            onClick={() => {
                                window.location.href = haveAcc;
                            }}
                            dangerouslySetInnerHTML={{ __html: t('LB_LG_HAVE_ACC') }}
                        />
                        <div
                            onClick={() => {
                                window.location.href = noAcc;
                            }}
                            dangerouslySetInnerHTML={{ __html: t('LB_LG_NO_ACC') }}
                        />
                    </div>
                )}
            </div>
            <div className="item item2">
                <div
                    className="cursor-pointer"
                    onClick={() => {
                        window.location.href = t('LB_BS_LOGIN_ACCESS_LOCKED_URL');
                    }}
                >
                    <img src={forgetpw} alt="" />
                    <span>{t('LB_BS_CANNOT_LOGIN')}</span>
                </div>
            </div>
        </div>
    );
};

const AnswerModal = (props) => {
    const { show, onHide } = props;
    const { t } = useTranslation();
    return (
        <Modal
            backdrop="static"
            show={show}
            onHide={onHide}
            size="lg"
            dialogClassName="custom-modal answer-modal"
            aria-labelledby="answer-modal"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="answer-modal">{t('LB_BS_LOGIN_QUESTION')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LoginEKBAAnswer {...props} />
            </Modal.Body>
        </Modal>
    );
};

const EWalletModal = (props) => {
    const { show, onHide, setPassword, setLoginAccount } = props;
    const { t } = useTranslation();

    return (
        <Modal
            backdrop="static"
            show={show}
            onHide={onHide}
            size="lg"
            dialogClassName="custom-modal answer-modal"
            aria-labelledby="answer-modal"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('LB_BS_EWALLET_TITLE')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="login-answer-container">
                    <div className="login-answer-content">
                        <div className="forgot-answer">{t('LB_BS_EWALLET_DESC')}</div>
                        <div className="button-box">
                            <div
                                className="bsBgColor cta_brand"
                                onClick={() => {
                                    setLoginAccount('');
                                    setPassword('');
                                    onHide();
                                }}
                            >
                                {t('LB_BS_EWALLET_LATER')}
                            </div>
                            <div
                                className="cta_yellow ewallet_upgrade"
                                onClick={() => {
                                    setLoginAccount('');
                                    setPassword('');
                                    onHide();
                                    window.open(t('LB_BS_EWALLET_UPGRADE_URL'), '_blank');
                                }}
                            >
                                {t('LB_BS_EWALLET_UPGRADE')}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

const TCModal = (props) => {
    const { onHide } = props;
    const { t } = useTranslation();
    const { msgLoading, getBetSlipLoginTnC } = useBetSlipMsgQuery();

    let textData = '';
    if (!msgLoading) {
        textData = getBetSlipLoginTnC();
        if (textData === '') {
            onHide();
        }
    }

    return !msgLoading && textData !== '' ? (
        <Modal
            backdrop="static"
            {...props}
            size="lg"
            dialogClassName="custom-modal tc-modal"
            aria-labelledby="tc-modal"
            centered
        >
            <Modal.Header>
                <Modal.Title id="tc-modal">{t('LB_BS_LOGIN_TANDC')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CustomScrollbars>
                    <div className="tc-content">
                        <TandCText textData={textData} />
                    </div>
                </CustomScrollbars>
                <div className="button-c">
                    <button
                        className="button proceed-button"
                        onClick={() => {
                            onHide();
                        }}
                    >
                        {t('LB_BS_LOGIN_TANDCBTN')}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    ) : null;
};

const IncorrectModal = (props) => {
    const { loginIncorrectMsg, onHide } = props;
    const { t } = useTranslation();
    return (
        <Modal
            backdrop="static"
            show={loginIncorrectMsg}
            onHide={onHide}
            dialogClassName="custom-modal incorrect-modal"
            aria-labelledby="incorrect-modal"
            centered
        >
            <Modal.Body>
                <div className="incorrect-content">
                    {loginIncorrectMsg && (
                        <LoginIncorrect messageCode={loginIncorrectMsg} onClose={onHide} showClose={false} />
                    )}
                </div>
                <div className="button-c cancel-button-c">
                    <button
                        className="button cancel-button"
                        onClick={() => {
                            onHide();
                        }}
                    >
                        {t('LB_CLOSE')}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

const Ewin2Header = () => {
    const { t, i18n } = useTranslation();
    const breakpoint = useBreakpoint();
    return breakpoint === 'mobile' ? (
        <div className="Ewin2Header-m">
            <img src={i18n.language === 'en' ? logo_en_white : logo_ch_white} alt="" />
        </div>
    ) : (
        <>
            <div className="Ewin2Header">
                <a
                    className={`old-version ${
                        !window.globalConfig.ENABLE_OLD_SITE_LINK ? 'vsbHide' : ''
                    }`}
                    href={getOldUrl(i18n.language)}
                >
                    <img src={oldVersionLogo} />
                    {t('LB_OLD_VERSION')}
                </a>
                <LangSelector /> 
            </div>
            <div className="logo-banner">
                <img src={i18n.language === 'en' ? logo_en : logo_ch} alt="" />
            </div>
        </>
    );
};

const Ewin2Footer = () => {
    return (
        <div className="Ewin2Footer">
            <Copyright />
        </div>
    );
};

const Login = ({ type = 'Speedbet', loginLogout }) => {
    const isSpeedbet = type === 'Speedbet';
    const { i18n } = useTranslation();
    const navigate = useNavigate();
    // const loginLogout = window.globalConfig.IS_IB ? useLoginLogout_IB() : useLoginLogout();
    const [isReadOnly, setIsReadOnly] = useState(false);
    const {
        checkAccountPassword,
        showLoginEKBAAnswer,
        setShowLoginEKBAAnswer,
        loginEKBAQuestion,
        loginIncorrectMsg,
        checkEKBAAnswer,
        loginAccount,
        setLoginAccount,
        password,
        setPassword,
        // resetLoginTimeout,
        accessToken,
        // logoutStateFn,
        // loginTimeoutRemind,
        // isShowTODialog,
        // setIsShowTODialog,
        // isShowCSLogoutDialog,
        setIsShowCSLogoutDialog,
        isShowTandC,
        setIsShowTandC,
        // userInfo,
        // showUserInfo,
        setShowUserInfo,
        // showLastLoginInfo,
        // setShowLastLoginInfo,
        showAnswerErrorMsg,
        // showLogoutSucMsg,
        // setShowLogoutSucMsg,
        closeLoginIncorrectMsg,
        // isShowLogoutBetText,
        // setIsShowLogoutBetText,
        // willOpenUrl,
        // setWillOpenUrl,
        showLogoutReminder,
        // setShowLogoutReminder,
        LoginAPILoading,
        setLoginAPILoading,
        showEkbaQuestion,
        showEwalletDialog,
        setShowEwalletDialog
    } = loginLogout;

    const ssoInit = async () => {
        const data = await initSsoLogin();
        if (data != false) {
            setLoginAccount(data);
            setPassword('********');
            setIsReadOnly(true);
            setShowUserInfo(true);
        } else {
            setIsReadOnly(false);
        }
    };

    useEffect(() => {
        setIsShowCSLogoutDialog(false)
        !sessionStorage.getItem('setShowLogoutReminder') && ssoInit();
        sessionStorage.removeItem('setShowLogoutReminder');
    }, []);

    const resetMyBetSlipLoginInfo = (obj) => {};

    const handleClickLoginEKBAAnswerCancel = () => {
        setShowLoginEKBAAnswer(false);
        SetLoginBusyStatus(false);
    };

    const handleClickLogout = () => {
        setShowLoginEKBAAnswer(false);
        SetLoginBusyStatus(false);
        ssoExpired();
        setIsReadOnly(false);
    };

    const handleCheckPassword = async () => {
        setLoginAPILoading(true);
        const isEoD = await checkIsEod();
        if (!isEoD) {
            if (isSsoLogin > 0) {
                if ((await showEkbaQuestion(true)) == false) {
                    ssoExpired();
                }
            } else {
                await checkAccountPassword(loginAccount, password);
            }
        }
        setLoginAPILoading(false);
    };

    const isSsoLogin = sessionStorage.getItem('sso_web_id')?.length > 0;

    const ssoExpired = (clearSSO = true) => {
        if (clearSSO) {
            setIsReadOnly(false);
            setPassword('');
            setLoginAccount('');
            setSsoLogout();
        } else {
            ssoInit();
        }

        clearLoginSessionStorage();
    };

    const handleClickLoginEKBAAnswerConfirm = async (answer) => {
        setLoginAPILoading(true);
        const isEod = await checkIsEod();
        if (!isEod) {
            await checkEKBAAnswer(answer, setLoginAPILoading);
            resetMyBetSlipLoginInfo();
            !window.globalConfig.IS_IB && setLoginAPILoading(false);
        } else {
            setLoginAPILoading(false);
        }
    };

    const closeCandTFn = () => {
        setIsShowTandC(false);
        if (accessToken) {
            setShowUserInfo(true);
            if(window.history.state.idx > 0) {
                navigate(-1);
            } else {
                navigate(`/${i18n.language}/racing`);
            }

        }
    };

    const closeEWallet = () => {
        setPassword('');
        setLoginAccount('');
        setShowEwalletDialog(false);
    };

    useEffect(() => {
        checkIsEodAsync();
    }, [sessionStorage.getItem('OOSMsgId')]);

    const checkIsEodAsync = async () => {
        await QueryConfig('channelParas', window.globalConfig.CHANNEL_PARA_URL);
        return setEodUi();
    };

    const checkIsEod = async () => {
        await QueryConfig('channelParas', window.globalConfig.CHANNEL_PARA_URL);
        return setEodUi();
    };

    const setEodUi = () => {
        if (isEoD()) {
            handleClickLoginEKBAAnswerCancel();
            return true;
        } else {
            return false;
        }
    };

    const getEodMessage = () => {
        const { t } = useTranslation();
        let message = GetOnlinePara(i18n.language === 'en' ? 'OOSEngContent' : 'OOSChiContent');
        if (message == null || message.length <= 0) {
            message = t('LB_BS_EOD');
        }
        return message;
    };

    return (
        <div className={`GL login-page ${isSpeedbet ? 'isSpeedbet' : 'eWin2'}`}>
            <div id="eod">
                <div className={`eod ${isEoD() ? 'show' : ''}`}>
                    <div className="eod-msg">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: getEodMessage()
                            }}
                        ></div>
                    </div>
                </div>
            </div>
            {isSpeedbet ? <Header isSpeedbet={isSpeedbet} loginLogoutHook={loginLogout} /> : <Ewin2Header />}
            <div className="login-body">
                <Title isSpeedbet={isSpeedbet} />
                <div className="login-c">
                    {isSpeedbet ? (
                        <div className="image">
                            <img src={loginImg} alt="" />
                        </div>
                    ) : null}
                    <div className="login">
                        <LoginInput
                            loginAccount={loginAccount}
                            setLoginAccount={setLoginAccount}
                            password={password}
                            setPassword={setPassword}
                            checkAccountPassword={handleCheckPassword}
                            checkIsEod={checkIsEod}
                            APILoading={LoginAPILoading}
                            setLoginAPILoading={setLoginAPILoading}
                            isReadOnly={isReadOnly}
                        />
                        <IncorrectModal
                            loginIncorrectMsg={loginIncorrectMsg}
                            onHide={closeLoginIncorrectMsg}
                            showClose={false}
                        />
                    </div>
                </div>
                <Link />
                <AnswerModal
                    show={showLoginEKBAAnswer}
                    loginEKBAQuestion={loginEKBAQuestion}
                    showAnswerErrorMsg={showAnswerErrorMsg}
                    onHide={handleClickLoginEKBAAnswerCancel}
                    onClickCancel={handleClickLoginEKBAAnswerCancel}
                    onClickConfirm={handleClickLoginEKBAAnswerConfirm}
                    onClickLogout={handleClickLogout}
                    APILoading={LoginAPILoading}
                    isSsoLogin={isSsoLogin}
                />
                <TCModal show={isShowTandC} onHide={closeCandTFn} />
                <EWalletModal
                    show={showEwalletDialog}
                    onHide={closeEWallet}
                    setPassword={setPassword}
                    setLoginAccount={setLoginAccount}
                />
            </div>
            {/* {isSpeedbet ? <Footer /> : <Ewin2Footer />} */}
            {<Footer />}
        </div>
    );
};

export default Login;
