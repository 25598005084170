import React, { useEffect, useRef, useState } from 'react';
// import AddSlipBtn from '../../../BetSlip/AddSlipBtn';
import './FbBottomBetslipSticky.scss'

const FbBottomBetslipSticky = ({ windowSize, intersectionRatio, children, height = 44 }) => {
    const innerRef = useRef();
    const outerRef = useRef();
    const [isReachBottom, setIsReachBottom] = useState(false);
    const [left, setLeft] = useState();
    useEffect(() => {
        if (innerRef?.current && outerRef?.current && innerRef?.current?.style && outerRef?.current?.offsetWidth) {
            if (intersectionRatio === 0 && !isReachBottom) {

                innerRef.current.style.position = 'fixed';
                innerRef.current.style.width = outerRef.current.offsetWidth + 'px';
                innerRef.current.style.boxShadow = 'rgb(0 0 0 / 20%) 0px 0px 5px';
                innerRef.current.style.borderRadius = '4px';
                innerRef.current.style.zIndex = 11;
                let bottom = 0 - height;
                innerRef.current.style.bottom = 24 + 'px';
                // const up = () => {
                //     if (bottom < 0) {
                //         bottom = bottom + 1;
                //         if(innerRef?.current?.style){
                //             innerRef.current.style.bottom = bottom + 'px';
                //             setTimeout(up, 4);
                //         }
                //     }
                // };
                innerRef.current.style.left = outerRef.current.getBoundingClientRect().left + 'px';
                // setTimeout(up, 4);
            } else {
                innerRef.current.style.boxShadow = null;
                innerRef.current.style.position = 'static';
                innerRef.current.style.width = '100%';
            }
        }
    }, [windowSize, innerRef.current, outerRef.current, intersectionRatio, isReachBottom, left]);

    const standardPageScrollbars = document.querySelector('.StandardPage-scrollbars > :first-child');
    useEffect(() => {
        const scrollFn = () => {
            let target = document.querySelector('.fb-bottom-betslip-sticky-outer-wrapper');
            let targetTop = target?.offsetTop;
            while (target?.offsetParent) {
                target = target.offsetParent;
                targetTop = targetTop + target.offsetTop;
            }
            setIsReachBottom(document.documentElement.clientHeight + standardPageScrollbars.scrollTop > targetTop + 68);
            setLeft(outerRef.current?.getBoundingClientRect().left + 'px')
        };
        standardPageScrollbars?.addEventListener?.('scroll', scrollFn);
        const fbOddsTable = document.querySelector('#fbOddsTable');
        const observe = new ResizeObserver(scrollFn);
        fbOddsTable && observe.observe(fbOddsTable);
        return () => {
            standardPageScrollbars?.removeEventListener('scroll', scrollFn);
            observe.disconnect()
        };
    }, [standardPageScrollbars, outerRef.current]);
    return (
        <div ref={outerRef} className="fb-bottom-betslip-sticky-outer-wrapper" style={{height: height}}>
            <div ref={innerRef} className={`fb-bottom-betslip-sticky-inner-wrapper ${intersectionRatio === 0 && !isReachBottom ? 'is-floating' : ''}`}>
                {/* <div className="fb-bottom-betslip">
                    <AddSlipBtn />
                </div> */}
                {
                    children
                }
            </div>
        </div>
    );
};

export default FbBottomBetslipSticky;
