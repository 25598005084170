import React, { useEffect } from 'react';
import './index.scss';


 const ArrowIcon = (props) => {
    const {
        direction ='right',
        change=false,
        changeDirection='down',
        size=24,
        color,
        className='',
        id='',
        product='racing',
        onClick,
    }=props;

    const onHandleClick = () =>{
        onClick && onClick();
    }
    return (
        <div id={`${id}`} className={`${className} arrow-icon arrow-icon-default${color?`-${color}`:''}-${direction} ${change && `arrow-icon-change-${changeDirection}`}`} style={{width:`${size}px`,height:`${size}px`}} onClick={onHandleClick}>

        </div>
    );
};

export default ArrowIcon