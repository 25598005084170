import React, { useState, useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Popup from 'reactjs-popup';
import { formatServiceCharge } from './utils';

const EpsNotice = ({
    chargeAmtEps,
    loginAccount,
    setProceedEFT,
    goToLinkBAPage,
    bankInfo,
    showReminder,
    setShowReminder,
    setSaveEpsNotice,
    linkDisabled
}) => {
    useEffect(() => {
        setSaveEpsNotice(false);
    }, []);

    const { t, i18n } = useTranslation();

    const cancelNotice = () => {
        setShowReminder({ open: false, status: '', params: {} });
    };

    const onClickProceed = () => {
        setProceedEFT(true);
    };

    const goLinkBankFn = () => {
        goToLinkBAPage(1, bankInfo.bankAccountType);
    };

    const onChangeNotShow = (event) => {
        setSaveEpsNotice(event.target.checked);
    };

    return (
        <Popup open={showReminder.open} className="logout-reminder" closeOnDocumentClick={false}>
            <div className="logout-reminder-container">
                <div class="title">{t('LB_BS_FT_LINK_REMIND_HEADER')}</div>
                <div className="logout-reminder-message center">
                    {t(`LB_BS_FT_EPS_NOTICE_CONTENT`).replace('{0}', formatServiceCharge(chargeAmtEps))}
                </div>
                {bankInfo?.FPS?.linkStatus == 'NO' && bankInfo?.bankInfo.eDDADepositEnable == '1' && !linkDisabled && (
                    <div className="logout-reminder-message">
                        <span className="lnk" onClick={goLinkBankFn}>
                            {t('LB_BS_FT_EPS_NOTICE_LINK1')}
                        </span>
                        {t(`LB_BS_FT_EPS_NOTICE_LINK2`)}
                    </div>
                )}
                <div className="logout-reminder-message center">
                    <input id="chkEpsNoticeNotShow" type="checkbox" className="chk" onChange={onChangeNotShow}></input>
                    <label for="chkEpsNoticeNotShow">{t(`LB_BS_FT_EPS_NOTICE_NOSHOW`)}</label>
                </div>
                <div className="link-notice-btn-area">
                    <div className="notice-btn backBtn" onClick={cancelNotice}>
                        {t('LB_BS_FT_CANCEL')}
                    </div>
                    <div className="notice-btn confirmBtn" onClick={onClickProceed}>
                        {t('LB_BS_FT_PROCEED')}
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default EpsNotice;
