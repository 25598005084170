import { gql } from '@apollo/client';
import { toLocaleLang } from '../../Common/home-common';

export function getImportantNoticesQuery() {
    return gql`
        query importantNotices($category: [RemarkCategory]!) {
            generalBettingRemarks(channel: WEB_SP4E, category: $category) {
                categories
                dt: updateTime
                locations
                contents {
                    lang
                    content
                }
            }
        }
    `;
}

export function getCommonControlQuery() {
    return gql`
        query commonControl {
            peNotes {
                JCBWButtonEnabled
            }
            jcbwControl {
                mainlineOnTopDisplay
                CWAButtonEnabled
                CWBButtonEnabled
                CWCButtonEnabled
                enableFeaturedMatches
                enableGlassBox
                headToHeadIconEnabled
                earlySettlementEnabled
                earlySettlementIndicatorEnabled
                enableEarlySettlementUIMessage
                earlySettlementFilterDisplayEnabled
                liveTIconEnabled
                fchloFilterDisplayEnabled
            }
        }
    `;
}

export function getWebTVEnabledQuery() {
    return gql`
        query commonControl {
            jcbwControl {
                liveWebTvControl {
                    footballMatchId
                    id
                    liveWebTvEnabled
                }
            }
        }
    `;
}

export function getFPSMessageQuery() {
    return gql`
        query fpsMessage {
          jcbwControl {
            fpsBannerControl {
              fpsEFTMessage {
                  name_ch
                  name_en
              }
            }
          }
        }
    `;
}

export function getFPSBannerQuery() {
    return gql`
        query fpsBanner($path: String!, $lang: String!) {
            item(path: $path, language: $lang) {
                children {
                    key: name
                    image: field(name: "FundTransferBanner") {
                        ... on ImageField {
                            src(maxWidth: null)
                            width
                            height
                            alt
                        }
                    }
                    itemEnable: field(name: "Enabled") {
                        value
                    }
                    itemRedirection: field(name: "MenuItemRedirection") {
                        value
                    }
                    redirectMethod: field(name: "RedirectionMethod") {
                        value
                    }
                    pH: field(name: "PopupHeight") {
                        value
                    }
                    pW: field(name: "PopupWidth") {
                        value
                    }
                    pX: field(name: "PopupX") {
                        value
                    }
                    pY: field(name: "PopupY") {
                        value
                    }
                    itemPage: field(name: "MenuItemPage") {
                        value
                    }
                    itemLink: field(name: "MenuItemLink") {
                        value
                    }
                }
            }
        }
    `;
}

export const getImportantNoticesProduct = (prod) => {
    switch (prod) {
        case 'racing':
            return 'HR';
        case 'football':
            return 'FB';
        case 'marksix':
            return 'LY';
    }
    return 'GL';
};

export const getMsgInboxProducts = (prod) => {
    switch (prod) {
        case 'HR':
            return ['GL', 'HR'];
        case 'FB':
            return ['GL', 'FB'];
        case 'LY':
            return ['GL', 'LY'];
    }
    return ['GL'];
};

const getNavMenuFragment = () => {
    return gql`
        fragment NavMenuFragment on Item {
            key: name
            itemName: field(name: "MenuItemName") {
                value
            }
            itemRedirection: field(name: "MenuItemRedirection") {
                value
            }
            itemLink: field(name: "MenuItemLink") {
                value
            }
            itemPage: field(name: "MenuItemPage") {
                value
            }
            itemIcon: field(name: "MenuItemIcon") {
                ... on ImageField {
                    src(maxWidth: null)
                    width
                    height
                }
            }
            jpFlag: field(name: "AutoJackpotFlag") {
                value
            }
            altTxt: field(name: "AltText") {
                value
            }
            itemEnable: field(name: "MenuItemEnable") {
                value
            }
            redirectMethod: field(name: "RedirectionMethod") {
                value
            }
            pH: field(name: "PopupHeight") {
                value
            }
            pW: field(name: "PopupWidth") {
                value
            }
            pX: field(name: "PopupX") {
                value
            }
            pY: field(name: "PopupY") {
                value
            }
            specialtag: field(name: "Specialtag") {
                value
            }
            predefinedColor: field(name: "predefinedColor") {
                ... on LookupField {
                    targetItem {
                        ... on DroplistOption {
                            optionValue {
                                value
                            }
                        }
                    }
                }
            }
            frontendID: field(name: "FrontendID") {
                value
            }
            startDatetime: field(name: "StartDatetime") {
                ... on DateField {
                    value: formattedDateValue(format: "yyyy-MM-ddTHH:mm:ss", offset: -480)
                }
            }
            noOfDays: field(name: "DefaultNumberOfdays") {
                value
            }
            frontEndIds: field(name: "TournamentResultFrontEndID") {
                value
            }
            sNoOfDays: field(name: "SpecificNumberOfDays") {
                value
            }
            moHead: field(name: "MenuItemMouseoverHeading") {
                value
            }
            moText: field(name: "MenuItemMouseoverText") {
                value
            }
            moImage: field(name: "MenuItemMouseoverImage") {
                ... on ImageField {
                    src(maxWidth: null)
                    width
                    height
                }
            }
            iconM: field(name: "MenuItemIconForMobile") {
                ... on ImageField {
                    src(maxWidth: null)
                    width
                    height
                }
            }
            pCardEnable: field(name: "PcardEnabled") {
                value
            }
            GlassboxCustomEventName: field(name: "glassboxCustomEventName") {
                value
            }
            GlassboxCustomEventValue: field(name: "glassboxCustomEventValue") {
                value
            }
            GlassboxTaggingEnabled: field(name: "GlassboxTaggingEnabled") {
                value
            }
        }
    `;
};

export const getSitecoreMenuQuery = () => {
    return gql`
        ${getNavMenuFragment()}
        query NavigationMenu($path: String!, $lang: String!) {
            item(path: $path, language: $lang) {
                children {
                    sectionTitle: field(name: "MenuItemSectionTitle") {
                        value
                    }
                    sectionEnabled: field(name: "MenuItemSectionEnabled") {
                        value
                    }
                    sectionIcon: field(name: "MenuItemSectionIcon") {
                        ... on ImageField {
                            src(maxWidth: null)
                            width
                            height
                        }
                    }
                    ...NavMenuFragment
                    children {
                        ...NavMenuFragment
                    }
                }
            }
        }
    `;
};

export const getSitecoreMenuCartQuery = () => {
    return gql`
        ${getNavMenuFragment()}
        query NavigationMenu($path: String!, $lang: String!) {
            item(path: $path, language: $lang) {
                children {
                    key: name
                    enable: field(name: "Enabled") {
                        value
                    }
                    list: field(name: "OddsMenuSelection") {
                        ... on MultilistField {
                            children: targetItems {
                                sectionTitle: field(name: "MenuItemSectionTitle") {
                                    value
                                }
                                sectionEnabled: field(name: "MenuItemSectionEnabled") {
                                    value
                                }
                                sectionIcon: field(name: "MenuItemSectionIcon") {
                                    ... on ImageField {
                                        src(maxWidth: null)
                                        width
                                        height
                                    }
                                }
                                ...NavMenuFragment
                                children {
                                    ...NavMenuFragment
                                }
                            }
                        }
                    }
                }
            }
        }
    `;
};

export const getProductInfoBlockQuery = () => {
    return gql`
        ${getNavMenuFragment()}
        query NavigationMenu($path: String!, $lang: String!) {
            item(path: $path, language: $lang) {
                children {
                    key: name
                    itemName: field(name: "TabName") {
                        value
                    }
                    itemEnable: field(name: "TabEnabled") {
                        value
                    }
                    fromDate: field(name: "TabEffectiveFromDatetime") {
                        ... on DateField {
                            value: formattedDateValue(format: "yyyy-MM-ddTHH:mm:ss", offset: -480)
                        }
                    }
                    toDate: field(name: "TabEffectiveToDatetime") {
                        ... on DateField {
                            value: formattedDateValue(format: "yyyy-MM-ddTHH:mm:ss", offset: -480)
                        }
                    }
                    children {
                        ...NavMenuFragment
                        enable: field(name: "Enabled") {
                            value
                        }
                        fromDate: field(name: "EffectiveFromDatetime") {
                            ... on DateField {
                                value: formattedDateValue(format: "yyyy-MM-ddTHH:mm:ss", offset: -480)
                            }
                        }
                        toDate: field(name: "EffectiveToDatetime") {
                            ... on DateField {
                                value: formattedDateValue(format: "yyyy-MM-ddTHH:mm:ss", offset: -480)
                            }
                        }
                        iconContent: field(name: "IconContent") {
                            ... on ImageField {
                                src(maxWidth: null)
                                width
                                height
                                alt
                            }
                        }
                        children {
                            key: name
                            enable: field(name: "Enabled") {
                                value
                            }
                            list: field(name: "SubmenuItemList") {
                                ... on MultilistField {
                                    children: targetItems {
                                        ...NavMenuFragment
                                        fromDate: field(name: "EffectiveFromDatetime") {
                                            ... on DateField {
                                                value: formattedDateValue(format: "yyyy-MM-ddTHH:mm:ss", offset: -480)
                                            }
                                        }
                                        toDate: field(name: "EffectiveToDatetime") {
                                            ... on DateField {
                                                value: formattedDateValue(format: "yyyy-MM-ddTHH:mm:ss", offset: -480)
                                            }
                                        }
                                        iconContent: field(name: "IconContent") {
                                            ... on ImageField {
                                                src(maxWidth: null)
                                                width
                                                height
                                                alt
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `;
};

export const getProductTabQuery = () => {
    return gql`
        ${getNavMenuFragment()}
        query NavigationMenu($path: String!, $lang: String!) {
            item(path: $path, language: $lang) {
                children {
                    ...NavMenuFragment
                }
            }
        }
    `;
};

export const getWhatsNewQuery = () => {
    return gql`
        query whatsnew($path: String!, $lang: String!) {
            section: item(path: "/sitecore/content/Sites/JCBW/Product Info Block/eWin/Section", language: $lang) {
                children {
                    name
                }
            }
            item(path: $path, language: $lang) {
                children {
                    key: name
                    enable: field(name: "Enabled") {
                        value
                    }
                    list: field(name: "ProductInfoItemSelection") {
                        ... on MultilistField {
                            children: targetItems {
                                key: name
                                section: field(name: "Section") {
                                    ... on MultilistField {
                                        items: targetItems {
                                            key: name
                                            name: field(name: "SectionName") {
                                                value
                                            }
                                        }
                                    }
                                }
                                message: field(name: "Messages") {
                                    value
                                }
                                enable: field(name: "Enabled") {
                                    value
                                }
                                fromDate: field(name: "EffectiveFromDatetime") {
                                    ... on DateField {
                                        value: formattedDateValue(format: "yyyy-MM-ddTHH:mm:ss", offset: -480)
                                    }
                                }
                                toDate: field(name: "EffectiveToDatetime") {
                                    ... on DateField {
                                        value: formattedDateValue(format: "yyyy-MM-ddTHH:mm:ss", offset: -480)
                                    }
                                }
                                itemRedirection: field(name: "MenuItemRedirection") {
                                    value
                                }
                                redirectMethod: field(name: "RedirectionMethod") {
                                    value
                                }
                                pH: field(name: "PopupHeight") {
                                    value
                                }
                                pW: field(name: "PopupWidth") {
                                    value
                                }
                                pX: field(name: "PopupX") {
                                    value
                                }
                                pY: field(name: "PopupY") {
                                    value
                                }
                                itemPage: field(name: "MenuItemPage") {
                                    value
                                }
                                itemLink: field(name: "MenuItemLink") {
                                    value
                                }
                                predefinedColor: field(name: "predefinedColor") {
                                    ... on LookupField {
                                        targetItem {
                                            ... on DroplistOption {
                                                optionValue {
                                                    value
                                                }
                                            }
                                        }
                                    }
                                }
                                iconContent: field(name: "IconContent") {
                                    ... on ImageField {
                                        src(maxWidth: null)
                                        width
                                        height
                                        alt
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `;
};

export const getBetSlipMsgQuery = () => {
    return gql`
        query BetslipMessage($path: String!, $lang: String!) {
            item(path: $path, language: $lang) {
                children {
                    key: name
                    enable: field(name: "Enabled") {
                        value
                    }
                    msg: field(name: "Message") {
                        ... on TextField {
                            value
                        }
                    }
                    msg2: field(name: "TnCContent") {
                        ... on TextField {
                            value
                        }
                    }
                }
            }
        }
    `;
};

export const getEarlySettlementMsgQuery = () => {
    return gql`
        query EarlySettlementTnC($path: String!, $lang: String!) {
            item(path: $path, language: $lang) {
                msg: field(name: "TnCContent") {
                    value
                }
                enable: field(name: "Enabled") {
                    value
                }
            }
        }
    `;
};

const getFBImportantNoticeFragment = () => {
    return gql`
        fragment FBImportantNoticeFragment on CRSImportantNotice {
            key: name
            message: field(name: "message") {
                value
            }
            enable: field(name: "enabled") {
                value
            }
        }
    `;
};

export const getFBImportantNoticesQuery = () => {
    return gql`
        ${getFBImportantNoticeFragment()}
        query fbMessage($path: String!, $lang: String!) {
            item(path: $path, language: $lang) {
                children {
                    ...FBImportantNoticeFragment
                }
            }
        }
    `;
};

export const getLogoutBoxQuery = () => {
    return gql`
        query LogoutBox($path: String!, $lang: String!) {
            item(path: $path, language: $lang) {
                key: name
                text: field(name: "LogoutText") {
                    value
                }
                enable: field(name: "Enable") {
                    value
                }
                image: field(name: "LogoutBoxImage") {
                    ... on ImageField {
                        src(maxWidth: null)
                        width
                        height
                        alt
                    }
                }
                imageEnable: field(name: "LogoutBoxImageEnable") {
                    value
                }
            }
        }
    `;
};

export const getMarksixFixturesQuery = () => {
    return gql`
        query MarksixFixtures($path: String!, $lang: String!) {
            legends: item(path: "/sitecore/content/Sites/JCBW/NextDrawSchedule/DrawTypeLegend", language: $lang) {
                children {
                    key: name
                    name: field(name: "LegendName") {
                        value
                    }
                }
            }
            item(path: $path, language: $lang) {
                years: children {
                    year: name
                    months: children {
                        key: name
                        month: field(name: "DrawMonth") {
                            value
                        }
                        dates: field(name: "NormalDrawDates") {
                            ... on MultilistField {
                                date: targetItems {
                                    value: name
                                }
                            }
                        }
                        snowballs: field(name: "SnowballDrawDates") {
                            ... on MultilistField {
                                date: targetItems {
                                    value: name
                                }
                            }
                        }
                        presales: field(name: "PresellDrawDates") {
                            ... on MultilistField {
                                date: targetItems {
                                    value: name
                                }
                            }
                        }
                        header: field(name: "HeaderMessage") {
                            value
                        }
                        message: field(name: "MessageDetail") {
                            value
                        }
                    }
                }
            }
        }
    `;
};

export const getEwinUsefulLinksQuery = () => {
    return gql`
        query ewinUsefulLinks($path: String!, $lang: String!) {
            racingEos: item(path: "/sitecore/content/Sites/JCBW/Int_EOS Message", language: $lang) {
              message: field(name: "Message") {
                  value
              }
            }
            item(path: $path, language: $lang) {
                children {
                    key: name
                    product: field(name: "Product") {
                        value
                    }
                    name: field(name: "LinkName") {
                        value
                    }
                    icon: field(name: "LinkIcon") {
                        ... on ImageField {
                            src(maxWidth: null)
                            width
                            height
                        }
                    }
                    itemRedirection: field(name: "RedirectionTarget") {
                        value
                    }
                    redirectMethod: field(name: "RedirectionMethod") {
                        value
                    }
                    pH: field(name: "PopupHeight") {
                        value
                    }
                    pW: field(name: "PopupWidth") {
                        value
                    }
                    pX: field(name: "PopupX") {
                        value
                    }
                    pY: field(name: "PopupY") {
                        value
                    }
                    itemPage: field(name: "RedirectionPage") {
                        value
                    }
                    itemLink: field(name: "RedrectionLink") {
                        value
                    }
                    enable: field(name: "Enabled") {
                        value
                    }
                }
            }
        }
    `;
};

export const getRGPBannerQuery = () => {
    return gql`
        query RGPBanner($path: String!, $lang: String!) {
            item(path: $path, language: $lang) {
                key: name
                enable: field(name: "RGPEnabled") {
                    value
                }
                massSiteEnable: field(name: "MassSiteEnabled") {
                    value
                }
                speedbetEnable: field(name: "SpeedbetEnabled") {
                    value
                }
                chinaSiteEnable: field(name: "ChinaSiteEnabled") {
                    value
                }
                header: field(name: "RGPHeader") {
                    value
                }
                text1: field(name: "RGPTextOne") {
                    value
                }
                text2: field(name: "RGPTextTwo") {
                    value
                }
                icon: field(name: "RGPIcon") {
                    ... on ImageField {
                        src(maxWidth: null)
                        width
                        height
                    }
                }
                itemRedirection: field(name: "MenuItemRedirection") {
                    value
                }
                itemLink: field(name: "MenuItemLink") {
                    value
                }
                itemPage: field(name: "MenuItemPage") {
                    value
                }
                redirectMethod: field(name: "RedirectionMethod") {
                    value
                }
                pH: field(name: "PopupHeight") {
                    value
                }
                pW: field(name: "PopupWidth") {
                    value
                }
                pX: field(name: "PopupX") {
                    value
                }
                pY: field(name: "PopupY") {
                    value
                }
            }
        }
    `;
};

export const getRacingFOUserContentQuery = () => {
    return gql`
        query racingFOUserContent($path: String!, $lang: String!) {
            item(path: $path, language: $lang) {
                children {
                    key: name
                    enable: field(name: "MasterEnabled") {
                        value
                    }
                    betType: field(name: "MasterType") {
                        value
                    }
                    dataType: field(name: "DataScenario") {
                        value
                    }
                    headBanner: field(name: "BannerContent") {
                        ... on TextField {
                            value
                        }
                    }
                    headBannerEnable: field(name: "BannerEnabled") {
                        value
                    }
                    lastUpdCfg: field(name: "LastUpdateConfig") {
                        value
                    }
                    lastUpdText: field(name: "LastUpdateText") {
                        value
                    }
                    lastUpdEnable: field(name: "LastUpdateEnabled") {
                        value
                    }
                    rmkBanner: field(name: "RemarkImage") {
                        ... on ImageField {
                            src(maxWidth: null)
                            width
                            height
                            alt
                        }
                    }
                    rmkBannerEnable: field(name: "RemarkEnabled") {
                        value
                    }
                    ijcInfoText: field(name: "IjcInfoText") {
                        value
                    }
                    ijcLinkText: field(name: "IjcLinkText") {
                        value
                    }
                    ijcLinkUrl: field(name: "IjcLinkUrl") {
                        value
                    }
                    ijcEnable: field(name: "IjcInfoEnabled") {
                        value
                    }
                    children {
                        key: name
                        template {
                            name
                        }
                        seq: field(name: "ProfileSequenceConfig") {
                            value
                        }
                        hkonly: field(name: "HKonly") {
                            value
                        }
                        children {
                            key: name
                            enable: field(name: "Enabled") {
                                value
                            }
                            name: field(name: "LinkName") {
                                value
                            }
                            itemLink: field(name: "LinkUrl") {
                                value
                            }
                            icon: field(name: "LinkIcon") {
                                ... on ImageField {
                                    src(maxWidth: null)
                                    width
                                    height
                                }
                            }
                            redirectMethod: field(name: "RedirectionMethod") {
                                value
                            }
                            pH: field(name: "PopupHeight") {
                                value
                            }
                            pW: field(name: "PopupWidth") {
                                value
                            }
                            pX: field(name: "PopupX") {
                                value
                            }
                            pY: field(name: "PopupY") {
                                value
                            }
                            pCode: field(name: "ProfileCode") {
                                value
                            }
                            pEnable: field(name: "ProfileEnabled") {
                                value
                            }
                            pName: field(name: "ProfileDisplayName") {
                                value
                            }
                            pIcon: field(name: "ProfileIcon") {
                                ... on ImageField {
                                    src(maxWidth: null)
                                    width
                                    height
                                    alt
                                }
                            }
                            pImage: field(name: "ProfileImage") {
                                ... on ImageField {
                                    src(maxWidth: null)
                                    width
                                    height
                                    alt
                                }
                            }
                            pImageLink: field(name: "ProfileImageLink") {
                                value
                            }
                            pRedirect: field(name: "ProfileRedirectionConfig") {
                                value
                            }
                            pMOEnable: field(name: "MouseoverEnabled") {
                                value
                            }
                            pMOImage: field(name: "MouseoverImage") {
                                ... on ImageField {
                                    src(maxWidth: null)
                                    width
                                    height
                                }
                            }
                            pMOName: field(name: "MouseoverName") {
                                value
                            }
                            GlassboxCustomEventName: field(name: "glassboxCustomEventName") {
                                value
                            }
                            GlassboxCustomEventValue: field(name: "glassboxCustomEventValue") {
                                value
                            }
                            GlassboxTaggingEnabled: field(name: "GlassboxTaggingEnabled") {
                                value
                            }
                        }
                    }
                }
            }
        }
    `;
};

export const getSilkColorQuery = () => {
    return gql`
        query color($path: String!, $lang: String!) {
            item: item(path: $path, language: $lang) {
                color: field(name: "Silk") {
                    ... on ImageField {
                        src(maxWidth: null)
                    }
                }
            }
        }
    `;
};

export const getSurveyQuery = () => {
    return gql`
        query survey($path: String!, $lang: String!) {
            survey: item(path: $path, language: $lang) {
                enable: field(name: "Enabled") {
                    value
                }
                lblHome: field(name: "EntryPoint4eText") {
                    value
                }
                lblBetConfirm: field(name: "EntryPointBetConfirmText") {
                    value
                }
                descBetConfirm: field(name: "EntryPointBetConfirmDescription") {
                    value
                }
                surveyUrl: field(name: "SurveyUrl") {
                    value
                }
                redirectMethod: field(name: "RedirectionMethod") {
                    value
                }
                pH: field(name: "PopupHeight") {
                    value
                }
                pW: field(name: "PopupWidth") {
                    value
                }
                pX: field(name: "PopupX") {
                    value
                }
                pY: field(name: "PopupY") {
                    value
                }
            }
        }
    `;
};

export function getESShortcutQuery() {
    return gql`
        query {
            betConfirmationFeatureShortcut {
                btnLabel_ch
                btnLabel_en
                content_ch
                content_en
                title_ch
                title_en
            }
            jcbwControl {
                enableBetConfirmationFeatureShortcut4e
            }
        }
    `;
}

export const getSitecoreVariables = (prod, type, lang) => {
    const scLang = toLocaleLang(lang);
    const cond = prod + type;
    switch (cond) {
        case '2ndLvlMenu':
            return {
                path: window.sitecorePath.SECOND_LVL_MENU,
                lang: scLang
            };
        case 'racingProductMenu':
            return {
                path: window.sitecorePath.PRODUCTMENU_HR,
                lang: scLang
            };
        case 'footballProductMenu':
            return {
                path: window.sitecorePath.PRODUCTMENU_FB,
                lang: scLang
            };
        case 'marksixProductMenu':
            return {
                path: window.sitecorePath.PRODUCTMENU_LY,
                lang: scLang
            };
        case 'racingOddsMenu':
            return {
                path: window.sitecorePath.ODDSMENU_HR,
                lang: scLang
            };
        case 'footballOddsMenu':
            return {
                path: window.sitecorePath.ODDSMENU_FB,
                lang: scLang
            };
        case 'marksixOddsMenu':
            return {
                path: window.sitecorePath.ODDSMENU_LY,
                lang: scLang
            };
        case 'mobileBottomMenu':
            return {
                path: window.sitecorePath.BOTTOMMENU,
                lang: scLang
            };
        case 'ewinProductInfoBlock':
            return {
                path: window.sitecorePath.PRODUCT_INFO_BLOCK_GL,
                lang: scLang
            };
        case 'footballProductInfoBlock':
            return {
                path: window.sitecorePath.PRODUCT_INFO_BLOCK_FB,
                lang: scLang
            };
        case 'marksixProductInfoBlock':
            return {
                path: window.sitecorePath.PRODUCT_INFO_BLOCK_LY,
                lang: scLang
            };
        case 'massProductTab':
            return {
                path: window.sitecorePath.PRODUCT_TAB_MASS,
                lang: scLang
            };
        case 'speedbetProductTab':
            return {
                path: window.sitecorePath.PRODUCT_TAB_SPEEDBET,
                lang: scLang
            };
        case 'rgpBanner':
            return {
                path: window.sitecorePath.RGP_BANNER,
                lang: scLang
            };
        case 'rsFoUserContent':
            return {
                path: window.sitecorePath.RS_FO_USER_CONTENT,
                lang: scLang
            };
        case 'betslipMsg':
            return {
                path: window.sitecorePath.BETSLIPMSG,
                lang: scLang
            };
        case 'fbImportantNotices':
            return {
                path: window.sitecorePath.FB_IMPORTANT_NOTICES,
                lang: scLang
            };
        case 'm6Fixtures':
            return {
                path: window.sitecorePath.M6_FIXTURES,
                lang: scLang
            };
        case 'ewinUsefulLinks':
            return {
                path: window.sitecorePath.EWIN_USEFUL_LINKS,
                lang: scLang
            };
        case 'fpsBanner':
            return {
                path: window.sitecorePath.FPS_BANNER,
                lang: scLang
            };
        case 'logoutBox':
            return {
                path: window.sitecorePath.LOGOUTBOX,
                lang: scLang
            };
        case 'earlySettlementMsg':
            return {
                path: window.sitecorePath.EARLY_SETTLEMENT_MSG,
                lang: scLang
            };
        case 'survey':
            return {
                path: window.sitecorePath.SURVEY,
                lang: scLang
            };
    }
    return {
        path: '',
        lang: scLang
    };
};

export const getSilkColorVariables = (code, lang) => {
    const scLang = toLocaleLang(lang);
    const codePrefix = code.charAt(0);
    return {
        path: window.sitecorePath.LOCAL_RACECOLOR_PATH_PREFIX.replace('{0}', codePrefix).replace('{1}', code),
        lang: scLang
    };
};
