import React, { useState, useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Popup from 'reactjs-popup';
import { GlobalContext } from '../../../../StandardPage';
import './index.scss';
import { useGetESShortcutQuery } from "../../../Home/Data/DataRefreshHooks";
import { useNavigate } from "react-router-dom";
import { Cookies } from "../../../Common/CookieHelper";

export const EarlySettlementShortcutNavigate = ({betData,isShowConfirmation}) => {
    const { t, i18n } = useTranslation();
    const [show,setShow] = useState(false);
    const {esShortcutFeature} = useGetESShortcutQuery(); 
    const globalContext = useContext(GlobalContext);
    const { betConfirmationFeatureShortcut, jcbwControl}=esShortcutFeature;
    const navigate = useNavigate();
    const lang = i18n.language;
    useEffect(() => {
        let show = false;
        if(isShowConfirmation && jcbwControl?.enableBetConfirmationFeatureShortcut4e && globalContext?.globalState?.commonCtrl?.earlySettlementEnabled){
            const esCloseClick=Cookies.getData('esShortcutCloseClick');
            let isDadFBBet=false;
            let isDadHRBet=false;
            betData.map(item=>{
                if(item.prod=="FB" && !isDadFBBet){
                    isDadFBBet=true;
                }
                if(item.prod=="HR" && !isDadHRBet){
                    isDadHRBet=true;
                }
            })
            const isEsPage= location.pathname === `/${lang}/football/cashout`
            if(isDadFBBet && !isDadHRBet && !esCloseClick && !isEsPage){
                show = true;
            }
        }
        setShow(show)
        
    }, [betData,isShowConfirmation,location.pathname])

    const onClickClose = () => {
        setShow(false)
        Cookies.setData('esShortcutCloseClick','1')
    };

    const goToESPage = () => {
        navigate(`/${lang}/football/cashout`)
        Cookies.setData('esShortcutCloseClick','1')
        setShow(false)
    };
    
    return (
        <>
            { show &&
            <div className="esShortcutNavWrap">
                <div className="esShortcutNav">
                    <div className="icon"></div>
                    <div className="text">
                        <div>
                            {betConfirmationFeatureShortcut?.[`title_${lang}`]}
                        </div>
                        <div>
                            {betConfirmationFeatureShortcut?.[`content_${lang}`]}
                        </div>
                    </div>
                    <div className='btnArea'>
                        <div className='switch-btn-icon open-btn-icon' onClick={onClickClose}></div>
                        <div className="button" onClick={() => { goToESPage() }}>
                        {betConfirmationFeatureShortcut?.[`btnLabel_${lang}`]}
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    );
};