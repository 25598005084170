import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import OddsTable from './RCOddsTableCompact';
import CWINTable from './CWINTable';
import { RacingContext } from '../../Home/RacingPage';
import { useWindowSize } from '../../Common/CommonHooks';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';

const CWinBase = (props) => {
    const { t } = useTranslation();
    const context = useContext(RacingContext);

    const [collapse, setCollapse] = useState(true);
    const RCColumns = {
        no: true,
        banker1: false,
        banker2: false,
        banker3: false,
        leg: false,
        horseName: true,
        rcNote: context.content.isPeNoteEnable,
        rcNoteM: context.content.isPeNoteEnable,
        win: true,
        place: false,
        field: false
    };

    const RCColumnLbls = {
        leg: t('LB_RC_ODDS_SEL')
    }

    const handleClickCollapse = e => {
        setCollapse(!collapse);
    }

    const { isMobileSize } = useWindowSize();
    const columnsCountBreakPoints = isMobileSize ? { 350: 1, 1024: 2 } : { 350: 2 }

    return (
        <ResponsiveMasonry columnsCountBreakPoints={columnsCountBreakPoints} style={{ margin: "12px 0px", width: '100%' }}>
            <Masonry className="masonry-col-cwa">
                <div className="cwin-racecard-table">
                    <div className="cwin-racecard-table-m">
                        <a data-bs-toggle="collapse" href="#cwinRaceCardCollapse" role="button" aria-expanded="false" aria-controls="cwinRaceCardCollapse" onClick={() => handleClickCollapse()}>
                            <label className="font-color-black font-size-15">
                                {t('LB_WIN')}
                            </label>
                            <div className={collapse ? 'collaspse' : 'expend'}>
                                <ArrowIcon className='' direction='down' change={!collapse} changeDirection='up' size={24} />
                            </div>
                        </a>
                    </div>
                    <div className="collapse with-100 cwinRaceCardCollapse" id="cwinRaceCardCollapse">
                        <OddsTable betType={props.betType} columns={RCColumns} lbl={RCColumnLbls}
                            firstRace={props.raceNo} raceNo={props.raceNo}
                            selectedBetType={props.betType} raceStaticHeader="LB_WIN" startCnt={0} disableQuickBet={true} />
                    </div>
                </div>
                <div>
                    <CWINTable betType={props.betType} raceNo={props.raceNo} hideCheckbox={props.hideCheckbox} disableQuickBet={props.disableQuickBet} />
                </div>
            </Masonry>
        </ResponsiveMasonry>
    )
}
export default CWinBase;