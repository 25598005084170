import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { RacingContext } from '../../Home/RacingPage';
import { GetRaceConfig } from '../../Common/ConfigHelper';

const MergedPoolRemarks = ({ betType }) => {

    const { t } = useTranslation();
    const context = useContext(RacingContext);
    const mtg = context.content.meeting;
    const poolInv =  mtg.poolInvs.filter((data) => data.leg.races[0] == context.content.raceNo && data.oddsType==betType)[0];
    const singlePools = GetRaceConfig().singleRacePool.split(';');

    function getMemberPools(pid) {
        return mtg.poolInvs.filter(x=>x.mergedPoolId==pid).map(x=>x.oddsType).sort((x,y)=> singlePools.indexOf(x) - singlePools.indexOf(y));
    }

    function getMergedPoolRemarksLbl(memPools) {
        let outStr = '';
        switch (memPools.length) {
            case 3:
                outStr = t('LB_RC_INVESTMENT_MERGE_REMARK_3')
                    .replace('{0}', t('LB_' + memPools[0]))
                    .replace('{1}', t('LB_' + memPools[1]))
                    .replace('{2}', t('LB_' + memPools[2]));
                break;
            case 2:
                outStr = t('LB_RC_INVESTMENT_MERGE_REMARK_2')
                    .replace('{0}', t('LB_' + memPools[0]))
                    .replace('{1}', t('LB_' + memPools[1]));
                break;
            default:
                break;
        }
        return outStr;
    }


    let isMergedPool = poolInv!=null && poolInv.mergedPoolId!=null;
    let remarks = '';

    if ( isMergedPool ) {
        let mPools = getMemberPools(poolInv.mergedPoolId);
        remarks = getMergedPoolRemarksLbl(mPools);
    }

    return isMergedPool && betType!="IWN" ? <div className="rc-mergepool-remarks">{remarks}</div> : null
}

export default MergedPoolRemarks