import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { useProductInfoBlockQuery } from '../../Home/Data/SitecoreDataHooks';
import { handleClickMenuItem, isProductInfoItemEnable, IconContent } from '../../Menu/MenuCommon'; 

import { Scrollbars } from 'react-custom-scrollbars-2';

const CustomScrollbars = props =>{
    return (
    <Scrollbars
        // autoHeight
        autoHeightMin={200}
        autoHeightMax={200}
        autoHide
        {...props}
    />
)};

const FootballTopBlock = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [ menuLoading, menuData ] = useProductInfoBlockQuery('football');

    const handleOnPageChange = (url) => {
        navigate(url);
    }

    

    let tabIdx = 0;
    let contentIdx = 0;
    return !menuLoading ? <div>
        <ul className="nav nav-tabs" id="myTab" role="tablist">
            {menuData.map((menu) => {
                return isProductInfoItemEnable(menu) ? <li className="nav-item" role="presentation"key={menu.key}>
                    <button className={`nav-link ${tabIdx++===0 ? 'active' : ''}`} id={`${menu.key}-tab`} data-bs-toggle="tab" data-bs-target={`#${menu.key}`} type="button" role="tab" aria-controls="home" aria-selected="true">
                        {menu.itemName.value}
                    </button>
                </li> : null
            })}
        </ul>
        <div className="tab-content" id="myTabContent">
            {menuData.map((menu) => {
                return isProductInfoItemEnable(menu) ? <div className={`tab-pane fade ${contentIdx++===0 ? 'show active': ''}`} id={menu.key} role="tabpanel" aria-labelledby={`${menu.key}-tab`} key={menu.key}>
                    <CustomScrollbars><BlockBody menu={menu} t={t} handleOnPageChange={handleOnPageChange}/></CustomScrollbars>
                </div> : null
            })}
        </div>
    </div> : null

}
export default FootballTopBlock;

const BlockBody = ({menu, t, handleOnPageChange}) => {
    const filterChildren = menu.children.filter(x=> x.itemEnable.value==="1");
    if ( filterChildren.length===0 ) {
        return <div className="fb-info-block-noinfo">{t('LB_RC_JTC_NOINFO')}</div>;
    }
    return <div>
        {filterChildren.map((cMenu, index) => {
            let predefinedColor = cMenu.predefinedColor?.targetItem?.optionValue?.value || "";
            let hlStyle = predefinedColor!="" ? { color: predefinedColor } : {};
            return  <div className={`top-block-item`} style={hlStyle} onClick={(e)=>handleClickMenuItem(e, cMenu, handleOnPageChange)} key={cMenu.itemName.value+index}>
                {cMenu.itemName.value}
                <span style={{ paddingLeft: "4px" }}>
                    <IconContent iconContent={cMenu.iconContent} />
                </span>
            </div>
        })}
    </div>
}