import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import RBCMultiLegSlider from '../Common/RBCMultiLegSlider';
import { getPool, getAlupPools, getAvailableRunnerNos } from './RBCCommon';
import { RbcContext } from '../Page/RebateCalculator';
import CheckBox from '../../Common/ui-components/CheckBox';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';

const RBCBetCard = () => {
    const { rbcState, setRbcMeetingState } = useContext(RbcContext);
    const { t, i18n } = useTranslation();
    const rbcBetTypeConfig = rbcState.raceConfig?.rbcBetType;
    let raceList = rbcState.races.map(r => {
        return { raceNo: r, pos: 1 };
    });
    let alupFCT = rbcState.betType === 'FCT' && raceList.length > 1;
    if (rbcBetTypeConfig?.[rbcState.betType].multiLeg && rbcState.races.length>0) {
        raceList = getPool(rbcState.meeting.pmPools, rbcState.betType, rbcState.races[0])?.leg.races.map(r => {
            return { raceNo: r, pos: 1 };
        });
    }

    if ( rbcState.races.length===1 && rbcBetTypeConfig?.[rbcState.betType].posCnt>1 ) {
        if ( rbcState.subTypesByRace[rbcState.races[0]]!='M') {
            raceList = [];
            for ( let i=0; i<rbcBetTypeConfig[rbcState.betType].posCnt; i++ ) {
                raceList.push({ raceNo: rbcState.races[0], pos: i+1 });
            }
        }
    }

    const getNumHeaderLbl = (raceNo) => {
        switch ( rbcState.betTypesByRace[raceNo] ) {
            case "CWA":
            case "CWB":
            case "CWC":
                return 'LB_RC_CWIN_COMPOSITE';
        }
        return 'LB_RC_ODDS_TABLE_NO';
    }

    const getBankerHeaderLbl = (raceNo) => {
        switch ( rbcState.betTypesByRace[raceNo] ) {
            case "IWN":
                return 'LB_RC_ODDS_IWNTABLE_1ST_IWN';
            case 'FCT':
                return getLegHeaderSubTypeLbl(raceNo, 1);
        }
        return 'LB_RC_ODDS_BANKER';
    }

    const getLegHeaderLbl = (raceNo, pos) => {
        switch ( rbcState.betTypesByRace[raceNo] ) {
            case "IWN":
                return 'LB_RC_RESULT_SPOILER';
            case 'FCT':
                if ( rbcState.betType=="ALUP" || rbcState.races.length > 1 ) {
                    return getLegHeaderSubTypeLbl(raceNo, 2);
                }
                return getLegHeaderSubTypeLbl(raceNo, pos);
            case 'TCE':
            case 'QTT':
                return getLegHeaderSubTypeLbl(raceNo, pos);
        }
        return rbcBetTypeConfig[rbcState.betTypesByRace[raceNo]].showBanker ? 'LB_RC_ODDS_LEG' : 'LB_RC_ODDS_SEL';
    }

    const getLegHeaderSubTypeLbl = (raceNo, pos) => {

        switch( rbcState.subTypesByRace[raceNo] ) {
            case 'S':
                if ( pos===1 ) return 'LB_RC_ODDS_1ST';
                else if ( pos===2 ) return 'LB_RC_ODDS_2ND';
                else if ( pos===3 ) return 'LB_RC_ODDS_3RD';
                else if ( pos===4 ) return 'LB_RC_ODDS_4TH';
            case 'B':
            case 'BM':
                let posTxt = '';
                if ( pos===1 ) posTxt = 'LB_RC_OODS_1ST_B';
                else if ( pos===2 ) posTxt = 'LB_RC_OODS_2ND_B';
                else if ( pos===3 ) posTxt = 'LB_RC_OODS_3RD_B';

                if ( rbcBetTypeConfig[rbcState.betTypesByRace[raceNo]].posCnt===pos )
                    posTxt = 'LB_RC_OODS_LEG_B';
                return posTxt;
            case 'MB':
                if ( pos===1 ) return 'LB_RC_ODDS_1ST_MB';
                else if ( pos===2 ) return 'LB_RC_ODDS_2ND_MB';
                else if ( pos===3 ) return 'LB_RC_ODDS_3RD_MB';
                else if ( pos===4 ) return 'LB_RC_ODDS_4TH_MB';
        }
        return 'LB_RC_ODDS_SEL';
    }

    const isShowField = (raceNo, pos) => {
        switch ( rbcState.betTypesByRace[raceNo] ) {
            case "CWA":
            case "CWB":
            case "CWC":
            case "IWN":
                return false;
            case "FCT":
                if ( rbcState.betType=="ALUP" || rbcState.races.length > 1 ) {
                    if ( rbcState.subTypesByRace[raceNo]==="S" )
                        return false;
                    break;
                }
                // else go to below case
            case "TCE":
            case "QTT":
                switch( rbcState.subTypesByRace[raceNo] ) {
                    case "S":
                        return false;
                    case "B":
                    case "BM":
                        return rbcBetTypeConfig[rbcState.betTypesByRace[raceNo]].posCnt===pos;
                }
        }
        return true;
    }

    const isShowBanker = (raceNo, betType) => {
        if ( betType==='FCT' && (rbcState.betType=="ALUP" || rbcState.races.length > 1) && rbcState.subTypesByRace[raceNo]!="M" ) {
            return true;
        }
        return rbcBetTypeConfig[betType].showBanker;
    }

    const RaceHeader = ({raceNo}) => {
        return <div className="rc-odds-table-compact-race-header rbcTableHeader">
            <div>{t('LB_RC_SEL').replace('{0}', raceNo)}</div>
        </div>;
    }

    const OddsDropdownMenu = ({raceNo}) => {
        const alupPools = getAlupPools(rbcState.meeting.pmPools, rbcBetTypeConfig, raceNo);
        let menuItem = alupPools.map(pool => {
            let disabledOption = rbcState.races.length > rbcBetTypeConfig[pool].maxAlupLeg;
            return <Dropdown.Item eventKey={pool} onClick={() => selectedBetTypeChange(raceNo, pool)} disabled={disabledOption} value={pool} key={`raceDD_${pool}_${raceNo}`} >
                {t('LB_RBC_' + pool)}
            </Dropdown.Item>;
        });

        return (alupPools.length > 1) && <div className="rc-odds-table-compact-race-allup rcbTableDrop">
            {
                <>
                    <DropdownButton
                        as={ButtonGroup}
                        key={`slAllup`}
                        id={`slAllup_${raceNo}`}
                        className='slAllup-dd'
                        variant={rbcState.betTypesByRace[raceNo]}
                        title={<>{t('LB_RBC_' + rbcState.betTypesByRace[raceNo])}<ArrowIcon direction='down' size={24}/></>}
                        align="start"
                    >
                        <Dropdown.Item eventKey="slAllupsub-header" value={t('LB_RACE_INFO_BLOCK_POOL')} key="slAllupsub-header" >{t('LB_RACE_INFO_BLOCK_POOL')}</Dropdown.Item>
                        {menuItem}

                    </DropdownButton>
                </>
            }
        </div>
    }

    const selectedBetTypeChange = (raceNo, pool) => {
        Object.keys(rbcState.selections).filter(x=> x.indexOf(`${rbcState.betTypesByRace[raceNo]}_${raceNo}`)>=0).forEach(x=> {
            delete rbcState.selections[x];
        });
        rbcState.betTypesByRace[raceNo] = pool;
        rbcState.subTypesByRace[raceNo] = pool==="FCT" ? "S" : "";
        setRbcMeetingState({ betTypesByRace: rbcState.betTypesByRace, subTypesByRace: rbcState.subTypesByRace });
    }

    const OddsSubDropdownMenu = ({raceNo}) => {
        let menuItem = rbcState.raceConfig.posPoolSubType.map(item => {
            return <Dropdown.Item eventKey={`${raceNo}_${item}`} onClick={() => selectedBetSubTypeChange(raceNo, item)} value={item} key={`${raceNo}_${item}`} >
                {t('LB_STYPE_'+item)}
            </Dropdown.Item>;
        });

        return <div className="rc-odds-table-compact-race-allup rcbTableDrop rcbTableSubDrop" style={{ paddingTop: rbcState.betType!=="ALUP" ? '8px' : '0px' }}>
            {
                <>
                    <DropdownButton
                        as={ButtonGroup}
                        key={`slAllup`}
                        id={`slAllup_${raceNo}`}
                        className='slAllup-dd'
                        variant={rbcState.subTypesByRace[raceNo]}
                        title={<>{t('LB_STYPE_'+rbcState.subTypesByRace[raceNo])}<ArrowIcon direction='down' size={24}/></>}
                        align="start"
                    >
                        <Dropdown.Item eventKey="slAllupsub-header" value={t('LB_RACE_INFO_BLOCK_POOL')} key="slAllupsub-header" >{t('LB_RACE_INFO_BLOCK_POOL')}</Dropdown.Item>
                        {menuItem}

                    </DropdownButton>
                </>
            }
        </div>
    }

    const selectedBetSubTypeChange = (raceNo, sType) => {
        rbcState.subTypesByRace[raceNo] = sType;
        Object.keys(rbcState.selections).filter(x=> x.indexOf(`${rbcState.betTypesByRace[raceNo]}_${raceNo}`)>=0).forEach(x=> {
            delete rbcState.selections[x];
        });
        setRbcMeetingState({ subTypesByRace: rbcState.subTypesByRace });
    }


    function RaceCardTable({raceNo, firstRaceNo, pos, legType}) {
        let cards = getAvailableRunnerNos(rbcState.meeting, raceNo);
        let showField = isShowField(firstRaceNo, pos);
        let rowTitleText = getNumHeaderLbl(firstRaceNo);
        let rowLegText = getLegHeaderLbl(firstRaceNo, pos);
        let rowBankerText = getBankerHeaderLbl(firstRaceNo);
        let betType = rbcState.betType==="ALUP" ? rbcState.betTypesByRace[firstRaceNo] : rbcState.betType;
        let isBanker = isShowBanker(firstRaceNo, betType);
        let isAlupFCTTable = i18n.language=="ch" && betType==="FCT" && (rbcState.betType=="ALUP" || rbcState.races.length > 1)
            && rbcState.subTypesByRace[firstRaceNo]!=="M";

        if ( rbcBetTypeConfig[betType].isCW ) {
            let cwPool = rbcState.meeting.pmPools.filter(x=> x.leg.races[0] === firstRaceNo && betType===x.oddsType)[0];
            cards = cwPool.cWinSelections.map(sel=> {
               return sel.composite;
            });
            cards.sort((x, y) => { return x.substring(1) - y.substring(1); });
        }

        let rows = [];
        rows = cards.map(num => {
            return <RaceCardRow raceNo={raceNo} firstRaceNo={firstRaceNo} num={num} legType={legType} key={`${raceNo}_${pos}_${num}`} />
        });
        let rowCss = isBanker ? 'rbcTableRow3' : 'rbcTableRow2';

        return <div className="rbcTableRowCompact">
            <div className={`${rowCss} ${isAlupFCTTable && "fctheader"}`}>
                <div >{t(rowTitleText)}</div>
                {isBanker && <div className={`rc-checkbox banker ${isAlupFCTTable && "verticalWord"}`}>{t(rowBankerText)}</div>}
                <div className={`rc-checkbox ${isAlupFCTTable && "verticalWord"}`}>{t(rowLegText)}</div>
            </div>
            {rows}
            {showField && <RaceCardRow raceNo={raceNo} firstRaceNo={firstRaceNo} num="F" legType={legType} key={`${raceNo}_${pos}_F`} />}
        </div>
    }

    function RaceCardRow({ raceNo, firstRaceNo, num, legType }) {
        let betType = rbcState.betType==="ALUP" ? rbcState.betTypesByRace[firstRaceNo] : rbcState.betType;
        let isBanker = isShowBanker(firstRaceNo, betType);
        let isAlupFCTTable = betType==="FCT" && (rbcState.betType=="ALUP" || rbcState.races.length > 1);
        let rowCss = isBanker ? 'rbcTableRow3' : 'rbcTableRow2';
        let isBankerDisabled = num !== 'F' && IsBetSelected(betType, raceNo, 'b1', 'F');
        if((rbcState.betType==="FCT" && rbcState.races.length>1 ) && ["S","B","BM","MB"].includes(rbcState.subTypesByRace[firstRaceNo]) ){
            legType='leg';
        }
        let isLegDisabled = num !== 'F' && IsBetSelected(betType, raceNo, legType, 'F');
        return <div className={rowCss}>
            <div className="no">{num !== 'F' ? num : t('LB_RC_POOL_FULL')}</div>
            {isBanker && <div className="rc-checkbox banker">
                {(num!=="F" || (isAlupFCTTable && rbcState.subTypesByRace[firstRaceNo]==="MB")) &&
                    <CheckBox  id={`${betType}_${raceNo}_b1_${num}`} onChange={(e) => handleClickOdds(betType, raceNo, 'b1', num)} defaultChecked={IsBetSelected(betType, raceNo, 'b1', num)} disabled={isBankerDisabled} />
                }
            </div>}
            <div className="rc-checkbox"><CheckBox  id={`${betType}_${raceNo}_${legType}_${num}`} onChange={(e) => handleClickOdds(betType, raceNo, legType, num)} defaultChecked={IsBetSelected(betType, raceNo, legType, num)} disabled={isLegDisabled} /></div>
        </div>
    }

    const handleClickOdds = (betType, raceNo, type, num) => {
        if ( IsBetSelected(betType, raceNo, type, num) ) {
            delete rbcState.selections[`${betType}_${raceNo}_${type}_${num}`];
        }
        else {
            // can only select 1 in following citerias
            if ( num==='F'
                || rbcState.betTypesByRace[raceNo]==="IWN"
                || rbcState.subTypesByRace[raceNo]==='S'
                || (['B', 'BM'].includes(rbcState.subTypesByRace[raceNo]) && ["b1", "b2", "b3"].includes(type)) ) {
                Object.keys(rbcState.selections).filter(x=> x.indexOf(`${betType}_${raceNo}_${type}`)>=0).forEach(x=> {
                    delete rbcState.selections[x];
                });
            }

            if ( rbcState.subTypesByRace[raceNo]!=='MB' ) {
                delete rbcState.selections[`${betType}_${raceNo}_b1_${num}`];
                delete rbcState.selections[`${betType}_${raceNo}_b2_${num}`];
                delete rbcState.selections[`${betType}_${raceNo}_b3_${num}`];
                delete rbcState.selections[`${betType}_${raceNo}_leg_${num}`];
            }

            rbcState.selections[`${betType}_${raceNo}_${type}_${num}`] = true;
        }
        setRbcMeetingState({ selections: rbcState.selections });
    }

    const IsBetSelected = (betType, raceNo, type, num) => {
        return rbcState.selections[`${betType}_${raceNo}_${type}_${num}`]===true;
    }

    const getSubTypeRaceCardTable = (raceNo) => {
        let arr = [];
        for ( let i=1; i<rbcBetTypeConfig?.[rbcState.betType].posCnt; i++ ) {
            let legType = i===rbcBetTypeConfig?.[rbcState.betType].posCnt-1 ? "leg" : `b${i+1}`;
            arr.push(<div className='rbcTableMain' key={`${raceNo}_${i+1}`}>
                <RaceCardTable raceNo={raceNo} firstRaceNo={raceNo} pos={i+1} legType={legType} />
            </div>);
        }
        return arr;
    }

    return (
        <div className='rbcBetArea'>

            {raceList.length > 0 && <RBCMultiLegSlider cnt={raceList.length} className="multi-leg-table" curSelectRaceNo={rbcState.curSelectRaceNo} races={rbcState.races}>
                {raceList.map(({raceNo, pos}) => {
                    let crossAlupFCT = rbcState.betType === 'ALUP' && rbcState.betTypesByRace[raceNo] === 'FCT';
                    let firstRaceNo = rbcBetTypeConfig[rbcState.betType].multiLeg ? raceList[0].raceNo : raceNo;
                    let legType = pos===rbcBetTypeConfig[rbcState.betType].posCnt ? "leg" : `b${pos}`;
                    return <div className='rbcTableMain' key={`${raceNo}_1_${pos}`}>
                            {(!rbcBetTypeConfig[rbcState.betType].hasSubType || alupFCT) && <RaceHeader raceNo={raceNo} />
                            }
                            {rbcState.betType === 'ALUP' && <OddsDropdownMenu raceNo={raceNo} />
                            }
                            {(crossAlupFCT || alupFCT) && <OddsSubDropdownMenu raceNo={raceNo} />
                            }
                            <RaceCardTable raceNo={raceNo} firstRaceNo={firstRaceNo} pos={pos} legType={legType} />
                        </div>

                })}
            </RBCMultiLegSlider>
            }
        </div>
    )
}

export default RBCBetCard;
