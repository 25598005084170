import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NewWindow } from '../../Common/home-common';
import { RbcContext } from '../Page/RebateCalculator';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';

const RBCResultCard = () => {
    const { rbcState } = useContext(RbcContext);
    const { t, i18n } = useTranslation();

    const [collaspseWin, setCollaspseWin] = useState(false);
    const [collaspseLose, setCollaspseLose] = useState(false);

    const handleClickCollapse = type => {
        if (type === 'win') {
            setCollaspseWin(!collaspseWin);
        } else {
            setCollaspseLose(!collaspseLose)
        }
    }

    function RBetInfoItem(props) {
        let itemList = props.items.map((item, index) => {
            return (<div className={`rbcRBetInfoItem ${(index + 2) % 2 ? 'odd' : ''}`} key={item.betLine}><span>{item.betLine}</span><span>$ {item.money}</span></div>)
        })
        return <div className='rbcRBetInfoList'>
            {itemList}
        </div>
    }

    const handleClickDemoLink = () => {
        let url = t('LB_RBC_LOSTINGBETINFO_LINK');
        NewWindow(url, '', 770, 500, 1, 1);
    }

    return (
        <div className='rbcResultArea'>
            <div className='rbcRHeader'>
                <div className='rbcRHeaderItem '>
                    <div className='rbcRHeaderLabel'>{t('LB_RBC_RACEDATE')}</div>
                    <div className='rbcRHeaderName'>
                        <div className='flexJCB'>
                            <span>{rbcState.date}</span>
                            <span>{t('LB_RBC_VENUE' + rbcState.venue)}</span>
                        </div>
                    </div>
                </div>
                <div className='rbcRHeaderItem odd'>
                    <div className='rbcRHeaderLabel'>{t('LB_RC_BETLINE')}</div>
                    <div className='rbcRHeaderName rbcBetLine'>
                        {rbcState.result.betLine}
                    </div>
                </div>
                <div className='rbcRHeaderItem '>
                    <div className='rbcRHeaderLabel'>{t('LB_RC_TOTAL_AMOUNT')}</div>
                    <div className='rbcRHeaderName'>
                        <div >
                            <span>{rbcState.result.totalAmount}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='rbcAmount'>
                <span>{t('LB_RBC_AMOUNT')}</span>
            </div>
            <div className='rbcRBet'>
                <div className='rbcRBetTitle df'>
                    <a className='iconArea' data-bs-toggle="collapse" href={`#collapseInvestmentCal`} role="button" aria-expanded="false" aria-controls={`collapseInvestmentCal`} onClick={() => handleClickCollapse('win')}>
                        <ArrowIcon className='' direction='down' change={collaspseWin} changeDirection='up' size={20} />
                    </a>
                    <span>{t('LB_RBC_WININGREFUNDBET')}</span>
                </div>
                <div className={`collapse rbcRBetInfo `} id="collapseInvestmentCal">
                    {rbcState.result.divBuf && <RBetInfoItem items={rbcState.result.divBuf} />}
                </div>
                <div className='rbcRHeaderItem rbcRBetBot rbcRBetBotWinLabel'>
                    <div className='rbcRHeaderLabel rbcRBetBotLabel '>{t('LB_RBC_DIVIDENDREFUNDTOTALAMOUNT')}</div>
                    <div className='rbcRBetBotName'>{rbcState.result.dividendAmount}</div>
                </div>
            </div>

            <div className='rbcRBet'>
                <div className='rbcRBetTitle df'>
                    <a className='iconArea' data-bs-toggle="collapse" href={`#collapseInvestmentCalLose`} role="button" aria-expanded="false" aria-controls={`collapseInvestmentCalLose`} onClick={() => handleClickCollapse('lose')}>
                        <ArrowIcon className='' direction='down' change={collaspseLose} changeDirection='up' size={20} />
                    </a>
                    <span>{t('LB_RBC_LOSTINGBET')}</span>
                </div>
                <div className={`collapse rbcRBetInfo `} id="collapseInvestmentCalLose">
                    {rbcState.result.lossBuf && <RBetInfoItem items={rbcState.result.lossBuf} />}
                </div>
                <div className='rbcRHeaderItem rbcRBetBot rbcRBetBotLoseLabel'>
                    <div className='rbcRHeaderLabel rbcRBetBotLabel'>{t('LB_RBC_LOSTINGBETTOTALAMOUNT')}</div>
                    <div className='rbcRBetBotName'>{rbcState.result.losingBetTotalAmount}</div>
                </div>
                <div className='rbcRHeaderItem rbcRBetBot '>
                    <div className='rbcRemarklabel'><span> {t('LB_RBC_LOSTINGBETINFO')}</span><span className='ball-panel-help-link' onClick={handleClickDemoLink}>{t('LB_RBC_LOSTINGBETINFOLastText')}</span><span> {i18n.language == 'en'?`.`:`。`}</span></div>
                </div>
                { rbcState.result.showRebateDetail ?
                    <div className='rbcRHeaderItem rbcRBetBot'>
                        <div className='rbcRHeaderLabel rbcRBetBotLabel'>{t('LB_RBC_REBATE')}</div>
                        <div className='rbcRBetBotName'>{rbcState.result.rebateAmount}</div>
                    </div>
                :null}
            </div>

            <div className='rbcRHeaderItem rbcRBetBot rbcRAmount rbcRBetBotTotalLabel'>
                <div className='rbcRHeaderLabel rbcRBetBotLabel'>{t('LB_RBC_OVERALLTOTAL')}</div>
                <div className='rbcRBetBotName'>{rbcState.result.totalDividentTotalAmount}</div>
            </div>
            {rbcState.result.showDividendsAnnouncedMsg &&
                <div className='rbcNoDivMsg'>
                    <div className=''>{t('LB_RBC_NO_DIV_MSG')}</div>
                </div>
            }

            <div className='rbcRemark'>
                <div className='rbcRemarkTitle'>{t('LB_RC_POOLINV_REMARKS')}:</div>
                <div className='rbcRemarklabel'>{t('REMARKLABEL')}</div>
            </div>

        </div>
    )
}

export default RBCResultCard;
