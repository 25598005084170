import React,{ useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import LangSelector from '../../Header/LangSelector'
import { Dropdown, DropdownButton} from 'react-bootstrap';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';
import CloseButton from '../../Common/ui-components/CloseButton';
import { useWindowSize } from '../../Common/CommonHooks.js'

const M6Calculator = () => {
  const { t } = useTranslation()
  const {isMobileSize} = useWindowSize()
  const initDefaultUnitBet = sessionStorage.getItem('Mk6DefaultUnitBetAmount')?Number(sessionStorage.getItem('Mk6DefaultUnitBetAmount')):10;
  const initPartialUnitBet = sessionStorage.getItem('Mk6PartialUnitBetAmount')?Number(sessionStorage.getItem('Mk6PartialUnitBetAmount')):5;
  const [multData, setMultData] = useState({
    leg: 6,
    inv: initDefaultUnitBet,
  })
  const [bankerData, setBankerData] = useState({
    banker: 0,
    leg: 6,
    inv: initDefaultUnitBet
  })
  const [numLegOpt,setNumLegOpt] = useState([])

  useEffect(()=> {
    const minNum = bankerData.banker ? 7 - bankerData.banker : 6
    const legOptions = Array.from(new Array(50 - bankerData.banker).keys()).slice(minNum)
    setBankerData(oldVal => ({...oldVal, leg: minNum}))
    setNumLegOpt(legOptions)
  },[bankerData.banker])

  const getChance = (total, count) => {
    if( total < count) return 0
    let val = 1
    let n = 1
    const length = count
    for(let i =0;i <length;i++ ){
        val *= total
        n *= count
        total--
        count--
    }
    return val/n
  }
  const getOptions = (optType) => {
    switch(optType){
      case 'selections':
        return Array.from(new Array(49 + 1).keys()).slice(6)
      case 'banker':
        return Array.from(new Array(6).keys())
      default:
        break
    }
  }

  const handleChangeSelect = (value,selectType) => {
    switch(selectType){
      case 'multLeg':
        setMultData(oldVal => ({
          inv: value > 6 ?  oldVal.inv : initDefaultUnitBet,
          leg: value
        }))
        break
      case 'multInv':
        setMultData(oldVal => ({
          ...oldVal,
          inv: value
        }))
        break
      case 'bankerLeg':
        setBankerData(oldVal=>({
          ...oldVal,
          inv: (oldVal.banker < 1 && oldVal.leg < 7 ) ?  oldVal.inv : initDefaultUnitBet,
          leg: value
        }))
        break
      case 'bankerInv':
        setBankerData(oldVal=>({
          ...oldVal,
          inv: value
        }))
        break
      case 'banker':
        setBankerData(oldVal=>({
          ...oldVal,
          inv: (oldVal.banker < 1 && oldVal.leg < 7 ) ?  oldVal.inv : initDefaultUnitBet,
          banker: value
        }))
        break
      default:
        break
    }
  }

  return <div className='marksix-calculator-container'>
    <div className='marksix-calculator'>
      <header className={`${isMobileSize?'popupTitle':''}`}>
        {t('LB_M6_CALCULATOR')}
        <LangSelector />
        {isMobileSize && <CloseButton isIOSPWA={true} />}
      </header>
      <div className='df m6-cal-main'>
        <div>
          <div className='m6-cal-title'>
            {t('LB_M6_RANDOM_MULTIPLE')}
          </div>
          <div className='m6-cal-content'>
            <div>
              <div>{t('LB_M6_RANDOM_NO_OF_SELECTIONS')} :</div>
              <DropdownButton
              title={<>{multData.leg}<ArrowIcon direction='down' size={24}/></>}
              align="start"
              value={multData.leg} >
                {getOptions('selections').map((item, index) => {
                  return <Dropdown.Item value={item} onClick={()=>handleChangeSelect(item,'multLeg')} active={multData.leg === item} key={item}>{item}</Dropdown.Item>
                })}
              </DropdownButton>
            </div>
            <div>
            <div>{t('LB_M6_CAL_INVESTMENT')} :</div>
              <DropdownButton
              title={<>{multData.inv === initDefaultUnitBet ? t('LB_M6_MULT_UNITBET').replace('{0}', initDefaultUnitBet) : t('LB_M6_MULT_PARBET').replace('{0}', initPartialUnitBet)}<ArrowIcon direction='down' size={24}/></>}
              align="start"
              disabled={multData.leg <= 6}
              value={multData.inv} >
                <Dropdown.Item value={initDefaultUnitBet} onClick={()=>handleChangeSelect(initDefaultUnitBet,'multInv')} active={multData.inv === initDefaultUnitBet} key={initDefaultUnitBet}>{t('LB_M6_MULT_UNITBET').replace('{0}', initDefaultUnitBet)}</Dropdown.Item>
                <Dropdown.Item value={initPartialUnitBet} onClick={()=>handleChangeSelect(initPartialUnitBet,'multInv')} active={multData.inv === initPartialUnitBet} key={initPartialUnitBet}>{t('LB_M6_MULT_PARBET').replace('{0}', initPartialUnitBet)}</Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
          <div className='m6-cal-amount'>
            <div>
              <div>{t('LB_M6_SINGLE_TABLE_CHANCE')}</div>
              <div>{getChance(multData.leg, 6).toLocaleString()} </div>
            </div>
            <div>
              <div>{t('LB_M6_SINGLE_TABLE_TOTALAMOUNT')}</div>
              <div>${(getChance(multData.leg, 6) * multData.inv).toLocaleString()}</div>
            </div>
          </div>
        </div>
        <div>
          <div className='m6-cal-title'>
            {t('LB_M6_RANDOM_BANKER')}
          </div>
          <div className='m6-cal-content'>
            <div>
              <div>{t('LB_M6_RANDOM_NO_OF_BANKER')} :</div>
              <DropdownButton
              title={<>{bankerData.banker}<ArrowIcon direction='down' size={24}/></>}
              align="start"
              value={bankerData.banker} >
                {getOptions('banker').map((item, index) => {
                  return <Dropdown.Item value={item} onClick={()=>handleChangeSelect(item,'banker')} active={bankerData.banker === item} key={item}>{item}</Dropdown.Item>
                })}
              </DropdownButton>
            </div>
            <div>
              <div>{t('LB_M6_RANDOM_NO_OF_LEGS')} :</div>
              <DropdownButton
              title={<>{bankerData.leg}<ArrowIcon direction='down' size={24}/></>}
              align="start"
              value={bankerData.leg} >
                {numLegOpt.map((item, index) => {
                  return <Dropdown.Item value={item} onClick={()=>handleChangeSelect(item,'bankerLeg')} active={bankerData.leg === item} key={item}>{item}</Dropdown.Item>
                })}
              </DropdownButton>
            </div>
            <div>
              <div>{t('LB_M6_CAL_INVESTMENT')} :</div>
              <DropdownButton
              title={<>{bankerData.inv === initDefaultUnitBet ? t('LB_M6_MULT_UNITBET').replace('{0}', initDefaultUnitBet) : t('LB_M6_MULT_PARBET').replace('{0}', initPartialUnitBet)}<ArrowIcon direction='down' size={24}/></>}
              align="start"
              disabled={bankerData.banker < 1 && bankerData.leg < 7 }
              value={bankerData.inv} >
                <Dropdown.Item value={initDefaultUnitBet} onClick={()=>handleChangeSelect(initDefaultUnitBet,'bankerInv')} active={bankerData.inv === initDefaultUnitBet} key={initDefaultUnitBet}>{t('LB_M6_MULT_UNITBET').replace('{0}', initDefaultUnitBet)}</Dropdown.Item>
                <Dropdown.Item value={initPartialUnitBet} onClick={()=>handleChangeSelect(initPartialUnitBet,'bankerInv')} active={bankerData.inv === initPartialUnitBet} key={initPartialUnitBet}>{t('LB_M6_MULT_PARBET').replace('{0}', initPartialUnitBet)}</Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
          <div className='m6-cal-amount'>
            <div>
              <div>{t('LB_M6_SINGLE_TABLE_CHANCE')}</div>
              <div>{getChance(bankerData.leg, 6 - bankerData.banker).toLocaleString()} </div>
            </div>
            <div>
              <div>{t('LB_M6_SINGLE_TABLE_TOTALAMOUNT')}</div>
              <div>${(getChance(bankerData.leg, 6 - bankerData.banker) * bankerData.inv).toLocaleString()}</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
}

export default M6Calculator