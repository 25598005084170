import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NewWindow } from '../../Common/home-common';
import Instructions from './Instructions'
import { useQuery } from '@apollo/client';
import { getMarksixStatQuery } from '../Data/DataQuery';
import { formatDateTime, scrollTo } from '../../Common/GlobalFunc';
import CheckBox from '../../Common/ui-components/CheckBox'
import { useWindowSize } from '../../Common/CommonHooks';
import Loading from '../../Common/ui-components/Loading';
import { errRedirectPage } from '../../Common/utils';


const BallPanelTable = ({ onChangeBall, size = 50, curBallList = [], type, selectedData = [], clear, onChangeMode, betTypeSelect, setBetTypeSelect }) => {

const statGqlVars = useQuery(getMarksixStatQuery(), {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onError:err=>{
        errRedirectPage(err);
    }
});
    const { isMobileSize } = useWindowSize();
  const [selectedBall, setSelectedBall] = useState([])
  const [selectedBankerBall, setSelectedBankerBall] = useState([])
  const { t, i18n } = useTranslation()

  const scSize = document.documentElement.clientWidth
  const [mySelectData, setMySelectData] = useState({ sinceNumber: true, timersNumber: false, isMultiCheck: true })

  useEffect(() => {
    if (curBallList?.numList?.length === size) {
      // single true
      setSelectedBall([])
    } else {
      setSelectedBall(curBallList.numList)
      setSelectedBankerBall(curBallList.bankerList || [])
    }
  }, [curBallList, size])

  const handleClickBall = (num) => {
    let ballList = []
    if (num === 50) {
      const allBall = Array.from(new Array(50).keys()).slice(1)
      setSelectedBall(allBall)
      onChangeBall(allBall)
      return
    }
    if (curBallList.numList.includes(num)) {
      ballList = curBallList.numList.filter(i => i !== num)
    } else if (curBallList.numList.length < size) {
      ballList = curBallList.numList.concat(num)
    }
    if (curBallList.numList.length >= size) {
      onChangeBall(curBallList.numList, num)
      return
    }
    const newBallList = ballList.sort((a, b) => a - b)
    setSelectedBall(newBallList)
    onChangeBall(newBallList)
    // scrollTo(0, document.querySelector('.ball-panel-table-container').offsetTop - 135 + 1)
  }

  const handleClickBankerBall = (num) => {
    let curLabelType = betTypeSelect.filter(x => x.checked)[0].type;
    const { numList, bankerList } = curBallList;
    const curSeletedList = [...new Set([...numList, ...bankerList])]
    const labelList = curLabelType === 'banker' ? 'bankerList' : 'numList';
    let ballList = []
    let newBallList={...curBallList};
    if (num === 50) {
      const allBall = Array.from(new Array(50).keys()).slice(1);
      ballList = allBall.filter(x => !bankerList.includes(x));
      curLabelType = 'leg';
      newBallList.numList=ballList.sort((a, b) => a - b);
    } else {
      if (curSeletedList.includes(num)) {
        if(bankerList.includes(num)){
          newBallList.bankerList=newBallList.bankerList.filter(i => i !== num);
          if(curLabelType !== 'banker'){
            newBallList.numList=[...newBallList.numList,num].sort((a, b) => a - b)
          }
        }
        if(numList.includes(num)){
          newBallList.numList=newBallList.numList.filter(i => i !== num);
          if(curLabelType === 'banker' && newBallList.bankerList.length < 5){
            newBallList.bankerList=[...newBallList.bankerList,num].sort((a, b) => a - b)
          }
        }
      } else {
        if (curLabelType === 'banker' && curBallList[labelList].length >= 5) {
          alert(t('LB_M6_BANKERMAXNUM'));
          return false;
        }
        newBallList[labelList] = [...curBallList[labelList], num].sort((a, b) => a - b)
      }
    }
    onChangeBall(newBallList, null, 0, curLabelType);
    // scrollTo(0, document.querySelector('.ball-panel-table-container').offsetTop - 135 + 1)
  }

  const BankerLabel = () => {
    return <div className='banker-selected-area'>
      {betTypeSelect.map(item => {
        return <div className={`banker-selected-text ${item.checked ? 'active' : ''}`} onClick={() => labelOnchange(item)} key={item.name1}>{t(item.name1)}</div>
      })}
    </div>
  }

  const labelOnchange = data => {
    let items = betTypeSelect;
    if (data.type === (items.filter(x => x.checked)[0].type)) {
      return;
    }
    items.map(item => {
      item.checked = !item.checked;
    })
    setBetTypeSelect(prev => { return [...items] });
  }



  const handleClickCheckbox = (type) => {
    mySelectData[type] = !mySelectData[type]
    if (type === 'isMultiCheck') {
      clear()
    }
    setMySelectData({ ...mySelectData })
    onChangeMode(mySelectData)
  }



  const footnotesFn = (t, statData) => {
    let dt = formatDateTime(statData.drawDate.substring(0,10));
    let id = (statData.year+'').substring(2) + "/" + String(statData.no).padStart(3, '0');
    return t('LB_M6_STAT_NOTES').replace('{0}', dt[0]).replace('{1}', id);
  }

  if (statGqlVars.data==null || statGqlVars.data.lotteryStats==null || statGqlVars.data.lotteryStats[0]==null ) {
    return <Loading/>;
  } else {
    let statData = statGqlVars.data.lotteryStats[0];
    const tableProps = {
      type,
      handleClickBankerBall,
      handleClickBall,
      selectedBall,
      isMobileSize,
      mySelectData,
      statData,
      handleClickCheckbox,
      selectedBankerBall,
      betTypeSelect
    }
    return <div className='ball-panel-table-container' id="ball-panel-table-container">
      <header className='df marksix-header'>
        <div className='df'>
          {t(`LB_M6_${type.toUpperCase()}`)}
        </div>
      </header>
      <Instructions selectedData={selectedData} clear={clear} tableType={mySelectData.isMultiCheck?'multiple':'banker'} />
      <StatisticsPanel {...tableProps} />
      {!mySelectData.isMultiCheck && <BankerLabel />}
      <Table {...tableProps} />
      <div className='statFootnotes'>{footnotesFn(t, statData)}</div>
    </div>
  }
}

export default BallPanelTable

const StatisticsPanel = (props) => {
  const {mySelectData,handleClickCheckbox}=props;
  const { t, i18n } = useTranslation();
    return <div className='df statisticsPanel'>
    <div className='statisticsPanelL'>
      <div className='panelTitle'>
        {t(`LB_M6_STAT_TITLE`)}
      </div>
      <div className='panelInfo'>
        <div className='df'>
          <CheckBox className='marginRight8px' product='marksix' type='checkbox' checked={mySelectData.sinceNumber} onChange={() => handleClickCheckbox('sinceNumber')} />
          <span className='panelInfoBlue'>{t(`LB_M6_STAT_SINCE`)}</span>
        </div>
        <div className='df'>
          <CheckBox className='marginRight8px' product='marksix' type='checkbox' checked={mySelectData.timersNumber} onChange={() => handleClickCheckbox('timersNumber')} />
          <span>{t(`LB_M6_STAT_TOTAL`)}</span>
        </div>
      </div>
    </div>
    <div className='df chooseModelBtn'>
      <div className={mySelectData.isMultiCheck ? 'active' : ''} onClick={() => handleClickCheckbox('isMultiCheck')}><div>{t(`LB_M6_STAT_SELECT`)}</div><div>{t(`LB_M6_RANDOM_SINGLE`)}</div></div>
      <div className={!mySelectData.isMultiCheck ? 'active' : ''} onClick={() => handleClickCheckbox('isMultiCheck')}><div>{t(`LB_M6_STAT_SELECT`)}</div><div>{t(`LB_M6_BANKERLABEL`)}</div></div>
    </div>

  </div>
}

const Row = ({ rowIndex, colorList, statData, isMobileSize, type, mySelectData, handleClickBankerBall,handleClickBall,selectedBall,selectedBankerBall,betTypeSelect }) => {
  const { t, i18n } = useTranslation();
  const row = []
  const rowSize = !isMobileSize ? 10 : 7;
  const ballSize = !isMobileSize ? 'l' : 'm';
  // const rowSize = 10
  // const ballSize = 'l';
  const curClickBallFn = !mySelectData.isMultiCheck ? handleClickBankerBall : handleClickBall;
  const showField = mySelectData.isMultiCheck || betTypeSelect.filter(x => x.checked)[0].type=="leg";
  for (let i = 1; i <= rowSize; i++) {
    const num = i + rowIndex * rowSize
    const color = colorList[num - 1]
    if (num === 50 && showField) {
      row.push(<div className='table-cell' key={num} onClick={() => {curClickBallFn(num)}}>
        {selectedBall.length < 49 ?
          <div className={`yellow-ball cp`}>
            {t('LB_M6_BALLPANEL_ALL')}
          </div>
          :
          <div className='table-cell-ball cp' >
            <img className={`ballsize-${ballSize}`} src={require(`../../../info/marksix/info/images/marksix-F_${i18n.language}.svg`)} alt={num} />
          </div>}
      </div>)
    }
    else if (num >= 50) {
      row.push(<div key={num}></div>)
    }
    else {
      let drawnData = statData.drawnNumbers.filter(x=> x.drawnNo==num)[0];
      row.push(<div className='table-cell' onClick={() => {curClickBallFn(num)}} key={num}>
        {!selectedBall.includes(num) && !selectedBankerBall.includes(num) ?
          <div className={`${color}-ball cp`}>
            {num}
          </div>
          : selectedBankerBall.includes(num) ?
            <div className='table-cell-ball cp'>
              <img className={`ballsize-${ballSize}`} src={require(`../../../info/marksix/info/images/marksix-gold-${num}.svg`)} alt={num} />
            </div>
            :
            <div className='table-cell-ball cp'>
              <img className={`ballsize-${ballSize}`} src={require(`../../../info/marksix/info/images/marksix-${num}.svg`)} alt={num} />
            </div>
        }
        {mySelectData.sinceNumber ?
          <div className='table-cell-sinceNumber'>{drawnData.lastDrawnIn==0 ? '-' : drawnData.lastDrawnIn}</div>
          : null}
        {mySelectData.timersNumber ?
          <div className='table-cell-timersNumber'>{drawnData.totalNumber}</div>
          : null}
      </div>)
    }
  }
  return <div className='table-row'>{row}</div>
}

const Table = (props) => {
  const table = []
  const rowSize = !props.isMobileSize ? 5 : props.type === 'single' ? 7 : 8
  let key = 1
  const colorList = []
  for (let rowIndex = 0; rowIndex < 5; rowIndex++) {
    for (let i = 1; i <= 10; i++) {
      const red = [1, 2, 7, 8].includes(i - rowIndex)
      const blue = [3, 4, -2, -3, 9, 10].includes(i - rowIndex)
      const color = red ? 'red' : blue ? 'blue' : 'green'
      colorList.push(color)
    }
  }
  for (let i = 0; i < rowSize; i++) {
    table.push(<Row key={key++} rowIndex={i} colorList={colorList} {...props} />)
  }

  return <div className='ball-panel-table'>
    {table}
  </div>
}