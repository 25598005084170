import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import { NewWindow } from '../../Common/home-common';
import { RacingContext } from '../../Home/RacingPage';
import { FoName, isRefund } from './FO_List';

export const clickRaceByRaceLink =(lang, bettype, date, venue, instNo) =>{
    let url = "/" + lang + "/racing/racebyrace/"+ bettype.toLowerCase() +"/" + date + "/" + venue + "/" + instNo;
    NewWindow(url, bettype+'RaceByRace', 900, 500, 1, 1);
}

export const sortFOResult = (res1, res2) => {
    let rfd1 = ["REFUND", "REFUNDBEFORESUSPEND"].includes(res1.combStatus);
    let rfd2 = ["REFUND", "REFUNDBEFORESUSPEND"].includes(res2.combStatus);
    if ( rfd1 && !rfd2 )
        return 1;
    if ( !rfd1 && rfd2 )
        return -1;
    if ( res2.curRes.points != res1.curRes.points )
        return res2.curRes.points - res1.curRes.points;
    if ( res2.curRes.point1st != res1.curRes.point1st )
        return res2.curRes.point1st - res1.curRes.point1st;
    if ( res2.curRes.point2nd != res1.curRes.point2nd )
        return res2.curRes.point2nd - res1.curRes.point2nd;
    if ( res2.curRes.point3rd != res1.curRes.point3rd )
        return res2.curRes.point3rd - res1.curRes.point3rd;
    let cnt4_1 = res1.curRes.count4th + (res1.curRes.numerator4th / res1.curRes.denominator4th);
    let cnt4_2 = res2.curRes.count4th + (res2.curRes.numerator4th / res2.curRes.denominator4th);
    if ( cnt4_2 != cnt4_1 )
        return cnt4_2 - cnt4_1;
    if (res1.number!=null && res2.number!=null)
        return res1.number  - res2.number;
    else
        return res1.name_en.localeCompare(res2.name_en);
}

const FOResult = ({betType}) => {
    const { t, i18n } = useTranslation();
    const context = useContext(RacingContext);
    const mtg = context.content.meeting;
    const foPools = mtg.foPools?.filter(x=> x?.oddsType==betType);

    const isLastRace = (foPool) => {
        const lastFoRace = foPool.includeRaces[foPool.includeRaces.length-1];
        return lastFoRace==context.content.raceNo;
    }

    const ResRowHeader = ({foPool}) => {
        return <div className="rc-odds-table-header table-row">
                <div className="sel">{t('LB_RC_'+betType+'_RES_SEL')}</div>
                <div className="name">{t('LB_RC_'+betType+'_RES_JK')}</div>
                <div className="pt">{t('LB_RC_'+betType+'_RES_PT')}</div>
                {isLastRace(foPool) ? <div className="remarks">{t('LB_RC_'+betType+'_RES_REMARKS')}</div> : null}
            </div>;
    }

    const FOResTables = () => {
        let arr = [];
        foPools.sort((x, y) => {
            if ( x.oddsType !== y.oddsType )
                return x.oddsType < y.oddsType ? -1 : 1;
            return x.instNo - y.instNo;
        });
        foPools.forEach(foPool => {
            if ( isRefund(foPool) ) {
                arr.push(<div className="forescontainer refund ">
                    <div className="refund">{t('LB_RC_'+betType+'_RES_REFUND').replace("{0}", foPool.instNo)}</div>
                </div>);
            }
            else {
                const foRes = foPool.selections.map(sel => {
                    let res = sel.results.filter(x=> x.raceNo==context.content.raceNo)[0];
                    sel.curRes = res;
                    return sel;
                });
                const foOthRes = foPool.otherSelections.map(sel => {
                    let res = sel.results.filter(x=> x.raceNo==context.content.raceNo)[0];
                    sel.curRes = res;
                    return sel;
                });
                if ( foRes.length>0 && foRes[0].curRes ) {
                    foRes.sort(sortFOResult);
                    if ( foOthRes.length > 0 && foOthRes[0].curRes ) {
                        foOthRes.sort(sortFOResult);
                    }
                    arr.push(<div className="forescontainer">
                        <Title instNo={foPool.instNo} />
                        <div className="forestable">
                            <ResRowHeader foPool={foPool}/>
                            <FOResTable foPool={foPool} foRes={foRes} />
                            {foOthRes[0]?.curRes ? <>
                                <SubTitle foPool={foPool} />
                                <FOResTable foPool={foPool} foRes={foOthRes} />
                            </> : null}
                        </div>
                        <FootNote instNo={foPool.instNo} />
                    </div>);
                }
            }
        });
        return arr;
    }

    const Points = ({sel}) => {
        if ( ["REFUND", "REFUNDBEFORESUSPEND"].includes(sel?.combStatus) ) {
            return t('LB_RC_FO_RFD');
        }
        else if ( sel?.curRes?.points>=0 ) {
            return sel?.curRes?.points;
        }
        return "---";
    }

    const FOResTable = ({foPool, foRes}) => {
        let idx = 0;
        return foRes.map(sel => {
            return <div className={`rc-odds-row ${idx++%2==0 ? 'sec':''}`}>
                <div className="sel">{sel.number}</div>
                <div className="name">
                    <FoName t={t} betType={betType} foPool={foPool} num={sel.number} code={sel.code} name={sel["name_"+i18n.language]} showNote={true} />
                </div>
                <div className="pt"><Points sel={sel} /></div>
                {isLastRace(foPool) ? <div className="remarks">{sel.combStatus=="WIN" ? t('LB_RC_FO_WIN') : '-'}</div> : null}
            </div>
        })
    }

    const Title = ({ instNo }) => {
        return <header>{t('LB_RC_' + betType + '_RES_TITLE').replace("{0}", instNo)}</header>
    }

    const SubTitle = ({foPool}) => {
        return <div className={`rc-odds-row`}>
            <div className="subtitleother">{t('LB_RC_' + betType + '_RES_SUBTITLE_OTHER')}</div>
            {isLastRace(foPool) ? <div></div> : null}
        </div>
        //return null
    }

    const FootNote = ({ instNo }) => {
        return <div className="forecfootnote"><a onClick={() => clickRaceByRaceLink(i18n.language, betType, context.content.date, context.content.venue, instNo)}>{t('LB_RC_' + betType + '_RES_RACEBYRACE')}</a></div>
    }

    if ( foPools.length==0 ) {
        return null;
    }
    else {
        return <div>
                <FOResTables />
            </div>
    }
}

export default FOResult;