import { parseTopic } from './parseTopic';
import { parseFoPool } from './parseFoPool';
import { parseOddsPool } from './parseOddsPool';
import { parseSelSta } from './parseSelSta';
import { parseRacSta } from './parseRacSta';
import { parseMeetingSta } from './parseMeetingSta';
import { parseInvOdds } from './parseInvOdds';
import { consoleLog } from '../../../Common/GlobalFunc';

export const parseMessage = (topic, message, contentRef,t) => {
    try{
        let topicInfo = parseTopic(topic);
        consoleLog("topic",topic,"topicInfo",topicInfo,"message",message);
        if(!topicInfo || topicInfo.date!=contentRef.current.date || topicInfo.venue!=contentRef.current.venue) {
            return;
        }
        let update = false;
        if ( contentRef.current.meeting!=null ) {
            if ( topicInfo.isFoPool ) {
                parseFoPool( contentRef.current, message, topicInfo,t);   // jkc tnc odds, details, fo sellstatus, fo status
            }
            else if ( topicInfo.isOdds ) {
                parseOddsPool(  contentRef.current, message, topicInfo); // odds, top_n , banker, wpq top 20 , wpq banker 10
            }
            else{
                if(topicInfo.pmPoolStatus){
                    parseSelSta(  contentRef.current, message, topicInfo); // pmPool.sellStatus, poolInv.status 
                }
                if(topicInfo.raceStatus){          
                    parseRacSta(  contentRef.current, message, topicInfo); // race status , content.alupFormula , runner status
                }
                if(topicInfo.meetingStatus){
                    parseMeetingSta(  contentRef.current,message, topicInfo);  // meeting status        
                }
                switch (topicInfo.displayType) {
                    case "inv":
                        parseInvOdds(  contentRef.current, message, topicInfo); //pool investment
                        break;
                }
            }
        }
        return update;
    }
    catch (e) {
        console.error(e, e.stack);
    }
}