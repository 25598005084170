import React, { useContext, useCallback, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FootballContext } from '../../Home/FootballPage';
import { ResultHeader, ResultFooter } from '../Common/ResultsBase';
import { toDateFormat } from '../Common/Common';
import { TournImage, FlagTournIcon } from '../Common/MatchBase';
import FootballTitle from '../Common/FootballTitle';

const getNoName = (scorer, nameLanguage, defaultValue = '') => {
    const no = scorer?.selections?.[0]?.str;
    const name = scorer?.selections?.[0]?.[nameLanguage];
    const noName = (no || name) ? `${no} ${name}` : defaultValue
    return noName
}

export const filterMatchData = (match, t, i18n) => {
    const nameLanguage = "name_"+i18n.language;
    const hName = match.homeTeam[nameLanguage];
    const aName = match.awayTeam[nameLanguage];
    const combinations = match?.foPools?.[0]?.lines?.[0]?.combinations || [];
    // firstScorer
    let firstScorer = combinations.filter(item => item.status === 'WIN').sort((a,b) => a.winOrd - b.winOrd)
    firstScorer = firstScorer.map(item => getNoName(item, nameLanguage)).filter(item => item !== '');
    // REFUND
    let homeRefund = []
    let awayRefund = []
    let refundForAll = match?.foPools?.[0]?.status.indexOf('REFUND')>=0
    if (!refundForAll) {
        homeRefund = combinations.filter(item => item.status.indexOf('REFUND')>=0 && item?.str?.[0] === '1') || []
        awayRefund = combinations.filter(item => item.status.indexOf('REFUND')>=0 && item?.str?.[0] === '2') || []
        homeRefund = homeRefund.map(item => getNoName(item, nameLanguage)).filter(item => item !== '').sort();
        awayRefund = awayRefund.map(item => getNoName(item, nameLanguage)).filter(item => item !== '').sort();
    }

    return {
        eventId: match.frontEndId!=null && match.frontEndId!='' ? match.frontEndId : `${match.matchDayOfWeek} ${match.matchNumber}`,
        matchDate: toDateFormat(match.kickOffTime, true, false),
        tournCode: match.tournament?.code,
        tournName: match.tournament?.[nameLanguage],
        hName,
        aName,
        h2a: <>{hName} <br/> {aName}</>,
        firstScorer,
        refundForAll,
        homeRefund,
        awayRefund
    }
}

const TableHeader = memo(() => {
    const {t} = useTranslation()
    return (
        <>
            <tr className='table-header'>
                <td rowspan='2'>{t('LB_FB_BY_DATE')}</td>
                <td rowspan='2'>{t('LB_FB_EVENT_ID')}</td>
                <td rowspan='2'>
                    <FlagTournIcon />
                </td>
                <td rowspan='2' className='text-align-left'>{t('LB_FB_HOME_TEAM')}<br/>{t('LB_FB_AWAY_TEAM')}</td>
                <td rowspan='2'>{t('LB_FB_TITLE_FGS')}</td>
                <td colspan='2' className='rfd'>{t('LB_FB_RFD')}</td>
            </tr>
            <tr className='table-header'>
                <td>{t('LB_FB_HOME')}</td>
                <td>{t('LB_FB_AWAY')}</td>
            </tr>
        </>
    )

})

const TableRow = memo(({matchData, className}) => {
    const {t, i18n} = useTranslation()
    const {
        eventId,
        matchDate,
        tournCode,
        tournName,
        h2a,
        firstScorer,
        refundForAll,
        homeRefund,
        awayRefund
    } = filterMatchData(matchData, t, i18n);

    // for the same height
    const homeLen = homeRefund.length
    const awayLen = awayRefund.length
    const maxLen = awayRefund.length = homeRefund.length = Math.max(homeLen, awayLen)
    homeRefund.fill(' ', homeLen, maxLen)
    awayRefund.fill(' ', awayLen, maxLen)

    return <tr className={`${className}`}>
        <td>{matchDate}</td>
        <td>{eventId}</td>
        <td className='TournImage'><TournImage code={tournCode} name={tournName} /></td>
        <td className='text-align-left'>{h2a}</td>
        <td className='text-align-left white-space-pre'>{firstScorer.length > 0 ? firstScorer.join(`\n`) : '-'}</td>
        {
            refundForAll ? <td colspan='2' className='color-red'>{t('LB_FB_REFUND_FOR_ALL')}</td> : <>
                <td className='text-align-left refund white-space-pre'>{homeRefund.join(`\n`)}<div className='keep-same-width-hack'>{awayRefund.join(`\n`)}</div></td>
                <td className='text-align-left refund white-space-pre'><div className='keep-same-width-hack'>{homeRefund.join(`\n`)}</div>{awayRefund.join(`\n`)}</td>
            </>
        }
    </tr>
})

const TableHeaderMobile  = memo(({ data })=> {
    const {eventId, matchDate, tournName, hName, aName} = data
    return <div className='fb-fgsres-table-header-m'>
        <div className='h2a'>
            <span className='h-name'>(H) {hName}</span>
            <span className='vs-container'>
                <span className='vs'>vs</span>
            </span>
            <span className='a-name'>{aName} (A)</span>
        </div>
        <div className='other-information'>
            <span className='event-id'>{eventId}</span>
            <span className='match-date'>{matchDate}</span>
            <span className='tourn-name'>{tournName}</span>
        </div>
    </div>
})


const TableBodyMobile  = memo(({ data })=> {
    const {t} = useTranslation()
    const {firstScorer, homeRefund, awayRefund, refundForAll} = data
    return <div className='fb-fgsres-table-body-m'>
        <div className='first-scorer'>
            <span className='title'>{t('LB_FB_TITLE_FGS')}</span>
            <span className='data white-space-pre'>{firstScorer.length > 0 ? firstScorer.join(`\n`) : '-'}</span>
        </div>
        <div className='home-refund'>
            <span className='title'>{t('LB_FB_RFD')} ({t('LB_FB_HOME')})</span>
            <span className='data white-space-pre'>{
                refundForAll ? <span className='color-red'>{t('LB_FB_REFUND_FOR_ALL')}</span> : homeRefund.join(`\n`)
            }</span>
        </div>
        <div className='away-refund'>
            <span className='title'>{t('LB_FB_RFD')} ({t('LB_FB_AWAY')})</span>
            <span className='data white-space-pre'>{refundForAll ? <span className='color-red'>{t('LB_FB_REFUND_FOR_ALL')}</span> : awayRefund.join(`\n`)}</span>
        </div>
    </div>
})

const TableMobile = memo(({matchData})=> {
    const {t, i18n} = useTranslation()
    const data = filterMatchData(matchData, t, i18n);
    return <div className='fb-fgsres-table-m'>
        <TableHeaderMobile data={data} />
        <TableBodyMobile data={data} />
    </div>
})


const FGSRES = memo(() => {
    const { t, i18n } = useTranslation();
    const context = useContext(FootballContext);
    const matches = context.content.matchData.matches;

    const Table = useCallback(() => {
        const table = matches.map((item, index)=> <TableRow matchData={item} className={index %2 ? 'even' : 'odd'} key={item.frontEndId} /> )
        return (
            <table className='fb-fgsres-table'>
                <TableHeader t={t}/>
                {table}
            </table>
        )
    }, [matches])

    const TablesMobile = useCallback(() => {
        const table = matches.map((item)=> <TableMobile matchData={item} key={item.frontEndId} />)
        return <div className='fb-fgsres-tables-m'>
            {table}
        </div>
    }, [matches])

    return <>
        <ResultHeader />
        <header className='football-fgsres-title-m'>
            <FootballTitle isPoolDefined={true} />
        </header>
        <section>
            <div className='fb-fgsres-table-container'>
                <Table />
                <TablesMobile />
            </div>
            <ResultFooter />
        </section>
    </>

})
export default FGSRES;