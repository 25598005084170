import React, { useState, useEffect, useContext } from 'react'
import ResultsTable from './ResultsTable'
import { RrawResultContext } from '../Page/CheckDrawResult';

const CheckResultsTable = ({ getInfo, onclickResultDtls, apiErr=false, selectedTableData }) => {
    const [resultList, setResultList] = useState([])
    const [pageNumber, setPageNumber] = useState(1)

    useEffect(() => {
        checkRrawNumberFn(getInfo,selectedTableData);
    }, [getInfo,selectedTableData])

    const checkRrawNumberFn = (infoData,selectedObj) => {
        let bankerList = selectedObj[0].bankerList || [];
        if (bankerList[0] == '') {
            bankerList = [];
        }
        const numList = selectedObj[0].numList || [];
        getBingoResults(infoData, bankerList, numList)
    }

    function getBingoResults(result60days, bankerList, numList) {
        let lastNDraws = [];
        let inNumbersBk = bankerList;
        let inNumbers = numList;
        for (let i = 0; i < result60days.length; i++) {
            let drawNos = result60days[i].drawResult.drawnNo;
            let bankerBingoCount = 0;
            let bingoCount = 0;
            for (let x in drawNos) {
                inNumbersBk.find(item=>{
                    if(item == drawNos[x]){
                        bankerBingoCount++;
                        return true;
                    }
                })
                inNumbers.find(item=>{
                    if(item == drawNos[x]){
                        bingoCount++;
                        return true;
                    }
                })
            }
            // special handle of 5 bankers and 4 bankers
            let isBingo = false;
            if (inNumbersBk.length == 5) {
                if (bankerBingoCount >= 3)
                    isBingo = true;
                else if (bankerBingoCount == 2 && bingoCount >= 1)
                    isBingo = true;
            } else if (inNumbersBk.length == 4) {
                if (bankerBingoCount >= 3)
                    isBingo = true;
                else if (bankerBingoCount == 2 && bingoCount >= 1)
                    isBingo = true;
                else if (bankerBingoCount == 1 && bingoCount >= 2)
                    isBingo = true;
            } else {
                if (bankerBingoCount + bingoCount >= 3)
                    isBingo = true;
            }
            if (isBingo) {
                lastNDraws = lastNDraws.concat(result60days[i]);
            }
        }
        setResultList(lastNDraws)
    }

    return <div className=''>
        <ResultsTable onclickResultDtls={onclickResultDtls} draws={resultList} apiErr={apiErr} setPageNumber={setPageNumber} pageNumber={pageNumber}/>
    </div>
}

export default CheckResultsTable