import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NewWindow } from '../../Common/home-common';
import { handleClickHelpLink, handleClickChanceTableLink } from './Common';
import { setMyFavCookie, MyFavouritesContext } from './MyFavourites';
import { ConfigContext } from '../../Common/ConfigHelper';
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import { MarksixContext } from '../../Home/MarksixPage';
import { isStartSell, isSnowballPage } from './Common';
import { useBetSlip, AddM6SlipFunc } from '../../BetSlip/BetSlipHooks';
import { glassboxCustomEvent } from '../../Common/utils/glassbox';
import { useWindowSize } from '../../Common/CommonHooks';
import { isPmuEoD, eodMessage } from '../../Common/GlobalFunc';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';

const Instructions = ({ activeType, t, selectedData = [], clear }) => {
    const context = useContext(MarksixContext);
    const isSell = isStartSell(context.nextDraw);
    const [openIns, setOpenIns] = useState(false);
    const [canAddToSlip, setCanAddToSlip] = useState(true);
    const { updateMyFavourites, curpageFavCheckArr, setCurpageFavCheckArr, setFavInitCheckState } = useContext(MyFavouritesContext);
    const { addToBetSlip, betLines } = useBetSlip();
    const { isMobileSize } = useWindowSize();

    useEffect(() => {
        let num = 0;
        let canSave = true;
        if (activeType.toLowerCase() === 'single') {
            selectedData.forEach((item) => {
                if (item.numList.length >= 6) {
                    num++;
                } else if (item.numList.length > 0) canSave = false;
                else if (item.numList.length === 0 && item.isFav) canSave = false;
            });
        } else if (activeType.toLowerCase() === 'multiple') {
            if (selectedData[0].numList.length >= 6) {
                num = selectedData[0].chance;
            }
        } else if (['random', 'banker'].includes(activeType.toLowerCase())) {
            selectedData.forEach((item) => {
                const cha = item.chance || 0;
                num += cha;
            });
        }
        if (num === 0) canSave = false;
        setCanAddToSlip(canSave);
    }, [selectedData]);

    const handleClickDemoLink = () => {
        let url = t('LB_M6_SELECT_NUMBERS_DEMO_LINK');
        NewWindow(url, '', 770, 500, 1, 1);
    };

    const handleClickAddToSlip = () => {
        let favCheckedList = isMobileSize ? curpageFavCheckArr.filter((item) => item.checked) : [];
        if (!isSell) {
            if (isPmuEoD() && eodMessage()?.length > 0) {
                return window.alert(eodMessage());
            } else {
                return window.alert(t('LB_M6_MSG_NOT_DIFINED'));
            }
        }

        if (!canAddToSlip && favCheckedList.length < 1) return window.alert(t('LB_M6_MSG_INSU_SELECTIONS'));
        const fav = selectedData.filter((item) => item.isFav);
        for (let i = 0; i < selectedData.length; i++) {
            if (selectedData[i].numList.length >= 49) {
                return window.alert(t('LB_M6_MSG_SELECT_NUMBERS_MULT_49'));
            }
        }
        // if(fav.length > 0){
        //   const favMax = setMyFavCookie(fav,t('LB_M6_MSG_MYFAV_MAX'))
        //   if(favMax) return
        //   window.alert(t('LB_M6_MSG_MYFAV'))
        //   updateMyFavourites()
        // }
        if(favCheckedList.length>0){
            favCheckedList=favCheckedList.map(item=>{
                return {...item,random:false,checked:false};
            })
            const dateNow = Date.now();
            setFavInitCheckState(dateNow)
            setTimeout(() => {
                setFavInitCheckState('')
            }, 300);
        }
        const betList = AddM6SlipFunc([...selectedData, ...favCheckedList], context);
        addToBetSlip(betList) && clear();
        glassboxCustomEvent('Add_to_Slip');
    };

    return (
        <div className="ball-panel-instructions">
            <div className="ball-panel-instructions-top">
                <div className="df instructions-left">
                    <div className="ms-switch-btn-icon">
                        <ArrowIcon
                            onClick={() => setOpenIns(!openIns)}
                            className="hueRotate"
                            direction="down"
                            change={openIns}
                            changeDirection="up"
                            size={20}
                        />
                    </div>
                    {t('LB_M6_HOW_TO_SELECT')}
                </div>
                <div className="instructions-btn">
                    <button
                        onClick={() => handleClickAddToSlip()}
                        className="add-to-slip AddToSlip"
                        title={t('LB_ADDTOSLIP')}
                    >
                        {t('LB_ADDTOSLIP')}
                    </button>
                </div>
            </div>
            {openIns && (
                <div className="ball-panel-instructions-bottom">
                    <div>
                        <span>{t(`LB_M6_RANDOM_HELP_${activeType}1`)}</span>
                    </div>
                    {/* <div ><span>{t('LB_M6_RANDOM_HELP_SINGLE2')}</span><span className='ball-panel-help-link' onClick={handleClickDemoLink} alt=''>{t('LB_M6_MSG_SELECT_NUMBERS_DEMO')}</span><span>{t('LB_M6_MSG_SELECT_NUMBERS_HELP4')}</span></div> */}
                </div>
            )}
        </div>
    );
};

export const QuickPickTopTable = ({
    clear,
    selectedData = [],
    activeType = 'SINGLE',
    onChangeActiveType,
    onChangeComb,
    onClickRandom
}) => {
    const { t, i18n } = useTranslation();
    const context = useContext(MarksixContext);
    const isSnowball = isSnowballPage(context.pageName);
    const [numSelections, setNumSelections] = useState(7);
    const [numBanker, setNumBanker] = useState(1);
    const [numLeg, setNumLeg] = useState(6);
    const [entrie, setEntrie] = useState(4);
    const [numLegOpt, setNumLegOpt] = useState([]);
    const { marksix } = useContext(ConfigContext);
    const { isMobileSize } = useWindowSize();
    useEffect(() => {
        onChangeComb({
            entrie,
            numSelections,
            numBanker,
            numLeg
        });
    }, [entrie, numSelections, numBanker, numLeg]);

    useEffect(() => {
        const legOptions = Array.from(new Array(50 - numBanker).keys()).slice(7 - numBanker);
        setNumLeg(7 - numBanker);
        setNumLegOpt(legOptions);
    }, [numBanker]);

    const tabList = [
        { label: t('LB_M6_RANDOM_SINGLE'), value: 'SINGLE' },
        { label: t('LB_M6_RANDOM_MULTIPLE'), value: 'MULTIPLE' },
        { label: t('LB_M6_RANDOM_BANKER'), value: 'BANKER' }
    ];

    const getOptions = (optType) => {
        if (!marksix?.random) return [];
        const randomConfig = marksix['random'];
        switch (optType) {
            case 'entrie':
                return Array.from(new Array((randomConfig?.NO_OF_ENTRIES_MAX || 15) + 1).keys()).slice(
                    randomConfig?.NO_OF_ENTRIES_MIN || 1
                );
            case 'numSelections':
                return Array.from(new Array((randomConfig?.NO_OF_SELECTIONS_MAX || 40) + 1).keys()).slice(
                    randomConfig?.NO_OF_SELECTIONS_MIN || 7
                );
            case 'numBanker':
                return Array.from(new Array(6).keys()).slice(1);
            default:
                break;
        }
    };

    const handleChangeSelect = (e, selectType) => {
        const value = e;
        switch (selectType) {
            case 'entrie':
                setEntrie(value);
                break;
            case 'numSelections':
                setNumSelections(value);
                break;
            case 'numBanker':
                setNumBanker(value);
                break;
            case 'numLeg':
                setNumLeg(value);
                break;
            default:
                break;
        }
    };

    const handleClickReset = () => {
        setNumSelections(7);
        setNumBanker(1);
        setNumLeg(6);
        setEntrie(4);
    };

    return (
        <div className="quick-pick-top-container">
            <Instructions t={t} activeType={activeType} selectedData={selectedData} clear={clear} />
            <div className="quick-pick-type-tab">
                {tabList.map((item) => (
                    <div>
                        <div
                            key={item.value}
                            onClick={() => onChangeActiveType(item.value)}
                            className={activeType === item.value ? 'active-tab' : ''}
                        >
                            {item.label}
                        </div>
                    </div>
                ))}
            </div>
            {activeType !== 'SINGLE' && (
                <div className="marsix-chance-help-m">
                    <div className="df marsix-chance-help" onClick={handleClickChanceTableLink}>
                        <i className="icon-table"></i>
                        <div>{t(`LB_M6_HEADER_CHANCE_TABLE_${activeType}`)}</div>
                    </div>
                </div>
            )}
            <div className="ramdom-selection-panel">
                <div>
                    {activeType === 'MULTIPLE' && (
                        <div>
                            <div>{t('LB_M6_RANDOM_NO_OF_SELECTIONS')} :</div>
                            <DropdownButton
                                title={
                                    <>
                                        {numSelections}
                                        <ArrowIcon direction="down" size={24} />
                                    </>
                                }
                                align="start"
                                value={numSelections}
                            >
                                {getOptions('numSelections').map((item, index) => {
                                    return (
                                        <Dropdown.Item
                                            value={item}
                                            onClick={() => handleChangeSelect(item, 'numSelections')}
                                            active={numSelections === item}
                                            key={item}
                                        >
                                            {item}
                                        </Dropdown.Item>
                                    );
                                })}
                            </DropdownButton>
                        </div>
                    )}
                    {activeType === 'BANKER' && (
                        <>
                            <div>
                                <div>{t('LB_M6_RANDOM_NO_OF_BANKER')} :</div>
                                <DropdownButton
                                    title={
                                        <>
                                            {numBanker}
                                            <ArrowIcon direction="down" size={24} />
                                        </>
                                    }
                                    align="start"
                                    value={numBanker}
                                >
                                    {getOptions('numBanker').map((item, index) => {
                                        return (
                                            <Dropdown.Item
                                                value={item}
                                                onClick={() => handleChangeSelect(item, 'numBanker')}
                                                active={numBanker === item}
                                                key={item}
                                            >
                                                {item}
                                            </Dropdown.Item>
                                        );
                                    })}
                                </DropdownButton>
                            </div>
                            <div>
                                <div>{t('LB_M6_RANDOM_NO_OF_LEGS')} :</div>
                                <DropdownButton
                                    title={
                                        <>
                                            {numLeg}
                                            <ArrowIcon direction="down" size={24} />
                                        </>
                                    }
                                    align="start"
                                    value={numLeg}
                                >
                                    {numLegOpt.map((item, index) => {
                                        return (
                                            <Dropdown.Item
                                                value={item}
                                                onClick={() => handleChangeSelect(item, 'numLeg')}
                                                active={numLeg === item}
                                                key={item}
                                            >
                                                {item}
                                            </Dropdown.Item>
                                        );
                                    })}
                                </DropdownButton>
                            </div>
                        </>
                    )}
                    <div>
                        <div>{t('LB_M6_RANDOM_NO_OF_ENTRIES')} :</div>
                        <DropdownButton
                            title={
                                <>
                                    {entrie}
                                    <ArrowIcon direction="down" size={24} />
                                </>
                            }
                            align="start"
                            value={entrie}
                        >
                            {getOptions('entrie').map((item, index) => {
                                return (
                                    <Dropdown.Item
                                        value={item}
                                        onClick={() => handleChangeSelect(item, 'entrie')}
                                        active={entrie === item}
                                        key={item}
                                    >
                                        {item}
                                    </Dropdown.Item>
                                );
                            })}
                        </DropdownButton>
                    </div>
                </div>
                <div>
                    {isMobileSize?<div className='m6_random_reset' onClick={handleClickReset}>
                        {t('LB_RC_RESET')}
                    </div>:
                    <button className="cta_m6" title={t('LB_RC_RESET')} onClick={handleClickReset}>
                        {t('LB_RC_RESET')}
                    </button>
                    }
                    <button className="cta_m6" title={t('LB_M6_RANDOM')} onClick={onClickRandom}>
                        {t('LB_M6_RANDOM')}
                    </button>
                </div>
            </div>
        </div>
    );
};
