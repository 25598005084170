import React, { useContext, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FootballContext } from '../../Home/FootballPage';
import { NewWindow } from '../../Common/home-common';
import { toDateFormat, isEliminated, isRefund, getFootballHelpUrl } from './Common';
import { SingleMessage } from './FootballTitle';
import { TournImage, goToAllOddsPage } from './MatchBase';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';

export const Coupon = ({ title, singleTourn, handleOnClick, isChecked, showHelpBtn, showAllupTxt, betType, isShowSupended,frontEndId }) => {

    const { t, i18n } = useTranslation();
    const context = useContext(FootballContext);

    const HelpBtn = () => {
        return <div className="HelpBtn" onClick={(e) => {NewWindow(getFootballHelpUrl(betType, i18n.language), 'specialSite', 800, 600, 1, 1); e.preventDefault();e.stopPropagation();}} title={t('LB_FB_HELP_ALT')} />
    }

    return <>
        {
            !context.state.windowSize.isMobileSize ? <div onClick={() => { handleOnClick(betType) }} className={`coupon ${!isChecked? 'couponExpand' : 'couponCollaspse' }`} id={`coupon${betType}${frontEndId ? `_${frontEndId}` : ''}`}>

                <div className='left'>

                    <div className={`couponBtn`}>
                        <ArrowIcon className='hueRotate' direction='down' change={isChecked} changeDirection='up' size={20} />
                    </div>

                    <div className='desc'>
                        {title}

                    </div>

                    {showHelpBtn && <HelpBtn />}
                    <div className='supplement'>
                        {showAllupTxt && <SingleMessage betType={betType} />}
                    </div>
                    {isShowSupended && <div className='lb-suspended'>-{t('LB_FB_SUSPENDED')}</div>}

                </div>
            </div>
                :
                <div className='coupon-m'  id={`coupon${betType}`}>
                    <div className='desc'>{title}</div>
                    <div className='supplement'>
                        {showAllupTxt && <SingleMessage betType={betType} />}
                    </div>
                </div>
        }
    </>
}

export const SingleBetypePageCoupon = ({ showTitle, singleTourn, handleOnClick, isChecked, esst, handleOnClickShowAll, isShowAll, isShowSupended, betType }) => {

    const { t, i18n } = useTranslation();
    const context = useContext(FootballContext);
    const code = singleTourn.code;
    const name = singleTourn['name_'+i18n.language];
    const title = <span>{singleTourn.frontEndId} { !context.state.windowSize.isMobileSize ? <TournImage code={code} name={name} /> : null } <span style={{marginLeft: 12}}>{name}</span></span>

    return <div className='tournHeader' id={`coupon${betType}_${singleTourn.frontEndId}`}>
                <div className='tournName'>
                    {handleOnClick ? <div className="coupon"><div className={`couponBtn`} onClick={handleOnClick} >
                        <ArrowIcon className='hueRotate' direction='down' change={isChecked} changeDirection='up' size={20} />
                    </div></div> : null }
                    {showTitle ? <div className='desc' onClick={handleOnClick}>
                        {title}
                    </div> : null}
                    {isShowSupended && <div className='lb-suspended'>-{t('LB_FB_SUSPENDED')}</div>}
                </div>
                <div className='tournEsst'>
                    <div className="tournEsstLbl">{t('LB_FB_CHP_TABLE_DATE')}</div>
                    <div>{toDateFormat(esst, true, true)}</div>
                </div>
                <div className='tournButton'>
                    <div className='df chooseModelBtn'>
                        <div className={isShowAll ? 'active' : ''} onClick={() => handleOnClickShowAll()}><div>{t('LB_FB_CHP_TABLE_SHOWALL')}</div></div>
                        <div className={!isShowAll ? 'active' : ''} onClick={() => handleOnClickShowAll()}><div>{t('LB_FB_CHP_TABLE_SELLONLY')}</div></div>
                    </div>
                </div>
            </div>
}

export const ShowAllBtn = ({tournInfo, esst, isShowAll, handleOnClickShowAll}) => {
    const { t } = useTranslation();
    return <div className="showAllRow">
        <div className="showAllEsst">{esst!=null ? <>
            {tournInfo!=null ? <div>{tournInfo}</div> : null}
            <div className="tournEsstLbl">{t('LB_FB_CHP_TABLE_DATE')}</div>
            <div>{toDateFormat(esst, true, true)}</div></>: null}</div>
        <div>
            <div className='df chooseModelBtn'>
                <div className={isShowAll ? 'active' : ''} onClick={() => handleOnClickShowAll()}><div>{t('LB_FB_CHP_TABLE_SHOWALL')}</div></div>
                <div className={!isShowAll ? 'active' : ''} onClick={() => handleOnClickShowAll()}><div>{t('LB_FB_CHP_TABLE_SELLONLY')}</div></div>
            </div>
        </div>
    </div>
}

export const sortTournOdds = (x, y) => {
    let xOdds = x.currentOdds;
    let yOdds = y.currentOdds;
    let xVal = 99999999;
    let yVal = 99999999;
    if ( isRefund(x) )
        xVal = 99999999;
    else if ( isEliminated(x) )
        xVal = 999999;
    else
        xVal = parseFloat(xOdds);
    if ( isRefund(y) )
        yVal = 99999999;
    else if ( isEliminated(y) )
        yVal = 999999;
    else
        yVal = parseFloat(yOdds);
    if ( xVal==yVal )
        return parseInt(x.selections[0].str) - parseInt(y.selections[0].str);
    return xVal - yVal;
}

export const AcceptInplayOnlyLink = ({match, betType}) => {
    const { t } = useTranslation();
    const context = useContext(FootballContext);
    return <div className="acceptInplayOnly" onClick={()=> { goToAllOddsPage(match, context, betType) }}>
        <div>{t('LB_FB_ACCEPT_INPLAY_ONLY')}</div>
    </div>
}

export const isInplayMatch = (status) =>{
    return ["FIRSTHALF",
    "FIRSTHALFCOMPLETED",
    "SECONDHALF",
    "FULLTIMECOMPLETED",
    "FIRSTHALFET",
    "FIRSTHALFETCOMPLETED",
    "SECONDHALFET",
    "SECONDHALFETCOMPLETED",
    "PENALTYKICK",
    "PENALTYKICKCOMPLETED"].includes(status)
}