import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isVoidedRace, isAbandonedRace } from './Common';

const NoBettingAndVoidedMsgUI = (props) => {
    const { t } = useTranslation();
    const { content } = props;
    const [text, setText] = useState('');

    useEffect(() => {
        if (!content) return;
        const { raceNo, meeting } = content;
        const { poolInvs, races } = meeting;
        const isNoBetService = poolInvs.filter(x => x.leg.races[0] === raceNo).length === 0;
        const isVoided = isVoidedRace(content, meeting, raceNo);
        const isAbandon = isAbandonedRace(meeting, raceNo)
        if(isAbandon){
            setText('LB_RC_RACE_ABANDON')
        }else if (isVoided) {
            setText('LB_RC_MATCH_VOIDED')
        } else if (isNoBetService) {
            setText('LB_RC_NO_BETTING_SERVICE')
        } else {
            setText('')
        }
    }, [content]);

    return (<>
        {content.page==="HOME" && text ?
            <div className="racingBlackMessage">
                {t(text)}
            </div>
            : null}
    </>)
}
export default NoBettingAndVoidedMsgUI;