import React, { useContext, useEffect, useState } from 'react';
import { FootballContext } from '../../Home/FootballPage';
import FootballTitle from '../Common/FootballTitle';
import { useTranslation } from 'react-i18next';
import ResultsTable from '../Common/ResultsTable';
import ResultsSearchBox from '../Common/ResultsSearchBox';
import ResultDetail from '../Common/ResultDetail';
import LastOdds from './LastOdds';
import { enableDetailsButton, ResultHeader, ResultFooter, MatchCountLabel } from '../Common/ResultsBase';
import { hasConfirmedResult } from '../Common/Common' ;
import { formatDateObj, scrollTo } from '../../Common/GlobalFunc';
import Pagination from '../Common/Pagination';
import { useWindowSize } from '../../Common/CommonHooks';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';
import {Loading} from '../../Common/ui-components';
import useGetMatches from '../Data/useGetMatches';
import useGetTeamList from '../Data/useGetTeamList';
import { GlobalContext } from '../../../StandardPage';

const Results = (props) => {
    const context = useContext(FootballContext);
    const {sessionServerTimeStamp} = useContext(GlobalContext);
    const { loadMatchResultQuery } = useGetMatches(context.contentRef, context.setContent);
    const { t, i18n } = useTranslation();
    const { isMobileSize } = useWindowSize();
    const availMatches = context.content?.matchData?.matches;
    const offset = context.content?.matchData?.timeOffset?.fb || 0;
    const searchEndDt = new Date(sessionServerTimeStamp + offset*1000);
    const [openSearchBox, setOpenSearchBox] = useState(false)
    const pageLimit = context.content.config.MaxSearchResultPaginationLimit;
    const [pageState, setPageState] = useState({
        details: null,
        lastOdds: null,
        matchInProgress: false,
        isSearch: false,
        startDate: new Date(searchEndDt),
        endDate: new Date(searchEndDt),
        displayTeamId: "0",
        teamId: "0",
        pageNum: 1
    })
    const [prevSelectVal, setPrevSelectVal] = useState({
        id: '0',
        code: '0',
        nameE: 'All Teams',
        nameC: '所有球隊',
        teamName: 'All Teams(所有球隊)',
    })
    const { teamList } = useGetTeamList();
    const [isOpenTable, setIsOpenTable] = useState(true)
    const [isOpenMIP, setIsOpenMip] = useState(true)


    const handleClickSearchBtn = () => {
        context.content.matchData.matches = []
        context.content.matchData.matchNumByDate = {}
        context.state.onClickSearchResult(pageState.startDate, pageState.endDate, null, pageState.teamId == '0' ? null : pageState.teamId );
        pageState.isSearch = true;
        pageState.pageNum = 1;
        pageState.displayTeamId = pageState.teamId;
        setPageState({...pageState});
        history.pushState({page: 'results', para:pageState}, "", "#search");
    }

    const updateSearchParams = (newSearchParams) => {
        if ( newSearchParams.startDate )
            pageState.startDate = newSearchParams.startDate;
        if ( newSearchParams.endDate )
            pageState.endDate = newSearchParams.endDate;
        if ( newSearchParams.teamId )
            pageState.teamId = newSearchParams.teamId;
        setPageState({...pageState});
    }

    const realHandleClickBack = () => {
        setPageState(oldState=>{
            return {
                ...oldState,
                details : null,
                lastOdds: null,
                matchInProgress: false
            }
        });
    }

    const handleClickBack = () => {
        history.go(-1)
        realHandleClickBack()
    }

    // 当点击了detail或者lastOdds后，控制浏览器返回按钮是返回在Results页面
    useEffect(() => {
        const hashchange = (e) => {
            if(e?.oldURL?.includes('#detail')) {
                realHandleClickBack()
            }
        }
        const searchChange = (e) =>{
            context.state.updateContent('isSearching', true)
            if(e?.oldURL?.includes('#detail')){
                return
            }
            if(e?.state?.para) {
                const pageState = e?.state?.para
                setPageState({...e?.state?.para})
                loadMatchResultQuery({ startDate: formatDateObj(pageState.startDate)[0], endDate: formatDateObj(pageState.endDate)[0], startIndex: (pageState.pageNum-1) * 20 + 1, endIndex: pageState.pageNum*20, teamId:  pageState.teamId == '0' ? null : pageState.teamId })
            }else{
                setPageState({
                    details: null,
                    lastOdds: null,
                    matchInProgress: false,
                    isSearch: false,
                    startDate: new Date(searchEndDt),
                    endDate: new Date(searchEndDt),
                    displayTeamId: "0",
                    teamId: "0",
                    pageNum: 1
                })
                loadMatchResultQuery({ variables: { startDate: null, endDate: null, startIndex:null, endIndex: null, teamId: null }});
            }
        }
        window.addEventListener("hashchange", hashchange);
        window.addEventListener("popstate", searchChange);
        return () => {
            window.removeEventListener('hashchange', hashchange);

            window.removeEventListener("popstate", searchChange);
        };
    }, [])

    const handleClickDetailBtn = (matchId) => {
        scrollTo(0,0)
        pageState.details = matchId;
        pageState.lastOdds = null;
        pageState.matchInProgress = false;
        setPageState({...pageState});
        if(location.hash !== '#detail') {
            history.pushState({page: 'detail', matchId, para: pageState.isSearch ? pageState : null}, "", "#detail");
        }
    }

    const handleClickLastOddsBtn = (matchId, mip) => {
        scrollTo(0,0)
        pageState.details = null;
        pageState.lastOdds = matchId;
        pageState.matchInProgress = mip;
        setPageState({...pageState});
        if(location.hash !== '#detail') {
            history.pushState({page: 'lastodds', matchId, para: pageState.isSearch ? pageState : null}, "", "#detail");
        }
    }

    const handlePageNumChange = (num) => {
        if(context.content.isSearching ) return
        pageState.pageNum = num;
        setPageState({...pageState});
        context.content.matchData.matches = []
        context.state.updateContent('isSearching', true)
        loadMatchResultQuery({ startDate: formatDateObj(pageState.startDate)[0], endDate: formatDateObj(pageState.endDate)[0], startIndex: (pageState.pageNum-1) * 20 + 1, endIndex: pageState.pageNum*20, teamId:  pageState.teamId == '0' ? null : pageState.teamId })
        history.pushState({page: 'results', para:pageState}, "", "#search");
    }

    const isMatchVoided = (match) => {
        return [ "INPLAYRESULTVOIDED", "MATCHVOIDED", "RESULTVOIDED", "MATCHSUSPENDED" ].includes(match.status);
    }

    const hasSelectedTeam = (match) => {
        return pageState.displayTeamId==="0" || match.homeTeam.id===pageState.displayTeamId || match.awayTeam.id===pageState.displayTeamId;
    }


    const handleClickOpenSearchBox = () => {
        if(!openSearchBox) scrollTo(0,0)
        setOpenSearchBox(!openSearchBox)
    }

    const handleClickOpenMip = () =>{
        setIsOpenMip(!isOpenMIP)
        setIsOpenTable(!isOpenTable)
    }

    const sortResults = (x, y) => {
        if ( x.matchDate != y.matchDate ) {
            return x.matchDate < y.matchDate ? 1 : -1;
        }
        else {
            if ( x.frontEndId!=null && x.frontEndId!="" ) {
                if ( x.kickOffTime != y.kickOffTime ) {
                    return x.kickOffTime < y.kickOffTime ? -1 : 1;
                }
                else if ( x.tournament?.code != y.tournament?.code ) {
                    return x.tournament?.code < y.tournament?.code ? -1 : 1;
                }
                return x.homeTeam.name_en < y.homeTeam.name_en ? -1 : 1;
            }
            else {
                return parseInt(x.matchNumber) - parseInt(y.matchNumber);
            }
        }
    }

    const hasResultDetailDisplayPools = (match) => {
        return [...window.fbConfig.ResultDetailPools/* , 'CHL', 'FCH', 'ECH' */].some(x=> match.poolInfo?.payoutRefundPools?.indexOf(x)>=0);
    }

    const hasRefundPool  = (match) => {
        return ['CHL', 'FCH', 'ECH'].some(item => match.poolInfo?.refundPools?.includes(item))
    }

    const matchesInProgressData = availMatches?.filter(match => !isMatchVoided(match) && !hasConfirmedResult(match) && !hasResultDetailDisplayPools(match) && !hasRefundPool(match)).sort(sortResults);
    const matchesResultsData = availMatches?.filter(match => (isMatchVoided(match) || hasConfirmedResult(match) || hasResultDetailDisplayPools(match) || hasRefundPool(match)) );
    let filteredmatchesResultsData = pageState.isSearch ? matchesResultsData : matchesResultsData?.sort(sortResults);
    let totalMatches = context.content?.matchData?.matchNumByDate?.total || matchesResultsData?.length;
    // if ( pageState.isSearch ) {
    //     // filteredmatchesResultsData = matchesResultsData.filter((item, index) => index >= (pageState.pageNum - 1) * pageLimit && index <= (pageState.pageNum * pageLimit - 1));
    //     filteredmatchesResultsData = matchesResultsData.slice((pageState.pageNum - 1) * pageLimit, (pageState.pageNum * pageLimit));
    // }

    return <>
        {!pageState.details && !pageState.lastOdds && <ResultHeader onClickSearch={handleClickOpenSearchBox} openSearchBox={openSearchBox}/>}
        {/* Results Table */}
        {!pageState.details && !pageState.lastOdds && <section className='RESULTS'>
            <header>
                <FootballTitle title={t('LB_FB_TITLE_MATCH_RESULTS')} isPoolDefined={true} />
            </header>
            <ResultsSearchBox searchParams={pageState}
                windowSize={context?.state?.windowSize}
                teamList={teamList}
                onChangeSearchParams={updateSearchParams}
                handleClickSearchBtn={handleClickSearchBtn}
                isOpen={openSearchBox}
                setIsOpen={setOpenSearchBox}
                prevSelectVal={prevSelectVal}
                setPrevSelectVal={setPrevSelectVal}
            />
            {/* Match in Progress Table*/}
            {!pageState.isSearch && matchesInProgressData?.length > 0 && <>
                {isMobileSize && <div onClick={handleClickOpenMip} className={`resultsTitlemobile  ${isOpenMIP ? 'MIPOpen' : 'MIPClose'}`}>
                    <span>{t('LB_FB_TITLE_RESULTS_MIP')}</span>
                    <div className='m-arrow-area'><ArrowIcon className='' direction='down' change={isOpenMIP} changeDirection='up' size={21} /></div>
                    </div>}
                {(isOpenMIP || !isMobileSize) && <>
                <header >
                    <FootballTitle title={t('LB_FB_TITLE_RESULTS_MIP')} isPoolDefined={true} controlOpen={true} isOpenTable={isOpenTable} setIsOpenTable={setIsOpenTable} />
                </header>
                {isOpenTable  && <ResultsTable matches={matchesInProgressData} isInProgress={true} handleClickLastOddsBtn={handleClickLastOddsBtn} />}
                </>}

            </>}
            {/* Match Results Table */}
            {pageState.isSearch && totalMatches > 0 && <div className='pagination'>
                <MatchCountLabel total={totalMatches} pageNum={pageState.pageNum} maxPerPage={pageLimit} />
                <Pagination total={Math.ceil(totalMatches / pageLimit)} onChange={(num) => handlePageNumChange(num)} pageNum={pageState.pageNum} />
            </div>}

            {context.content.isSearching ?
            <Loading />:
            <>
            {filteredmatchesResultsData?.length > 0 && <ResultsTable matches={filteredmatchesResultsData} isInProgress={false} handleClickDetailBtn={handleClickDetailBtn} handleClickLastOddsBtn={handleClickLastOddsBtn} />}
            {matchesInProgressData?.length + filteredmatchesResultsData?.length==0 && <div className="noMatchResult">{t('LB_FB_NO_RESULT')}</div> }
            </>
            }
            <ResultFooter />
        </section>}
        {/* Result Detail */}
        {pageState.details && <section className='RESDTLS'>
            <ResultDetail match={matchesResultsData.filter((item) => pageState.details === item.id)[0]}
                matchList={matchesResultsData.filter(x=> enableDetailsButton(x))} onClickBackBtn={handleClickBack} onClickResultDropdown={handleClickDetailBtn} />
        </section>}
        {pageState.lastOdds && <section className='LASTODDS'>
            <LastOdds match={availMatches.filter((item) => pageState.lastOdds === item.id)[0]} isInProgress={pageState.matchInProgress}
                matchList={pageState.matchInProgress ? matchesInProgressData : matchesResultsData} onClickBackBtn={handleClickBack}  onClickResultDropdown={handleClickLastOddsBtn}/>
        </section>}
    </>
}

export default Results
