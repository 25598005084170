import React, { useRef, useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../Common/GlobalFunc';
import { MarksixContext } from '../../Home/MarksixPage';
import remarkImg from '../../../info/Include/images/sms_failed.svg';
import Pagination from '../../Racing/Common/Pagination'
import printImg from '../../../info/Include/images/icon-print-white.svg';
import ReactToPrint from 'react-to-print'
import Loading from '../../Common/ui-components/Loading';

const ResultsTable = ({ onclickResultDtls, draws=[], apiErr=false, apiLoading=false, pageNumber=1, setPageNumber }) => {
    const {t, i18n} = useTranslation()
    const context = useContext(MarksixContext);

    const pageName = context.pageName;
    const [tableData, setTableData] = useState([])
    const scSize = document.documentElement.clientWidth

    useEffect(()=> {
        const start = (pageNumber-1) * 20
        const end = pageNumber*20
        const data = draws.slice(start,end)
        setTableData(data)
    },[draws,pageNumber])

    const handleResultId = (draw) => {
        if ( draw.lotteryPool?.lotteryPrizes?.length>0 )
            onclickResultDtls(draw.id);
    }

    const handleClickPrint = ()=> {
        //return document.querySelector('.maraksx-results-table-print')
        window.print();
    }

    const onBeforePrint = () => {
        //const iframe = document.querySelector('#printWindow')
        //iframe.contentWindow.document.querySelector('.maraksx-results-table-print').setAttribute('style', 'display: block')
    }


    const tableHeader=() => {
        return <div className='table-row marksix-results-print-header'>
            <div className='table-cell'>{t('LB_M6_LASTDRAW_NUM')}</div>
            <div className='table-cell'>{t('LB_M6_DRAWDATE')}</div>
            <div className='table-cell'>{t('LB_M6_RESULTS_PRINT_SBNAME')}</div>
            <div className='table-cell'>{t('LB_M6_SINGLE_DRAW_RESULTS')}</div>
        </div>
    }



    const PrintTable = (props) => {
        const table = draws.map((item, index)=> <Row draw={item} key={item.id} className={index%2 ? 'sec' : ''} {...props}/>)
        return <div className='maraksx-results-table-print' >
            {pageName==='CHECKDRAWRESULT'?
                <div className='marksix-head-note-print'>
                    <div>{t(`LB_M6_DRAWRESULTS`)}</div>
                    <div className='marksix-results-print'>{t('LB_M6_RESULTS_PRINT')} </div>
                </div>:null}
            <div className='maraksx-results-table' style={{display:'table'}}>
                {tableHeader()}
                {draws.length !== 0 ?
                    table
                    :
                    <div className='maraksx-no-matched'>
                        {apiErr?t('LB_RC_JTC_NOINFO'):t('LB_M6_DRAWRESULTSNULL')}
                    </div>
                }
            </div>
        </div>
    }

    const tableProps = {
        draws,tableData,apiErr,
        handleResultId, scSize
    }

    return <div className='marksix-results-table-container'>
        <header>
            <div>{t(`${pageName==="CHECKDRAWRESULT" ? 'LB_M6_DRAWRESULTS' : 'LB_M6_RESULTS'}`)}</div>
            <ReactToPrint
            trigger={()=>{
                return <div className='marksix-results-print'><img src={printImg} alt=''/> {t('LB_M6_RESULTS_PRINT')}</div>
            }}
            bodyClass='print-content'
            documentTitle={document.title}
            onBeforePrint={onBeforePrint}
            pageStyle={'size: A4; margin: 15mm;'}
            content={handleClickPrint}
            />
        </header>
        {apiLoading ?<div>
            <Loading/>
        </div>:<>
            <Table {...tableProps}/>
            <PrintTable  {...tableProps}/>
            <Pagination onChange={setPageNumber} pageNum={pageNumber} total={Math.ceil(draws.length/20)}/>
        </>
        }
    </div>
}

export default ResultsTable

const Row = ({draw, className, handleResultId, scSize}) => {
    const {t, i18n} = useTranslation();

    const sbName = draw["snowballName_" + i18n.language];
    let drawId = `${draw.year.substring(2,4)}/${String(draw.no).padStart(3,'0')}`;
    if ( draw.snowballCode!=null && draw.snowballCode!='' )
        drawId += ' ' + draw.snowballCode;
    const drawDt = formatDateTime(draw.drawDate.substring(0,10));
    const ballList = draw.drawResult.drawnNo
    return <div className={'table-row '+className}>
        <div className='table-cell cell-id' onClick={() => handleResultId(draw)}>
            <a onClick={() => handleResultId(draw)} >{drawId}</a>
            {sbName && <div className='result-date-m'>{sbName}</div>}
            <div className='result-date-m'>{drawDt[0]}</div>
        </div>
        <div className='table-cell cell-date'>{drawDt[0]}</div>
        <div className='table-cell cell-sbName'>{sbName}</div>
        <div className='table-cell cell-ball-list'>
            <div>
                {ballList.map(ball => <div className='img-box' key={ball}><img className={scSize > 1023 ? 'ballsize-m' :'ballsize-s'} src={require(`../../../info/marksix/info/images/marksix-${ball}.svg`)} alt={ball}/></div>)}
                <span>+</span>
                <div className='img-box' key={draw.drawResult.xDrawnNo}><img className={scSize > 1023 ? 'ballsize-m' : 'ballsize-s'} src={require(`../../../info/marksix/info/images/marksix-${draw.drawResult.xDrawnNo}.svg`)} alt={draw.drawResult.xDrawnNo}/></div>
            </div>
        </div>
    </div>
}

const Table = (props) => {
    const {t, i18n} = useTranslation()

    const {draws,tableData,apiErr} = props

    return <div className='maraksx-results-table maraksx-results-table-noprint'>
                {draws.length !== 0 ?
                    tableData.map((item, index)=> <Row draw={item} key={item.id} className={index%2 ? 'sec' : ''} {...props}/>)
                    :
                    <div className='maraksx-no-matched'>
                        {apiErr?t('LB_RC_JTC_NOINFO'):t('LB_M6_DRAWRESULTSNULL')}
                    </div>
                }
        </div>
}