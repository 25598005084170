import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import OddsDropLegend from '../Common/OddsDropLegend';
import InvestmentCalculator from '../Common/InvestmentCalculator';
import { initAlupData } from '../Common/Common';
import OddsTable from '../Common/RCOddsTableAllUp';
import MultiLegSlider from '../Common/MultiLegSlider';
import AddSlipBtn from '../Common/AddSlipBtn';
import { getAlupBetTypeList } from '../Common/AlupPageBase';
import { RacingContext } from '../../Home/RacingPage';
import {MobileCurrentOdds} from '../Common/CurrentOdds';
import Loading from '../../Common/ui-components/Loading';
import HandleMultiLeg3xTablesTrHeight from '../Common/HandleMultiLeg3xTablesTrHeight';
import { useWindowSize } from '../../Common/CommonHooks';

const CrossAlup = (props) => {
    const { t, i18n } = useTranslation();
    const context = useContext(RacingContext);
    const mtg= context.content.meeting;

    const betTypeList = getAlupBetTypeList(context.content.page);

    useEffect(() => {
        if ( context.content.alupData.length==0  ) {
           let alupData = initAlupData(context.contentRef, context.content.raceNo, betTypeList);
           context.racingFuncs.resetAlupData({ data: alupData });
        }
    }, [context.content.page, context.content.raceNo])

    const AllUpTable = (_cItem, _index, defTableSize) => {
        return <OddsTable key={_cItem.raceNo} alupIdx={_index} betTypeList={betTypeList} defTableSize={defTableSize} />
    }

    const CWINRemarks = () => {
        if ( !['CROSS_ALUP', 'CWAALUP'].includes(context.content.page) )
            return null;

        if ( context.content.alupData?.filter(x=> x.betType=="CWA").length>0 ) {
            return <div className="rc-cwa-all-remarks">
                <div>{t('LB_RC_CWIN_REMARKS')}</div>
            </div>;
        }

        return null;
    }

    let pageConfig = context.pageConfig;

    const renderTables = handleRenderTables()
    HandleMultiLeg3xTablesTrHeight()

    if (context.content.alupData.length==0) {
        return <Loading/>
    } else {
        return <section className={context.content.page}>
            <InvestmentCalculator enable={true} />
            <MobileCurrentOdds />
            <MultiLegSlider cnt={context.content.alupData.length} className="multi-leg-3x-table" >
                {
                    // context.content.alupData.map(function (item, index) {
                    //     return AllUpTable(item, index, defTableSize)
                    // })
                    // 替换成以下处理表格行数相等的情况
                    renderTables
                }
            </MultiLegSlider>
            <OddsDropLegend />
            <CWINRemarks />
            <div className="multi-leg-footer-betSlip">
                <div><AddSlipBtn /></div>
            </div>
            <InvestmentCalculator enable={!context.windowSize.isMobileSize} />
        </section>
    }
}

const handleRenderTables = () => {
    const context = useContext(RacingContext);
    const mtg= context.content.meeting;
    const betTypeList = getAlupBetTypeList(context.content.page);

    const AllUpTable = (_cItem, _index, defTableSize) => {
        return <OddsTable key={_cItem.raceNo} alupIdx={_index} betTypeList={betTypeList} defTableSize={defTableSize} />
    }

    let defTableSize = 14
    const calDefTableSize = (data) => {
        data.forEach(function (item, index) {
            try {
                let raceNo = item.raceNo
                let race = mtg.races.filter((data) => data.no == raceNo)[0];
                let runners = race.runners;
                let rLen = runners.filter((data) => data.no || data.no === 0 ).length;
                defTableSize = Math.max(defTableSize, rLen)
            } catch (error) {
                defTableSize = 14
            }
        })
        return defTableSize
    }
    const { width, isMobileSize } = useWindowSize();
    let renderTables = []
    const handleTablesData = (tmpTables, tmpIndexs) => {
        const defTableSize = calDefTableSize(tmpTables)
        return tmpTables.map(function (item, index) {
            return AllUpTable(item, tmpIndexs[index], defTableSize)
        })
    }
    if(!isMobileSize) {
        let group = 3;
        if (width < 1280) {
            group = 2;
        }
        if (context.content.alupData) {
            let tmpTables = [];
            let tmpIndexs = [];
            for (let i = 0; i < context.content.alupData.length; i++) {
                tmpTables.push(context.content.alupData[i]);tmpIndexs.push(i)
                if ((i + 1) % group === 0) {
                    renderTables = [...renderTables, ...handleTablesData(tmpTables,tmpIndexs)]
                    tmpTables = [];tmpIndexs = [];
                }
            }
            if (tmpTables.length !== 0) {
                renderTables = [...renderTables, ...handleTablesData(tmpTables,tmpIndexs)]
                tmpTables = [];tmpIndexs = [];
            }
        }
    } else {
        renderTables = context.content.alupData.map(function (item, index) {
            return AllUpTable(item, index, defTableSize)
        })
    }
    return renderTables;
}

export default CrossAlup;