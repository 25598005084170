import React, { useCallback, useEffect, useState, useMemo, memo, ReactNode } from 'react';
import { EqualHeightProvider } from './equal-height-context';

export const defaults = {
    sizes: [],
    temporarySizes: [],
    update: false,
    forceUpdate: false,
    originalChildrenCount: 0,
    childrenCount: 0,
    animationSpeed: 0,
    timeout: 200,
    updateOnChange: undefined
};

const EqualHeight = memo((props) => {
    const {
        children,
        timeout = defaults.timeout,
        animationSpeed = defaults.animationSpeed,
        updateOnChange = defaults.updateOnChange
    } = props;

    // States
    const [sizes, setSizes] = useState(defaults.sizes);
    const [temporarySizes, setTemporarySizes] = useState(defaults.temporarySizes);
    const [update, setUpdate] = useState(defaults.update);
    const [forceUpdate, setForceUpdate] = useState(defaults.forceUpdate);
    const [originalChildrenCount, setOriginalChildrenCount] = useState(defaults.originalChildrenCount);
    const [childrenCount, setChildrenCount] = useState(defaults.childrenCount);

    const handleUpdate = useCallback(() => setUpdate(value => !value), []);

    useEffect(() => {
        let resizeTimer;
        let orientationChangeTimer;
        const browser = typeof window === 'object' && typeof window.document === 'object';

        if (browser) {
            window.addEventListener('resize', timeout ? () => {
                clearTimeout(resizeTimer);
                resizeTimer = window.setTimeout(handleUpdate, timeout);
            } : handleUpdate);

            window.addEventListener('orientationchange', timeout ? () => {
                clearTimeout(orientationChangeTimer);
                orientationChangeTimer = window.setTimeout(handleUpdate, timeout);
            } : handleUpdate);

            return () => {
                window.removeEventListener('resize', handleUpdate);
                window.removeEventListener('orientationchange', handleUpdate);
            };
        }
    }, []);

    useMemo(() => {
        handleUpdate();
    }, [forceUpdate, originalChildrenCount, updateOnChange]);

    useMemo(() => {
        if (originalChildrenCount <= childrenCount) {
            let filteredSizes = [];
            temporarySizes.map((filteredSize) => {
                const name = filteredSize.name;
                const height = filteredSize.height;
                const elementIndex = filteredSizes.findIndex((e) => e.name === name);
                if (elementIndex > -1) {
                    const savedHeight = filteredSizes[elementIndex].height;
                    if (savedHeight < height) {
                        filteredSizes[elementIndex].height = height;
                    }
                } else {
                    filteredSizes = [...filteredSizes, {
                        name,
                        height
                    }]
                }
            });
            setSizes(filteredSizes);

            // Reset
            setTemporarySizes([]);
            setChildrenCount(0);
        }
    }, [childrenCount]);

    return (
        <EqualHeightProvider value={{
            sizes,
            temporarySizes,
            update,
            animationSpeed,
            forceUpdate,
            originalChildrenCount,
            childrenCount,
            setTemporarySizes,
            setOriginalChildrenCount,
            setChildrenCount,
            setForceUpdate,
            updateOnChange
        }}>
            { children }
        </EqualHeightProvider>
    );
});

export default EqualHeight;
