import { useApolloClient } from '@apollo/client';
import { getRacingQuery, getReloadRacingQuery, getQueryVariables } from '../DataQuery';
import { apiErrorCallback } from '../../../Common/graphqlFetch';
import { ClearBetRaces, ClearBetByBetTypeAndRacesAndRunner} from '../../Common/RacingBetline';
import { initAlupData, getNextDefinedRace } from '../../Common/Common';
import { getAlupBetTypeList } from '../../Common/AlupPageBase';

const useLoadMeeting = (contentRef, setContent) => {
    const { query } = useApolloClient();

    const reloadMeeting = (callbackFunc) => {
        clearTimeout(window.RCMeetingFetchTimer)
        return Promise.all([query({
            query : getRacingQuery(),
            variables: getQueryVariables({
                page: contentRef.current.page,
                date: contentRef.current.date,
                venue: contentRef.current.venue
            }),
            fetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: true
        })])
        .then(([results]) => {
            if(results.errors){
                throw new Error(results.errors?.[0]?.message)
            }
            if ( results?.data?.activeMeetings ) {
                contentRef.current.meetingList = results.data.activeMeetings;
            }
            if ( results?.data?.raceMeetings?.[0] ) {
                contentRef.current.meeting = results.data.raceMeetings[0];
            }
            if ( callbackFunc ) {
                callbackFunc();
            }
            else {
                setContent({ ...contentRef.current });
            }
        }).catch(error => {console.log(error), apiErrorCallback(error, ()=> {reloadMeeting(callbackFunc)}, {timer: window.RCMeetingFetchTimer})});
    }

    const reloadMeetingStatus = (callbackFunc) => {
        clearTimeout(window.RCMeetingFetchTimer)
        return Promise.all([query({
            query : getReloadRacingQuery(),
            variables: getQueryVariables({
                page: contentRef.current.page,
                date: contentRef.current.date,
                venue: contentRef.current.venue
            }),
            fetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: true
        })])
        .then(([results]) => {
            if(results.errors){
                throw new Error(results.errors?.[0]?.message)
            }
            if ( results?.data?.raceMeetings?.[0] ) {
                contentRef.current.meeting.status = results.data.raceMeetings[0].status;
                contentRef.current.meeting.currentNumberOfRace = results.data.raceMeetings[0].currentNumberOfRace;
                contentRef.current.meeting.totalInvestment = results.data.raceMeetings[0].totalInvestment;
                results.data.raceMeetings[0].races.forEach(race => {
                    let tRace = contentRef.current.meeting.races.filter(x=>x.no==race.no)[0];
                    if ( tRace ) {
                        tRace.status = race.status;
                        if(["CLOSED", "VOIDED", "RESULT", "ABANDONED"].includes(tRace.status.toUpperCase())){
                            // ClearBetSels();
                            ClearBetRaces(race.no);
                            if(contentRef.current.alupData.length>0){
                                const lastRaceNo = Math.max(... contentRef.current.meeting.races.map(x=>x.no))
                                if(tRace.no != lastRaceNo){
                                    contentRef.current.alupData = contentRef.current.alupData?.filter(x => x.raceNo != tRace.no) ;
                                    if ( contentRef.current.alupData.length <=6 ) {
                                        let fSet =  contentRef.current.config['ALUP_FORMULA'][contentRef.current.alupData.length];
                                        contentRef.current.alupFormula = fSet[0];
                                    }
                                    if(contentRef.current.alupData.length == 0){
                                        const curRaceNo = getNextDefinedRace(contentRef.current.page, contentRef.current.raceNo, contentRef.current.meeting)
                                        contentRef.current.alupData.push(initAlupData(contentRef, curRaceNo, getAlupBetTypeList( contentRef.current.page)))
                                    }

                                }
                            }
                        }
                        race.runners.forEach(runner => {
                            let tRunner = tRace.runners.filter(x=> x.id==runner.id)[0];
                            if ( tRunner ) {
                                tRunner.status = runner.status;
                                if(["SCRATCHED", "SCRATCHEDRESERVED", "STANDBY"].includes(runner.status.toUpperCase())){
                                    ClearBetByBetTypeAndRacesAndRunner(tRace.no,tRunner.no);
                                }
                            }
                        })
                    }
                });
            }
            setContent({ ...contentRef.current });
        }).catch(error => {console.log(error), apiErrorCallback(error, ()=> {reloadMeeting(callbackFunc)}, {timer: window.RCMeetingFetchTimer})});
    }

    return {
        reloadMeeting,
        reloadMeetingStatus
    }
}

export default useLoadMeeting