import { useContext } from 'react';
import { useApolloClient } from '@apollo/client';
import { getTournamentQuery, getTournamentResultQuery, getQueryVariables } from '../DataQuery';
import { setTournamentPageTabs } from '../../Common/Common';
import { setTournDataOddsDiff } from '../useFBDataRefresh/setRefreshDataOddsDiff';
import { parseMessage } from '../useFootballPush/parseMessage';
import { GlobalContext } from '../../../../StandardPage';
import { Query, abortFBMatch, apiErrorCallback } from '../../../Common/graphqlFetch'

const useGetTournaments = (contentRef, setContent) => {
    // const { query } = useApolloClient();
    const globalContext = useContext(GlobalContext);
    const { replayMsgQueue, isPushing } = globalContext.mqttClient;

    const loadTournQuery = (paras, callbackFunc, option) => {
        globalContext.globalRef.current.isLoadingData = true;
        !option?.disabledAbort && abortFBMatch()
        Promise.all([Query({ 
            query : getTournamentQuery(),
            variables: getQueryVariables(paras),
            controller: !option?.disabledAbort && window.FBMatchController
        })])
        .then(([results]) => {
            if ( callbackFunc ) {
                callbackFunc(results);
            }
            else {
                setTournQueryDataToContent(results);
            }
            globalContext.globalRef.current.isLoadingData = false;
        }).catch(error => apiErrorCallback(error, ()=>{ loadTournQuery(paras, callbackFunc)}));
    }

    const setTournQueryDataToContent = (queryResult) => {
        if(!queryResult || !queryResult.data) return
        contentRef.current.isLoading = false;
        contentRef.current.isApiErr = false;
        contentRef.current.rawTournData = {...queryResult.data.tournaments};
        contentRef.current.tournData = {...queryResult.data};
        setTournamentPageTabs(contentRef.current.tournData.tournaments, contentRef.current);
        replayMsgCache();
        setContent({ ...contentRef.current });
    }

    const replayMsgCache = () => {
        replayMsgQueue.current.forEach(({topic, message}) => {

            parseMessage(topic, message, contentRef, [...replayMsgQueue.current], {isReplay: true});
        });
    }

    const resetTournQueryDataToContent = (queryResult) => {
        if(!queryResult || !queryResult.data) return
        contentRef.current.isLoading = false;
        contentRef.current.isApiErr = false;
        setTournDataOddsDiff(contentRef, queryResult.data.tournaments);
        contentRef.current.tournData = {...queryResult.data};
        setContent({ ...contentRef.current });
        if (window.globalConfig.FB_ODDS_PUSH && isPushing) replayMsgCache()
    }

    const loadTournResultQuery = (paras) => {
        abortFBMatch()
        Promise.all([Query({ 
            query : getTournamentResultQuery(),
            variables: getQueryVariables(paras),
            controller: window.FBMatchController
        })])
        .then(([results]) => {
            setTournResultQueryDataToContent(results);
        }).catch(error => apiErrorCallback(error, ()=>{ loadTournQuery(paras)}));
    }

    const setTournResultQueryDataToContent = (queryResult) => {
        if(!queryResult || !queryResult.data) return
        contentRef.current.isLoading = false;
        contentRef.current.isApiErr = false;
        contentRef.current.tournData = {...queryResult.data};
        setContent({ ...contentRef.current });
    }

    return {
        loadTournQuery,
        loadTournResultQuery,
        resetTournQueryDataToContent
    }
}

export default useGetTournaments