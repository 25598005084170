import { createSelectionData } from "./createMatch";

export const createTournData =(message)=>{
    let poolInfo ={
        inplayPools:[],
        normalPools:[],
        sellingPools:[]
    }
    let chlInfoStatus;

    if(message.chlInfo!=null){
        chlInfoStatus = message.chlInfo.filter(x=>x.chl =="InteractiveService")[0].st == "Available"? true : false;
    }
    let tempTournData =  {
        code : message.code,
        frontEndId : message.frontEndId,
        id : message.id,
        isInteractiveServiceAvailable: chlInfoStatus,
        nameProfileId : message.npId.toString(),
        name_ch : message.nm.filter(x=> x.ln == "zh-hk")[0].val,
        name_en : message.nm.filter(x=> x.ln == "en-us")[0].val,
        sequence : message.seq,
        poolInfo : poolInfo
    };
    return tempTournData;
}

export const createTournament =(message)=>{
    return {
        code : message.code,
        frontEndId : message.frontEndId,
        id : message.id,
        nameProfileId : message.npId.toString(),
        name_ch : message.nm.filter(x=> x.ln == "zh-hk")[0].val,
        name_en : message.nm.filter(x=> x.ln == "en-us")[0].val,
        sequence : message.seq
    };
}

export const updateTourn = (tourn, message) => {
    tourn.code = message.code;
    tourn.frontEndId = message.frontEndId;
    tourn.id =  message.id;
    if(message.chlInfo!=null){
        tourn.isInteractiveServiceAvailable = message.chlInfo.filter(x=> x.chl == "InteractiveService")[0].st == "Available";
    }
    tourn.nameProfileId = message.npId.toString();
    tourn.name_ch = message.nm.filter(x=> x.ln == "zh-hk")[0].val;
    tourn.name_en = message.nm.filter(x=> x.ln == "en-us")[0].val;
    tourn.sequence = message.seq;
}

export const InsertNewTournData =(contentRef, tPool, tTour)=>{
    if(tPool.status == "SELLINGSTARTED" ){
        tTour.poolInfo.sellingPools.push(tPool[0].bTyp);
    }
    tTour.poolInfo.normalPools.push(tPool[0].bTyp);

    let tournData =  {
        code : tTour.code,
        foPools : tPool,
        frontEndId : tTour.frontEndId,
        id : tTour.id,
        isInteractiveServiceAvailable:  tTour.isInteractiveServiceAvailable,
        poolInfo : tTour.poolInfo,
        nameProfileId : tTour.nameProfileId,
        name_ch :  tTour.name_ch,
        name_en : tTour.name_en,
        sequence :  tTour.sequence,
    };

    let duplicateProfile = false;
    let index;
    for(let i=0; i<contentRef.current.selection.tabs.length; i++){
        for(let j=0 ; j<contentRef.current.selection.tabs[i].tournIds.length; j++){
            if(contentRef.current.selection.tabs[i].nameProfileId == tournData.nameProfileId ){
                // console.debug("same nameProfileID " );
                index = i;
                duplicateProfile = true;
                break;
            }
        }
        if(duplicateProfile){
            break;
        }
    }
    if(duplicateProfile == false){
        contentRef.current.selection.tabs.push(createSelectionData(tournData,tTour.sequence));
        // console.debug("contentRef.current.selection.tabs",contentRef.current.selection.tabs);
    }

    return tournData;
}