

const useGetToken = () => {

    const getAppToken = () => {
        const url = `${window.globalConfig.CIAM_APP_TOKEN_URL}/auth/access_token`;
        const accCode = () => {
            return `Basic ${window.btoa('JCBW_NS:' + window.globalConfig.CUSTOMER_SECRET)}`;
        };

        return fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: accCode()
            },
            body: `grant_type=client_credentials`
        })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            console.error(err);
        });
    }

    const getCustomerToken = () => {
        const url = `${window.globalConfig.CIAM_URL}/auth/access_token`;
        const accCode = () => {
            return `Basic ${window.btoa('JCBW-BAU:' + window.globalConfig.CIAM_CLIENT_SECRET)}`;
        };
        return fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: accCode()
            },
            body: `grant_type=urn:ietf:params:oauth:grant-type:jwt-bearer&redirect_uri=${
                window.globalConfig.SITE_DOMAIN
            }&assertion=${sessionStorage.getItem('jwt')}&scope=solace_messaging&logTrackingID=${sessionStorage.getItem('hWebID')}_${(new Date()).getTime()}`
        })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            console.error(err);
        });
    };

    return {
        getAppToken,
        getCustomerToken
    }
}

export default useGetToken