import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { postUpdateFpsInfo } from '../FundsTransferAPI';
import { postUpdateFpsInfo_IB } from '../FundsTransferIBAPI';
import FPSBanner from '../components/FPSBanner';
import useBroadcastChannel from '../../../Common/hooks/useBroadcastChannel';

const LinkMain = ({ props }) => {
    const { t, i18n } = useTranslation();
    const { nbaData, setNbaData, curStep, setCurStep, selectedItem, setSelectedItem, setLinkResult, fpsMessageQuery } =
        props;
    const langName = i18n.language === 'en' ? 'name_en' : 'name_ch';
    const broadcast_resetlogin = useBroadcastChannel('resetLoginTimeout');
    const fpsEFTMessage = fpsMessageQuery?.jcbwControl?.fpsBannerControl?.fpsEFTMessage['name_' + i18n.language]?.replace(/\n/g, '<br/>');

    const rejectMessage = (type, code, lang = null) => {
        const error = nbaData.FPSErrorList.find((obj) => {
            return obj.type == type && obj.code == code;
        });

        const language = lang != null ? lang : i18n.language;
        const message = language === 'en' ? error?.userMessage : error?.userMessageTC;
        return message;
    };

    const onClickItem = (nba, method, bankAccountType) => {
        if (selectedItem?.nba == nba) {
            setSelectedItem(null);
        } else {
            setSelectedItem({
                nba: nba,
                linkStatus: method.linkStatus,
                linkErrorCode: method.linkErrorCode,
                linkErrorType: method.linkErrorType,
                isSingleLeg: method.isSingleLeg,
                bankAccountType: bankAccountType
            });
        }
    };

    const goToSummaryPage = () => {
        setLinkResult({
            eDDAStatus: selectedItem.linkStatus,
            errorType: selectedItem.linkErrorType,
            errorCode: selectedItem.linkErrorCode,
            errorMsg: rejectMessage(selectedItem.linkErrorType, selectedItem.linkErrorCode, 'en'),
            errorMsgTC: rejectMessage(selectedItem.linkErrorType, selectedItem.linkErrorCode, 'ch')
        });
        setCurStep(5);
    };

    const onClickNext = () => {
        if (selectedItem?.linkStatus == 'NO') {
            setCurStep(1);
        } else if (selectedItem?.linkStatus == 'AP' && selectedItem.isSingleLeg) {
            setCurStep(1);
        } else if (['AP', 'RJ', 'IP'].includes(selectedItem?.linkStatus)) {
            goToSummaryPage();
        } else {
            console.error('Unexpected linkStatus', selectedItem?.linkStatus);
        }
    };

    const onClickSingleLegErrMsg = (nba, method, bankAccountType) => {
        setSelectedItem({
            nba,
            linkStatus: method.linkStatus,
            isSingleLeg: true,
            bankAccountType: bankAccountType
        });
        setCurStep(1);
    };

    const itemSelected = (nba) => {
        if (selectedItem?.nba == nba) {
            return 'link-type-fps link-type-fps-selected';
        }
        return 'link-type-fps';
    };

    const onClickBankList = () => {
        setCurStep(4);
    };

    const onClickRejectMsg = async (nba, method, bankAccountType) => {
        if (!method.isSingleLeg) {
            const params = {
                bankCode: nbaData.link[nba]?.bankInfo?.code,
                bankAccountNo: nbaData.link[nba]?.bankAccountNo,
                notifyEDDAStatus: 'NO',
                bankAccountType: bankAccountType
            };
            await postUpdateFpsInfo_IB({
                language: i18n.language === 'en' ? '0' : '1',
                params: params
            });
        }
        setSelectedItem({ nba, linkStatus: method.linkStatus, isSingleLeg: false, bankAccountType: bankAccountType });
        setCurStep(1);
    };

    const showNextButton = () => {
        return (
            nbaData.link.nba1?.bankInfo?.eDDADepositEnable == '1' ||
            nbaData.link.nba2?.bankInfo?.eDDADepositEnable == '1'
        );
    };

    const onClickDone = () => {
        window.close();
    };

    const singleLegErrMsg = (nba, method, bankAccountType) => {
        const showOtherErrMsg = nbaData.link[nba]?.FPS.notifyEDDASingleLegErrorCode !== '000';
        const message = rejectMessage(
            nbaData.link[nba]?.FPS.notifyEDDASingleLegErrorType,
            nbaData.link[nba]?.FPS.notifyEDDASingleLegErrorCode
        );

        return (
            <div className="link-reject" onClick={() => onClickSingleLegErrMsg(nba, method, bankAccountType)}>
                <div>
                    <div>
                        <div>
                            {t('LB_BS_FT_SINGLELEG_LINKBANK_ERRMSG1')}
                            <u>{t('LB_BS_FT_SINGLELEG_LINKBANK_ERRMSG2')}</u>
                            {t('LB_BS_FT_SINGLELEG_LINKBANK_ERRMSG3')}
                        </div>
                        {showOtherErrMsg && message?.length > 0 && (
                            <div className="singleLegErrorMessage">
                                <strong>{t('LB_BS_FT_LINK_RJ_REASON')} </strong>
                                {message}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const isValidFpsStatus = (nba) => {
        if (['AP', 'RJ', 'IP', 'NO'].includes(nba?.FPS?.linkStatus)) {
            return true;
        }
        return false;
    };

    return (
        <div>
            <div className="link-title">{t('LB_BS_FT_LINK_TITLE')}</div>
            <div className="FT-form-content">
                <div className="link-row">
                    <div className="link-l">
                        {t('LB_BS_FT_LINK_NBA')} 1<br />
                        {t('LB_BS_FT_LINK_DEP_WDR')}
                    </div>
                    <div className="link-r">
                        {!nbaData.link.nba1 && (
                            <>
                                <div
                                    className="link-no-nba"
                                    dangerouslySetInnerHTML={{ __html: t('LB_BS_FT_LINK_NO_NBA1') }}
                                ></div>
                                <div onClick={() => onClickBankList()} className="link-banklist-url">
                                    <u>{t('LB_BS_FT_LINK_BANKLIST')}</u>
                                </div>
                            </>
                        )}
                        {nbaData.link.nba1?.bankInfo?.[langName] && (
                            <div>
                                {nbaData.link.nba1?.bankInfo?.[langName]}
                                <br />
                                {nbaData.link.nba1?.bankAccountNoMasked}
                                <br />
                            </div>
                        )}
                        {nbaData.link.nba1 &&
                            !nbaData.link.nba1.EPS?.show &&
                            (nbaData.link.nba1.bankInfo?.eDDADepositEnable != '1' ||
                                !isValidFpsStatus(nbaData.link.nba1)) && (
                                <>
                                    {!nbaData.link.nba1?.bankInfo?.code ? (
                                        <div
                                            className="link-no-nba"
                                            dangerouslySetInnerHTML={{ __html: t('LB_BS_FT_LINK_NO_NBA1') }}
                                        ></div>
                                    ) : (
                                        <>
                                            <br />
                                            <div
                                                className="link-no-nba"
                                                dangerouslySetInnerHTML={{ __html: t('LB_BS_FT_LINK_NBA_NOT_SUPPORT') }}
                                            ></div>
                                        </>
                                    )}
                                    <div onClick={() => onClickBankList()} className="link-banklist-url">
                                        <u>{t('LB_BS_FT_LINK_BANKLIST')}</u>
                                    </div>
                                </>
                            )}
                        {nbaData.link.nba1 &&
                        nbaData.link.nba1?.FPS?.linkStatus == 'NO' &&
                        nbaData.link.nba1?.FPS?.FPSPStatus != 1 ? (
                            <div>
                                <br />
                                <div>{t('LB_BS_FT_CASE_FPSISSUSPENDED')}</div>
                            </div>
                        ) : (
                            <div>
                                {nbaData.link.nba1 &&
                                    (nbaData.link.nba1?.bankInfo?.eDDADepositEnable == '1' ||
                                        nbaData.link.nba1?.FPS?.linkStatus == 'AP') &&
                                    isValidFpsStatus(nbaData.link.nba1) && (
                                        <div
                                            className={itemSelected('nba1')}
                                            onClick={() => onClickItem('nba1', nbaData.link.nba1?.FPS, 1)}
                                        >
                                            <div className="link-type-text">
                                                {t('LB_BS_FT_LINK_FPS')}
                                                <br />
                                                <span className="link-type-text-status">
                                                    {t('LB_BS_FT_LINK_' + nbaData.link.nba1?.FPS.linkStatus)}
                                                </span>
                                            </div>
                                            <div className="link-type-icon">
                                                {nbaData.link.nba1?.FPS.isSingleLeg ? (
                                                    <div
                                                        className="redWarningIcon"
                                                        onClick={() =>
                                                            onClickSingleLegErrMsg('nba1', nbaData.link.nba1?.FPS, 1)
                                                        }
                                                    >
                                                        !
                                                    </div>
                                                ) : (
                                                    <div className={nbaData.link.nba1?.FPS.linkStatus}></div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                {nbaData.link.nba1?.EPS.show && (
                                    <div className="link-type-eps">
                                        <div className="link-type-text">
                                            {t('LB_BS_FT_LINK_EPS')}
                                            <br />
                                            {t('LB_BS_FT_LINK_AP')}
                                        </div>
                                        <div className="link-type-icon">
                                            <div className="AP"></div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        {nbaData.link.nba1?.FPS.isSingleLeg && singleLegErrMsg('nba1', nbaData.link.nba1.FPS, 1)}
                        {nbaData.link.nba1?.FPS.linkStatus == 'IP' && <div>{t('LB_BS_FT_LINK_IP_MSG')}</div>}
                        {nbaData.link.nba1?.FPS.linkStatus == 'RJ' && (
                            <div
                                className="link-reject"
                                onClick={() => onClickRejectMsg('nba1', nbaData.link.nba1.FPS, 1)}
                            >
                                <div>
                                    <div>
                                        <strong>{t('LB_BS_FT_LINK_RJ_REASON')}</strong>
                                    </div>
                                    <div>
                                        {rejectMessage(
                                            nbaData.link.nba1?.FPS.linkErrorType,
                                            nbaData.link.nba1?.FPS.linkErrorCode
                                        )}
                                        &nbsp;<u>{t('LB_BS_FT_LINK_RJ_REG')}</u>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="link-row">
                    <div className="link-l">
                        {t('LB_BS_FT_LINK_NBA')} 2<br />
                        {t('LB_BS_FT_LINK_DEP')}
                    </div>
                    <div className="link-r">
                        {!nbaData.link.nba2 && (
                            <>
                                {!nbaData.link.nba2?.bankInfo?.code ? (
                                    <div
                                        className="link-no-nba"
                                        dangerouslySetInnerHTML={{ __html: t('LB_BS_FT_LINK_NO_NBA2') }}
                                    ></div>
                                ) : (
                                    <>
                                        <br />
                                        <div
                                            className="link-no-nba"
                                            dangerouslySetInnerHTML={{ __html: t('LB_BS_FT_LINK_NBA_NOT_SUPPORT') }}
                                        ></div>
                                    </>
                                )}
                                <div onClick={() => onClickBankList()} className="link-banklist-url">
                                    <u>{t('LB_BS_FT_LINK_BANKLIST')}</u>
                                </div>
                            </>
                        )}
                        {nbaData.link.nba2?.bankInfo?.[langName] && (
                            <div>
                                {nbaData.link.nba2?.bankInfo?.[langName]}
                                <br />
                                {nbaData.link.nba2?.bankAccountNoMasked}
                                <br />
                            </div>
                        )}
                        {nbaData.link.nba2 &&
                            !nbaData.link.nba2.EPS?.show &&
                            nbaData.link.nba2?.FPS?.linkStatus != 'AP' &&
                            (nbaData.link.nba2.bankInfo?.eDDADepositEnable != '1' ||
                                !isValidFpsStatus(nbaData.link.nba2)) && (
                                <>
                                    <br />
                                    <div
                                        className="link-no-nba"
                                        dangerouslySetInnerHTML={{ __html: t('LB_BS_FT_LINK_NBA_NOT_SUPPORT') }}
                                    ></div>
                                    <div onClick={() => onClickBankList()} className="link-banklist-url">
                                        <u>{t('LB_BS_FT_LINK_BANKLIST')}</u>
                                    </div>
                                </>
                            )}
                        {nbaData.link.nba2 &&
                        nbaData.link.nba2.FPS?.linkStatus == 'NO' &&
                        nbaData.link.nba2?.FPS?.FPSPStatus != 1 ? (
                            <div>
                                <br />
                                <div>{t('LB_BS_FT_CASE_FPSISSUSPENDED')}</div>
                            </div>
                        ) : (
                            <div>
                                {nbaData.link.nba2 &&
                                    (nbaData.link.nba2.bankInfo.eDDADepositEnable == '1' ||
                                        nbaData.link.nba2?.FPS?.linkStatus == 'AP') &&
                                    isValidFpsStatus(nbaData.link.nba2) && (
                                        <div
                                            className={itemSelected('nba2')}
                                            onClick={() => onClickItem('nba2', nbaData.link.nba2?.FPS, 2)}
                                        >
                                            <div className="link-type-text">
                                                {t('LB_BS_FT_LINK_FPS')}
                                                <br />
                                                <span className="link-type-text-status">
                                                    {t('LB_BS_FT_LINK_' + nbaData.link.nba2?.FPS.linkStatus)}
                                                </span>
                                            </div>
                                            <div className="link-type-icon">
                                                {nbaData.link.nba2?.FPS.isSingleLeg ? (
                                                    <div
                                                        className="redWarningIcon"
                                                        onClick={() =>
                                                            onClickSingleLegErrMsg('nba2', nbaData.link.nba2?.FPS, 2)
                                                        }
                                                    >
                                                        !
                                                    </div>
                                                ) : (
                                                    <div className={nbaData.link.nba2?.FPS.linkStatus}></div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                {nbaData.link.nba2?.EPS.show && (
                                    <div className="link-type-eps">
                                        <div className="link-type-text">
                                            {t('LB_BS_FT_LINK_EPS')}
                                            <br />
                                            {t('LB_BS_FT_LINK_AP')}
                                        </div>
                                        <div className="link-type-icon">
                                            <div className="AP"></div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        {nbaData.link.nba2?.FPS.isSingleLeg && singleLegErrMsg('nba2', nbaData.link.nba2?.FPS, 2)}
                        {nbaData.link.nba2?.FPS.linkStatus == 'IP' && <div>{t('LB_BS_FT_LINK_IP_MSG')}</div>}
                        {nbaData.link.nba2?.FPS.linkStatus == 'RJ' && (
                            <div
                                className="link-reject"
                                onClick={() => onClickRejectMsg('nba2', nbaData.link.nba2?.FPS, 2)}
                            >
                                <div>
                                    <div>
                                        <strong>{t('LB_BS_FT_LINK_RJ_REASON')}</strong>
                                    </div>
                                    {
                                        <div>
                                            {rejectMessage(
                                                nbaData.link.nba2?.FPS.linkErrorType,
                                                nbaData.link.nba2?.FPS.linkErrorCode
                                            )}
                                            &nbsp;<u>{t('LB_BS_FT_LINK_RJ_REG')}</u>
                                        </div>
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="link-footer" dangerouslySetInnerHTML={{ __html: t('LB_BS_FT_LINK_NO_NBA') }}></div>
            </div>
            <FPSBanner fpsBannerQuery={props.fpsBannerQuery} />

            {showNextButton() ? (
                <div className="deposit-btnArea">
                    <div
                        className={selectedItem?.nba ? 'deposit-btn link-btn-active' : 'deposit-btn link-btn-nonActive'}
                        onClick={onClickNext}
                    >
                        {t('LB_BS_FT_NEXT')}
                    </div>
                </div>
            ) : (
                <div className="deposit-btnArea">
                    <div className="deposit-btn doneBtn link-btn-active" onClick={onClickDone}>
                        {t('LB_BETSLIP_DONE')}
                    </div>
                </div>
            )}
        </div>
    );
};

export default LinkMain;
