import React, { useContext } from 'react';
import NextDrawInfoTable from '../Common/NextDrawInfoTable';
import MultipleSelectedNumTable from '../Common/MultipleSelectedNumTable';
import MyFavourites from '../Common/MyFavourites';
import { MarksixContext } from '../../Home/MarksixPage';

const Multiple = () => {
    const context = useContext(MarksixContext);

    return (
        <section className={context.pageName}>
            <NextDrawInfoTable />
            <MultipleSelectedNumTable />
            <MyFavourites />
        </section>
    );
}
export default Multiple;
