import { useCallback, useState } from 'react';
import Cookies from 'js-cookie';
import { cookie2obj, obj2cookie } from '../../Common/utils';

const personalSetSplitFn = (objStr, opt) => {
    let newObj = {};
    if (typeof objStr == 'string') {
        newObj = { ...cookie2obj(objStr) }
    } else {
        newObj = { ...objStr }
    }
    let domainShare = {}
    const hostNameArr = window.location.host.split('.');
    if (hostNameArr.length > 1) {
        domainShare = { domain: ` .${hostNameArr[1]}.${hostNameArr[2]}` }
    }
    let newOpt = { ...opt, ...domainShare }
    const {
        racing,
        football,
        m6MulDrow = '1',
        accountInfo: { accountName = '0', accountBalance = '0' },
        racingNotes = '1',
        showBetlineDetail = '0',
        allUpSetting = '1',
    } = newObj;
    const arr = ['DefAmtHB', 'DefAmtSB', 'M6DrawNo', 'AccInfo', 'AlupSetting', 'showPeNote', 'showBetlineDetail']
    arr.map((item) => {
        if (['DefAmtHB', 'DefAmtSB'].includes(item)) {
            const isHB = 'DefAmtHB' == item;
            let obj = obj2cookie(isHB ? racing : football);
            let strObj = defAmtReplaceStrFn(String(obj), isHB, 'split');
            let newStr = strObj.slice(1, strObj.length - 1)
            Cookies.set(isHB ? 'DefAmtHB' : 'DefAmtSB', newStr, newOpt)
        } else if (['AccInfo'].includes(item)) {
            Cookies.set(item, `${accountName},${accountBalance}`, newOpt)
            Cookies.set('isMaskAmount', accountBalance=='1'?false:true)
        } else if (['M6DrawNo'].includes(item)) {
            Cookies.set(item, `${m6MulDrow}`, newOpt)
        } else if (['AlupSetting'].includes(item)) {
            Cookies.set(item, `${allUpSetting}`, newOpt)
        } else if (['showPeNote'].includes(item)) {
            Cookies.set(item, `${racingNotes}`, newOpt)
        } else if (['showBetlineDetail'].includes(item)) {
            Cookies.set(item, `${showBetlineDetail}`, newOpt)
        }
    })
}

const defAmtReplaceStrFn = (allStr, isHB, type = 'join') => {
    let newStr = allStr;
    const alupArr = ['ALUP', 'ALUPX']
    const start = type == 'split' ? 0 : 1;
    const end = type == 'split' ? 1 : 0;
    newStr = newStr.replace(alupArr[start], alupArr[end]);
    if (isHB) {
        const HBReplaceArr = [['FF', 'F-F'], ['DT', 'D-T'], [',TT', ',T-T']]
        HBReplaceArr.map(item => {
            newStr = newStr.replace(item[start], item[end]);
        })
        const cwStartIndex = newStr.indexOf(',CW:');
        const cwendIndex = newStr.indexOf(',IWN:');
        const cwStrFirst = newStr.slice(0, cwStartIndex + 4);
        const cwStrSec = newStr.slice(cwStartIndex + 4, cwendIndex);
        const cwStrThr = newStr.slice(cwendIndex);
        let cwStrSecChange = cwStrSec
        if (type == 'split') {
            cwStrSecChange = `${cwStrSec},CWA:${cwStrSec},CWB:${cwStrSec},CWC:${cwStrSec}`
        } else if (type == 'join') {
            cwStrSecChange = `${cwStrSec.split(',')[0]}`
        }
        newStr = `${cwStrFirst}${cwStrSecChange}${cwStrThr}`
    }
    return newStr;
}

const personalSetJoinFn = (objStr, opt) => {
    const arr = ['DefAmtHB', 'DefAmtSB', 'M6DrawNo', 'AccInfo', 'AlupSetting', 'showPeNote', 'showBetlineDetail']

    let newObj = {};
    let oldObj = {}
    if (typeof objStr == 'string') {
        oldObj = { ...cookie2obj(objStr) }
    } else {
        oldObj = { ...objStr }
    }
    arr.map((item) => {
        const hasCookies = Cookies.get(item);
        if (hasCookies) {

            if (['DefAmtHB', 'DefAmtSB'].includes(item)) {
                const isHB = 'DefAmtHB' == item;
                let str = Cookies.get(isHB ? 'DefAmtHB' : 'DefAmtSB');
                if (str) {
                    let newStr = `{${str}}`
                    let strObj = defAmtReplaceStrFn(newStr, isHB, 'join');
                    let obj = cookie2obj(strObj);
                    newObj[`${isHB ? 'racing' : 'football'}`] = obj;
                }
            } else if (['AccInfo'].includes(item)) {
                let AccInfoArr = hasCookies.split(',')
                newObj.accountInfo = {
                    accountName: AccInfoArr[0],
                    accountBalance: AccInfoArr[1]
                }
                Cookies.set('isMaskAmount', AccInfoArr?.[1]=='1'?false:true)
            } else if (['M6DrawNo'].includes(item)) {
                newObj.m6MulDrow = hasCookies;
            } else if (['AlupSetting'].includes(item)) {
                newObj.allUpSetting = hasCookies;
            } else if (['showPeNote'].includes(item)) {
                newObj.racingNotes = hasCookies;
            } else if (['showBetlineDetail'].includes(item)) {
                newObj.showBetlineDetail = hasCookies;
            }
        }
    })
    if (!Cookies.get('personalSettings')) {
        Cookies.set('personalSettings', String(obj2cookie({ ...oldObj, ...newObj })), opt);
    }
    return obj2cookie({ ...oldObj, ...newObj });
}

const useCookie = (cookieName, initialValue, options, raw = true) => {
    const [value, setValue] = useState(() => {
        try {
            const v = Cookies.get(cookieName);
            if (Cookies.get('DefAmtHB')) {
                return personalSetJoinFn(String(initialValue), options);
            } else if (typeof v !== 'string') {
                Cookies.set(cookieName, raw ? String(initialValue) : JSON.stringify(initialValue), options);

                personalSetSplitFn(String(initialValue), options)
                return initialValue;
            } else {
                return raw ? v : JSON.parse(v || 'null');
            }
        } catch {
            return initialValue;
        }
    });

    const updateCookie = useCallback(
        (newValue, newOptions = {}) => {
            let v = raw ? String(newValue) : JSON.stringify(newValue);
            Cookies.set(cookieName, v, { ...options, ...newOptions });
            if (cookieName == 'personalSettings') {
                personalSetSplitFn(newValue, { ...options, ...newOptions })
            }
            setValue(newValue);
        },
        [cookieName],
    );



    const deleteCookie = useCallback(() => {
        Cookies.remove(cookieName);
        setValue(null);
    }, [cookieName]);

    return [value, updateCookie, deleteCookie];
};

export default useCookie;

// import React from 'react';

// import useCookie from './hooks/useCookie-v2';

// const App = () => {
//     const [value, setValue] = useCookie('aaa', 'aaa');
//     console.log(value);
//     return <div onClick={() => setValue('bbb', { expires: 365 })}>111</div>;
// };

// export default App;
