import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../Common/CommonHooks';
import OddsTable from '../Common/RCOddsTableCompact';
import OddsDropLegend from '../Common/OddsDropLegend';
import InvestmentCalculator from '../Common/InvestmentCalculator';
import { SetBetSelectSingle } from '../Common/RacingBetline';
import IWNOddsTable from '../Common/IWNOddsTable';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { RacingContext } from '../../Home/RacingPage';
import AddSlipBtn from '../Common/AddSlipBtn';
import {MobileCurrentOdds} from '../Common/CurrentOdds';
import Loading from '../../Common/ui-components/Loading';
import { isStartSellpmPool } from '../Common/Common';

const OddsIWN = (props) => {
    const { t, i18n } = useTranslation();
    const { isMobileSize } = useWindowSize();
    const context = useContext(RacingContext);
    const mtg = context.content.meeting;
    const [scnUpdate, setScreenUpdate] = useState({});
    let pool = mtg.pmPools.filter((data) => data.leg.races[0] == context.content.raceNo && data.oddsType == 'IWN')[0];
    let isStartSell = isStartSellpmPool(pool);//pool?.status === 'START_SELL';

    const RCColumns = {
        no: true,
        banker1: true,
        banker2: false,
        banker3: false,
        leg: true,
        horseName: true,
        rcNoteM: context.content.isPeNoteEnable,
        win: true,
        place: false,
        field: false
    };

    const RCColumnLbls = {
        banker1: t('LB_RC_ODDS_IWNTABLE_1ST_IWN'),
        leg: t('LB_RC_ODDS_IWNTABLE_2ND_IWN')
    };

    const handleOddsClick = (_type, _race, _col, _no, val) => {
        SetBetSelectSingle(_type, _race, _col, _no, val);
        setScreenUpdate({ ...scnUpdate });
    };

    const RemarkMsg = () => {
        let sgaAmount = pool?.minTicketCost;
        return (
            <>
                <div className="rc-comingle-remarks">{t('LB_RC_ODDS_IWN_REMARKS_1')}</div>
                <div className="rc-comingle-remarks">
                    {sgaAmount > 10 ? t('LB_RC_ODDS_IWN_REMARKS_2') + sgaAmount : null}
                </div>
            </>
        );
    };
    const columnsCountBreakPoints = isMobileSize ? { 320: 1 } : { 350: 2 }

    if (context == null) {
        return <Loading/>
    } else {
        let pageConfig = context.pageConfig;

        return (
            <section className={context.content.page}>
                <InvestmentCalculator enable={true} />
                <MobileCurrentOdds />
                <ResponsiveMasonry columnsCountBreakPoints={columnsCountBreakPoints} style={{ margin: '12px 0px' }}>
                    <Masonry className="masonry-col">
                        <div>
                            <IWNOddsTable msg={<RemarkMsg />} enableQuickBet={!isMobileSize && isStartSell} isStartSell={isStartSell}/>
                        </div>
                        <div>
                            <OddsTable
                                betType={context.content.page}
                                columns={RCColumns}
                                lbl={RCColumnLbls}
                                showAddSlip={false}
                                firstRace={context.content.raceNo}
                                raceNo={context.content.raceNo}
                                selectedBetType={context.content.page}
                                startCnt={0}
                                oddsCheckboxClickCallback={handleOddsClick}
                            />
                        </div>
                        <div className="OddsDropLegend-Content-ge1280">
                            <OddsDropLegend />
                        </div>
                    </Masonry>
                </ResponsiveMasonry>
                <div className="OddsDropLegend-Content-lt1280">
                    <OddsDropLegend />
                </div>
                <div className="AddSlipBtn-Content">
                    <AddSlipBtn />
                </div>
                <InvestmentCalculator enable={!context.windowSize.isMobileSize} />
            </section>
        );
    }
};
export default OddsIWN;
