import { parseFirstNamed, parseFullOdds, parseLast2Mins } from "./parseFullOdds";
import { parseTopNOdds } from "./parseTopNOdds";
import { parseBankerOdds } from "./parseBankerOdds";
import{ parseWPQBanker}  from "./parseWPQBanker";
import{ parseWPQTop}  from "./parseWPQTop";

export const parseOddsPool = ( content, message, topicInfo) => {
    let pmPool;
    switch (topicInfo.displayType) {
        case "full":
            pmPool = content.meeting.pmPools.filter(x => x.oddsType===topicInfo.oddsType && x.leg.races[0]===topicInfo.raceNo)[0];
            if(pmPool!=null){
                parseFullOdds(pmPool,message)
            }
            if(content.page == "PWIN" && topicInfo.oddsType=="WIN"){
                let pwin1Pool = content.meeting.pmPools.filter(x => x.oddsType==="PWIN1" && x.leg.races[0]===topicInfo.raceNo)[0];
                if ( pwin1Pool!=null ) {
                    parseLast2Mins(pwin1Pool,message);
                }
                else {
                    if(pmPool!=null && message.oddsSnapshot!=null ) {
                        pwin1Pool = {...pmPool};
                        pwin1Pool.id = pwin1Pool.id.replace("WIN", "PWIN1");
                        pwin1Pool.oddsType = "PWIN1";
                        parseLast2Mins(pwin1Pool,message);
                        content.meeting.pmPools.push(pwin1Pool);

                        let pwin0Pool = {...pmPool};
                        pwin0Pool.id = pwin0Pool.id.replace("WIN", "PWIN0");
                        pwin0Pool.oddsType = "PWIN0";
                        parseFirstNamed(pwin0Pool,message);
                        content.meeting.pmPools.push(pwin0Pool);
                    }
                }
            }
            if(content.page == "WPQ"){    
                pmPool = content.meeting.pmPools.filter(x => x.oddsType===topicInfo.oddsType +"Top" && x.leg.races[0]===topicInfo.raceNo)[0]; //parseTop20
                if(pmPool!=null){
                    parseWPQTop(pmPool,message)
                }
                pmPool = content.meeting.pmPools.filter(x => x.oddsType===topicInfo.oddsType +"Banker" && x.leg.races[0]===topicInfo.raceNo)[0]; //parse Banker
                if(pmPool!=null){
                    parseWPQBanker(pmPool,message)
                }
            }
            break;
        case "top_n":
            pmPool = content.meeting.pmPools.filter(x => x.oddsType===topicInfo.oddsType + "Top" && x.leg.races[0]===topicInfo.raceNo)[0];
            if(pmPool!=null){
                parseTopNOdds(pmPool,message)
            }
            break;
        case "banker":
            pmPool = content.meeting.pmPools.filter(x => x.oddsType===topicInfo.oddsType + "Banker" && x.leg.races[0]===topicInfo.raceNo)[0];
            if(pmPool!=null){
                parseBankerOdds(pmPool,message)
            }
            break;
    }
}