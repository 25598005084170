import React, { useEffect, useState, useContext, useRef } from 'react';
import { useParams } from "react-router-dom";
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FootballContext } from '../Home/FootballPage';
import { RacingContext } from '../Home/RacingPage';
import { MarksixContext } from '../Home/MarksixPage';
import { GlobalContext } from '../../StandardPage';
import { isLocalMeeting } from '../Common/home-common';
import { sortSeqIgnoreCase, isEarlySettlementEanbled } from '../Football/Common/Common';
import { highLightLeftMenuItem, LeftMenuIcon, hasChpTypePool } from './MenuCommon';
import { useOddsMenuQuery } from '../Home/Data/SitecoreDataHooks';
import './scss/MobileMenu.scss';
import { isLogonHigh } from '../BetSlip/LoginHooks'
import { scrollTo } from '../Common/GlobalFunc';

const MobileMenu = (props) => {
    const { t, i18n } = useTranslation();
    let { page, matchId } = useParams();
    const racingContext = useContext(RacingContext);
    const marksixContext = useContext(MarksixContext);
    const globalContext = useContext(GlobalContext);
    const fbContext = useContext(FootballContext);
    const fbMenuGqlVars = globalContext.fbMenuGqlVars;
    const product = props.product;
    const [menuLoading, menuData] = useOddsMenuQuery(props.product);
    const [show, setShow] = useState(false);
    const cashoutData = globalContext.esHook.getEsData() || [];

    const getSelectedItemName = () => {
        if (menuLoading) return '';

        if ( !page ) {
            page = 'home';
        }
        page = page.toUpperCase();
        switch (product) {
            case 'racing':
                if (page === 'HOME') {
                    return t('LB_RACE_HOME');
                } else if (page === 'HOME_RESULTS') {
                    return t('LB_RACE_HOME_RESULTS');
                } else if (['WPALUP', 'WPQALUP', 'FCTALUP', 'TRIALUP'].includes(page)) {
                    page = page.replace('ALUP', '');
                } else if (['CWA', 'CWAALUP', 'CWAALLALUP'].includes(page)) {
                    return t('LB_CWA');
                } else if (['JKC','TNC'].includes(page)) {
                    if ( racingContext?.content?.meeting?.foPools[0] ) {
                        return `${t('LB_'+page)} ${racingContext.content.meeting.foPools[0].instNo}`;
                    }
                }
                break;
            case 'football':
                if (page === 'HOME') {
                    return t('LB_FB_TITLE_HAD');
                } else if (page === 'INPLAY_ALL') {
                    page = 'INPLAY';
                } else if (page === 'TOURNAMENT') {
                    let tmpPage = '';
                    fbMenuGqlVars?.data?.tournaments?.forEach((item) => {
                        if (window.location.pathname.indexOf('/' + item.nameProfileId) >= 0) {
                            tmpPage = item['name_' + i18n.language];
                        }
                    });
                    return tmpPage;
                }
                break;
            case 'marksix':
                if (page === 'HOME') {
                    return t('LB_HOME');
                }
                break;
        }
        for (let i = 0; i < menuData.length; i++) {
            if (menuData[i].itemPage) {
                let item = menuData[i].itemPage.value;
                // console.log(item)
                let isSamePage = matchId ? `${page}/${matchId}`.toUpperCase() === item.toUpperCase() : page.toUpperCase() === item.toUpperCase()
                if (menuData[i].sectionTitle === null && isSamePage) {
                    let itemNameValue = menuData[i].itemName.value;
                    // the selected bet type should not include spacing
                    // if(itemNameValue === "Home/Away/Draw") { itemNameValue  = "Home / Away / Draw";} //SQ0248-2610
                    return itemNameValue;
                }

                // PRJ1027-7066
                if (menuData[i].sectionTitle === null && page.toUpperCase() === menuData[i].itemPage.value.toUpperCase()) {
                    return menuData[i].itemName.value;
                }
            }

            if (menuData[i].children.length > 0) {
                for (let j = 0; j < menuData[i].children.length; j++) {
                    let cItem = menuData[i].children[j].itemPage.value;
                    let cIsSamePage = matchId ? `${page}/${matchId}`.toUpperCase() === cItem.toUpperCase() : page.toUpperCase() === cItem.toUpperCase()
                    if (cIsSamePage)
                        return menuData[i].children[j].itemName.value;
                }
            }
        }

        return '';
    };

    const handleMenuClick = (menu) => {
        setShow(false);
        if (menu.itemRedirection.value === window.sitecoreVars.REDIRECT_JCBW_PAGE) {
            innerPageChange(menu.itemPage.value);
        } else {
            window.open(menu.itemLink.value, '_self');
        }
    };

    const innerPageChange = (page) => {
        let url = '';
        switch (product) {
            case 'racing':
                url = `/${i18n.language}/${product}/${page}/${racingContext.content.date}/${racingContext.content.venue}/${racingContext.content.raceNo}`;
                break;
            case 'football':
                url = `/${i18n.language}/${product}/${page}`;
                break;
            case 'marksix':
                url = `/${i18n.language}/${product}/${page}`;
                break;
            default:
                break;
        }
        props.onPageChange(url, page);
        scrollTo(0, 0)
    };

    const reorginizeMenu = (menuList) => {
        let newList = [];
        let tmpMenuList = [...menuList];
        let half = Math.floor(tmpMenuList.length / 2);
        let secondHalfList = tmpMenuList.splice(-half);
        for (let i = 0; i <= half; i++) {
            if (tmpMenuList[i]) newList.push(tmpMenuList[i]);
            if (secondHalfList[i]) newList.push(secondHalfList[i]);
        }
        if (newList.length % 2 === 1) {
            newList.push(<Dropdown.Item key="emptyMenuItem" className={`dropdown-child`}></Dropdown.Item>);
        }
        return [<div>{newList}</div>];
    };

    const appendMenuNameSuffix = (menu) => {
        switch ( menu.key ) {
            case window.sitecoreVars.MENU_JKC_ODDS_PAGE:
                if ( racingContext?.content?.meeting?.jkcInstNo.length>0 ) {
                    return ` ${racingContext?.content?.meeting?.jkcInstNo[0].instNo}`;
                }
                break;
            case window.sitecoreVars.MENU_TNC_ODDS_PAGE:
                if ( racingContext?.content?.meeting?.tncInstNo.length>0 ) {
                    return ` ${racingContext?.content?.meeting?.tncInstNo[0].instNo}`;
                }
                break;
        }
        return '';
    }

    const setMenu = (menuList, menu, addedTournPId) => {
        let showMenu = menu.key!=window.sitecoreVars.MENU_EARLY_SETTLEMENT || isEarlySettlementEanbled(globalContext);
        if (menu.itemEnable.value !== '1' || !showMenu) {
            return null;
        }
        let _name = `${menu.itemName.value}${appendMenuNameSuffix(menu)}`;
        let _url = menu.itemPage.value;
        let showChildItem = false;
        let predefinedColor = menu.predefinedColor?.targetItem?.optionValue?.value || "";
        let hlStyle = predefinedColor!="" ? { color: predefinedColor } : {};

        if (menu.key === window.sitecoreVars.MENU_AUTO_CWA_MENU && globalContext.globalState.commonCtrl.CWAButtonEnabled) {
            let mtg = racingContext.content.meeting;
            if (mtg != null) {
                if (isLocalMeeting(mtg.venueCode) || mtg.poolInvs.filter((x) => x.oddsType === 'CWA').length > 0) {
                    menuList.push(
                        <Dropdown.Item key={menu.key} className={`dropdown-child`} style={hlStyle}>
                            <div>
                                {_name}
                                <LeftMenuIcon menu={menu} />
                            </div>
                        </Dropdown.Item>
                    );
                    showChildItem = true;
                }
            }
        } else if (menu.key === window.sitecoreVars.MENU_AUTO_CWB_CWC_MENU) {
            let mtg = racingContext.content.meeting;
            if (mtg != null) {
                let hasCWB = mtg.poolInvs.filter((x) => 'CWB' === x.oddsType).length > 0 && globalContext.globalState.commonCtrl.CWBButtonEnabled;
                let hasCWC = mtg.poolInvs.filter((x) => 'CWC' === x.oddsType).length > 0 && globalContext.globalState.commonCtrl.CWCButtonEnabled;
                if (hasCWB || hasCWC) {
                    menuList.push(
                        <Dropdown.Item key={menu.key} className={`dropdown-child`} style={hlStyle}>
                            <div>
                                {_name}
                                <LeftMenuIcon menu={menu} />
                            </div>
                        </Dropdown.Item>
                    );
                    showChildItem = true;
                }
            }
        } else if (menu.key === window.sitecoreVars.MENU_AUTO_FEATURED_MATCHES) {
            if (fbMenuGqlVars?.data?.featuredMatches.length > 0 && globalContext.globalState.commonCtrl.enableFeaturedMatches) {
                menuList.push(
                    <Dropdown.Item
                        key={menu.key}
                        value={_url}
                        onClick={() => {
                            handleMenuClick(menu);
                        }}
                        className={`dropdown-child cursor-pointer ${
                            highLightLeftMenuItem(_url, globalContext, product) ? 'active' : ''
                        }`}
                        style={hlStyle}
                    >
                        {_name}
                        <LeftMenuIcon menu={menu} />
                    </Dropdown.Item>
                );
            }
        } else if (menu.key === window.sitecoreVars.MENU_AUTO_TOURN_LIST) {
            fbMenuGqlVars?.data?.tournaments?.sort(sortSeqIgnoreCase);
            fbMenuGqlVars?.data?.tournaments?.forEach((item) => {
                let isActiveMenuItem = window.location.pathname.indexOf('/' + item.nameProfileId) >= 0;
                let tournUrl = 'tournament/' + item.nameProfileId;
                let topItem = (
                    <Dropdown.Item
                        key={tournUrl}
                        value={tournUrl}
                        onClick={() => {
                            innerPageChange(tournUrl);
                        }}
                        className={`dropdown-child cursor-pointer ${isActiveMenuItem ? 'active' : ''}`}
                        style={hlStyle}
                    >
                        {item['name_' + i18n.language]}
                        <LeftMenuIcon menu={menu} />
                    </Dropdown.Item>
                );
                if (!addedTournPId.includes(item.nameProfileId)) {
                    addedTournPId.push(item.nameProfileId);
                    menuList.push(topItem);
                }
            });
        }
        else if (menu.key===window.sitecoreVars.MENU_AUTO_FINALIST) {
            if ( hasChpTypePool("3", fbMenuGqlVars?.data?.chpTypes) ) {
                menuList.push(
                    <Dropdown.Item
                        key={menu.key}
                        value={_url}
                        onClick={() => {
                            handleMenuClick(menu);
                        }}
                        className={`dropdown-child cursor-pointer ${
                            highLightLeftMenuItem(_url, globalContext, product) ? 'active' : ''
                        }`}
                        style={hlStyle}
                    >
                        {_name}
                        <LeftMenuIcon menu={menu} />
                    </Dropdown.Item>
                );
            }
        }
        else if (menu.key===window.sitecoreVars.MENU_AUTO_WINNING_COUNTRY) {
            if ( hasChpTypePool("1", fbMenuGqlVars?.data?.chpTypes) ) {
                menuList.push(
                    <Dropdown.Item
                        key={menu.key}
                        value={_url}
                        onClick={() => {
                            handleMenuClick(menu);
                        }}
                        className={`dropdown-child cursor-pointer ${
                            highLightLeftMenuItem(_url, globalContext, product) ? 'active' : ''
                        }`}
                        style={hlStyle}
                    >
                        {_name}
                        <LeftMenuIcon menu={menu} />
                    </Dropdown.Item>
                );
            }
        }
        else if (menu.key===window.sitecoreVars.MENU_AUTO_WINNING_CONTINENT) {
            if ( hasChpTypePool("2", fbMenuGqlVars?.data?.chpTypes) ) {
                menuList.push(
                    <Dropdown.Item
                        key={menu.key}
                        value={_url}
                        onClick={() => {
                            handleMenuClick(menu);
                        }}
                        className={`dropdown-child cursor-pointer ${
                            highLightLeftMenuItem(_url, globalContext, product) ? 'active' : ''
                        }`}
                        style={hlStyle}
                    >
                        {_name}
                        <LeftMenuIcon menu={menu} />
                    </Dropdown.Item>
                );
            }
        }
        else {
            if (menu.specialtag?.value === window.sitecoreVars.MENU_ADHOC_TOURN) {
                let frontEndId = menu.frontendID.value;
                let tourn = fbContext?.contentRef.current.rawTournList.filter(x=>x.frontEndId===frontEndId)[0];
                if (tourn) {
                    addedTournPId.push(tourn.nameProfileId);
                    let isActiveMenuItem = window.location.pathname.indexOf('/' + tourn.nameProfileId) >= 0;
                    let tournUrl = 'tournament/' + tourn.nameProfileId;
                    menuList.push(
                        <Dropdown.Item
                            key={tournUrl}
                            value={tournUrl}
                            onClick={() => {
                                innerPageChange(tournUrl);
                            }}
                            className={`dropdown-child cursor-pointer ${isActiveMenuItem ? 'active' : ''}`}
                            style={hlStyle}
                        >
                            {_name}
                            <LeftMenuIcon menu={menu} />
                        </Dropdown.Item>
                    );
                }
            } else if (menu.specialtag?.value === window.sitecoreVars.MENU_ADHOC_MATCH) {
                let frontEndId = menu.frontendID.value;
                let match = fbContext.contentRef.current.rawMatchList.filter(x=>x.frontEndId===frontEndId)[0];
                if (match) {
                    let alloddsUrl = (match.status !== 'PREEVENT' ? 'inplay_all/' : 'allodds/') + match.id;
                    menuList.push(
                        <Dropdown.Item
                            key={alloddsUrl}
                            value={alloddsUrl}
                            onClick={() => {
                                innerPageChange(alloddsUrl);
                            }}
                            className={`dropdown-child cursor-pointer ${highLightLeftMenuItem(alloddsUrl, globalContext, product) ? 'active' : ''}`}
                            style={hlStyle}
                        >
                            {_name}
                            <LeftMenuIcon menu={menu}
                            />
                        </Dropdown.Item>
                    );
                }
            } else {
                menuList.push(
                    <Dropdown.Item
                        key={menu.key}
                        value={_url}
                        onClick={() => {
                            handleMenuClick(menu);
                        }}
                        className={`dropdown-child cursor-pointer ${highLightLeftMenuItem(_url, globalContext, product) ? 'active' : ''}`}
                        style={hlStyle}
                    >
                        {_name}
                        <LeftMenuIcon menu={menu}
                        cashOutNo={
                            menu.key===window.sitecoreVars.MENU_EARLY_SETTLEMENT && isLogonHigh() && cashoutData.length>0?
                            <span className={`cashoutMenuNo${cashoutData.length>99?' moreThan99':''}`}>{cashoutData.length>99?'99+':cashoutData.length}</span>
                            : null
                        }
                        />
                    </Dropdown.Item>
                );
            }
        }

        if (showChildItem && menu.children?.length > 0) {
            menu.children.forEach((cMenu, i) => {
                if (cMenu.itemEnable.value === '1') {
                    let _childName = cMenu.itemName.value;
                    let _childUrl = cMenu.itemPage.value;
                    let showMenu = false;

                    if (cMenu.key === window.sitecoreVars.MENU_AUTO_CWB_MENU && globalContext.globalState.commonCtrl.CWBButtonEnabled) {
                        let mtg = racingContext.content.meeting;
                        if (mtg != null) {
                            showMenu =
                                racingContext.content.meeting.poolInvs.filter((x) => 'CWB' === x.oddsType).length > 0;
                        }
                    } else if (menu.key === window.sitecoreVars.MENU_AUTO_CWC_MENU && globalContext.globalState.commonCtrl.CWCButtonEnabled) {
                        let mtg = racingContext.content.meeting;
                        if (mtg != null) {
                            showMenu =
                                racingContext.content.meeting.poolInvs.filter((x) => 'CWC' === x.oddsType).length > 0;
                        }
                    } else {
                        showMenu = true;
                    }

                    if (showMenu) {
                        menuList.push(
                            <Dropdown.Item
                                key={cMenu.key}
                                value={_childUrl}
                                onClick={() => {
                                    handleMenuClick(cMenu);
                                }}
                                className={`son-menu-m son-menu-m-${i} ${menu.children?.length === i + 1 ? 'son-menu-m-last' : ''} dropdown-child cursor-pointer ${
                                    highLightLeftMenuItem(_childUrl, globalContext, product) ? 'active' : ''}
                                    ${Math.ceil ? 'leftItem' : 'rightItem'}
                                    `}
                            >
                                <span className='before'></span>
                                <span className="leftMenuItemPoint">{_childName}</span>
                                <span className='after'></span>
                            </Dropdown.Item>
                        );
                    }
                }
            });
        }
    };

    const scrollToOddsTable = () => {
        const top = document.querySelector('.marksix-header-info').offsetTop
        const scrollBar = document.querySelector('.StandardPage-scrollbars > div:first-child')

        scrollBar.scrollTop =  top
    };

    const MenuHeader = () => {
        switch (props.product) {
            case 'racing':
            case 'football':
                return (
                    <div key={`menu-Odds`} className="dropdown-section">
                        {t('LB_ODDS')}
                        {/* <div
                            style={{ top: 1 }}
                            className="msgInboxClose"
                            onClick={() => {
                                setShow(false);
                            }}
                        ></div> */}
                    </div>
                );
        }
        return null;
    };



    const container = useRef();

    // const Menu = () => {
    //     return (
    //         <>
    //             <button
    //                 onClick={() => {
    //                     setShow(!show);
    //                 }}
    //                 id="menu-icon"
    //                 aria-expanded="true"
    //                 type="button"
    //                 class="dropdown-toggle btn"
    //             ></button>
    //             <Modal
    //                 show={show}
    //                 onHide={() => {
    //                     setShow(false);
    //                 }}
    //                 dialogClassName="mobile-menu-modal modal-90w incorrect-modal"
    //                 aria-labelledby="incorrect-modal"
    //                 centered
    //                 container={container.current}
    //             >
    //                 <MenuHeader />
    //                 <Modal.Body>
    //                     <MenuBody />
    //                 </Modal.Body>
    //             </Modal>
    //         </>
    //     );
    // };
    const menuBodyProps={menuData, setMenu,marksixContext, reorginizeMenu}


    return !menuLoading ? (
        <div className="navigation-menu flex-center">
            <label className="pageName" htmlFor="menu-icon" dangerouslySetInnerHTML={{ __html: getSelectedItemName()?.replace(/(\/)/g,'$1<wbr />') }}></label>
            <Menu MenuBody={MenuBody} MenuHeader={MenuHeader} isShow={show} menuBodyProps={menuBodyProps} globalContext={globalContext}/>
            <div ref={container}></div>
        </div>
    ) : null;
};
export default MobileMenu;


const Menu = ( { MenuHeader, MenuBody,menuBodyProps, globalContext }) => {
    const { t, i18n } = useTranslation();
    const onClose = (isOpen) => {
        const scrollBar = document.querySelector('body')
        if(!isOpen){
            scrollBar.style.overflow = ''
        }else{
            const isRaicng = globalContext.globalState.product === 'racing'
            const meetingButton = document.querySelector('.meeting-button')

            if(isRaicng && meetingButton){
                const top =getOffsetTop(meetingButton)
                top !== false && window.scrollTo(0, top)

            }else{
                const top =getOffsetTop(document.querySelector('.navigation-menu'))
                top !== false && window.scrollTo(0, top - 15)
            }
            scrollBar.style.overflow = 'hidden'
        }
    }

    const getOffsetTop = (el) => { //获取当前element距离html顶部的距离
        let topH = el.offsetTop
        if(el&&el.nodeName != 'HTML'){
            el = el.parentElement
            while(el&&el.nodeName != 'HTML'){
                if(getComputedStyle(el).position !== 'static'){
                    if(getComputedStyle(el).position === 'fixed') return false;
                    topH += el.offsetTop
                }
                el = el.parentElement
            }
        }
        return topH
    }

    return <Dropdown
        key={`DropdownButton`}
        align="end"
        onToggle={onClose}
    >
        <Dropdown.Toggle aria-label={t('LB_AS_OPEN_BET_TYPE_LIST')}  id={`menu-icon`}>
        </Dropdown.Toggle>
        <Dropdown.Menu>
            <MenuHeader />
            <div className='DropdownButtonScrollContainer'>
            <MenuBody {...menuBodyProps}/>
            </div>
        </Dropdown.Menu>
    </Dropdown>
}

const MenuBody = ({menuData, setMenu,marksixContext, reorginizeMenu}) => {
    let addedTournPId = [];
    let menuList = [];
    let needReorg = true;
    menuData.forEach((menu, i) => {
        if (menu.sectionTitle === null) {
            // index ++
            setMenu(menuList, menu, addedTournPId);
        } else {
            needReorg = false;
            let menuTitle = null;
            let subMenuList = [];
            let showMenu = false;
            if (menu.sectionEnabled.value !== '1') {
                return null;
            } else if (menu.key === window.sitecoreVars.MENU_AUTO_NEXT_DRAW) {
                let draws = marksixContext.gqlVars.data?.lotteryDraws || [];
                let nextDraw = draws?.length > 1 ? draws[1] : null;
                if (!nextDraw || marksixContext.isMergeDraw || !nextDraw?.snowballCode) {
                    showMenu = true;
                    menuTitle = (
                        <Dropdown.Item key={`menu-${menu.key}`} className="dropdown-section">
                            {menu.sectionTitle.value}
                            <LeftMenuIcon menu={menu} />
                        </Dropdown.Item>
                    );
                }
            } else if (menu.key === window.sitecoreVars.MENU_AUTO_SNOWBALL_DRAW) {
                let draws = marksixContext.gqlVars.data?.lotteryDraws || [];
                let hasSbDraw = draws?.length > 2;
                if ( hasSbDraw ) {
                    showMenu = true;
                    menuTitle = (
                        <Dropdown.Item key={`menu-${menu.key}`} className="dropdown-section">
                            {menu.sectionTitle.value}
                            <LeftMenuIcon menu={menu} />
                        </Dropdown.Item>
                    );
                }
            } else {
                menuTitle = (
                    <Dropdown.Item key={`menu-${menu.key}`} className="dropdown-section">
                        {menu.sectionTitle.value}
                        <LeftMenuIcon menu={menu}
                        />
                    </Dropdown.Item>
                );
                showMenu = true;
            }
            if (showMenu) {
                menu.children?.forEach((cMenu) => {
                    setMenu(subMenuList, cMenu, addedTournPId);
                });
            }
            menuList = [...menuList, menuTitle, ...reorginizeMenu(subMenuList)];
        }
    });
    if (needReorg) menuList = reorginizeMenu(menuList);
    return menuList;
};