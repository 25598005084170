import React, { useEffect, useState, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../Common/GlobalFunc';
import AddSlipBtn from './AddSlipBtn';
import RCOddsHeaderM from './RCOddsHeaderM';
import RaceInfoDialog from './RaceInfoDialog';
import { HorseName, RaceNote, getRunnerData } from './RCOddsTableBase';
import { SetBetSelect, IsBetSelected } from './RacingBetline';
import { useWindowSize } from '../../Common/CommonHooks';
import { isLocalMeeting } from '../../Common/home-common';
import { isPoolDefined } from './Common';
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import { RacingContext } from '../../Home/RacingPage';
import { useQuickBet } from './QuickBetHook'
import CheckBox from '../../Common/ui-components/CheckBox';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';
import { isStartSellpmPool } from './Common';

const OddsDropdownMenu = ({ betTypeList, selectedBetType, selectedSubBetType, raceNo, handleOnchange, handleOnSubItemChange }) => {
    const { t } = useTranslation();
    const context = useContext(RacingContext);
    const mtg = context.content.meeting;

    let menuList = betTypeList;
    let selectedLegCount = context.content.alupData.length;

    let subItemList = menuList.filter(x => x.type == selectedBetType)[0];

    let menuItem = menuList.map(function (item, index) {
        let itemName = item.type;
        return item.show && isPoolDefined(item.type, raceNo, mtg.poolInvs)
         && <Dropdown.Item eventKey={itemName + index} onClick={() => handleOnchange(itemName, item.maxLeg)} disabled={selectedLegCount>item.maxLeg} value={itemName} key={`${itemName}`} >
            {t('LB_' + itemName + "_S")}
        </Dropdown.Item>;
    });

    let menuSize = menuList.filter(item=> item.show).length;
    let subItemName = selectedSubBetType == null ? subItemList.subType[0] : selectedSubBetType;
    return (menuSize > 1 || subItemList?.showSubMenu) && <div className="rc-odds-table-compact-race-allup">
        {
            (menuSize > 1) && <>
                <DropdownButton
                    as={ButtonGroup}
                    key={`slAllup`}
                    id={`slAllup_${raceNo}`}
                    variant={selectedBetType}
                    title={<><div className='slAllup'>{t('LB_' + selectedBetType + "_S")}</div><ArrowIcon direction='down' size={24}/></>}
                    align="start"
                    className={`${selectedBetType}-ddbtn slAllup-dd`}
                >
                    <Dropdown.Item eventKey="slAllup-header" value={t('LB_RACE_INFO_BLOCK_POOL')} key="slAllup-header" >{t('LB_RACE_INFO_BLOCK_POOL')}</Dropdown.Item>
                    {menuItem}

                </DropdownButton>
            </>
        }
        {
            (subItemList.showSubMenu) && <>
                <DropdownButton
                    as={ButtonGroup}
                    key={`slAllupSubItem`}
                    id={`slAllupSubItem_${raceNo}`}
                    variant={subItemName}
                    title={<><div className='slAllup'>{t('LB_' + subItemName + "_S")}</div><ArrowIcon direction='down' size={24}/></>}
                    align="start"
                    className={`${subItemName}-ddbtn slAllupSubItem`}
                >
                    <Dropdown.Item eventKey="slAllupsub-header" value={t(' LB_RACE_FCT_METHOD')} key="slAllupsub-header" >{t('LB_RACE_FCT_METHOD')}</Dropdown.Item>
                    {
                        subItemList.subType.map(function (item, index) {

                            return <Dropdown.Item onClick={() => handleOnSubItemChange(item)} eventKey={item + index} value={item} key={`${item}`} >
                                {t('LB_' + item + "_S")}
                            </Dropdown.Item>;
                        })
                    }

                </DropdownButton>
            </>
        }
    </div>
}

const RCOddsTableCompact = (props) => {
    const { t, i18n } = useTranslation();
    const { isMobileSize } = useWindowSize();
    const context = useContext(RacingContext);
    const mtg = context.content.meeting;

    const [error, setError] = useState(false);
    const [scnUpdate, setScreenUpdate] = useState({});
    const { handleClickCombOdds } = useQuickBet()
    const [collaspse, setCollaspse] = useState(true);

    const handleClickOdds = (_type, _race, _col, _no, e) => {
        let isChecked = e.target.checked;
        SetBetSelect(_type, _race, _col, _no, isChecked);

        if (props.oddsCheckboxClickCallback != null) {
            props.oddsCheckboxClickCallback(_type, _race, _col, _no, isChecked);
        }
        else {
            if (_col == 'leg' && isChecked) {
                SetBetSelect(_type, _race, 'b1', _no, false);
            }
            else if (_col == 'b1' && isChecked) {
                SetBetSelect(_type, _race, 'leg', _no, false);
            }
        }
        setScreenUpdate({ ...scnUpdate });

        context.racingFuncs.resetAllUpFormula();
        context.racingFuncs.resetInvCal();
    }

    function RaceHeader() {
        let ra = mtg.races.filter((data) => data.no == props.raceNo)[0];
        let postDateArr = formatDateTime(ra.postTime);
        let isLocal = isLocalMeeting(context.content.venue);
        return <div className="rc-odds-table-compact-race-header" id={`race_table_compact_header_${props.raceNo}`}>
            <div>{context.content.page=="DT" ? t('LB_RC_DT_'+props.legIdx) : ''}{t('LB_RC_SEL').replace('{0}', props.raceNo)}</div>
            <div className="separator"></div>
            <div>{isLocal ? t('LB_' + context.content.venue) : context.content.venue}, {postDateArr[0]} {postDateArr[1]}</div>
        </div>;
    }


    function WINRaceHeader() {

        return <div className="rc-odds-table-compact-race-header toggle">
            <div className="WINOddsTable">
                <label className="font-size-15">
                    {t('LB_RACE_WIN')}
                </label>
            </div>
            <div className="WINOddsTable-m">
                <a data-bs-toggle="collapse" href={`#rc-odds-table-compact-${props.raceNo}`} role="button" aria-expanded="false" aria-controls="qTTOddsTableCollapse" onClick={() => handleClickCollapse()}>
                    <label className="font-color-black font-size-15">
                        {t('LB_RACE_WIN')}
                    </label>
                    <div className={collaspse ? 'collaspse' : 'expend'}>
                        <ArrowIcon className='hueRotate' direction='down' change={collaspse} changeDirection='up' size={20} />
                    </div>
                </a>
            </div>
            <div className="collapse with-100 qTTOddsTableCollapse" id="qTTOddsTableCollapse">
            </div>

        </div>;
    }

    function RaceSubHeader() {
        let raceObj = mtg.races.filter((data) => data.no == props.raceNo)[0];
        let rcClass = '';
        let desc = [];
        if ( raceObj['raceClass_'+i18n.language]!=null && raceObj['raceClass_'+i18n.language]!='' )
            rcClass = raceObj['raceClass_'+i18n.language];
        if ( raceObj.raceTrack['description_'+i18n.language]!=null && raceObj.raceTrack['description_'+i18n.language]!='' )
            desc.push(raceObj.raceTrack['description_'+i18n.language]);
        if ( raceObj.raceCourse['displayCode'] != null && raceObj.raceCourse['displayCode'] != '' )
            desc.push(t('LB_RACE_RACE_COURSE').replace('{0}', raceObj.raceCourse['displayCode']));
        if ( raceObj.distance!=null && raceObj.distance!='' )
            desc.push(raceObj.distance + t('LB_METER'));
        if ( raceObj['go_'+i18n.language]!=null && raceObj['go_'+i18n.language]!='' )
            desc.push(raceObj['go_'+i18n.language]);

        return <div className="rc-odds-table-compact-race-subheader" id={`race_table_compact_subheader_${props.raceNo}`}>
            <div>{rcClass}&nbsp;</div>
            <div>{desc.join(', ')}</div>
        </div>;
    }

    function RaceStaticHeader() {
        return <div className="cwin-table-container win-header">
            <header>{t(props.raceStaticHeader)}</header>
        </div>;
    }


    function CWAMeetingRow({ no, label, starters, odds, oddsCss, isStartSell }) {
        return <div className="rc-odds-row-m">
            <div className="no" id={`hr_CWAALUP_comb_${props.raceNo}_${no}`}>{no}</div>
            <div className="rc-checkbox"  ><CheckBox id={`cwinleg_CWA_${props.raceNo}_${no}`}  disabled={!isStartSell} onChange={(e) => handleClickOdds(props.selectedBetType, props.raceNo, 'leg', no, e)} defaultChecked={IsBetSelected(props.selectedBetType, props.raceNo, 'leg', no)} /></div>
            <div style={{ width: "80px" }}>{label}</div>
            <div id={`hr_CWAALUP_horses_${props.raceNo}_${no}`}>{starters}</div>
            <div className="rc-odds-m">
                <div className={oddsCss} id={`odds_CWAALUP_${props.raceNo}_${no}`}><a onClick={() => !isMobileSize && isStartSell && handleClickCombOdds({combString:no}, 'CWA', props.raceNo)}>{odds}</a></div>
            </div>
        </div>
    }

    const handleClickCollapse = e => {
        setCollaspse(!collaspse);
    }

    const meetingRowProps = {...props,handleClickOdds, handleClickCombOdds }
    //const pDef = isPoolDefined(props.selectedBetType, props.firstRace, mtg.poolInvs);

    if (error) {
        return <div>Error: {error.message}</div>;
    // } else if (!pDef) {
    //     return <></>
    } else {
        return (<div className='rc-odds-table-main' id={`rc-odds-table-main_${props.raceNo}`}>
            {props.showRaceHeader ? <RaceHeader /> : null}
            {props.raceStaticHeader ? <RaceStaticHeader /> : null}

            {props.showRaceHeader ? <RaceSubHeader /> : null}
            {props.showOddsDropdownMenu ? <OddsDropdownMenu {...props} handleOnchange={props.handleDropdownMenuChange} handleOnSubItemChange={props.handleDropdownSubMenuChange} /> : null}

            {props.selectedBetType === 'CWA' && props.showCWATable && <CWAMeetingTable {...meetingRowProps} CWAMeetingRow={CWAMeetingRow}/>}
            {props.selectedBetType === 'CWA' && props.showCWATable && <WINRaceHeader />}
            <div className={`rc-odds-table-compact pr ${!props.showOddsDropdownMenu ? 'isTop' : ''} show`} id={`rc-odds-table-compact-${props.raceNo}`}>
                <MeetingRow {...meetingRowProps}/>
                {props.showAddSlip ? <div className="rc-odds-row-compact betSlip">
                    <div><AddSlipBtn /></div>
                </div> : null}
            </div>
        </div>)
    }
}
export default RCOddsTableCompact;

const MeetingRow = (props) => {
    const {handleClickOdds, handleClickCombOdds } = props
    const { t, i18n } = useTranslation();
    const { isMobileSize } = useWindowSize();
    const context = useContext(RacingContext);
    const mtg = context.content.meeting;

    let rows = [];
    let selectedBetType = props.selectedBetType;

    let firstRace = props.firstRace;
    let raceNo = props.raceNo;

    let isLocal = isLocalMeeting(context.content.venue);
    let cnt = props.startCnt != null ? props.startCnt : 0;

    let race = mtg.races.filter((data) => data.no == raceNo)[0];
    let runners = race.runners;
    let rLen = runners.filter((data) => data.no || data.no === 0 ).length;
    let defTableSize = props.defTableSize != null ? Math.max(props.defTableSize, rLen) : rLen;

    let preSuffix = context.content.isPresales ? 'Pre' : '';
    let firstRacePool = mtg.poolInvs.filter((data) => data.leg.races[0] == firstRace && data.oddsType==selectedBetType)[0];
    let winPool = mtg.poolInvs.filter((data) => data.leg.races[0] == raceNo && data.oddsType=="WIN")[0];
    let plaPool = mtg.poolInvs.filter((data) => data.leg.races[0] == raceNo && data.oddsType=="PLA")[0];

    let poolDisable = !isStartSellpmPool(firstRacePool); //firstRacePool?.status != "START_SELL";
    switch (selectedBetType) {
        case "QQP":
            let qinPool = mtg.poolInvs.filter((data) => data.leg.races[0] == firstRace && data.oddsType=="QIN")[0];
            let qplPool = mtg.poolInvs.filter((data) => data.leg.races[0] == firstRace && data.oddsType=="QPL")[0];
            let qinDisable =  !isStartSellpmPool(qinPool);//qinPool?.status != "START_SELL";
            let qplDisable = !isStartSellpmPool(qplPool);//qplPool?.status != "START_SELL";
            poolDisable = qinDisable || qplDisable;
            break;
        case "W-P":
            let wPool = mtg.poolInvs.filter((data) => data.leg.races[0] == firstRace && data.oddsType=="WIN")[0];
            let pPool = mtg.poolInvs.filter((data) => data.leg.races[0] == firstRace && data.oddsType=="PLA")[0];
            let wDisable =  !isStartSellpmPool(wPool);//wPool?.status != "START_SELL";
            let pDisable =  !isStartSellpmPool(pPool);//pPool?.status != "START_SELL";
            poolDisable = wDisable || pDisable;
            break;
    }
    let winDisable = props.disableQuickBet || isMobileSize || winPool?.status != "START_SELL" || winPool?.sellStatus != "START_SELL" ;
    let plaDisable = props.disableQuickBet || isMobileSize || plaPool?.status != "START_SELL" || plaPool?.sellStatus != "START_SELL" ;
    for (let i = 0; i < runners.length; i++) {
        if (runners[i].no == null || runners[i].no=="") {
            continue;
        }

        let runner =  getRunnerData(props.columns, context, t, isLocal, mtg, runners[i], raceNo, mtg.pmPools, i18n.language);

        let rcInfo = {
            betType: context.content.page,
            no: runner.no,
            horName: runner.name,
            color: runner.isReserve ? '-' : runner.racingColorFileName,
            horseId: runner.horseId,
            jockyId: runner?.jockyId,
            jockyName: !runner.isScratch ? runner.jocky : '-',
            trnId: runner?.trnId,
            trnName: !runner.isScratch ? runner.trn : '-',
            draw: runner.draw,
            handicapWT: runner.wt,
            bodyWT: isLocal ? runner.bodyWT : '-',
            last6: runner.last6,
            rating: runner.rtg,
            intRating: runner.intRtg,
            age: runner.age,
            sex: runner.sex,
            bestTime: runner.bestTime,
            gear: runner.gear,
            wtAgeAllowance: runner.wtAgeAllowance,
            tCard: runner.tp.trumpCard,
            pRun: runner.tp.priority,
            OrderOfTrnPre: runner.tp.pref,
            isScratch: runner.isScratch,
            isReserved: runner.isReserve,
            alw: runner.alw,
            isLocal: isLocal,
            rcNote: props.columns.rcNoteM,
            showIntRating: race.ratingType==="I"
        }

        let enableWinQuickBet = !runner.selDisable && !winDisable && runner.winOdds.odds!="";
        let enablePlaQuickBet = !runner.selDisable && !plaDisable && runner.plaOdds.odds!="";
        let row = <div className={`rc-odds-row-m ${cnt % 2 == 0 ? 'oddbg' : 'evenbg'} ${runner.isScratch ? 'rc-row-scratch': ''}`} key={`rc-odds-row-m-${cnt}`}>
            {props.columns.no ? <div className="no" id={`runnerNo_${props.raceNo}_${runner.no}`}>{runner.no}{runner.resTxt}</div> : null}
            {props.columns.banker1 ? <div className="rc-checkbox">
                <CheckBox  id={`wpbank1_${selectedBetType}_${raceNo}_${runner.no}`} disabled={runner.selDisable || poolDisable || IsBetSelected(selectedBetType, raceNo, 'b1', "F") ? 'disabled' : ''}
                    onChange={(e) => handleClickOdds(selectedBetType, raceNo, 'b1', runner.no, e)} defaultChecked={IsBetSelected(selectedBetType, raceNo, 'b1', runner.no)} />
            </div> : null
            }
            {props.columns.banker2 ? <div className="rc-checkbox">
                <CheckBox  id={`wpbank2_${selectedBetType}_${raceNo}_${runner.no}`} disabled={runner.selDisable || poolDisable || IsBetSelected(selectedBetType, raceNo, 'b2', "F") ? 'disabled' : ''}
                    onChange={(e) => handleClickOdds(selectedBetType, raceNo, 'b2', runner.no, e)} defaultChecked={IsBetSelected(selectedBetType, raceNo, 'b2', runner.no)} />
            </div> : null
            }
            {props.columns.banker3 ? <div className="rc-checkbox">
                <CheckBox  id={`wpbank3_${selectedBetType}_${raceNo}_${runner.no}`} disabled={runner.selDisable || poolDisable || IsBetSelected(selectedBetType, raceNo, 'b3', "F") ? 'disabled' : ''}
                    onChange={(e) => handleClickOdds(selectedBetType, raceNo, 'b3', runner.no, e)} defaultChecked={IsBetSelected(selectedBetType, raceNo, 'b3', runner.no)} />
            </div> : null
            }
            {props.columns.leg ? <div className="rc-checkbox">
                <CheckBox  id={`wpleg_${selectedBetType}_${raceNo}_${runner.no}`} disabled={runner.selDisable || poolDisable || IsBetSelected(selectedBetType, raceNo, 'leg', "F") ? 'disabled' : ''}
                    onChange={(e) => handleClickOdds(selectedBetType, raceNo, 'leg', runner.no, e)} defaultChecked={IsBetSelected(selectedBetType, raceNo, 'leg', runner.no)} />
            </div> : null
            }
            {props.columns.horseName ? <HorseName t={t} isLocal={isLocal} name={runner.name} raceNo={props.raceNo} runnerNo={runner.no} racingColorFileName={runner.racingColorFileName} /> : null}
            {props.columns.rcNoteM && isLocal ? <RaceNote isLocal={isLocal} horseId={runner.horseId} /> : null}
            {
                props.columns.win ?
                    <div className="rc-checkbox rc-odds-m">
                        <div id={`odds_WIN_${raceNo}_${runner.no}`} clickable={`${enableWinQuickBet}`} className={`${runner.winOdds.className} win ${enableWinQuickBet? 'cp' : ''}`} onClick={()=>(enableWinQuickBet) && handleClickCombOdds(runner, 'WIN',props.raceNo)}>
                            {!enableWinQuickBet ? <span>{runner.winOdds.odds}</span> : <a>{runner.winOdds.odds}</a>}
                        </div>
                    </div> : null
            }
            {
                props.columns.place ?
                    <div className="rc-checkbox rc-odds-m">
                        <div id={`odds_PLA_${raceNo}_${runner.no}`} clickable={`${enablePlaQuickBet}`} className={`${runner.plaOdds.className} pla ${enablePlaQuickBet? 'cp' : ''}`} onClick={()=>(enablePlaQuickBet) && handleClickCombOdds(runner, 'PLA', props.raceNo)}>
                            {!enablePlaQuickBet ? <span>{runner.plaOdds.odds}</span> : <a>{runner.plaOdds.odds}</a>}
                        </div>
                    </div> : null
            }
            <div className="exp-arrow">
                <RaceInfoDialog info={rcInfo} />
            </div>
        </div>;
        rows.push(row);
        cnt++;
    }

    for (let i = rLen; i < defTableSize; i++) {
        let row = <div className={`rc-odds-row-m ${cnt % 2 == 0 ? 'oddbg' : 'evenbg'}`} key={`rc-odds-row-m-${cnt}`}>
            {props.columns.no ? <div className="no"></div> : null}
            {props.columns.banker1 ? <div className="rc-checkbox rc-odds-m"></div> : null}
            {props.columns.banker2 ? <div className="rc-checkbox rc-odds-m"></div> : null}
            {props.columns.banker3 ? <div className="rc-checkbox rc-odds-m"></div> : null}
            {props.columns.leg ? <div className="rc-checkbox rc-odds-m"></div> : null}
            {props.columns.horseName ? <div className="horseName"></div> : null}
            {props.columns.rcNoteM && isLocal ? <div className="RCNOTE"></div> : null}
            {props.columns.win ? <div className="rc-checkbox rc-odds-m"></div> : null}
            {props.columns.place ? <div className="rc-checkbox rc-odds-m"></div> : null}
            <div className="exp-arrow">
            </div>
        </div>;
        rows.push(row);
        cnt++;
    }


    if (props.columns.field) {
        let full = <div className="rc-odds-row-m full">
            <div style={{ textAlign: "center" }}>F</div>
            {props.columns.banker1 ? <div className="rc-checkbox">
                {props.columns.banker1F ? <div className="rc-checkbox">
                    <CheckBox  id={`wpbank1_${selectedBetType}_${raceNo}_F`} disabled={poolDisable} onClick={(e) => handleClickOdds(selectedBetType, raceNo, 'b1', 'F', e)} defaultChecked={IsBetSelected(selectedBetType, raceNo, 'b1', "F")} />
                </div> : null}
            </div> : null}
            {props.columns.banker2 ? <div className="rc-checkbox">
                {props.columns.banker2F ? <div className="rc-checkbox">
                    <CheckBox  id={`wpbank2_${selectedBetType}_${raceNo}_F`} disabled={poolDisable} onClick={(e) => handleClickOdds(selectedBetType, raceNo, 'b2', 'F', e)} defaultChecked={IsBetSelected(selectedBetType, raceNo, 'b2', "F")} />
                </div> : null}
            </div> : null}
            {props.columns.banker3 ? <div className="rc-checkbox">
                {props.columns.banker3F ? <div className="rc-checkbox">
                    <CheckBox  id={`wpbank3_${selectedBetType}_${raceNo}_F`} disabled={poolDisable} onClick={(e) => handleClickOdds(selectedBetType, raceNo, 'b3', 'F', e)} defaultChecked={IsBetSelected(selectedBetType, raceNo, 'b3', "F")} />
                </div> : null}
            </div> : null}
            {props.columns.leg ? <div className="rc-checkbox">
                <div className="rc-checkbox">
                    <CheckBox  id={`wpleg_${selectedBetType}_${raceNo}_F`} disabled={poolDisable} onClick={(e) => handleClickOdds(selectedBetType, raceNo, 'leg', 'F', e)} defaultChecked={IsBetSelected(selectedBetType, raceNo, 'leg', "F")} />
                </div>
            </div> : null}
            {props.columns.horseName ? <div className="horseName">{t('LB_RC_POOL_FULL')}</div> : null}
            {props.columns.rcNoteM && isLocal ? <div className="RCNOTE"></div> : null}
            {props.columns.win ? <div className="rc-checkbox rc-odds-m"></div> : null}
            {props.columns.place ? <div className="rc-checkbox rc-odds-m"></div> : null}
            <div></div>
        </div>;
        rows.push(full);
    }


    let output = [];
    output.push(<RCOddsHeaderM columns={props.columns} lbl={props.lbl} raceStaticHeader={props.raceStaticHeader} />);
    output = output.concat(rows);
    return output;
}


function CWAMeetingTable(props) {

    const {CWAMeetingRow } = props
    const { t, i18n } = useTranslation();
    const context = useContext(RacingContext);
    const mtg = context.content.meeting;

    let cwaPool = mtg.pmPools.filter((data) => data.leg.races[0] == props.raceNo && data.oddsType=="CWA")[0];
    const isStartSell =  isStartSellpmPool(cwaPool);//cwaPool?.status === 'START_SELL'
    let rows = [];
    if (cwaPool != null) {
        cwaPool.cWinSelections.sort((x, y) => x.composite < y.composite ? -1 : 1);
        let runners = mtg.races.filter(x=>x.no==props.raceNo)[0].runners;
        rows = cwaPool.cWinSelections.map(function (cwinDtl, index) {
            let item = cwaPool.oddsNodes.filter(data=> cwinDtl.composite==data.combString)[0];
            let starts = cwinDtl.starters.map(ele=>{
                let runner =  runners.filter(x=>parseInt(x.no)==ele)[0];
                let st = ''
                if(runner.status.toUpperCase().indexOf('SCRATCHED')>=0) st += `(${t('LB_RC_SCR_S')})`;
                if(['SCRATCHEDRESERVED', 'RESERVED', 'STANDBY'].includes(runner.status.toUpperCase())) st += `(${t('LB_RC_RESERVE')})`;
                return `${ele}${st}`;
            }).join(', ');
            let label = cwinDtl['name_' + i18n.language];
            let oddsCss = "";
            if (item?.hotFavourite) {
                oddsCss = 'favourite';
            } else {
                const isPresales = context?.content?.isPresales
                oddsCss = isPresales ? '' : item?.oddsDropValue >= 50 ? 'od-50' : item?.oddsDropValue >= 20 ? 'od-20' : '';
            }

            return <CWAMeetingRow no={cwinDtl.composite} odds={item?.oddsValue} starters={starts} label={label} oddsCss={oddsCss} isStartSell={isStartSell}/>
        });
    }

    return <div className="rc-odds-table-compact CWA" id={`cwin_table_${props.raceNo}`}>
        <div className="rc-odds-table-header-m">
            <div style={{ position: "absolute", paddingLeft: "8px" }}>{t('LB_COMPOSITE_SEL')}</div>
            <div className="rc-checkbox"></div>
            <div></div>
            <div>{t('LB_COMPOSITE_STARTERS_INCLUDED')}</div>
            <div>{t('LB_ODDS')}</div>
        </div>
        {rows}
        {/* <CWAMeetingRow no="A1" odds="3.2" starters="3, 5, 7, 8, 9" label={t('LB_RC_OVERNIGHT_FAVOURITES')} /> */}
    </div>
}