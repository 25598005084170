import React, { useState, useEffect } from 'react';
import { useWindowSize } from '../../CommonHooks'
import './checkbox.scss';

/**
 * @param {string} product football racing marksix
 */

const productBackground = {
    racing: '#173e96',
    football: '#00c06c',
    marksix: '#d62d2d',
}
const CheckBox = ({
    onChange,
    onClick,
    value = '',
    defaultChecked,
    checked,
    disabled = false,
    className = '',
    classNameBox = '',
    product = 'racing',
    size,
    id,
    showInput = true,
    noBorder = false,
    background,
    reverse,
    arrNumStayOne = 0,
    control = false,
}) => {
    const { isMobileSize } = useWindowSize();
    if(size === undefined) {
        size = 20
    }
    const [_checked, setChecked] = useState(false);
    // useEffect(() => {
    //     if (_checked === null) {
    //         setChecked(Boolean(checked ?? defaultChecked));
    //     } else {
    //         setChecked(checked);
    //     }
    // }, [checked]);
    useEffect(() => {
        // if (_checked === null) {
        //     setChecked(Boolean(checked ?? defaultChecked));
        // } else {
        //     setChecked(checked);
        // }
        if(disabled) {
            setChecked(false);
        } else {
            setChecked(Boolean(checked ?? defaultChecked));
        }
    }, [checked, defaultChecked, disabled]);
    const inputId = id ? { id } : {};
    const _onChange = (e) => {
        if((arrNumStayOne == 1 && e.target.checked == false) || control){
        }else{
            setChecked(e.target.checked);
        }
        onChange && onChange(e);
    };
    const _onClick = (e) => {
        if((arrNumStayOne == 1 && e.target.checked == false) || control){
        }else{
            setChecked(e.target.checked);
        }
        onClick && onClick(e);
    };
    const style = { '--size': size + 'px' };
    if(background) {
        style['--background'] = background
    } else if(reverse) {
        style['--background'] = '#fff'
    } else {
        style['--background'] = productBackground[product] ?? '#173e96'
    }

    return (
        <div
            style={style}
            className={` checkbox-container size-${size} ${product ? `checkbox-${product}` : ''} ${disabled ? 'checkbox-disabled' : ''} ${className}`}
        >
            {showInput && (
                <input
                    {...inputId}
                    type="checkbox"
                    // defaultChecked={_checked}
                    checked={_checked}
                    disabled={disabled}
                    value={value}
                    onChange={_onChange}
                    onClick={_onClick}
                />
            )}
            <span
                className={`${classNameBox} ${reverse? 'reverse': ''}`}
                style={{ border: noBorder || _checked ? '0' : '1px solid #999999' }}
            ></span>
        </div>
    );
};

export default CheckBox;