import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { RacingContext } from '../../Home/RacingPage';

const JackpotRemarks = ({ betType }) => {

    const { t, i18n } = useTranslation();
    const context = useContext(RacingContext);
    const mtg = context.content.meeting;
    const poolInv =  mtg.jpEsts.filter((data) => data.leg.races[0] == context.content.raceNo && data.oddsType==betType)[0];

    const hasJpt = () =>{
        return poolInv!=null && poolInv.jackpot!=null && poolInv.jackpot!="";
    }

    return <>
        {
            hasJpt() && <div className="jpt-remarks">
                {t('LB_RC_ODDS_JPT_REMARKS')}
            </div>
        }
    </>
}

export default JackpotRemarks