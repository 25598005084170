import React, { useEffect, useState, useRef } from 'react';
import { textFormatAmountFn } from '../../GlobalFunc';
import { useWindowSize } from '../../CommonHooks';
import './index.scss';

const AmountInput = (props) => {
    const {
        onChange,
        value,
        disabled,
        className = '',
        maxLength = 10,
        min = 0,
        max = 99999999,
        maxErrMsg,
        minErrMsg,
        decimals = 0
    } = props;
    const [amountCache, setAmountCache] = useState('-');
    const oldVlaueRef = useRef('-');
    const { isMobileSize } = useWindowSize();

    useEffect(() => {
        if (amountCache && amountCache != '-') {
            onChange(amountCache);
        }
    }, [amountCache]);

    useEffect(() => {
        if (value) {
            oldVlaueRef.current = value;
        }
    }, []);

    useEffect(() => {
        if (value) {
            setAmountCache(value);
        } else if (value === '') {
            setAmountCache('-');
        }
    }, [value]);

    const onInputAmount = (e) => {
        let value = e.target.value;
        if (isMobileSize && e.target.getAttribute('data-init') == 1) {
            if (e.nativeEvent.data != null) {
                value = e.nativeEvent.data;
            }
            e.target.setAttribute('data-init', 0);
        }
        if (/\D/g.test(value)) {
            value = value.replace(/\D/g, '');
        }
        if (value <= 0) {
            setAmountCache('');
        } else {
            setAmountCache(value);
        }
    };

    const [isFocus, setIsFocus] = useState(false);
    const onFocusAmount = (e) => {
        if (isMobileSize) {
            e.preventDefault();
            e.target.setAttribute('data-init', 1);
            e.target.setSelectionRange(10, 10);
            setTimeout(function () {
                e.target.setSelectionRange(10, 10);
            });
        }
        setIsFocus(true);
        if (e.target.value === '-') {
            setAmountCache('');
        }
    };

    const onBlurAmount = (e) => {
        setIsFocus(false);
        let isErr = false;
        if (amountCache === '') {
            isErr = true;
        } else if (parseInt(amountCache, 10) < min) {
            isErr = true;
            minErrMsg && alert(minErrMsg);
        } else if (parseInt(amountCache, 10) > max) {
            isErr = true;
            maxErrMsg && alert(maxErrMsg);
        }
        if (isErr) {
            setAmountCache(oldVlaueRef.current);
        } else {
            oldVlaueRef.current = amountCache;
        }
    };
    const showValue = amountCache == '' ? '' : textFormatAmountFn(amountCache, decimals, false);
    // console.log(isFocus, amountCache, isFocus || amountCache === '-' || amountCache === '', showValue )
    return (
        <div className={`HKJCAmountInputContainer ${className}`}>
            <span>$</span>
            <input
                autoComplete="off"
                maxLength={maxLength}
                type="text"
                inputMode="numeric"
                disabled={disabled}
                onInput={onInputAmount}
                onFocus={onFocusAmount}
                onBlur={onBlurAmount}
                className="AmountInput"
                value={showValue}
            />
        </div>
    );
};

export default AmountInput;
