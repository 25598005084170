import { getOddsValue } from "./common";

export const parseTopNOdds = (pmPool, message) => {
    if(message != null) {
        if(message.updAt != null && message.cmb !=null){
            pmPool.lastUpdateTime = message.updAt;
            let newCombs = [];
            message.cmb.forEach(comb => {
                let targetComb = {
                    combString: comb.cmbStr,
                    oddsValue: getOddsValue(comb.odds, comb.cmbSt),
                    hotFavourite: false,
                    oddsDropValue: 0,
                    bankerOdds: []
                }
                newCombs.push(targetComb);
            });
            pmPool.oddsNodes = newCombs;
        }
    }
}