import React,{ useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {RacingContext} from '../../Home/RacingPage'

const RadioButtonSet = (props) => {
    const { t, i18n } = useTranslation();
    const { contentRef, racingFuncs } = useContext(RacingContext)
    useEffect(()=>{

        const selectedItem = props?.items?.find((item)=> item.checked)
        if(props.items){
            contentRef.current.subMethod = selectedItem;
            racingFuncs.setContent({...contentRef.current})
        }
    },[props.items])

    return <div className="radio-button-set">
        {props.title!=null ? <div className="method-title">{props.title}</div> : null}
        {props.items.map((_cItem, _cIndex) => {
            return <div onClick={()=> {!_cItem.disabled && props.handleRadioClick(_cItem.type)}} className={`radio-button-set-item radio-button-set-method-${_cItem.type} ${_cItem.checked?'radio-button-set-item-checked':''} ${_cItem.disabled?'radio-button-set-item-disabled':''}`}>
                <label className="radio-container">
                    <span>{t(_cItem.name1)}</span>
                    {_cItem.name2!=null ? <span>{t(_cItem.name2)}</span> : null}
                    <input id={`subType${_cItem.type}`} type="radio" defaultChecked={_cItem.checked} checked={_cItem.checked} value={_cItem.type} disabled={_cItem.disabled} name="betTypeRadio" /* onClick={(e)=> {props.handleRadioClick(e)}} */ />
                    <span className="radio-checkmark"></span>
                </label>
            </div>;
        })}
    </div>;
}

export default RadioButtonSet