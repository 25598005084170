import React,{ useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import {getDayOfWeek} from '../../Common/home-common';
import { formatDateTime, scrollTo } from '../../Common/GlobalFunc';
import printImg from '../../../info/Include/images/icon-print-white.svg';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';

const ResultDetail = ({ draw, handleClickBack}) => {
    const { t } = useTranslation()
    const ballList = draw?.drawResult?.drawnNo || []

    useEffect(()=> {
        scrollTo(0,0)
    },[])

    const prizeRender = (prize) => {
        const isZeroReg = /^0*(\.)?(0*)?$/;
        return !isZeroReg.test(prize) ? `$${parseInt(prize).toLocaleString()}` : '-';
    }

    const handleClickPrint = ()=> {
        window.print()
    }

    const TableL = () => {
      const prizeRows = []
      for(let i=1 ; i<=3; i++){
        const prizeLabel = t(`LB_M6_SINGLE_${i}_M`);
        let prize = draw.lotteryPool.lotteryPrizes.filter(x=> x.type==i)[0];
        const winningUnit = prize?.winningUnit > 0 ? (prize.winningUnit / draw.lotteryPool.unitBet).toLocaleString('en-US', { minimumFractionDigits: 1 })  : '-'
        let prizeCss = i===1 ? 'result-1st-prize' : '';
        prizeRows.push(
            <div className='table-row' key={i}>
                <div className="prize-label">{prizeLabel}</div>
                <div className={prizeRender(prize?.dividend) !== '-' ? prizeCss : 'result-table-value-black'}>{prizeRender(prize.dividend)}</div>
                <div className={winningUnit !== '-' ? '' : 'result-table-value-black'}>{winningUnit}</div>
            </div>
        )
      }
      prizeRows.push(
        <div className='table-row empty-row' key='empty'>
            <div></div>
            <div></div>
            <div></div>
        </div>
      )
    return <div className='result-detail-l'>
        <div className='table-header'>
          <div >{t('LB_M6_SINGLE_PRIZE')}</div>
          <div>{t('LB_M6_SINGLE_UNIT_PRIZE')}</div>
          <div>{t('LB_M6_SINGLE_WIN_UNIT')}</div>
        </div>
        {prizeRows}
    </div>
    }
    const TableR = () => {
      const prizeRows = []
      for(let i=4; i<=7 ; i++ ){
        let prize = draw.lotteryPool.lotteryPrizes.filter(x=> x.type==i)[0];
        const winningUnit = prize?.winningUnit > 0 ? (prize.winningUnit / draw.lotteryPool.unitBet).toLocaleString('en-US', { minimumFractionDigits: 1 })  : '-'
        prizeRows.push(
            <div className='table-row ' key={i}>
                <div className="prize-label">{t(`LB_M6_SINGLE_${i}_M`)}</div>
                <div>{prizeRender(prize.dividend)}</div>
                <div>{winningUnit}</div>
            </div>
        )
      }

    return <div className='result-detail-r'>
        <div className='table-header'>
          <div>{t('LB_M6_SINGLE_PRIZE')}</div>
          <div>{t('LB_M6_SINGLE_UNIT_PRIZE')}</div>
          <div>{t('LB_M6_SINGLE_WIN_UNIT')}</div>
        </div>
        {prizeRows}
    </div>
    }

    const DrawInfoTable = ({ drawId, drawDt, dow, inv }) => {
        return <div className='draw-info-table'>
            <div className='table-row'>
                <div>{t('LB_M6_LASTDRAW_NUM')}</div>
                <div>{drawId}</div>
            </div>
            <div className='table-row'>
                <div>{t('LB_M6_DRAWDATE')}:</div>
                <div>{drawDt}{dow}</div>
            </div>
            <div className='table-row'>
                <div>{t('LB_M6_TOTAL_TURNOVER')}</div>
                <div>${inv}</div>
            </div>
        </div>
    }

    if ( draw==null )
        return null;
    else {
        const drawId = `${draw.year.substring(2,4)}/${String(draw.no).padStart(3,'0')}`;
        const drawDt = formatDateTime(draw.drawDate.substring(0,10))[0];
        const dow = `(${getDayOfWeek(draw.drawDate, t)})`;
        const inv = parseInt(draw.lotteryPool.totalInvestment).toLocaleString();
        return <div className='marksix-result-detail'>
            <header>
                <div className='ms-result-title'>
                    <ArrowIcon className='hueRotate cursor-pointer' onClick={handleClickBack} direction='left' size={24}/>
                    {t('LB_M6_RESULTSDETAIL')}
                </div>
                <div className='marksix-results-print' onClick={handleClickPrint}><img src={printImg} alt=''/> {t('LB_M6_RESULTS_PRINT')}</div>
            </header>
            <div className='draw-info'>
                <div >{t('LB_M6_LASTDRAW_NUM')}: {drawId}</div>
                <div >{t('LB_M6_DRAWDATE')}: {drawDt} {dow}</div>
                <div >{t('LB_M6_TOTAL_TURNOVER')}: ${inv}</div>
            </div>
            <div className='draw-info-m'>
                <DrawInfoTable drawId={drawId} drawDt={drawDt} dow={dow} inv={inv} />
            </div>
            <div className='draw-result'>
                <div>{t('LB_M6_SINGLE_DRAW_RESULTS')}</div>
                <div className='result-ball-list'>
                    {ballList.map(ball => <div  key={ball}><img className='ballsize-m' src={require(`../../../info/marksix/info/images/marksix-${ball}.svg`)} alt=''/></div>)}
                    <span>+</span>
                    <div  key={'sno'}><img className='ballsize-m' src={require(`../../../info/marksix/info/images/marksix-${draw.drawResult.xDrawnNo}.svg`)} alt=''/></div>
                </div>
            </div>
            <div className='draw-result-table'>
                <TableL />
                <TableR />
            </div>
        </div>
    }
}

export default ResultDetail