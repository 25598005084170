import React from 'react';
import { compressToUTF16, decompressFromUTF16 } from 'lz-string';
import { isLocalMeeting } from '../Common/home-common';
import { GetFootballConfig } from '../Common/ConfigHelper';
import { postEnquirePPSStatusTR_IB } from '../BetSlip/FundsTransfer/FundsTransferIBAPI';
import { formatMsgFn } from '../BetSlip/FundsTransfer/OnlineDeposit/DepositResult';
import { GetError } from '../Common/bs_str_table';
import { HRMultiRaceTemplate } from '../BetSlip/BetLineTemplate';
import { textFormatAmountFn } from './GlobalFunc';
import { isCRSOtherSels } from '../Football/Common/CRSTable';
const CURRENT_SESSION_RECORDS_BETLINES = 'CURRENT_SESSION_RECORDS_BETLINES';

export const saveBetLinesToCurrentSessionRecords = (betLines) => {
    if (!Array.isArray(betLines)) {
        return;
    }
    try {
        let currentSessionBetLines = [...getBetLines(), ...betLines];
        AssembleCurrentSessionRecordsData(currentSessionBetLines);
    } catch (err) {
        console.error('CurrentSessionRecords', err);
    }
};

const AssembleCurrentSessionRecordsData = (data) =>{
    let currentSessionBetLines =data; 
    currentSessionBetLines = currentSessionBetLines.map((item, index) => ({ ...item, No: index + 1 }));
    currentSessionBetLines = JSON.stringify(currentSessionBetLines);
    const compressedBetLines = compressToUTF16(currentSessionBetLines);
    window.localStorage.setItem(CURRENT_SESSION_RECORDS_BETLINES, compressedBetLines);
}

export const findPpsDataToCSRecords = (betLines) =>{
    if(betLines[0].curIsPpsType && betLines[0].resCode !='999'){
        let currentSessionData = getBetLines().filter(item=>{
            return item.referenceNo != betLines[0].referenceNo 
        })
        AssembleCurrentSessionRecordsData(currentSessionData);
    }
}

export const removeCurrentSessionRecords = () => {
    window.localStorage.removeItem(CURRENT_SESSION_RECORDS_BETLINES);
};

export const getBetLines = () => {
    const compressedBetLines = window.localStorage.getItem(CURRENT_SESSION_RECORDS_BETLINES) || '[]';
    let betLines = compressedBetLines == '[]' ? compressedBetLines : decompressFromUTF16(compressedBetLines);
    betLines = JSON.parse(betLines);
    return betLines;
};

export const getCurrentSessionRecords = (t, i18n, configs) => {
    const transactionDetails = (betLine, betType) => {
        const { prod } = betLine;
        if (betLine?.xxx) return betLine.xxx; // Backend interface data
        let data = '';
        if (prod === 'HR') {
            data = transactionDetailsHR(betLine, betType);
        } else if (prod === 'FB') {
            data = transactionDetailsFB(betLine);
        } else if (prod === 'M6') {
            data = transactionDetailsM6(betLine);
        } else if (prod === 'FT') {
            data = transactionDetaulsFT(betLine);
        }
        return data || betLine.betLineStr;
    };

    const transactionDetailsHR = (betLine, betType) => {
        const racingConfig = configs?.racing;
        const betObj = betLine.betObj;
        if (betObj.betType.includes('ALUP')) {
            betType = t('LB_ALLUP_S') + ' ' + betObj?.allupFormula;
        }
        const isMultiRace = [...racingConfig?.MultiRacePage, ...racingConfig?.ALUP_PAGE, 'CWAALLALUP'].includes(
            betObj.betType
        );
        const isAllUp = [...racingConfig?.ALUP_PAGE, 'CWAALLALUP'].includes(betObj.betType);
        const isCWIN = ['CWA', 'CWB', 'CWC'].includes(betObj.betType);
        const hideTopRaceNo = [
            ...racingConfig?.MultiRacePage,
            'JKC',
            'TNC',
            ...racingConfig?.ALUP_PAGE,
            'CWAALLALUP'
        ]?.includes(betObj.betType);
        const isLocalMtg = isLocalMeeting(betObj.venueid);

        const mtgDateTemp = betObj?.id?.split('_')[1];
        const mtgDate = mtgDateTemp
            ? mtgDateTemp.substring(6, 8) + '/' + mtgDateTemp.substring(4, 6) + '/' + mtgDateTemp.substring(0, 4)
            : '';

        const title = ['JKC', 'TNC'].includes(betObj.betType) ? (
            <div>
                <div>
                    {t('LB_' + betObj.betType)} {betObj.currentPool?.instNo}
                </div>
                <div>{t('LB_' + betObj.venueid)}</div>
                <div>{mtgDate}</div>
            </div>
        ) : (
            <div>
                <div>
                    {!isLocalMtg ? t('LB_BS_OVERSEAS') : ''} {t('LB_' + betObj.venueid)} {t(`LB_${betObj.dayid}`)}{' '}
                    {`${betType} ${betObj.subType ? t('LB_STYPE_' + betObj.subType) + ' ' : ''}`}
                    {!hideTopRaceNo ? ` ${t('LB_RC_SEL_S').replace('{0}', betObj.raceno)}` : ''}
                </div>
            </div>
        );
        const content = (
            <div>
                {!isMultiRace && !isCWIN ? (
                    <>
                        {betObj.bankers?.map((subBankers) => {
                            if (subBankers.length > 0) {
                                return [
                                    subBankers?.map((runner, index) => {
                                        return (
                                            <span>
                                                {runner.no} {i18n.language === 'en' ? runner.name_en : runner.name_ch}{' '}
                                                {index === subBankers.length - 1 ? '' : ' + '}
                                            </span>
                                        );
                                    }),
                                    <div>{t('LB_BS_BANKER_WITH')}</div>
                                ];
                            }
                        })}
                        {betObj.combs.map((runner, index) => {
                            if (!runner) return;
                            const connector = betObj.betType == 'IWN' ? ' # ' : ' + ';
                            return (
                                <>
                                    <span>
                                        {runner.no || runner.number}{' '}
                                        {i18n.language === 'en' ? runner.name_en : runner.name_ch}
                                    </span>
                                    {['JKC', 'TNC'].includes(betObj.betType) ? (
                                        <>
                                            @
                                            {betLine.txNo &&
                                                (runner.oldOddsVal && runner.oldOddsVal != runner.currentOdds
                                                    ? `${runner.oldOddsVal} ${t('LB_BS_RESENDBET_CHANGED_TO')} ${
                                                          runner.currentOdds
                                                      }`
                                                    : runner.currentOdds)}
                                            {!betLine.txNo &&
                                                (runner.oldOddsVal ? runner.oldOddsVal : runner.currentOdds)}
                                            <br />
                                        </>
                                    ) : index === betObj.combs.length - 1 ? (
                                        ''
                                    ) : (
                                        connector
                                    )}
                                </>
                            );
                        })}
                    </>
                ) : (
                    <HRMultiRaceTemplate
                        betObj={betObj}
                        isCWIN={isCWIN}
                        isAllUp={isAllUp}
                        isLocalMtg={isLocalMtg}
                        isCurrentSessionRecords={true}
                    />
                )}
                <div className="subText">{betObj.isRandom ? `(${t('LB_BS_RANDOMNO')})` : ''} </div>
            </div>
        );
        return [title, content];
    };

    const transactionDetailsFB = (betLineData) => {
        const betObj = betLineData.betObj;
        const tournamentName = i18n.language === 'en' ? betObj.tournamentNameEN : betObj.tournamentNameCH;
        const matchTeam = i18n.language === 'en' ? betObj.matchTeamEN : betObj.matchTeamCH;
        const isTPSBetType = betLineData?.betObj?.betType === 'TPS';
        const specialsLine = betObj?.specialLine;
        const FBconfig = GetFootballConfig();
        const { TournamentAllupPools } = FBconfig;
        const isAutoAcceptance = betLineData.isAutoAcceptance;
        const counterOfferOddsChange = betLineData?.counterOfferOddsChange;

        const TournAllUpTemp = ({ objData }) => {
            const langU = i18n.language === 'en' ? 'EN' : 'CH';
            const langL = i18n.language === 'en' ? 'en' : 'ch';
            let groupObJ = {};
            let groupArr = [];
            objData.combs.forEach((item) => {
                const objKey = item.raceno;
                if (groupObJ[objKey]) {
                    groupObJ[objKey].push(item);
                } else {
                    groupObJ[objKey] = [item];
                }
            });
            groupArr = Object.keys(groupObJ).sort((a, b) => a[0].raceno - b[0].raceno);
            return (
                <div>
                    <div className="subText subTitle">
                        {objData.frontEndId} | {objData['tournamentName' + langU]}
                    </div>
                    {groupArr.map((key) => {
                        let betArr = groupObJ[key];
                        return betArr.map((item, index) => {
                            return (
                                <div key={index}>
                                    {index === 0 && (
                                        <div className="subText">
                                            {t('LB_FB_TITLE_' + item.betType)} {item?.currentPool.instNo}{' '}
                                            {item?.currentPool['name_' + langL]}
                                        </div>
                                    )}
                                    <div>
                                        {item?.currentCombNameObj['name_' + langL]}@
                                        {!betLineData.txNo && item.oldOddsVal ? item.oldOddsVal : item.oddsVal}
                                    </div>
                                </div>
                            );
                        });
                    })}
                </div>
            );
        };

        const combinationName = (comb) => {
            let selectedComb = comb.id.split('_')[comb.id.split('_').length - 1];
            let condition = !comb.condition || comb.condition === '0.0' ? '' : `[${comb.condition}]`;
            if (['HIL', 'FHL', 'CHL', 'EHL', 'ECH', 'FCH'].includes(comb.betType)) {
                selectedComb = selectedComb === 'H' ? 'HIGH' : 'LOW';
            }
            let combName = t(`LB_BS_ADDTOSLIP_FB_${selectedComb}`);
            if (['TTG', 'ETG'].includes(comb.betType)) {
                combName = selectedComb;
            }
            if (['CRS', 'FCS', 'ECS'].includes(comb.betType)) {
                combName = isCRSOtherSels(selectedComb)
                    ? t(`LB_BS_ADDTOSLIP_FB_${selectedComb}`)
                    : selectedComb
                          .split(':')
                          .map((i) => parseInt(i))
                          .join(':');
            }
            if (['HFT'].includes(comb.betType)) {
                combName = selectedComb
                    .split(':')
                    .map((i) => t(`LB_BS_ADDTOSLIP_FB_${i}_S`))
                    .join('-');
            }
            if (['FTS'].includes(comb.betType)) {
                combName = selectedComb
                    .split(':')
                    .map((i) => t(`LB_BS_ADDTOSLIP_FB_${i}_M`))
                    .join('-');
            }
            if (['MSP', 'TSP', 'FGS', 'SGA'].includes(comb.betType)) {
                const nameObj = comb.currentCombNameObj || {};
                selectedComb = selectedComb === '000' ? '00' : selectedComb;
                combName = `${['FGS', 'SGA'].includes(comb.betType) ? selectedComb : `(${selectedComb})`}${comb.betType==="SGA"?".":""} ${
                    nameObj['name_' + i18n.language]
                }`;
            }
            if (['CHP', 'TPS', 'TQL', 'FINALIST', 'WINCNTY', 'WINCNTT'].includes(comb.betType)) {
                const selectedCombNumber = comb.currentCombNameObj?.str;
                combName = `${selectedCombNumber} ${comb?.currentCombNameObj?.['name_' + i18n.language]}`;
            }
            if (['GPW', 'GPF'].includes(comb.betType)) {
                combName = (
                    <>
                        {comb?.currentPool?.['name_' + i18n.language]}
                        <br /> {comb?.currentCombNameObj?.['name_' + i18n.language]}
                    </>
                );
            }
            return (
                <>
                    {combName}
                    {condition}
                </>
            );
        };

        const instanceNo = (betObj) => {
            if (['JKC', 'TNC', 'TQL', 'GPW', 'GPF'].includes(betObj.betType)) {
                return betObj?.combs?.[0]?.currentPool?.instNo ? betObj?.combs?.[0]?.currentPool?.instNo : '';
            }
            if (['NTS', 'ENT'].includes(betObj.betType)) {
                const instNo = betObj?.instNo;
                const goal = instNo < 4 ? instNo : 4;
                if (instNo >= 0) {
                    return '(' + t(`LB_FB_RESULT_GOAL_${goal}`).replace('{0}', instNo) + ')';
                }
            }
            return '';
        };

        const handleOddsVal = (comb, index) => {
            /* 之前的 */
            // const combName = combinationName(comb);
            // return (
            //     <div key={comb.id}>
            //         {combName}@
            //         {!betLineData.txNo && comb.oldOddsVal ? comb.oldOddsVal : comb.oddsVal}
            //     </div>
            // );

            /* 修改後的SQ0248-3078 */
            const combName = combinationName(comb);
            const newOdds = comb.oddsVal;
            const oldOdds = comb.oldOddsVal;
            const oddsChanged = oldOdds != undefined && newOdds != oldOdds;
            let oddsContent = '';
            if(betLineData.txNo) {
                if (oddsChanged && (isAutoAcceptance || counterOfferOddsChange)) {
                    oddsContent = (
                        <>
                            {oldOdds} {t('LB_BS_RESENDBET_CHANGED_TO')} {newOdds}
                        </>
                    );
                } else {
                    oddsContent = newOdds;
                }
            } else {
                oddsContent = oldOdds ? oldOdds : newOdds
            }
            return (
                <div key={comb.id}>
                    {combName}@{oddsContent}
                </div>
            );

        }

        const data = (
            <>
                {betObj.betType !== 'ALUP' ? (
                    <>
                        <div>
                            <div>
                                {betObj.frontEndId} | {tournamentName}
                            </div>
                        </div>
                        <div>
                            {!isTPSBetType ? <div>{matchTeam}</div> : null}
                            <div>
                                {t('LB_FB_TITLE_' + betObj.betType)} {instanceNo(betObj)}
                            </div>
                            {isTPSBetType ? <div>{t('LB_FB_TPS_TEAM')}</div> : null}
                            {specialsLine && (
                                <div>
                                    {specialsLine.instNo}{' '}
                                    {i18n.language === 'en' ? specialsLine.name_en : specialsLine.name_ch}
                                </div>
                            )}
                            {betObj.combs.map(handleOddsVal)}
                        </div>
                    </>
                ) : (
                    <>
                        <div>
                            <div>
                                {t('LB_FB_BET_ALLUP')} {betObj?.selectedFormula}
                            </div>
                        </div>
                        <div>
                            {betObj.betList.map((item) => {
                                const lang = i18n.language === 'en' ? 'EN' : 'CH';
                                if (TournamentAllupPools.includes(item.betType)) {
                                    return <TournAllUpTemp objData={item} key={item.matchId} />;
                                } else {
                                    return (
                                        <div key={item.matchId}>
                                            <div>
                                                {item.frontEndId} | {item['tournamentName' + lang]}
                                            </div>
                                            <div>{item['matchTeam' + lang]}</div>
                                            <div>
                                                {t('LB_FB_TITLE_' + item.betType)} {instanceNo(item.betType)}
                                            </div>
                                            <div>
                                                {item.combs.map(handleOddsVal)}
                                            </div>
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </>
                )}
            </>
        );
        return data;
    };

    const transactionDetailsM6 = (betLine) => {
        const betObj = betLine.betObj;
        const nextDraw = betObj.nextDraw;
        let drawNo = String(nextDraw.no).padStart(3, '0');
        if (betObj.pageName.indexOf('SNOWBALL') > -1) {
            drawNo = nextDraw.snowballCode;
        }
        let drawId = `${nextDraw?.year.substring(2, 4)}/${drawNo}`;
        const getBetLineStr = () => {
            let bankerArr = [];
            let legArr = [...betObj.numList];
            let betStr = '';
            if (betObj.bankerList && betObj.bankerList.length > 0) {
                bankerArr = [...betObj.bankerList];
                bankerArr.map((item, index) => {
                    if (index !== 0) {
                        betStr = betStr + '+ ';
                    }
                    betStr = betStr + item + ' ';
                });
                betStr = betStr + '> ';
            }
            legArr.map((item, index) => {
                if (index !== 0) {
                    betStr = betStr + '+ ';
                }
                betStr = betStr + item + ' ';
            });
            if (betObj.random == true) {
                betStr = betStr + ' (' + t('LB_BS_RANDOMNO') + ')';
            }
            return betStr;
        };
        const draws = betObj.multidraw > 1 ? betObj.multidraw + ' ' + t('LB_M6_BETSLIP_DRAWS') : '';
        return (
            <>
                {t('LB_FMENU_MARKSIX')} {drawId} {draws} <br /> {getBetLineStr()} <br />
            </>
        );
    };

    const transactionDetaulsFT = (betLine) => {
        if (betLine.transaction == 'Deposit') {
            if(betLine.curIsPpsType){
                const enLang = i18n.language === 'en';
                return `${t('LB_BS_FT_LINK_PPS_FROM')}${enLang?' ':''}${t('LB_BS_FT_TO')}${enLang?' ':''}${t('LB_BS_FT_BETACCOUNT')} (${t('LB_CASHOUT_REF_NO_S')}: ${betLine.referenceNo ? betLine.referenceNo : '-'})`;
            }
            const transaction = t('LB_BS_FT_DEPOSIT');
            const nba = t('LB_BS_FT_NBA' + betLine.nba);
            return (
                <>
                    {transaction} - {nba}
                </>
            );
        } else if (betLine.transaction == 'Withdrawal') {
            const transaction = t('LB_BS_FT_WITHDRAWAL');
            const method = betLine.method == 'AUTOPAY' ? t('LB_BS_FT_AP') : t('LB_BS_FT_IOT');
            return (
                <>
                    {transaction} ({method})
                </>
            );
        }

        return <></>;
    };

    const getFundTransferStatus = (resultStatus) => {
        switch (resultStatus) {
            case 'SUCCESSFUL':
            case 'AUTOPAYSUCCESSFUL':
                return t('LB_ACCEPTED');
            case 'REJECTED':
            case 'UNSUCCESSFUL':
                return t('LB_REJECTED');
            case 'PENDING':
            case 'TIMEOUT':
            case 'UNKNOWN':
                return t('LB_UNKNOWN');
            case 'PROCESSING':
                return t('LB_BS_FT_PROCESSING');
            default:
                return '';
        }
    };

    const getFundTransferTransactionNUmber = (item) => {
        switch (item.resultStatus) {
            case 'SUCCESSFUL':
            case 'AUTOPAYSUCCESSFUL':
                return item.transactionNumber.padStart(4, '0');
            case 'REJECTED':
            case 'UNSUCCESSFUL':
                return i18n.language == 'en' ? item.msg_en : item.msg_ch;
            case 'TIMEOUT':
            case 'PENDING':
                return '';
            default:
                return '';
        }
    };

    const betLines = getBetLines();
    if (!Array.isArray(betLines)) {
        return;
    }
    try {
        let nowSessionRecords = betLines.map((item, index) => {
            const { prod, betObj, unitBet, resultMsg, resultMsgChi } = item;
            if (prod == 'FT') {
                return {
                    No: index + 1,
                    status: getFundTransferStatus(item.resultStatus),
                    txNo: getFundTransferTransactionNUmber(item),
                    betType: '',
                    betLineStr: transactionDetails(item),
                    unitBet: '',
                    amount: '$' + item.amount
                };
            }

            let amount = '';
            let unitBetAmount = `$${textFormatAmountFn(unitBet, 2)}`;
            if (betObj.isFlexi) {
                unitBetAmount = (betObj.betTotal / betObj.noOfBets).toLocaleString(undefined, {
                    minimumFractionDigits: 4,
                    maximumFractionDigits: 4
                });
                if (Number.isNaN(unitBetAmount)) {
                    unitBetAmount = 0;
                } else {
                    unitBetAmount = '$' + unitBetAmount + '/' + betObj.noOfBets + ` (${t('LB_AC_FLEXI_BET')})`;
                }
            } else if (betObj.multidraw) {
                // marksix multidraw
                amount = `${unitBet * betObj.noOfBets * betObj.multidraw}`;
            } else {
                amount = unitBet * (betObj?.noOfBets || betObj?.noOfComb);
                amount = Number.isNaN(amount) ? 0 : amount;
            }

            if (prod == 'M6') {
                if (unitBet > 0 && unitBet < 10) {
                    unitBetAmount = unitBetAmount + ` (${t('LB_M6_BETSLIP_PARTIALUNIT')})`;
                }
            }

            var txNo = item.txNo;

            let status = '';
            if (item.isCounterOffer && item.txNo == undefined) {
                status = t('LB_REJECTED');
                amount = `$${textFormatAmountFn(0, 2)}`;
                if (item.counterOfferAmountChange && item.counterOfferOddsChange) {
                    txNo = t('LB_BS_COUNTER_ODDS_AMOUNT');
                    unitBetAmount = `$${textFormatAmountFn(item.oldUnitBet, 2)}`;
                } else if (item.counterOfferOddsChange) {
                    txNo = t('LB_BS_COUNTER_ODDS');
                } else if (item.counterOfferAmountChange) {
                    txNo = t('LB_BS_COUNTER_AMOUNT');
                    unitBetAmount = `$${textFormatAmountFn(item.oldUnitBet, 2)}`;
                }
            } else if (item.resultCode === 0) {
                status = t('LB_ACCEPTED');
            } else if (item.resultCode > 0) {
                status = t('LB_REJECTED');
                txNo = i18n.language === 'en' ? resultMsg : resultMsgChi;
            } else {
                status = t('LB_UNKNOWN');
            }
            let betType = '';
            betType = betObj.betType;
            if (prod === 'HR') {
                const useLongForm = ['CWA', 'CWB', 'CWC'].includes(betType) ? '_L' : '';
                if (betType.includes('ALUP') && betObj.betList && betObj.betList.length > 0) {
                    const betTypes = [];
                    for (let i = 0; i < betObj.betList.length; i++) {
                        const item = betObj.betList[i];
                        if (!betTypes.includes(item.betType)) {
                            betTypes.push(item.betType);
                        }
                    }
                    if (betTypes.length > 1) {
                        betType = t('LB_BS_BET_CROSSALLUP');
                        betType += ' ' + betObj?.allupFormula;
                        for (let i = 0; i < betObj.betList.length; i++) {
                            const item = betObj.betList[i];
                            betType += '<br>' + t(`LB_${item.betType + useLongForm}`);
                        }
                    } else {
                        const lang = i18n.language === 'en' ? 'EN' : 'CH';
                        if (i18n.language != 'en') {
                            // 'ch'
                            betType =
                                t(`LB_${betObj.betList[0].betType + useLongForm}`) +
                                t('LB_ALLUP_NO_EMPTY') +
                                ' ' +
                                betObj?.allupFormula;
                        } else {
                            // 'en'
                            betType =
                                t('LB_ALLUP_NO_EMPTY') +
                                ' ' +
                                t(`LB_${betObj.betList[0].betType + useLongForm}`) +
                                ' ' +
                                betObj?.allupFormula;
                        }
                    }
                    amount = betObj.betTotal || amount;
                } else {
                    betType = t(`LB_${betType.toUpperCase() + useLongForm}`) || betType;
                }
                if (betObj.isFlexi) {
                    amount = betObj.betTotal || amount;
                }
            } else if (prod === 'FB') {
                if (betType === 'ALUP') {
                    const betTypes = [];
                    const betTypes_raceNo = []; // for tournament cross pool all up
                    const betTypes_tourn = [];
                    for (let i = 0; i < betObj.betList.length; i++) {
                        const item = betObj.betList[i];
                        if (!betTypes.includes(item.betType)) {
                            betTypes.push(item.betType);
                        }
                        for (const comb of item.combs) {
                            if (!(comb.betType && comb.raceno)) {
                                continue;
                            }
                            const tmp = comb.betType + '_' + comb.raceno
                            if (!betTypes_raceNo.includes(tmp)) {
                                betTypes_raceNo.push(tmp);
                            }
                            if (!betTypes_tourn.includes(comb.betType)) {
                                betTypes_tourn.push(comb.betType);
                            }
                        }
                    }
                    if (betTypes.length > 1) {
                        betType = t('LB_BS_BET_CROSSALLUP');
                        betType += ' ' + betObj?.selectedFormula;
                        for (let i = 0; i < betObj.betList.length; i++) {
                            const item = betObj.betList[i];
                            betType += '<br>' + t('LB_FB_TITLE_' + item.betType);
                        }
                    } else if (betTypes_raceNo.length > 1 && betTypes_tourn.length > 1) {
                        betType = t('LB_BS_BET_CROSSALLUP');
                        betType += ' ' + betObj?.selectedFormula;
                        for (let item of betTypes_raceNo) {
                            const bt = item.split('_')[0];
                            betType += '<br>' + t('LB_FB_TITLE_' + bt);
                        }
                    } else {
                        const lang = i18n.language === 'en' ? 'EN' : 'CH';
                        if (i18n.language === 'ch') {
                            betType =
                                t('LB_FB_TITLE_' + betObj.betList[0].betType) +
                                ' ' +
                                t('LB_ALLUP_NO_EMPTY') +
                                ' ' +
                                betObj?.selectedFormula;
                        } else {
                            betType =
                            t('LB_ALLUP_NO_EMPTY') +
                            ' ' +
                            t('LB_FB_TITLE_' + betObj.betList[0].betType) +
                            ' ' +
                            betObj?.selectedFormula;
                        }
                    }
                } else {
                    betType = t(`LB_FB_TITLE_${betType.toUpperCase()}`) || betType;
                }
            } else if (prod === 'M6') {
                betType = t('LB_M6_MK6') || betType;
            }

            amount = amount > 0 ? `$${textFormatAmountFn(amount, 2)}` : amount;

            return {
                No: index + 1,
                status,
                txNo: txNo || '',
                betType,
                betLineStr:
                    (
                        <>
                            {transactionDetails(item, betType)}
                            <br /> {unitBetAmount}
                        </>
                    ) || '',
                amount: item.resultCode === 0 ? amount : `$0.00`
            };
        });
        return nowSessionRecords;
    } catch (error) {
        console.error(error);
    }
    return [];
};

export const checkPpsProcessStatus = async(i18n) =>{
    const betLines = getBetLines();
    if (!Array.isArray(betLines)) {
        return;
    }
    let ppsIds='';
    betLines.map(item=>{
        if(item.curIsPpsType && item.resultStatus=='PROCESSING'){
            ppsIds+=item.referenceNo+',';
        }
    })
    if(!ppsIds.length){
        return ;
    }

    const newObj = await postEnquirePPSStatusTR_IB(ppsIds.slice(0,-1));
    const ppsArr = newObj?.PPSEnquireResult?.split(';');
    const ppsFormatArr=[];
    for(const line of ppsArr){
        const item =line.split('=');
        let enMsg=item[5];
        let chMsg=item[5];
        const code=item[1];
        const resCode=item[3];
        let numberCode=parseInt(code, 10)
        if(numberCode>10 && GetError(code,i18n.language)){
            enMsg = formatMsgFn(resCode, 'en', code)
            chMsg = formatMsgFn(resCode, 'ch', code)
        }else if(numberCode>0){
            enMsg = formatMsgFn(resCode || code, 'en', 'lbl_pps_reject_msg')
            chMsg = formatMsgFn(resCode || code, 'ch', 'lbl_pps_reject_msg')
        }
        let curSessionRecordsStatus = 'REJECTED';
        if(['611','615'].includes(code)){
            curSessionRecordsStatus = 'PROCESSING';
        } else if(['901','603','604','499','610','612','613'].includes(code)){
            curSessionRecordsStatus = 'UNKNOWN';
        }
        let obj = {
            prod: 'FT',
            Result : code, 
            resCode : resCode, 
            resultStatus: curSessionRecordsStatus,
            transactionNumber: '',
            referenceNo: item[0],
            transaction: 'Deposit',
            curIsPpsType : true,
            msg_en : enMsg,
            msg_ch : chMsg,
            amount: textFormatAmountFn(
                item[4] ? item[4] : 0,
                2
            ) ,
            charge: textFormatAmountFn(0, 0),
        }
        ppsFormatArr.push(obj);
    }
    const newBetLines=betLines.map(item=>{
        if(item.curIsPpsType && item.resultStatus=='PROCESSING'){
            const obj=ppsFormatArr.find(line=>line.referenceNo==item.referenceNo);
            return {
                ...item,
                ...obj
            };
        }else{
            return item;
        }
    })
    AssembleCurrentSessionRecordsData(newBetLines);
}
