import React, { useCallback, useContext } from 'react';
import { sortSeq } from '../Common/Common';
import { FootballContext } from '../../Home/FootballPage';
import TQLTable from '../Common/TQLTable';
import { Remark } from '../Common/MatchBase';
import RefreshTime from '../../Common/ui-components/RefreshTime'

const TQL = () => {
    const context = useContext(FootballContext);
    const TqlTable = useCallback(({selection, tournaments}) => {
        tournaments.sort(sortSeq);
        return tournaments.map((item) => {
            let show = selection?.value?.includes(item.id);
            return show && <TQLTable betType="TQL" isMatch={false} tourn={item} isSingleBetPage={true} />
        })
    }, []);

    return (
        <>
        {context.content.pageConfig.showRefreshTime && <div className='refreshContainer'><RefreshTime product={'football'}/></div>}
            <TqlTable selection={context.content.selection} tournaments={context.content.tournData?.tournaments || []}/>
            <div className='mobileMarginLR'>
                <Remark />
            </div>
        </>
    )
}
export default TQL;