import React, {  useContext,  useState, useMemo } from 'react';
import { onClickCheckBox } from './AddBet';
import { Coupon } from './TournamentTable';
import { useTranslation } from 'react-i18next';
import { FootballContext } from '../../Home/FootballPage';
import { getOddsArrowCss } from './MatchBase';
import { toDateFormat, formatOdds,  isEliminated, isRefund, isSellingStarted } from './Common';
import CheckBox from '../../Common/ui-components/CheckBox';
import { useWindowSize } from '../../Common/CommonHooks'

const SPCTable = ({betType, isMatch, match, tourn, showCoupon, isResultPage}) => {
    const { isMobileSize } = useWindowSize();
    const { t, i18n } = useTranslation();
    const context = useContext(FootballContext);
    const [expand, setExpand] = useState(true);
    const [checkboxState, setCheckboxState] = useState([]);
    const foPools = isMatch ? match.foPools.filter(x=> x.oddsType===betType) : tourn.foPools.filter(x=> x.oddsType===betType);
    foPools?.sort((x,y) => {return x.instNo - y.instNo});

    const toggle = () => {
        setExpand(!expand)
    }

    const foPoolLen = useMemo(() => {
        let sumLen = 2
        return foPools?.reduce((acc, foPool) => {
            sumLen += (foPool.lines?.[0]?.combinations?.length || 0);
            sumLen = sumLen % 2 ? sumLen + 1 : sumLen;
            sumLen = sumLen + 2;
            acc.push(sumLen);
            return acc
        }, [])
    }, [foPools])

    const tableProps = { isMatch, match, tourn, isMobileSize, foPoolLen, isResultPage, betType, context, setCheckboxState }    

    return (
        <section className={betType}>
            {showCoupon ? <Coupon title={`${t('LB_FB_TITLE_' + betType)} ${tourn.frontEndId}`} handleOnClick={toggle} isChecked={expand} showHelpBtn={true} betType="SPC"  frontEndId={tourn.frontEndId}/> : null}
            { expand ? <>
                {foPools?.map((foPool, idx) => {
                    return <SPCItemTable idx={idx}  foPool={foPool} {...tableProps}/>
                })}
            </> : null }
        </section>
    )

}
export default SPCTable;

const SPCItemTable = ({foPool, idx, isMatch, match, tourn, isMobileSize, foPoolLen, isResultPage, betType, context, setCheckboxState}) => {
    const {t, i18n} = useTranslation()
    let isSelling = isSellingStarted(isMatch ? match : tourn, foPool, foPool.lines[0]);
    let combinations = foPool.lines?.[0]?.combinations.sort((a,b)=>a.combId-b.combId);
    return <div className="tspTable">
        <div className={`tspHeader ${(!isMobileSize && ((idx === 0 ? 1 : (foPoolLen[idx - 1]) / 2) % 2) === 1) ? 'bg-gray' : '' }`}>
            <div className="tspTitle">{`${t('LB_FB_ITEM_NO')}: ${foPool.instNo} ${foPool["name_"+i18n.language]}`}

            </div>
            {!isResultPage && <div className="tspEsst">
                <div className="tspEsstLbl">{t('LB_FB_CHP_TABLE_DATE')}</div>
                <div>{toDateFormat(isMatch ? match.kickOffTime : foPool.expectedSuspendDateTime, !isMatch, true)}</div>
            </div>}
        </div>
        {!isResultPage && !isSelling && <div className='lb-suspended'> {t('LB_FB_SUSPENDED')}</div>}
        <div className={`tspSelections2`}>
            {combinations.map((comb, index) => {
                let sel = comb.selections[0];
                let id = `${betType}_${foPool.lines[0].lineId}_${comb.combId}_${foPool.id}_${sel.str}`;
                let curState = context.content.oddsSelection.filter(x => x.id == id)[0];
                let isDisabled = isResultPage || isEliminated(comb) || isRefund(comb) || !isSelling || comb.status!="AVAILABLE";
                let cssStr = index%2==0 ? 'even': '';
                curState = {...curState,
                    id: id,
                    checked: curState?.checked || false,
                    matchId: isMatch ? match.id : tourn.id,
                    oddsVal: comb.currentOdds
                }
                const line=((idx === 0 ? 1 : (foPoolLen[idx - 1]) / 2) + Math.ceil((index + 1)/2)) % 2 ;
                return <div className={`tspCellGrid ${cssStr} ${(!isMobileSize && line === 1) ? 'bg-gray' : '' }`}>
                    <div className="tspAns">{`(${sel.str}) ${sel["name_"+i18n.language]}`}</div>
                    <div className={`tspSelChkbox`}>
                        {!isResultPage && <CheckBox product='football' id={`fb_${id}`} classNameBox={`${id} ${isDisabled && 'dim'}`} onClick={() => onClickCheckBox(curState, isDisabled, context, setCheckboxState)} defaultChecked={curState.checked} disabled={isDisabled} />}
                    </div>
                    <div className={`tspSelOdds oddsColor ${getOddsArrowCss(curState)}`}>
                        {formatOdds(t, comb.currentOdds, isResultPage ? '' : comb.status)}
                        <div className={`oddsArrow ${getOddsArrowCss(curState)}`}></div>
                    </div>
                </div>
            })}
            {
                !isMobileSize && combinations.length % 2 ? <div className={`tspCellGrid ${(!isMobileSize && (((foPoolLen[idx]) / 2) % 2) === 1) ? '' : 'bg-gray' }`}></div> : null
            }
        </div>
    </div>
}