import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import RefreshTime from '../../Common/ui-components/RefreshTime';
import { RacingContext } from '../../Home/RacingPage';

export const CurrentOdds = (props) => {
    const { t } = useTranslation();
    const { contentRef, setContent } = useContext(RacingContext);
    const [selValue,setSelValue]=useState('0')

    useEffect(()=>{
        if(contentRef.current.isPresales){
            setSelValue('1')
        }else{
            setSelValue('0')
        }

    },[contentRef.current.isPresales])

    const handleCurrentPresales = (val) => {
        contentRef.current.update = Math.random();
        contentRef.current.isPresales = val=="1";
        setSelValue(val);
        setContent({...contentRef.current});
    }

    const MenuBody = () => {
        let list = ['LB_RC_CURRENT_ODDS', 'LB_RC_PRE_SEEL_ODDS'];
        return list.map((_cItem, _cIndex) => {
            return (
                <Dropdown.Item id={`oType${_cIndex}`} className={`${ _cIndex==selValue ? 'active':''}`} eventKey={_cIndex} value={_cIndex} key={`${_cItem}`} onClick={()=>{handleCurrentPresales(_cIndex)}}>
                    {t(_cItem)}
                </Dropdown.Item>
            );
        });
    }

    return (
        <div className="current-odds inline-flex">
            <label title="" variant="" className="form-check-label" htmlFor="dropdown-currentodds">
                {t(contentRef.current.isPresales ? 'LB_RC_PRE_SEEL_ODDS' : 'LB_RC_CURRENT_ODDS')}
            </label>
            <Menu MenuBody={MenuBody} />

            
        </div>
    );
}

const Menu = ( {MenuBody}) =>{

    return <DropdownButton
            as={ButtonGroup}
            key={`DropdownButton`}
            id={`dropdown-currentodds`}
            variant=""
            title=""
            align="end"
        >
        <MenuBody />
    </DropdownButton>

}

export const MobileCurrentOdds = (props) => {
    const context = useContext(RacingContext);
    return <>
        {context.windowSize.isMobileSize ?
            <div className='mobileCurrentOdds'>
                <RefreshTime product="racing" />
                {context.pageConfig.showPresalesDropdown ? <CurrentOdds /> : null}
            </div>
        :
        null
        }
    </>
}
