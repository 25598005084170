import React,{ useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MarksixContext } from '../../Home/MarksixPage';

const UserInfo = () => {
    const {t} = useTranslation()
    const context = useContext(MarksixContext);

    return <section className={context.pageName}>
        Page to be ready in later sprint
    </section>
}

export default UserInfo