import moment from 'moment';
import { useQuery } from '@apollo/client';
import { getJockeyTrainerRankingQuery } from './DataQuery';
import { errRedirectPage } from '../../Common/utils';

export const useJockeyTrainerRankingQuery = () => {
    const { loading, data } = useQuery(getJockeyTrainerRankingQuery(), {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onError:err=>{
            //errRedirectPage(err);
        }
    });

    const getLastMeeting = () => {
        if ( data?.lastMeeting?.date ) {
            return moment(data?.lastMeeting?.date).format("DD/MM/YYYY");
        }
        return "";
    }

    return [
        loading,
        data?.jockeyStat || [],
        data?.trainerStat || [],
        getLastMeeting
    ];
}