import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate  } from 'react-router-dom';
import { getSiteCoreImagePath } from './home-common';
import { EmptyTable } from './EwinHomeCommon';
import { handleClickMenuItem } from '../Menu/MenuCommon';
import { scrollTo } from './GlobalFunc';

const EwinHomeUsefulLink = ({ linkData, product }) => {
    const {t, i18n} = useTranslation()
    const navigate = useNavigate()

    const goToLink = (url) => {
        scrollTo(0, 0);
        navigate(url);
    }

    const IconAndLink = ({obj}) => {
        return <div onClick={(event)=> { handleClickMenuItem(event, obj, goToLink) }}>
            <div>{obj.icon?.src!=="" && <img src={getSiteCoreImagePath(obj.icon.src, i18n.language, obj.icon.isLocalSrc)} width={obj.icon.width} height={obj.icon.height} title={obj.name.value}/>}</div>
            <div>{obj.name.value}</div>
        </div>
    }

    const UsefulLinksTable = () => {
        const linkList= linkData.length>4?[...linkData].slice(0,4):[...linkData];
        return <div className="blockBody">
            <div className="blockDrawResultHead bottom-border">
                <div>{t('LB_USEFUL_LINK')}:</div>
            </div>
            <div className="blockUsefulLinks iconSize40">
                {linkList.map(obj => {
                    return <IconAndLink obj={obj} key={obj.key} />
                })}
            </div>
        </div>
    }

    return (
        <section className={`${product}UsefulLinks`}>
            {linkData?.length>0 ? <UsefulLinksTable /> : <EmptyTable />}
        </section>
    );
}
export default EwinHomeUsefulLink;