import React, { useCallback, useEffect, useRef, useState, createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { RebateCalcMeetingSlider, VenueSlider } from '../../Common/Slider';
import RBCBetCard from '../Common/RBCBetCard';
import RBCResultCard from '../Common/RBCResultCard';
import { isPoolExist, getPool, getAvailableRaces, getMaxAlupLeg, getFirstAlupPool, validateBet, buildBetline, getCWDescByBetline } from '../Common/RBCCommon';
import { validUnitBet, breakdownBet, trimToCent, roundAmount, roundDividend, getDividends, getLosingAmount, setCalculationMap, getRebateInBet, dividendList, dividendCount, racingPools, rebateList, enablePoolList, enableAlupPoolList, formatBetline } from '../../../components/Racing/Common/RBCBetcal';
import { GetRaceConfig } from '../../Common/ConfigHelper';
import { useQuery, useLazyQuery } from '@apollo/client';
import { getRBCMeetingListQuery, getRBCMeetingQuery } from '../Data/DataQuery';
import { textFormatAmountFn, scrollTo } from '../../Common/GlobalFunc';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';
import CloseButton from '../../Common/ui-components/CloseButton';
import { useWindowSize } from '../../Common/CommonHooks.js'
import {Loading} from '../../Common/ui-components';

import '../../../css/racing/rebate_calculator.scss';


export const RbcContext = createContext();

// choose meeting
const RbcRaceNo = (props) => {
    const {rbcState, selectRaceNo}=props;
    let availRaces = getAvailableRaces(rbcState.betType, rbcState.meeting);
    let disable = false;
    if ( rbcState.raceConfig?.rbcBetType[rbcState.betType].alup ) {
        let maxAlupLeg = getMaxAlupLeg(rbcState.raceConfig?.rbcBetType, rbcState.betTypesByRace);
        disable = rbcState.races.length >= maxAlupLeg;
    }
    let items = [];
    let maxRace = Math.max(...rbcState.meeting.races.map(x=>x.no));
    for ( let i=0; i<=maxRace; i++ ) {
        const currentRace = rbcState.meeting.races.find(race => race.no === i )
        if ( !currentRace ) {
            continue;
        }
        const curRaceNo=currentRace.no;
        let activeCss = rbcState.races.includes(curRaceNo) ? 'active' : '';
        let missCss = activeCss==='' && (!availRaces.includes(curRaceNo) || disable) ? 'miss' : '';
        items.push(
            <div className={`race-no-item ${activeCss} ${missCss}`} key={`metting-venue-li-${curRaceNo}`} onClick={() => missCss==='' && selectRaceNo(curRaceNo)}>
                <h3><span>{curRaceNo}</span></h3>
            </div>
        )
    }
    return <RebateCalcMeetingSlider rebateCalc={true}>{items}</RebateCalcMeetingSlider>;
}

const RebateCalculator = (props) => {
    const { t, i18n } = useTranslation();
    const [selectedLang, setSelectedLang] = useState(i18n.language);
    const { lang } = useParams();

    const [rbcState, setRbcState] = useState({
        raceConfig: null,
        meetingList: {},
        meeting: null,
        date: '',
        venue: '',
        betType: 'ALUP',
        races: [],
        betTypesByRace: {},
        subTypesByRace: {},
        selections: {},
        result: {},
        formula: '',
        curSelectRaceNo:0,
        unitBet: 10
    });

    const [apiErr,setApiErr]=useState(false);
    const [isLoading,setIsLoading]=useState(false);

    useEffect(() => {
        setIsLoading(true);
        if(GetRaceConfig()){
            reloadMtgList();
        }
    }, [GetRaceConfig()])

    const [loadRBCMtgList,rbcListGqlVars] = useLazyQuery(getRBCMeetingListQuery(), {
        variables: { startDate: null, endDate: null },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onCompleted: async data => {
            rbcState.raceConfig = await GetRaceConfig();
            data.raceMeetings.sort((x,y)=>{
                if ( x.date===y.date ) {
                    return rbcState.raceConfig?.validVenue.indexOf(x.venueCode) - rbcState.raceConfig?.validVenue.indexOf(y.venueCode);
                }
                return new Date(y.date.substring(0,10)) - new Date(x.date.substring(0,10));
            });
            rbcState.meetingList = data.raceMeetings.reduce((init, next) => {
                let key = next.date.substring(0,10);
                init[key] = init[key] || [];
                init[key].push(next.venueCode);
                return init;
            }, {});
            if ( Object.keys(rbcState.meetingList).length>0 ) {
                rbcState.date = Object.keys(rbcState.meetingList)[0];
                rbcState.venue = rbcState.meetingList[rbcState.date][0];
            }
            setRbcState({...rbcState});
            reloadMeeting();
            setApiErr(false);
        },
        onError:err=>{
            setApiErr(true);
            setIsLoading(false);
        }
    });

    const reloadMtgList = () => {
        loadRBCMtgList({variables: { startDate: null, endDate: null }});
    }

    const [loadRBCMeeting, rbcGqlVars] = useLazyQuery(getRBCMeetingQuery(), {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onCompleted: data => {
            if ( data && data.raceMeetings && data.raceMeetings.length>0 )
                setRbcMeetingState({ meeting: data.raceMeetings[0] });
            setApiErr(false);
            setIsLoading(false);

        },
        onError:err=>{
            setApiErr(true);
            setIsLoading(false);
        }
    });

    const reloadMeeting = () => {
        loadRBCMeeting({variables: { date: rbcState.date, venueCode: rbcState.venue }});
    }

    const resetMeeting = () => {
        let defaultDate = Object.keys(rbcState.meetingList)[0];
        rbcState.date = defaultDate!=null ? defaultDate : "";
        rbcState.venue = defaultDate!=null ? rbcState.meetingList[defaultDate][0] : "";
        rbcState.betType = 'ALUP';
        rbcState.races = [];
        rbcState.betTypesByRace = {};
        rbcState.subTypesByRace = {};
        rbcState.selections = {};
        rbcState.result = {};
        rbcState.formula = '';
        rbcState.unitBet = 10;
        rbcState.curSelectRaceNo = 0;
        rbcState.meeting = null;
        setRbcState({...rbcState});
        scrollTo(0, 0);
    }

    const resetCal = () => {
        resetMeeting();
        setIsLoading(true);
        reloadMeeting();
    }

    const setRbcMeetingState = (args) => {
        if ( args.date!=null )
            rbcState.date = args.date;
        if ( args.venue!=null )
            rbcState.venue = args.venue;
        if ( args.betType!=null )
            rbcState.betType = args.betType;
        if ( args.races!=null )
            rbcState.races = args.races;
        if ( args.betTypesByRace!=null )
            rbcState.betTypesByRace = args.betTypesByRace;
        if ( args.subTypesByRace!=null )
            rbcState.subTypesByRace = args.subTypesByRace;
        if ( args.selections!=null )
            rbcState.selections = args.selections;
        if ( args.formula!=null )
            rbcState.formula = args.formula;
        if ( args.unitBet!=null )
            rbcState.unitBet = args.unitBet;
        if ( args.meeting!=null )
            rbcState.meeting = args.meeting;
        if ( args.result!= null )
            rbcState.result = args.result;
        if ( args.curSelectRaceNo!= null )
            rbcState.curSelectRaceNo = args.curSelectRaceNo;
        setRbcState({...rbcState});
    }

    const subTypeLbl = {
        "S1": t("LB_STYPE_S"),
        "S2": "",
        "M1": t("LB_STYPE_M"),
        "M2": "",
        "B1": t("LB_STYPE_B"),
        "B2": "",
        "BM1": t("LB_STYPE_BM"),
        "BM2": "",
        "MB1": t("LB_STYPE_MB_1"),
        "MB2": t("LB_STYPE_MB_2")
    }

    const {isMobileSize} = useWindowSize()
    const curLargeSize = !isMobileSize

    // const [curLargeSize, setCurLargeSize] = useState(true);

    // useEffect(() => {
    //     initLang();
    // }, [])

    // useEffect(() => {
    //     function handleResize() {
    //         let isMobileSize = window.innerWidth < 769 || document.documentElement?.clientWidth < 769;
    //         setCurLargeSize(!isMobileSize);
    //     }
    //     // Add event listener
    //     window.addEventListener("resize", handleResize);
    //     // Call handler right away so state gets updated with initial window size
    //     handleResize();
    //     // Remove event listener on cleanup
    //     return () => window.removeEventListener("resize", handleResize);
    // }, []);

    const initLang = () => {
        if (selectedLang !== lang) {
            setSelectedLang(lang);
            i18n.changeLanguage(lang);
        }
    }

    const DateDropdownMenu = () => {
        let dates = Object.keys(rbcState.meetingList);
        let menuItem = dates.map((item, index) => {
            return <Dropdown.Item eventKey={item + index} onClick={() => dateOnchange(item)} value={item} key={`${item + index}`} >
                {item}
            </Dropdown.Item>;
        });
        return (dates.length >= 1) && <div className="rc-odds-table-compact-race-allup rcbDateDrop">
            {
                <>
                    <DropdownButton
                        as={ButtonGroup}
                        key={`slAllupDate`}
                        id={`slAllupDate`}
                        variant={rbcState.date}
                        // noCaret
                        title={<><div className='slAllup'>{rbcState.date}</div><ArrowIcon direction='down' size={24}/></>}
                        align="end"
                    >
                        <Dropdown.Item eventKey="slAllupsub-header" value={t('LB_FB_BY_DATE')} key="slAllupsub-header" >{t('LB_FB_BY_DATE')}</Dropdown.Item>
                        {menuItem}

                    </DropdownButton>
                </>
            }
        </div>
    }

    const dateOnchange = (date) => {
        resetMeeting();
        setRbcMeetingState({ date: date, venue: rbcState.meetingList[date][0] });
        setIsLoading(true);
        reloadMeeting();
    }

    // local race
    const Venue = () => {
        let raceArr = rbcState.meetingList[rbcState.date];
        let items = [];
        raceArr && raceArr.map((item) => {
            items.push(
                <div className={`${rbcState.venue === item ? "active" : ""}`} key={`metting-venue-li-${item}`} onClick={() => handleVenueClick(item)}>
                    <h3><span>{t(`LB_RACE_${item}`)}</span></h3>
                </div>
            )
        })
        return <VenueSlider mtgSize={raceArr?.length}>{items}</VenueSlider>;
    }

    const handleVenueClick = (venue) => {
        let date = rbcState.date;
        resetMeeting();
        setRbcMeetingState({ date: date, venue: venue });
        setIsLoading(true);
        reloadMeeting();
    }

    const PoolSelection = () => {
        const poolList = Object.keys(rbcState.raceConfig?.rbcBetType);
        let itemList = poolList.map((_cItem) => {
            if ( isPoolExist(_cItem, rbcState.meeting.pmPools) )
                return <Dropdown.Item eventKey={`main_betType_${_cItem}`} value={_cItem} key={`main_betType_${_cItem}`} onClick={() => selectMainBetType(_cItem)} >{t('LB_RBC_' + _cItem)}</Dropdown.Item>;
            return null;
        });

        return <div className="flex-center">
            <label title="" variant="" className="form-check-label" htmlFor="currentoddsChecbox">{t('LB_RACE_INFO_BLOCK_POOL')}: </label>
            <DropdownButton
                as={ButtonGroup}
                key={`slAllup`}
                id={`slAlluppool`}
                variant=""
                title={<><div className='slAllup'>{t('LB_RBC_' + rbcState.betType)}</div><ArrowIcon direction='down' size={24}/></>}
                align="end"
                className='rbcDdBtn'
            >
                {itemList}
            </DropdownButton>
        </div>
    }

    const selectMainBetType = (bType) => {
        setRbcMeetingState({ betType: bType, races: [], betTypesByRace: {}, subTypesByRace: {}, formula: '', selections: {} });
    }


    const selectRaceNo = (raceNo) => {
        let rbcConfigType = rbcState.raceConfig?.rbcBetType[rbcState.betType];
        if ( rbcConfigType.alup ) {
            let idx = rbcState.races.indexOf(raceNo);
            if ( idx >= 0 ) {
                rbcState.races.splice(idx, 1);
                Object.keys(rbcState.selections).filter(x=> x.indexOf(`${rbcState.betTypesByRace[raceNo]}_${raceNo}`)>=0).forEach(x=> {
                    delete rbcState.selections[x];
                });
                delete rbcState.betTypesByRace[raceNo];
                delete rbcState.subTypesByRace[raceNo];
            }
            else {
                rbcState.races.push(raceNo);
                rbcState.races.sort((x,y) => { return x - y; });
                rbcState.betTypesByRace[raceNo] = rbcState.betType=="ALUP" ? getFirstAlupPool(rbcState.meeting.pmPools, rbcState.raceConfig?.rbcBetType, raceNo) : rbcState.betType;
                rbcState.subTypesByRace[raceNo] = rbcState.betTypesByRace[raceNo]==="FCT" ? "S" : "";
            }
            let formula = rbcState.races.length > 1 ? rbcState.raceConfig?.ALUP_FORMULA[rbcState.races.length][0] : '';
            setRbcMeetingState({ races: rbcState.races, betTypesByRace: rbcState.betTypesByRace,
                subTypesByRace: rbcState.subTypesByRace, formula: formula, curSelectRaceNo: raceNo });
        }
        else {
            if ( rbcState.races.includes(raceNo) ) {
                setRbcMeetingState({ races: [], betTypesByRace: {}, subTypesByRace: {}, formula: '', selections: {} });
            }
            else {
                rbcState.betTypesByRace[raceNo] = rbcState.betType;
                rbcState.subTypesByRace[raceNo] = rbcState.raceConfig?.rbcBetType[rbcState.betType].hasSubType ? "S" : "";
                setRbcMeetingState({ races: [raceNo], betTypesByRace: rbcState.betTypesByRace,
                    subTypesByRace: rbcState.subTypesByRace, formula: '' });
            }
        }
    }

    const AlupFormulaSelection = () => {
        let itemList = rbcState.raceConfig?.ALUP_FORMULA[rbcState.races.length].map((_cItem) => {
            return <Dropdown.Item eventKey={_cItem} value={_cItem} key={`${_cItem}`} onClick={() => selectFormula(_cItem)} >{_cItem}</Dropdown.Item>;
        });
        return <div className="flex-center">
            <label title="" variant="" className="form-check-label" htmlFor="currentoddsChecbox">{t('LB_RC_ALLUP_FORMULA')}: </label>

            <DropdownButton
                as={ButtonGroup}
                key={`DropdownButtonSec`}
                id={`dropdown-currentoddsSec`}
                variant={rbcState.formula}
                title={<><div className='slAllup'>{rbcState.formula}</div><ArrowIcon direction='down' size={24}/></>}
                align="end"
                className="rbcDdBtn"
                disabled={rbcState.races.length < 2}
            >
                {itemList}
            </DropdownButton>
        </div>
    }

    const selectFormula = (formula) => {
        setRbcMeetingState({ formula: formula });
    }

    const handleUnitOnchange = e => {
        let val = e.target.value.replace(/\D/g, '');
        if ( val!=="" )
            val = parseInt(val);
        setRbcMeetingState({ unitBet: val });
    }

    const onchangeUnitFn = type => {
        let newVal = rbcState.unitBet;
        if (type === 'up') {
            if (newVal > 99999999) {
                newVal = 99999999;
            } else {
                newVal += 10;
            }
        } else {
            if (newVal < 20) {
                newVal = 10;
            } else {
                newVal -= 10;
            }
        }
        setRbcMeetingState({ unitBet: newVal });
    }

    const SubTypeButtons = () => {
        return rbcState.races.length > 0 && <div className="rbc-button-set">
            {rbcState.raceConfig?.posPoolSubType.map(item => {
                return curLargeSize ? <div>
                    <label className="radio-container">
                        <div>{subTypeLbl[item+'1']}{subTypeLbl[item+'2']}</div>
                        <input type="radio" defaultChecked={item===rbcState.subTypesByRace[rbcState.races[0]]} value={item} name="betTypeRadio" onClick={(e)=> {clickSubType(item)}} />
                        <span className="radio-checkmark"></span>
                    </label>
                </div>
                :
                <div className={item===rbcState.subTypesByRace[rbcState.races[0]] ? 'active' : ''} onClick={(e)=> {clickSubType(item)}}>
                    <div>{subTypeLbl[item+'1']}</div><div>{subTypeLbl[item+'2']}</div>
                </div>
            })}
        </div>
    }

    const clickSubType = (item) => {
        rbcState.subTypesByRace[rbcState.races[0]] = item;
        setRbcMeetingState({ subTypesByRace: rbcState.subTypesByRace, selections: {} });
    }

    const calculate = () => {
        let [valid, betCount] = validateBet(rbcState, t);
        if ( !valid )
            return;

            //console.log(betCount);
        if (betCount > rbcState.raceConfig?.rbcMaxBetCount) {
            alert(t('LB_RBC_TOO_MANY_SELECTION'));
            return false;
        }

        const formula = rbcState.formula;

        let raceList = rbcState.races;
        let isMultiLeg = rbcState.raceConfig?.rbcBetType[rbcState.betType].multiLeg;
        if (isMultiLeg) {
            raceList = getPool(rbcState.meeting.pmPools, rbcState.betType, rbcState.races[0]).leg.races;
        }
        let betline = [];
        let betlineDesc = [];
        raceList.forEach((raceNo, idx) => {
            let firstRaceNo = isMultiLeg ? raceList[0] : raceNo;
            betline.push(buildBetline(t, rbcState, firstRaceNo, raceNo, idx===0 || !isMultiLeg, false));
            betlineDesc.push(buildBetline(t, rbcState, firstRaceNo, raceNo, idx===0 || !isMultiLeg, true));
        });

        betline = betline.join('/');
        betlineDesc = betlineDesc.join('/');
        if (rbcState.races.length > 1) {
            betline = 'ALUP ' + formula + '/' + betline;
            betlineDesc = 'ALUP ' + formula + '/' + betlineDesc;
        }

        try {
            let breakdown = breakdownBet(betline);
            if (!validUnitBet(breakdown.length, rbcState.betType, rbcState.unitBet)) {
                alert(t('LB_RBC_AMOUNTERROR'));
                return false;
            }
            let totalAmount = breakdown.length * rbcState.unitBet;
            let calMap = setCalculationMap(rbcState);
            //console.log('calMap',calMap);
            let resData = {};
            resData['betLine'] = betlineDesc + ' $' + moneyFormatFn(rbcState.unitBet);
            resData['totalAmount'] = '$' + moneyFormatFn(totalAmount);
            resData['dividendAmount'] = '$0.00';
            resData['losingBetTotalAmount'] = '$0.00';
            resData['rebateAmount'] = '$0.00';
            resData['totalDividentTotalAmount'] = '$0.00';
            resData['divBuf'] = [];
            resData['lossBuf'] = [];

            if (isDividendsReady(calMap)) {
                let totalLoss = 0;
                let totalDiv = 0;
                let totalRebate = 0;
                let isRebateInDiv= 0;
                let divBuf = [];
                let lossBuf = [];

                for (let i = 0; i < breakdown.length; i++) {
                    const getDivi=getDividends(calMap, breakdown[i]);
                    const roundDivi=roundDividend(getDivi * rbcState.unitBet, rbcState.unitBet, breakdown[i])
                    const div = trimToCent(roundDivi);
                    const loseInfo = getLosingAmount(calMap, breakdown[i], rbcState.unitBet);
                    if (div > 0) {
                        divBuf.push({
                            betLine: breakdown[i].indexOf("CW") > -1 ? getCWDescByBetline(t, rbcState, breakdown[i]) : breakdown[i],
                            money: moneyFormatFn(div),
                        })
                    } else {
                        lossBuf.push({
                            betLine: breakdown[i].indexOf("CW") > -1 ? getCWDescByBetline(t, rbcState, breakdown[i]) : breakdown[i],
                            money: moneyFormatFn(trimToCent(loseInfo[1])),
                        })

                        const losingBet = breakdown[i].split('/')[loseInfo[0]];
                        const rebateRateKey = losingBet.split("*")[0].replace(' ', '_');
                        const rebateRate = calMap.rebateList[rebateRateKey] || 0;
                        totalRebate += loseInfo[1] * (rebateRate / 100);
                    }
                    isRebateInDiv += getRebateInBet(calMap, breakdown[i]);


                    totalDiv += div;
                    totalLoss += loseInfo[1];

                }
                if ( totalRebate > 0 || isRebateInDiv > 0 ) {
                    const minRebateAmount = 10000
                    if (totalLoss >= minRebateAmount) {
                        resData['rebateAmount'] = moneyFormatFn(trimToCent(totalRebate))
                    } else {
                        totalRebate = 0;
                    }
                    resData['showRebateDetail'] = true;
                } else {
                    totalRebate = 0;
                    resData['showRebateDetail'] = false;
                }

                const total = roundAmount(totalDiv + totalRebate);

                resData['divBuf'] = divBuf;
                resData['lossBuf'] = lossBuf;
                resData['dividendAmount'] = '$' + moneyFormatFn(trimToCent(totalDiv));
                resData['losingBetTotalAmount'] = '$' + moneyFormatFn(trimToCent(totalLoss));
                resData['totalDividentTotalAmount'] = '$' + moneyFormatFn(trimToCent(total));


            } else {
                resData['showDividendsAnnouncedMsg'] = true;
            }
            setRbcMeetingState({ result: resData });

            scrollTo(0, 0);
        } catch (error) {
            console.error(error);
        }
    }

    const isDividendsReady = (map) => {
        if (rbcState.meeting.status.indexOf("ABANDON")>=0) {
            return true;
        }

        let hasDiv = true;
        rbcState.races.forEach(raceNo => {
            if ( hasDiv ) {
                let tP = [];
                if (rbcState.betTypesByRace[raceNo] === 'W-P') {
                    tP.push('WIN');
                    tP.push('PLA');
                }
                else if (rbcState.betTypesByRace[raceNo] === 'QQP') {
                    tP.push('QIN');
                    tP.push('QPL');
                }
                else {
                    tP.push(rbcState.betTypesByRace[raceNo]);
                }
                tP.forEach(pool => {
                    if (hasDiv && (map.dividendList[pool+ '_' + raceNo] == null || map.dividendList[pool+ '_' + raceNo].length == 0) && map.refundPool[pool+ '_' + raceNo] == null) {
                        hasDiv = false;
                    }
                });
            }
        });
        return hasDiv;
    }

    const moneyFormatFn = (num) => {
        let newNum = num - 0;
        return newNum.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    }

    const showResult = () => {
        return Object.keys(rbcState.result).length > 0;
    }

    const isHasracesLen=rbcState.races?.length>0;

    return (
        <RbcContext.Provider value={{ rbcState, setRbcMeetingState }}>
            <div id="rbc" className={`rebateCalculator ${curLargeSize ? '' : 'rebateCalculator_m'}`}>
                <Helmet>
                    <title>{t('LB_RACING_RBC_TITLE')}</title>
                    <meta name="description" content={t('LB_RACING_RBC_DESC')} />
                </Helmet>
                <div className={`rbcTitle ${curLargeSize ? '' : 'rbcTitle_m popupTitle'}`}>
                    {t('LB_RC_RBCTITLE')}
                    {!curLargeSize && <CloseButton isIOSPWA={true} /> }
                </div>
                {apiErr?
                    <div className='noInfoTip'>{t('LB_RC_JTC_NOINFO')}</div>
                :isLoading ?
                    <div className="wait">
                        <Loading/>
                    </div>
                :
                <>
                {showResult() ?
                    <RBCResultCard />
                    :
                    <div>
                        {curLargeSize ?
                            <div className='rbcMeetingSession'>
                                <div className='venueInfo flex-center'>
                                    <div className='meetingDate '>
                                        <DateDropdownMenu />
                                    </div>
                                    <div className='meeting-button'>
                                        <Venue />
                                    </div>
                                </div>
                                <div className='meetingBtnSplitLine'></div>
                                <div className='meeting-button'>
                                    {rbcState.meeting && <RbcRaceNo selectRaceNo={selectRaceNo} rbcState={rbcState}  />}
                                </div>
                            </div>
                            :
                            <div className='rbcMobileMeetingSession'>
                                <div className='meetingDate '>
                                    <DateDropdownMenu />
                                </div>
                                <div className='venueInfo'>
                                    <div className='meeting-button'>
                                        <Venue />
                                        <div className='meetingBtnSplitLine'></div>
                                        {rbcState.meeting && <RbcRaceNo selectRaceNo={selectRaceNo} rbcState={rbcState} />}
                                    </div>
                                </div>
                            </div>
                        }

                        <div className={`rbcBetFormula ${!curLargeSize ? 'rbcBetFormula_m' : ''}`}>
                            <div className="flex-center">
                                <div className='poolSelection'>
                                    {rbcState.meeting && <PoolSelection />}

                                </div>
                                <div className='alupFormulaSelection'>
                                    {rbcState.meeting && <AlupFormulaSelection />}
                                </div>
                            </div>
                            <div className='unit-bet'>
                                <div className='dfc'>
                                    <label className="unit-bet-label form-check-label">{t('LB_RC_UNIT_EACH_BET')}: </label>
                                    <input maxLength="10" type="text" inputMode="numeric"
                                        autoComplete="off"
                                        className="unit-bet-input mr-12"
                                        onChange={handleUnitOnchange}
                                        id={`text-unit-bet`}
                                        key={`text-unit-bet`}
                                        // defaultValue={10}
                                        value={textFormatAmountFn(rbcState.unitBet, 0, false)}
                                    />
                                </div>
                                <div className='bet-btn-icon-area'>
                                    <ArrowIcon className="bet-btn-icon " onClick={() => { onchangeUnitFn('up') }} direction='up' size={24}/>
                                    <ArrowIcon className="bet-btn-icon " onClick={() => { onchangeUnitFn('down') }} direction='down' size={24}/>

                                </div>
                            </div>
                        </div>
                        <div className='FCT'>
                            {(['TCE', 'QTT'].includes(rbcState.betType) || (rbcState.betType === 'FCT' && rbcState.races.length===1))
                               && <SubTypeButtons />
                            }
                        </div>
                        <div className='rbcCardArea'>
                            <RBCBetCard />
                        </div>
                    </div>
                }
                <div className='rbcBottomBtn'>
                    <button className="race-btn cta_hr mr-12" onClick={resetCal} id={`resetBetUnit`} name="resetBetUnit">{t('LB_RC_RESET')}</button>
                    <button className={`race-btn cta_hr ${showResult()  || !isHasracesLen ? 'disBtn' : ''}`} style={{ display: `${showResult() ? 'none' : 'block'}` }} onClick={()=>{isHasracesLen && calculate()}} disabled={showResult()}>{t('LB_RC_CALCULATE')}</button>
                </div>
                </>
                }
            </div>
        </RbcContext.Provider >
    );
}
export default RebateCalculator;