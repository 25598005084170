import { wagRunningResultCompare } from "./common";

export const parseUpdMatchRes = (message, topicInfo, contentRef) => {
    if(contentRef.current.matchData != null) {
        let match = null;
        match = contentRef.current.matchData.matches.find(x => x.tournament.id===topicInfo.tournamentid && x.id === topicInfo.matchid);
        if(match != null){
            if(match.runningResult == null){

                let runRes ={
                    awayScore : 0,
                    homeScore : 0,
                    corner : 0

                }
                
                let runningResArr= message.wgrRes.res.filter(x => x.stageId == 2 || x.stageId == 3 || x.stageId == 4 || x.stageId == 5); //running result stageId = 2-5
                let hrResArr = runningResArr.filter(x=> x.resTyp ==1).sort(wagRunningResultCompare);  // resTyp==1 for awayScore and homeScore
                let cornerResArr =  runningResArr.filter(x=> x.resTyp ==2).sort(wagRunningResultCompare); // resTyp==2 for corner
             
                if(hrResArr.length>0){
                    let hrResArrLen = hrResArr.length;
                    runRes.awayScore = hrResArr[hrResArrLen-1].aRes;
                    runRes.homeScore = hrResArr[hrResArrLen-1].hRes;
                }
                if(cornerResArr.length>0){
                    let cornerResArrLen = cornerResArr.length;
                    runRes.corner = cornerResArr[cornerResArrLen-1].aRes + cornerResArr[cornerResArrLen-1].hRes; //corner = hRes+aRes
                   
                }
                match.runningResult = runRes;
            }
            else{
                let runningResArr= message.wgrRes.res.filter(x => x.stageId == 2 || x.stageId == 3 || x.stageId == 4 || x.stageId == 5); //running result stageId = 2-5
                let hrResArr = runningResArr.filter(x=> x.resTyp ==1).sort(wagRunningResultCompare);  // resConfirmTyp==1 for awayScore and homeScore
                let cornerResArr =  runningResArr.filter(x=> x.resTyp ==2).sort(wagRunningResultCompare); // resConfirmTyp==2 for corner

                if(hrResArr.length>0){
                    let hrResArrLen = hrResArr.length;
                    match.runningResult.awayScore = hrResArr[hrResArrLen-1].aRes;
                    match.runningResult.homeScore = hrResArr[hrResArrLen-1].hRes;
                }else{
                    match.runningResult.awayScore = 0
                    match.runningResult.homeScore = 0
                }
                if(cornerResArr.length>0){
                    let cornerResArrLen = cornerResArr.length;
                    match.runningResult.corner = cornerResArr[cornerResArrLen-1].aRes + cornerResArr[cornerResArrLen-1].hRes; //corner = hRes+aRes
                }else{
                    match.runningResult.corner  = 0
                }
            }
            if(match.runningResultExtra == null){

                let runResExt ={
                    awayScore : 0,
                    homeScore : 0,
                    corner : 0

                }
                
                let runningResExtArr= message.wgrRes.res.filter(x => x.stageId == 6 || x.stageId == 7 || x.stageId == 8 || x.stageId == 9); //running extra result stageId = 6-9
                let hrResExtArr = runningResExtArr.filter(x=> x.resTyp ==1).sort(wagRunningResultCompare);  // resTyp==1 for awayScore and homeScore
                let cornerExtResArr =  runningResExtArr.filter(x=> x.resTyp ==2).sort(wagRunningResultCompare); // resTyp==2 for corner

          
                if(hrResExtArr.length>0){
                    let hrResExtArrLen = hrResExtArr.length;
                    runResExt.awayScore = hrResExtArr[hrResExtArrLen-1].aRes;
                    runResExt.homeScore = hrResExtArr[hrResExtArrLen-1].hRes;
                }
                if(cornerExtResArr.length>0){
                    let cornerResExtArrLen = cornerExtResArr.length;
                    runResExt.corner = cornerExtResArr[cornerResExtArrLen-1].aRes + cornerExtResArr[cornerResExtArrLen-1].hRes; //corner = hRes+aRes
                   
                }
                match.runningResultExtra = runResExt;
                // console.debug("runResExt",runResExt);


            }
            else{
                let runningResExtArr= message.wgrRes.res.filter(x => x.stageId == 6 || x.stageId == 7 || x.stageId == 8 || x.stageId == 9); //running result stageId = 2-5
                let hrResExtArr = runningResExtArr.filter(x=> x.resTyp ==1).sort(wagRunningResultCompare);  // resConfirmTyp==1 for awayScore and homeScore
                let cornerResExtArr =  runningResExtArr.filter(x=> x.resTyp ==2).sort(wagRunningResultCompare); // resConfirmTyp==2 for corner

                if(hrResExtArr.length>0){
                    let hrResArrExtLen = hrResExtArr.length;
                    match.runningResultExtra.awayScore = hrResExtArr[hrResArrExtLen-1].aRes;
                    match.runningResultExtra.homeScore = hrResExtArr[hrResArrExtLen-1].hRes;
                }else{
                    match.runningResultExtra.awayScore = 0
                    match.runningResultExtra.homeScore = 0
                }
                if(cornerResExtArr.length>0){
                    let cornerResExtArrLen = cornerResExtArr.length;
                    match.runningResultExtra.corner = cornerResExtArr[cornerResExtArrLen-1].aRes + cornerResExtArr[cornerResExtArrLen-1].hRes; //corner = hRes+aRes
                }else{
                    match.runningResultExtra.corner = 0
                }

            }
            if(contentRef.current.isESContent){
                match.results = message?.wgrRes?.res?.map(item => {
                    return {
                        "sequence": item?.seqNo,
                        "resultType": item?.resTyp,
                        "stageId": item?.stageId,
                        "homeResult": item?.hRes,
                        "awayResult": item?.aRes,
                        "resultConfirmType": item?.resConfirmTyp,
                        "payoutConfirmed": item?.payoutConfirmed
                    }
                })
            }
        }
    }
}