import React, { useState, useContext, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    formatRacingDate,
    PenetroReading, HammerReading,
    JackPotInfo, EstDivInfo, Changes,
    hasJpt, hasEst, filterChangeItems
} from './Common';
import { isLocalMeeting } from '../../Common/home-common';
import { formatDateTime } from '../../Common/GlobalFunc';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { RacingContext } from '../../Home/RacingPage';
import Loading from '../../Common/ui-components/Loading';
import { glassboxCustomEvent } from '../../Common/utils/glassbox';
import { redirectJCRW } from './Common';
const CustomScrollbars = props => {
    return (
        <Scrollbars
            // autoHeight
            autoHeightMin={200}
            autoHeightMax={200}
            autoHide
            {...props}
        />
    )
};

const RacingTopBlock = (props) => {
    const { t, i18n } = useTranslation();
    const context = useContext(RacingContext);
    const mtg = context.content.meeting;
    const {meetingList = []} = context.content;

    const onClickMore = () => {
        const url = window.globalConfig.JCRW_URL + t('URL_RC_JCRW_CHANGES');
        glassboxCustomEvent("To_JCRW", "More");
        redirectJCRW(url); 
    }

    if (mtg == null) {
        return <Loading />;
    } if (meetingList.length < 1) {
        return <div>
            <NavTabsHeaderUI t={t} />
            <div className="tab-content" id="myTabContent">
                <div className='noInfoTip'>{t('LB_RC_JTC_NOINFO')}</div>
            </div>
        </div>
    } else {
        let firstRaceNo = Math.min(...mtg.races.map(x=>x.no));
        let firstRace = mtg.races.filter(x => x.no == firstRaceNo)[0];
        let curRaceNo = mtg.currentNumberOfRace == 0 ? firstRaceNo : mtg.currentNumberOfRace;
        let raceObj = mtg.races.filter(x => x.no == curRaceNo)[0];
        let desc = [];
        if (raceObj && raceObj.raceTrack['description_' + i18n.language] != null && raceObj.raceTrack['description_' + i18n.language] != '')
            desc.push(raceObj.raceTrack['description_' + i18n.language]);
        if (raceObj && raceObj.raceCourse['displayCode'] != null && raceObj.raceCourse['displayCode'] != '')
            desc.push(t('LB_RACE_RACE_COURSE').replace('{0}', raceObj.raceCourse['displayCode']));
        if (raceObj && raceObj['go_' + i18n.language] != null && raceObj['go_' + i18n.language] != '')
            desc.push(raceObj['go_' + i18n.language]);

      
        return (<div>
            <NavTabsHeaderUI  t={t}/>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="race-block-gen" role="tabpanel" aria-labelledby="race-block-gen-tab">

                    <CustomScrollbars>
                        <strong>{t('LB_RACE_INFO_BLOCK_MEETING')}</strong>
                        <div>{formatRacingDate(mtg.date)} ({t('LB_' + mtg.dateOfWeek + '_SHORT')})</div>
                        <p>{t('LB_RACE_INFO_BLOCK_' + mtg.meetingType).replace('{0}', mtg.totalNumberOfRace).replace('{1}', t('LB_' + mtg.venueCode))}</p>
                        <strong>{t('LB_RACE_INFO_BLOCK_START')}</strong>
                        <div>{formatDateTime(firstRace?.postTime)?.[1]}</div>
                        <div>{desc.join(', ')}</div>
                        <PenetroReading reading={mtg.penetrometerReadings[0]} t={t} />
                        <HammerReading reading={mtg.hammerReadings[0]} t={t} />
                    </CustomScrollbars>

                </div>
                <div className="tab-pane fade" id="race-block-pool" role="tabpanel" aria-labelledby="race-block-pool-tab">

                    <CustomScrollbars>
                        <div>
                            <strong>{t('LB_RACE_INFO_BLOCK_MEETING')}</strong> {formatRacingDate(mtg.date)}({t('LB_' + mtg.dateOfWeek + '_SHORT')})
                            <hr />
                        </div>
                        <JackPotInfo betType="TRI" mtg={mtg} />
                        <JackPotInfo betType="FF" mtg={mtg} />
                        <EstDivInfo mtg={mtg} />
                        {!hasJpt(mtg) && !hasEst(mtg) ? <div className="font-color-red" style={{ textAlign: "center" }}>{isLocalMeeting(mtg.venueCode) ? t('LB_RACE_INFO_SHORTLY') : t('LB_RC_JTC_NOINFO')}</div> : null}
                    </CustomScrollbars>

                </div>
                <div className="tab-pane fade" id="race-block-change" role="tabpanel" aria-labelledby="race-block-change-tab">

                    <CustomScrollbars>
                        <Changes mtg={mtg} />
                        {mtg.changeHistories.filter(x=> filterChangeItems(x)).length >= 3 ?
                            <div style={{ textAlign: "right" }}><a target="_blank" onClick={onClickMore}  href={`${window.globalConfig.JCRW_URL}${t('URL_RC_JCRW_CHANGES')}`}>{t('LB_RACE_CHANGES_MORE')}</a></div> : null}
                    </CustomScrollbars>
                </div>
            </div>
        </div>);
    }
}
export default RacingTopBlock;

const NavTabsHeaderUI = ({t}) => {
    return <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item first" role="presentation">
            <button className="nav-link active" id="race-block-gen-tab" data-bs-toggle="tab" data-bs-target="#race-block-gen" type="button" role="tab" aria-controls="home" aria-selected="true">
                {t('LB_RACE_INFO_BLOCK_GEN')}
            </button>
        </li>
        <li className="nav-item" role="presentation">
            <button className="nav-link" id="race-block-pool-tab" data-bs-toggle="tab" data-bs-target="#race-block-pool" type="button" role="tab" aria-controls="profile" aria-selected="false">
                {t('LB_RACE_INFO_BLOCK_POOL_INFO')}
            </button>
        </li>
        <li className="nav-item last" role="presentation">
            <button className="nav-link" id="race-block-change-tab" data-bs-toggle="tab" data-bs-target="#race-block-change" type="button" role="tab" aria-controls="contact" aria-selected="false">
                {t('LB_RACE_INFO_BLOCK_CHANGE')}
            </button>
        </li>
    </ul>
}