import { useEffect, useRef, useState, useContext, useCallback } from 'react';
import moment from 'moment';
import mqtt from 'mqtt';
import { consoleLog, bin2String, getMqttOptions, getNotLoginMqttOptions } from '../GlobalFunc';
import { isLogonHigh } from '../../BetSlip/LoginHooks';
import { useTranslation } from 'react-i18next';
import useGetToken from './useGetToken';
// import { v4 as uuidv4 } from 'uuid'
import { parseTopic } from '../../Racing/Data/useRacingPush/parseTopic';
import { gunzip } from 'react-zlib-js';

const host = window.globalConfig.PUSH_URL;

const useMqttClient = () => {
    const { t, i18n } = useTranslation();
    const client = useRef(null);
    const { getAppToken, getCustomerToken } = useGetToken();
    //const [refreshToken, setRefreshToken] = useState(false);
    const clientId = useRef('');
    const savedTopics = useRef([]);
    const [isPushing, setIsPushing] = useState(false);
    const appToken = useRef("");
    const isConnectedAppToken = useRef(false);
    const customerToken = useRef("");
    const isConnectedCustToken = useRef(false);
    const replayMsgQueue = useRef([]);
    const tmpMsgQueue = useRef([]);
    const RecoveryId = useRef()
    const pushState = useRef({
        topic: '',
        message: {}
    })
    const oldPathName = useRef('')

    const esTimeoutRef = useRef();

    const getCustTokenFunc = () => {
        return customerToken.current;
    }

    const resubscribeTopic = (topics) => {
        client.current?.unsubscribe(savedTopics.current);
        if (topics.length > 0) {
            console.debug('Subscribe Topics : ' + topics);
            client.current?.subscribe(topics, { qos: 0 });
        }else{
            clearMessage()
        }
        savedTopics.current = [...topics];
    };

    const pubTopic = (_topic, message, prefix, clientId) => {
        //set timeout alert
        if (_topic.indexOf('early_stlmt') >= 0 && _topic.indexOf('acpt') >= 0) {
            clearTimeout(esTimeoutRef.current);
            esTimeoutRef.current = setTimeout(() => {
                alert(t('IB_ERROR_711'));
            }, 10000);
        }

        client.current?.publish(
            _topic,
            message,
            {
                qos: 0,
                retain: false,
                properties: {
                    userProperties: {
                        SenderTimeStamp: new Date().getTime(),
                        sender_uniqueid: clientId,
                        sender_systemcode: 'JCBW',
                        sender_hostname: window.location.host,
                        event_prefix_topic: prefix
                    }
                }
            },
            function (error) {
                if (error) {
                    console.error(error);
                } else {
                    console.debug('Published:' + _topic);
                    console.debug(message);
                }
            }
        );
    };

    const subscribeCacheTopic = () => {
        let topic = `hk/d/prdt/wager/rpy/01/recovery/client/${RecoveryId.current}`
        client.current?.unsubscribe(topic)
        console.debug('Subscribe Topics : ' + topic)
        client.current?.subscribe(topic, { qos: 0 })
    }

    const pubCacheTopic = (topic, requestAll) => {
        if(!topic) return
        const indicator = requestAll ? 1 : 0
        client.current?.publish(
            'hk/d/prdt/wager/req/01/recovery/client',
            '',
            {
                qos: 0,
                retain: false,
                properties: {
                    userProperties: {
                        "sender-id": RecoveryId.current,
                        "recovery-topic": topic.replace(/\+/g, '*' ),
                        "request-all-indicator": indicator
                    }
                }
            },
            function (error) {
                if (error) {
                    console.error(error);
                } else {
                    console.debug('pubCacheTopic: ' + topic,', indicator: ' + indicator );
                }
            }
        );
    }

    const clearMessage = () => {
        tmpMsgQueue.current = []
        replayMsgQueue.current = []
    }

    const setPushMsg = (topic, message, packet, isCache) => {
        try{
            var plain = bin2String(message);
            if (plain.length > 0) {
                let msg = JSON.parse(plain);
                console.debug(`${isCache?'cache ':''}topic`, topic, msg);
                let lang = i18n.language=="en" ? "en-us" : "zh-hk";
                if(msg.lang && msg.lang != lang){
                    return;
                }else{
                    let tmpMsg = {
                        topic: topic,
                        lang: msg.lang,
                        message: msg
                    };
                    let isRacingTopic = topic.includes("racing");
                    let requestAll = false;
                    if(isRacingTopic){ 
                        let topicInfo = parseTopic(topic);
                        if(topicInfo.raceStatus || topicInfo.pmPoolStatus){
                            requestAll = true;
                        }
                    }
                    let idx =  tmpMsgQueue.current.findIndex(x=> x.topic===topic && (!x.lang || x.lang==lang));
                    let replayIdx =   replayMsgQueue.current.findIndex(x=> x.topic===topic && (!x.lang || x.lang==lang))
 
                    if( !requestAll && isCache && (replayIdx >= 0 || idx >=0)) return

                    if ( !requestAll && idx >=0 ) {
                            tmpMsgQueue.current[idx] = tmpMsg;
                    }
                    else {
                        tmpMsgQueue.current.push(tmpMsg);
                    }                    
                    savePushMsg(topic, msg, requestAll);
                }
            }
        }
        catch (e) {
            console.error(e, e.stack);
        }
        
       
    };

    const savePushMsg = (topic, message, requestAll) => {
        const updTime = moment()
        const msgCacheItem = {topic, message, updTime}
        let lang = i18n.language=="en" ? "en-us" : "zh-hk";
        let idx = replayMsgQueue.current.findIndex(x=> x.topic===topic && (!x.lang || x.lang==lang));
        if ( !requestAll && idx>=0 ) {
            replayMsgQueue.current[idx] = msgCacheItem;
        }
        else {
            replayMsgQueue.current.push(msgCacheItem);
        }
    }

    const messageCallback = (topic, message, packet) => {
        if(topic.includes('wager/rpy/01/recovery')){
            if(parseInt(packet.properties?.userProperties['payload-size']) > 0){
                let rTopic = packet.properties.userProperties["recovery-topic"]
                gunzip(message, (err, decoded) => {
                    setPushMsg(rTopic, decoded, packet, true);
                });
            }
        }
        else if (topic.indexOf('early_stlmt') >= 0) {
            if (topic.indexOf('acpt') >= 0) {
                clearTimeout(esTimeoutRef.current);
            }
            setPushMsg(topic, message, packet);
        } else {
            gunzip(message, (err, decoded) => {
                setPushMsg(topic, decoded, packet);
            });
        }
    };

    const connectMqttWithCustomerToken = useCallback(() => {
        if ( customerToken.current != '' && customerToken.current != null) {
            //clientId.current = 'jcbw2_4e_' + Math.random().toString(16).substring(2, 8);
            clientId.current = 'jcbw2_4e_' + sessionStorage.getItem('account') +'_' + new Date().getTime();
            RecoveryId.current = 'jcbw2_4e_' + sessionStorage.getItem('account')+'_'+ new Date().getTime()
            let c = mqtt.connect(host, getMqttOptions(clientId.current, true, customerToken.current));

            c.on('message', messageCallback);

            c.on('error', (err) => {
                console.debug('Connection error (Cust): ', err);
                isConnectedCustToken.current = false;
                setIsPushing(false);
                c.end();
            });

            c.on('connect', () => {
                console.debug('Client connected (Cust):' + clientId.current);
                isConnectedCustToken.current = true;
                subscribeCacheTopic()
                setIsPushing(true);
            });

            c.on('reconnect', () => {
                console.debug('Reconnecting...');
            });

            c.on('close', () => {
                console.debug('Client disconnected (Cust):' + clientId.current);
                isConnectedCustToken.current = false;
                clearMessage()
                setIsPushing(false);
            });

            client.current = c;
        }
    },[])

    const connectMqttWithAppToken = useCallback(() => {
        if (appToken.current != '' && appToken.current != null) {
            clientId.current = 'jcbw2_4e_' + Math.random().toString(16).substring(2, 8);
            RecoveryId.current = 'jcbw2_4e_' + Math.random().toString(16).substring(2, 8)+'_'+ new Date().getTime()
            let c = mqtt.connect(host, getNotLoginMqttOptions(clientId.current, appToken.current));

            c.on('message', messageCallback);

            c.on('error', (err) => {
                console.debug('Connection error (App): ', err);
                isConnectedAppToken.current = false;
                setIsPushing(false);
                c.end();
            });

            c.on('connect', () => {
                console.debug('Client connected (App):' + clientId.current);
                isConnectedAppToken.current = true;
                setIsPushing(true);
                subscribeCacheTopic()
            });

            c.on('reconnect', () => {
                console.debug('Reconnecting...');
            });

            c.on('close', () => {
                console.debug('Client disconnected (App):' + clientId.current);
                isConnectedAppToken.current = false;
                clearMessage()
                setIsPushing(false);
            });

            client.current = c;
        }
    },[])

    const disconnectPush = () => {
        client.current?.end();
        client.current?.off('message', messageCallback);
    }

    const isPushPage = () => {
        return window.location.pathname.toLowerCase().indexOf("/football/")>=0 && window.globalConfig.FB_ODDS_PUSH
             || window.location.pathname.toLowerCase().indexOf("/racing/")>=0 && window.globalConfig.RC_ODDS_PUSH;
    }

    const isConnected = () => {
        let isLogon = isLogonHigh();
        return isLogon && isConnectedCustToken.current || !isLogon && isConnectedAppToken.current;
    }

    useEffect(() => {
        if(oldPathName.current != window.location.pathname){
            if ( isPushPage() ) {
                if ( !isConnected() ) {
                    refreshTokenAndConnect();
                }
            }
            else {
                disconnectPush();
            }
        }
        oldPathName.current = window.location.pathname
    }, [window.location.pathname]);

    useEffect(() => {
        if ( isLogonHigh() ) {
            if ( isConnectedAppToken.current ) {
                disconnectPush();
            }
            if ( !isConnected() ) {
                refreshTokenAndConnect();
            }
        }
        else {
            if ( isConnectedCustToken.current ) {
                disconnectPush();
            }
            if ( !isConnected() ) {
                refreshTokenAndConnect();
            }
        } 
    }, [isLogonHigh()]);

    // for refreshToken
    useEffect(()=> {
        const timer = setInterval(() => {
            disconnectPush();
            refreshTokenAndConnect();
        }, 10 * 60 * 1000);
        return () => {
            clearInterval(timer);
        }
    },[]);

    const refreshTokenAndConnect = () => {
        if ( !isPushPage() ) {
            return;
        }
        
        if ( isLogonHigh() ) {
            getCustomerToken()
            .then((data) => {
                consoleLog("refresh cust token", data?.access_token);
                customerToken.current = data?.access_token;
                connectMqttWithCustomerToken();                   
            });
        }
        else if ( window.globalConfig.ODDS_PUSH_NO_LOGIN ) {
            getAppToken()
            .then((data) => {
                consoleLog("refresh app token", data?.access_token);
                appToken.current = data?.access_token;
                connectMqttWithAppToken();                    
            });
        }
    }

    return { client,
        pushState,
        isPushing,
        resubscribeTopic,
        pubTopic,
        pubCacheTopic,
        getCustTokenFunc,
        clientId,
        tmpMsgQueue,
        replayMsgQueue,
        clearMessage
    };
};

export default useMqttClient;
