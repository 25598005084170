import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import './index.scss';
import './mobile.scss';
import { useTranslation } from 'react-i18next';
import ConfirmLoading from './components/ConfirmLoading';
import OnlineDeposit from './OnlineDeposit/index';
import OnlineWithdrawal from './OnlineWithdrawal';
import LinkingBankAccount from './LinkingBankAccount';
import { getSessionLoginAccount } from '../LoginHooks';
import { NewWindow } from '../../Common/home-common';
import {
    getFPSBankList,
    getAccountInformation,
    getFPSInfomation,
    getFPSErrorList,
    postUpdateFpsInfo
} from './FundsTransferAPI';
import {
    getFPSBankList_IB,
    getFPSErrorList_IB,
    getAccountInformation_IB,
    getFPSInfomation_IB,
    getPPSEnable_IB,
    postUpdateFpsInfo_IB,
    postUpdateFpsSingleLegInfo_IB
} from './FundsTransferIBAPI';
import { getNbaData } from './utils';
import { ExceptionalCaseComp } from './components/FPSChargeTips/otherCaseTips';
import useBroadcastChannel from '../../Common/hooks/useBroadcastChannel';
import { useFPSBannerQuery } from '../../Home/Data/SitecoreDataHooks';
import { useFPSMessageQuery } from '../../Home/Data/DataRefreshHooks';
import { ApiErrPopupUI } from '../LoginBase';
import { setSsoLogout } from '../SsoHook';
import Loading from '../../Common/ui-components/Loading';
import CloseButton from '../../Common/ui-components/CloseButton';
import { isEoD, isPmuEoD } from '../../Common/GlobalFunc';
import { QueryConfig } from '../../Common/ConfigHelper';
import { useWindowSize } from '../../Common/CommonHooks';

const FundTransfer = () => {
    const { t, i18n } = useTranslation();
    const { isMobileSize } = useWindowSize();
    const [tab, setTab] = useState(0);
    const [nbaData, setNbaData] = useState(null);
    const [bankList, setBankList] = useState({});
    const loginAccount = getSessionLoginAccount();
    const accessToken = sessionStorage.getItem('access_token');
    const [initializing, setInitializing] = useState(false);
    const [showReminder, setShowReminder] = useState({ open: false, status: '', params: {} });
    const [isInitPage, setIsInitPage] = useState(true);
    const [saveEpsNotice, setSaveEpsNotice] = useState(false);
    const [ppsBtnState, setPpsBtnState] = useState(false);
    const fpsBannerQuery = useFPSBannerQuery();
    const [loading, fpsMessageQuery] = useFPSMessageQuery();

    // resultShowStatus : SUCCESSFUL , REJECTED , PENDING , TIMEOUT, UNSUCCESSFUL
    const [resultShowStatus, setResultShowStatus] = useState('');

    const noNba = 'noNba',
        noFps = 'noFps',
        fpsServiceCant = 'fpsServiceCant',
        epsServiceCant = 'epsServiceCant';
    const [getExpCaseStatus, setGetExpCaseStatus] = useState('');
    const [withDrawalCaseStatus, setWithDrawalCaseStatus] = useState('');

    const [loadingStatus, setLoadingStatus] = useState(false);
    const refreshBalance = useBroadcastChannel('refreshBalance');
    const ssoLogoutBoardcast = useBroadcastChannel('ssoLogoutNow');
    const [getNbaInfo, setGetNbaInfo] = useState(null);
    const [isOpenBankPageStep, setIsOpenBankPage] = useState(0);
    const [curNbaNo, setCurNbaNo] = useState(1);
    const [showWarningIcon, setShowWarningIcon] = useState(false);
    const [bankAccountType, setBankAccountType] = useState(1);
    const [isApiErr, setIsApiErr] = useState(false);
    const broadcast_resetlogin = useBroadcastChannel('resetLoginTimeout');
    const [bankOrPpsAccType, setBankOrPpsAccType] = useState('');

    const isEnablePPSG = sessionStorage.getItem('IsEnablePPSG')==='1';
    const isMassSite = !(window.globalConfig.IS_SPEEDBET || window.globalConfig.IS_EWIN2);
    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        setResultShowStatus('');
        if (tab === 0) {
            setIsOpenBankPage(0);
            setCurNbaNo(1);
            setBankOrPpsAccType('');
        }
    }, [tab]);

    useEffect(() => {
        if (!nbaData?.link.config.disabled) {
            if (nbaData?.deposit?.nba1?.FPS?.isSingleLeg == true || nbaData?.deposit?.nba2?.FPS?.isSingleLeg == true) {
                setShowWarningIcon(true);
            } else if (
                nbaData?.deposit?.nba1?.FPS?.disabled == false &&
                nbaData?.deposit?.nba1?.FPS?.linkStatus == 'NO' &&
                nbaData?.deposit?.nba1?.bankInfo?.eDDADepositEnable == '1'
            ) {
                setShowWarningIcon(true);
            } else if (
                nbaData?.deposit?.nba2?.FPS?.disabled == false &&
                nbaData?.deposit?.nba2?.FPS?.linkStatus == 'NO' &&
                nbaData?.deposit?.nba2?.bankInfo?.eDDADepositEnable == '1'
            ) {
                setShowWarningIcon(true);
            } else {
                setShowWarningIcon(false);
            }
        }
    }, [nbaData]);

    const checkIsEod = async () => {
        //await QueryConfig('channelParas', window.globalConfig.CHANNEL_PARA_URL);
        return setEodUi();
    };

    const setEodUi = () => {
        if (isEoD()) {
            return true;
        } else {
            return false;
        }
    };
    const getEodMessage = () => {
        let message =
            i18n.language === 'en' ? sessionStorage.getItem('OOSEngContent') : sessionStorage.getItem('OOSChiContent');
        if (message == null || message.length <= 0) {
            message = t('LB_BS_EOD');
        }
        return message;
    };

    const init = async () => {
        if (await checkIsEod()) {
            window.alert(getEodMessage());
            window.close();
        }
        setInitializing(true);
        if (!accessToken) return;
        const [nbaData, bankList, FPSErrorList] = await Promise.all([
            await getAccInfo(),
            await getBankList(),
            window.globalConfig.IS_IB ? await getFPSErrorList_IB() : await getFPSErrorList()
        ])
            .then((datas) => {
                broadcast_resetlogin?.postMessage(false);
                return datas;
            })
            .catch((err) => {
                console.error(err);
                broadcast_resetlogin?.postMessage(false);
                return [{}, {}, {}];
            });
        let FPSInfo = { bankAccount: [] };
        if (
            (nbaData?.nba?.nba1BankCode && nbaData?.nba?.nba1BankAccountNumber) ||
            (nbaData?.nba?.nba2BankCode && nbaData?.nba?.nba2BankAccountNumber)
        ) {
            FPSInfo = window.globalConfig.IS_IB
                ? await getFPSInfomation_IB(i18n.language)
                : await getFPSInfomation(nbaData?.nba);
            broadcast_resetlogin?.postMessage(false);

            if (isReturnErrorCode(sessionStorage.getItem('result'))) {
                return;
            }
        }
        setGetNbaInfo(nbaData?.nba);
        const initNbaData = getNbaData({ nbaData, FPSInfo, bankList, FPSErrorList });

        setNbaData(initNbaData);
        checkNbaState(initNbaData);
        setBankList(bankList);
        if (FPSInfo?.bankAccount) {
            setInitializing(false);
        }
        displayPopupMessage(initNbaData);

        const PPSEnable = await getPPSEnable_IB();
        setPpsBtnState(PPSEnable && !isPmuEoD());
    };

    const updateFPSInfoFn = async (num) => {
        const newNbaData = { ...nbaData };
        if (num) {
            newNbaData.deposit.config.FPS.currentMonthlyDepCnt = num;
        } else {
            const FPSInfo = await getFPSInfomation(getNbaInfo);
            newNbaData.deposit.config.FPS.currentMonthlyDepCnt = FPSInfo.currentMonthlyDepCnt;
        }
        setNbaData({ ...newNbaData });
    };

    const checkNbaState = (obj) => {
        if (obj?.deposit) {
            const { deposit } = obj;
            const { nba1, nba2 } = deposit;
            let status = '';
            let curBankIdx = 1;
            if (!nba1 && !nba2) {
                status = noNba;
            } else {
                if (nba1) {
                    const nba1EpsShow = nba1.EPS.show;
                    const nba1FpsShow = nba1.FPS.show;
                    const nba1FpsDisabled = nba1.FPS.disabled;
                    if (!nba1EpsShow && !nba1FpsShow) {
                        status = noFps;
                    }
                    if (!nba1EpsShow && !nba1FpsShow && nba1FpsDisabled && !nba2) {
                        status = fpsServiceCant;
                    }
                    if (!deposit.config.otherMeansEnabled) {
                        if (!nba1EpsShow && nba1FpsShow && nba1FpsDisabled) {
                            if (!nba2) {
                                status = fpsServiceCant;
                            } else if (!nba2.EPS.show && nba2.FPS.show && nba2.FPS.disabled) {
                                status = fpsServiceCant;
                            }
                        }
                    }
                    if (nba1?.FPS?.isSingleLeg) {
                        setShowWarningIcon(true);
                    }
                }
                if (nba2) {
                    const nba2EpsShow = nba2.EPS.show;
                    const nba2FpsShow = nba2.FPS.show;
                    const nba2FpsDisabled = nba2.FPS.disabled;
                    if (nba2EpsShow || nba2FpsShow || nba1?.EPS.show || nba1?.FPS.show) {
                        status = '';
                    } else {
                        status = noFps;
                        curBankIdx = 2;
                    }
                    if (
                        status != noFps &&
                        !nba2EpsShow &&
                        !nba2FpsShow &&
                        (!nba1 || (!nba1?.EPS.show && !nba1?.FPS.show && nba1?.FPS.disabled))
                    ) {
                        status = fpsServiceCant;
                        curBankIdx = 2;
                    }
                    if (!deposit.config.otherMeansEnabled) {
                        if (!nba2EpsShow && nba2FpsShow && nba2FpsDisabled) {
                            if (!nba1) {
                                status = fpsServiceCant;
                            } else if (!nba1.EPS.show && nba1.FPS.show && nba1.FPS.disabled) {
                                status = fpsServiceCant;
                            }
                        }
                    }
                    if (nba2?.FPS?.isSingleLeg) {
                        setShowWarningIcon(true);
                    }
                }
            }
            setGetExpCaseStatus(status);
            setBankAccountType(curBankIdx);

            if (!nba1 || nba1?.bankAccountNo.length <= 0) {
                setWithDrawalCaseStatus(noNba);
            }
        }
    };

    const displayPopupMessage = (obj) => {
        const nba1Notify = obj?.link?.nba1?.FPS?.notifyStatus;
        const nba2Notify = obj?.link?.nba2?.FPS?.notifyStatus;

        if (nba1Notify && nba1Notify != '') {
            const eDDAStatus = nba1Notify;
            setShowReminder({
                open: true,
                status: eDDAStatus,
                params: {
                    bankAccountType: 1,
                    bankCode: obj.link.nba1.bankInfo.code,
                    bankAccountNo: obj.link.nba1.bankAccountNo,
                    notifyEDDAStatus: obj?.link?.nba1?.FPS?.notifyEDDAStatus,
                    notifyEDDASingleLegStatus: obj?.link?.nba1?.FPS?.notifyEDDASingleLegStatus
                }
            });
            return;
        } else if (nba2Notify && nba2Notify != '') {
            const eDDAStatus = nba2Notify;
            setShowReminder({
                open: true,
                status: eDDAStatus,
                params: {
                    bankAccountType: 2,
                    bankCode: obj.link.nba2.bankInfo.code,
                    bankAccountNo: obj.link.nba2.bankAccountNo,
                    notifyEDDAStatus: obj?.link?.nba2?.FPS?.notifyEDDAStatus,
                    notifyEDDASingleLegStatus: obj?.link?.nba2?.FPS?.notifyEDDASingleLegStatus
                }
            });
            return;
        }
        setIsInitPage(false);
    };

    const getAccInfo = async () => {
        const responseData = window.globalConfig.IS_IB
            ? await getAccountInformation_IB(i18n.language)
            : await getAccountInformation();
        broadcast_resetlogin?.postMessage(false);
        if (!responseData) {
            setIsApiErr(true);
        } else {
            setIsApiErr(false);
        }
        return responseData || {};
    };

    const getBankList = async () => {
        const responseData = window.globalConfig.IS_IB ? await getFPSBankList_IB() : await getFPSBankList();
        broadcast_resetlogin?.postMessage(false);
        if (!responseData) {
            setIsApiErr(true);
        } else {
            setIsApiErr(false);
        }
        return responseData || {};
    };

    const handleClickHelpLink = () => {
        let url = t('LB_BS_FT_FAQURL');
        NewWindow(url, '', 770, 500, 1, 1);
    };

    const updateBetSlipBalance = (value) => {
        if (!value) return;
        refreshBalance?.postMessage(value);
        window.opener.sessionStorage.setItem('balance', value.replace(/,/g, ''));
    };

    const onClickTab = (index) => {
        !loadingStatus && !initializing && setTab(index);
    };

    const goToLinkBAPage = (step, nbaIndex = 1) => {
        setTab(2);
        setIsOpenBankPage(step);
        setCurNbaNo(nbaIndex);
    };

    const onClickNotice = async () => {
        if (window.globalConfig.IS_IB) {
            if (showReminder.params.notifyEDDASingleLegStatus != '') {
                await postUpdateFpsSingleLegInfo_IB({
                    language: i18n.language === 'en' ? '0' : '1',
                    params: showReminder.params
                });
            } else {
                await postUpdateFpsInfo_IB({
                    language: i18n.language === 'en' ? '0' : '1',
                    params: showReminder.params
                });
            }
        } else {
            await postUpdateFpsInfo(showReminder.params);
        }
        broadcast_resetlogin?.postMessage(false);
        setShowReminder({ open: false, status: '', params: {} });
        setIsInitPage(false);
    };

    const isReturnErrorCode = (errorCode) => {
        const code = parseInt(errorCode);
        if (!isNaN(code)) {
            if (code == -2) {
                setSsoLogout();
                ssoLogoutBoardcast?.postMessage('ssoLogoutNow');
                window.alert(t('LB_BS_SSO_SIGN_IN_USER_CHANGED'));
                window.close();
                return true;
            }

            if (code >= 100 && code <= 120) {
                window.alert(t('IB_ERROR_' + errorCode));
                window.close();
                return true;
            }
        }
        return false;
    };

    const warningIcon = <div className="redWarningIcon">!</div>;

    return (
        <div className="FTInbox">
            <div className={`FTInboxTitle df ${isMobileSize?'popupTitle':''}`}>
                <div>{t('LB_BS_FT_FUNDSTRANSFER')}</div>
                <div className="FTInboxHelp" onClick={handleClickHelpLink} />
                {isMobileSize && <CloseButton isIOSPWA={true} />}
            </div>
            <div className="FTInboxHeader">
                {['deposit', 'withdrawal', 'link'].map((item, index) => {
                    return (
                        nbaData &&
                        !nbaData[item]?.config?.disabled && (
                            <div className={`FTInboxHeaderCell ${tab === index && 'active'} `} key={index}>
                                <div
                                    className={`FTInboxHeaderCellInner ${index === 0 && 'firstCell'}`}
                                    onClick={() => onClickTab(index)}
                                >
                                    {t(`LB_BS_FT_TAB${index}`)}
                                    {index === 2 && showWarningIcon && warningIcon}
                                </div>
                            </div>
                        )
                    );
                })}
            </div>
            {resultShowStatus && (
                <div className="result-status">
                    {resultShowStatus === 'SUCCESSFUL' && (
                        <>
                            <div className="icon-accepted " />
                            <div>{t('LB_BS_FT_RESULTS_SUCESS')}</div>
                        </>
                    )}
                    {resultShowStatus === 'AUTOPAYSUCCESSFUL' && (
                        <>
                            <div className="icon-accepted " />
                            <div>{t('LB_BS_FT_RESULTS_AUTOPAYSUCCESS')}</div>
                        </>
                    )}
                    {resultShowStatus === 'REJECTED' && (
                        <>
                            <div className="icon-rejected " />
                            <div>{t('LB_BS_FT_RESULTS_REJECTED')}</div>
                        </>
                    )}
                    {resultShowStatus === 'PENDING' && (
                        <>
                            <div className="icon-pending" />
                            <div>
                                {tab === 0 ? t('LB_BS_FT_RESULTS_PENDING') : t('LB_BS_FT_RESULTS_AUTOPAYSUCCESS')}
                            </div>
                        </>
                    )}
                    {resultShowStatus === 'SUBMITTED' && (
                        <>
                            <div className="icon-pending " />
                            <div>{t('LB_BS_FT_LINK_RESULT_IP')}</div>
                        </>
                    )}
                    {resultShowStatus === 'TIMEOUT' && (
                        <>
                            <div className="icon-unknown " />
                            <div>{t('LB_BS_FT_RESULTS_TIMEOUT')}</div>
                        </>
                    )}
                    {resultShowStatus === 'UNSUCCESSFUL' && (
                        <>
                            <div className="icon-rejected " />
                            <div>{t('LB_BS_FT_RESULTS_UNSUCCESSFUL')}</div>
                        </>
                    )}
                </div>
            )}
            <div className={`acInfo ${loadingStatus ? 'hide' : ''}`}>
                <div className="acInfoCell acInfoCell1 borderLLine">
                    <span className="labelText">{t('LB_BETSLIP_BETACCOUNTNO')}:</span>
                    <span>{loginAccount}</span>
                </div>
            </div>
            {initializing && <Loading />}
            <div
                className={`FTMain ${loadingStatus || initializing ? 'hide' : ''} ${
                    resultShowStatus ? 'FTMain-s' : ''
                }`}
            >
                {tab === 0 &&
                    (getExpCaseStatus && (isMobileSize || !isMassSite || !isEnablePPSG) ? (
                        <ExceptionalCaseComp
                            props={{
                                getExpCaseStatus,
                                goToLinkBAPage,
                                isSingleLeg: showWarningIcon,
                                bankAccountType,
                                bankList,
                                fpsMessageQuery
                            }}
                        />
                    ) : (
                        <>
                            {nbaData?.deposit && (
                                <OnlineDeposit
                                    props={{
                                        loginAccount,
                                        updateBetSlipBalance,
                                        nbaData: nbaData?.deposit,
                                        FPSErrorList: nbaData?.FPSErrorList,
                                        updateFPSInfoFn,
                                        resultShowStatus,
                                        setResultShowStatus,
                                        loadingStatus,
                                        setLoadingStatus,
                                        goToLinkBAPage,
                                        fpsBannerQuery,
                                        fpsMessageQuery,
                                        isReturnErrorCode,
                                        saveEpsNotice,
                                        setSaveEpsNotice,
                                        getExpCaseStatus,
                                        isSingleLeg: showWarningIcon,
                                        bankAccountType,
                                        bankList,
                                        isMobileSize,
                                        isMassSite,
                                        bankOrPpsAccType, 
                                        setBankOrPpsAccType,
                                        isEnablePPSG,
                                        ppsBtnState
                                    }}
                                />
                            )}
                        </>
                    ))}
                {tab === 1 &&
                    (withDrawalCaseStatus ? (
                        <ExceptionalCaseComp
                            props={{
                                getExpCaseStatus: withDrawalCaseStatus,
                                goToLinkBAPage,
                                bankList,
                                fpsMessageQuery
                            }}
                        />
                    ) : (
                        <OnlineWithdrawal
                            loginAccount={loginAccount}
                            nbaData={nbaData?.withdrawal}
                            FPSErrorList={nbaData?.FPSErrorList}
                            setTab={setTab}
                            goToLinkBAPage={goToLinkBAPage}
                            setResultShowStatus={setResultShowStatus}
                            updateBetSlipBalance={updateBetSlipBalance}
                            loadingStatus={loadingStatus}
                            setLoadingStatus={setLoadingStatus}
                            fpsBannerQuery={fpsBannerQuery}
                            fpsMessageQuery={fpsMessageQuery}
                            isReturnErrorCode={isReturnErrorCode}
                            saveEpsNotice={saveEpsNotice}
                            setSaveEpsNotice={setSaveEpsNotice}
                        />
                    ))}
                {tab === 2 &&
                    (getExpCaseStatus == noNba ? (
                        <ExceptionalCaseComp
                            props={{
                                getExpCaseStatus,
                                goToLinkBAPage,
                                bankList,
                                fpsMessageQuery
                            }}
                        />
                    ) : (
                        <LinkingBankAccount
                            props={{
                                loginAccount,
                                init,
                                nbaData,
                                FPSErrorList: nbaData?.FPSErrorList,
                                setNbaData,
                                loadingStatus,
                                setLoadingStatus,
                                resultShowStatus,
                                setResultShowStatus,
                                bankList,
                                isOpenBankPageStep,
                                curNbaNo,
                                fpsBannerQuery,
                                fpsMessageQuery,
                                isReturnErrorCode
                            }}
                        />
                    ))}
            </div>
            {isInitPage && (
                <Popup open={showReminder.open} className="logout-reminder" closeOnDocumentClick={false}>
                    <div className="logout-reminder-container">
                        <div className="title">{t('LB_BS_FT_LINK_REMIND_HEADER')}</div>
                        <div className="logout-reminder-message">
                            {showReminder.status && t(`LB_BS_FT_LINK_REMIND_${showReminder.status}`)}
                        </div>
                        <div className="link-notice-btn-area">
                            <div className="link-notice-btn" onClick={onClickNotice}>
                                {t('LB_BS_FT_CONFIRM')}
                            </div>
                        </div>
                    </div>
                </Popup>
            )}
            <ApiErrPopupUI isApiErr={isApiErr} />
            {loadingStatus && <ConfirmLoading />}
        </div>
    );
};

export default FundTransfer;
