import React, { useState, useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Popup from 'reactjs-popup';
import { GlobalContext } from '../../../../StandardPage';
import './index.scss';

export const PopupAlert = () => {
    const { t, i18n } = useTranslation();
    const globalContext = useContext(GlobalContext);
    const {mobileAlertObj, setMobileAlertObj}=globalContext;
    const {isShow, alertMsg}=mobileAlertObj;

    const onClickClose = () => {
        setMobileAlertObj({
            isShow:false,
            alertMsg:''
        })
    };


    return (
        <Popup open={isShow} closeOnDocumentClick={false} className="PopupAlert">
            <div className="logout-succ-msg-container">
                <div className="msgContent">{t(alertMsg)}</div>
                <div className="close-btn-box">
                    <div className="close" onClick={onClickClose}>
                        {t('LB_CLOSE')}
                    </div>
                </div>
            </div>
        </Popup>
    );
};