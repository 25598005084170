import $ from 'jquery';
import { alupFormulaItem } from '../../Common/GlobalFunc';
import { getAvailableRunnerNos, getScratchRunnerNos, formatSimpleBetType } from './RBCCommon';

export let enablePoolList = "ALUP,WIN,PLA,W-P,QIN,QPL,QQP,IWN,CWA,CWB,CWC,FCT,TCE,TRI,F-F,QTT,DBL,TBL,D-T,T-T,6UP".split(',');
export let enableAlupPoolList = "WIN,PLA,W-P,QIN,QPL,QQP,FCT,TRI,CWA".split(',');

function SettingVo() {

    this.pool = arguments[0];
    this.alup = arguments[1];
    this.alupMaxRaces = arguments[2];
    this.showBanker = arguments[3];
    this.maxBankerNo = arguments[4];
    this.nameEn = arguments[5];
    this.nameCh = arguments[6];

    this.canScrRefund = arguments[7];

    this.posCnt = arguments[8];

    this.canRebate = arguments[9];

    this.hasSubType = arguments[10];
    this.isMultiLeg = arguments[11];

}
export let racingPools = [];
racingPools['ALUP'] = new SettingVo('ALUP', true, 6, false, 0, 'Cross Pool All Up', '混合過關', true, 1, true, false, false);
racingPools['WIN'] = new SettingVo('WIN', true, 6, false, 0, 'Win', '獨贏', true, 1, true, false, false);
racingPools['PLA'] = new SettingVo('PLA', true, 6, false, 0, 'Place', '位置', true, 1, true, false, false);
racingPools['W-P'] = new SettingVo('W-P', true, 6, false, 0, 'Win & Place', '獨贏及位置', true, 1, true, false, false);
racingPools['QIN'] = new SettingVo('QIN', true, 6, true, 1, 'Quinella', '連贏', true, 1, true, false, false);
racingPools['QPL'] = new SettingVo('QPL', true, 6, true, 1, 'Quinella Place', '位置Q', true, 1, true, false, false);
racingPools['QQP'] = new SettingVo('QQP', true, 6, true, 1, 'QQP', '連贏及位置Q', true, 1, true, false, false);
racingPools['IWN'] = new SettingVo('IWN', false, 1, true, 1, 'IWN', '保險獨贏', true, 1, true, false, false);
racingPools['CWA'] = new SettingVo('CWA', true, 6, false, 0, 'CWA', '組合獨贏 CWA', true, 1, true, false, false);
racingPools['CWB'] = new SettingVo('CWB', false, 6, false, 0, 'CWB', '組合獨贏 CWB', true, 1, true, false, false);
racingPools['CWC'] = new SettingVo('CWC', false, 6, false, 0, 'CWC', '組合獨贏 CWC', true, 1, true, false, false);
racingPools['FCT'] = new SettingVo('FCT', true, 3, true, 0, 'Forecast', '二重彩', true, 2, true, true, false);
racingPools['TCE'] = new SettingVo('TCE', false, 1, false, 0, 'Tierce', '三重彩', true, 3, false, true, false);
racingPools['QTT'] = new SettingVo('QTT', false, 1, false, 0, 'Quartet', '四重彩', true, 4, false, true, false);
racingPools['TRI'] = new SettingVo('TRI', true, 3, true, 2, 'Trio', '單T', true, 1, false, false, false);
racingPools['F-F'] = new SettingVo('F-F', false, 1, true, 3, 'First 4', '四連環', true, 1, false, false, false);
racingPools['DBL'] = new SettingVo('DBL', false, 1, false, 0, 'Double', '孖寶', false, 1, false, false, true);
racingPools['TBL'] = new SettingVo('TBL', false, 1, false, 0, 'Treble', '三寶', false, 1, false, false, true);
racingPools['D-T'] = new SettingVo('D-T', false, 1, true, 2, 'Double Trio', '孖T', false, 1, false, false, true);
racingPools['T-T'] = new SettingVo('T-T', false, 1, true, 2, 'Triple Trio', '三T', false, 1, false, false, true);
racingPools['6UP'] = new SettingVo('6UP', false, 1, false, 0, 'Six Up', '六環彩', false, 1, false, false, true);

export const validUnitBet = (betCount, bType, ub) => {
    if (ub >= 10) { return true; }

    if (bType === "SixUP" || bType === "TT") {
        if (betCount * ub >= 100 && ub >= 2) return true;      // valid
    }
    else if (bType === "QTT") {
        if (betCount * ub >= 24 && ub >= 1) return true;       // valid
    }
    else {  // FCT/TCE/TRI/D-T/F-F/TBL
        if (betCount * ub >= 100 && ub >= 1) return true;       // valid
    }
    return false;
}

export function breakdownBet(bet) {
    let data = [];
    
    if (bet.indexOf('ALUP') >= 0)
        return breakdownALUPBet(bet);

    let parts = bet.split('*');
    let pool = bet.split(' ')[0];
    let prefix = parts[0];
    let legs = parts[1].split('/');

    if (legs.length == 1) {
        data = data.concat(getSingleBreakdown(pool, prefix + '*', legs[0]));
    }
    else {
        let legsBreakdown = [];
        for (let i = 0; i < legs.length; i++) {
            legsBreakdown.push(getSingleBreakdown(pool, '', legs[i]));
        }
        getLegCombinations(data, legsBreakdown, 0, prefix + '*', '/', [], true);
    }

    return data;
}

function breakdownALUPBet(bet) {
    var data = [];
    var legs = bet.split('/');
    let formula = legs[0].split(' ')[1];
    var legBreakdown = [];
    var i;
    for (i = 1; i < legs.length; i++) {
        legBreakdown.push(breakdownBet(legs[i]));
    }
    var combFlag = alupFormulaItem[formula].split('#');
    for (i = 0; i < combFlag.length; i++) {
        getAlupCombinations(data, legBreakdown, 0, '', '/', [], combFlag[i]);
    }

    data = [...new Set(data)];
    return data;
}

function getAlupCombinations(data, legArrays, legNo, prefix, delim, out, formulaFlag) {
    if (legNo < legArrays.length) {
        for (var i = 0; i < legArrays[legNo].length; i++) {
            var a = [...out];
            if (formulaFlag.indexOf((legNo+1).toString())>=0)
                a.push([legArrays[legNo][i]]);
            getAlupCombinations(data, legArrays, legNo + 1, prefix, delim, a, formulaFlag);
        }
    }
    else {
        var b = prefix + out.join(delim);
        data.push(b);
    }
}

function getLegCombinations(data, legArrays, legNo, prefix, delim, out, allowDuplicate) {
    if (legNo < legArrays.length) {
        for (var i = 0; i < legArrays[legNo].length; i++) {
            var a = out.concat([legArrays[legNo][i]]);
            getLegCombinations(data, legArrays, legNo + 1, prefix, delim, a, allowDuplicate);
        }
    }
    else {
        if (allowDuplicate || !hasDuplicateSels(out))
            data.push(prefix + out.join(delim));
    }
}

function hasDuplicateSels(sels) {
    for (var i = 0; i < sels.length; i++) {
        for (var j = i + 1; j < sels.length; j++) {
            if (sels[i] == sels[j])
                return true;
        }
    }
    return false;
}

function getSingleBreakdown(pool, prefix, betBody) {
    var data = [];
    switch (pool) {
        case 'WIN':
        case 'PLA':
        case 'DBL':
        case 'TBL':
        case '6UP':
        case 'CWA':
        case 'CWB':
        case 'CWC':
        case 'IWN':
            data = getBreakdownWIN(prefix, betBody);
            break;
        case 'W-P':
            data = getBreakdownWIN(prefix.replace('W-P', 'WIN'), betBody);
            data = data.concat(getBreakdownWIN(prefix.replace('W-P', 'PLA'), betBody));
            break;
        case 'QIN':
        case 'QPL':
            data = getBreakdownQIN(prefix, betBody);
            break;
        case 'QQP':
            data = getBreakdownQIN(prefix.replace('QQP', 'QIN'), betBody);
            data = data.concat(getBreakdownQIN(prefix.replace('QQP', 'QPL'), betBody));
            break;
        case 'TRI':
        case 'D-T':
        case 'T-T':
            data = getBreakdownTRI(prefix, betBody);
            break;
        case 'F-F':
            data = getBreakdownFF(prefix, betBody);
            break;
        case 'FCT':
        case 'TCE':
        case 'QTT':
            data = getBreakdownTCE(prefix, betBody);
            break;
        default:
            break;
    }
    return data;
}

function getBreakdownWIN(prefix, betBody) {
    var data = [];
    var sels = betBody.split('+');
    for (var i = 0; i < sels.length; i++) {
        data.push(prefix + sels[i]);
    }
    return data;
}

function sortInt(a, b) {
    return a - b;
}

function getBreakdownQIN(prefix, betBody) {
    var data = [];
    var parts = betBody.split('>');

    var sels;
    if (parts.length > 1) {
        var bankers = parts[0];
        sels = parts[1].split('+');
        for (var i = 0; i < sels.length; i++) {
            var combSel = [bankers];
            combSel.push(sels[i]);
            combSel.sort(sortInt);
            data.push(prefix + combSel.join('+'));
        }
    }
    else {
        sels = parts[0].split('+');
        getCombinationArray(data, sels, 0, 2, prefix, '+', []);
    }
    return data;
}

function getCombinationArray(data, sels, idx, size, prefix, delim, out) {
    if (size == 0) {
        data.push(prefix + out.join(delim));
    }
    else {
        for (var i = idx; i <= sels.length - size; i++) {
            var a = out.concat([sels[i]]);
            getCombinationArray(data, sels, i + 1, size - 1, prefix, delim, a);
        }
    }
}

function getBreakdownTRI(prefix, betBody) {
    var data = [];
    var parts = betBody.split('>');

    var sels;
    if (parts.length > 1) {
        var bankers = parts[0].split('+');
        sels = parts[1].split('+');
        var size = 1;
        if (bankers.length == 1) {
            size = 2;
        }
        var tmpData = [];
        getCombinationArray(tmpData, sels, 0, size, '', '+', []);
        for (var i = 0; i < tmpData.length; i++) {
            var combSel = tmpData[i].split('+');
            combSel = combSel.concat(bankers);
            combSel.sort(sortInt);
            data.push(prefix + combSel.join('+'));
        }
    }
    else {
        sels = parts[0].split('+');
        getCombinationArray(data, sels, 0, 3, prefix, '+', []);
    }
    return data;
}

function getBreakdownFF(prefix, betBody) {
    var data = [];
    var parts = betBody.split('>');

    var bankers, sels;
    if (parts.length > 1) {
        bankers = parts[0].split('+');
        sels = parts[1].split('+');
        var size = 1;
        if (bankers.length == 1) {
            size = 3;
        }
        else if (bankers.length == 2) {
            size = 2;
        }
        var tmpData = [];
        getCombinationArray(tmpData, sels, 0, size, '', '+', []);
        for (var i = 0; i < tmpData.length; i++) {
            var combSel = tmpData[i].split('+');
            combSel = combSel.concat(bankers);
            combSel.sort(sortInt);
            data.push(prefix + combSel.join('+'));
        }
    }
    else {
        sels = parts[0].split('+');
        getCombinationArray(data, sels, 0, 4, prefix, '+', []);
    }
    return data;
}

function getBreakdownTCE(prefix, betBody) {
   //console.log(prefix, betBody);
    var data = [];
    var parts = prefix.split(' ');
    var poolType = parts[0];

    var raceNum = parts[2].replace('*', '');
    var subType = parts[1];
    var size = racingPools[poolType].posCnt;

    var i, j, k, tmpData, tmpCombs, parts, bankers, sels;
    switch (subType) {
        case 'S':
            data.push(prefix.replace(' S', '') + betBody);
            break;
        case 'M':
            tmpData = [];
            sels = betBody.split('+');
            getCombinationArray(tmpData, sels, 0, size, '', '+', []);
            for (i = 0; i < tmpData.length; i++) {
                data = data.concat(getTCECombination(poolType, prefix.replace(' M', ''), tmpData[i].split('+')));
            }
            break;
        case 'B':
            parts = betBody.split('>');
            bankers = parts[0].split('+');
            sels = parts[1].split('+');
            if (bankers.length < size - 1) {
                if (poolType == 'TCE') {
                    for (i = 0; i < sels.length; i++) {
                        for (j = i + 1; j < sels.length; j++) {
                            data.push(prefix.replace(' B', '') + bankers.join('+') + '+' + sels[i] + '+' + sels[j]);
                            data.push(prefix.replace(' B', '') + bankers.join('+') + '+' + sels[j] + '+' + sels[i]);
                        }
                    }
                } else if (poolType == 'QTT') {
                    if (bankers.length == 1) {
                        for (i = 0; i < sels.length; i++) {
                            for (j = i + 1; j < sels.length; j++) {
                                for (k = j + 1; k < sels.length; k++) {
                                    data.push(prefix.replace(' B', '') + bankers.join('+') + '+' + sels[i] + '+' + sels[j] + '+' + sels[k]);
                                    data.push(prefix.replace(' B', '') + bankers.join('+') + '+' + sels[i] + '+' + sels[k] + '+' + sels[j]);
                                    data.push(prefix.replace(' B', '') + bankers.join('+') + '+' + sels[j] + '+' + sels[k] + '+' + sels[i]);
                                    data.push(prefix.replace(' B', '') + bankers.join('+') + '+' + sels[j] + '+' + sels[i] + '+' + sels[k]);
                                    data.push(prefix.replace(' B', '') + bankers.join('+') + '+' + sels[k] + '+' + sels[i] + '+' + sels[j]);
                                    data.push(prefix.replace(' B', '') + bankers.join('+') + '+' + sels[k] + '+' + sels[j] + '+' + sels[i]);
                                }
                            }
                        }
                    } else if (bankers.length == 2) {
                        for (i = 0; i < sels.length; i++) {
                            for (j = i + 1; j < sels.length; j++) {
                                data.push(prefix.replace(' B', '') + bankers.join('+') + '+' + sels[i] + '+' + sels[j]);
                                data.push(prefix.replace(' B', '') + bankers.join('+') + '+' + sels[j] + '+' + sels[i]);
                            }
                        }
                    }
                }
            } else {
                for (i = 0; i < sels.length; i++) {
                    data.push(prefix.replace(' B', '') + parts[0] + '+' + sels[i]);
                }
            }
            break;
        case 'BM':
            parts = betBody.split('>');
            bankers = parts[0].split('+');
            sels = parts[1].split('+');
            if (bankers.length < size - 1) {
                if (poolType == 'TCE') {
                    //only one banker
                    for (i = 0; i < sels.length; i++) {
                        for (j = i + 1; j < sels.length; j++) {
                            tmpCombs = [];
                            tmpCombs = tmpCombs.concat(bankers);
                            tmpCombs.push(sels[i]);
                            tmpCombs.push(sels[j]);
                            data = data.concat(getTCECombination(poolType, prefix.replace(' BM', ''), tmpCombs));
                        }
                    }
                } else if (poolType == 'QTT') {
                    if (bankers.length == 1) {
                        for (i = 0; i < sels.length; i++) {
                            for (j = i + 1; j < sels.length; j++) {
                                for (k = j + 1; k < sels.length; k++) {
                                    tmpCombs = [];
                                    tmpCombs = tmpCombs.concat(bankers);
                                    tmpCombs.push(sels[i]);
                                    tmpCombs.push(sels[j]);
                                    tmpCombs.push(sels[k]);
                                    data = data.concat(getTCECombination(poolType, prefix.replace(' BM', ''), tmpCombs));
                                }
                            }
                        }
                    } else if (bankers.length == 2) {
                        for (i = 0; i < sels.length; i++) {
                            for (j = i + 1; j < sels.length; j++) {
                                tmpCombs = [];
                                tmpCombs = tmpCombs.concat(bankers);
                                tmpCombs.push(sels[i]);
                                tmpCombs.push(sels[j]);
                                data = data.concat(getTCECombination(poolType, prefix.replace(' BM', ''), tmpCombs));
                            }
                        }
                    }
                }
            } else {

                for (i = 0; i < sels.length; i++) {
                    tmpCombs = [];
                    tmpCombs = tmpCombs.concat(bankers);
                    tmpCombs.push(sels[i]);

                    data = data.concat(getTCECombination(poolType, prefix.replace(' BM', ''), tmpCombs));
                }
            }
            break;
        case 'MB':
            var pos = betBody.split('>');
            var legsBreakdown = [];
            for (i = 0; i < pos.length; i++) {
                legsBreakdown.push(pos[i].split('+'));
            }
            getLegCombinations(data, legsBreakdown, 0, prefix.replace(' MB', ''), '+', [], false);
            break;
        default:
    }

    return data;
}

export function formatBetline(betline, venue, translateText) {
    let newBetline = [];

    let bets = betline.split('/');
    for (let i = 0; i < bets.length; i++) {
        let newBet = bets[i];
        if (newBet.indexOf("CWA") == 0 || newBet.indexOf("CWB") == 0 || newBet.indexOf("CWC") == 0) {
            let builder = "";
            let items = newBet.split(" ");
            let bType = items[0];
            let raceNo = items[1].split("*")[0];
            let raceData = $.grep(venue.Race, function (r) { return r.RaceNumber == raceNo })[0];

            for (let j = 0; j < raceData.CWin[bType].length; j++) {
                let lbl = translateText("LB_RC_CWIN_COMPOSITE") + " " + raceData.CWin[bType][j].CompositeId;
                if (raceData.CWin[bType][j].starters) {
                    lbl += "(" + raceData.CWin[bType][j].starters + ")";
                }
                newBet = newBet.replace(raceData.CWin[bType][j].CompositeId, lbl);
            }
        }
        newBetline.push(newBet);
    }

    return newBetline.join('/');
}

export function getDividends(map, bet) {
    let div = 1;
    let bType = bet.split(' ')[0];
    var legs = racingPools[bType] && racingPools[bType].isMultiLeg ? [bet] : bet.split('/');
    for (var i = 0; i < legs.length; i++) {
        div *= getSingleDividend(map, legs[i]);
    }
    return div;
}

function getSingleDividend(map, bet) {
    // console.log('getSingleDividend bet->',bet);
    var tmp = bet.split(' ');
    var tmp2 = tmp[1].split('*');

    var pool = tmp[0]; // 过关方式
    var rNo = tmp2[0]; // 当前场次
    var betBody = tmp2[1]; // 投注马号
    var winning = 0;
    var spolier = 0;
    var divs = map.dividendList[ formatSimpleBetType(pool) + '_' + rNo];
    if (isRefund(map, formatSimpleBetType(pool), rNo)) // pool refund
        winning += 1;
    else if (racingPools[pool].canScrRefund && hasScrSel(map, bet))  // Scratch refund (WIN, PLA, QIN, QPL, CWA, CWB, CWC only)
        winning += 1; // refund
    else {
        if (divs != undefined) {
            var tmpWinning;
            for (var i = 0; i < divs.length; i++) {
                let partialUnit = divs[i].partialUnit || 10;
                if (isWinSel(map, rNo, pool, divs[i].winComb, betBody)) {
                    tmpWinning = parseFloat(divs[i].div / partialUnit);

                    if (pool == "6UP") {
                        winning += tmpWinning;
                    } else {
                        if (tmpWinning > winning) {
                            winning = tmpWinning;
                        }
                    }
                } else if (pool == "IWN" && divs[i].winComb.indexOf('F') != -1) {
                    if (isSpoilerHorse(divs[i].winComb, betBody))
                        spolier += parseFloat(divs[i].div / partialUnit) / 10;
                }
            }
            // isAllRefund = false;
        }
    }
    // console.log('winning + spolier->',winning + spolier);
    return winning + spolier;
}

export function getRebateInBet(map, bet) {
    let bType = bet.split(' ')[0];
    var legs = racingPools[bType] && racingPools[bType].isMultiLeg ? [bet] : bet.split('/');
    for (var i = 0; i < legs.length; i++) {
        const rebateRateKey = legs[i].split("*")[0].replace(' ', '_');
        for(let key in map.rebateList){
            if(key==rebateRateKey){
                return 1;
            }
        }
    }
    return 0;
}

function isRefund(map, poolName, raceNum) {
    return map.refundPool[poolName + '_' + raceNum]===true;
}

function hasScrSel(map, bet) {
    var tmp = bet.split(' ');
    var tmp2 = tmp[1].split('*');

    //var pool = tmp[0];
    //var rNo = tmp2[0];
    var betBody = tmp2[1];

    var legList = betBody.split('/');
    for (var i = 0; i < legList.length; i++) {
        var sels = legList[i].split('>');
        for (var j = 0; j < sels.length; j++) {
            if (tmp[0] == "IWN")
                var tmpSel = sels[j].split('#');
            else
                var tmpSel = sels[j].split('+');
            for (var k = 0; k < tmpSel.length; k++) {
                if (isScratch(map, tmp2[0], tmpSel[k]))
                    return true;
            }
        }
    }
    return false;
}

function isScratch(map, raceNum, selNum) {
    return map.scratchList[raceNum + '_' + selNum] === true;
}

function isSpoilerHorse(winComb, bet) {
    if (winComb.split('#')[1] == bet.split('#')[1])
        return true;
    return false;
}

function isWinSel(map, rNo, pool, winComb, sel) {
    var isWin = false;
    switch (pool) {
        case 'WIN':
        case 'PLA':
        case 'TRI':
        case 'F-F':
        case 'CWA':
        case 'CWB':
        case 'CWC':
        case 'IWN':
            isWin = isWinSelSingleLeg(winComb, sel);
            break;
        case 'FCT':
        case 'TCE':
        case 'QTT':
        case 'QIN':
        case 'QPL':
            isWin = isWinSelMultiLegQQP(map, rNo, pool, winComb, sel);
            break;
        case 'TBL':
        case 'DBL':
        case '6UP':
        case 'D-T':
        case 'T-T':
            isWin = isWinSelMultiLeg(map, rNo, pool, winComb, sel);
            break;
        default:
            break;
    }
    return isWin;
}

function isWinSelSingleLeg(winComb, sel) {
    return winComb == sel;
}

function isWinSelMultiLegQQP(map, rNo, pool, winComb, sel) {
    var isWin = false;
    var possibleWinComb = map.divPossibleWinComb[pool + '_' + rNo][winComb];
    if (possibleWinComb != null && $.inArray(sel, possibleWinComb) > -1) {
        isWin = true;
    }
    return isWin;
}

function isWinSelMultiLeg(map, rNo, pool, winComb, sel) {
    var isWin = false;
    var possibleWinComb = map.divPossibleWinComb[formatSimpleBetType(pool) + '_' + rNo][winComb];
    var legSels = sel.split('/');
    if (possibleWinComb != null) {
        var allLegWin = true;
        for (var i = 0; i < legSels.length; i++) {
            if ($.inArray(legSels[i], possibleWinComb[i]) < 0) {
                allLegWin = false;
                break;
            }
        }
        if (allLegWin) {
            isWin = true;
        }
    }
    return isWin;
}

export function roundDividend(div, unitBet, betline) {
    // round up not applicable to IWN
    if (betline.indexOf("IWN") > -1)
        return div;

    if (div == unitBet || div == 0)
        return div;

    var isSinglePla = (betline.indexOf("PLA") > -1) && (betline.indexOf("/") < 0);
    var isSingleCW = (betline.indexOf("CWA") > -1 || betline.indexOf("CWB") > -1 || betline.indexOf("CWC") > -1) && (betline.indexOf("/") < 0);

    var actDiv = (div <= unitBet + 0.5 ? unitBet + 0.5 : roundDivCent(div, 0.5));

    if (isSinglePla) {
        actDiv = (div <= unitBet + 0.5 ? roundDivCent(div, 0.1) : roundDivCent(div, 0.5));
    }

    if (isSingleCW) {
        actDiv = (div <= unitBet + 0.1 ? unitBet + 0.1 : (roundDivCent(div, 0.5) <= unitBet + 0.1 ? unitBet + 0.1 : roundDivCent(div, 0.5)));
    }

    return actDiv;
}

//Rounding part
function roundDivCent(div, step) {
    var idv = Math.floor(div);
    var dec = div - idv;
    var result =								// div = 3.55, idv = 3, dec = 0.55, step = 0.5
        idv										// idv = 3
        + Math.floor(dec / step) * step			// 0.5
        + (dec % step <= step / 2 ? 0 : step);	// 0.05 <= 0.5 / 2 ===> 0
    return result; 							    // result = 3.5
}

export function trimToCent(d) {
    return Math.floor(d * 10) / 10;
}

export function roundAmount(d) {
    var dec = d - Math.floor(d);
    if (dec < 0.25)
        return Math.floor(d);
    else if (dec >= 0.25 && dec < 0.75)
        return Math.floor(d) + 0.5;
    else
        return Math.floor(d) + 1;
}

// return [losing leg, losing amount]
export function getLosingAmount(map, bet, unitAmount) {
    let div = 1;
    let singleDividend;
    let bType = bet.split(' ')[0];
    let legs = racingPools[bType] && racingPools[bType].isMultiLeg ? [bet] : bet.split('/');
    for (let i = 0; i < legs.length; i++) {
        singleDividend = getSingleDividend(map, legs[i]);
        if (singleDividend > 0)
            div *= singleDividend;
        else {
            return [i, parseFloat(unitAmount) * div];
        }
    }
    return [-1, 0];
}

export function setCalculationMap(rbcState) {
    let scratchList = [];
    let refundPool = [];
    let rebateList = [];
    let dividendList = [];
    let divPossibleWinComb = {};

    rbcState.races.forEach(raceNo => {
        getScratchRunnerNos(rbcState.meeting, raceNo).forEach(num => {
            scratchList[raceNo + '_' + num] = true;
        });

        rbcState.meeting.pmPools.filter(x=> x.status.indexOf("REFUND")>=0).forEach(pool => {
            refundPool[pool.oddsType + '_' + pool.leg.races[0]] = true;
        });

        rbcState.meeting.cwInfo.forEach(cwPool => {
            let race = cwPool.leg.races[0];
            cwPool.oddsNodes.forEach(odds => {
                if ( odds.oddsValue==="SCR" || odds.oddsValue==="---" )
                    scratchList[race + '_' + odds.combString] = true;
            });
        });

        rbcState.meeting.pmPools.filter(x=> x.leg.races[0]===raceNo).forEach(pool=> {
            if ( pool.rebateRate > 0 )
                rebateList[pool.oddsType + '_' + raceNo] = pool.rebateRate;

            if ( pool.dividends.length > 0 ) {
                let divs = pool.dividends.map(div => {
                    div.winComb = div.winComb.replace(/,/g, '+');
                    return div;
                })
                dividendList[pool.oddsType + '_' + raceNo] = divs;
            }

            switch (pool.oddsType) {
                case 'TBL':
                case 'DBL':
                case 'SixUP':
                    if (rbcState.betType===pool.oddsType) {
                        divPossibleWinComb[pool.oddsType + '_' + raceNo] = genPossibleWinComb(rbcState, pool.leg.races, dividendList[pool.oddsType + '_' + raceNo] || [], 1);
                    }
                    break;
                case 'DT':
                case 'TT':
                    if (rbcState.betType===pool.oddsType) {
                        divPossibleWinComb[pool.oddsType + '_' + raceNo] = genPossibleWinComb(rbcState, pool.leg.races, dividendList[pool.oddsType + '_' + raceNo] || [], 3);
                    }
                    break;
                case 'QIN':
                case 'QPL':
                case 'QQP':
                    if (rbcState.betType===pool.oddsType || ["ALUP", "QQP"].includes(rbcState.betType)) {
                        divPossibleWinComb[pool.oddsType + '_' + raceNo] = getPossibleWinCombQINQPL(rbcState, raceNo, dividendList[pool.oddsType + '_' + raceNo] || []);
                    }
                    break;
                case 'FCT':
                case 'TCE':
                case 'QTT':
                    if (rbcState.betType===pool.oddsType || ["ALUP"].includes(rbcState.betType)) {
                        divPossibleWinComb[pool.oddsType + '_' + raceNo] = getPossibleWinCombTCE(pool.oddsType, dividendList[pool.oddsType + '_' + raceNo] || []);
                    }
                    break;
                default:
                    break;
            }
        });
    });

    return {    
        scratchList,
        refundPool,
        rebateList,
        dividendList,
        divPossibleWinComb
    }
}

function genPossibleWinComb(rbcState, races, dividendList, noOfSel) {
    var i, k;
    var possiComb = [];
    for (k = 0; k < dividendList.length; k++) {
        var wcList = dividendList[k].winComb.split('/');

        var tmpLegArr = [];
        for (i = 0; i < wcList.length; i++) {
            if (wcList[i] == 'F') {
                wcList[i] = getAvailableRunnerNos(rbcState.meeting, races[i]).join('+');
            }

            if (noOfSel == 1)
                tmpLegArr.push(getBreakdownWIN('', wcList[i]));
            else if (noOfSel == 3)
                tmpLegArr.push(getBreakdownTRI('', wcList[i]));
        }

        possiComb[dividendList[k].winComb] = tmpLegArr;
    }
    return possiComb;
}

function getPossibleWinCombQINQPL(rbcState, raceNo, dividendList) {
    var i, k;
    var possiComb = {};
    for (k = 0; k < dividendList.length; k++) {
        var wcList = dividendList[k].winComb.split('+');
        var pComb = [];
        if (wcList.length == 2 && wcList[1] == 'F') {
            let cardNumber = getAvailableRunnerNos(rbcState.meeting, raceNo);
            for (var i = 0; i < cardNumber.length; i++) {
                if (parseInt(cardNumber[i]) < parseInt(wcList[0]))
                    pComb.push(cardNumber[i] + '+' + wcList[0]);
                else if (parseInt(cardNumber[i]) > parseInt(wcList[0]))
                    pComb.push(wcList[0] + '+' + cardNumber[i]);
            }
        }
        else {
            pComb.push(dividendList[k].winComb);
        }
        possiComb[dividendList[k].winComb] = pComb;
    }
    return possiComb;
}

function getPossibleWinCombTCE(pool, dividendList) {
    var i, k;
    var possiComb = {};
    for (k = 0; k < dividendList.length; k++) {
        var pComb = [];
        if (dividendList[k].winComb.lastIndexOf('M') > 0) {
            var combArr = dividendList[k].winComb.replace('M', '').split('+');
            pComb = getTCECombination(pool, '', combArr);
        }
        else {
            pComb.push(dividendList[k].winComb);
        }
        possiComb[dividendList[k].winComb] = pComb;
    }
    return possiComb;
}


var comb = [];
comb['FCT'] = [[0, 1], [1, 0]];
comb['TCE'] = [[0, 1, 2], [0, 2, 1], [1, 0, 2], [1, 2, 0], [2, 0, 1], [2, 1, 0]];
comb['QTT'] = [[0, 1, 2, 3], [0, 1, 3, 2], [0, 2, 1, 3], [0, 2, 3, 1], [0, 3, 1, 2], [0, 3, 2, 1],
[1, 0, 2, 3], [1, 0, 3, 2], [1, 2, 0, 3], [1, 2, 3, 0], [1, 3, 0, 2], [1, 3, 2, 0],
[2, 0, 1, 3], [2, 0, 3, 1], [2, 1, 0, 3], [2, 1, 3, 0], [2, 3, 0, 1], [2, 3, 1, 0],
[3, 0, 1, 2], [3, 0, 2, 1], [3, 1, 0, 2], [3, 1, 2, 0], [3, 2, 0, 1], [3, 2, 1, 0]];
function getTCECombination(pool, prefix, selCombs) {
    var data = [];
    for (var i = 0; i < comb[pool].length; i++) {
        var buf = [];
        for (var j = 0; j < comb[pool][i].length; j++) {
            buf.push(selCombs[comb[pool][i][j]]);
        }
        data.push(prefix + buf.join('+'));
    }
    return data;
}