import React, { useState, useContext } from 'react';
import NextDrawInfoTable from '../Common/NextDrawInfoTable';
import QuickPick from '../Common/QuickPick';
import MyFavourites from '../Common/MyFavourites';
import { MarksixContext } from '../../Home/MarksixPage';

const Random = () => {
    const context = useContext(MarksixContext);
    const [activeType, setActiveType] = useState('SINGLE')
    const randomProps={activeType, setActiveType};
    return (
        <section className={context.pageName}>
            <NextDrawInfoTable {...randomProps} />
            <QuickPick {...randomProps} />
            <MyFavourites />
        </section>
    );
}
export default Random;
