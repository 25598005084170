import React, { useEffect, useContext, useState, useRef } from 'react';
import Popup from 'reactjs-popup';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../Common/CommonHooks';
import { lblBr } from '../../Common/GlobalFunc';
import AddSlipBtn from '../Common/AddSlipBtn';
import { clickJockyLink, clickTrainerLink, clickJockyOtherLink, clickTrainerOtherLink } from '../Common/RCOddsTableBase';
import { RacingContext } from '../../Home/RacingPage';
import { Scrollbars } from 'react-custom-scrollbars-2';
import '../../../css/racing/fo.scss';
import { useQuickBet } from '../Common/QuickBetHook'
import CheckBox from '../../Common/ui-components/CheckBox';
import { glassboxCustomEvent } from '../../Common/utils/glassbox';

export function getBetStage(foPool) {
    let stage = 0;
    if ( foPool!=null ) {
        if (foPool.raceStopSellNo == 0 && foPool.selections.length > 0 )
            stage = 3;
        else if (foPool.raceStopSellNo > 0 && foPool.selections.length > 0 && foPool.selections[0].results.length == 0)
            stage = 4;
        else if (foPool.raceStopSellNo > 0 && foPool.selections.length > 0 && foPool.selections[0].results.length > 0)
            stage = 5;
    }
    return stage;
}

export function clickLink(t, code, betType) {
    switch ( betType ) {
        case 'JKC':
            clickJockyLink(t, code);
            glassboxCustomEvent("To_JCRW", "Jockey_Info_" + betType);
            break;
        case 'TNC':
            clickTrainerLink(t, code);
            glassboxCustomEvent('To_JCRW', 'Trainer_Info_' + betType);
            break;
    }
}

export function clickOtherLink(t, betType) {
    switch ( betType ) {
        case 'JKC':
            clickJockyOtherLink(t);
            break;
        case 'TNC':
            clickTrainerOtherLink(t);
            break;
    }
}

export function FoName({ t, betType, foPool, num, code, name, showOtherTable, oSels, showNote }) {
    const context = useContext(RacingContext);
    if(num==foPool.otherSelNo){
        if ( showOtherTable ) {
            return <Popup
                    trigger={open => (
                        <div className="jockey"><a herf="#">{t('LB_RC_'+ betType +'_OTHERS')}</a></div>
                    )
                    }
                    closeOnDocumentClick
                    position="right top"
                    className="otherdetailpopup"
                    >
                    <OtherDetail t={t} betType={betType} foPool={foPool} oSels={oSels} resOnly={true} />
                    <OtherDetailM t={t} betType={betType} foPool={foPool} oSels={oSels} resOnly={true} />
                </Popup>
        }
        else
            return <div className="jockey">
                <a className='jockey-link' onClick={() => clickOtherLink(t, betType)}>{showNote ? '*' : ''}{t('LB_RC_'+ betType +'_OTHERS')}</a>
            </div>
    }else{
        return <div className="jockey">
            <a className='jockey-link' onClick={() => clickLink(t, `${code}`, betType)}>{name}</a>
        </div>
    }
}

const Points = ({sel}) => {
    if (sel.points >= 0 && (sel.combStatus==null || !["REFUNDBEFORESUSPEND", "REFUND"].includes(sel.combStatus?.toUpperCase()))) {
        return sel.points;
    }
    return '---';
}

function SRide({t, betType, foPool, sel, oSels, sRides}){
    if(sel.number==foPool.otherSelNo) {
        return <Popup
                trigger={open => (
                    <a herf="#">{t('LB_RC_'+betType+'_DETAILS')}</a>
                )
                }
                closeOnDocumentClick
                position="top center"
                className="otherdetailpopup"
                >
                <OtherDetail t={t} betType={betType} foPool={foPool} oSels={oSels} />
                <OtherDetailM t={t} betType={betType} foPool={foPool} oSels={oSels} />
            </Popup>
    } else {
        if (sRides >= 0 ) {
            if (sel.combStatus!=null && ["REFUNDBEFORESUSPEND", "REFUND"].includes(sel.combStatus?.toUpperCase()))
                return 0;
            return sRides;
        }
        return '---';
    }
}

function OtherDetailM({t, betType, foPool, oSels, resOnly}){
    return <div className='otherdtlsecM'>
        <header className="OtherDetailm"><div className="rcTitle">{t('LB_RC_'+betType+'_OTHERS')}</div></header>
        <CustomScrollbars>
            <div className='otherdtlsecM_table'>
                <OtherDetailHeaderM t={t} betType={betType} foPool={foPool} resOnly={resOnly} />
                <OtherDetailRowM t={t} betType={betType} foPool={foPool} oSels={oSels} resOnly={resOnly}/>
            </div>
        </CustomScrollbars>
    </div>
}

function OtherDetail({t, betType, foPool, oSels, resOnly}){

    return <div className='otherdtlsec'><header className="OtherDetail"><div className="rcTitle">{t('LB_RC_'+betType+'_OTHERS')}</div></header>
        <OtherDetailHeader t={t} betType={betType} foPool={foPool} resOnly={resOnly} />
        <OtherDetailRow t={t} betType={betType} foPool={foPool} oSels={oSels} resOnly={resOnly}/>
    </div>;
}

function OtherDetailHeaderM({t, betType, foPool, resOnly}) {
    let stage = getBetStage(foPool);
     return  <div className="row">
        <div className="cell jk2">{t('LB_RC_' + betType + '_OTHER_JK')}</div>
        {stage==3 || stage==4 ? <div className="cell rides2">{lblBr(t('LB_RC_' + betType + '_LIST_SRIDES'))}</div> : null}
        {stage==5 || resOnly ? <div className="cell pt2">{t('LB_RC_' + betType + '_LIST_POINTS').replace('{nl}',' ')}</div> : null}
        {stage==5 && !resOnly ? <div className="cell rides2">{lblBr(t('LB_RC_' + betType + '_LIST_RRIDES'))}</div> : null}
    </div>;
}

function OtherDetailHeader({t, betType, foPool, resOnly}) {
    let stage = getBetStage(foPool);
    return  <div className="row">
        <div className="cell jk1 jockey">{t('LB_RC_' + betType + '_OTHER_JK')}</div>
        {stage==3 || stage==4 ? <div className="cell rides1">{lblBr(t('LB_RC_' + betType + '_LIST_SRIDES'))}</div> : null}
        {stage==5 || resOnly ? <div className="cell pt1">{lblBr(t('LB_RC_' + betType + '_LIST_POINTS'))}</div> : null}
        {stage==5 && !resOnly ? <div className="cell rides1">{lblBr(t('LB_RC_' + betType + '_LIST_RRIDES'))}</div> : null}
        <div className="cell jk2 jockey">{t('LB_RC_' + betType + '_OTHER_JK')}</div>
        {stage==3 || stage==4 ? <div className="cell rides2">{lblBr(t('LB_RC_' + betType + '_LIST_SRIDES'))}</div> : null}
        {stage==5 || resOnly ? <div className="cell pt2">{lblBr(t('LB_RC_' + betType + '_LIST_POINTS'))}</div> : null}
        {stage==5 && !resOnly ? <div className="cell rides2">{lblBr(t('LB_RC_' + betType + '_LIST_RRIDES'))}</div> : null}
    </div>;
}

const OtherDetailRowItem = ({betType, foPool, oSel, curNum, resOnly}) => {
    const { t, i18n } = useTranslation();
    let items = [];
    let subfix =  curNum % 2 === 0  ? "1" : "2";
    let stage = getBetStage(foPool);
    return <><div className={"cell jk" + subfix}><FoName t={t} betType={betType} foPool={foPool} code={oSel.code} name={oSel['name_'+i18n.language]} />{}</div>
        {stage==3 || stage==4 ? <div className="cell rides"><SRide t={t} betType={betType} foPool={foPool} sel={oSel} sRides={oSel.scheduleRides} /></div> : null}
        {stage==5 || resOnly ? <div className="cell pt"><Points sel={oSel} /></div> : null}
        {stage==5 && !resOnly ? <div className="cell rides"><SRide t={t} betType={betType} foPool={foPool} sel={oSel}  sRides={oSel.remainingRides} /></div> : null}
    </>
}

function OtherDetailRow({betType, foPool, oSels, resOnly}) {
    let rows = [];
    const noOfCols = 2 // should come from state or props
    const noOfRows = Math.ceil(oSels.length / noOfCols)
    Array.from(oSels, (_, j) =>(
        oSels[j].curNum = j
    ));
    return Array.from({ length: noOfRows }, (_, i) => (
        <div className={i % 2 === 0 ? 'row sec' : 'row'}>
            {oSels[i]!=null ? <OtherDetailRowItem className="rc-other-detail-rowitem" betType={betType} foPool={foPool} curNum={oSels[i].curNum} oSel={oSels[i]} resOnly={resOnly} /> : null}
            {oSels[i + noOfRows]!=null ? <OtherDetailRowItem className="rc-other-detail-rowitem" betType={betType} foPool={foPool} curNum={oSels[i + noOfRows].curNum} oSel={oSels[i + noOfRows]} resOnly={resOnly} /> : null}
        </div>
    ));
}
function OtherDetailRowM({betType, foPool, oSels, resOnly})
{
    let rows = [];
    return Array.from(oSels, (_, j) =>(
            <div className={j % 2 === 0 ? 'row sec' : 'row'}>
                <OtherDetailRowItem className="rc-other-detail-rowitem"  betType={betType} foPool={foPool} curNum={1} oSel={oSels[j]} resOnly={resOnly} />
            </div>
        ));
}

export function isPayout(foPool) {
    return foPool?.status.toUpperCase()=="PAYOUTSTARTED";
}

export function isSuspend(foPool) {
    return foPool?.status.toUpperCase() == "SUSPENDED";
}

export function isRefund(foPool) {
    return ["REFUNDBEFORESUSPEND", "REFUND"].includes(foPool?.status.toUpperCase());
}

export function isDisplayFoPool(foPool) {
    return ["SELLINGSTARTED","SELLINGSTOPPED","HOLD","SUSPENDED","REFUNDBEFORESUSPEND","REFUND","PAYOUTSTARTED","PAYOUTSTOPPED","PAYOUTHOLD"].includes(foPool?.status);
}

const CustomScrollbars = props => (
    <Scrollbars
        autoHeight
        //autoHeightMin={250}
        autoHeightMax={250}
        autoHide
        {...props}
    />
);

const FOList = ({ betType }) => {

    const { t, i18n } = useTranslation();
    const anchor = React.useRef();
    const { isMobileSize } = useWindowSize();
    const context = useContext(RacingContext);
    const mtg = context.content.meeting;
    const {setFOSelectedBet, FOSelectedBet} = context
    let foPool = mtg.foPools.filter(x=> x.oddsType==betType && isDisplayFoPool(x))[0];

    const pageState = useRef({
        lastOddsLbl: t('LB_RC_FO_ODDS'),
        showCheckbox: true,
    });

    const hasLastRaceResult = () => {
        return foPool.selections.length > 0 && foPool.selections[0].results.filter(x=> x.raceNo==foPool.includeRaces[foPool.includeRaces.length-1]).length>0;
    }
    useEffect(()=>{
        setFOSelectedBet({})
    },[])


    // 和以下代碼相關
    useEffect(()=>{
        if(foPool?.status){
            if(!["SELLINGSTARTED"].includes(foPool.status?.toUpperCase())){
                setFOSelectedBet({});
             }
        }

    },[foPool?.status])


    //可能是由於foPool的引用沒有變化，導致下面的代碼不生效，所以寫了以上的測試代碼
    useEffect(()=>{
        if(foPool!= null){
            if(!["SELLINGSTARTED"].includes(foPool.status?.toUpperCase())){
                setFOSelectedBet({});
             }
        }

    },[foPool])


    const handleClickOddsCheckbox = (id, sel) =>{
        const newSelectedBet = {...FOSelectedBet}
        if(!FOSelectedBet[id] || !FOSelectedBet?.[id]?.checked){
            newSelectedBet[id] = {
                id,
                checked: true,
                sel
            }
        }else{
            newSelectedBet[id] = {
                id,
                checked: false,
                sel
            }
        }

        setFOSelectedBet(newSelectedBet)
    }

    useEffect( () => {
        if ( foPool !=null ) {
            let newState = {};
            if (isPayout(foPool) || hasLastRaceResult()) {
                newState.lastOddsLbl = t('LB_RC_FO_CLOSING_ODDS');
                newState.showCheckbox = false;
                newState.hideOdds = true;
            }
            else {
                newState.lastOddsLbl = t('LB_RC_FO_ODDS');
                newState.showCheckbox = true;
                newState.hideOdds = false;
            }

            if (isSuspend(foPool) || (foPool.raceStopSellStatus == '1' && foPool.selections[0]?.results.length > 0)) {
                newState.showCheckbox = false;
                newState.hideOdds = true;
            }
            pageState.current  = newState
        }
    });

    const NoResult = () => {
        return <div className="refund">{t('LB_RC_'+betType+'_LIST_NA')}</div>
    }

    if( !foPool || foPool.status=="" || foPool.selections.length==0 ) {
        return <NoResult/>
    }
    else if ( isRefund(foPool) ) {
        return t('LB_RC_'+ betType + '_REFUND').replace('{0}', foPool.instNo);
    }
    else {
        return <><div className="table folist">
            <RowHeader {...{foPool, anchor, betType, pageState, t}} />
            <Rows {...{foPool, betType, mtg, pageState, FOSelectedBet, handleClickOddsCheckbox, isMobileSize, i18n, t, context}} />
        </div>
        <div className="table folistaddtoslip">
            <div className="row">
                <div className="cell"><div className='slipcontainer'><div className='left'></div><div className='right'>
                    <div className="addSlip"><AddSlipBtn /></div></div></div></div>
            </div>
        </div></>
    }
}

const RowHeader = ({foPool, anchor, betType, pageState, t}) => {
    let stage = getBetStage(foPool);
    return <div className="row" ref={anchor}>
        <div className="cell sel">{lblBr(t('LB_RC_' + betType + '_LIST_SEL'))}</div>
        <div className="cell jockey">{lblBr(t('LB_RC_' + betType + '_LIST_JK'))}</div>
        {stage==3 || stage==4 ? <div className="cell srides">{lblBr(t('LB_RC_' + betType + '_LIST_SRIDES'))}</div> : null}
        {stage==3 || stage==4 ? <div className="cell oodds">{lblBr(t('LB_RC_FO_OPEN_ODDS'))}</div> : null}
        {stage==5 ? <div className="cell srides">{lblBr(t('LB_RC_' + betType + '_LIST_POINTS'))}</div> : null}
        {stage==5 ? <div className="cell srides">{lblBr(t('LB_RC_' + betType + '_LIST_RRIDES'))}</div> : null}
        <div className="cell podds">{lblBr(t('LB_RC_FO_PREV_ODDS'))}</div>
        <div className="cell odds">{lblBr(pageState.current.lastOddsLbl)}</div>
    </div>;
}

const FixedOdds = ( {sel, odds, isEnable, isPre, quickBet, quickBetId, pageState, betType, context} ) => {
    const { t } = useTranslation();
    const { handleClickCombOdds } = useQuickBet()
    let tmpHtml = [];
    if (["LOSE", "ELIMINATED"].includes(sel.combStatus))
        return (isPre ? "---" : t('LB_RC_FO_LOSE'));
    else if (["REFUND", "REFUNDBEFORESUSPEND"].includes(sel.combStatus))
        return (isPre ? "---" : t('LB_RC_FO_RFD'));
    else if (pageState.current.hideOdds)
        return ("---");
    else if (!isPre && (sel.combStatus!="AVAILABLE"))
        return ("---");
    else if ( odds!=null && odds!="" && isEnable)
        return <a key={`fo${betType}_${sel.number}`} className='cp' onClick={() =>quickBet && handleClickCombOdds({...sel,quickBetId},context?.content?.page)}>{odds}</a>;
    else {
        return odds;
    }
}

const Rows = ({foPool, betType, mtg, pageState, FOSelectedBet, handleClickOddsCheckbox, isMobileSize, i18n, t, context}) => {
    let stage = getBetStage(foPool);
    foPool.selections.sort((x,y) => x.order - y.order);
    foPool.otherSelections.sort((x,y) => {
        if ( x.order === y.order ) {
            return x.name_en < y.name_en ? -1 : 1;
        }
        return x.order - y.order;
    });
    return foPool.selections.map((sel, i) => {
        let cbDisabled = !['SELLING_STARTED', 'SELLINGSTARTED'].includes(foPool.status.toUpperCase()) || foPool.sellStatus!="Available" || sel.combStatus!="AVAILABLE"  ;

        let id = `${betType}#${mtg.id}#${foPool.poolId}#${sel.combId}`
        return <div className={i % 2 === 0 ? 'row sec' : 'row'}>
            <div className="cell sel">{sel.number}</div>
            <div className="cell jockey"><FoName t={t} betType={betType} foPool={foPool} num={sel.number} code={sel.code} name={sel["name_"+i18n.language]} /></div>
            {stage==3 || stage==4 ? <div className="cell srides"><SRide t={t} betType={betType} foPool={foPool} sel={sel} oSels={foPool.otherSelections} sRides={sel.scheduleRides} /></div> : null}
            {stage==3 || stage==4 ? <div className="cell oodds"><FixedOdds pageState={pageState} betType={betType} context={context} sel={sel} odds={sel.openOdds} isEnable={false} isPre={true} /></div> : null}
            {stage==5 ? <div className="cell srides"><Points sel={sel} /></div> : null}
            {stage==5 ? <div className="cell srides"><SRide t={t} betType={betType} foPool={foPool} sel={sel} oSels={foPool.otherSelections} sRides={sel.remainingRides} /></div> : null}
            <div className="cell podds"><FixedOdds pageState={pageState} betType={betType} context={context}  sel={sel} odds={sel.prevOdds} isEnable={false} isPre={true} /></div>
            <div className={`cell ${pageState.current.showCheckbox ? 'oddschk' : 'podds'}`}>
                {pageState.current.showCheckbox ?
                <div className="rc-checkbox" >
                    <CheckBox checked={FOSelectedBet?.[id]?.checked} disabled={cbDisabled} onChange={()=>handleClickOddsCheckbox(id, sel)} id={`${betType}_${sel.number}`}/>
                </div> : null}
                <div className='odds-number' id={`odds_${betType}_${sel.number}`}><FixedOdds pageState={pageState} betType={betType} context={context} sel={sel} odds={sel.currentOdds} isEnable={!cbDisabled} isPre={false} quickBet={!isMobileSize} quickBetId={id}/></div>
            </div>

        </div>
    });
}
export default FOList;