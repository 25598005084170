import React,{ useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toDateFormat, hasConfirmedResult } from './Common';
import { TournImage } from './MatchBase';
import remarkImg from '../../../info/Include/images/sms_failed.svg';
import { NewWindow } from '../../Common/home-common';
import Sticky from 'react-stickynode';
import { useWindowSize } from '../..//Common/CommonHooks';
import { FootballContext } from '../../Home/FootballPage';

const getScore =(match, stage) => {
    let res = match.results?.filter(item=> item.resultType==1 && item.resultConfirmType>1 && item.stageId===stage && item.payoutConfirmed)
                .sort((r1, r2) => r2.sequence - r1.sequence)[0]
    if ( res == null || res.homeResult<0 || res.awayResult<0 )
        return '-';
    return res.homeResult + " : " + res.awayResult;
}

const hasCorner =(match, stage) => {
    let res = match.results?.filter(item=> item.resultType==2 && item.resultConfirmType>1 && item.stageId===stage && item.payoutConfirmed)[0];
    return res!=null;
}

const getCorner =(match, stage) => {
    let res = match.results?.filter(item=> item.resultType==2 && item.resultConfirmType>1 && item.stageId===stage && item.payoutConfirmed)[0];
    if ( res==null || res.homeResult<0 || res.awayResult<0 )
        return '-';
    return res.homeResult + res.awayResult;
}

export const enableDetailsButton = (match) => {
    const payoutRefundPools = match.poolInfo.payoutRefundPools?.filter(item => [...window.fbConfig.ResultDetailPools, /* 'CHL', 'FCH', 'ECH' */].includes(item)) || []
    const refundPools = match.poolInfo.refundPools?.filter(item => ['CHL', 'FCH', 'ECH'].includes(item)) || []
    return hasConfirmedResult(match) || payoutRefundPools.length>0 || refundPools.length > 0;
}

export const filterMatchData = (match, t, i18n) => {
    const hName = match.homeTeam["name_"+i18n.language];
    const aName = match.awayTeam["name_"+i18n.language];
    const isVoid = match.status.indexOf("VOIDED")>=0;
    const halfTime = getScore(match, 3);
    const fullTime = getScore(match, 5);
    const extraTime = getScore(match, 9);
    return {
        eventId: match.frontEndId!=null && match.frontEndId!='' ? match.frontEndId : `${t('LB_' + match.matchDayOfWeek + '_SHORT')} ${match.matchNumber}`,
        hName: hName,
        aName: aName,
        h2h: `${hName} ${t('LB_FB_VS')} ${aName}`,
        matchDate: toDateFormat(match.kickOffTime, true, false),
        isVoid: isVoid,
        halfTime: halfTime,
        fullTime: fullTime,
        extraTime: extraTime,
        halfVoid: isVoid && halfTime==='-',
        fullVoid: isVoid && halfTime!=='-' && fullTime==='-',
        extraVoid: isVoid && halfTime!=='-' && fullTime!=='-',
        tournCode: match.tournament?.code,
        tournName: match.tournament?.["name_"+i18n.language],
        enableDtlsBtn: enableDetailsButton(match),
        matchId: match.id,
        halfCorner: getCorner(match, 3),
        fullCorner: getCorner(match, 5),
        extraCorner: getCorner(match, 9),
        abandonedScore: getScore(match, 100),
        abandonedCornerResult: getCorner(match, 100),
        hasHalfTimeCorner: hasCorner(match, 3),
        hasFullTimeCorner: hasCorner(match, 5),
        hasExtraTimeCorner: hasCorner(match, 9),
        hasAbnCornerResult: hasCorner(match, 100)
    }
}

const getESScore =(match, stage) => {
    let res = match.results?.filter(item=> item.resultType==1 && item.stageId===stage)
                .sort((r1, r2) => r2.sequence - r1.sequence)[0]
    if ( res == null || res.homeResult<0 || res.awayResult<0 )
        return '-';
    return res.homeResult + " : " + res.awayResult;
}

const hasESCorner =(match, stage) => {
    let res = match.results?.filter(item=> item.resultType==2 && item.stageId===stage)[0];
    return res!=null;
}

const getESCorner =(match, stage) => {
    let res = match.results?.filter(item=> item.resultType==2 && item.stageId===stage)[0];
    if ( res==null || res.homeResult<0 || res.awayResult<0 )
        return '-';
    return res.homeResult + res.awayResult;
}

export const filterESMatchData = (match, t, i18n) => {
    const halfTime = getESScore(match, 3);
    const fullTime = getESScore(match, 5);
    const extraTime = getESScore(match, 9);
    return {
        eventId: match.frontEndId!=null && match.frontEndId!='' ? match.frontEndId : `${t('LB_' + match.matchDayOfWeek + '_SHORT')} ${match.matchNumber}`,
        halfTime: halfTime,
        fullTime: fullTime,
        extraTime: extraTime,
        halfCorner: getESCorner(match, 3),
        fullCorner: getESCorner(match, 5),
        extraCorner: getESCorner(match, 9),
        abandonedScore: getESScore(match, 100),
        abandonedCornerResult: getESCorner(match, 100),
        hasHalfTimeCorner: hasESCorner(match, 3),
        hasFullTimeCorner: hasESCorner(match, 5),
        hasExtraTimeCorner: hasESCorner(match, 9),
        hasAbnCornerResult: hasESCorner(match, 100)
    }
}

export const MatchInfo = ({match, isResultPage}) => {
    const {t, i18n} = useTranslation()
    const {
        eventId,
        hName,
        aName,
        fullTime,
        extraTime,
        tournCode,
        tournName,
    } = filterMatchData(match, t, i18n)

    let hScore = '';
    let aScore = '';
    let score = extraTime!='-' && !isResultPage ? extraTime : fullTime;
    if ( score!='-' ) {
        [hScore, aScore] = score.split(':');
    }
    return <div className='results-match-info'>
        <div  className='frontendid'>{t('LB_FB_EVENT_ID')}: {eventId}</div>
        <div className='team-score'>
            <span>{hName}</span>
            <div ><span>{hScore}</span><span>{aScore}</span></div>
            <span>{aName}</span>
        </div>
        <div>
            <span className='frontendid-m'>{eventId}</span>
            <TournImage code={tournCode} name={tournName} />
            <span>{tournName}</span>
        </div>
    </div>
}

const handleClickRemark = (t) => {
    const url = t('LB_FB_RESULT_REMARK2_LINK')
    NewWindow(url, '', 770, 500, 1, 1)
}

export const ResultHeader = ({onClickSearch, openSearchBox}) => {
    const {t} = useTranslation()
    const {isMobileSize} = useWindowSize();
    return <Sticky innerZ={800} enabled={isMobileSize}>
        <div className='fb-results-header'>
            <span>{t('LB_FB_RESULT_TITLE_M')}</span>
            {onClickSearch ? <div className={`searchBtnContainer ${openSearchBox? 'expanded': 'collapsed'}`}>
                <div className='icon-search icon-search-new' onClick={onClickSearch}></div>
            </div>
            : null
            }
        </div>
    </Sticky>
}

export const ResultFooter = () => {
    const {t} = useTranslation()
    return <div className='fb-results-footer'>
        <div>
            <img src={remarkImg} alt='' />
        </div>
        <div>
            <span>
                {t('LB_FB_RESULT_REMARK1')}
            </span>
            <span className='payout-time-link' onClick={() => { handleClickRemark(t) }}>
                {t('LB_FB_RESULT_REMARK2')}
            </span>
            <span>
                {t('LB_FB_RESULT_REMARK3')}
            </span>
        </div>
    </div>
}

export const MatchCountLabel = ({ total, pageNum, maxPerPage }) => {

    const { t } = useTranslation();
    const context = useContext(FootballContext);
    const MaxPaginationLimit = maxPerPage || context.content.config.MaxPaginationLimit;
    const startIdx = ((pageNum - 1) * MaxPaginationLimit) + 1;
    const endIdx = ((pageNum - 1) * MaxPaginationLimit) + MaxPaginationLimit > total ? total : ((pageNum - 1) * MaxPaginationLimit) + MaxPaginationLimit;

    return <div>
        {
            t('LB_FB_MATCH_COUNT_RESULTS').replace('{0}', startIdx).replace('{1}', endIdx).replace('{2}', total)
        }
    </div>;
}