import React from 'react';
import { gql } from '@apollo/client';

export function getOffsetTimeQuery() {
    return gql`
    query footballData {
        timeOffset {
          fb
        }
    }
    `;
  }