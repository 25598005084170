import moment from 'moment';
import { useApolloClient } from '@apollo/client';
import { getFoPoolQuery } from '../DataQuery';

const useLoadFoPool = (contentRef, setContent) => {
    const { query } = useApolloClient();

    const loadFoPool = (oddsTypes) => {
        return Promise.all([query({ 
            query : getFoPoolQuery(),
            variables: {
                "date": contentRef.current.date,
                "venueCode": contentRef.current.venue,
                "foOddsTypes": oddsTypes
            },
            fetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: true 
        })])
        .then(([results]) => {
            if(results.errors){
                throw new Error(results.errors?.[0]?.message)
            }
            setFoPoolDataToContent(results);
        });
    }

    const setFoPoolDataToContent = (queryResult) => {
        if(!queryResult || !queryResult.data) return
        let foData = queryResult.data?.raceMeetings?.[0]?.foPools || [];
        foData.forEach(foPool => {
            const idx = contentRef.current.meeting.foPools.findIndex(x=> x.oddsType===foPool.oddsType && x.instNo===foPool.instNo);
            if ( idx > -1 ) {
//                let oldTime = moment(contentRef.current.meeting.foPools[idx].lastUpdateTime);
//               let newTime = moment(foPool.lastUpdateTime);
//               if ( oldTime < newTime ) {
                    contentRef.current.meeting.foPools[idx] = foPool;
//                }
            }
            else {
                contentRef.current.meeting.foPools.push(foPool);
                contentRef.current.meeting.foPools.sort((x,y) => parseInt(y.instNo) - parseInt(x.instNo) )
            }
        });
        setContent({ ...contentRef.current });
    }

    return {
        loadFoPool
    }
}

export default useLoadFoPool