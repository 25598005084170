import { createFoSelection } from "./createFoSelection";

export const parseFoOdds = (foPool, message) => {
    message.cmb?.forEach(cmb => {
        let foSel = foPool.selections?.filter(x=> x.combId===cmb.id)[0];
        if( !foSel ) {
            foSel = createFoSelection();
            foSel.combId = cmb.id;
            foSel.number = parseInt(cmb.str);
            foPool.selections.push(foSel);
        }
        foSel.openOdds = cmb.opn.odds?cmb.opn.odds:"";
        if ( cmb.prev ) {
            foSel.prevOdds = cmb.prev.odds;
        }
        foSel.currentOdds = cmb.cur.odds;
        foSel.combStatus = cmb.st.toUpperCase();
        foSel.lineId = message.lineId;
    });
}