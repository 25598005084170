import { useQuery, useLazyQuery, useApolloClient } from '@apollo/client';
import { useContext, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
    getSitecoreMenuQuery,
    getSitecoreMenuCartQuery,
    getProductInfoBlockQuery,
    getProductTabQuery,
    getWhatsNewQuery,
    getRGPBannerQuery,
    getRacingFOUserContentQuery,
    getBetSlipMsgQuery,
    getEarlySettlementMsgQuery,
    getLogoutBoxQuery,
    getSitecoreVariables,
    getMarksixFixturesQuery,
    getEwinUsefulLinksQuery,
    getFPSBannerQuery,
    getSilkColorQuery,
    getSilkColorVariables,
    getFBImportantNoticesQuery,
    getSurveyQuery
} from './DataQuery';
import useQueryString from '../../Common/hooks/useQueryString';
import { isMenuWithinDate, isMenuOverDate } from '../../Menu/MenuCommon';
import { headerMenuDataEn, headerMenuDataCh, mobileBottomMenuCh, mobileBottomMenuEn, defaultProductTabEn, defaultProductTabCh } from './localData';
import { MyBetSlipContext } from '../../BetSlip/BetSlipHooks';

import { topMenuData } from './topMenu';
import { leftMenuData } from './leftMenu';
import { RGPBannerData } from './RGPBanner';
import { BetslipMessage } from './BetslipMessage';
import { ewinUsefulLinks } from './ewinUsefulLinks';
import { rsFoUserContent } from './rsFoUserContent';

export const useProductMenuQuery = (prod) => {
    const { i18n } = useTranslation();
    let { loading, error, data } = useQuery(getSitecoreMenuQuery(), {
        variables: getSitecoreVariables(prod, 'ProductMenu', i18n.language),
        context: { clientName: 'sitecore' },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onError: (err) => {
        }
    });

    if (!loading) {
        if (error) {
            data = topMenuData[i18n.language][prod].data;
            error = false;
        }
    }

    return [loading, data?.item?.children || []];
};

export const useOddsMenuQuery = (prod) => {
    const { i18n } = useTranslation();
    const queryString = useQueryString();
    const query = prod=="football" ? getSitecoreMenuCartQuery() : getSitecoreMenuQuery();
    let { loading, error, data } = useQuery(query, {
        variables: getSitecoreVariables(prod, 'OddsMenu', i18n.language),
        context: { clientName: 'sitecore' },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onError: (err) => {
        }
    });

    if (!loading) {
        if (error) {
            data = leftMenuData[i18n.language][prod];
            error = false;
        }
    }

    const getData = () => {
        let newItems = [];
        if (!loading) {
            let dt = queryString.get('dt');
            let tItem = queryString.get('item');
            let items = data?.item?.children || [];
            if ( prod=="football" ) {
                if (window.globalConfig.IS_PREVIEW && tItem) {
                    items = items.filter(x=> x.key===tItem && x?.enable?.value==="1")[0]?.list?.children || [];
                }
                else {
                    items = items.filter(x=> x?.enable?.value==="1")[0]?.list?.children || [];
                }
            }
            let dtDate = moment();
            if (window.globalConfig.IS_PREVIEW && dt) {
                dtDate = moment(dt, 'YYYYMMDDHHmm');
            }
            items.filter((menu) => {
                if (isMenuOverDate(menu, dtDate)) {
                    newItems.push(menu);
                    let chds = menu.children.filter((cItem) => {
                        return isMenuOverDate(cItem, dtDate);
                    });
                    menu.children = chds;
                }
            });
        }
        return newItems;
    };

    return [loading, getData(), error];
};

export const use2ndLvlMenuQuery = () => {
    const { i18n } = useTranslation();
    let { loading, error, data } = useQuery(getSitecoreMenuQuery(), {
        variables: getSitecoreVariables('', '2ndLvlMenu', i18n.language),
        context: { clientName: 'sitecore' },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onError: (err) => {
        }
    });

    if (!loading) {
        if (error) {
            if (i18n.language === 'en') {
                data = headerMenuDataEn.data;
            } else {
                data = headerMenuDataCh.data;
            }
            error = false;
        }
    }

    return [loading, data?.item?.children || []];
};

export const useMobileBottomMenuQuery = () => {
    const { i18n } = useTranslation();
    let { loading, error, data } = useQuery(getSitecoreMenuQuery(), {
        variables: getSitecoreVariables('', 'mobileBottomMenu', i18n.language),
        context: { clientName: 'sitecore' },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onError: (err) => {
        }
    });

    if (!loading && error) {
        if (i18n.language === 'en') {
            data = mobileBottomMenuEn.data;
        } else {
            data = mobileBottomMenuCh.data;
        }
    }

    return [loading, data?.item?.children || []];
};

export const useProductInfoBlockQuery = (product) => {
    const { i18n } = useTranslation();
    const queryString = useQueryString();
    const query = product=='ewin' ? getWhatsNewQuery() : getProductInfoBlockQuery();
    let { loading, data } = useQuery(query, {
        variables: getSitecoreVariables(product, 'ProductInfoBlock', i18n.language),
        context: { clientName: 'sitecore' },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onError: (err) => {
        }
    });

    const getRandomNews = () => {
        let arr = [];
        if (!loading) {
            let dt = queryString.get('dt');
            let tItem = queryString.get('item');
            let items = data?.item?.children || [];
            if (window.globalConfig.IS_PREVIEW && tItem) {
                items = items.filter(x=> x.key===tItem && x?.enable?.value==="1")[0]?.list?.children || [];
            }
            else {
                items = items.filter(x=> x?.enable?.value==="1")[0]?.list?.children || [];
            }
            let dtDate = moment();
            if (window.globalConfig.IS_PREVIEW && dt) {
                dtDate = moment(dt, 'YYYYMMDDHHmm');
            }
            items.forEach((item) => {
                const fromDt = item.fromDate.value.startsWith('0001') ? dtDate : moment(item.fromDate.value);
                if (item.enable.value === '1' && isMenuWithinDate(item, dtDate)) {
                    item.section?.items?.forEach((sec) => {
                        arr.push({
                            key: sec.key,
                            prod: sec.name.value,
                            dt: fromDt.format('DD/MM/YYYY'),
                            item: item
                        });
                    });
                }
            });
        }
        return arr;
    };

    const getData = () => {
        let newItems = [];
        if (product !== 'ewin' && !loading) {
            let dt = queryString.get('dt');
            let tItem = queryString.get('item');
            // deep clone array
            let items = data?.item?.children || [];
            let dtDate = moment();
            if (window.globalConfig.IS_PREVIEW && dt) {
                dtDate = moment(dt, 'YYYYMMDDHHmm');
            }
            items = items.filter((menu) => {
                if (isMenuWithinDate(menu, dtDate)) {
                    let cloneMenu = {...menu};
                    newItems.push(cloneMenu);
                    let childs = cloneMenu.children;
                    if ( cloneMenu.key=="Int_OnlineFootballLive" ) {
                        let newItem = [];
                        let targetItems = cloneMenu.children.filter(x=>x.key==="OnlineFootballLiveSelection")[0]?.children || [];
                        if (window.globalConfig.IS_PREVIEW && tItem) {
                            newItem = targetItems.filter(x=> x.key===tItem && x?.enable?.value==="1")[0]?.list?.children || [];
                        }
                        else {
                            newItem = targetItems.filter(x=> x?.enable?.value==="1")[0]?.list?.children || [];
                        }
                        childs = newItem;
                    }
                    let chds = childs.filter((cItem) => {
                        return isMenuWithinDate(cItem, dtDate);
                    });
                    cloneMenu.children = chds;
                }
            });
        }
        return newItems;
    };

    return [loading, getData(), getRandomNews];
};



export const useProductTabQuery = () => {
    const { isSpeedbet } = useContext(MyBetSlipContext);
    const { i18n } = useTranslation();
    const host = location.host;
    let { loading, error, data } = useQuery(getProductTabQuery(), {
        variables: getSitecoreVariables(
            isSpeedbet ? 'speedbet' : 'mass',
            'ProductTab',
            i18n.language
        ),
        context: { clientName: 'sitecore' },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onError: (err) => {
        }
    });

    if (!loading && error) {
        if (i18n.language === 'en') {
            data = defaultProductTabEn;
        } else {
            data = defaultProductTabCh;
        }
    }

    return [loading, data?.item?.children ||  []];
};

export const useRGPBannerQuery = () => {
    const { i18n } = useTranslation();
    let { loading, error, data } = useQuery(getRGPBannerQuery(), {
        variables: getSitecoreVariables('', 'rgpBanner', i18n.language),
        context: { clientName: 'sitecore' },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onError: (err) => {
        }
    });

    if (!loading) {
        if (error) {
            data = RGPBannerData[i18n.language].data;
            error = false;
        }
    }

    return [loading, data?.item || []];
};

export const useRacingFOUserContentQuery = () => {
    const { i18n } = useTranslation();
    const { query } = useApolloClient();
    const [data, setData] = useState({});

    const loadFOUserContent = () => {
        Promise.all([query({ 
            query : getRacingFOUserContentQuery(),
            variables: getSitecoreVariables('', 'rsFoUserContent', i18n.language),
            context: { clientName: 'sitecore' },
            fetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: true 
        })])
        .then(([results]) => {
            if(!results || !results.data) { setData(rsFoUserContent[i18n.language].data) };
            setData({...results.data});
        });
    }

    const getTemplate = (betType) => {
        return data?.item?.children?.filter((x) => x.enable.value === '1' && x.betType.value === betType)[0];
    };

    const getTemplateByName = (betType, templateName) => {
        return data?.item?.children?.filter(
            (x) => x.key === templateName && x.enable.value === '1' && x.betType.value === betType
        )[0];
    };

    return {
        loadFOUserContent,
        data: data?.item?.children || [],
        getTemplate,
        getTemplateByName,
        error : null
    };
};

export const useBetSlipMsgQuery = () => {
    const { i18n } = useTranslation();
    let { loading, error, data } = useQuery(getBetSlipMsgQuery(), {
        variables: getSitecoreVariables('', 'betslipMsg', i18n.language),
        context: { clientName: 'sitecore' },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onError: (err) => {
        }
    });

    if (!loading) {
        if (error) {
            data = BetslipMessage[i18n.language].data;
            error = false;
        }
    }

    const getBetSlipLoginTnC = () => {
        let textData = '';
        if (!loading) {
            let msgObj = data?.item?.children?.filter(
                (x) => x.enable.value === '1' && x.key === window.sitecoreVars.BETSLIP_TNC
            )[0];
            if (msgObj) {
                textData = msgObj.msg.value;
            }
        }
        return textData;
    };

    const getLinkBankTnC = () => {
        let textData = '';
        if (!loading) {
            let msgObj = data?.item?.children?.filter(
                (x) => x.enable.value === '1' && x.key === window.sitecoreVars.LINKBANK_TNC
            )[0];
            if (msgObj) {
                textData = msgObj.msg2.value;
            }
        }
        return textData;
    };

    return {
        msgLoading: loading,
        getBetSlipLoginTnC,
        getLinkBankTnC,
        msgData: data?.item?.children || []
    };
};

export const useFBImportantNoticesQuery = () => {
    const { i18n } = useTranslation();
    let { loading, error, data } = useQuery(getFBImportantNoticesQuery(), {
        variables: getSitecoreVariables('', 'fbImportantNotices', i18n.language),
        context: { clientName: 'sitecore' },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onError: (err) => {
        }
    });

    if (!loading) {
        if (error) {
            data = { item: { children: [ ] } };
            error = false;
        }
    }

    return {
        loading,
        betTypeMessages: data?.item?.children || []
    };
};

export const useEarlySettlementMsgQuery = () => {
    const { i18n } = useTranslation();
    let { loading, error, data } = useQuery(getEarlySettlementMsgQuery(), {
        variables: getSitecoreVariables('', 'earlySettlementMsg', i18n.language),
        context: { clientName: 'sitecore' },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onError: (err) => {
        }
    });

    if (!loading) {
        if (error) {
            data = { item: { msg: { value: "" }, enable: { value: "" } } };
            error = false;
        }
    }

    return {
        msgLoading: loading,
        msgData: data?.item || []
    };
};

export const useLogoutBoxQuery = () => {
    const { i18n } = useTranslation();
    let [loadLogoutBox, { msgLoading, data }] = useLazyQuery(getLogoutBoxQuery(), {
        variables: getSitecoreVariables('', 'logoutBox', i18n.language),
        context: { clientName: 'sitecore' },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onError: (err) => {
        }
    });

    return {
        msgLoading,
        loadLogoutBox,
        textEnable: data?.item?.enable?.value === '1',
        text: data?.item?.text?.value || '',
        imageEnable: data?.item?.imageEnable?.value === '1',
        image: data?.item?.image
    };
};

export const useMarksixFixturesQuery = () => {
    const { i18n } = useTranslation();
    const { loading, data } = useQuery(getMarksixFixturesQuery(), {
        variables: getSitecoreVariables('', 'm6Fixtures', i18n.language),
        context: { clientName: 'sitecore' },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onError: (err) => {
        }
    });

    const getMonthList = () => {
        let list = {};
        if (!loading) {
            data?.item?.years.forEach((item) => {
                let year = item.year;
                item.months.forEach((mItem) => {
                    let month = mItem.month.value;
                    list[year + month.padStart(2, '0')] = 1;
                });
            });
        }
        return Object.keys(list).sort();
    };

    const getDatesByYearAndMonth = (item) => {
        let year = item?.substring(0, 4);
        let month = parseInt(item?.substring(4, 6));
        if (!loading) {
            return data?.item?.years
                .filter((x) => x.year === year)[0]
                .months.filter((x) => parseInt(x.month.value) === month)[0];
        }
        return [];
    };

    const getNext4Draw = () => {
        if (!loading) {
            let list = [];
            let today = moment();
            let nowMonth = parseInt(today.format('YYYYMM'));
            data?.item?.years.forEach((yItem) => {
                let year = yItem.year;
                yItem.months.forEach((mItem) => {
                    let month = mItem.month.value;
                    if (nowMonth <= parseInt(`${year}${month.padStart(2, '0')}`)) {
                        mItem.dates.date.forEach((dItem) => {
                            let dt = moment(`${year}${month.padStart(2, '0')}${dItem.value}`, 'YYYYMMDD');
                            if (dt.isSameOrAfter(today, 'day')) list.push(dt);
                        });
                    }
                });
            });
            return list.slice(0, 4);
        }
        return [];
    };

    const getNextSnowballDraw = () => {
        if (!loading) {
            let list = [];
            let today = moment();
            let nowMonth = parseInt(today.format('YYYYMM'));
            data?.item?.years.forEach((yItem) => {
                let year = yItem.year;
                yItem.months.forEach((mItem) => {
                    let month = mItem.month.value;
                    if (nowMonth <= parseInt(`${year}${month.padStart(2, '0')}`)) {
                        mItem.snowballs.date.forEach((dItem) => {
                            let dt = moment(`${year}${month.padStart(2, '0')}${dItem.value}`, 'YYYYMMDD');
                            if (dt.isSameOrAfter(today, 'day')) list.push(dt);
                        });
                    }
                });
            });
            return list.slice(0, 4);
        }
        return [];
    };

    return {
        loading,
        months: getMonthList(),
        getDatesByYearAndMonth,
        getNext4Draw,
        getNextSnowballDraw,
        legends: data?.legends || [],
        data: data?.item || []
    };
};

export const useEwinUsefulLinksQuery = () => {
    const { i18n } = useTranslation();
    let { loading, error, data } = useQuery(getEwinUsefulLinksQuery(), {
        variables: getSitecoreVariables('', 'ewinUsefulLinks', i18n.language),
        context: { clientName: 'sitecore' },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onError: (err) => {
        }
    });

    if (!loading) {
        if (error) {
            data = ewinUsefulLinks[i18n.language].data;
            error = false;
        }
    }

    const getRacingEosMesssage = () => {
        if (!loading) {
            return data?.racingEos?.message?.value || "";
        }
        return "";
    };

    const getUsefulLinksItems = (product) => {
        if (!loading) {
            return data?.item?.children.filter((x) => x.enable.value === '1' && x.product.value === product);
        }
        return [];
    };

    return {
        loading,
        getUsefulLinksItems,
        getRacingEosMesssage,
        data: data?.item?.children || []
    };
};

export const useFPSBannerQuery = () => {
    const { i18n } = useTranslation();
    const { loading, data } = useQuery(getFPSBannerQuery(), {
        variables: getSitecoreVariables('', 'fpsBanner', i18n.language),
        context: { clientName: 'sitecore' },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onError: (err) => {
        }
    });

    return [loading, data?.item?.children?.[0] || {}];
};

export const useLocalSilkColorQuery = (prod) => {
    const { i18n } = useTranslation();
    let [loadColor, { loading, data }] = useLazyQuery(getSilkColorQuery(), {
        context: { clientName: 'sitecore' },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onError: (err) => {
        }
    });

    const getLocalSilkColor = (code) => {
        loadColor({ variables: getSilkColorVariables(code, i18n.language) });
    };

    return [getLocalSilkColor, data?.item?.color?.src];
};

export const useGetSurveyQuery = () => {
    const { i18n } = useTranslation();
    let { data } = useQuery(getSurveyQuery(), {
        variables: getSitecoreVariables('', 'survey', i18n.language),
        context: { clientName: 'sitecore' },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onError: (err) => {
        }
    });


    return { survey: data?.survey || {} };
};
