import { parseTopic } from './parseTopic';
import { parseNewTourn } from './parseNewTourn';
import { parseNewMatch } from './parseNewMatch';
import { parseNewMatchPool } from './parseNewMatchPool';
import { parseNewTournPool } from './parseNewTournPool';
import { parseUpdTourn } from './parseUpdTourn';
import { parseUpdMatchPool } from './parseUpdMatchPool';
import { parseUpdTournPool } from './parseUpdTournPool';
import { parseUpdMatch } from './parseUpdMatch';
import { parseUpdMatchAdm } from './parseUpdMatchAdm';
import { parseUpdMatchRes } from './parseUpdMatchRes';

export const parseMessage = (topic, message, contentRef, loadMatchQuery, mQueue, option) => {
    try{
        let topicInfo = parseTopic(topic, contentRef);
         console.debug("topicinfo ",topicInfo, "message",message);
         let msgCacheId
         let msgCacheType

        if(topicInfo.touNew){
            parseNewTourn(message, topicInfo, contentRef);
        }
        else if(topicInfo.matNew){
            parseNewMatch(message, topicInfo, contentRef);
            msgCacheId = message.id
            msgCacheType = 'matNew'
        }
        else if(topicInfo.pooNew){
            parseNewMatchPool(message, topicInfo, contentRef);
            msgCacheId = topicInfo.matchid
            msgCacheType = 'pooNew'
        }
        else if(topicInfo.chpPooNew){
            if(!contentRef.current.config.TOURNAMENT_PAGE.includes(contentRef.current.page)) {
                parseNewMatchPool(message, topicInfo, contentRef);
            }
            else{
                parseNewTournPool(message, topicInfo, contentRef);
            }
        }
        else if(topicInfo.touUpd){
            parseUpdTourn(message, topicInfo, contentRef);
        }
        else if(topicInfo.poolUpd){
            parseUpdMatchPool(message, topicInfo, contentRef, loadMatchQuery, option);
            msgCacheId = topicInfo.matchid
            msgCacheType = 'poolUpd'
        }
        else if(topicInfo.chpPooUpd){
            if(!contentRef.current.config.TOURNAMENT_PAGE.includes(contentRef.current.page)){
                parseUpdMatchPool(message, topicInfo, contentRef, loadMatchQuery, option);
            }
            else {
                parseUpdTournPool(message, topicInfo, contentRef, option);
            }
        }
        else if(topicInfo.matchUpd){
            parseUpdMatch(message, topicInfo, contentRef, loadMatchQuery, mQueue);
            msgCacheId = topicInfo.matchid
            msgCacheType = 'matchUpd'
        }
        else if(topicInfo.matchAdminUpd){
            parseUpdMatchAdm(message, topicInfo, contentRef);
        }
        else if(topicInfo.matchWagUpd){
            parseUpdMatchRes(message, topicInfo, contentRef);
        }
    }
    catch (e) {
        console.error(e, e.stack);
    }
    
}