import React, { useContext, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NewWindow } from '../../Common/home-common'

const begins = 'begins', preview = 'preview', loading = 'loading', successful = 'successful', rejected = 'rejected', unknow = 'unknow', suspended = 'suspended';

const CashOutImportantNotice = ({ handleClickCashOutAll, isLoading, tabShowData, esApiError, esStates }) => {
    const { t } = useTranslation()
    const [isCanClick, setIsCanClick] = useState(true);
    const isDimStatus = [successful, suspended]

    // useEffect(() => {
    //     let checkBtn = false;
    //     if (tabShowData?.length > 0) {
    //         const checked = tabShowData.find((item) => { 
    //         const status = esStates?.[item.uniqueTicketId] || '';
    //             return  !isDimStatus.includes(status) 
    //         });
    //         if (checked) { checkBtn = true; }
    //     }
    //     setIsCanClick(checkBtn);
    // }, [tabShowData])

    const handleClickImportantNoticeLink = () => {
        let url = t('LB_CASHOUT_IMPO_NOTICE_URL');
        NewWindow(url, '', 770, 500, 1, 1);
    }

    return <div className='cashOutImportantNotice'>
        <div className='importantNoticeLeft'><span className='importantNoticeTitle'>{t('LB_CASHOUT_IMPO_NOTICE')}</span><br />
            {t('LB_CASHOUT_IMPO_NOTICE1')}
            <span className='importantNoticeLink' onClick={handleClickImportantNoticeLink}>{t('LB_CASHOUT_IMPO_NOTICE2')}</span>
            {t('LB_CASHOUT_IMPO_NOTICE3')}
        </div>
        {/*<div className='importantNoticeRight'>
            <div className={`cashOutAllButton ${isLoading || !isCanClick || esApiError ? 'dimBg' : ''}`} onClick={() => !isLoading && isCanClick && !esApiError && handleClickCashOutAll()}>{t('LB_CASHOUT_ALL_BTN')}</div>
        </div> */}
    </div>
}

export default CashOutImportantNotice