export const createFoSelection = () => {
    return {
        "order": -1,
        "number": -1,
        "code": "",
        "name_en": "",
        "name_ch": "",
        "scheduleRides": -1,
        "remainingRides": -1,
        "points": -1,
        "lineId": "",
        "combId": "",
        "combStatus": "",
        "openOdds": "",
        "prevOdds": "",
        "currentOdds": "",
        "results": []
    }
}

export const createFoOtherSelection = () => {
    return {
        "order": 0,
        "code": "",
        "name_en": "",
        "name_ch": "",
        "scheduleRides": -1,
        "remainingRides": -1,
        "points": -1,
        "results": []
    }
}