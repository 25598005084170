import { useQuery } from '@apollo/client';
import { getTeamListQuery } from '../DataQuery';
// import { useEffect, useState} from 'react';
// import { Query } from '../../../Common/graphqlFetch'

const useGetTeamList = () => {
    // const [data, setData] = useEffect({teamList:[]})
    // const [loading, setLoading] = useEffect(true)
    let { loading, error, data } = useQuery(getTeamListQuery(), {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onError: (err) => {
        }
    });
    if (!loading) {
        if (error) {
            data = { teamList: [] };
            error = false;
        }
    }

    // useEffect(()=>{
    //     Query({
    //         query: getTeamListQuery(),

    //     }).then(results=>{
    //         if(results.data){

    //             setData(results.data)
    //         }
    //     }).catch(err =>{

    //     })
    // },[])


    return {
        loading,
        teamList: data?.teamList || []
    };
}

export default useGetTeamList