import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const  FBSearchButton = ({active, isMobile, onClickSearchBtn,
    inputVal, clearSearchResults, onInputVal, searchResults,
    onBlurInput, onFocusInput, inputFocus, onClickTeamName,onKeyDownEnterKey,
    showSearchHistory, onClickDeleteBtn, showInputBox}) =>{
    const {t} = useTranslation()
    const currentSearchText = inputVal?.trim()
    return  <div className={`fbSearchButton ${active? 'active' : ''} ${showInputBox ? 'showInput': ''}`} >
            <div className={`searchBtn`} onClick={onClickSearchBtn}>
                <div className='icon-search'></div> {!isMobile && <span>{t('LB_FB_SEARCH')}</span>}
            </div>
            {active && isMobile ?
            <div className='section-SEARCH-m'>
                <input type='text' value={inputVal} className='fbSearchInput' onInput={onInputVal} onKeyDown={onKeyDownEnterKey} placeholder={t('LB_FB_SEARCH_TEAM')} onBlur={onBlurInput} onFocus={onFocusInput}></input>
                {currentSearchText && showInputBox && <div className='msgInboxClose clearSearchResultBtn' onClick={(e)=> clearSearchResults()}></div>}
                {( currentSearchText || showSearchHistory ) && inputFocus && <div className='searchResultsList'>
                    {
                        searchResults?.map(item =>{
                            const val = hlSearchResultText(currentSearchText, item.label)
                            return <div className='searchResultsListItem' onClick={() => onClickTeamName(item)} key={item.label}>
                                <div className={item.isHistory ? 'icon-history' : 'icon-search-s'}></div>
                                <div dangerouslySetInnerHTML={{__html: val}} ></div>
                                {item.isHistory  && <div className='msgInboxClose' onClick={(e)=> onClickDeleteBtn(item, e)}></div>}
                            </div>
                        })
                    }
                    {searchResults.length === 0 && <div className='searchResultsListItem noSearchResultsListItem'>{t('LB_FB_SEARCH_NOFOUND')}</div>}
                </div>}
            </div>
            : null}
    </div>
}

export default FBSearchButton

export const FBSearchInput = ({searchText,onInputSearchText,
    onBlurSearchInput, onFocusSearchInput, searchResults,onKeyDownEnterKey,
    inputFocus, onClickTeamName, showSearchHistory,clearSearchResults,
    onClickDeleteBtn }) =>{

    const {t} = useTranslation()
    const currentSearchText = searchText?.trim()

    return (<section className='section-SEARCH'>
    <div>
        <input value={searchText}
        type='text'
         className='fbSearchInput'
        onInput={onInputSearchText}
        placeholder={t('LB_FB_SEARCH_TEAM')}
        onBlur={onBlurSearchInput}
        onKeyDown={onKeyDownEnterKey}
        onFocus={onFocusSearchInput}/>
        {currentSearchText && <div className='msgInboxClose clearSearchResultBtn' onClick={(e)=> clearSearchResults()}></div>}
        {( currentSearchText || showSearchHistory ) && inputFocus && <div className='searchResultsList'>
                {
                    searchResults?.map(item =>{
                        const val = hlSearchResultText(currentSearchText, item.label)
                        return <div className='searchResultsListItem' onClick={()=> onClickTeamName(item)} key={item.label}>
                            <div className={item.isHistory  ? 'icon-history' : 'icon-search-s'}></div>
                            <div dangerouslySetInnerHTML={{__html: val}}></div>
                            {item.isHistory && <div className='msgInboxClose' onClick={(e)=> onClickDeleteBtn(item, e)}></div>}
                        </div>
                    })
                }
                {searchResults.length === 0 && <div className='searchResultsListItem noSearchResultsListItem'>{t('LB_FB_SEARCH_NOFOUND')}</div>}
            </div>}
    </div>
</section>)
}

const  escape = (str) => {
    str = str.replace(/&/g, '&amp;')
    str = str.replace(/</g, '&lt;')
    str = str.replace(/>/g, '&gt;')
    str = str.replace(/"/g, '&quot;')
    str = str.replace(/'/g, '&#39;')
    str = str.replace(/`/g, '&#96;')
    str = str.replace(/\//g, '&#x2F;')
    return str
}

// Highlight text
export const hlSearchResultText = (str, label) =>{
    str = str ? escape(str) : str
    label = label ? escape(label) : label
    if(!label || !str) return label
    str = str.trim()
    str = str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
    const reg = new RegExp(str, 'ig')
    return  label.replace(reg, (match)=>`<span class='fbSearchHL'>${match}</span>`)
}

export const NoMatchResult = ({searchAllMatch}) =>{
    const {t} = useTranslation()
    return <div className='searchNoMatchResult'>
        <div className='searchNoMatchResult-content'>{t('LB_FB_SEARCH_NORESULT_MSG1')} <div>{t('LB_FB_SEARCH_NORESULT_MSG2')}</div></div>
        <div className='searchNoMatchResult-button' onClick={searchAllMatch}>{t('LB_FB_SEARCH_NORESULT_BUTTON')}</div>
    </div>
}

