import React, { useCallback, useContext, useEffect, useState } from 'react';
import { onClickCheckBox } from './AddBet';
import { Coupon, ShowAllBtn, sortTournOdds } from '../Common/TournamentTable';
import { useTranslation } from 'react-i18next';
import { FootballContext } from '../../Home/FootballPage';
import { toDateFormat, formatOdds, isEliminated, isRefund, isSellingStarted } from './Common';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useWindowSize } from '../../Common/CommonHooks';
import { getOddsArrowCss } from './MatchBase';
import CheckBox from '../../Common/ui-components/CheckBox';

const GPWTable = ({singleTourn}) => {

    const { t, i18n } = useTranslation();
    const context = useContext(FootballContext);
    const [expand, setExpand] = useState(true);
    const [showAll, setShowAll] = useState(false);
    const [checkboxState, setCheckboxState] = useState([]);
    const foPools = singleTourn.foPools.filter(x=> x.oddsType==="GPW");
    foPools.sort((x,y) => { return x.instNo - y.instNo });

    const toggle = () => {
        setExpand(!expand)
    }

    const toggleShowAll = () => {
        setShowAll(!showAll)
    }



    const { isMobileSize } = useWindowSize();
    const columnsCountBreakPoints = isMobileSize ? { 350: 1, 1024: 3 } : { 350: 3 }

    const tableProps = { showAll, singleTourn, context, setCheckboxState }

    return <section className="GPW">
        <Coupon title={`${t('LB_FB_TITLE_GPW')} ${singleTourn.frontEndId}`} handleOnClick={toggle} isChecked={expand} showHelpBtn={true} showAllupTxt={true} betType="GPW" frontEndId={singleTourn.frontEndId}/>
        { expand ? <>
            <ShowAllBtn isShowAll={showAll} handleOnClickShowAll={toggleShowAll} />
            <ResponsiveMasonry columnsCountBreakPoints={columnsCountBreakPoints} style={{ margin: "12px 0px" }}>
                <Masonry className="masonry-col">
                    {foPools.map(foPool => {
                        return <GPWSingleTable foPool={foPool} {...tableProps}/>
                    })}
                </Masonry>
            </ResponsiveMasonry>
        </> : null }
    </section>

}
export default GPWTable;

const GPWSingleTable = ({foPool, showAll, singleTourn, context, setCheckboxState}) => {
    const {t, i18n} = useTranslation()
    let combs = foPool.lines[0].combinations;
    combs?.sort(sortTournOdds);

    let cssIdx = 0;

    return <div className="gpwSingleTable">
        <div className="gpwTitle">{`${foPool["name_" + i18n.language]} / ${t('LB_FB_GPW_GROUPNO')}: ${foPool.instNo}`}</div>
        <div className="gpwEsst">
            <div className="gpwEsstLbl">{t('LB_FB_CHP_TABLE_DATE')}</div>
            <div>{toDateFormat(foPool.expectedSuspendDateTime, true, true)}</div>
        </div>
        <div className="gpwSelections">
            <div className="gpwSelHeader">{t('LB_FB_CHP_TEAM')}</div>
            <div className="gpwSelHeader"></div>
            <div className="gpwSelHeader">{t('LB_ODDS')}</div>
            {combs.map(comb => {
                let odds = comb.currentOdds;
                let show = showAll || !isEliminated(comb);
                let cssStr = cssIdx%2==0 ? '': 'even';
                if ( show )
                    cssIdx++;
                let isDisabled = isRefund(comb) || isEliminated(comb) || !isSellingStarted(singleTourn, foPool, foPool.lines[0]) || comb.status!="AVAILABLE";
                let id = 'GPW_' + foPool.lines[0].lineId + "_" + comb.combId  + "_"  + foPool.id + "_" + comb.str;
                let curState = context.content.oddsSelection.filter(x => x.id == id)[0];
                curState = {...curState,
                    id: id,
                    checked: curState?.checked || false,
                    oddsVal: odds,
                    matchId: singleTourn.id
                }
                return show ? <>
                    <div className={`gpwSelName ${cssStr}`}>{comb.selections[0].str} {comb.selections[0]['name_'+i18n.language]}</div>
                    <div className={`gpwSelChkbox ${cssStr}`}>
                        <CheckBox product='football' id={`fb_${id}`} onClick={() => onClickCheckBox(curState, isDisabled, context, setCheckboxState)} defaultChecked={curState.checked} disabled={isDisabled} />
                        <span onClick={() => onClickCheckBox(curState, isDisabled, context, setCheckboxState)} className={`${id} ${isDisabled && 'dim'}`}></span>
                    </div>
                    <div className={`gpwSelOdds ${cssStr} oddsColor ${getOddsArrowCss(curState)}`}>
                        {formatOdds(t, odds, comb.status)}
                        <div className={`oddsArrow ${getOddsArrowCss(curState)}`}></div>
                    </div>
                </> : null
            })}
        </div>
    </div>
}