import React from 'react';
import MatchTable from '../Common/MatchTable';
import { Remark } from '../Common/MatchBase';

const FCH = () => {
    return (
        <section className='FCH'>
            <MatchTable betType='FCH' />
            <Remark />
        </section>
    )
}
export default FCH;