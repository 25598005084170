import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ESCombination, CashOutNoData } from './CashOutBase';
import { textFormatAmountFn } from '../../Common/GlobalFunc';
import { FootballContext } from '../FootballPage';
import { getNumberSuffix,getIsCrossPoolAllup } from '../../Football/Common/MatchBase';

const TableHeader = () => {
    const { t } = useTranslation();

    return <div className='table-row table-header'>
        <div className='table-cell text-center'>
            {t('LB_CASHOUT_REF_NO_S')}
        </div>
        <div className='table-cell text-center'>
            {t('LB_CASHOUT_DATE_TIME')}
        </div>
        <div className='table-cell '>
            {t('LB_CASHOUT_BET_TYPE')}
        </div>
        <div className='table-cell table-cell-team'>
            {t('LB_CASHOUT_TRANSACTION_DETAIL')}
        </div>
        <div className='table-cell '>
            {t('LB_CASHOUT_DEBIT')}
        </div>
        <div className='table-cell'>
            {t('LB_CASHOUT_CREDIT')}
        </div>
    </div>
}

const ESLoadMoreButtonUI = (props) => {
    const { t, i18n } = useTranslation();
    const { data = {}, isMoreButton, showSettledMoreBtn, onClickSettledMoreButton } = props;
    return isMoreButton && <div className="ESLoadMoreButton" onClick={(e) => onClickSettledMoreButton(data.accountTranNo, e)}>
        {t(showSettledMoreBtn[data.accountTranNo] ? 'LB_FB_SHOW_LESS' :'LB_FB_SHOW_ALL')}
    </div>
}

const goalStr = (leg) => {
    const { t, i18n } = useTranslation();
    const context = useContext(FootballContext);
    let goalStr = "";
    if (context.content.config.NTSPools.includes(leg.pool.oddsType)) {
        let ngoal = `${leg.pool.instNo}${i18n.language=="en" ? getNumberSuffix(leg.pool.instNo) : ''}`;
        goalStr = ` (${t('LB_FB_N_GOAL').replace('{0}', ngoal)})`;
    }
    return goalStr
}

const TableRow = (props) => {
    const { data = {}, showSettledMoreBtn } = props;
    const { t, i18n } = useTranslation();
    const context = useContext(FootballContext);
    const isAllup = data.allUpFormula && data.allUpFormula != ''
    let isCrossPoolAllup = getIsCrossPoolAllup(data)
    const isMoreButton = data.legs.length > 1
    let CrossPoolAllupName= t(isCrossPoolAllup ? 'LB_RBC_ALUP' : 'LB_ALLUP')
    let typeName = t(`LB_FB_TITLE_${data.legs[0].pool.oddsType}`)
    let legs = showSettledMoreBtn[data.accountTranNo] ? data.legs : data.legs.slice(0,1);
    
    return <div className='table-row table-body'>
        <div className='table-cell text-center'>
            {data.accountTranNo}
        </div>
        <div className='table-cell table-cell-time'>
            {data.dateTime}
        </div>
        <div className='table-cell '>
            {isAllup ? `${CrossPoolAllupName} ${data.allUpFormula}` : `${typeName}${goalStr(legs[0])}` }
        </div>
        <div className='table-cell table-cell-details'>
            {isAllup && `${CrossPoolAllupName} ${data.allUpFormula}` }
            {legs.map((item,index)=>{
                let goalStr = "";
                if (context.content.config.NTSPools.includes(item.pool.oddsType)) {
                    let ngoal = `${item.pool.instNo}${i18n.language=="en" ? getNumberSuffix(item.pool.instNo) : ''}`;
                    goalStr = ` (${t('LB_FB_N_GOAL').replace('{0}', ngoal)})`;
                }
                return <div className='bet-details' key={item.eventId}>
                    <span>{item.match.frontEndId}</span>
                    <span>{`${item.match.homeTeam["name_" + i18n.language]} ${t('LB_FB_VS')} ${item.match.awayTeam["name_" + i18n.language]}`}</span>
                    <span>{t(`LB_FB_TITLE_${data.legs[index].pool.oddsType}`)}{goalStr}</span>
                    <span><ESCombination item={data} legNo={index} /></span>
                    
                </div>
            })}
            <ESLoadMoreButtonUI {...props} isMoreButton={isMoreButton}/>
            <span>{t('LB_CASHOUT')}</span>
        </div>
        <div className='table-cell '>
            ${textFormatAmountFn(data.ticketCostAmt / 100,2)}
        </div>
        <div className='table-cell'>
            ${textFormatAmountFn(data.esValue,2)}
        </div>
    </div>
}

const TableMobileRow = (props) => {
    const { data = {}, showSettledMoreBtn } = props;
    const { t, i18n } = useTranslation();
    const context = useContext(FootballContext);
    const isAllup = data.allUpFormula && data.allUpFormula != ''
    let isCrossPoolAllup = getIsCrossPoolAllup(data)
    const isMoreButton = data.legs.length > 1
    let CrossPoolAllupName= t(isCrossPoolAllup ? 'LB_RBC_ALUP' : 'LB_ALLUP')
    let typeName = t(`LB_FB_TITLE_${data.legs[0].pool.oddsType}`)
    let legs = showSettledMoreBtn[data.accountTranNo] ? data.legs : data.legs.slice(0,1);

    return <div className='table-m'>
        <div className='table-header-m'>
            <span>{t('LB_CASHOUT_REF_NO')}</span>
            <span>{data.accountTranNo}</span>
        </div>
        <div className='table-body-m'>
            <div className='table-body-item'>
                <div className='item-title'>{t('LB_CASHOUT_DATE_TIME')}</div>
                <div className='item-content'>{data.dateTime}</div>
            </div>
            <div className='table-body-item'>
                <div className='item-title'>{t('LB_CASHOUT_BET_TYPE')}</div>
                <div className='item-content'>{isAllup ? `${CrossPoolAllupName}${data.allUpFormula}` : `${typeName}${goalStr(legs[0])}` }</div>
            </div>
            <div className='table-body-item'>
                <div className='item-title'>{t('LB_CASHOUT_TRANSACTION_DETAIL')}</div>
                <div className='item-content item-details'>
                    {legs.map((item,index)=>{
                        let goalStr = "";
                        if (context.content.config.NTSPools.includes(item.pool.oddsType)) {
                            let ngoal = `${item.pool.instNo}${i18n.language=="en" ? getNumberSuffix(item.pool.instNo) : ''}`;
                            goalStr = ` (${t('LB_FB_N_GOAL').replace('{0}', ngoal)})`;
                        }
                        return <div className='bet-details' key={item.eventId}>
                            <span>{item.match.frontEndId}</span>
                            <span>{`${item.match.homeTeam["name_" + i18n.language]} ${t('LB_FB_VS')} ${item.match.awayTeam["name_" + i18n.language]}`}</span>
                            <span>{t(`LB_FB_TITLE_${data.legs[index].pool.oddsType}`)}{goalStr}</span>
                            <span><ESCombination item={data} legNo={index} /></span>
                        </div>
                    })}
                    <ESLoadMoreButtonUI {...props} isMoreButton={isMoreButton}/>
                    <span>{t('LB_CASHOUT')}</span>
                </div>
            </div>
            <div className='table-body-item'>
                <div className='item-title'>{t('LB_CASHOUT_DEBIT')}</div>
                <div className='item-content'>${textFormatAmountFn(data.ticketCostAmt / 100,2)}</div>
            </div>
            <div className='table-body-item'>
                <div className='item-title'>{t('LB_CASHOUT_CREDIT')}</div>
                <div className='item-content'>${textFormatAmountFn(data.esValue,2)}</div>
            </div>
        </div>

    </div>
}

const CashOutSettled = (props) => {
    const { isMobileSize, esApiError, jumpUrl, curTab } = props
    const { t } = useTranslation()
    const esSettleds = props.esSettleds;
    
    return <>
        {esSettleds.length===0 ? <CashOutNoData {...props} />
        :
            esApiError ? <div className='CashOutNoData'>
                <div className='CashOutNoDataMsg'>{t('LB_BS_LOGIN_ERROR_OTHER')}</div>
            </div>
            :
            isMobileSize ? <div className='cashOutSettledTable-m'>
                {esSettleds.map(item => { return <TableMobileRow {...props} key={item.accountTranNo} data={item} /> })}
            </div> : <div className='cashOutSettledTable'>
                <TableHeader />
                {esSettleds.map(item => { return <TableRow {...props} key={item.accountTranNo} data={item} /> })}
            </div>
        }
    </>
}

export default CashOutSettled