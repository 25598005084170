export const fbMenuData = {
    "data": {
      "timeOffset": {
        "fb": 0
      },
      "fullTourns": [],
      "chpTypes": [],
      "tournaments": [],
      "tournamentResult": [],
      "featuredMatches": []
    }
  }