export const createFoPool = (topicInfo) => {
    return {
        "instNo":  topicInfo.insNo,
        "poolId": "",
        "oddsType": topicInfo.oddsType,
        "status": "",
        "sellStatus": "",
        "otherSelNo": -1,
        "inplayUpTo": -1,
        "expStartDateTime": "",
        "expStopDateTime": "",
        "raceStopSellNo": 0,
        "raceStopSellStatus": null,
        "includeRaces": [],
        "excludeRaces": [],
        "lastUpdateTime": "",
        "selections": [],
        "otherSelections": []
    }
}