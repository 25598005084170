import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { postOtpCodeGenerate, postOtpCodeConfirm, postEddaSetup } from '../FundsTransferAPI';
import { postOtpCodeGenerate_IB, postEddaSetup_IB, postOtpCodeConfirm_IB } from '../FundsTransferIBAPI';
import { ConfigContext } from '../../../Common/ConfigHelper';
import { getAuthorize, getAccessToken } from '../../BetSlipAPI';
import { setTokenToSession } from '../../LoginHooks';
import useBroadcastChannel from '../../../Common/hooks/useBroadcastChannel';
import { maskPhoneNumber } from '../utils';

const LinkOTP = ({ props }) => {
    const { betslipMenu } = useContext(ConfigContext);
    const [otp, setOtp] = useState();
    const [msgCounter, setMsgCounter] = useState(0);
    const [canResendOTP, setCanResendOTP] = useState(false);
    const [showOTPMsg, setShowOTPMsg] = useState(false);
    const [isWrongOTP, setIsWrongOTP] = useState(false);

    const { t, i18n } = useTranslation();
    const {
        nbaData,
        setCurStep,
        selectedItem,
        setLoadingStatus,
        setLinkResult,
        setResultShowStatus,
        init,
        otpConfirmData,
        setOtpConfirmData,
        isReturnErrorCode,
        otpCounter
    } = props;
    const [otpCountdown, setOtpCountdown] = useState(otpCounter);
    const broadcast_resetlogin = useBroadcastChannel('resetLoginTimeout');

    let mobileNo = sessionStorage.getItem('mobile_country')
    ? `+${sessionStorage.getItem('mobile_country')}-${maskPhoneNumber(sessionStorage.getItem('mobile_num'))}`
    : t('LB_BS_FT_LINK_NO_MOBILE');
    const respMobileNo=otpConfirmData?.DoOTPCodeGenerateTRResult?.find((item) => {
        return item.Key == 'phoneNo';
    }).Value;
    if(respMobileNo){
        const respMobileNoList=respMobileNo.split('-');
        mobileNo = `${respMobileNoList[0]}-${maskPhoneNumber(respMobileNoList[1])}`;
    }


    useEffect(() => {
        if (document.getElementById('otpInput0')) {
            document.getElementById('otpInput0').focus();
        }
    }, [isWrongOTP]);

    setTimeout(() => {
        if (otpCountdown > 0) {
            setCanResendOTP(false);
            setOtpCountdown(otpCountdown - 1);

            if (showOTPMsg) {
                if (otpCounter <= betslipMenu?.OTPResendSeconds - betslipMenu?.OTPMsgDisplaySeconds) {
                    setShowOTPMsg(false);
                }
            }
        } else {
            setCanResendOTP(true);
        }
    }, 1000);

    const onKeyUpOtp = (e) => {
        const curValue = document.getElementById(e.target.id).value;
        if (e.key == 'Backspace') {
            if (curValue.length <= 0) {
                movePrevOtp(e);
            } else {
                document.getElementById(e.target.id).value = '';
            }
            e.preventDefault();
        } else if (e.key == 'ArrowRight') {
            moveNextOtp(e);
        } else if (e.key == 'ArrowLeft') {
            movePrevOtp(e);
        } else if (['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].includes(curValue)) {
            document.getElementById(e.target.id).value = curValue;
            moveNextOtp(e);
        } else {
            document.getElementById(e.target.id).value = '';
        }

        const otpValue = () => {
            let v = '';
            for (var i = 0; i < 6; i++) {
                v += document.getElementById('otpInput' + i).value;
            }
            return v;
        };
        setOtp(otpValue);
        e.preventDefault();
    };

    const moveNextOtp = (e) => {
        const id = e.target.id;
        const index = id.charAt(id.length - 1);
        const nextId = document.getElementById('otpInput' + (parseInt(index) + 1));
        if (nextId !== null) {
            nextId.focus();
        }
    };

    const movePrevOtp = (e) => {
        const id = e.target.id;
        const index = id.charAt(id.length - 1);
        const prevId = document.getElementById('otpInput' + (parseInt(index) - 1));
        if (prevId !== null) {
            prevId.focus();
        }
    };

    const secondsToTime = () => {
        const minutes = Math.floor((otpCountdown % 3600) / 60)
            .toString()
            .padStart(2, '0');
        const seconds = Math.floor((otpCountdown % 3600) % 60)
            .toString()
            .padStart(2, '0');
        return minutes + ':' + seconds;
    };

    const onClickSendOTP = async () => {
        if (otpCountdown > 0) {
            return;
        } else {
            const result = await resendOTP();
            if (
                window.globalConfig.IS_IB == false &&
                result?.authId &&
                result?.callbacks
                    .find((item) => item.type == 'HiddenValueCallback')
                    ?.input[0]?.value?.includes('createAt')
            ) {
                setOtpConfirmData(result);
                setOtpCountdown(otpCounter);
                setShowOTPMsg(true);
            } else if (window.globalConfig.IS_IB == true) {
                const resultCode = result.DoOTPCodeGenerateTRResult.find((e) => e.Key == 'result')?.Value;
                if (resultCode == '0') {
                    setOtpConfirmData(result);
                    setOtpCountdown(otpCounter);
                    setShowOTPMsg(true);
                } else {
                    if (isReturnErrorCode(resultCode)) {
                        return;
                    }
                }
            }
        }
    };

    const resendOTP = async () => {
        const resendResult = window.globalConfig.IS_IB
            ? await postOtpCodeGenerate_IB(i18n.language, nbaData.link.config.timeout)
            : await postOtpCodeGenerate();
        broadcast_resetlogin?.postMessage(false);
        return resendResult;
    };

    const onClickNext = async () => {
        setLoadingStatus(true);
        const confirmOTPResult = await confirmOTP();
        setOtp('');
        if (isReturnErrorCode(sessionStorage.getItem('result'))) {
            return;
        }
        if (confirmOTPResult == true) {
            const eddaResult = await setupEdda();
            if (isReturnErrorCode(sessionStorage.getItem('result'))) {
                return;
            }
            setLinkResult({ ...eddaResult, isLinkSingleLeg: selectedItem.isSingleLeg });
            setLoadingStatus(false);
            setCurStep(3);
        } else {
            setIsWrongOTP(true);
            setResultShowStatus('UNSUCCESSFUL');
            setLoadingStatus(false);
        }
        //init();
    };

    const confirmOTP = async () => {
        if (window.globalConfig.IS_IB) {
            let otpCode = otp;
            const result = await postOtpCodeConfirm_IB(otpCode);
            broadcast_resetlogin?.postMessage(false);
            if (result?.result == 0) {
                return true;
            }
            return false;
        } else {
            const callback0 = otpConfirmData?.callbacks?.[0];
            const callback1 = otpConfirmData?.callbacks?.[1];

            callback1.input[0].value = otp;
            const body = { ...otpConfirmData, callbacks: [callback0, callback1] };

            const result = window.globalConfig.IS_IB
                ? await postOtpCodeConfirm_IB(body)
                : await postOtpCodeConfirm(body);
            broadcast_resetlogin?.postMessage(false);

            if (result?.tokenId) {
                const authorize = await getAuthorize(result.tokenId, 'JCBW_EHS');
                if (authorize) {
                    let code = '';
                    if (authorize.url) {
                        const tempUrl = new URL(authorize.url);

                        const queryParams = new URLSearchParams(tempUrl.search);
                        code = queryParams.get('code');
                    }
                    const accessToken = await getAccessToken(code, 'JCBW_EHS');
                    if (accessToken?.access_token) {
                        setTokenToSession({
                            accessToken: accessToken.access_token,
                            refreshToken: accessToken.refresh_token,
                            idToken: accessToken.id_token,
                            login_account: ''
                        });
                        return true;
                    }
                }
            }
            return false;
        }
    };

    const setupEdda = async () => {
        const params = {
            fpsChannelID: 104,
            bankAccountType: selectedItem.bankAccountType,
            bankAccountNo: nbaData.link[selectedItem.nba].bankAccountNo,
            bankCode: nbaData.link[selectedItem.nba].bankInfo.code,
            mobileNo: respMobileNo
        };

        let eDDAResult;
        if (window.globalConfig.IS_IB) {
            eDDAResult = await postEddaSetup_IB({
                params,
                timeout: nbaData.link.config.timeout,
                isSingleLeg: selectedItem?.isSingleLeg
            });
        } else {
            eDDAResult = await postEddaSetup({
                params,
                timeout: nbaData.link.config.timeout,
                isSingleLeg: selectedItem?.isSingleLeg
            });
        }
        broadcast_resetlogin?.postMessage(false);
        return eDDAResult;
    };

    const onClickDone = () => {
        setResultShowStatus('');
        setIsWrongOTP(false);
    };

    return (
        <div>
            {isWrongOTP ? (
                <>
                    <div className="FT-form-content">
                        <div className="link-otp-summary">{t('LB_BS_FT_LINK_OTP_WRONG')}</div>
                    </div>
                    <div className="deposit-btnArea link-bottom">
                        <div className="deposit-btn doneBtn link-btn-active" onClick={onClickDone}>
                            {t('LB_BETSLIP_DONE')}
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className={showOTPMsg ? 'link-otp-resend-msg' : 'link-hide'}>
                        {t('LB_BS_FT_LINK_OTP_RESEND_OK')}
                    </div>
                    <div className="FT-form-content">
                        <div className="link-otp-title">{t('LB_BS_FT_LINK_OTP_MOBILE')}</div>
                        <div className="link-otp-mobile">{mobileNo}</div>
                        <div className="link-otp-cc">{t('LB_BS_FT_LINK_OTP_CC')}</div>
                        <div className="link-otp-code">{t('LB_BS_FT_LINK_CODE')}</div>
                        <div className="link-otp-cod-ph">
                            <div className="link-otp-otpCode-container">
                                <input
                                    id="otpInput0"
                                    className="link-otp-otpCode"
                                    maxLength="1"
                                    type="number"
                                    onKeyUp={onKeyUpOtp}
                                />
                            </div>
                            <div className="link-otp-otpCode-container">
                                <input
                                    id="otpInput1"
                                    className="link-otp-otpCode"
                                    maxLength="1"
                                    type="number"
                                    onKeyUp={onKeyUpOtp}
                                />
                            </div>
                            <div className="link-otp-otpCode-container">
                                <input
                                    id="otpInput2"
                                    className="link-otp-otpCode"
                                    maxLength="1"
                                    type="number"
                                    onKeyUp={onKeyUpOtp}
                                />
                            </div>
                            <div className="link-otp-otpCode-container">
                                <input
                                    id="otpInput3"
                                    className="link-otp-otpCode"
                                    maxLength="1"
                                    type="number"
                                    onKeyUp={onKeyUpOtp}
                                />
                            </div>
                            <div className="link-otp-otpCode-container">
                                <input
                                    id="otpInput4"
                                    className="link-otp-otpCode"
                                    maxLength="1"
                                    type="number"
                                    onKeyUp={onKeyUpOtp}
                                />
                            </div>
                            <div className="link-otp-otpCode-container">
                                <input
                                    id="otpInput5"
                                    className="link-otp-otpCode"
                                    maxLength="1"
                                    type="number"
                                    onKeyUp={onKeyUpOtp}
                                />
                            </div>
                        </div>
                        <div className="link-otp-rec">{t('LB_BS_FT_LINK_NO_CODE')}</div>
                        <div className="link-otp-resend">
                            <span
                                className={canResendOTP ? 'link-otp-resend-ok' : 'link-otp-resend-no'}
                                onClick={onClickSendOTP}
                            >
                                {t('LB_BS_FT_LINK_OTP_RESEND')}
                            </span>
                            <span className={canResendOTP ? 'link-hide' : 'link-otp-timer'}>({secondsToTime()})</span>
                        </div>
                    </div>
                    <div className="deposit-btnArea">
                        <div
                            className={
                                otp?.length == 6 ? 'deposit-btn link-btn-active' : 'deposit-btn link-btn-nonActive'
                            }
                            onClick={() => otp?.length == 6 && onClickNext()}
                        >
                            {t('LB_BS_FT_NEXT')}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default LinkOTP;
