import React from 'react';
import MatchTable from '../Common/MatchTable';
import { Remark } from '../Common/MatchBase';

const OOE = () => {
    return (
        <section className='OOE'>
            <MatchTable betType='OOE' />
            <Remark />
        </section>
    )
}
export default OOE;