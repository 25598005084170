import { useEffect } from 'react';
const debounce = (fn, delay) => {
	let timer = null;
	return function () {
		let context = this;
		let args = arguments;
		clearTimeout(timer);
		timer = setTimeout(function () {
			fn.apply(context, args);
		}, delay);
	};
};

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
	constructor(callback) {
		callback = debounce(callback, 16);
		super(callback);
	}
};

const useResizeObserver = (query, callback, timeRef) => {
    useEffect(() => {
        const dom = document.querySelector(query/* '.multi-leg-3x-table' */)
        const doSomething = () => {
            // console.warn('no callback')
        }
        const observe = new ResizeObserver(callback || doSomething);
        dom && observe.observe(dom);
        return () => {
            observe.disconnect()
            // console.log(`HandleMultiLeg3xTablesTrHeight setTimeout disconnect`, timeRef.current)
            timeRef?.current && clearTimeout(timeRef.current)
        };
    })
};

export default useResizeObserver;
