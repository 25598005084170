import useResizeObserver from '../../Common/hooks/useResizeObserver';
import { useWindowSize } from '../../Common/CommonHooks';
import setTableTrSameHeight from '../../Common/utils/setTableTrSameHeight';
import setTableSameTrCount from '../../Common/utils/setTableSameTrCount';
import { useRef } from 'react';

const HandleMultiLeg3xTablesTrHeight = () => {
    const { width, isMobileSize } = useWindowSize();
    const timeRef = useRef();
    // // console.log(`HandleMultiLeg3xTablesTrHeight ----------`)
    const callback = () => {
        if (isMobileSize) return;
        // clearTimeout(timeRef.current)
        // timeRef.current = setTimeout(() => {
        //     const dom = document.querySelector('.multi-leg-3x-table');
        //     if (dom) {
        //         let tables = dom.querySelectorAll('.rc-odds-table-compact');
        //         if (tables) {
        //             let group = 3;
        //             if (width < 1280) {
        //                 group = 2;
        //             }
        //             let tmpTables = [];
        //             for (let i = 0; i < tables.length; i++) {
        //                 tmpTables.push(tables[i]);
        //                 if ((i + 1) % group === 0) {
        //                     // setTableSameTrCount([...tmpTables], true, 'rc-odds-row-m');
        //                     setTableTrSameHeight([...tmpTables]);
        //                     tmpTables = [];
        //                 }
        //             }
        //             if (tmpTables.length !== 0) {
        //                 // setTableSameTrCount([...tmpTables], true, 'rc-odds-row-m');
        //                 setTableTrSameHeight([...tmpTables]);
        //             }
        //         }
        //     }
        // },0);
        const dom = document.querySelector('.multi-leg-3x-table');
        if (dom) {
            let tables = dom.querySelectorAll('.rc-odds-table-compact');
            if (tables) {
                let group = 3;
                if (width < 1280) {
                    group = 2;
                }
                let tmpTables = [];
                for (let i = 0; i < tables.length; i++) {
                    tmpTables.push(tables[i]);
                    if ((i + 1) % group === 0) {
                        // setTableSameTrCount([...tmpTables], true, 'rc-odds-row-m');
                        setTableTrSameHeight([...tmpTables]);
                        tmpTables = [];
                    }
                }
                if (tmpTables.length !== 0) {
                    // setTableSameTrCount([...tmpTables], true, 'rc-odds-row-m');
                    setTableTrSameHeight([...tmpTables]);
                }
            }
        }
    };
    useResizeObserver('.multi-leg-3x-table', callback, timeRef);
};

export default HandleMultiLeg3xTablesTrHeight;
