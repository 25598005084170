import { useContext, useRef } from 'react';
import { Query, abortFBMatch, apiErrorCallback } from '../../../Common/graphqlFetch'
import { useApolloClient } from '@apollo/client';
import { getMatchQuery, getMatchResultQuery, getFgsResultQuery, getQueryVariables } from '../DataQuery';
import { setMatchDataOddsDiff } from '../useFBDataRefresh/setRefreshDataOddsDiff';
import { parseMessage } from '../useFootballPush/parseMessage';
import { GlobalContext } from '../../../../StandardPage';
import { useParams } from "react-router-dom";
import {isFeaturedMatch} from '../../Common/Common'

const useGetMatches = (contentRef, setContent) => {
    const apolloClient = useApolloClient();
    const globalContext = useContext(GlobalContext);
    const { replayMsgQueue, isPushing } = globalContext.mqttClient;
    const params = useParams();
    const matchIdRef = useRef()
    matchIdRef.current = params.matchId

    const loadMatchQuery = async (paras, callbackFunc, option) => {
        globalContext.globalRef.current.isLoadingData = true;
        abortFBMatch()
        await Promise.all([Query({ 
            query : getMatchQuery(),
            variables: getQueryVariables(paras),
            controller: window.FBMatchController
        })])
        .then(([results]) => {
            if ( callbackFunc ) {
                callbackFunc(results, option,paras);
            }
            else {
                setMatchQueryDataToContent(results, option, paras);
            }
            globalContext.globalRef.current.isLoadingData = false;
        }).catch(error => apiErrorCallback(error, ()=>{ loadMatchQuery(paras, callbackFunc, option)}));
    }

    const setMatchQueryDataToContent = (queryResult, option={}, paras) => {
        if(!queryResult || !queryResult.data) {
            if(!option.refetchCount  || option.refetchCount < 1 ){
                let refetchCount = option.refetchCount ? option.refetchCount + 1 : 1
                loadMatchQuery(paras, null, {...option,refetchCount})
            }else{
                contentRef.current.isLoading = false;
                contentRef.current.isApiErr = false;
                contentRef.current.matchData = {matches: []}
            }
            return
        }
        contentRef.current.isLoading = false;
        contentRef.current.isApiErr = false;
        const isAllMatches = contentRef.current.selection.type === 'ALL' || (contentRef.current.selection.type === 'SEARCH' && contentRef.current.selection.value.length == 0)
        if(contentRef.current.pageNum > 1 && isAllMatches ){
            const newMatches = [...contentRef.current.matchData.matches]
            queryResult.data.matches.forEach((match)=>{
                if(!newMatches.some(i => i.id === match.id)){
                    newMatches.push(match)
                }
            })
            contentRef.current.matchData = {matches: newMatches};
            // setLoadMoreLoading(false)
        }else{
            let matches = [...queryResult.data.matches]
            if(matches.length === 0 && contentRef.current.config.AllOddsPages.includes(contentRef.current.page)){
                const match = contentRef.current.rawMatchList.find(m => m.id ===  matchIdRef.current)
                if(!contentRef.current.listData.matches.find(m => m.id ===  matchIdRef.current)&& match){
                    contentRef.current.listData.matches.push(match)
                }
                matches = match ?  [match] : []
            }
            if( contentRef.current.page === 'OFM' && !option.isNewFeatureMatch){
                matches = matches.filter(match => isFeaturedMatch(match, contentRef.current.offset))
            }
            contentRef.current.matchData = {matches : matches};
        }
        if(!option?.disabledMsgCache) replayMsgCache();
        setContent({ ...contentRef.current });
        // setOpenTournIdsCache(false)
    }

    const loadMatchResultQuery = (paras) => {
        globalContext.globalRef.current.isLoadingData = true;
        abortFBMatch()
        Promise.all([Query({ 
            query : getMatchResultQuery(),
            variables: paras,
            controller: window.FBMatchController
        })])
        .then(([results]) => {
            setMatchResultQueryDataToContent(results);
            globalContext.globalRef.current.isLoadingData = false;
        }).catch(error => apiErrorCallback(error, ()=>{loadMatchResultQuery(paras)}));
    }

    const setMatchResultQueryDataToContent = (queryResult) => {
        if(!queryResult || !queryResult.data) return;
        contentRef.current.isLoading = false;
        contentRef.current.isApiErr = false;
        contentRef.current.isSearching = false;
        contentRef.current.matchData = {...queryResult.data};
        setContent({ ...contentRef.current });
    }

    const loadFgsResultQuery = () => {
        abortFBMatch()
        Promise.all([Query({ 
            query : getFgsResultQuery(),
            controller: window.FBMatchController
        })])
        .then(([results]) => {
            setMatchResultQueryDataToContent(results);
        }).catch(error => apiErrorCallback(error, ()=>{loadFgsResultQuery()}));
    }

    const reloadMatchQuery = (paras) => {
        loadMatchQuery(paras, resetMatchQueryDataToContent);
    }

    const resetMatchQueryDataToContent = (queryResult, option={}) => {
        if(!queryResult || !queryResult.data) return
        contentRef.current.isLoading = false;
        setMatchDataOddsDiff(contentRef, queryResult.data.matches);
        contentRef.current.matchData = {matches : [...queryResult.data.matches]};
        setContent({ ...contentRef.current });
        if (window.globalConfig.FB_ODDS_PUSH && isPushing) replayMsgCache()
    }

    const replayMsgCache = () => {
        replayMsgQueue.current.forEach(({topic, message}) => {
            parseMessage(topic, message, contentRef, loadMatchQuery, [...replayMsgQueue.current], {isReplay: true});
        });
    }

    return {
        loadMatchQuery,
        loadMatchResultQuery,
        loadFgsResultQuery,
        reloadMatchQuery
    }
}

export default useGetMatches

