import React, { useState } from 'react';
import { OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';
import BtnExpand from '../../Common/ui-components/BtnExpand';
import style from './scss/common.module.scss';

const { 'expand-c': expandC, header, 'btn-expand': btnExpand, content } = style;

export const ExpandContainer = ({ title = 'title', children, className = '', defaultExpand = false }) => {
    const [isExpand, setIsExpand] = useState(defaultExpand);
    return (
        <div className={`${className} ${expandC} `}>
            <div className={header} onClick={() => setIsExpand(!isExpand)}>
                <BtnExpand className={btnExpand} isExpand={isExpand} />
                <span>{title}</span>
            </div>
            <div className={`${isExpand ? 'show' : 'hide'} ${content} `}>{children}</div>
        </div>
    );
};

const { footer, button, restore, save } = style;
export const Footer = ({ restoreFn, t }) => {
    return (
        <div className={footer}>
            <input type="button" onClick={restoreFn} className={`${button} ${restore}`} value={t('LB_BS_SET_RES')} />
            <OverlayTrigger
                overlay={
                    <Popover id="save-tooltip">
                        <Popover.Body>{t('LB_BS_SET_TIPS')}</Popover.Body>
                    </Popover>
                }
            >
                <input type="submit" className={`${button} ${save}`} value={t('LB_BS_SET_SAVE')} />
            </OverlayTrigger>
            {/* <input
                // title={t('LB_BS_SET_TIPS')}
                type="submit"
                className={`${button} ${save}`}
                value={t('LB_BS_SET_SAVE')}
            /> */}
        </div>
    );
};

export const Radio = ({ title = '', value = '', defaultValue = '', register, path }) => {
    return (
        <label className="radio-container">
            {title}
            <input
                {...register(path, {
                    value: defaultValue
                })}
                type="radio"
                value={value}
            />
            <span className="radio-checkmark" />
        </label>
    );
};
