import React from 'react';
import MatchTable from '../Common/MatchTable';
import { Remark } from '../Common/MatchBase';

const FHA = () => {
    return (
        <section className='FHA'>
            <MatchTable betType='FHA' />
            <Remark />
        </section>
    )
}
export default FHA;