import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

const EpscoPin = ({onChange}) => {
    const { t } = useTranslation()
    const [pin, setPin] = useState('')
    const [pinList, setPinList] = useState([])

    useEffect(()=> {
        setPinList(getRandomPin())
    }, [])

    useEffect(()=>{
        onChange && onChange(pin)
    },[pin])

    const getRandomPin = () => {
        const numList = []

        while(numList.length < 10){
            const randomNum = Math.floor(Math.random() *10)
            if(!numList.includes(randomNum)){
                numList.push(randomNum)
            }
        }
        return numList
    }

    const onInputPin = (value) => {
        const newPin = pin + value
        if(newPin.length <= 8){

            setPin(newPin)
        }
    }

    const backSpacePin = () =>{
        const newPin = pin.substring(0, pin.length -1)
        setPin(newPin)
    }

    const resetPin = () => {
        setPin('')
    }

    const value = pin.split('').map(i => '*').join('')

    return <>
    <div className='formItemContent-fwn'>{t('LB_BS_FT_EPSTIPS')}</div>
    <div className='epscoPinContainer'>
        <div className='epscoPinTop'>
            <div className='df epscoPinInput'>
                <div>{t('LB_BS_FT_EPSPIN_8DIGIT')}</div>
                <input readOnly value={value}></input>
            </div>
            <div onClick={backSpacePin} className='deposit-btn'>{t('LB_BS_FT_EPSPIN_BACKSPACE')}</div>
        </div>
        <div className='epscoPinBottom'>
            <div className='pinList df'>
                {pinList.map((num)=><div className='epscoPinNumberContainer' key={num}>
                    <div className='epscoPinNumber' onClick={()=> onInputPin(num)}>{num}</div>
                </div>)}
            </div>
            <div className='deposit-btn' onClick={()=> resetPin()}>{t('LB_BS_FT_EPSPIN_REINPUT')}</div>
        </div>
    </div>
    </>
}

export default EpscoPin