import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';


const useProductName = () => {
    const { pathname } = useLocation();
    return useMemo(() => {
        let strs = pathname.split('/').filter(Boolean);
        if ( strs.length > 1 ) {
            switch ( strs[1].toLowerCase() ) {
                case 'racing':
                case 'football':
                case 'marksix':
                    return strs[1].toLowerCase();
            }
        }
        return "ewin";
    });
};

export default useProductName;
