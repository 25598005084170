import { useApolloClient } from '@apollo/client';
import { getPmPoolQuery } from '../DataQuery';
import { setData } from './common';

const useLoadPmPool = (contentRef, setContent) => {
    const { query } = useApolloClient();

    const loadPmPool = (oddsTypes, allRaces) => {
        return Promise.all([query({ 
            query : getPmPoolQuery(),
            variables: {
                "date": contentRef.current.date,
                "venueCode": contentRef.current.venue,
                "raceNo": allRaces ? 0 : contentRef.current.raceNo,
                "oddsTypes": oddsTypes
            },
            fetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: true 
        })])
        .then(([results]) => {
            if(results.errors){
                throw new Error(results.errors?.[0]?.message)
            }
            setPmPoolDataToContent(results);
        });
    }

    const setPmPoolDataToContent = (queryResult) => {
        if(!queryResult || !queryResult.data) return
        let oddsData = queryResult.data?.raceMeetings?.[0]?.pmPools || [];
        oddsData.forEach(pmPool => {
            setData(contentRef, pmPool)
        });
        setContent({ ...contentRef.current });
    }

    return {
        loadPmPool
    }
}

export default useLoadPmPool