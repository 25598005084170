import React from 'react';

const LoginBtn = () => {

    const handleClickLoginBtn = () => {
        document.getElementById('betslip-container').style.display = 'inherit';
        document.getElementById("divBetSlipNormal").style.left = "calc(100vw - 90vw)";
        document.getElementById("betslip-over-lay").style.left = "0";
        document.body.style.overflow = "hidden";
    };

    return <div role='button' tabIndex='0' className="login-icon" onClick={handleClickLoginBtn} />;
};
export default LoginBtn;
