const getHeight = (dom) => {
    const tempHeight = dom.style.getPropertyValue('height');
    dom.style.removeProperty('height');
    const newHeight = dom.offsetHeight;
    dom.style.setProperty('height', tempHeight);
    return newHeight;
};
const setTableTrSameHeight = (tables) => {
    try {
        let minTableTrLen = Infinity;
        tables.forEach((table) => {
            if (minTableTrLen > table.childNodes.length) {
                minTableTrLen = table.childNodes.length;
            }
        });

        for (let i = 0; i < minTableTrLen; i++) {
            let maxHeight = 0;
            tables.forEach((table) => {
                const height = getHeight(table.childNodes[i]);
                if (maxHeight < height) {
                    maxHeight = height;
                }
            });

            tables.forEach((table) => {
                table.childNodes[i].style.setProperty('height', maxHeight + 'px');
            });
        }
    } catch(e) {
        console.error('setTableTrSameHeight Error')
    }

};

export default setTableTrSameHeight;
