import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../Common/CommonHooks';
import { SetBetSelect, IsBetSelected } from './RacingBetline';
import { RacingContext } from '../../Home/RacingPage';
import { useQuickBet } from './QuickBetHook'
import CheckBox from '../../Common/ui-components/CheckBox';
import Loading from '../../Common/ui-components/Loading';
import { isStartSellpmPool } from './Common';

const CWINTable = ({betType, raceNo, hideCheckbox, disableQuickBet}) => {

  const {t, i18n} = useTranslation();
  const { isMobileSize } = useWindowSize();
  const context = useContext(RacingContext);
  const { handleClickCombOdds } = useQuickBet()
  const mtg = context.content.meeting;
  const runners = mtg.races?.filter(item => item.no === raceNo)[0].runners;
  const pool = mtg.pmPools?.filter(item=> item.leg.races[0]==raceNo && item.oddsType==betType)[0];
  const cwdtls = pool?.cWinSelections;
  const oddsNodes = pool?.oddsNodes;
  const isStartSell = isStartSellpmPool(pool);

  const [showDetail, setShowDetail] = useState(false)

  const handleClickShowDetail = () => {
    setShowDetail(!showDetail)
  }
  const handleCheckOdds = (_type, _race, _col, _no, e) => {

    let isChecked = e.target.checked;
    SetBetSelect(_type, _race, _col, _no, isChecked);

    if (_col == 'leg' && isChecked) {
        SetBetSelect(_type, _race, 'b1', _no, false);
    }
    else if (_col == 'b1' && isChecked) {
        SetBetSelect(_type, _race, 'leg', _no, false);
    }
    context.racingFuncs.resetInvCal();
  }

  const handleClickOdds = (sel) => {
   if(isStartSell){
    handleClickCombOdds(sel, betType);
   }
  }

  const checkScratch = (runner) => {
    let str = '';
    if(!runner) return '';
    if(['SCRATCHEDRESERVED', 'RESERVED', 'STANDBY'].includes(runner.status.toUpperCase())) str += `(${t('LB_RC_RESERVE')})`;
    if(runner.status.toUpperCase().indexOf('SCRATCH')>=0) str += `(${t('LB_RC_SCR_S')})`;
    return str;
  }
  const checkAllScratch = (odds) => {
    if(pool.status.toUpperCase().indexOf('REFUND')>=0) return true
    return ['SCR', '---'].includes(odds)

  }
  const getOdds = (odds) => {
    if(pool.status.toUpperCase().indexOf('REFUND')>=0) return '---'
    if(odds === 'SCR') return t('LB_RC_SCR_S')
    return odds
  }

  const tableProps = {getOdds, checkAllScratch, oddsNodes,
    betType, raceNo, showDetail, runners, checkScratch,handleClickOdds, context,
     hideCheckbox, handleCheckOdds, isMobileSize, isStartSell,pool, handleClickShowDetail, disableQuickBet
    }
 
  if ( pool==null ) {
    return <Loading/>
  }
  else {
    return<div className='cwin-table-container' >
      <header>{pool["name_"+i18n.language]}*</header>
      <Table {...tableProps}/>
      </div>
  }
}

export default CWINTable

const Table = (props) => {
  const table = []
  props.pool.cWinSelections.sort((x,y)=> x.composite < y.composite ? -1 : 1);
  for(let i = 0; i < props.pool.cWinSelections.length; i++ ){
    table.push(<Row  sel={props.pool.cWinSelections[i]} rowIndex={i} className={`table-row ${i%2 ? 'evenbg': 'oddbg'}`} key={props.pool.cWinSelections[i].composite} {...props}/>)
  }
return <div  className='cwin-table' id={`cwin_table_${props.raceNo}`}>
    <TableHeader {...props}/>
    {table}
  </div>
}


const TableHeader = ({handleClickShowDetail, showDetail}) => {

  const {t, i18n} = useTranslation()

  return <div className='cwin-table-header table-row df evenbg'>
    <div className='table-cell cwin-table-comp'>{t('LB_RC_CWIN_COMPOSITE')}</div>
    <div className='table-cell cwin-table-detail' >
      <div className='df'>
        <div>{t('LB_RC_CWIN_SI')}</div>
        <div onClick={handleClickShowDetail} className={`cwin-detail-switch`}><div className={`switch-btn-icon ${showDetail? 'close-btn-icon' : 'open-btn-icon'}`}></div></div>
        <div>{t('LB_RC_CWIN_HD')}</div>
      </div>
    </div>
    <div className='table-cell cwin-table-odds'>{t('LB_ODDS')}</div>
  </div>
}

const Row = ({sel, rowIndex, className, oddsNodes, getOdds, checkAllScratch,
   betType, raceNo, showDetail, runners, checkScratch,handleClickOdds, context,
    hideCheckbox, handleCheckOdds, isMobileSize, isStartSell, disableQuickBet}) => {
  const {t, i18n} = useTranslation()
  const oddsNode = oddsNodes.filter(item => sel.composite==item.combString)[0];
  const isPresales = context?.content?.isPresales
  const hot = oddsNode?.hotFavourite ? 'favourite': isPresales ? "" : oddsNode?.oddsDropValue >= 50 ? 'drop50' : oddsNode?.oddsDropValue >= 20 ? 'drop20' : ''

  const odds = getOdds(oddsNode?.oddsValue)
  const isScratch = checkAllScratch(oddsNode?.oddsValue)
  return <div className={className}>
    <div className='table-cell'>
      <div className="cwin-table-comp df" id={`hr_${betType}_comb_${raceNo}_${sel.composite}`}>
        <span>{sel.composite}</span>
        <div>{sel["name_" + i18n.language]}</div>
      </div>
    </div>
    <div className="table-cell">
      <div className="cwin-table-detail" id={`hr_${betType}_horses_${raceNo}_${sel.composite}`}>
        { showDetail ? <div>
          {sel.starters.map(item => {
            let runner = runners.filter(i => parseInt(i.no) == item)[0];
            const horName = runner!=null ? runner["name_"+i18n.language] : "";
            const checkScr = checkScratch(runner)
            return <div className={`df cwin-table-detail-item ${checkScr ? 'cwin-detail-scr': ''}`}>
              <span>{item}</span>
              <div >{horName} {checkScr}</div>
            </div>
          })}
        </div>
        : <div className='df cwin-table-detail-rl'>
          {sel.starters.map(item => {
            let runner = runners.filter(i => parseInt(i.no) == item)[0];
            const checkScr = checkScratch(runner)
            return <span className={checkScr ? 'cwin-detail-scr': ''}>{item}{checkScr}</span>
          })}
        </div>}
    </div>
    </div>
    <div className="table-cell">
      <div className='cwin-table-odds df' id={`hr_${betType}_odds_${raceNo}_${sel.composite}`}>
        {!hideCheckbox ? <div className="rc-checkbox">
          <CheckBox  id={`cwinleg_${betType}_${raceNo}_${sel.composite}`}  onChange={(e)=> handleCheckOdds(betType, raceNo, 'leg', sel.composite, e)} disabled={isScratch || !isStartSell}
          defaultChecked={IsBetSelected(betType, raceNo, 'leg', sel.composite)}/>
        </div> : null}
        <div id={`odds_${betType}_${raceNo}_${sel.composite}`} clickable={`${!disableQuickBet}`} className={`cwin-odds ${disableQuickBet || isScratch ? '' : `${hot} cp table-odds`} `} onClick={(e)=>!disableQuickBet && !isMobileSize && !isScratch && handleClickOdds( oddsNode)}>{odds}</div>
      </div>
    </div>
  </div>
}